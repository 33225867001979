import { isEmpty } from "lodash";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  bookmarkQuestionApi,
  getAnalyticsSingleQuestionApi,
  getRandomQuestion,
  saveAnswer,
} from "../../../apis/practiceService";
import {
  bookmarkQuestion,
  bookmarkQuestionSuccess,
  catchErrorAnalytics,
  catchErrorAnswer,
  catchErrorBookmarkQuestion,
  catchErrorQuestions,
  fetchQuestionsList,
  fetchQuestionSuccess,
  getAnalyticsSingleQuestion,
  getAnalyticsSingleQuestionSuccess,
  saveAnswerSuccess,
  savedAnswer,
} from "./actionCreators";

// get solved or unsolved question list
function* fetchQuestions(api_payload) {
  try {
    const questionData = yield call(getRandomQuestion, api_payload.body);
    const out = yield questionData.data.output;
    let questionList = [];
    if (!out.every((val) => val === null)) {
      questionList = out.map((data, index) => {
        return {
          questionNo: index + 1,
          subjectName: data.subject,
          paragraph: data.paragraph ? data.paragraph : null,
          options: {
            A: data.A,
            B: data.B,
            C: data.C,
            D: data.D,
          },
          markedAns: data.markedAns ? data.markedAns : null,
          ...data,
        };
      });
    }
    yield put(fetchQuestionSuccess(questionList));
  } catch (error) {
    yield put(catchErrorQuestions(error.message));
  }
}

// submit answer
function* saveAns(api_payload) {
  try {
    const submitAnswer = yield call(saveAnswer, api_payload.body);
    const result = yield submitAnswer.data;
    if (!result.error) {
      yield put(saveAnswerSuccess(result));
    } else {
      yield put(catchErrorAnswer(result.error));
    }
  } catch (error) {
    yield put(catchErrorAnswer(error.message));
  }
}

//bookmark question
function* bookmarkCurrentQuestion(api_payload) {
  try {
    const bookmarkQues = yield call(bookmarkQuestionApi, api_payload.body);
    const ifBookmarked = yield bookmarkQues.data;
    if (ifBookmarked.status === "SUCCESS") {
      yield put(bookmarkQuestionSuccess(ifBookmarked));
    } else yield put(catchErrorBookmarkQuestion(ifBookmarked.error));
  } catch (error) {
    yield put(catchErrorBookmarkQuestion(error.message));
  }
}

/* Analytics of particular question */
function* getAnalyticsCurrentQuestion(api_payload) {
  try {
    const categories = {
      timeSpent0: "0s",
      timeSpent1: "1-20s",
      timeSpent2: "21-40s",
      timeSpent3: "41-60s",
      timeSpent4: "1m-1m 20s",
      timeSpent5: "1m21s-1m40s",
      timeSpent6: "1m41s-2m",
      timeSpent7: "2m-2m20s",
      timeSpent8: "2m21s-2m40s",
      timeSpent9: "2m41s-3m",
      timeSpent10: "3-4m",
      timeSpent11: "4-5m",
      timeSpent12: "5-10m",
      timeSpent13: "10m+",
    };
    const timeSpentFinalData = {};
    const dataToBeShownOnChart = {};
    let output;
    const analysis = yield call(
      getAnalyticsSingleQuestionApi,
      api_payload.body
    );
    if (isEmpty(analysis.data)) {
      output = {};
    } else {
      const analyticsData = yield analysis.data.output;
      Object.keys(analyticsData).forEach((data) => {
        if (data in categories) {
          timeSpentFinalData[data] = analyticsData[data];
        }
      });

      Object.keys(categories).forEach((timeWindow) => {
        if (timeWindow in timeSpentFinalData) {
          dataToBeShownOnChart[timeWindow] = timeSpentFinalData[timeWindow];
        } else {
          dataToBeShownOnChart[timeWindow] = 0;
        }
      });
      output = {
        ...analyticsData,
        students: Object.values(dataToBeShownOnChart).reverse(),
        timeSpentData: Object.values(categories).reverse(),
      };
    }
    yield put(getAnalyticsSingleQuestionSuccess(output));
  } catch (error) {
    yield put(catchErrorAnalytics(error.message));
  }
}

/* -------------------------------------------------------------------------------------------------------- */

function* practiceSagaWatcher() {
  yield takeLatest(fetchQuestionsList().type, fetchQuestions);
  yield takeLatest(savedAnswer().type, saveAns);
  yield takeLatest(bookmarkQuestion().type, bookmarkCurrentQuestion);
  yield takeLatest(
    getAnalyticsSingleQuestion().type,
    getAnalyticsCurrentQuestion
  );
}

export default practiceSagaWatcher;
