import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
const CustomSelect = withStyles((theme) => ({
  root: {
    paddingLeft: "2%",
    paddingRight: "2%",
    borderRadius: "15px",
    textTransform: "capitalize",
    "&:focus": {
      borderRadius: "15px",
      color: theme.palette.primary.main,
    },
    "&:hover": {
      borderRadius: "15px ",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "13px 21px 13px 21px",
      // borderRadius: "15px 10px 0 0",
      // "&:hover": {
      //   borderRadius: "15px 10px 0 0",
      // },
      // "&:focus": {
      //   borderRadius: "15px 10px 0 0",
      // },
    },
  },
  select: {
    color: theme.palette.primary.main,
    // fontWeight: 600,
    fontSize: "14px",
    // lineHeight: "24px",
    border: "none",
    "&:not([multiple]) option, &:not([multiple]) optgroup": {
      backgroundColor: theme.palette.primary.light,
      padding: "10px",
    },
  },
}))((props) => <Select disableUnderline {...props} />);

export { CustomSelect };
