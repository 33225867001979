import { call, put, takeLatest } from "redux-saga/effects";
import {getAppSettingsInfo} from "../../../apis/appSettings";
import {
appSettingsLoading,
appSettingsSuccess,
catchErrorappSettings
} from "./actionCreator";

function* appSettings(api_payload) {
  try {
    const userData = yield call(getAppSettingsInfo, api_payload);
    console.log(userData);
    const result = yield userData.result;
    console.log(result);
    yield put(appSettingsSuccess(result));
  } catch (error) {
    yield put(catchErrorappSettings(error.message));
  }
}

function* appSettingsSagaWatcher() {
  yield takeLatest(appSettingsLoading().type, appSettings);
}

export default appSettingsSagaWatcher;
