export default {
  "5e7370083d8520a40c4ceaaa": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/bhashyam_mini.png",
    title: "BHASHYAM EDUCATIONAL INSTITUTIONS",
    smsHeader: "- ",
    PE: "1201164330565400925",
  },
  "5ea3572fe301133d0a73e9e0": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/miity_mini.png",
    title: "MIITY JUNIOR COLLEGE",
    smsHeader: "MIITYE",
    subTitle: "IIT-JEE MAIN|ADVANCED",
    PE: "1201164330565400925",
    regNo: "(Regd. No. 515/2021) ",
  },
  "5e9098aaa218252d49be5a3b": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/sr_mini.png",
    title: "SR EDUCATIONAL INSTITUTIONS",
    smsHeader: "- ",
    PE: "1201164330565400925",
  },
  "61e850055876f313e30ea1de": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/agastya_mini.png",
    title: "AGASTYA JUNIOR COLLEGE",
    smsHeader: "AJCIIT",
    PE: "1201164330565400925",
  },
  "640053dc178e451001e83e37": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/onesaz_mini.png",
    title: "ONESAZ",
    smsHeader: "- ",
    PE: "1201164330565400925",
  },
  "5d679d49c136660a09596d85": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/onesaz_mini.png",
    title: "ONESAZ",
    smsHeader: "- ",
    PE: "1201164330565400925",
  },
  "5ee85fc60f25a93146ea0bf9": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/sriabhidakp_mini.png",
    title: "SRI ABHIDA IIT JEE FOUNDATIONS - KP",
    smsHeader: "SRIAKP",
    PE: "1201164330565400925",
  },
  "645b4332b98a5dc4ce54256d": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/vinners_mini.png",
    title: "VINNERS OLYMPIAD SCHOOL , NIRMAL",
    smsHeader: "VINNRO",
    PE: "1201164330565400925",
  },
  "646634c8a400ecffc1306d0d": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/turito_mini.png",
    title: "SREE SARASWATHI EDUCATIONAL SOCIETY",
    smsHeader: "- ",
    PE: "1201164330565400925",
  },
  "61e6489a9b609f2143aab796": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/aiitv.jpg",
    title: "SREE VENKATESWARA AIAT IIT & NEET ACADEMY",
    smsHeader: "- ",
    PE: "1201164330565400925",
  },
  "63a943927e91069155cc07da": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/edify-logo.png",
    title: "EDIFY SCHOOL",
    PE: "1201164330565400925",
  },
  "6422b982a32e9b7c5498b129": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/ligadepatil.jpg",
    title: "LigadePatil Jr College of science",
    smsHeader: "LPJCSK",
    PE: "1201164330565400925",
  },

  "65772522ee64cfa8dc9851f2": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/SRCLG.jpg",
    title: "SR Educational Academy",
    smsHeader: "SREDUA",
    PE: "1501566770000051473",
  },
  "65772522ee64cfa8dc9851f2_prime": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/SRPRIME.jpg",
  },
  "65772522ee64cfa8dc9851f2_digi": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/SR_DIGI_LOGO1.png",
  },
  "65772522ee64cfa8dc9851f2_National": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/NHS-logo.png",
  },
  "65772522ee64cfa8dc9851f2_Dimples": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/dimples-logo.png",
  },
  "6617918287bfb9f18b6d32a5": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/ybrant.jpg",
    title: "YBRANT Classes",
  },
  "6655751f14c7f8079c692091": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/SRCLG.jpg",
    title: "SR Educational Academy",
    smsHeader: "SREDUA",
    PE: "1201164330565400925",
  },
  "5fd25b12ed14200ff702f738": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/tirumala_mini.png",
    title: "TIRUMALA IIT & MEDICAL ACADEMY",
    smsHeader: "",
    PE: "1201164330565400925",
    regStart:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/banners/tirumala_01.jpg",
  },
  "5ee85fc60f25a93146ea0bg9": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/sriabhida_mini.png",
    title: "SRI ABHIDA JUNIOR COLLEGE",
    smsHeader: "SRI",
    PE: "1201164330565400925",
    collegeCode: "58538",
    address:
      "58538-SRI ABHIDA JUNIOR COLLEGE,H.NO. 4-6-69/5/A,B,C,D,E, P.No. 104, 105, 106, 109, 110, , Near Pillar No. 141, VASUDEVA REDDY NAGAR COLONY, ATTAPUR",
    rcNo: "Rc.No. 20251/47254/C15-3/2007-2008",
  },
  "5ee85fc60f25a93146ea0bg1": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/pinegrove_mini.png",
    title: "PINEGROVE JUNIOR COLLEGE",
    smsHeader: "PINEGROVE",
    PE: "1201164330565400925",
  },
  "5ee85fc60f25a93146ea0a4h": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/vijna_mini.png",
    new_url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/vijna_new_logo.jpg",
    title: "VIJNA NEET COLLEGE",
    smsHeader: "VIJNA",
    PE: "1201164330565400925",
  },
  "5ee85fc60f25a93146ea0n4o": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/neutrinoacademy_mini.png",
    title: "Neutrino Academy",
    smsHeader: "NEUTRINO",
    PE: "1201164330565400925",
    subTitle: "Balancing all inequalities",
  },
  "5ee85fc60f25a93146ea0ayt": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/ayati_mini.png",
    title: "Ayati junior college",
    smsHeader: "AYATI",
    PE: "1201164330565400925",
  },
  "5ee85fc60f25a93146ea0s01n": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/sundar_mini.png",
    title: "SUNDAR EDUCATIONAL ACADEMY, KOMPALLY",
    smsHeader: "SUNDAR",
    PE: "1201164330565400925",
  },
  "5ee85fc60f25a93146ea0coz": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/cognizant_mini.png",
    title: "COGNIZANT Institute of NEET and JEE",
    smsHeader: "COGNIZANT",
    PE: "1201164330565400925",
  },
  "5ee85fc60f25a93146ea0meta": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/metamind_mini.png",
    title: "METAMIND JEE&NEET JR COLLEGE",
    smsHeader: "METAMIND",
    PE: "1201164330565400925",
  },
  "66c430d428cbcc7c97d22a27": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/motion_mini.png",
    title: "MOTION IIT JEE ACADEMY",
    smsHeader: "MOTION",
    PE: "1201164330565400925",
  },
  "66d6f203d1e9e56a0955f6b5": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/kle_mini.png",
    title: "KLE INSTITUTE OF TECHNOLOGY",
    smsHeader: "KLE",
    PE: "1201164330565400925",
  },
  "66d7fd5ed38d3afac6db0696": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/excellencia_mini.png",
    title: "EXCELLENCIA JR COLLEGE",
    smsHeader: "EXC",
    PE: "1201164330565400925",
  },
  "66e2a9114c68e81e10ee021c": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/arastu_mini.png",
    title: "ARASTU JR COLLEGE",
    smsHeader: "ARA",
    PE: "1201164330565400925",
  },
  // "5d679d49c136660a09596d85": {
  //   url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/onesaz_mini.png",
  //   title: "ONESAZ",
  //   regStart:
  //     "https://onesaz-new.s3.ap-south-1.amazonaws.com/banners/tirumala_01.jpg",
  // },

  "5d679d49c136660a09596d85": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/tirumala_mini.png",
    title: "TIRUMALA IIT & MEDICAL ACADEMY",
    smsHeader: "",
    PE: "1201164330565400925",
    regStart:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/banners/tirumala_01.jpg",
  },
};

export const hostnamesMap = {
  "trinity.onesaz.com": "5fa17e1e5eff2c02d9eb32c1",
  "bhashyam.onesaz.com": "5e7370083d8520a40c4ceaaa",
  "sreducation.onesaz.com": "5e9098aaa218252d49be5a3b",
  "sriabhidakp.onesaz.com": "5ee85fc60f25a93146ea0bf9",
  "miity.onesaz.com": "5ea3572fe301133d0a73e9e0",
  "demo.onesaz.com": "5d4c80481a4a8320de0b9e8b",
  "viitjee.onesaz.com": "5dc1388b22bdc209de6a4d80",
  "vinners.onesaz.com": "5e9b3d6cdbae145f55d1e712",
  "sriabhida.onesaz.com": "5ee85fc60f25a93146ea0bg9",
  "onesaz.com": "5d679d49c136660a09596d85",
  "www.onesaz.com": "5d679d49c136660a09596d85",
  "tirumala.onesaz.com": "5fd25b12ed14200ff702f738",
  "medicon.onesaz.com": "602f25174f379c03fa0f7737",
  "impulse.onesaz.com": "608ea4eea4105bb4fbc94822",
  "sandeepani.onesaz.com": "61db4c3da28e11700a6064ac",
  "aiat.onesaz.com": "61e6489a9b609f2143aab796",
  "valleyoak.onesaz.com": "5e521e0da587c5bcefdb7c90",
  "scjc.onesaz.com": "62b317ab4406d92c11fd4257",
  "pjcmncl.onesaz.com": "5dd2de6fc539670ff56e37fc",
  "agastya.onesaz.com": "61e850055876f313e30ea1de",
  "edify.onesaz.com": "63a943927e91069155cc07da",
  "ligadepatil.onesaz.com": "6422b982a32e9b7c5498b129",
  "turito.onesaz.com": "646634c8a400ecffc1306d0d",
  // localhost:4000
  "localhost:4000": "5d679d49c136660a09596d85",
  localhost: "5d679d49c136660a09596d85",
  "ybrant.onesaz.com": "6617918287bfb9f18b6d32a5",
  "sr.onesaz.com": "65772522ee64cfa8dc9851f2",
  "pinegrove.onesaz.com": "5ee85fc60f25a93146ea0bg1",
  "neutrinoacademy.onesaz.com": "5ee85fc60f25a93146ea0n4o",
  "vijna.onesaz.com": "5ee85fc60f25a93146ea0a4h",
  "ayati.onesaz.com": "5ee85fc60f25a93146ea0ayt",
  "sundar.onesaz.com": "5ee85fc60f25a93146ea0s01n",
  "cognizant.onesaz.com": "5ee85fc60f25a93146ea0coz",
  "metamind.onesaz.com": "5ee85fc60f25a93146ea0meta",
  "motion.onesaz.com": "66c430d428cbcc7c97d22a27",
  "excellencia.onesaz.com": "66d7fd5ed38d3afac6db0696",
  "kle.onesaz.com": "66d6f203d1e9e56a0955f6b5",
  "192.168.1.21": "5fd25b12ed14200ff702f738"
};
