import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Checkbox,
  TextField,
  Grid,
} from "@material-ui/core";
import {
  CustomTableRow,
  CustomTableCellType3,
} from "../../components/CustomTableCells";
import { useStyles } from "../../Dashboard/Components/styles";
import startCase from "lodash/startCase";
import { useStyles4 } from "../styles";

const OtherFeesTable = ({
  otherFees,
  selectedFeeTypesSecondTable,
  balancesSecondTable,
  nameSecondTable,
  config,
  handleFeeTypeCheckboxChangeSecondTable,
  handleBalanceChangeSecondTable,
}) => {
  const classes = useStyles();
  const classes1 = useStyles4();
  return (
    otherFees !== null && (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <CustomTableRow className={classes.tr}>
              <CustomTableCellType3 className={classes.tableHeaderStyle}>
                Fee Type
              </CustomTableCellType3>
              <CustomTableCellType3 className={classes.tableHeaderStyle}>
                Amount
              </CustomTableCellType3>
              <CustomTableCellType3 className={classes.tableHeaderStyle}>
                Balance
              </CustomTableCellType3>
              <CustomTableCellType3 className={classes.tableHeaderStyle}>
                Paid
              </CustomTableCellType3>
            </CustomTableRow>
          </TableHead>
          <TableBody>
            {otherFees &&
              Object.entries(otherFees).map(([feeType, feeDetails]) => {
                const checkboxKey = `${feeType}`;
                const isChecked = selectedFeeTypesSecondTable.includes(feeType);
                const balance = isChecked ? balancesSecondTable[feeType] : "";

                const isTotalFeeType = feeType === "Total";
                const feeTypeName = startCase(feeType);
                return (
                  !isTotalFeeType && (
                    <CustomTableRow key={checkboxKey} className={classes.tr}>
                      {/* {feeDetails.balance !== 0 && ( */}
                      <>
                        <CustomTableCellType3>
                          {feeTypeName}
                        </CustomTableCellType3>
                        <CustomTableCellType3>
                          {feeDetails.amount.toLocaleString("en-IN")}
                        </CustomTableCellType3>
                        <CustomTableCellType3>
                          {feeDetails.balance.toLocaleString("en-IN")}
                        </CustomTableCellType3>
                        <CustomTableCellType3>
                          {feeDetails.paid.toLocaleString("en-IN")}
                        </CustomTableCellType3>
                        <CustomTableCellType3>
                          <Checkbox
                            checked={feeDetails.balance === 0 || isChecked}
                            style={{
                              color:
                                feeDetails.balance === 0
                                  ? "rgb(8, 197, 167)"
                                  : "#197BBD",
                            }}
                            defaultChecked={feeDetails.balance === 0}
                            disabled={feeDetails.balance === 0}
                            onChange={() =>
                              handleFeeTypeCheckboxChangeSecondTable(
                                feeType,
                                feeDetails.balance
                              )
                            }
                          />
                          {isChecked && (
                            <Grid xs={12} sm={6} className={classes1.root}>
                              <TextField
                                id="outlined-name"
                                label="Amount"
                                size="small"
                                disabled={!config.parentAccessToEnterAmount}
                                value={
                                  nameSecondTable[feeType] || feeDetails.balance
                                }
                                type="number"
                                onChange={(event) =>
                                  handleBalanceChangeSecondTable(
                                    feeType,
                                    event.target.value
                                  )
                                }
                                variant="outlined"
                              />
                            </Grid>
                          )}
                        </CustomTableCellType3>
                      </>
                      {/* )} */}
                    </CustomTableRow>
                  )
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
};
export default OtherFeesTable;
