import {
  AppBar,
  Button,
  Container,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import MenuIcon from "@material-ui/icons/Menu";
import onesazLogo from "assets/v2/onesaz.png";
import { capitalize } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
// transition direction for prompt box
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyle = makeStyles((theme) => ({
  toolbar: {
    margin: "1% 4px 1% 4px",
    // [theme.breakpoints.up("md")]: { margin: "1% 10% 1% 10%" },
  },
  icon: {
    verticalAlign: "middle",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));
function HeaderPracticeSet({ body, ...props }) {
  const classes = useStyle();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const handleLeave = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLeaveSession = () => {
    history.push("/practice");
    setOpen(false);
  };
  // analytics controller
  const handleViewAnalytics = () => {
    console.log("Analytics: ");
  };
  return (
    <React.Fragment>
      <AppBar color="secondary" position="sticky">
        <Container fixed disableGutters>
          <Toolbar className={classes.toolbar}>
            <Grid container justify="space-between">
              <Grid item>
                <Hidden xsDown>
                  <Typography variant="h3" color="textPrimary">
                    <img src={onesazLogo} alt="Onesaz" height="37px" />
                    &nbsp;|&nbsp;
                    {capitalize(body.topic)}
                  </Typography>
                  <Typography variant="caption" color="textPrimary">
                    {capitalize(body.subTopic)}
                  </Typography>
                </Hidden>
                <Hidden smUp>
                  <Typography variant="caption" color="textPrimary">
                    <img src={onesazLogo} alt="Onesaz" height="27px" />
                    &nbsp;|&nbsp;
                    {capitalize(body.topic)}
                  </Typography>
                  &nbsp;|&nbsp;
                  <Typography
                    variant="caption"
                    align="center"
                    color="textPrimary"
                  >
                    {capitalize(body.subTopic)}
                  </Typography>
                </Hidden>
              </Grid>
              <Grid item>
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  edge="start"
                  onClick={props.handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                &nbsp;&nbsp;
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={handleLeave}
                >
                  Leave
                </Button>
                &nbsp;&nbsp;
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={handleViewAnalytics}
                >
                  Analytics
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      {/* ------------------------ */}
      {/* alert for leaving test */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Leaving?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure, you want to leave the practice session?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleLeaveSession} color="inherit">
            Leave
          </Button>
        </DialogActions>
      </Dialog>
      {/* ---------------------- */}
    </React.Fragment>
  );
}

export default HeaderPracticeSet;
