import { Box } from "@material-ui/core";
import React, { memo } from "react";
import TopicList from "./TopicList";

const Practice = ({ arrayOfTopic, topics, subject, percentData }) => {
  const MemoisedTopicList = memo(TopicList);
  return (
    <Box mb="150px">
      {arrayOfTopic.map((topicName, index) => {
        return (
          <MemoisedTopicList
            key={topicName}
            count={index + 1}
            titleName={topicName}
            subTopics={Object.keys(topics[arrayOfTopic[index]])}
            subject={subject}
            percentData={percentData}
          />
        );
      })}
    </Box>
  );
};

export default Practice;
