import { makeStyles, withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";




export const useStyles1 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // minWidth: "500px",
    maxWidth: "1700px",
    overflow: "auto",
    // marginTop:"20px",
    padding: "4% 8% 8% 8%",
    [theme.breakpoints.down("sm")]: {
      padding: "4% 2% 2% 2%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "20%",
    },
    // position: "absolute",
    // width: "1500px",
    height: "3452px",
    top: "20px",
    background: "#ECF0F3",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    // color: theme.palette.text.secondary,
    background: "#FAFAFA",
    boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    borderRadius: "10px",
  },
}));
export const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    borderRadius: "50%",
    animation: "$ripple 1.2s infinite ease-in-out",
    border: "1px solid currentColor",
    content: '""',
    boxSizing: "border-box",
    position: "absolute",
    width: "19.51px",
    height: "19.51px",
    left: "80.29px",
    top: "68.49px",
    background: "#03AD92",
    border: "3px solid #FFFFFF",
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    textAlign: "center",
  },
  root: {
    flexGrow: 1,
  },
  grandTotalColor: {
    color: "blue",
  },
  progressCard: {
    padding: "25px 15px",
    gap: "10px",
    // width: "148px",
    // height: "90px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.15px",
    backgroundColor: "#3BBDED",
    boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    borderRadius: "10px",
    color: "#E7F7FD",
  },
  paper: {
    display: " flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "25px 15px",
    gap: "10px",
    // width: "148px",
    // height: "90px",
    letterSpacing: "0.15px",
    background: "#FAFAFA",
    boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    borderRadius: "10px",
  },
  paper1: {
    display: " flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "20px 15px",
    gap: "10px",
    // width: "200px",
    // height: "100px",
    letterSpacing: "0.15px",
    background: "#FAFAFA",
    boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    borderRadius: "10px",
  },
  navigationDiv: {
    marginLeft: "-4%",
  },
  bgCustom: {
    minWidth: "500px",
    maxWidth: "1700px",
    overflow: "auto",
    padding: "0 15% 5% 15%",
    [theme.breakpoints.down("sm")]: {
      padding: "0 2% 7% 2%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "20%",
    },
    position: "absolute",
    width: "1500px",
    height: "3452px",
    top: "10px",
    background: "#ECF0F3",
  },

  hiStudentFont: {
    // width: "111px",
    // height: "30px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "150%",
    // display: "flex",
    // alignItems: "center",
    color: "#31456A",
    // flex: "none",
    // order: 0,
    // flexGrow: 0,
  },
  name: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "flex-start",
    color: "#31456A",
    align: "flex-start",
    justifyContent: "flex-start",
    padding:1,
  
  },
  studentName: {
    width: "200px",
    height: "90px",
    left:"20px",
    fontFamily: "Montserrat",
    // textAlign: "center",
    textTransform: "capitalize",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "30px",
    display: "flex",
    // alignItems: "center",
    color: "#31456A",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
  greatToSeeYouFont: {
    // width: "136px",
    // height: "32px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "160%",
    // display: "flex",
    // alignItems: "center",
    color: "#3BBDED",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "180px",
    },

    // flex: "none",
    // order: 0,
    // flexGrow: 0,
  },
  CardFontStyle: {
    color: "#8E9AAF",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "15px",
  },
  numberFontStyle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "15px",
  },
  graphsContainer: {
    display: " flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    gap: "20px",
    width: "728px",
    height: "1901px",
  },
  cards: {
    display: " flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    width: "635px",
    maxWidth: "1200px",
    height: "510px",
    background: "#FAFAFA",
    boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    borderRadius: "10px",
    overflow: "auto",
  },

  stuProfile: {
    display: " flex",
    flexWrap: "wrap",
    flexGrow: 1,
    // overflow: "auto",
    // width:"200px",
    maxWidth: "300px",
    flexDirection: "column",
    alignItems: "flex-start",
    // padding: "20px 20px",
    padding: "13px 0px 0px 0px",

    position: "absolute",
    // width: "340px",
    // height: "280px",
    maxHeight: "900px",
    // [theme.breakpoints.down("xs")]: {
    //   left: "680.9px",
    // },
    // left: "907.9px",
    boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    // top: "150px",
    top:"130px",
    background: "#FAFAFA",
    borderRadius: "10px",
  },
  stuProfile1: {
    display: " flex",
    // flexWrap: "wrap",
    flexGrow: 2,
    // overflow: "auto",
    maxWidth: "400px",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "15px 20px",
    position: "absolute",
    width: "295px",
    height: "130px",
    maxHeight: "900px",
    // [theme.breakpoints.down("xs")]: {
    //   left: "680.9px",
    // },
    // left: "907.9px",
    boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    top: "470px",
    background: "#FAFAFA",
    borderRadius: "10px",
  },
  imgBox: {
    flex: "none",
    order: 0,
    flexGrow: 0,
    padding: "13px 0px 0px 0px",
  },
  img: {
    boxSizing: "borderBox",
    position: "absolute",
    width: "90px",
    height: "90px",
  
    left: "20px",
    border: "3px solid #FFFFFF",
    filter: "drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.08))",
  },
  batch: {
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 2,
    // justifyContent: "center",
    // alignItems: "center",
    // // padding: "10px",
    // width:"200px",
    maxWidth: "250px",
    border: "1px solid #118FBE",
    // gap: "10px",
    // width: "208px",
    // height: "30px",
    background: "#E7F7FD",
    borderRadius: "8px",
    // flex: "none",
    // order: 0,
    // flexGrow: 0,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "14px",
    color: "#118FBE",
  },
  admissionNoBox: {
    display: " flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 16px",
    gap: "10px",
    width: "200px",
    height: "30px",
    borderRadius: "4px",
    flex: "none",
    order: 0,
    flexGrow: 0,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "14px",
    background: "#E6F9F6",
    color: "#069E86",
  },
  omrBox: {
    display: " flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 16px",
    gap: "10px",
    width: "108px",
    height: "30px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "14px",
    background: "rgba(255, 238, 204, 0.3)",
    borderRadius: "4px",
    flex: "none",
    order: 0,
    color: "#E99C00",
    flexGrow: 0,
  },
  examSummary: {
    display: " flex",
    alignItems: "flex-start",
    padding: "3px",
    gap: "22px",
    width: "604px",
    height: "35px",
    flex: "none",
    order: 0,
    alignSelf: "stretch",
    flexGrow: 0,
    zIndex: 0,
  },
  title: {
    display: " flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0px",
    gap: "10px",
    width: "200px",
    height: "46px",
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
  dropDown: {
    display: " flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px",
    gap: "16px",
    width: "380px",
    height: "46px",
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
  timeSpent: {
    display: " flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0px",
    gap: "16px",
    width: "200px",
    height: "46px",
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
  skillSummaryBox: {
    display: " flex",
    alignItems: "flex-start",
    padding: "0px",
    gap: "22px",
    width: "604px",
    flex: "none",
    order: 0,
    alignSelf: "stretch",
    flexGrow: 0,
    zIndex: 0,
  },
  skillSummaryChart: {
    width: "440.85px",
    height: "400px",
    left: " 187.16px",
    top: "0px",
  },
  button: {
    border: "1.5px solid #ECF0F3",
    borderRadius: "4px",
    color: "#8E9AAF",
    height: "35px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "10px",
    display: " flex",
    letterSpacing: "0.15px",
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
  icon: {
    height: "1.7rem",
  },
  paymentBox1: {
    display: " flex",
    alignItems: "flex-start",
    padding: "0px",
    gap: "10px",
    width: "604px",
    flexDirection: "column",
    width: "294px",
    height: "257px",
    [theme.breakpoints.down("xs")]: {
      left: "680.9px",
    },
    left: "907.9px",
    top: "420px",
    position: "absolute",
  },
  paymentBox2: {
    display: " flex",
    alignItems: "flex-start",
    padding: "5px 10px",
    gap: "22px",
    width: "604px",
    flexDirection: "row",
    width: "294px",
    left: "907.9px",
    top: "420px",
    borderRadius: "10px",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
  paymentText: {
    width: "195px",
    height: "20px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "25px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.15px",
    color: "#8E9AAF",
    flex: "none",
    order: 0,
    flexGrow: 1,
  },
  paymentBox3: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    padding: " 5px 10px",
    width: "294px",
    height: "45px",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
  expandBox: {
    boxSizing: "border-box",
    display: " flex",
    alignItems: "flex-start",
    padding: "10px",
    gap: "10px",
    width: "68px",
    flexDirection: "row",
    height: "35px",
    left: "907.9px",
    top: "400px",
    background: "#FFFFFF",
    border: "1px solid #E7F7FD",
    borderRadius: "6px",
    flex: "none",
    order: 1,
    color: "#118FBE",
    flexGrow: 0,
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "15px",
  },
  feeToPayBox: {
    display: " flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "13px 20px",
    gap: "79px",
    width: "294px",
    height: "50px",
    background: " #FAFAFA",
    boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    borderRadius: "10px",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
  feeToPayText: {
    width: "87px",
    height: "24px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.15px",
    color: "#31456A",
    flex: "none",
    order: 0,
    flexGrow: 1,
  },
  installmentBox: {
    display: " flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "294px",
    height: "142px",
    padding: "12px",
    gap: "2px",
    isolation: "isolate",
    background: " #FAFAFA",
    boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    borderRadius: "10px",
    flex: "none",
    order: 2,
    flexGrow: 0,
  },
  installmentTextBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "10px",
    gap: "24px",
    zIndex: 1,
    width: "250px",
    height: "40px",
    alignSelf: "stretch",
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
  lastDateTextBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "10px",
    gap: "24px",
    alignSelf: "stretch",
    width: "270px",
    height: "40px",
    flex: "none",
    order: 3,
    flexGrow: 0,
    zIndex: 3,
  },
  feeButtonsBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px",
    gap: "4px",
    width: "270px",
    height: "34px",
    flex: "none",
    order: 4,
    flexGrow: 0,
    zIndex: 4,
    alignSelf: "stretch",
  },
  requestChangeButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    gap: "31px",
    width: "130px",
    height: "34px",
    background: "#ECF0F3",
    borderRadius: "6px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "20px",
    color: "#31456A",
    flex: "none",
    order: 4,
    flexGrow: 1,
  },
  payNowButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: " 12px 16px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "20px",
    color: "#FAFAFA",
    gap: "31px",
    width: "130px",
    height: "34px",
    background: "#31456A",
    borderRadius: "6px",
    flex: "none",
    order: 4,
    flexGrow: 1,
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "30px",
    position: "absolute",
    width: "628px",
    maxWidth: "900px",
    height: "1941px",
    // overflowX:"auto",
    [theme.breakpoints.up("sm")]: {
      left: "239.56px",
    },
    // top: "279.57px",
    top: "300.57px",
  },
  TopSmallCardsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    gap: "12px",
    // position: "absolute",
    width: "628px",
    height: "90px",
    // [theme.breakpoints.up("sm")]: {
    //   left: "240px",
    // },
    // top: "160px",
  },
  TopSmallCardsContainer1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    gap: "12px",
    // position: "absolute",
    width: "628px",
    height: "90px",
    [theme.breakpoints.up("sm")]: {
      left: "24px",
    },
    top: "150px",
  },
  studentGreetingsBox: {
    padding: "5px 10px",
    gap: "4px",
    // position: "absolute",
    width: "628px",
    height: "40px",
    [theme.breakpoints.up("sm")]: {
      left: "239.96px",
    },
    top: "100.35px",
  },
  tableHeaderStyle: {
    color: "#197BBD",
    // fontWeight: 700,
    fontSize: "14px",
    whiteSpace: 'nowrap' 
  },
}));
