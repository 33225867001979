import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import Container from '@material-ui/core/Container';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomTable from "components/Table/Table.jsx";
import NetworkProgress from "./NetworkProgress";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const styles = (theme) => ({
  legendLabel: {
    background: "#93A6A5",
    height: "5px",
    width: "5px",
    whiteSpace: "pre-wrap",
  },
});

class ComparisionCard extends React.Component {
  //  tableHead =["  ", "Your Details", "Average", "Topper Details"];
  //  tableHead1 = [" ","PHY C", "PHY W", "PHY U","PHY Marks","MAT C", "MAT W", "MAT U","MAT Marks", "CHE C", "CHE W", "CHE U", "CHE Marks", "Total" ]
  //  tableData=[[" h ", "your Details", "Average", "Topper Details"],["ello  ", "your Details", "Average", "Topper Details"]];

  subjectChartdata = {};
  pieColors = {
    MATHEMATICS: ["#E4D360", "#B1AEAE"],
    CHEMISTRY: ["#60C1E4", "#B1AEAE"],
    PHYSICS: ["#E4A260", "#B1AEAE"],
  };
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      networkRequest: true,
      subjectCharts: {},
      rowMapping: {
        YourDetails: ["Your Details"],
        Average: ["Average"],
        TopperDetails: ["Topper Details"],
      },
      tableHead: [" "],
      cardsData: {},
      // detailBackendMapping:{
      //     CHEMISTRY: "C",
      //     CHEMISTRY_wrong: "CW",
      //     CHEMISTRY_unattempted: "CU",
      //     CHEMISTRY_correct: "CC",
      //     MATHEMATICS_correct: "MC",
      //     MATHEMATICS_wrong: "MW",
      //     MATHEMATICS_unattempted: "MU",
      //     MATHEMATICS: "M",
      //     PHYSICS_correct: "PC",
      //     PHYSICS_wrong: "PW",
      //     PHYSICS_unattempted: "PU",
      //     PHYSICS: "P"
      // },
      detailMapping: {
        PC: ["Physics Correct"],
        PW: ["Physcis Wrong"],
        PU: ["Physics Unattempted"],
        P: ["Physics Total"],
        MC: ["Maths Correct"],
        MW: ["Maths Wrong"],
        MU: ["Maths Unattempted"],
        M: ["Maths Total"],
        CC: ["Chemistry Correct"],
        CW: ["Chemistry Wrong"],
        CU: ["Chemistry Unattempted"],
        C: ["Chemistry Total"],
        T: ["Total Marks"],
      },
    };
  }
  componentWillReceiveProps(prev, props) {
    // eslint-disable-next-line

    const body = JSON.stringify({
      student_id: this.props.id,
      test_name: this.props.test_name,
    });
    if (this.props.data != {}) {
      const res = this.props.data;
      if (res.Average) {
        console.log(Object.values(res.Average));

        console.log(Object.keys(res.Average));
      }
      const rowMapping = this.state.rowMapping;
      Object.keys(res).map((key) => {
        let detail = res[key]; // e.g key->YourDetail
        // Object.keys(this.state.detailMapping).forEach( cwuKey => {
        //     // console.log(key1);
        //     if(this.state.rowMapping[key] && detail[cwuKey]!=undefined)
        //     rowMapping[key].push(<strong>{String(detail[cwuKey])}</strong>);
        // });
        Object.keys(detail).forEach((cwuKey) => {
          // console.log(key1);
          if (this.state.rowMapping[key] && detail[cwuKey] != undefined) {
          }
          // rowMapping[key].push(<strong>{String(detail[cwuKey])}</strong>);
        });
      });
      //   console.log(detailMapping, Object.keys(this.state.detailMapping));

      this.setState({
        data: rowMapping,
        networkRequest: false,
        cardsData: this.props.data,
      });

      // data
      const mydetails = this.props.data["YourDetails"];
      const headerKeys = Object.keys(this.state.rowMapping);
      if (mydetails) {
        const tableHead = this.state.tableHead;
        if (mydetails.P != undefined) {
          tableHead.push("PHY C", "PHY W", "PHY U", "PHY Marks");
          headerKeys.forEach((headerKey) => {
            let detail = res[headerKey];
            ["PC", "PW", "PU", "P"].forEach((cwuKey) => {
              rowMapping[headerKey].push(
                <strong>{String(detail[cwuKey])}</strong>
              );
            });
          });
          this.setState({
            tableHead: tableHead,
          });
          this.subjectChartdata["PHYSICS"] = [
            {
              name: "Correct",
              color: "#71C285",
              y: mydetails.PC,
            },
            {
              name: "Wrong",
              color: "#FF6666",
              // color: "orange",
              y: mydetails.PW,
            },
            {
              name: "Un Attempted",
              color: "#93A6A5",
              y: mydetails.PU,
            },
          ];
        }

        if (mydetails.C != undefined) {
          tableHead.push("CHE C", "CHE W", "CHE U", "CHE Marks");
          headerKeys.forEach((headerKey) => {
            let detail = res[headerKey];
            ["CC", "CW", "CU", "C"].forEach((cwuKey) => {
              rowMapping[headerKey].push(
                <strong>{String(detail[cwuKey])}</strong>
              );
            });
          });
          this.subjectChartdata["CHEMISTRY"] = [
            {
              name: "Correct",
              color: "#71C285",
              y: mydetails.CC,
            },
            {
              name: "Wrong",
              color: "#FF6666",
              // color: "orange",
              y: mydetails.CW,
            },
            {
              name: "Un Attempted",
              color: "#93A6A5",
              y: mydetails.CU,
            },
          ];
        }
        if (mydetails.M != undefined) {
          tableHead.push("MAT C", "MAT W", "MAT U", "MAT Marks");
          headerKeys.forEach((headerKey) => {
            let detail = res[headerKey];
            ["MC", "MW", "MU", "M"].forEach((cwuKey) => {
              rowMapping[headerKey].push(
                <strong>{String(detail[cwuKey])}</strong>
              );
            });
          });
          this.subjectChartdata["MATHEMATICS"] = [
            {
              name: "Correct",
              color: "#71C285",
              y: mydetails.MC,
            },
            {
              name: "Wrong",
              color: "#FF6666",
              // color: "orange",
              y: mydetails.MW,
            },
            {
              name: "Un Attempted",
              color: "#93A6A5",
              y: mydetails.MU,
            },
          ];
        }

        if (mydetails.B != undefined) {
          tableHead.push("BOT C", "BOT W", "BOT U", "BOT Marks");
          headerKeys.forEach((headerKey) => {
            let detail = res[headerKey];
            ["BC", "BW", "BU", "B"].forEach((cwuKey) => {
              rowMapping[headerKey].push(
                <strong>{String(detail[cwuKey])}</strong>
              );
            });
          });
          this.subjectChartdata["BOTONY"] = [
            {
              name: "Correct",
              color: "#71C285",
              y: mydetails.BC,
            },
            {
              name: "Wrong",
              color: "#FF6666",
              // color: "orange",
              y: mydetails.BW,
            },
            {
              name: "Un Attempted",
              color: "#93A6A5",
              y: mydetails.BU,
            },
          ];
        }

        if (mydetails.BIO != undefined) {
          tableHead.push("BIO C", "BIO W", "BIO U", "BIO Marks");
          headerKeys.forEach((headerKey) => {
            let detail = res[headerKey];
            ["BIOC", "BIOW", "BIOU", "BIO"].forEach((cwuKey) => {
              rowMapping[headerKey].push(
                <strong>{String(detail[cwuKey])}</strong>
              );
            });
          });
          this.subjectChartdata["BIOLOGY"] = [
            {
              name: "Correct",
              color: "#71C285",
              y: mydetails.BIOC,
            },
            {
              name: "Wrong",
              color: "#FF6666",
              // color: "orange",
              y: mydetails.BIOW,
            },
            {
              name: "Un Attempted",
              color: "#93A6A5",
              y: mydetails.BIOU,
            },
          ];
        }
        if (mydetails.Z != undefined) {
          tableHead.push("ZOO C", "ZOO W", "ZOO U", "ZOO Marks");
          headerKeys.forEach((headerKey) => {
            let detail = res[headerKey];
            ["ZC", "ZW", "ZU", "Z"].forEach((cwuKey) => {
              rowMapping[headerKey].push(
                <strong>{String(detail[cwuKey])}</strong>
              );
            });
          });
          this.subjectChartdata["ZOOLOGY"] = [
            {
              name: "Correct",
              color: "#71C285",
              y: mydetails.ZC,
            },
            {
              name: "Wrong",
              color: "#FF6666",
              // color: "orange",
              y: mydetails.ZW,
            },
            {
              name: "Un Attempted",
              color: "#93A6A5",
              y: mydetails.ZU,
            },
          ];
        }
        headerKeys.forEach((headerKey) => {
          let detail = res[headerKey];
          ["T"].forEach((cwuKey) => {
            rowMapping[headerKey].push(
              <strong>{String(detail[cwuKey])}</strong>
            );
          });
        });
        //  const tableHead = this.state.tableHead;
        tableHead.push("Total");
        this.setState({
          tableHead: tableHead,
        });
        this.forceUpdate();
      }

      // data
      const subjectCharts = {};
      Object.keys(this.subjectChartdata).forEach((subject) => {
        const chartdata = {
          chart: {
            width: 140,
            height: 140,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0,
            },
          },
          title: {
            useHTML: true,
            text: '<b style="font-size:14px">' + subject + "</b>",
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.y}</b>",
          },
          plotOptions: {
            pie: {
              colors: this.pieColors[subject],
              //  showInLegend: showInLegend,
              size: 100,
              dataLabels: {
                enabled: true,
                format: "<b>{point.y}</b>",
                distance: -15,
                color: "white",
              },
              // showInLegend: true
            },
          },
          series: [
            {
              name: "Info",
              colorByPoint: true,
              data: this.subjectChartdata[subject],
            },
          ],
        };
        subjectCharts[subject] = chartdata;
        // showInLegend = false;
      });
      this.setState({
        subjectCharts: subjectCharts,
      });
    }

    // return prev.data!=props.data
  }

  // classes,
  //     tableHead,
  //     tableData,
  //     tableHeaderColor,
  //     hover,
  //     colorsColls,
  //     coloredColls,
  //     customCellClasses,
  //     customClassesForCells,
  //     striped,
  //     tableShopping,
  //     customHeadCellClasses,
  //     customHeadClassesForCells
  render() {
    const { classes } = this.props;
    console.log(this.state.rowMapping);
    return (
      <React.Fragment>
        {this.state.networkRequest ||
        this.state.rowMapping.Average.length < 14 ? (
          <NetworkProgress />
        ) : (
          <CustomTable
            tableData={Object.values(this.state.rowMapping)}
            tableHead={this.state.tableHead}
          >
            {" "}
          </CustomTable>
        )}
        <br /> <br /> <br />
        <GridContainer>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{
                backgroundColor: "#ebebeb",
                borderLeft: "4px solid #2aabe2",
                padding: "10px",
              }}
              gutterBottom
            >
              Accuracy per subject
            </Typography>
          </Grid>

          <GridItem>
            {/* <Card> */}
            <br />
            <Grid container justify="space-around" alignItems="center">
              {Object.entries(this.state.subjectCharts).map((entry) => {
                const sub = entry[0];
                return (
                  <Grid item>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={this.state.subjectCharts[sub]}
                    />
                    <br />
                    {/* <strong> Marks Obtained: </strong>{" "}
                        <bold>
                          {" "}
                          {entry[1].correctAnswers * 4 -
                            entry[1].incorrectAnswers}
                        </bold> */}
                  </Grid>
                );
              })}

              <GridItem
                xs={12}
                style={{
                  borderBottom: "1px solid grey",
                  marginRight: "5%",
                }}
              >
                <br /> <br />
                <GridContainer justify="flex-end">
                  <GridItem xs={4} sm={2}>
                    {" "}
                    <span className={classes.legendLabel}> {"  "} </span>{" "}
                    <strong style={{ fontSize: "10px" }}> UN ATTEMPTED</strong>{" "}
                  </GridItem>
                  <GridItem xs={4} sm={2}>
                    {" "}
                    <span
                      className={classes.legendLabel}
                      style={{ background: "#FF6666" }}
                    >
                      {" "}
                      {"  "}{" "}
                    </span>
                    <strong style={{ fontSize: "10px" }}> INCORRECT</strong>
                  </GridItem>
                  <GridItem xs={4} sm={2}>
                    {" "}
                    <span
                      className={classes.legendLabel}
                      style={{ background: "#71C285" }}
                    >
                      {" "}
                      {"  "}{" "}
                    </span>
                    <strong style={{ fontSize: "10px" }}> CORRECT</strong>
                  </GridItem>
                </GridContainer>
              </GridItem>

              {/* <GridItem xs={12}>
                    <br />
                    <br />
                    <Button color="primary">
                      Total Questions: {this.scoremap.totalQuestions}
                    </Button>
                    <Button color="teriary">
                      Attempted Questions: {this.scoremap.totalAttempted}
                    </Button>
                    <Button
                      style={{
                        pointer: "unset",
                        textAlign: "center",
                        color: "white",
                        background: "#3288F9"
                      }}
                    >
                      Total Marks: {this.marks}
                    </Button>
                  </GridItem> */}
            </Grid>

            {/* </Card> */}
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ComparisionCard);
