import {
  Container,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  ThemeProvider,
  Typography,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useEffect, useState } from "react";
import store from "store";
import {
  getAllAssignments,
  getAllAssignmentsWithoutTopic,
} from "../../apis/videoService";
import theme from "../../assets/theme";
import BottomNav from "../BottomNav";
import CustomListItem from "../CustomListItem";
import Header from "../Header";
import NetworkProgress from "../NetworkProgress";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ExamTab, ExamTabs } from "../ExamTypeSelectNav";
import { allSubjects } from "./getSubjects";
import ResultHeader from "../../mycomponents/resultPage/ResultHeader";
import AlertComponent from "../AlertComponent";

function compare(a, b) {
  if (a.createdDate > b.createdDate) {
    return -1;
  }
  if (a.createdDate < b.createdDate) {
    return 1;
  }
  return 0;
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const assignmentTypes = ["All", "Assignments", "Books", "Hints&Solutions"];
const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: "30px",
    lineHeight: "37px",
    fontWeight: "bold",
    marginLeft: "5px",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  subtitle: {
    color: theme.palette.text.primary,
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "bold",
    marginLeft: "5px",
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
  },
  download: {
    float: "right",
  },
}));
const Assignment = () => {
  const exam = store.get("startedExam");
  const agent = navigator.userAgent;
  const dimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  const [allAssignments, setAllAssignments] = useState([]);
  const [assignmentWithoutTopic, setAssignmentWithoutTopic] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [subject, setSubject] = useState(allSubjects[selectedTab].name);
  const [doc, setDoc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const user = store.get("user");
  const { institute, level, branchId, sectionid } = user;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    const assignments = async (instituteId) => {
      setIsLoading(true);
      const getAssignments = await getAllAssignments({ instituteId });
      const getAssignmentsWithout_topic = await getAllAssignmentsWithoutTopic({
        instituteId,
        subject,
        type: assignmentTypes[value],
        branch: branchId,
        section: sectionid,
      });
      if (getAssignments.status === "success") {
        setAllAssignments(getAssignments.payload);
        const filteredResult = getAssignmentsWithout_topic.payload.filter(
          (a) =>
            (a.showAssignment == true || a.showAssignment == undefined) &&
            (a.branch == branchId || a.branch == undefined)
        );

        setAssignmentWithoutTopic(filteredResult);
      }
      setIsLoading(false);
    };
    const inst_val =
      subject && subject !== "ALL"
        ? `${institute}_${level}_${subject}`
        : `${institute}_${level}`;
    assignments(inst_val);
  }, [subject, value]);
  const handleOpenPDF = (url) => {
    setOpen(true);
    setDoc(url);
  };
  const handleClose = () => {
    setOpen(false);
    setDoc("");
  };
  const handleSubjectSelect = (e, selected) => {
    setSelectedTab(selected);
    setSubject(allSubjects[selected].name);
  };
  if (exam && exam.test_status === "STARTED") {
    return (
      <>
        <br />
        <ResultHeader testDisplayName="View Library" path="/home-page" />
        <br />
        <Typography>{`Please, Submit ongoing Test : ${
          exam.testDisplayName
        } Before accessing this feature!`}</Typography>
      </>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      {/* <Header /> */}
      <br />
      <ResultHeader testDisplayName="View Library" path="/home-page" />
      <Container maxWidth="md">
        <ExamTabs value={selectedTab} onChange={handleSubjectSelect}>
          {allSubjects.map((label) => {
            return <ExamTab label={label.name} key={label.name} />;
          })}
        </ExamTabs>

        {/* {subject && subject !== "ALL" && ( */}
        <Paper elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="All resources" />
            <Tab label="Assignments" />
            <Tab label="Books" />
            <Tab label="Hints & Solutions" />
          </Tabs>
        </Paper>
        {/* )} */}
      </Container>
      <br />

      {isLoading ? (
        <NetworkProgress />
      ) : (
        <Container maxWidth="md">
          {assignmentWithoutTopic.length > 0
            ? assignmentWithoutTopic.sort(compare).map((item, index) => {
                if (item.assignment) {
                  return (
                    <>
                      <CustomListItem
                        className={classes.list}
                        title={
                          item.assignment.split("live_event_assignments/")
                            .length >= 2 &&
                          item.assignment.split("live_event_assignments/")[1]
                        }
                        count={index + 1}
                      >
                        {agent.includes("Android") && window.Android && (
                          <IconButton size="small">
                            <VisibilityIcon
                              onClick={() => {
                                window.Android.showPDF(item.assignment);
                              }}
                            />
                          </IconButton>
                        )}
                        &nbsp;
                        <IconButton
                          size="small"
                          onClickCapture={() => {
                            if (
                              navigator.userAgent.includes("Android") &&
                              window.Android
                            ) {
                              window.Android.downloadPDF(item.assignment);
                            } else {
                              handleOpenPDF(item.assignment);
                            }
                          }}
                        >
                          <GetAppIcon />
                        </IconButton>
                      </CustomListItem>
                      <br />
                    </>
                  );
                }
              })
            : null}

          {allAssignments.length === 0 &&
          assignmentWithoutTopic.length === 0 ? (
            <Typography className={classes.title}>
              Resources Not Available!
            </Typography>
          ) : (
            <Grid container direction="column" spacing={2}>
              {allAssignments.map((assignment) => (
                <Grid item key={assignment.live_url}>
                  <br />
                  <Typography className={classes.subtitle}>
                    {assignment.topic}
                  </Typography>
                  <br />
                  {assignment.videoassignments.map((item, index) => (
                    <CustomListItem
                      title={
                        item.split("live_event_assignments/").length >= 2 &&
                        item.split("live_event_assignments/")[1]
                      }
                      count={index + 1}
                    >
                      {agent.includes("Android") && window.Android && (
                        <IconButton size="small">
                          <VisibilityIcon
                            onClick={() => {
                              window.Android.showPDF(item);
                            }}
                          />
                        </IconButton>
                      )}
                      &nbsp;
                      {window.location.hostname ===
                      "vijna.onesaz.com" ? null : (
                        <IconButton
                          size="small"
                          onClick={() => handleOpenPDF(item)}
                        >
                          <GetAppIcon />
                        </IconButton>
                      )}
                    </CustomListItem>
                  ))}
                  <br />
                </Grid>
              ))}
            </Grid>
          )}
          <br />
          <br />
          <br />
        </Container>
      )}

      <BottomNav />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar color="secondary" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Assignment
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <Typography align="center" variant="caption">
          <b>Please update the app if the pdf is not downloading.</b>
        </Typography>

        <div
          dangerouslySetInnerHTML={{
            __html: `<!DOCTYPE html>
<html>
<head>
</head>
<body>
<iframe src=${doc} width=${dimensions.width} height=${
              dimensions.height
            } ></iframe>
</body>
</html>`,
          }}
        />
      </Dialog>
    </ThemeProvider>
  );
};

export default Assignment;
