import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import React from "react";

export default function ChipDispaly({
  sourceData,
  handleClick,
  // style,
  selectedQno,
}) {
  return (
    <Grid item xs={12}>
      {sourceData && sourceData.length > 0 ? (
        <Grid item container spacing={2}>
          {sourceData.map((cdata) => {
            return (
              <Grid item key={cdata.question_number}>
                <Chip
                  mode="outlined"
                  variant="rectangular"
                  style={{
                    backgroundColor:
                      cdata.question_number === selectedQno
                        ? "#3BBDED"
                        : cdata.status === "ANSWERED"
                        ? "green"
                        : "white",
                    borderRadius: 8,
                    color: cdata.status === "ANSWERED" ? "white" : "black",
                    border: "2px solid #3BBDED",
                  }}
                  label={cdata.question_number}
                  onClick={() => {
                    handleClick(cdata.question_number);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : null}
    </Grid>
  );
}
