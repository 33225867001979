// store
import store from "store";
import React, { useRef } from "react";
// mui
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useTheme, useMediaQuery } from "@material-ui/core";
// npm module
import { useReactToPrint } from "react-to-print";
// components
import logoURLMap from "../../../components/LogoURLMap";
// loadash
import startCase from "lodash/startCase";
// assests
import VinnersLogo from "../../../assets/VINNERS_RECIPT_LOGS.jpg";
import aiitvLogo from "../../../assets/aiitv1.jpg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// utils
import { PAYMENT_MODE } from "../../../GlobalConstants";
import { useStyles3 } from "../styles";


import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PrintIcon from "@material-ui/icons/Print";

const InvoiceBox = ({ data, balance, handleCloseDialog }) => {
  const classes = useStyles3();
  const ComponentRef = useRef();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const handlePrint = useReactToPrint({
    content: () => ComponentRef.current,
  });
  let feeRows;
  const totalAmount = Object.values(data.fees).reduce(
    (acc, curr) => Number(acc) + Number(curr),
    0
  );
  if (data) {
    feeRows = Object.entries(data.fees).map(([name, value], index) => {
      const feeType = startCase(name);
      return (
        <TableRow key={name} className={classes.FeeRowHeight}>
          <TableCell className={classes.feeFontHeaderStyle}>
            {index + 1}
          </TableCell>
          <TableCell className={classes.feeFontHeaderStyle}>
            {feeType}
          </TableCell>
          <TableCell className={classes.feeFontHeaderStyle}>{value}</TableCell>
        </TableRow>
      );
    });
  }
  const exportPDF = () => {
    const input = document.getElementById("resultsTable");
    const totalPages = Math.ceil(input.offsetHeight / input.clientHeight);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      let offset = 0;
      for (let i = 0; i < totalPages; i++) {
        const pageData = canvas.toDataURL("image/png", 1.0);
        pdf.addImage(pageData, "PNG", 10, 10 + offset, 190, 0);
        offset -= 235;
        if (i < totalPages - 1) {
          pdf.addPage();
        }
      }

      pdf.save("download.pdf");
    });
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        padding={1}
      >
        <Grid item xs={10}>
          <IconButton onClick={handleCloseDialog} startIcon={ArrowBackIcon}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handlePrint}>
            <PrintIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={exportPDF}>
            <PictureAsPdfIcon style={{ color: "red" }} />
          </IconButton>
        </Grid>
      </Grid>

{isDesktop?(  <Table ref={ComponentRef} id="resultsTable">
        <Box flexGrow={1} border={1} padding={2} margin={2}>
          {store.get("user").institute === "645b4332b98a5dc4ce54256d" ||
          store.get("user").institute === "61e6489a9b609f2143aab796" ? (
            <>
              <Box
                marginLeft={8}
                width="80px"
                flexGrow={0}
                src={
                  store.get("user").institute !== "61e6489a9b609f2143aab796"
                    ? logoURLMap[store.get("user").institute].url
                    : null
                }
                component="img"
              />
              <Box
                component="img"
                src={
                  store.get("user").institute === "61e6489a9b609f2143aab796"
                    ? aiitvLogo
                    : VinnersLogo
                }
                width={
                  store.get("user").institute === "61e6489a9b609f2143aab796"
                    ? null
                    : "50%"
                }
                height={
                  store.get("user").institute === "61e6489a9b609f2143aab796"
                    ? null
                    : "20%"
                }
                marginLeft={
                  store.get("user").institute === "61e6489a9b609f2143aab796"
                    ? 15
                    : 8
                }
              />
            </>
          ) : null}
          <Box sx={{ flexGrow: 1 }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Grid container>
                <Grid
                  item
                  xs={1}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {store.get("user").institute !== "645b4332b98a5dc4ce54256d" ||
                  store.get("user") !== "61e6489a9b609f2143aab796" ? (
                    <Box
                      display="flex"
                      paddingLeft={
                        store.get("user").institute !==
                          "645b4332b98a5dc4ce54256d" ||
                        store.get("user").institute !==
                          "61e6489a9b609f2143aab796"
                          ? 8
                          : null
                      }
                      width={
                        store.get("user").institute !==
                          "645b4332b98a5dc4ce54256d" ||
                        store.get("user").institute !==
                          "61e6489a9b609f2143aab796"
                          ? "150px"
                          : "50px"
                      }
                      height={
                        store.get("user").institute ===
                        "646634c8a400ecffc1306d0d"
                          ? "70%"
                          : "100%"
                      }
                      flexGrow={0}
                      src={
                        store.get("user").institute !==
                          "61e6489a9b609f2143aab796" &&
                        store.get("user").institute !==
                          "645b4332b98a5dc4ce54256d"
                          ? logoURLMap[store.get("user").institute].url
                          : null
                      }
                      component="img"
                    />
                  ) : null}
                </Grid>
                <Grid item xs={11}>
                  <Grid container>
                    <Grid item xs={12}>
                      {store.get("user").institute ===
                        "645b4332b98a5dc4ce54256d" ||
                      store.get("user").institute ===
                        "61e6489a9b609f2143aab796" ? null : (
                        <Typography variant="h6" align="center">
                          {logoURLMap[store.get("user").institute].title}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {store.get("user").institute ===
                        "645b4332b98a5dc4ce54256d" ||
                      store.get("user").institute ===
                        "61e6489a9b609f2143aab796" ? null : (
                        <Typography variant="button" align="center">
                          {logoURLMap[store.get("user").institute].subTitle}
                        </Typography>
                      )}
                    </Grid>
           

                    <Grid item xs={9}>
                      {store.get("user").institute !==
                        "645b4332b98a5dc4ce54256d" ||
                      store.get("user").institute !==
                        "61e6489a9b609f2143aab796" ? (
                        <Typography align="center" variant="body2" />
                      ) : null}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography align="center" fontWeight={600}>
                        Fee Receipt
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box>
            <Grid container display="flex">
              <Grid item xs={6}>
                <Table size="small" className={classes.Table}>
                  <TableRow className={classes.rowHeight}>
                    <TableCell className={classes.key}>Receipt No</TableCell>
                    <TableCell className={classes.fontstyle}>:</TableCell>
                    <TableCell className={classes.fontstyle}>
                      {data.txnId}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.rowHeight}>
                    <TableCell className={classes.key}>Name</TableCell>
                    <TableCell className={classes.fontstyle}>:</TableCell>
                    <TableCell className={classes.fontstyle}>
                      {store.get("user").name}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.rowHeight}>
                    <TableCell className={classes.key}>Admission No</TableCell>
                    <TableCell className={classes.fontstyle}>:</TableCell>
                    <TableCell className={classes.fontstyle}>
                      {store.get("user").userId}
                    </TableCell>
                  </TableRow>
                </Table>
              </Grid>
              <Grid item xs={6}>
                <Table size="small" className={classes.Table}>
                  <TableRow className={classes.rowHeight}>
                    <TableCell className={classes.key}>Class</TableCell>
                    <TableCell className={classes.fontstyle}>:</TableCell>
                    <TableCell className={classes.fontstyle}>
                      {store.get("user").classVal}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.rowHeight}>
                    <TableCell className={classes.key}>Date</TableCell>
                    <TableCell className={classes.fontstyle}>:</TableCell>
                    <TableCell className={classes.fontstyle}>
                      {new Date(data.createdAt).toDateString()}
                    </TableCell>
                  </TableRow>

                  {data.SID && (
                    <TableRow className={classes.rowHeight}>
                      <TableCell className={classes.key}>SID</TableCell>
                      <TableCell className={classes.fontstyle}>:</TableCell>
                      <TableCell className={classes.fontstyle} />
                    </TableRow>
                  )}

                  <TableRow className={classes.rowHeight}>
                    <TableCell className={classes.key}>Pay Mode </TableCell>
                    <TableCell className={classes.fontstyle}>:</TableCell>
                    <TableCell className={classes.fontstyle}>
                      {PAYMENT_MODE[data.paymentMode]}
                    </TableCell>
                  </TableRow>
                  {data.GSTIN && (
                    <TableRow className={classes.rowHeight}>
                      <TableCell className={classes.key}>GSTIN</TableCell>
                      <TableCell className={classes.fontstyle}>:</TableCell>
                      <TableCell className={classes.fontstyle} />
                    </TableRow>
                  )}
                </Table>
              </Grid>
            </Grid>
          </Box>

          <Table size="small">
            <TableRow className={classes.FeeRowHeight}>
              <TableCell className={classes.feeFontStyle}>S.No</TableCell>
              <TableCell className={classes.feeFontStyle}>Fee Type</TableCell>
              <TableCell className={classes.feeFontStyle}>Amount</TableCell>
            </TableRow>

            {feeRows}
            <TableRow>
              <TableCell
                className={classes.feeFontStyle}
                colSpan={2}
                align="right"
              >
                Total
              </TableCell>
              <TableCell className={classes.feeFontStyle}>
                {totalAmount}
              </TableCell>
            </TableRow>
          </Table>
          {/* <Grid item xs={12}>
            <Typography variant="button" fontWeight="bold" paddingLeft={1}>
              Remaining Balance To pay : {balance}
            </Typography>
          </Grid> */}
          <Grid container padding={1}>
            <Grid item container xs={6} justifyContent="flex-start">
              <Typography
                variant="caption"
                fontWeight="bold"
                textAlign="center"
              >
                Received by online payment
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} paddingLeft={1}>
            <Typography variant="button" fontWeight="bold" paddingTop={3}>
              Note:-
            </Typography>
            <Typography variant="caption" display="block">
              Parents are requested to preserve this receipt for future
              clarifications in respect of fee paid by you. Fee once paid will
              not be refunded or transferred.Cheques subject to realization.
            </Typography>
          </Grid>
        </Box>
      </Table>): <Table  id="resultsTable">
        <Box flexGrow={1} border={1} padding={2} margin={2}>
          {store.get("user").institute === "645b4332b98a5dc4ce54256d" ||
          store.get("user").institute === "61e6489a9b609f2143aab796" ? (
            <>
              <Box
                marginLeft={8}
                width="80px"
                flexGrow={0}
                src={
                  store.get("user").institute !== "61e6489a9b609f2143aab796"
                    ? logoURLMap[store.get("user").institute].url
                    : null
                }
                component="img"
              />
              <Box
                component="img"
                src={
                  store.get("user").institute === "61e6489a9b609f2143aab796"
                    ? aiitvLogo
                    : VinnersLogo
                }
                width={
                  store.get("user").institute === "61e6489a9b609f2143aab796"
                    ? null
                    : "50%"
                }
                height={
                  store.get("user").institute === "61e6489a9b609f2143aab796"
                    ? null
                    : "20%"
                }
                marginLeft={
                  store.get("user").institute === "61e6489a9b609f2143aab796"
                    ? 15
                    : 8
                }
              />
            </>
          ) : null}
          <Box sx={{ flexGrow: 1 }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Grid container>
                <Grid
                  item
                  xs={1}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {store.get("user").institute !== "645b4332b98a5dc4ce54256d" ||
                  store.get("user") !== "61e6489a9b609f2143aab796" ? (
                    <Box
                      display="flex"
                      paddingLeft={
                        store.get("user").institute !==
                          "645b4332b98a5dc4ce54256d" ||
                        store.get("user").institute !==
                          "61e6489a9b609f2143aab796"
                          ? 8
                          : null
                      }
                      width={
                        store.get("user").institute !==
                          "645b4332b98a5dc4ce54256d" ||
                        store.get("user").institute !==
                          "61e6489a9b609f2143aab796"
                          ? "150px"
                          : "50px"
                      }
                      height={
                        store.get("user").institute ===
                        "646634c8a400ecffc1306d0d"
                          ? "70%"
                          : "100%"
                      }
                      flexGrow={0}
                      src={
                        store.get("user").institute !==
                          "61e6489a9b609f2143aab796" &&
                        store.get("user").institute !==
                          "645b4332b98a5dc4ce54256d"
                          ? logoURLMap[store.get("user").institute].url
                          : null
                      }
                      component="img"
                    />
                  ) : null}
                </Grid>
                <Grid item xs={11}>
                  <Grid container>
                    <Grid item xs={12}>
                      {store.get("user").institute ===
                        "645b4332b98a5dc4ce54256d" ||
                      store.get("user").institute ===
                        "61e6489a9b609f2143aab796" ? null : (
                        <Typography variant="h6" align="center">
                          {logoURLMap[store.get("user").institute].title}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={9}>
                      {store.get("user").institute !==
                        "645b4332b98a5dc4ce54256d" ||
                      store.get("user").institute !==
                        "61e6489a9b609f2143aab796" ? (
                        <Typography align="center" variant="body2" />
                      ) : null}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography align="center" fontWeight={600}>
                        Fee Receipt
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box>
            <Grid container display="flex">
              <Grid item xs={12}>
                <Table size="small" className={classes.Table}>
                  <TableRow className={classes.rowHeight}>
                    <TableCell className={classes.key}>Receipt No</TableCell>
                    <TableCell className={classes.fontstyle}>:</TableCell>
                    <TableCell className={classes.fontstyle}>
                      {data.txnId}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.rowHeight}>
                    <TableCell className={classes.key}>Name</TableCell>
                    <TableCell className={classes.fontstyle}>:</TableCell>
                    <TableCell className={classes.fontstyle}>
                      {store.get("user").name}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.rowHeight}>
                    <TableCell className={classes.key}>Admission No</TableCell>
                    <TableCell className={classes.fontstyle}>:</TableCell>
                    <TableCell className={classes.fontstyle}>
                      {store.get("user").userId}
                    </TableCell>
                  </TableRow>
                </Table>
              {/* </Grid>
              <Grid item xs={6}>
                <Table size="small" className={classes.Table}> */}
                  <TableRow className={classes.rowHeight}>
                    <TableCell className={classes.key}>Class</TableCell>
                    <TableCell className={classes.fontstyle}>:</TableCell>
                    <TableCell className={classes.fontstyle}>
                      {store.get("user").classVal}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.rowHeight}>
                    <TableCell className={classes.key}>Date</TableCell>
                    <TableCell className={classes.fontstyle}>:</TableCell>
                    <TableCell className={classes.fontstyle}>
                      {new Date(data.createdAt).toDateString()}
                    </TableCell>
                  </TableRow>

                  {data.SID && (
                    <TableRow className={classes.rowHeight}>
                      <TableCell className={classes.key}>SID</TableCell>
                      <TableCell className={classes.fontstyle}>:</TableCell>
                      <TableCell className={classes.fontstyle} />
                    </TableRow>
                  )}

                  <TableRow className={classes.rowHeight}>
                    <TableCell className={classes.key}>Pay Mode </TableCell>
                    <TableCell className={classes.fontstyle}>:</TableCell>
                    <TableCell className={classes.fontstyle}>
                      {PAYMENT_MODE[data.paymentMode]}
                    </TableCell>
                  </TableRow>
                  {data.GSTIN && (
                    <TableRow className={classes.rowHeight}>
                      <TableCell className={classes.key}>GSTIN</TableCell>
                      <TableCell className={classes.fontstyle}>:</TableCell>
                      <TableCell className={classes.fontstyle} />
                    </TableRow>
                  )}
                {/* </Table> */}
              </Grid>
            </Grid>
          </Box>

          <Table size="small">
            <TableRow className={classes.FeeRowHeight}>
              <TableCell className={classes.feeFontStyle}>S.No</TableCell>
              <TableCell className={classes.feeFontStyle}>Fee Type</TableCell>
              <TableCell className={classes.feeFontStyle}>Amount</TableCell>
            </TableRow>

            {feeRows}
            <TableRow>
              <TableCell
                className={classes.feeFontStyle}
                colSpan={2}
                align="right"
              >
                Total
              </TableCell>
              <TableCell className={classes.feeFontStyle}>
                {totalAmount}
              </TableCell>
            </TableRow>
          </Table>
          {/* <Grid item xs={12}>
            <Typography variant="button" fontWeight="bold" paddingLeft={1}>
              Remaining Balance To pay : {balance}
            </Typography>
          </Grid> */}
          <Grid container padding={1}>
            <Grid item container xs={6} justifyContent="flex-start">
              <Typography
                variant="caption"
                fontWeight="bold"
                textAlign="center"
              >
                Received by online payment
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} paddingLeft={1}>
            <Typography variant="button" fontWeight="bold" paddingTop={3}>
              Note:-
            </Typography>
            <Typography variant="caption" display="block">
              Parents are requested to preserve this receipt for future
              clarifications in respect of fee paid by you. Fee once paid will
              not be refunded or transferred.Cheques subject to realization.
            </Typography>
          </Grid>
        </Box>
      </Table>}
    
     
    </>
  );
};
export default InvoiceBox;
