import axios from "axios";

import API_CONST from "../constants";

var baseAxios = axios.create({
  baseURL: API_CONST.ANALYTICS_URL ,
  //port : API_CONST.PORT,
  //timeout: 1000,

  headers: {'Content-Type': 'application/json'},
  withCredentials: true,
});

export default baseAxios;
