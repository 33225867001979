import * as dateFns from "date-fns";

export const getDaysInMonth = (year, month) => {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const getEmptySlots = (firstDay) => {
  const emptySlots = [];
  for (let i = 0; i < firstDay; i++) {
    emptySlots.push(i);
  }
  return emptySlots;
};

export const getFirstDayOfMonth = (year, month) => {
  return new Date(year, month, 1).getDay();
};

export const getLast12Months = () => {
  const months = [];
  const today = new Date();
  for (let i = 0; i < 12; i++) {
    const month = new Date(today.getFullYear(), today.getMonth() - i, 1);
    months.push({
      label: dateFns.format(month, "MMMM yyyy"),
      year: month.getFullYear(),
      month: month.getMonth()
    });
  }
  return months.reverse();
};
