// react
import React, { useState, useEffect } from "react";
// mui
import {
  Table,
  TableBody,
  TableHead,
  Paper,
  TableContainer,
  Box,
  Button,
  Dialog,
} from "@material-ui/core";
// loadash
import startCase from "lodash/startCase";
// components
import {
  CustomTableRow,
  CustomTableCellType3,
} from "../components/CustomTableCells";
// styles
import { useStyles } from "../Dashboard/Components/styles";
import { RemoveRedEyeTwoTone } from "@material-ui/icons";
import InvoiceBox from "./components/InvoiceBox";




const MuiTable = ({ balance, transactions }) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  // console.log(stuFeeData);
  // const [transactions, setTransactions] = useState([]);

  const [invoiceData, setInvoiceData] = useState([]);

  const handleClick = (item) => {
    setInvoiceData(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const t = transactions.filter((item) => item.status === "Ok");


  // const grandTotal = data
  //   .reduce((total, item) => total + parseFloat(item.amount), 0)
  //   .toFixed(2);

  // useEffect(() => {
  //   if (stuFeeData) setTransactions(stuFeeData.transactions);
  // }, [stuFeeData]);
  return (
    <Box padding={1}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <CustomTableRow className={classes.tr}>
              <CustomTableCellType3 className={classes.tableHeaderStyle}>
                Transaction Id
              </CustomTableCellType3>
              <CustomTableCellType3 className={classes.tableHeaderStyle}>
                Admission No
              </CustomTableCellType3>
              <CustomTableCellType3 className={classes.tableHeaderStyle}>
                payment Mode
              </CustomTableCellType3>
              <CustomTableCellType3 className={classes.tableHeaderStyle}>
                Fee Type
              </CustomTableCellType3>
              <CustomTableCellType3 className={classes.tableHeaderStyle}>
                Amount
              </CustomTableCellType3>
              <CustomTableCellType3 className={classes.tableHeaderStyle}>
                Total
              </CustomTableCellType3>
              <CustomTableCellType3 className={classes.tableHeaderStyle}>
                Status
              </CustomTableCellType3>
            </CustomTableRow>
          </TableHead>
          <TableBody>
            {transactions &&
              transactions
                .filter((item) => item.status === "Ok")
                .map((item, index) => {
                  const feeTypeCount = Object.keys(item.fees).length;
                  let isFirstRow = true;

                  return (
                    <>
                      {Object.keys(item.fees).map((feeType, i) => {
                        const rowSpan = isFirstRow ? feeTypeCount : 0;
                        isFirstRow = false;

                        return (
                          <CustomTableRow key={`${index}-${i}`}>
                            {i === 0 && (
                              <CustomTableCellType3 rowSpan={rowSpan}>
                                {item.txnId}
                              </CustomTableCellType3>
                            )}
                            {i === 0 && (
                              <CustomTableCellType3 rowSpan={rowSpan}>
                                {item.customerId}
                              </CustomTableCellType3>
                            )}
                            {i === 0 && (
                              <CustomTableCellType3 rowSpan={rowSpan}>
                                {item.paymentMode}
                              </CustomTableCellType3>
                            )}
                            <CustomTableCellType3>
                              {startCase(feeType)}
                            </CustomTableCellType3>
                            <CustomTableCellType3>
                              {item.fees[feeType].toLocaleString("en-IN")}
                            </CustomTableCellType3>
                            {i === 0 && (
                              <CustomTableCellType3 rowSpan={rowSpan}>
                                {item.amount.toLocaleString("en-IN")}
                              </CustomTableCellType3>
                            )}
                            {i === 0 && (
                              <CustomTableCellType3 rowSpan={rowSpan}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  startIcon={<RemoveRedEyeTwoTone />}
                                  size="small"
                                  onClick={() => handleClick(item)}
                                >
                                  View
                                </Button>
                              </CustomTableCellType3>
                            )}
                            <CustomTableCellType3>
                              {item.status}
                            </CustomTableCellType3>
                          </CustomTableRow>
                        );
                      })}
                    </>
                  );
                })}
            {/* <CustomTableRow className={classes.tr}>
              <CustomTableCellType3
                colSpan={4}
                className={classes.grandTotalColor}
              >
                Grand Total
              </CustomTableCellType3> */}
              {/* <CustomTableCellType3 className={classes.grandTotalColor}>
                {grandTotal.toLocaleString("en-IN")}
              </CustomTableCellType3>
              <CustomTableCellType3 className={classes.grandTotalColor}>
                {grandTotal.toLocaleString("en-IN")}
              </CustomTableCellType3>
              <CustomTableCellType3 /> */}
            {/* </CustomTableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openDialog} fullScreen>
        {invoiceData.length !== 0 && (
          <InvoiceBox
            data={invoiceData}
            handleCloseDialog={handleCloseDialog}
            balance={balance}
          />
        )}
      </Dialog>
    </Box>
  );
};

export default MuiTable;
