import React, { useEffect, useState } from "react";
import PasswordChange from "./PasswordChange";
import store from "store";
import { useHistory } from "react-router-dom";
function MatchedPwd() {
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    if (store.get("matched")) {
      setOpen(true);
    } else {
      setOpen(false);
      history.push("/home-page");
    }
  };
  useEffect(() => {
    store.get("matched") && setOpen(true);
  }, [open]);

  return (
    <React.Fragment>
      <PasswordChange open={open} handleClose={handleClose} />
    </React.Fragment>
  );
}

export default MatchedPwd;
