const AttemptStatus = {
  bookmarked: "Bookmarked",
  skipped: "Skipped",
  correct: "Correct",
  wrong: "Wrong",
  unattempted: "Not Attempted",
};
const QuestionType = {
  single: "Single MCQ",
  multiple: "Multiple MCQ",
  integer: "Integer Type",
};
const filterQuestion = (allQuestions, query) => {
  if (query.length === 0) {
    return allQuestions;
  }
  const finalData = allQuestions.filter((item) => {
    for (let value of query) {
      if (item[value]) return true;
      if (
        value === "skipped" &&
        (item.markedAns === " " || item.markedAns === "")
      )
        return true;
      if (
        value === "correct" &&
        item.status !== undefined &&
        item.status === true
      )
        return true;
      if (
        value === "wrong" &&
        item.status !== undefined &&
        item.status === false &&
        /\S/.test(item.markedAns)
      )
        return true;
      if (value === "unattempted" && item.status === undefined) return true;
      if (item.questionType === value) return true;
    }
    return false;
  });
  return finalData;
};
export { AttemptStatus, QuestionType, filterQuestion };
