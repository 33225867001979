import {
  Container,
  Dialog,
  DialogTitle,
  List,
  ThemeProvider,
  Typography,
  Divider,
} from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router-dom";
import store from "store";
import theme from "../../assets/theme";
import Header from "../Header";
import UserAccountCard from "./UserAccountCard";

const ChildrenAccounts = (props) => {
  const { students } = store.get("user");
  const ids = students.split("_") || [];
  return (
    <ThemeProvider theme={theme}>
      {/* <Header /> */}
      <Container maxWidth="md">
        <Dialog aria-labelledby="simple-dialog-title" open={true}>
          <DialogTitle id="simple-dialog-title">
            Select your ward's account
          </DialogTitle>
          <List>
            {ids.length === 0 ? (
              <Typography align="center" color="error">
                No Students Mapped
              </Typography>
            ) : (
              ids.map((id) => <UserAccountCard key={id} id={id} {...props} />)
            )}
            <Divider component="li" />
            <Typography
              variant="caption"
              style={{ float: "right" }}
              color="textSecondary"
            >
              &emsp;Welcome to parent's portal.&emsp;
            </Typography>
          </List>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
};

export default withRouter(ChildrenAccounts);
