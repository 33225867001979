const institutesLogo = {
  "bhashyam.onesaz.com":
    "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/bhashyam_mini.png",
  "miity.onesaz.com":
    "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/miity_mini.png",
  "sr.onesaz.com":
    "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/sr_mini.png",
  "sreducation.onesaz.com":
    "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/sr_mini.png",
  "sriabhidakp.onesaz.com":
    "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/sriabhidakp_mini.png",
  "valleyoak.onesaz.com":
    "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/valleyoak_mini.png",
  "onesaz.com":    "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/onesaz_mini.png",
    "pinegrove.onesaz.com":"https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/pinegrove_mini.png",
    "neutrinoacademy.onesaz.com":"https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/neutrinoacademy_mini.png",
"ayati.onesaz.com":"https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/ayati_mini.png",
"ybrant.onesaz.com":"https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/ybrant.jpg",
"vijna.onesaz.com":'https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/vijna_mini.png',
"sundar.onesaz.com":'https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/sundar_mini.png',
"cognizant.onesaz.com":'https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/cognizant_mini.png',


};
export default institutesLogo;
