import { call, put, takeLatest } from "redux-saga/effects";
import { editUserProfile } from "../../../apis/UserService";
import {
  editProfileLoading,
  editProfileSuccess,
  catchErrorEditProfile,
} from "./actionCreator";

function* editProfile(api_payload) {
  try {
    const userData = yield call(editUserProfile, api_payload);
    const result = yield userData.data;
    yield put(editProfileSuccess(result.output));
  } catch (error) {
    yield put(catchErrorEditProfile(error.message));
  }
}

function* editProfileSagaWatcher() {
  yield takeLatest(editProfileLoading().type, editProfile);
}

export default editProfileSagaWatcher;
