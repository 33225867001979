import axios from "./examServerAxios";
import store from "store";

export async function getAllNotifications(body) {
  try {
    const user = store.get("user") || {};
    const { userId = "", institute = "", instituteId = "" } = user;
    const formattedUserId = `${institute || instituteId}_${userId}`;

    let data = await axios.post("/analysis/getnotifications", {
      ...body,
      userId: formattedUserId,
    });
    return data;
  } catch (error) {
    return error;
  }
}

export async function getRocketChat(body) {
  try {
    let data = await axios.post("/chat/chatLogin", body);
    return data.data;
  } catch (error) {
    return error;
  }
}

export async function getRocketChatSSO(body) {
  try {
    let data = await axios.post("/chat/chatSSO", body);
    return data.data;
  } catch (error) {
    return error;
  }
}
