import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
// @material-ui/core components
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Modal from "@material-ui/core/Modal";
import withStyles from "@material-ui/core/styles/withStyles";
import Check from "@material-ui/icons/Check";
import Phone from "@material-ui/icons/Email";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import contactus from "assets/img/contactus.svg";
import mail from "assets/v2/mail.png";
import map from "assets/v2/map.png";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import React from "react";
// react components used to create a google map
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
// import Grid from "@material-ui/core/Grid";
import SimplifiedApp from "./SimplifiedApp.jsx";
import "./SectionContacts.css";
import companyLocation from "./logos/companyLocation.png";

// const theme = createMuiTheme({
//   onesazBg: {
//     background: "rgba(236, 240, 243, 0.8)",
//   },
// });
// eslint-disable-next-line
const customInp = withStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  "&::placeholder": {
    color: "green",
  },
})(InputBase);

const style = (theme) => ({
  contact: {
    backgroundColor: "rgba(236, 240, 243, 0.8)",
    minHeight: "100vh",
    color: theme.onesazBg,
  },
  heading: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "45px",
    lineHeight: "44px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#31456A",
  },
  subheading: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#31456A",
  },
  text: {
    // font-family: Montserrat;
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "200%",

    // /* or 36px */

    // display: flex;
    // align-items: center;

    color: "rgba(49, 69, 106, 0.7)",
  },
  elevation: {
    width: "60px",
    height: "60px",
    background: "#EFF2F4",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    borderRadius: "197px",
  },
  bubble: {
    width: "24px",
    height: "24px",
    background: "#3BBDED",
    borderRadius: "50%",
    marginLeft: "auto",
  },
  contactForm: {
    background: "#EFF2F4",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    borderRadius: "30px",
  },
  input: {
    "&::placeholder": {
      color: "blue",
    },
  },
  textField: {
    //  '::placeholder':{

    //     marginLeft:'5px'

    //  },

    border: "1px solid #F7F8F9",
    background: "#F8F8F9",
    boxShadow: "inset 2px 2px 5px #D1D9E6",
    borderRadius: "12px",
    width: "100%",
    height: "50px",
  },
  horizLine: {
    width: "55px",
    height: "3px",
    background: "#08C5A7",
    borderRadius: "5px",
  },
  sendMessage: {
    height: "56px",
    width: "200px",
    background: "#08C5A7",
    boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
    borderRadius: "16px",
    // fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "100%",
    /* or 18px */
    textAlign: "center",
    color: "#FFFFFF",
    "&:hover": {
      background: "#0eab8f",
    },
  },
});
// eslint-disable-next-line
const RegularMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 44.43353, lng: 26.093928 - 0.025 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }],
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }],
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }],
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }],
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }],
          },
        ],
      }}
    >
      <Marker position={{ lat: 44.43353, lng: 26.093928 }} />
    </GoogleMap>
  ))
);

class SectionContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      open: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleChange(key, event) {
    this.setState({ [key]: event.target.value });
  }
  handleSubmit = (event) => {
    // console.log(event)
    // alert('A name was submitted: ' + this.state.firstname +"  "+ this.state.lastname);
    // eslint-disable-next-line
    const scriptURL =
      "https://script.google.com/macros/s/AKfycbykHgTSfOxAaTasWKF26-1Ulcrzz2veYd3MVLBJsUu8g2FY95Iw/exec?name=" +
      this.state.firstname;
    //  var url = new URL("https://script.google.com/macros/s/AKfycbykHgTSfOxAaTasWKF26-1Ulcrzz2veYd3MVLBJsUu8g2FY95Iw/exec?");
    var url = new URL(
      "https://script.google.com/macros/s/AKfycbzjoUNPPTQwLyJyU8Sf6ARL6nR5XJEYzaUC1QXGsi4OTzjD_b4/exec?"
    );
    url.searchParams.append("firstName", this.state.firstName);
    url.searchParams.append("lastName", this.state.lastName);
    url.searchParams.append("emailId", this.state.emailId);
    url.searchParams.append("message", this.state.message);
    // url.searchParams.append('branch',this.state.branch);
    url.searchParams.append("sheet", "Sheet1");
    this.setState({
      netReq: true,
      open: true,
    });
    fetch(url, {
      method: "GET",
    })
      .then((response) => {
        console.log("Success!", response);
        this.setState({
          netReq: false,
        });
        // alert('We will get back to you shortly')
      })
      .catch((error) => console.error("Error!", error.message));
    event.preventDefault();
  };
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }
  render1() {
    // eslint-disable-next-line
    const { classes, ...rest } = this.props;
    return (
      <div className="cd-section" {...rest}>
        {/* Contact us 1 START */}
        <div
          className={`${classes.contacts} ${classes.section}`}
          style={{ backgroundImage: `url(${contactus})` }}
        >
          <div className={classes.container}>
            <Grid container>
              <Grid item xs={12} sm={5} md={5}>
                <h2 className={classes.title}>Get in Touch</h2>
                <h5 className={classes.description}>
                  If you have any questions about the product or confused about
                  which plan to select. Contact us
                </h5>
                <InfoArea
                  className={classes.infoArea}
                  title="Find us at the office"
                  description={
                    <h5>
                      H.NO: 1-1-21/39, Plot No. 21, Road No. 6, Near Community
                      Hall,
                      <br />
                      Ram Naresh Nagar, HyderNagar,
                      <br /> Kukatpally, Hyderabad-500085.
                      <br /> <strong> Karnataka contact.</strong>
                      <br />
                      1. CH. Samba Siva Rao (Principal P.U college) Mob:
                      9663949655
                      <br />
                      2. P Nagama Naidu (P.U Education Counsellor) Mob:
                      9008896086
                    </h5>
                  }
                  icon={PinDrop}
                />
                <InfoArea
                  className={classes.infoArea}
                  title="Send us mail"
                  description={<h4>support@onesaz.com</h4>}
                  icon={Phone}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={5} className={classes.mlAuto}>
                <Card className={classes.card1}>
                  <form onSubmit={this.handleSubmit}>
                    <CardHeader
                      contact
                      color="primary"
                      className={classes.textCenter}
                    >
                      <h4 className={classes.cardTitle}>Contact Us</h4>
                    </CardHeader>
                    <CardBody>
                      <Grid container>
                        <Grid item xs={12} sm={6} md={6}>
                          <CustomInput
                            labelText="First Name"
                            id="first"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: this.state.firstName,
                              onChange: (e) =>
                                this.handleChange("firstName", e),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <CustomInput
                            labelText="Last Name"
                            id="last"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: this.state.lastName,
                              onChange: (e) => this.handleChange("lastName", e),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <CustomInput
                        labelText="Email Address"
                        id="email-address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: this.state.emailId,
                          onChange: (e) => this.handleChange("emailId", e),
                        }}
                      />
                      <CustomInput
                        labelText="Your Message"
                        id="message"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          multiline: true,
                          rows: 5,
                          value: this.state.message,
                          onChange: (e) => this.handleChange("message", e),
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.justifyContentBetween}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => this.handleToggle(1)}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{ label: classes.label }}
                        label="I'm not a robot"
                      />
                      <Button
                        color="primary"
                        className={classes.pullRight}
                        type="submit"
                      >
                        Send Message
                      </Button>
                      <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.open}
                        onClose={this.handleClose}
                      >
                        <div
                          style={{
                            verticalAlign: "middle",
                            borderRadius: "10px",
                            marginTop: "18%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            padding: "40px",
                            background: "white",
                            width: "400px",
                            height: "200px",
                          }}
                          className={classes.paper}
                        >
                          {this.state.netReq ? (
                            <div
                              style={{
                                padding: "30px",
                                marginLeft: "30%",
                              }}
                            >
                              <CircularProgress color="secondary" /> <br />
                              Sending....
                            </div>
                          ) : (
                            <React.Fragment>
                              <h3 id="simple-modal-title">
                                We received your message
                              </h3>
                              <p
                                style={{
                                  padding: "10px 20px",
                                }}
                              >
                                We will get back to you shortly !!
                              </p>
                            </React.Fragment>
                          )}
                          {/* <SimpleModal /> */}
                        </div>
                      </Modal>
                    </CardFooter>
                  </form>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
        {/* Contact us 1 END */}
      </div>
    );
  }

  render() {
    const { classes, ...rest } = this.props;
    console.log(classes);
    return (
      <div className="cd-section" {...rest} style={{ minHeight: "110vh" }}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.contact}
        >
          <Grid item xs={12}>
            <br />
            <br />
            <br />
            <br />
            <Grid container justify="center" direction="row">
              {/* <Grid item xs={1} /> */}
              <Grid
                item
                xs={12}
                lg={5}
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <Grid
                  container
                  justify="flex-start"
                  alignItems="flex-start"
                  direction="column"
                >
                  <Grid item xs>
                    <Typography
                      variant="h1"
                      component="h1"
                      className={classes.heading}
                    >
                      Let's Talk
                    </Typography>
                    <br />
                    <span className={classes.text}>
                      You need more information? Contact us.
                    </span>
                  </Grid>
                  <br />
                  <Grid item xs={12} sm={10} md={8} lg={10}>
                    <form onSubmit={this.handleSubmit}>
                      <Grid
                        container
                        className={classes.contactForm}
                        justify="space-between"
                        alignItems="center"
                        direction="column"
                      >
                        <Grid item xs>
                          <br /> <br />
                          <Typography
                            variant="h1"
                            component="h1"
                            className={classes.heading}
                            style={{ fontSize: "30px", lineHeight: "37px" }}
                          >
                            Contact us
                          </Typography>
                          <br />
                        </Grid>
                        <Grid item xs>
                          <div className={classes.horizLine} />
                          <br />
                        </Grid>
                        <Grid item xs={10} style={{ width: "inherit" }}>
                          <Grid
                            container
                            direction="row"
                            justify="space-evenly"
                          >
                            <Grid item xs={5}>
                              <InputBase
                                className={classes.textField}
                                placeholder="First Name"
                                id="first"
                                fullWidth={true}
                                inputProps={{
                                  value: this.state.firstName,
                                  onChange: (e) =>
                                    this.handleChange("firstName", e),
                                }}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <InputBase
                                className={classes.textField}
                                placeholder="Last Name"
                                id="last"
                                inputProps={{
                                  value: this.state.lastName,
                                  onChange: (e) =>
                                    this.handleChange("lastName", e),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={9} style={{ width: "inherit" }}>
                          <br /> <br />
                          <InputBase
                            className={classes.textField}
                            type="email"
                            placeholder="Email Address"
                            fullWidth={true}
                            id="email-address"
                            onChange={(e) => this.handleChange("emailId", e)}
                            value={this.state.emailId}
                          />
                        </Grid>
                        <Grid item xs={9} style={{ width: "inherit" }}>
                          <br /> <br />
                          <InputBase
                            className={classes.textField}
                            style={{ height: "inherit", marginBottom: "40px" }}
                            placeholder="Your message"
                            id="message"
                            fullWidth={true}
                            multiline
                            inputProps={{
                              classes: { input: classes.input },
                              rows: 8,
                              value: this.state.message,
                              onChange: (e) => this.handleChange("message", e),
                            }}
                          />
                        </Grid>
                        <Grid item xs>
                          <Button
                            className={classes.sendMessage}
                            style={{
                              marginBottom: "40px",
                              textTransform: "none",
                            }}
                            type="submit"
                          >
                            Send Message
                          </Button>
                          <br />
                          <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.open}
                            onClose={this.handleClose}
                          >
                            <div
                              style={{
                                verticalAlign: "middle",
                                borderRadius: "10px",
                                marginTop: "18%",
                                marginLeft: "auto",
                                marginRight: "auto",
                                padding: "40px",
                                background: "white",
                                width: "400px",
                                height: "200px",
                              }}
                              className={classes.paper}
                            >
                              {this.state.netReq ? (
                                <div
                                  style={{ padding: "30px", marginLeft: "30%" }}
                                >
                                  <CircularProgress color="primary" /> <br />
                                  Sending....
                                </div>
                              ) : (
                                <React.Fragment>
                                  <h3 id="simple-modal-title">
                                    We received your message
                                  </h3>
                                  <p style={{ padding: "10px 20px" }}>
                                    We will get back to you shortly !!
                                  </p>
                                </React.Fragment>
                              )}
                              {/* <SimpleModal /> */}
                            </div>
                          </Modal>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>

                  <div className={classes.bubble} />
                  {/* <Grid item xs>
                  <br /> <br />
                  <div
                    className={classes.elevation}
                    style={{ textAlign: "center" }}
                  >
                    <img alt="mail" src={mail} style={{ marginTop: "25%" }} />
                  </div>
                  <br />
                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.subheading}
                  >
                    Send us mail
                  </Typography>
                  <div
                    className={classes.text}
                    style={{
                      marginTop: "18px",
                      marginBottom: "20px",
                      fontWeight: "500",
                    }}
                  >
                    support@onesaz.com
                  </div>
                  <br />
                </Grid> */}
                </Grid>
              </Grid>
              <Grid
                xs={12}
                lg={3}
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Grid item xs>
                  <img src={companyLocation} alt="location" />
                </Grid>
                <Grid item xs>
                  <br />

                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.subheading}
                  >
                    <img
                      alt="map"
                      src={map}
                      style={{ width: "70px", height: "70px" }}
                    />
                    Find us at the office
                  </Typography>
                  <p
                    className={classes.text}
                    style={{ marginTop: "10px", marginLeft: "20px" }}
                  >
                    H.NO: 1-1-21/39, Plot No. 21, Road No. 6, Near Community
                    Hall, Ram Naresh Nagar, HyderNagar, Kukatpally, Hyderabad,
                    500085.
                  </p>
                </Grid>
              </Grid>
              {/* <Grid item xs={1} /> */}
            </Grid>
          </Grid>
        </Grid>
        <br />
        <br />
        <SimplifiedApp />
      </div>
    );
  }
}

export default withStyles(style)(SectionContacts);
