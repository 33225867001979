import {
  makeStyles,
  Table,
  TableContainer,
  Paper,
  IconButton,
  Typography,
  Grid,
} from "@material-ui/core";
import React from "react";
import store from "store";
import { withRouter } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import StyledDataInputField from "../../components/StyledDataField";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  downloadBtn: {
    color: "#FFFFFF !important",
    border: "none",
    background: "#3BBDED",
    borderRadius: "50%",
    cursor: "pointer",
    float: "right",
  },
  table: {
    // minWidth: 900,
    // maxWidth:1200,

    borderCollapse: "collapse",
    overflowX: "auto",
    "& td, th": {
      border: "1px solid #ccc", // Set default border for all cells
      textAlign: "center",
      padding: "10px",
    },
    "& td:nth-child(3n), th:nth-child(3n)": {
      borderRight: "3px solid #ccc", // Set thick border after every 3rd column
    },
  },
}));
const DownloadTable = ({
  tableId,
  tableName,
  ref,
  children,
  searchDate,
  handleDateSearch,
  search,
  title,
  dateValue,
  setDateValue,
  formattedDate,
  component,
  alignDownloadButtons,
  ...props
}) => {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  const handleClickBAck = () => {
    props.history.go(-1);
  };
  const exportPDF = () => {
    const input = document.getElementById("resultsTable");
    const totalPages = Math.ceil(input.offsetHeight / input.clientHeight);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      let offset = 0;
      for (let i = 0; i < totalPages; i++) {
        const pageData = canvas.toDataURL("image/png", 1.0);
        pdf.addImage(pageData, "PNG", 10, 10 + offset, 190, 0);
        offset -= 235;
        if (i < totalPages - 1) {
          pdf.addPage();
        }
      }

      pdf.save("download.pdf");
    });
  };

  return (
    <>
      {alignDownloadButtons && (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid item xs={10} />
          {matches && (
            <Grid item xs={2}>
              <IconButton onClick={exportPDF}>
                <ReactHTMLTableToExcel
                  id="download-table-xls-button"
                  className={classes.downloadBtn}
                  table="resultsTable"
                  filename={store.get("user").userId}
                  sheet={store.get("user").userId}
                  buttonText={<CloudDownloadIcon />}
                />
              </IconButton>
              <IconButton onClick={exportPDF}>
                <PictureAsPdfIcon style={{ color: "red" }} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}
      <Grid container item xs={12} justifyContent="space-between">
        {title ? (
          <Grid item xs={12} sm={6}>
            <Typography
              variant="button"
              color="primary"
              // style={{ marginBottom: 5 }}
            >
              {title ? title : null}
            </Typography>
          </Grid>
        ) : null}
        {component ? (
          <Grid item xs={12} sm={10}>
            {component ? component : null}
          </Grid>
        ) : null}

        {search && (
          <Grid item xs={12} sm={3}>
            <>
              <Typography variant="caption"> select Date</Typography>
              <StyledDataInputField
                value={dateValue}
                id="dateValue"
                max={formattedDate}
                handleChange={(event) => {
                  setDateValue(event.target.value);
                }}
              />
            </>
          </Grid>
        )}
        {!alignDownloadButtons&&matches && (
          <Grid item xs={12} sm={2}>
            <IconButton onClick={exportPDF}>
              <ReactHTMLTableToExcel
                id="download-table-xls-button"
                className={classes.downloadBtn}
                table="resultsTable"
                filename={store.get("user").userId}
                sheet={store.get("user").userId}
                buttonText={<CloudDownloadIcon />}
              />
            </IconButton>
            <IconButton onClick={exportPDF}>
              <PictureAsPdfIcon style={{ color: "red" }} />
            </IconButton>
          </Grid>
        )}
      </Grid>
      &nbsp;
      <TableContainer component={Paper}>
        <Table
          id={tableId}
          ref={ref}
          // className={classes.table}
          aria-label="a dense table"
          style={{
            // minWidth: "300px",
            border: "1px solid #ccc",
            width: "100%",
            // maxWidth: "900px",
            // overflowX: "auto",
          }}
          size="small"
        >
          {children}
        </Table>
      </TableContainer>
    </>
  );
};

export default withRouter(DownloadTable);
