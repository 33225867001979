const getSubjects = (classSubjects) => {
  const allSubjects = Object.values(classSubjects)
    .map((item) => item.subjects)
    .reduce((a, b) => a.concat(b), []);
  const key = "name";
  // get unique subjects
  return [...new Map(allSubjects.map((item) => [item[key], item])).values()];
};
export const allSubjects = [
  {
    name: "ALL",
    value: "ALL",
  },
  {
    name: "MATHEMATICS",
    value: "M",
  },
  {
    name: "PHYSICS",
    value: "P",
  },
  {
    name: "CHEMISTRY",
    value: "C",
  },
  {
    name: "BOTANY",
    value: "B",
  },
  {
    name: "ZOOLOGY",
    value: "Z",
  },
  {
    name: "BIOLOGY",
    value: "BIO",
  },
  {
    name: "ENGLISH",
    value: "E",
  },
  {
    name: "SANSKRIT",
    value: "S",
  },
  {
    name: "TELUGU",
    value: "TEL",
  },
  {
    name: "HINDI",
    value: "H",
  },
  {
    name: "SCIENCE",
    value: "GSC",
  },
  {
    name: "CIVICS",
    value: "CIV",
  },
  {
    name: "COMMERCE",
    value: "COM",
  },
  {
    name: "ECONOMICS",
    value: "ECO",
  },
  {
    name: "PHYSICAL SCIENCE",
    value: "PSC",
  },
  { name: "SOCIAL", value: "SOC" },
  { name: "GENERAL", value: "GSC" },
];
export default getSubjects;
