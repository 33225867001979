import { Button, Grid, makeStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { capitalize } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import theme from "../../assets/theme";
import { AccordionDetails, AccordionSummary } from "./MuiAccordion";
import SubtopicList from "./SubtopicList";
import store from "store";
import { useDispatch } from "react-redux";
import { saveQueryToStore } from "../../redux/actions/practicePageActions/actionCreators";

const useStyle = makeStyles(() => ({
  textScroll: {
    overflow: "hidden",
    width: "45vw",
    height: "64px",
    verticalAlign: "middle",
    lineHeight: "64px",
  },
  textScrollSubTopic: {
    overflow: "hidden",
    width: "30vw",
    verticalAlign: "middle",
  },
  accordionMargin: {
    borderRadius: "10px",
    boxShadow: "none",
    marginBottom: "10px",
    border: "none",
    "&:before": {
      background: "none",
    },
  },
  countNumber: {
    backgroundColor: theme.palette.background.paper,
    width: "52px",
    height: "64px",
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: "64px",
    borderRadius: `${theme.shape.borderRadius}`,
  },
}));

function TopicList({ count, titleName, subTopics, subject, percentData }) {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  let user = store.get("user");
  const handleOnClick = (event, topic = "", subTopic = "") => {
    const body = {
      student_id: user._id,
      instituteId: "5d679d49c136660a09596d85", //user.institute,
      subject: subject,
      topic: topic,
      subTopic: subTopic,
    };
    store.set("practice" + subject + "topic", topic);
    store.set("practice" + subject + "subTopic", subTopic);
    dispatch(saveQueryToStore(body));
    history.push(`/practice/${subject}`, body);
  };
  return (
    <React.Fragment>
      {subTopics[0] === "EMPTY" ? (
        <div className={classes.accordionMargin}>
          <AccordionSummary
            expandIcon={
              store.get("practice" + store.get("practiceSubject") + "topic") ===
              titleName ? (
                <Button
                  disableRipple
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={(event) => handleOnClick(event, titleName)}
                >
                  Resume
                </Button>
              ) : (
                <Button
                  disableRipple
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={(event) => handleOnClick(event, titleName)}
                >
                  Start
                </Button>
              )
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container spacing={2}>
              <Grid item>
                <Typography
                  className={classes.countNumber}
                  align="center"
                  variant="subtitle2"
                  color="textSecondary"
                >
                  {count}
                </Typography>
              </Grid>
              <Grid item xs={7} sm={8}>
                <Typography className={classes.textScroll} variant="subtitle1">
                  {capitalize(titleName)}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
        </div>
      ) : (
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          className={classes.accordionMargin}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container spacing={2}>
              <Grid item>
                <Typography
                  className={classes.countNumber}
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {count}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.textScroll} variant="subtitle1">
                  {capitalize(titleName)}&nbsp;
                  {store.get(
                    "practice" + store.get("practiceSubject") + "topic"
                  ) === titleName && (
                    <Typography variant="caption" color="primary">
                      (Resume)
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column">
              {subTopics.map((subtopic, index) => {
                const percent =
                  percentData &&
                  percentData.subTopicWisePercent[titleName] &&
                  percentData.subTopicWisePercent[titleName][subtopic]
                    ? percentData.subTopicWisePercent[titleName][subtopic]
                    : 0;
                return (
                  <SubtopicList
                    subtopic={subtopic}
                    key={subtopic}
                    topicName={titleName}
                    index={index}
                    percentage={percent}
                    textScrollClass={classes.textScrollSubTopic}
                    handleClick={(event) =>
                      handleOnClick(event, titleName, subtopic)
                    }
                  />
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </React.Fragment>
  );
}
export default TopicList;
