import React from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import * as dateFns from 'date-fns'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

// const CustomIcon = () => (
//   <svg
//     width='18'
//     height='19'
//     viewBox='0 0 18 19'
//     fill='none'
//     xmlns='http://www.w3.org/2000/svg'
//   >
//     <path
//       d='M13.6922 1.94229L7.44217 8.19229C7.38412 8.2504 7.31519 8.2965 7.23932 8.32795C7.16344 8.35941 7.08212 8.37559 6.99998 8.37559C6.91785 8.37559 6.83652 8.35941 6.76064 8.32795C6.68477 8.2965 6.61584 8.2504 6.55779 8.19229L0.307794 1.94229C0.190518 1.82502 0.124634 1.66596 0.124634 1.5001C0.124634 1.33425 0.190518 1.17519 0.307794 1.05792C0.42507 0.940646 0.584131 0.874762 0.749985 0.874762C0.915839 0.874762 1.0749 0.940646 1.19218 1.05792L6.99998 6.86573L12.8078 1.05792C12.9251 0.940646 13.0841 0.874762 13.25 0.874762C13.4158 0.874762 13.5749 0.940646 13.6922 1.05792C13.8095 1.17519 13.8753 1.33425 13.8753 1.5001C13.8753 1.66596 13.8095 1.82502 13.6922 1.94229Z'
//       fill='#3358B8'
//     />
//   </svg>
// )

const Calendar = ({
  classes,
  theme,
  isMobile,
  selectedMonthIndex,
  handleMonthChange,
  months,
  weekdays,
  emptySlots,
  allMonthDates,
  selectedDate,
  handleDateClick,
  holidays
}) => {
  const today = new Date()

  return (
    <Grid item xs={isMobile ? 12 : 6}>
      <Paper className={classes.root}>
        <Grid container display='flex' flexDirection='row'>
          <Grid item xs={6}>
            <Select
              value={selectedMonthIndex !== null ? selectedMonthIndex : ''}
              onChange={handleMonthChange}
              disableUnderline
              classes={{ root: classes.monthSelect, icon: classes.selectIcon }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                    width: 250
                  }
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center'
                },
                getContentAnchorEl: null
              }}
            >
              {months.map((month, index) => (
                <MenuItem key={index} value={index}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid
            item
            xs={3}
            container
            direction='row'
            justifyContent='flex-end'
            style={{ paddingTop: '3px' }}
          >
            <FiberManualRecordIcon
              style={{ color: '#46AB87', width: '15px', height: '15px' }}
            />

            <Typography
              variant='body2'
              style={{
                fontSize: '12px',
                fontWeight: 400
              }}
            >
              Present
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            container
            direction='row'
            style={{ paddingTop: '3px' }}
            justifyContent='flex-end'
          >
            <FiberManualRecordIcon
              color='error'
              style={{ width: '15px', height: '15px' }}
            />

            <Typography
              variant='body2'
              style={{
                fontSize: '12px',
                fontWeight: 400
              }}
            >
              Holiday
            </Typography>
          </Grid>
        </Grid>
        {/* Weekday names */}
        <div className={classes.dateGrid}>
          {weekdays.map(weekday => (
            <div key={weekday} className={classes.weekday}>
              <div style={{ paddingLeft: '4px' }}>{weekday}</div>
            </div>
          ))}
        </div>

        {/* Dates */}
        <div className={classes.dateGrid}>
          {emptySlots.map((_, index) => (
            <div key={`empty-${index}`} className={classes.date} />
          ))}
          {allMonthDates.map(date => {
            const formattedDate = dateFns.format(date, 'yyyy-MM-dd')
            const day = dateFns.format(date, 'd')
            const isSunday = date.getDay() === 0 // Check if it's Sunday
            const isAfterToday = dateFns.isAfter(date, today) // Check if date is after today
            const isHoliday = holidays.some(holiday =>
              dateFns.isSameDay(holiday, date)
            ) // Check if date is a holiday

            return (
              day && (
                <div
                  key={formattedDate}
                  style={{
                    backgroundColor:
                      day &&
                      (selectedDate && dateFns.isSameDay(selectedDate, date)
                        ? '#3358B8'
                        : isSunday
                        ? '#FC5132'
                        : isHoliday
                        ? '#46AB87' // Purple color for holidays
                        : theme.palette.common.white),
                    // boxShadow: day ? theme.shadows[1] : null,
                    padding: theme.spacing(1),
                    cursor: isAfterToday ? 'not-allowed' : 'pointer',
                    margin: '3px',
                    width: '27px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '4px',
                    fontWeight: 500,
                    padding: '4px',
                    border: isAfterToday ? '1px solid #D4D4D4' : null,
                    color:
                      selectedDate && dateFns.isSameDay(selectedDate, date)
                        ? theme.palette.common.white
                        : isSunday || isHoliday
                        ? '#FFFFFF'
                        : isAfterToday
                        ? '#D4D4D4'
                        : null,
                    opacity: isAfterToday ? 0.5 : 1
                  }}
                  onClick={() => !isAfterToday && handleDateClick(date)}
                >
                  <Typography
                    variant='body2'
                    // className={classes.dateValue}
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color:
                        day &&
                        (selectedDate && dateFns.isSameDay(selectedDate, date)
                          ? theme.palette.common.white
                          : isSunday || isHoliday
                          ? theme.palette.common.white
                          : null)
                    }}
                  >
                    {day || ''}
                  </Typography>
                </div>
              )
            )
          })}
        </div>
      </Paper>
    </Grid>
  )
}
export default Calendar
