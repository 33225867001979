// react
import React from "react";
import { useState, useEffect } from "react";
// mui
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import borderRadius from "highcharts-border-radius";
// loadash
import cloneDeep from "lodash/cloneDeep";
import { Box, Grid, Typography } from "@material-ui/core";
import Dropdown from "../../../components/CustomDropdown/Dropdown";
import { useStyles, StyledBadge } from "../Components/styles";
import CustomCheckBoxListMenu from "../Components/CustomCheckBoxListMenu";
import { subColors, avgColors } from "./colors";

borderRadius(Highcharts);
const a = {
  MPC: "JEEMAIN_2022",
  BiPC: "NEET_2021",
};
const examTypes = ["JEEMAIN_2022", "NEET_2021"];
const EaxmSummaryChart = ({ data1, subjects, testTypes, group }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:850px)");
  const [exam, setExam] = useState("");
  const [finalData, setFinalData] = useState([]);
  const [allSubjectsData, setAllSubjectsData] = useState([]);
  const [allAvgSubjectsData, setAllAvgSubjectsData] = useState([]);
  let matchingTypes;
  if (testTypes) {
    matchingTypes = testTypes.filter((testType) =>
      examTypes.includes(testType)
    );
  }
  const [values, setValues] = useState([]);
  const [testNames, setTestNames] = useState([]);
  const [graphChecked, setGraphChecked] = useState(
    testNames && testNames.slice(0, 2)
  );
  const [graphAnchorEl, setGraphAnchorEl] = useState(null);
  const [state, setState] = React.useState({
    checkedA: false,
  });
  const setGraphopen = Boolean(graphAnchorEl);
  const handleGraphClick = (event) => {
    setGraphAnchorEl(event.currentTarget);
  };
  const handleGraphClose = () => {
    setGraphAnchorEl(null);
  };

  const handleGraphToggle = (val) => () => {
    const currentIndex = graphChecked.indexOf(val);
    const newChecked = [...graphChecked];
    if (currentIndex === -1) {
      newChecked.push(val);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setGraphChecked(newChecked);
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  useEffect(() => {
    if (exam === "") {
      const values1 = data1.filter((test) => test.examType === a[group]);
      setValues(values1);
    } else {
      const values1 = data1.filter((test) => test.examType === exam);
      setValues(values1);
    }
  }, [exam, data1]);

  useEffect(() => {
    setTestNames(values.map((obj) => obj.testName));
  }, [values]);
  useEffect(() => {
    setGraphChecked(testNames.slice(0, 6));
  }, [exam, testNames]);
  let arry = [];
  let avgArry = [];
  let result;
  useEffect(() => {
    const filteredArray = values.filter((obj) =>
      graphChecked.includes(obj.testName)
    );

    // ------------------without avg--------------------
    const names = filteredArray.map((item) => item.subjectMarks);

    const entries = names.flat();
    const demo = (sub, stack) => {
      const properties = [sub];
      const sameProps = properties.reduce(
        (acc, prop) =>
          entries.every((obj) => prop in obj) ? [...acc, prop] : acc,
        []
      );

      result = entries
        .filter((obj) => sameProps.every((prop) => obj[prop]))
        .map((obj) => sameProps.reduce((acc, prop) => [...acc, obj[prop]], []));

      arry.push({
        ["name"]: properties[0],
        ["data"]: result.flat(),
        ["stack"]: stack,
      });
    };

    subjects.forEach((item) => {
      demo(item, "subjectMarks");
    });

    setFinalData(arry);
  }, [values, testNames, graphChecked]);

  let subjectsData = [];
  useEffect(() => {
    const mergeSubColors = () => {
      finalData.map((i) => {
        if (subColors[i.name]) {
          subjectsData.push({ ...i, ...subColors[i.name] });
        }
        setAllSubjectsData(subjectsData);
      });
    };
    mergeSubColors();
    const computeAverages = (data) => {
      const averages = [];
      finalData.forEach((subject) => {
        const subjectName = `${subject.name}AVG`;
        const marks = subject.data;
        const averagesData = [];
        for (let i = 0; i < marks.length; i++) {
          const sum = marks
            .slice(0, i + 1)
            .reduce((acc, curr) => acc + curr, 0);
          const average = sum / (i + 1);
          averagesData.push(average);
        }
        averages.push({
          name: subjectName,
          data: averagesData,
          stack: "subjectAvgMarks",
        });
      });
      return averages;
    };
    let subjectsAvgData = [];
    const mergeSubAvgColors = () => {
      computeAverages(finalData).map((i) => {
        if (avgColors[i.name]) {
          subjectsAvgData.push({ ...i, ...avgColors[i.name] });
        }
        setAllAvgSubjectsData(subjectsAvgData);
      });
    };
    mergeSubAvgColors();
  }, [finalData]);

  const defaultOptionsDataSet1 = {
    chart: {
      type: "column",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
      align: "left",
    },
    xAxis: {
      categories: graphChecked || [],
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      enabled: true,
    },

    plotOptions: {
      column: {
        stacking: "normal",
      },
      series: {
        pointPadding: -1,
        pointWidth: 15,

        point: {
          events: {
            click(e) {},
          },
        },
      },
    },
    credits: {
      enabled: false,
    },

    series:
      state.checkedA === true
        ? [...allSubjectsData, ...allAvgSubjectsData] || []
        : allSubjectsData || [],
  };
  const currentOptions = cloneDeep(defaultOptionsDataSet1);

  return (
    <>
      <Grid container spacing={1} direction="row" display="flex">
        <Grid item xs={3}>
          <Typography
            style={{ color: " #31456A", fontSize: "18px", fontWeight: 600 }}
          >
            Exams Summary
          </Typography>
          {!matches ? (
            <FormControlLabel
              control={
                <Switch
                  checked={state.checkedA}
                  onChange={handleChange}
                  name="checkedA"
                  color="primary"
                  fontSize="10px"
                />
              }
              label={
                <Typography className={classes.CardFontStyle}>Avg</Typography>
              }
            />
          ) : null}
        </Grid>
        {matches ? (
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.checkedA}
                  onChange={handleChange}
                  name="checkedA"
                  color="primary"
                  fontSize="10px"
                />
              }
              label={
                <Typography className={classes.CardFontStyle}>Avg</Typography>
              }
            />
          </Grid>
        ) : null}

        <Grid item xs={6}>
          <Dropdown
            width={!matches ? 140 : 200}
            dataToDisplay={testTypes || []}
            handleDropdownValueChange={(e, item) => {
              setExam(item);
            }}
            value={exam || ""}
            textFieldProps={{
              size: "small",
              InputLabelProps: { shrink: false },
              placeholder: matchingTypes[0] || "",
              label: "exam",
            }}
          />
        </Grid>

        <Grid item xs={1}>
          <CustomCheckBoxListMenu
            list={testNames}
            open={setGraphopen}
            handleButtonClick={handleGraphClick}
            anchorEl={graphAnchorEl}
            handleClose={handleGraphClose}
            handleToggle={handleGraphToggle}
            checked={graphChecked}
          />
        </Grid>
      </Grid>
      <div>
        <HighchartsReact highcharts={Highcharts} options={currentOptions} />
        {state.checkedA === true ? (
          <Typography>
            &nbsp;Selected Tests&nbsp;:&nbsp;{graphChecked.join(",\n ")}&nbsp;
          </Typography>
        ) : null}
      </div>
    </>
  );
};
export default EaxmSummaryChart;
