const submitBtnDisabledDuration = {
  "sr.onesaz.com": 1800,
  "bhashyam.onesaz.com": 300,
  // "tirumala.onesaz.com": 1800,
  "trinity.onesaz.com": 1800,
  "localhost:4000": 300,
  "onesaz.com": 1800,
  "viitjee.onesaz.com": 300,
  "valleyoak.onesaz.com": 300,
  "sriabhidakp.onesaz.com": 300,
  "miity.onesaz.com": 300,
  "sriabhida.onesaz.com": 300,
  "211210": 4800,
  "sanskriti.onesaz.com": 300,
  "agastya.onesaz.com": 300,
  "tirumala.onesaz.com": 300,
  "sreducation.onesaz.com": 1800,
  "pinegrove.onesaz.com": 300,
  "neutrino.onesaz.com": 300,
  "vijna.onesaz.com": 300,
  "sundar.onesaz.com": 300,
  "metamind.onesaz.com": 300,
  "ayati.onesaz.com": 300,
  "motion.onesaz.com": 4200,
  "excellencia.onesaz.com": 300,
  "kle.onesaz.com": 300,
  "arastu.onesaz.com": 300,
  "cognizant.onesaz.com": 300,
};
export default submitBtnDisabledDuration;
