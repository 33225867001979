import React from "react";
import { withStyles } from "@material-ui/core/styles";
import "../index.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import info from "assets/img/adv-info.svg";
import Tooltip from "@material-ui/core/Tooltip";
import MarkedStatus from "./MarkedStatus";
// core components
// import Button from "components/CustomButtons/Button.jsx";

// core components
// import Button from "components/CustomButtons/Button.jsx";

var classNames = require("classnames");
const styles = (theme) => ({
  // button: {
  //     margin: theme.spacing.unit*4,
  //     marginTop: '1em'
  //   },
  //   input: {
  //     display: 'none',
  //   },
  // subject: {
  //     width: 'auto',
  //     textAlign: 'center',
  //     margin: '12px',
  //     borderRadius: '2em',
  //     color: '#566573',
  //     fontSize: 20
  // },
  tooltip: {
    backgroundColor: "#E3EEFF",
    color: "black",
    fontWeight: "500",
    fontSize: "14px",
  },
  MuiSelected: {
    /* We increase the specificity */
    backgroundColor: "#0B80F1 !important",
  },
  activesubject: {
    backgroundColor: "#0B80F1",
    color: "white",
  },
  section: {
    width: "max-content",
    //  display: 'inline',
    // margin: '0px',
    fontWeight: "500",
    fontSize: "10px",
    padding: "10px",
    // listStyleType: 'none',
    // paddingLeft: '5px',
    color: "#0B80F1",
    "&:active": {
      color: "white",
      backgroundColor: "#0B80F1",
    },
  },
});
class AdvanceSubjects extends React.Component {
  anchorElLeft = null;
  anchorElTop = null;
  anchorElBottom = null;
  anchorElRight = null;
  a = 0;
  constructor(props) {
    super(props);
    this.state = {
      si: 0,
      openLeft: false,
      openTop: false,
      openBottom: false,
      openRight: false,
    };
    this.props = props;
    console.log(this.props);
  }
  handleClosePopover(state) {
    this.setState({
      [state]: false,
    });
  }
  handleClickButton(state) {
    this.setState({
      [state]: true,
    });
  }

  clickme(index) {
    console.log("28", index);
    this.props.displayQuestion(index);
  }
  handleListItemClick(event, ind, index, section) {
    console.log(ind);
    this.setState({ si: ind });
    this.clickme(this.props.schema.sections[section][index]);
  }
  render() {
    const { classes } = this.props;
    const props = this.props;

    return (
      <List
        style={{ display: "flex", padding: "0px" }}
        aria-label="main mailbox folders"
      >
        {props.qNumPerSubject &&
          Object.keys(props.qNumPerSubject).map((subject, index) => {
            // const { qNumPerSubject } = props;
            return (
              <React.Fragment>
                {Object.keys(props.schema.sections).map((section, sind) => {
                  return (
                    <ListItem
                      id="secs"
                      key={subject + index + "_" + sind}
                      classes={{ selected: classes.MuiSelected }}
                      disableGutters="true"
                      button
                      // selected={this.state.si === index}
                      selected={
                        props.current_question.subject === subject &&
                        props.schema.questions[
                          props.current_question.question_number
                        ] ===
                          sind + 1
                      }
                      onClick={(event) =>
                        this.handleListItemClick(
                          event,
                          index + sind,
                          index,
                          section
                        )
                      }
                      className={classes.section}
                    >
                      <span
                        onClick={() =>
                          this.clickme(props.schema.sections[section][index])
                        }
                        className={classNames(
                          classes.subject,
                          props.current_question.subject === subject &&
                            props.schema.questions[
                              props.current_question.question_number
                            ] ===
                              sind + 1
                            ? classes.activesubject
                            : ""
                        )}
                      >
                        {subject.substr(0, 3)} S-{section}
                      </span>{" "}
                      &nbsp;
                      <Tooltip
                        id="tooltip-left"
                        title={
                          <MarkedStatus
                            schema={props.schema}
                            questions={this.props.questions}
                            section={section}
                            subject={subject}
                          />
                        }
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <span>
                          {" "}
                          <img
                            src={info}
                            height="12px"
                            width="12px"
                            alt="info"
                          />
                        </span>
                      </Tooltip>
                    </ListItem>
                  );
                })}{" "}
              </React.Fragment>
            );
          })}
      </List>
    );
  }
}

export default withStyles(styles)(AdvanceSubjects);
