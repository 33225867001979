import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  Typography,
  Avatar
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
// import { ReactComponent as ProfileImage } from "../assets/img/Rectangle-profile.svg";
import BottomNav from "./BottomNav";
import PasswordChange from "./PasswordChange";
import ProfileEditForm from "./ProfileEditForm";
import store from "store";
import { useDispatch } from "react-redux";
import { clearErrorEditProfile } from "../redux/actions/editProfileAction/actionCreator";
import profileEditCondition from "../utils/profileEditCondition";
import { logoutUser } from "../apis/UserService";
import AlertComponent from "./AlertComponent";
import clearLocalStorage from "../common/clearStore";
import logOut from "../assets/img/navigationBarAssets/logOut.svg";
import settings from "../assets/img/navigationBarAssets/settings.svg";
import terms from "../assets/img/navigationBarAssets/termsAndPolicy.svg";
import editProfile from "../assets/img/navigationBarAssets/editProfile.svg";
import examsprofile from "../assets/img/navigationBarAssets/exams-profile.svg";
import examAnalysis from "../assets/img/navigationBarAssets/analysis-profile.svg";
import attProfile from "../assets/img/navigationBarAssets/att-profile.svg";
import feeProfile from "../assets/img/navigationBarAssets/fee-profile.svg";
import myClassroom from "../assets/img/navigationBarAssets/class-profile.svg";
import bell from "../assets/img/navigationBarAssets/bell.svg";
import timeTableProfile from "../assets/img/navigationBarAssets/timeTable-profile.svg";
import feedbackProfile from "../assets/img/navigationBarAssets/feeback-profile.svg";
import faqProfile from "../assets/img/navigationBarAssets/faqs.svg";
import useStudentData from "../mycomponents/Dashboard/Components/useStudentData";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import LogoutConfirmation from "../mycomponents/HomePage/LogoutConfirmation";
import logoutAnimation from "../assets/logout.json";
// import FeeDueAmount from "./HomePage/FeeDueAmount";

const CustomButton = withStyles({
  root: {
    textTransform: "none",

    color: "#2A2A2A",
    borderRadius: "10px",
    color: "#2A2A2A",
    fontSize: "14px",
    fontWeight: 400,

    justifyContent: "center",
    alignContent: "center",
    alignItems: "center"
  }
})(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "1600px"
  },
  title: {
    color: "#2A2A2A",
    fontSize: "18px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px" // Tablet
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "22px" // Desktop
    },

    fontWeight: 700,
    padding: "16px 0",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    alignItems: "flex-start"
  },
  profileImg: {
    height: "100%",
    width: "100%",
    background: "#3BBDED",
    position: "absolute",
    width: "100%",
    height: "253px",
    left: "0px",
    top: "0px",
    borderRadius: "0px",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center"
    // [theme.breakpoints.up("xs")]: {
    //   height: "270px", // Mobile
    // },
    // [theme.breakpoints.up("sm")]: {
    //   height: "300px", // Tablet
    // },
    // [theme.breakpoints.up("md")]: {
    //   height: "270px", // Desktop
    // },
  },
  user: {
    color: "#31456A",
    fontWeight: "bold",
    fontSize: "17px",
    textTransform: "capitalize !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px" // Tablet
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px" // Desktop
    }
  },
  feeCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    // padding: "8px",
    gap: "10px",

    marginLeft: "26px",
    marginRight: "26px",
    marginTop: "16px"
    // marginBottom: "16px"
  },
  btnContainer: {
    background: "#EBEFF3",
    boxShadow: `4px 4px 8px rgba(189, 194, 199, 0.75),
      8px 8px 12px rgba(189, 194, 199, 0.25),
     -4px -4px 8px rgba(255, 255, 255, 0.75),
     -8px -8px 12px rgba(255, 255, 255, 0.25)`,
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "8px",
    gap: "10px",

    marginLeft: "26px",
    marginRight: "26px",
    marginTop: "16px",
    marginBottom: "16px"
  },
  buttonMargin: {
    marginTop: "16px",

    paddingBottom: "16px"
  },
  BigScreenContainer: {
    minHeight: "300px,",
    alignItems: "center !important"
  },
  icon: {
    marginTop: "5px",
    alignItems: "flex-end",

    alignContent: "flex-end",
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    color: "#2A2A2A"
  },
  leftImge: {
    position: "absolute",
    width: "172px",
    height: "172px",
    right: "95%",
    left: "-125px;", // Positioning relative to the right edge
    top: "71.62px",
    background: "#0E5B7E",
    borderRadius: "30px",
    transform: "rotate(-45deg)"
    /* Rectangle 350 */
  },
  rightImg: {
    position: "absolute",
    width: "172px",
    height: "172px",

    right: "-125px", // Positioning relative to the right edge
    top: "0px !important",
    background: "#1AA8DD",
    borderRadius: "30px",
    transform: "rotate(-45deg)",
    left: (props) => props.left // Use the left value from props
  }
}));
const Icon = ({ handleClick, classes }) => {
  return (
    <IconButton onClick={handleClick}>
      <svg
        width="9"
        height="14"
        viewBox="0 0 9 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes.icon}
      >
        <path
          d="M8.19229 7.44229L1.94229 13.6923C1.88422 13.7504 1.81528 13.7964 1.73941 13.8278C1.66354 13.8593 1.58223 13.8755 1.5001 13.8755C1.41798 13.8755 1.33666 13.8593 1.26079 13.8278C1.18492 13.7964 1.11598 13.7504 1.05792 13.6923C0.999847 13.6342 0.953784 13.5653 0.922357 13.4894C0.890931 13.4135 0.874756 13.3322 0.874756 13.2501C0.874756 13.168 0.890931 13.0867 0.922357 13.0108C0.953784 12.9349 0.999847 12.866 1.05792 12.8079L6.86651 7.0001L1.05792 1.19229C0.94064 1.07502 0.874756 0.915956 0.874756 0.750103C0.874756 0.584251 0.94064 0.425191 1.05792 0.307916C1.17519 0.19064 1.33425 0.124756 1.5001 0.124756C1.66596 0.124756 1.82502 0.19064 1.94229 0.307916L8.19229 6.55792C8.2504 6.61596 8.2965 6.68489 8.32795 6.76077C8.35941 6.83664 8.37559 6.91797 8.37559 7.0001C8.37559 7.08224 8.35941 7.16357 8.32795 7.23944C8.2965 7.31531 8.2504 7.38425 8.19229 7.44229Z"
          fill="#737373"
        />
      </svg>
    </IconButton>
  );
};

const getUser = () => {
  let userData = localStorage.getItem("user");
  if (userData) {
    userData = JSON.parse(userData);
    return userData;
  }
};
const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: logoutAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
function Profile(props) {
  const isSmallScreen = useMediaQuery("(max-width:320px)");
  const isMediumScreen = useMediaQuery("(max-width:850px)");

  let leftValue;
  if (isSmallScreen) {
    leftValue = "90%";
  } else if (isMediumScreen) {
    leftValue = "95%";
  } else {
    leftValue = "default value"; // Set a default value for larger screens
  }
  const classes = useStyles({ left: leftValue });
  const hostname = window.location.hostname;
  const user = store.get("user");
  const [, , , avatar] = useStudentData();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [domain] = useState(hostname);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [alertText, setAlertText] = useState("");
  const anchorRef = React.createRef();
  const userData = getUser();
  const dispatch = useDispatch();
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handlePayment = () => {
    const { history } = props;
    history.push("/plans");
    // console.log("Make your payment");
  };
  const handleCloseAlertBox = () => {
    setConfirmOpen(false);
    setAlertText("");
  };
  const logout = async () => {
    // eslint-disable-next-line
    const exam = store.get("startedExam");
    if (exam && exam.test_status === "STARTED") {
      setConfirmOpen(true);
      setAlertText(
        `Please, Submit Test : ${exam.testDisplayName} Before Logging out!`
      );
    } else {
      const { history } = props;
      handleClose("");
      await logoutUser({ id: store.get("user")._id });
      clearLocalStorage();
      history.push("/sign-in");
    }
  };
  const handleLogoutDialogOpen = () => {
    setLogoutDialogOpen(true);
  };

  const handleLogoutDialogClose = () => {
    setLogoutDialogOpen(false);
  };

  useEffect(() => {
    // reset edit profile state in redux store
    return () => {
      dispatch(clearErrorEditProfile());
    };
  }, []);
  const handleProfile = () => {
    props.history.push("/view-profile");
  };
  const Info = () => {
    return (
      <>
        {/* <Box className={classes.feeCard}>
        <FeeDueAmount handleClick={handleClick} background="#4751B6" />   
        </Box> */}
        <Box className={classes.btnContainer}>
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <CustomButton
                onClick={() => props.history.push("/view-profile")}
                color="primary"
                disableRipple
                startIcon={<img src={terms} alt="logOut" />}
              >
                Profile
              </CustomButton>
            </Grid>
            <Grid
              item
              xs={1}
              container
              justifyContent="flex-end"
              alignContent="flex-end"
              alignItems="flex-end"
            >
              <Icon handleClick={handleProfile} classes={classes} />
            </Grid>
          </Grid>
          {/* <Grid container spacing={1}>
            <Grid item xs={11}>
              <CustomButton
                onClick={() => props.history.push("/view-profile")}
                color="primary"
                disableRipple
                startIcon={<img src={editProfile} alt="logOut" />}
              >
                Edit Profile
              </CustomButton>
            </Grid>
            <Grid
              item
              xs={1}
              container
              justifyContent="flex-end"
              alignContent="flex-end"
              alignItems="flex-end"
            >
              <Icon handleClick={handleProfile} classes={classes} />
            </Grid>
          </Grid> */}
        </Box>
        {/* <Box className={classes.btnContainer}>
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <CustomButton
                onClick={() => props.history.push("/home")}
                color="primary"
                disableRipple
                startIcon={<img src={examsprofile} alt="home" />}
              >
                Exams
              </CustomButton>
            </Grid>
            <Grid
              item
              xs={1}
              container
              justifyContent="flex-end"
              alignContent="flex-end"
              alignItems="flex-end"
            >
              <Icon
                handleClick={() => props.history.push("/home")}
                classes={classes}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <CustomButton
                onClick={() => props.history.push("/exam-analysis")}
                color="primary"
                disableRipple
                startIcon={<img src={examAnalysis} alt="analysis" />}
              >
                Analyis
              </CustomButton>
            </Grid>
            <Grid
              item
              xs={1}
              container
              justifyContent="flex-end"
              alignContent="flex-end"
              alignItems="flex-end"
            >
              <Icon
                handleClick={() => props.history.push("/exam-analysis")}
                classes={classes}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <CustomButton
                onClick={() => props.history.push("/attendance")}
                color="primary"
                disableRipple
                startIcon={<img src={attProfile} alt="attendance" />}
              >
                Attendance
              </CustomButton>
            </Grid>
            <Grid
              item
              xs={1}
              container
              justifyContent="flex-end"
              alignContent="flex-end"
              alignItems="flex-end"
            >
              <Icon
                handleClick={() => props.history.push("/attendance")}
                classes={classes}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <CustomButton
                onClick={() => props.history.push("/fee-details")}
                color="primary"
                disableRipple
                startIcon={<img src={feeProfile} alt="details" />}
              >
                Fee Details
              </CustomButton>
            </Grid>
            <Grid
              item
              xs={1}
              container
              justifyContent="flex-end"
              alignContent="flex-end"
              alignItems="flex-end"
            >
              <Icon
                handleClick={() => props.history.push("/fee-details")}
                classes={classes}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <CustomButton
                onClick={() => props.history.push("/classroom")}
                color="primary"
                disableRipple
                startIcon={<img src={myClassroom} alt="details" />}
              >
                Class Room
              </CustomButton>
            </Grid>
            <Grid
              item
              xs={1}
              container
              justifyContent="flex-end"
              alignContent="flex-end"
              alignItems="flex-end"
            >
              <Icon
                handleClick={() => props.history.push("/classroom")}
                classes={classes}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <CustomButton
                onClick={() => props.history.push("/classroom")}
                color="primary"
                disableRipple
                startIcon={<img src={timeTableProfile} alt="details" />}
              >
                Time Table
              </CustomButton>
            </Grid>
            <Grid
              item
              xs={1}
              container
              justifyContent="flex-end"
              alignContent="flex-end"
              alignItems="flex-end"
            >
              <Icon
                handleClick={() => props.history.push("/classroom")}
                classes={classes}
              />
            </Grid>
          </Grid>
        </Box> */}
        <Box className={classes.buttonMargin}>
          <Box className={classes.btnContainer}>
            {(domain == "www.onesaz.com" ||
              domain == "onesaz.com" ||
              domain == "localhost") && (
              <>
                {userData.statusType !== "paid" && (
                  <Grid container spacing={1}>
                    <Grid item xs={11}>
                      <CustomButton
                        onClick={handlePayment}
                        color="primary"
                        disableRipple
                        startIcon={<img src={terms} alt="logOut" />}
                      >
                        Subscribe Now
                      </CustomButton>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      container
                      justifyContent="flex-end"
                      alignItems="flex-end"
                    >
                      <Icon handleClick={handlePayment} classes={classes} />
                    </Grid>
                  </Grid>
                )}
                {userData.statusType == "paid" && (
                  <Grid container spacing={1}>
                    <Grid item xs={11}>
                      <CustomButton
                        onClick={handlePayment}
                        color="primary"
                        disableRipple
                        startIcon={<img src={terms} alt="logOut" />}
                      >
                        Subscribed to Crash Course
                      </CustomButton>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      container
                      justifyContent="flex-end"
                      alignItems="flex-end"
                    >
                      <Icon handleClick={handlePayment} classes={classes} />
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            <Grid container spacing={1}>
              <Grid item xs={11}>
                <CustomButton
                  onClick={() => props.history.push("/notification")}
                  color="primary"
                  disableRipple
                  startIcon={<img src={bell} alt="details" />}
                >
                  Notifications
                </CustomButton>
              </Grid>
              <Grid
                item
                xs={1}
                container
                justifyContent="flex-end"
                alignContent="flex-end"
                alignItems="flex-end"
              >
                <Icon
                  handleClick={() => props.history.push("/notification")}
                  classes={classes}
                />
              </Grid>
            </Grid>

            {user.institute === "5fd25b12ed14200ff702f738" ||
            user.institute === "5e7370083d8520a40c4ceaaa" ? null : (
              <Grid container>
                <Grid item xs={11}>
                  <CustomButton
                    onClick={handleOpen}
                    color="primary"
                    disableRipple
                    startIcon={<img src={settings} alt="logOut" />}
                  >
                    Change Password
                  </CustomButton>
                </Grid>
                <Grid
                  item
                  xs={1}
                  container
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Icon handleClick={handleOpen} classes={classes} />
                </Grid>
              </Grid>
            )}

            {hostname != "sreducation.onesaz.com" && (
              <Grid container>
                <Grid item xs={11}>
                  <CustomButton
                    onClick={handleLogoutDialogOpen}
                    color="primary"
                    disableRipple
                    startIcon={<img src={logOut} alt="logOut" />}
                  >
                    Log Out
                  </CustomButton>
                </Grid>
                <Grid
                  item
                  xs={1}
                  container
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Icon handleClick={handleLogoutDialogOpen} classes={classes} />
                </Grid>
              </Grid>
            )}
          </Box>

          <LogoutConfirmation
            open={logoutDialogOpen}
            onClose={handleLogoutDialogClose}
            onConfirm={logout}
            lottieOptions={defaultOptions}
          />
        </Box>
      </>
    );
  };
  const handleClick = () => {
    props.history.push("/fee-details");
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <div className={classes.profileImg}>
          <Box style={{ height: "100%" }}>
            <Grid
              container
              spacing={3}
              style={{ justifyContent: "center", alignContent: "center" }}
            >
              <Grid item xs={12}>
                <div className={classes.leftImge} />
                <Avatar
                  src={avatar}
                  alt="profile"
                  align="center"
                  style={{
                    width: "100px",
                    height: "100px",
                    justifyContent: "center",
                    alignContent: "center",
                    align: "center",
                    border: "2px solid white",
                    margin: "auto",
                    marginTop: !isMobile ? "70px" : "10px",
                    padding: "2px"
                  }}
                />
                {/* {avatar}
                </Avatar> */}
                <Grid xs={12}>
                  <Typography
                    className={classes.user}
                    style={{
                      textAlign: "center"
                    }}
                  >
                    {userData ? userData.name || userData.fullName : ""}
                  </Typography>
                  <Typography
                    align="center"
                    style={{
                      color: "#31456A"
                    }}
                  >
                    Class: {userData ? userData.classVal || userData.class : ""}
                  </Typography>
                </Grid>
                <div className={classes.rightImg} />
              </Grid>
            </Grid>
          </Box>
        </div>

        {/* ---------------------------------------update profile for bhashyam-------------------------------------  */}
        <Grid
          xs={12}
          style={{ top: !isMobile ? "190px" : "270px", position: "relative" }}
        >
          {profileEditCondition() ? (
            <ProfileEditForm />
          ) : (
            <>
              <Hidden only={["xs"]}>
                <Info />
              </Hidden>
              <Hidden only={["md", "lg", "xl", "sm"]}>
                <Info />
              </Hidden>
            </>
          )}
          {/* <ProfileEditForm /> */}
        </Grid>
      </Grid>
      <br />

      <Box pb="100px" bgcolor="#eee" />
      <PasswordChange open={open} handleClose={handleClose} />
      <AlertComponent open={confirmOpen} closeAlert={handleCloseAlertBox}>
        {alertText}
      </AlertComponent>
      <BottomNav />
    </div>
  );
}

export default withRouter(Profile);
