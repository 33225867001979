import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { ReactComponent as EventExclamation } from "../../assets/img/calendar-exclamation.svg";
import WifiOffIcon from "@material-ui/icons/WifiOff";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "50vh",
  },
}));

function NoExams() {
  const classes = useStyles();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <div style={{ margin: "auto" }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.root}
      >
        <Typography align="center">
          {isOnline ? <EventExclamation /> : <WifiOffIcon color="error" />}
          {isOnline ? (
            <>
              <Typography variant="subtitle1" color="textPrimary">
                Exams not available
              </Typography>
              <Typography color="textSecondary">Come back later</Typography>
            </>
          ) : (
            <>
              <Typography variant="subtitle1" color="error">
                You are Offline
              </Typography>
              <Typography color="textSecondary">
                Check your internet connection and try again.
              </Typography>
            </>
          )}
        </Typography>
      </Box>
    </div>
  );
}
export default NoExams;
