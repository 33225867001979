import axios from "./examServerAxios";
import { adminAxios } from "./baseAxios";

const COMMON = "/attendance";
// export async function getStudentAttendance(obj) {
//   try {
//     return (await axios.get(`${COMMON}/man-student`, {
//       params: { ...obj },
//     })).data;
//   } catch (error) {
//     return Promise.reject(error);
//   }
// }
export async function getStudentAllDetails(obj,admissionNo) {
  try {
    return (await axios.get(`${COMMON}/${admissionNo}`, {
      params: { ...obj },
    })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getStudentAttendance(body) {
  try {
    return (await adminAxios.get("/parent-portal/man-student",   {params: { ...body }}, ))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}