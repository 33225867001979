import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  correct: {
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    background: "#5db85c",
  },
});

function CheckedOptions(props) {
  const { option } = props;
  const A = (
    <SvgIcon
      style={{ padding: "6px" }}
      fontSize="small"
      styles={{ padding: "3px" }}
    >
      <path d="M9.5,0L0,24h5.6l1.8-4.9h9.2l1.8,4.9H24L14.4,0H9.5z M8.8,15l3.2-9l3.2,9H8.8z" />
    </SvgIcon>
  );
  // const A =  <SvgIcon
  //     xmlns="http://www.w3.org/2000/svg"
  //     x="0"
  //     y="0"
  //     enableBackground="new 0 0 24 24"
  //     viewBox="0 0 24 24"
  //     style={{padding:"4px"}}
  //   >
  //     <path d="M9.5 0L0 24h5.6l1.8-4.9h9.2l1.8 4.9H24L14.4 0H9.5zm-.7 15L12 6l3.2 9H8.8z" />
  //   </SvgIcon>

  const B = (
    <SvgIcon fontSize="small" style={{ padding: "6px" }}>
      <path d="M18.7,11.7c1.4-0.4,2.6-1.1,3.4-2s1.2-1.9,1.2-3.1c0-2.2-1-3.8-3-4.9S15.2,0,11.3,0H0v24h12.6c3.7,0,6.5-0.6,8.5-1.8  s3-2.9,3-5.2c0-1.4-0.5-2.5-1.4-3.4C21.6,12.6,20.3,12,18.7,11.7z M6.7,4h4.7c1.8,0,3.2,0.2,4,0.7s1.3,1.3,1.3,2.3  c0,1.9-1.7,2.9-5,3H6.7V4z M16,19.2c-0.9,0.6-2.1,0.8-3.7,0.8H6.7v-6.5h6.1c3.1,0,4.6,1.1,4.6,3.3C17.3,17.9,16.9,18.6,16,19.2z" />
    </SvgIcon>
  );

  const C = (
    <SvgIcon fontSize="small" style={{ padding: "6px" }}>
      <path d="M7.7,5.7c1-1.1,2.5-1.7,4.5-1.7c1.9,0,3.2,0.3,4.1,1c0.9,0.7,1.4,1.8,1.6,3.3H24c-0.3-2.6-1.5-4.6-3.6-6.1S15.6,0,12.2,0  C9.8,0,7.6,0.5,5.8,1.4S2.5,3.6,1.5,5.3S0,9,0,11.2v1.4C0,16.2,1.1,19,3.2,21s5.1,3,8.9,3c3.4,0,6.2-0.7,8.3-2.2s3.3-3.4,3.5-5.9  h-6.1c-0.1,1.4-0.7,2.5-1.6,3.2c-0.9,0.7-2.3,1-4.2,1c-2.1,0-3.6-0.6-4.6-1.7s-1.4-3-1.4-5.5V11C6.2,8.6,6.7,6.8,7.7,5.7z" />
    </SvgIcon>
  );
  const D = (
    <SvgIcon fontSize="small" style={{ padding: "6px" }}>
      <path d="M17.1,1.4C14.9,0.5,12.4,0,9.7,0H0v24h9.7c2.7,0,5.2-0.5,7.4-1.5c2.2-1,3.9-2.3,5.1-4c1.2-1.7,1.8-3.7,1.8-6v-1.1  c0-2.2-0.6-4.2-1.8-6C20.9,3.7,19.2,2.4,17.1,1.4z M17.4,12.7c0,2.4-0.7,4.2-2,5.4S12.1,20,9.6,20H6.5V4h3.2c2.5,0,4.5,0.6,5.8,1.9  s2,3.1,2,5.5V12.7z" />
    </SvgIcon>
  );
  // const D =  <SvgIcon>
  //            <path d="M10,0C4.5,0,0,4.5,0,10c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C20,4.5,15.5,0,10,0z M12.2,13.4l-0.6-1.7H8.4l-0.6,1.7h-2  l3.3-8.1h1.7l3.4,8.1H12.2z M10,7.3l1.1,3H8.9L10,7.3z" />
  //          </SvgIcon>;
  let result = "";
  switch (option) {
    case "A":
      result = A;
      break;
    case "B":
      result = B;
      break;
    case "C":
      result = C;
      break;
    case "D":
      result = D;
      break;
  }
  return result;
}

export default withStyles(styles)(CheckedOptions);
