import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";

const styles = (theme) => ({
  rankCard: {
    background: "linear-gradient(180deg,#47A5F8,#33B3FE)",
    minHeight: "201px",
    maxWidth: "330px",
    padding: "20px",
    paddingBottom: "0px",
    marginBottom: "16px",
  },
  scoreCard: {
    background: "linear-gradient(180deg,#00C2AE,#00C2AE)",
    minHeight: "201px",
    maxWidth: "330px",
    padding: "20px",
    paddingBottom: "0px",
    marginBottom: "16px",
  },
  accuracyCard: {
    background: "linear-gradient(180deg,#EFA268,#EFA268)",
    maxWidth: "330px",
    padding: "20px",
    paddingBottom: "0px",
    minHeight: "201px",
    marginBottom: "16px",
  },
  rankTitle: {
    borderBottom: "1px solid white",
    fontSize: "26px",
    //padding: "24px 24px 0",
    color: "white",
    fontWeight: "600",
  },
  accuracyTitle: {
    borderBottom: "1px solid #fbe7d9",
    fontSize: "26px",
    color: "white",
    fontWeight: "600",
  },
  scoreTitle: {
    borderBottom: "1px solid #affbf3",
    fontSize: "26px",
    //padding: "24px 24px 0",
    color: "white",
    fontWeight: "600",
  },
  rankValue: {
    color: "white",
    fontWeight: "600",
    fontSize: "45px",
  },
  scoreValue: {
    fontSize: "26px",
    fontWeight: "600",
    color: "white",
  },
  totalStudents: {
    color: "white",
    fontWeight: "400",
    fontSize: "14px",
  },
  tomarks: {
    paddingLeft: "5px",
    color: "white",
    fontWeight: "400",
    fontSize: "14px",
  },
});

class Comparision extends React.Component {
  tableHead = ["  ", "Your Details", "Average", "Topper Details"];
  tableHead1 = [
    " ",
    "PHY C",
    "PHY W",
    "PHY U",
    "PHY Marks",
    "MAT C",
    "MAT W",
    "MAT U",
    "MAT Marks",
    "CHE C",
    "CHE W",
    "CHE U",
    "CHE Marks",
    "Total",
  ];
  //  tableData=[[" h ", "your Details", "Average", "Topper Details"],["ello  ", "your Details", "Average", "Topper Details"]];
  accFields = {
    CC: "CW",
    PC: "PW",
    MC: "MW",
    BC: "BW",
    ZC: "ZW",
    BIOC: "BIOC",
  };
  papermarks = {
    "2k19_paper_1": "186",
    "2k19_paper_2": "186",
    "2k18_paper_1": "180",
    "2k18_paper_2": "180",
    "2k17_paper_1": "183",
    "2k17_paper_2": "183",
    "2k16_paper_1": "186",
    "2k16_paper_2": "195",
    "2k15_paper_1": "261",
    "2k15_paper_2": "240",
    "2k14_paper_1": "180",
    "2k14_paper_2": "180",
    "2k13_paper_1": "180",
    "2k13_paper_2": "180",
    "2k12_paper_1": "210",
    "2k12_paper_2": "198",
    "2k11_paper_1": "240",
    "2k11_paper_2": "240",
    EAMCET_MPC: "160",
    NEET: "720",
    "JEE MAINS": "300",
    MAINS: "300",
    CUSTOM: "360",
  };
  subjectChartdata = {};
  pieColors = {
    MATHEMATICS: ["#E4D360", "#B1AEAE"],
    CHEMISTRY: ["#60C1E4", "#B1AEAE"],
    PHYSICS: ["#E4A260", "#B1AEAE"],
    BIOLOGY: ["#E4B260", "#B1AEAE"],
    BOTANY: ["#E4D360", "#B1AEAE"],
    ZOOLOGY: ["#E4B260", "#B1BEAE"],
  };
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      networkRequest: true,
      rankData: {},
      scoreData: {},
      accData: {},
      subRanks: {},
      totalMarks: undefined,
      subjectCharts: {},
    };
    if (props.customQuestionsCount)
      this.papermarks["CUSTOM"] = this.props.customQuestionsCount * 4;
    console.log(props.customQuestionsCount);
    this.forceUpdate();
  }

  componentWillReceiveProps(upcomingprops) {
    console.log(upcomingprops === this.props, upcomingprops, this.props, "155");
    if (
      upcomingprops.customQuestionsCount !== this.props.customQuestionsCount
    ) {
      this.papermarks["CUSTOM"] = this.props.customQuestionsCount * 4;
    }
    if (Object.keys(this.props.data) != 0) {
      console.log(this.props.data);
      const rankData = {
        rank: this.props.data.ranks.total,
        totalStudents: this.props.data.totalStudents,
      };

      const submap = {
        biologyRank: "BIO",
        botanyRank: "BOT",
        zoologyRank: "ZOO",
        physicsRank: "PHY",
        chemistryRank: "CHE",
        mathematicsRank: "MAT",
      };

      const subRanks = this.state.subRanks;
      Object.keys(submap).forEach((key) => {
        if (this.props.data.ranks[key] !== undefined)
          subRanks[submap[key]] = this.props.data.ranks[key];
      });

      // score:
      const scoreData = {};
      if (this.props.data.YourDetails.P !== undefined)
        scoreData.PHY = this.props.data.YourDetails.P;
      if (this.props.data.YourDetails.C !== undefined)
        scoreData.CHE = this.props.data.YourDetails.C;
      if (this.props.data.YourDetails.M !== undefined)
        scoreData.MAT = this.props.data.YourDetails.M;
      if (this.props.data.YourDetails.B !== undefined)
        scoreData.BOT = this.props.data.YourDetails.B;
      if (this.props.data.YourDetails.Z !== undefined)
        scoreData.ZOO = this.props.data.YourDetails.Z;
      if (this.props.data.YourDetails.BIO !== undefined)
        scoreData.BIO = this.props.data.YourDetails.BIO;
      // T for total
      console.log(scoreData);
      const accData = {};
      ["YourDetails", "Average", "TopperDetails"].forEach((val) => {
        let correct = 0;
        let total = 0;
        Object.keys(this.accFields).forEach((key) => {
          if (this.props.data[val][key] !== undefined) {
            correct += this.props.data[val][key];
            console.log(this.props.data[val][key], key);
            total +=
              this.props.data[val][key] +
              this.props.data[val][this.accFields[key]];
          }
          // if(this.props.data.YourDetails.key!=undefined &&
          // this.props.data.YourDetails[this.accFields[key]]!=undefined  ){
          //     accData.YourDetails.key =  this.props.data.YourDetails[this.accFields[key]]+ this.props.data.YourDetails.key
          // }
          //  if(this.props.data.YourDetails.key!=undefined &&
          // this.props.data.YourDetails[this.accFields[key]]!=undefined  ){
          //     accData.YourDetails =  this.props.data.YourDetails[this.accFields[key]]+ this.props.data.YourDetails.key
          // }
          //  if(this.props.data.YourDetails.key!=undefined &&
          // this.props.data.YourDetails[this.accFields[key]]!=undefined  ){
          //     accData.YourDetails =  this.props.data.YourDetails[this.accFields[key]]+ this.props.data.YourDetails.key
          // }
        });
        if (total !== 0) accData[val] = parseInt((correct / total) * 100);
        else {
          accData[val] = 0;
        }
      });

      this.setState({
        subRanks: subRanks,
        rankData: rankData,
        accData: accData,
        scoreData: scoreData,
        totalMarks: this.props.data.YourDetails.T,
      });
      // for pie
      // data
      const mydetails = this.props.data["YourDetails"];
      if (mydetails) {
        if (mydetails.P !== undefined)
          this.subjectChartdata["PHYSICS"] = [
            {
              name: "Correct",
              color: "#71C285",
              y: mydetails.PC,
            },
            {
              name: "Wrong",
              color: "#FF6666",
              // color: "orange",
              y: mydetails.PW,
            },
            {
              name: "Un Attempted",
              color: "#93A6A5",
              y: mydetails.PU,
            },
          ];
        if (mydetails.C !== undefined)
          this.subjectChartdata["CHEMISTRY"] = [
            {
              name: "Correct",
              color: "#71C285",
              y: mydetails.CC,
            },
            {
              name: "Wrong",
              color: "#FF6666",
              // color: "orange",
              y: mydetails.CW,
            },
            {
              name: "Un Attempted",
              color: "#93A6A5",
              y: mydetails.CU,
            },
          ];
        if (mydetails.M !== undefined)
          this.subjectChartdata["MATHEMATICS"] = [
            {
              name: "Correct",
              color: "#71C285",
              y: mydetails.MC,
            },
            {
              name: "Wrong",
              color: "#FF6666",
              // color: "orange",
              y: mydetails.MW,
            },
            {
              name: "Un Attempted",
              color: "#93A6A5",
              y: mydetails.MU,
            },
          ];
      }
    }
    // data
    const subjectCharts = {};
    Object.keys(this.subjectChartdata).forEach((subject) => {
      const chartdata = {
        chart: {
          width: 140,
          height: 140,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0,
          },
        },
        title: {
          useHTML: true,
          text: '<b style="font-size:14px">' + subject + "</b>",
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.y}</b>",
        },
        plotOptions: {
          pie: {
            colors: this.pieColors[subject],
            //  showInLegend: showInLegend,
            size: 100,
            dataLabels: {
              enabled: true,
              format: "<b>{point.y}</b>",
              distance: -15,
              color: "white",
              // filter: {
              //     property: 'percentage',
              //     operator: '>',
              //     value: 4
              // }
            },
            // showInLegend: true
          },
        },
        series: [
          {
            name: "Info",
            colorByPoint: true,
            data: this.subjectChartdata[subject],
          },
        ],
      };
      subjectCharts[subject] = chartdata;
      // showInLegend = false;
    });
    this.setState({ subjectCharts: subjectCharts });
  }
  render() {
    const { classes } = this.props;
    console.log(this.state);
    console.log(this.props.paper);
    return (
      <React.Fragment>
        <GridContainer
          justify="space-around"
          style={{ textAlign: "-webkit-center" }}
        >
          <GridItem xs={12} sm={4} md={4} lg={3}>
            <Card className={classes.rankCard}>
              <CardContent>
                <GridContainer
                  justify="space-around"
                  direction="column"
                  alignItems="center"
                >
                  <GridItem>
                    <GridContainer justify="space-around">
                      <GridItem xs={8}>
                        <span className={classes.rankTitle}> RANK</span>
                      </GridItem>
                      <GridItem xs={4} style={{ paddingLeft: "1px" }}>
                        <span className={classes.rankValue}>
                          {" "}
                          {this.state.rankData.rank}
                        </span>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem>
                    <GridContainer
                      direction="row"
                      justify="space-around"
                      alignItems="flex-end"
                    >
                      <GridItem
                        xs={8}
                        style={{ borderRight: "1px solid #96d4fd" }}
                      >
                        <span className={classes.totalStudents}>
                          Total Students
                        </span>
                      </GridItem>
                      <GridItem xs={4}>
                        <span className={classes.totalStudents}>
                          {this.state.rankData.totalStudents}
                        </span>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem style={{ marginTop: "16px" }}>
                    <GridContainer>
                      {Object.keys(this.state.subRanks).map((subKey) => {
                        return (
                          <React.Fragment>
                            <GridItem
                              xs={6}
                              style={{ borderRight: "1px solid #affbf3" }}
                            >
                              <span
                                style={{
                                  padding: "16px",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  color: "white",
                                }}
                              >
                                {" "}
                                {subKey}
                              </span>
                            </GridItem>
                            <GridItem xs={6}>
                              <span
                                style={{
                                  padding: "16px",
                                  color: "white",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                {" "}
                                {this.state.subRanks[subKey]}
                              </span>
                            </GridItem>
                          </React.Fragment>
                        );
                      })}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardContent>
              {/* <CardActionArea>
                            helo
                       </CardActionArea>  */}
            </Card>
          </GridItem>
          {/* Total Score  */}
          <GridItem xs={12} sm={4} md={4} lg={3}>
            <Card className={classes.scoreCard}>
              <CardContent>
                <GridContainer>
                  <GridItem xs={9}>
                    <span className={classes.scoreTitle}>MARKS</span>
                  </GridItem>
                  <GridItem xs={3}>
                    <span className={classes.scoreValue}>
                      {this.state.totalMarks}
                    </span>
                  </GridItem>
                  <GridItem>
                    <GridContainer
                      direction="row"
                      justify="space-around"
                      alignItems="flex-end"
                    >
                      <GridItem
                        xs={9}
                        style={{ borderRight: "1px solid #96d4fd" }}
                      >
                        <span className={classes.totalStudents}>
                          Total Marks
                        </span>
                      </GridItem>
                      <GridItem xs={3}>
                        <span className={classes.tomarks}>
                          {this.papermarks[this.props.paper] !== undefined
                            ? this.papermarks[this.props.paper]
                            : this.papermarks[this.props.test_type] !==
                              undefined
                            ? this.papermarks[this.props.test_type]
                            : "360"}
                        </span>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem style={{ marginTop: "10px" }}>
                    <GridContainer>
                      {/* <GridItem xs={6}>
                                    <span> PHY</span>
                                </GridItem >
                                <GridItem xs={6}>
                                   <span> 3</span>
                                </GridItem>
                                 <GridItem xs={6}>
                                   <span> MAT</span>
                                </GridItem >
                                <GridItem xs={6}>
                                   <span> 25</span>
                                </GridItem> */}
                      {Object.keys(this.state.scoreData).map((scoreKey) => {
                        return (
                          <React.Fragment>
                            <GridItem
                              xs={7}
                              style={{ borderRight: "1px solid #affbf3" }}
                            >
                              <span
                                style={{
                                  padding: "16px",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  color: "white",
                                }}
                              >
                                {" "}
                                {scoreKey}
                              </span>
                            </GridItem>
                            <GridItem xs={5}>
                              <span
                                style={{
                                  padding: "16px",
                                  color: "white",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                {" "}
                                {this.state.scoreData[scoreKey]}
                              </span>
                            </GridItem>
                          </React.Fragment>
                        );
                      })}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardContent>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={4} md={4} lg={3}>
            <Card className={classes.accuracyCard}>
              <CardContent>
                <GridContainer>
                  <GridItem xs={8}>
                    <Hidden only={["sm"]}>
                      <span className={classes.accuracyTitle}>ACCURACY </span>
                    </Hidden>
                    <Hidden mdUp xsDown>
                      <span
                        className={classes.accuracyTitle}
                        style={{ fontSize: "20px" }}
                      >
                        ACCURACY{" "}
                      </span>
                    </Hidden>
                  </GridItem>
                  <GridItem xs={4}>
                    <span
                      className={classes.scoreValue}
                      style={{ fontSize: "30px" }}
                    >
                      {this.state.accData.YourDetails}%
                    </span>
                  </GridItem>
                  <GridItem style={{ marginTop: "38px" }}>
                    <GridContainer>
                      {" "}
                      <GridItem
                        xs={6}
                        style={{
                          borderRight: "1px solid #fbe7d9",
                          paddingBottom: "10px",
                        }}
                      >
                        <span
                          style={{
                            padding: "16px",
                            color: "white",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          Average
                        </span>
                      </GridItem>
                      <GridItem xs={6}>
                        <span
                          style={{
                            padding: "16px",
                            color: "white",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          {this.state.accData.Average}%
                        </span>
                      </GridItem>
                      <GridItem
                        xs={6}
                        style={{ borderRight: "1px solid #fbe7d9" }}
                      >
                        <span
                          style={{
                            padding: "16px",
                            fontWeight: "500",
                            fontSize: "14px",
                            color: "white",
                          }}
                        >
                          Topper
                        </span>
                      </GridItem>
                      <GridItem xs={6}>
                        <span
                          style={{
                            padding: "16px",
                            color: "white",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          {this.state.accData.TopperDetails} %
                        </span>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardContent>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Comparision);
