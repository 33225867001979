import React, { useState, useEffect, useRef } from "react";
import { Box, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import {
  CustomRowType2,
  CustomTableCellType2,
  CustomTableRow,
} from "../../components/CustomTableCells";

import DownloadTable from "../../components/DownloadTable";
import { ExamTab, ExamTabs } from "../../ExamTypeSelectNav";
import store from "store";
import { useStyles } from "../Components/styles";
import atom from "../assets/atom.png";
import testTube from "../assets/test-tube.png";
import calculator from "../assets/calculator.png";
import SmallCard from "../Components/SmallCard";
import Botany from "../assets/Botany.png";
import Zoology from "../assets/Zoology.png";
import BoardTypeTable from "./BoardTypeTable";

const ResultTable = ({ subjects, avgData, results, testTypes }) => {
  const classes = useStyles();
  const InsId = store.get("user").institute;
  const tableRef = useRef(null);
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState([]);
  const [subs, setSubs] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setData(
      results && results.filter((item) => item.test_type === testTypes[value])
    );
  }, [results, testTypes, value, avgData]);
  useEffect(() => {
    if (data !== undefined) {
      if (data[0] !== undefined) {
        setSubs(Object.keys(data[0].result));
      }
    }
  }, [data]);
const boardTypeIndex=testTypes.includes("BOARD")?testTypes.indexOf("BOARD"):null;

  if (results && results.length === 0)
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ height: "100vh" }}
      >
        <Box position="relative" display="inline-flex">
          <CircularProgress />
        </Box>
      </Grid>
    );
  const colorFun = (sub) => {
    if (sub === "MATHEMATICS") {
      return "#994d00";
    }
    if (sub === "PHYSICS") {
      return "#F31288";
    }
    if (sub === "CHEMISTRY") {
      return "#006600";
    }
    if (sub === "BOTANY") {
      return "#3333ff";
    }
    if (sub === "ZOOLOGY") {
      return "#8800cc";
    }
  };
  return (
    <>
      <ExamTabs value={value} onChange={handleChange}>
        {testTypes &&
          testTypes.map((item, index) => <ExamTab label={item} key={index} />)}
      </ExamTabs>
      &nbsp; &nbsp;
      {/* <Box className={classes.TopSmallCardsContainer1}> */}
      { value!==boardTypeIndex &&(
      <Grid item container spacing={3}>
        {subs.includes("PHYSICS") && data.length !== 0 ? (
          <SmallCard
            img={<img src={atom} alt="" style={{ height: "20px" }} />}
            heading="Physics Average"
            value1={
              (
                data.reduce(
                  (acc, curr) => acc + Number(curr.result.PHYSICSPercent || 0),
                  0
                ) / data.length
              ).toFixed(2) || 0
            }
            value2={
              (
                data.reduce(
                  (acc, curr) => acc + (curr.result.PHYSICS || 0),
                  0
                ) / data.length
              ).toFixed(2) || 0
            }
            background="#F31288"
          />
        ) : null}
        {subs.includes("CHEMISTRY") && data.length !== 0 ? (
          <SmallCard
            img={<img src={testTube} alt="" style={{ height: "20px" }} />}
            heading="Chemistry Average"
            value1={
              (
                data.reduce(
                  (acc, curr) =>
                    acc + Number(curr.result.CHEMISTRYPercent || 0),
                  0
                ) / data.length
              ).toFixed(2) || 0
            }
            value2={
              (
                data.reduce(
                  (acc, curr) => acc + (curr.result.CHEMISTRY || 0),
                  0
                ) / data.length
              ).toFixed(2) || 0
            }
            background="#197BBD"
          />
        ) : null}
        {subs.includes("MATHEMATICS") && data.length !== 0 ? (
          <SmallCard
            img={<img src={calculator} alt="" style={{ height: "20px" }} />}
            heading="Maths Average"
            value1={
              (
                data.reduce(
                  (acc, curr) =>
                    acc + Number(curr.result.MATHEMATICSPercent || 0),
                  0
                ) / data.length
              ).toFixed(2) || 0
            }
            value2={(
              data.reduce(
                (acc, curr) => acc + (curr.result.MATHEMATICS || 0),
                0
              ) / data.length
            ).toFixed(2)}
            background="#07AF62"
          />
        ) : null}

        {subs.includes("BOTANY") && data.length !== 0 ? (
          <SmallCard
            img={<img src={Botany} alt="" style={{ height: "20px" }} />}
            heading="Botany Average"
            value1={(
              data.reduce(
                (acc, curr) => acc + Number(curr.result.BOTANYPercent || 0),
                0
              ) / data.length
            ).toFixed(2)}
            value2={(
              data.reduce((acc, curr) => acc + (curr.result.BOTANY || 0), 0) /
              data.length
            ).toFixed(2)}
            background="#6F732F"
          />
        ) : null}
        {subs.includes("ZOOLOGY") && data.length !== 0 ? (
          <SmallCard
            img={<img src={Zoology} alt="" style={{ height: "20px" }} />}
            heading="Zoology Average"
            value1={(
              data.reduce(
                (acc, curr) => acc + Number(curr.result.ZOOLOGYPercent || 0),
                0
              ) / data.length
            ).toFixed(2)}
            value2={(
              data.reduce((acc, curr) => acc + (curr.result.ZOOLOGY || 0), 0) /
              data.length
            ).toFixed(2)}
            background="#00BFB2"
          />
        ) : null}
        {subs && subs.includes("BIOLOGY") && data.length !== 0 ? (
          <SmallCard
            img={<img src={Botany} alt="" style={{ height: "20px" }} />}
            heading="BIOLOGY Average"
            value1={(
              data.reduce(
                (acc, curr) => acc + Number(curr.result.BIOLOGYPercent || 0),
                0
              ) / data.length
            ).toFixed(2)}
            value2={(
              data.reduce(
                (acc, curr) => acc + Number(curr.result.BIOLOGY || 0),
                0
              ) / data.length
            ).toFixed(2)}
            background="#F31288"
          />
        ) : null}
        {testTypes.includes("BOARD") ? (
          <SmallCard
            img={<img src={calculator} alt="" style={{ height: "20px" }} />}
            heading="Total Average"
            value1={avgData[0].percentage || 0}
            value2={avgData[0].Marks || 0}
            // value1={0}
            // value2={0}
            background="#31456A"
          />
        ) : (
          <SmallCard
            img={<img src={calculator} alt="" style={{ height: "20px" }} />}
            heading="Total Average"
            value1={(
              data.reduce(
                (acc, curr) => acc + Number(curr.result.percentage || 0),
                0
              ) / data.length
            ).toFixed(2)}
            value2={(
              data.reduce(
                (acc, curr) => acc + Number(curr.result.Marks || 0),
                0
              ) / data.length
            ).toFixed(2)}
            background="#31456A"
          />
        )}
      </Grid>
      )} 
      &nbsp; &nbsp;
      {value!==boardTypeIndex ? (
        <DownloadTable
          tableId="resultsTable"
          tableName={store.get("user").userId}
          ref={tableRef}
          alignDownloadButtons={true}
        >
          <TableHead>
            <CustomTableRow>
              <CustomTableCellType2
                style={{ fontSize: "17px", color: "#31456A" }}
                colSpan={subjects ? 7 + subjects.length * 3 : null}
              >
                Name : &nbsp;&nbsp; {store.get("user").name}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; User Id : &nbsp;&nbsp;
                {store.get("user").userId}
                &nbsp;&nbsp; &nbsp;&nbsp;
              </CustomTableCellType2>
            </CustomTableRow>
          </TableHead>

          <TableHead>
            <CustomTableRow className={classes.tr}>
              <CustomTableCellType2 className={classes.tableHeaderStyle}>
                S.No:
              </CustomTableCellType2>
              <CustomTableCellType2
                align="left"
                className={classes.tableHeaderStyle}
              >
                Test Name
              </CustomTableCellType2>
              {InsId !== "6422b982a32e9b7c5498b129" &&
                testTypes === "BOARD" && (
                  <CustomTableCellType2 className={classes.tableHeaderStyle}>
                    Course
                  </CustomTableCellType2>
                )}

              {subjects &&
                subjects.map((sub) => (
                  <React.Fragment key={sub}>
                    <CustomTableCellType2 className={classes.tableHeaderStyle}>
                      {sub}
                    </CustomTableCellType2>

                    <CustomTableCellType2 className={classes.tableHeaderStyle}>
                      Rank
                    </CustomTableCellType2>
                    <CustomTableCellType2 className={classes.tableHeaderStyle}>
                      %
                    </CustomTableCellType2>
                  </React.Fragment>
                ))}
              <CustomTableCellType2 className={classes.tableHeaderStyle}>
                Total
              </CustomTableCellType2>
              {InsId !== "6422b982a32e9b7c5498b129" &&
                testTypes === "BOARD" && (
                  <CustomTableCellType2 className={classes.tableHeaderStyle}>
                    Rank
                  </CustomTableCellType2>
                )}

              <CustomTableCellType2 className={classes.tableHeaderStyle}>
                %
              </CustomTableCellType2>
              {InsId !== "6422b982a32e9b7c5498b129" &&
                testTypes === "BOARD" && (
                  <CustomTableCellType2 className={classes.tableHeaderStyle}>
                    ATT
                  </CustomTableCellType2>
                )}
            </CustomTableRow>
          </TableHead>
          <TableBody>
            {data.map((res, i) => (
              <CustomRowType2
                hover
                role="checkbox"
                tabIndex={-1}
                key={res.test_name}
              >
                <CustomTableCellType2>{i + 1}</CustomTableCellType2>
                <CustomTableCellType2 align="left">
                  {res.testDisplayName}
                </CustomTableCellType2>
                {InsId !== "6422b982a32e9b7c5498b129" &&
                  testTypes === "BOARD" && (
                    <CustomTableCellType2>{res.test_type}</CustomTableCellType2>
                  )}

                {subjects &&
                  subjects.map((sub) => {
                    if (res.result === "NA") {
                      return (
                        <React.Fragment>
                          <CustomTableCellType2>A</CustomTableCellType2>
                          <CustomTableCellType2>A</CustomTableCellType2>
                          <CustomTableCellType2>A</CustomTableCellType2>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment>
                          <CustomTableCellType2
                            style={{ color: colorFun(sub) }}
                          >
                            {res.result[sub]}
                          </CustomTableCellType2>

                          <CustomTableCellType2
                            style={{ color: colorFun(sub) }}
                          >
                            {res.result[sub + "Rank"]}
                          </CustomTableCellType2>
                          <CustomTableCellType2
                            style={{ color: colorFun(sub) }}
                          >
                            {res.result[sub + "Percent"]}
                          </CustomTableCellType2>
                        </React.Fragment>
                      );
                    }
                  })}
                {res.result === "NA" ? (
                  <React.Fragment>
                    <CustomTableCellType2>A</CustomTableCellType2>
                    <CustomTableCellType2>A</CustomTableCellType2>
                    {InsId !== "6422b982a32e9b7c5498b129" &&
                      testTypes === "BOARD" && (
                        <>
                          <CustomTableCellType2>A</CustomTableCellType2>
                          <CustomTableCellType2>0</CustomTableCellType2>
                        </>
                      )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <CustomTableCellType2
                      style={{
                        color: "#ffa31a",

                        fontWeight: 600,
                      }}
                    >{`${res.result.Marks}/${
                      res.result.total
                    }`}</CustomTableCellType2>
                    {InsId !== "6422b982a32e9b7c5498b129" &&
                      testTypes === "BOARD" && (
                        <CustomTableCellType2
                          style={{
                            color: "#ffa31a",

                            fontWeight: 600,
                          }}
                        >
                          {res.result.rank}
                        </CustomTableCellType2>
                      )}

                    <CustomTableCellType2
                      style={{
                        color: "#ffa31a",

                        fontWeight: 600,
                      }}
                    >
                      {res.result.percentage}
                    </CustomTableCellType2>
                    {InsId !== "6422b982a32e9b7c5498b129" &&
                      testTypes === "BOARD" && (
                        <CustomTableCellType2
                          style={{
                            color: "red",

                            fontWeight: 600,
                          }}
                        >
                          {res.result.ATT}
                        </CustomTableCellType2>
                      )}
                  </React.Fragment>
                )}
              </CustomRowType2>
            ))}
            {avgData &&
              avgData.map((res) => (
                <CustomRowType2
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={res.test_type}
                >
                  <CustomTableCellType2 />
                  <CustomTableCellType2 align="left">
                    Average
                  </CustomTableCellType2>
                  {InsId !== "6422b982a32e9b7c5498b129" &&
                    testTypes === "BOARD" && (
                      <CustomTableCellType2>
                        {res.test_type}
                      </CustomTableCellType2>
                    )}

                  {subjects.map((sub) => (
                    <React.Fragment>
                      <CustomTableCellType2>{res[sub]}</CustomTableCellType2>
                      <CustomTableCellType2>-</CustomTableCellType2>
                      <CustomTableCellType2>
                        {res[sub + "Percent"]}
                      </CustomTableCellType2>
                    </React.Fragment>
                  ))}
                  <CustomTableCellType2>{`${res.Marks}/${
                    res.total
                  }`}</CustomTableCellType2>
                  {InsId !== "6422b982a32e9b7c5498b129" &&
                    testTypes === "BOARD" && (
                      <CustomTableCellType2>-</CustomTableCellType2>
                    )}

                  <CustomTableCellType2>{res.percentage}</CustomTableCellType2>
                  {InsId !== "6422b982a32e9b7c5498b129" &&
                    testTypes === "BOARD" && (
                      <CustomTableCellType2>{res.ATT}</CustomTableCellType2>
                    )}
                </CustomRowType2>
              ))}
          </TableBody>
        </DownloadTable>
      ) : (
        <BoardTypeTable data={data} />
      )}
    </>
  );
};

export default ResultTable;
