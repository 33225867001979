// App.js
import React, { useState, useRef, useEffect } from "react";

const OTPInput = ({ length = 6, handleOtpChange, containerStyles }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputs = useRef([]);

  const handleChange = (e, index) => {
    const { value } = e.target;

    // Only allow single digit input
    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input
      if (index < length - 1) {
        inputs.current[index + 1].focus();
      }
    }

    if (value.length > 1) {
      const newOtp = [...otp];
      newOtp[index] = value[1];
      setOtp(newOtp);

      if (index < length - 1) {
        inputs.current[index + 1].focus();
      }
    }

    // Move focus to previous input on backspace
    if (value === "" && index > 0) {
      inputs.current[index - 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      // Move focus to previous input on backspace
      if (index > 0) {
        inputs.current[index - 1].focus();
      }
    }
  };

  useEffect(() => {
    handleOtpChange(otp.join(""));
    console.log("🚀 ~ useEffect ~ otp:", otp.join(""));
  }, [otp]);

  return (
    <div style={{ display: "flex", justifyContent: "center", ...containerStyles }}>
      {otp.map((_, index) => (
        <input
          key={index}
          type="text"
          value={otp[index]}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputs.current[index] = el)}
          style={{
            width: "40px",
            height: "40px",
            margin: "0 5px",
            textAlign: "center",
            fontSize: "18px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        />
      ))}
    </div>
  );
};

export default OTPInput;
