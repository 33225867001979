export default {
    "5e7370083d8520a40c4ceaaa": "bhashyamadmin.onesaz.com",
    "65772522ee64cfa8dc9851f2": "srgroup.onesaz.com",
    "6655751f14c7f8079c692091": "srexpenses.onesaz.com",
    "5e9098aaa218252d49be5a3b": "sradmin.onesaz.com",
    "5ee85fc60f25a93146ea0bf9": "sriabhidaadmin.onesaz.com",
    "5ee85fc60f25a93146ea0bg9": "sriabhidaattapuradmin.onesaz.com",
    "5ea3572fe301133d0a73e9e0": "miityadmin.onesaz.com",
    "5dc1388b22bdc209de6a4d80": "viitjeeadmin.onesaz.com",
    "5e9b3d6cdbae145f55d1e712": "vinnersadmin.onesaz.com",
    "645b4332b98a5dc4ce54256d": "vosadmin.onesaz.com",
    "5d679d49c136660a09596d85": "admin.onesaz.com",
    "5fd25b12ed14200ff702f738": "tirumalaadmin.onesaz.com",
    "602f25174f379c03fa0f7737": "mediconadmin.onesaz.com",
    "608ea4eea4105bb4fbc94822": "impulseadmin.onesaz.com",
    "61db4c3da28e11700a6064ac": "sandeepaniadmin.onesaz.com",
    "61e6489a9b609f2143aab796": "aiatadmin.onesaz.com",
    "5e521e0da587c5bcefdb7c90": "valleyoakadmin.onesaz.com",
    "62b317ab4406d92c11fd4257": "scjcadmin.onesaz.com",
    "5dd2de6fc539670ff56e37fc": "pjcmncladmin.onesaz.com",
    "61e850055876f313e30ea1de": "agastyaadmin.onesaz.com",
    "63a943927e91069155cc07da": "edifyadmin.onesaz.com",
    "6422b982a32e9b7c5498b129": "lpjcadmin.onesaz.com",
    "646634c8a400ecffc1306d0d": "turitoadmin.onesaz.com",
    "5ee85fc60f25a93146ea0bg1": "pinegroveadmin.onesaz.com",
    "5ee85fc60f25a93146ea0n4o": "neutrinoadmin.onesaz.com",
    "5ee85fc60f25a93146ea0a4h": "vijnaadmin.onesaz.com",
    "5ee85fc60f25a93146ea0ayt": "ayatiadmin.onesaz.com",
    "5ee85fc60f25a93146ea0s01n": "sundaradmin.onesaz.com",
    "5ee85fc60f25a93146ea0coz": "cognizantadmin.onesaz.com",
    "6617918287bfb9f18b6d32a5": "ybrantadmin.onesaz.com",
    "5ee85fc60f25a93146ea0meta": "metamindadmin.onesaz.com",
    "66c430d428cbcc7c97d22a27": "motionadmin.onesaz.com",
    "66d6f203d1e9e56a0955f6b5": "kle.onesaz.com",
    "66d7fd5ed38d3afac6db0696": "excellenciaadmin.onesaz.com",
}
