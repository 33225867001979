import React from "react";
import { motion } from "framer-motion";
import { Grid, Typography, Box } from "@material-ui/core";
import app from "./logos/app.png";
import MobilePhone from "./PhoneNumber";
import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";
import { makeStyles } from "@material-ui/core/styles";
import Fearures from "./logos/Fearures bg.png";
import Button from "components/CustomButtons/Button.jsx";
import footerBackground from "./logos/footeBackground.svg";
import footerLeaves from "./logos/footerLeaves.svg";
import FooterTreeView from "./FooterTreeViewItems";
import Footer from "../Footer";

const useStyles = makeStyles((theme) => ({
  customGooglePlayButton: {
    "& .custom-style": {
      fontSize: "10px", // Adjust font size as needed

      height: "15px",
    },
    "& img": {
      width: "20px", // Adjust logo width as needed
      height: "20px", // Adjust logo height as needed
    },
  },
  tryFreeButton: {
    background: "#3BBDED",
    boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #3BBDED",
    borderRadius: "12px",
    textTransform: "none",
    height: "50px",
    width: "170px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    marginRight: "80px",
    justifyContent: "center",
    alignContent: "center",
    align: "center",
    "&:hover": {
      background: "#3BBDED",
      transform: "scale(1.5)",
    },
  },
  appScreenShot: {
    animation: "$move 1s ease-in",
    backgroundImage: `url("${Fearures}")`, // Set the SVG image as the background
    backgroundSize: "cover",
    height: "300px !important",
    display: "block",
    alignItems: "center",
    padding: "14px",
    overflow: "visible",
    marginLeft: "14%",
    marginRight: "14%",
    borderRadius: "24px",
    position: "relative", // Add this
    zIndex: 1, // Add this to make sure it appears above the backgroundDiv
  },
  backgroundDiv: {
    width: "100%", // Set the width of the background div
    height: "1550px", // Set the height of the background div
    background: "#172133",
    // backgroundImage: `url("${footerBackground}")`, // Set the SVG image as the background
    backgroundSize: "cover", // Use 'contain' instead of 'cover'
    position: "relative", // Add this
    marginTop: "-200px", // Half of the height of appScreenShot
  },
  lastFooterHeading: {
    background:
      "linear-gradient(180.77deg, #1D2A40 0.63%, rgba(23, 33, 51, 0) 99.3%)",
    width: "200px",
    height: "382px",
    position: "relative",
    padding: "14px",
    margin: "100px",
    // top: "1120px",
    // left: "67px",
    // gap: "0px",
    borderRadius: "24px 24px 0px 0px",
    border: "1px solid #415B8C",
    // borderTop: "0px",
    // opacity: "0",
  },
}));
function App() {
  const classes = useStyles();
  const animationStyles = {
    animationDuration: "3s",
    // width: "auto",
    // height: "auto",
    whiteSpace: "pre",
    fontWeight: 800,
    fontStyle: "normal",
    fontFamily: "Montserrat",
    color: "#31456a",
    fontSize: "35px",
  };
  const android = "https://play.google.com/store/search?q=onesaz&c=apps";
  const iOSUrl = "https://apps.apple.com/in/app/onesaz-admin/id6470154453";
  const [showBox, setShowBox] = React.useState(false);
  const handleButtonClick = () => {
    // Scroll to the top of the page
    window.scrollTo({ top: 7000, behavior: "smooth" });
  };
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowBox(true);
    } else {
      setShowBox(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div>
        <Grid container spacing>
          <Grid
            item
            xs={12}
            container
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <div style={{ ...animationStyles }}>
              Enjoy a seamless experience with our
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            container
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <div style={{ ...animationStyles }}>
              <b style={{ color: "red" }}>Simplified</b> app
            </div>
          </Grid>
          <br />
          <Grid
            item
            xs={12}
            container
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Typography variant="body2">
              Watch the live classes anytime and anywhere
            </Typography>
          </Grid>
          <br />
          <br />
          <Grid
            item
            xs={12}
            container
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <img src={app} alt="appImg" />
          </Grid>
          <Grid
            item
            xs={12}
            container
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Typography style={{ fontSize: "20px", fontWeight: 700 }}>
              Get the App
            </Typography>
          </Grid>
          &nbsp; &nbsp;
          <Grid
            item
            xs={12}
            container
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <MobilePhone />
          </Grid>
          &nbsp; &nbsp;&nbsp; &nbsp;
          <Grid
            item
            xs={12}
            container
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Box display="flex">
              <div className={classes.customGooglePlayButton}>
                <GooglePlayButton
                  url={android}
                  theme={"dark"}
                  className={"custom-style"}
                />
              </div>
              &nbsp; &nbsp;
              <div className={classes.customGooglePlayButton}>
                <AppStoreButton
                  url={iOSUrl}
                  theme={"dark"}
                  className={"custom-style"}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
      <br />
      <br />
      <br />
      <div className={classes.appScreenShot}>
        <Grid
          container
          style={{
            marginTop: "30px",
            marginBottom: "30px",
            marginBottom: "36px",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
          spacing={4}
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                color: "#ffffff",
                fontWeight: 900,
                fontStyle: "normal",
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              Join ONESAZ in shaping the future of learning
            </Typography>
          </Grid>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{
                color: "#ffffff",
                fontWeight: 900,
                fontStyle: "normal",
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              Experience innovation,elevate education
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              className={classes.tryFreeButton}
              onClick={handleButtonClick}
            >
              Try it Now
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className={classes.backgroundDiv}>
        <br />
        <br />
        <br />
        <br />
        <Grid container spacing={4} style={{ paddingTop: "14px" }}>
          <Grid item xs={12}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Typography
              variant="h2"
              style={{
                color: "#ffffff",
                fontWeight: 900,
                fontStyle: "normal",
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              Frequently asked questions
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{
                color: "#ffffff",
                fontWeight: 600,
                fontStyle: "normal",
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              Here are few questions we get regularly from the users
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              color: "#ffffff",
              fontWeight: 900,
              fontStyle: "normal",
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <FooterTreeView />
          </Grid>
          <Grid item xs={12} className={classes.lastFooterHeading}>
            <Typography
              variant="body1"
              style={{
                color: "#ffffff",
                fontWeight: 600,
                fontStyle: "normal",
                fontFamily: "Montserrat, sans-serif",
                textAlign: "right",
                alignItems: "flex-end",
              }}
            >
              <img
                src={footerLeaves}
                alt="footerLeaves"
                style={{ marginTop: "-100px" }}
              />
            </Typography>
            <Footer />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
export default App;
