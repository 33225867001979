import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Container,
  Typography,
  Paper,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logoUrlMap from "../../components/LogoURLMap";
import { hostnamesMap } from "../../components/LogoURLMap";
import { insertEnquiry } from "../../apis/UserService";
import {
  CheckCircleOutline as SuccessIcon,
  ErrorOutline as ErrorIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[6],
    width: "100%",
    maxWidth: "500px",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      boxShadow: "none",
    },
  },
  logo: {
    height: theme.spacing(10),
    marginBottom: theme.spacing(2),
    objectFit: "contain",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const EnquiryForm = () => {
  const classes = useStyles();
  const hostname = window.location.hostname;
  const instituteId = hostnamesMap[hostname];
  const logoData = logoUrlMap[instituteId];
  const [open, setOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");

  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    studentName: Yup.string()
      .required("Student name is required")
      .min(2, "Name must be at least 2 characters"),
    fatherName: Yup.string()
      .required("Father's name is required")
      .min(2, "Name must be at least 2 characters"),
    schoolName: Yup.string()
      .required("School name is required")
      .min(2, "School name must be at least 2 characters"),
    schoolArea: Yup.string()
      .required("School area is required")
      .min(2, "School area must be at least 2 characters"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const response = await insertEnquiry({ ...values, instituteId });
    console.log(response);
    if (response.status === 200 && response.data.status === "SUCCESS") {
      setDialogMessage("SUCCESS");
      resetForm();
    } else {
      setDialogMessage(
        response.data.message || "An error occurred. Please try again."
      );
    }
    setOpen(true);
    setSubmitting(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Paper elevation={6} className={classes.paper}>
          {logoData && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={3}
            >
              <img
                src={logoData.url}
                alt={logoData.title}
                className={classes.logo}
              />
              <Typography variant="body1" color="primary">
                {logoData.title}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
              >
                Enquiry Form
              </Typography>
            </Box>
          )}

          <Formik
            initialValues={{
              phoneNumber: "",
              whatsappNo: "",
              studentName: "",
              fatherName: "",
              schoolName: "",
              schoolArea: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, isSubmitting, handleChange }) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  {[
                    { name: "phoneNumber", label: "Phone Number" },
                    { name: "studentName", label: "Student Name" },
                    { name: "fatherName", label: "Father's Name" },
                    { name: "schoolName", label: "School Name" },
                    { name: "schoolArea", label: "School Area", multiline: true, rows: 4 },
                  ].map(({ name, label, multiline, rows }) => (
                    <Grid item xs={12} key={name}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id={name}
                        name={name}
                        label={label}
                        value={values[name]}
                        autoComplete={name}
                        className={classes.textField}
                        as={Field}
                        error={errors[name] && touched[name]}
                        helperText={
                          errors[name] && touched[name] && errors[name]
                        }
                        onChange={handleChange}
                        multiline={multiline}
                        rows={rows}
                      />
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Container>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{"Submission Status"}</DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {dialogMessage === "SUCCESS" ? (
            <SuccessIcon style={{ fontSize: 40, color: "green" }} />
          ) : (
            <ErrorIcon style={{ fontSize: 40, color: "red" }} />
          )}
          <DialogContentText
            style={{
              marginTop: 16,
              color: dialogMessage === "SUCCESS" ? "green" : "red",
            }}
          >
            {dialogMessage === "SUCCESS" ? "Form submitted successfully!, Our representative will contact you soon." : dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EnquiryForm;
