import { Grid, Paper, Typography, Hidden, Badge } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme, withStyles, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState, useLayoutEffect, useEffect } from "react";
import classNames from "classnames";
import resolveMatrixAns from "../../utils/resolveMatrixAns";
import CauseErrorMenu from "./CauseErrorMenu";

const useStyle = makeStyles((theme) => ({
  correctAns: {
    color: theme.palette.action.selected,
  },
  unAttempted: {
    color: theme.palette.text.secondary,
  },
  wrong: {
    color: theme.palette.text.primary,
  },
  partial_correct: {
    color: theme.palette.warning.dark,
  },
  statusText: {
    textTransform: "capitalize",
  },
  badge: { display: "block" },
  optionWrapperDiv: {
    margin: theme.spacing(1),
    borderRadius: "10px",
    background: theme.palette.background.paper,
    boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    position: "relative",
  },
  ansCount: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  overlayOptions: {
    position: "absolute",
    height: "-webkit-fill-available",
    opacity: "0.3",
    zIndex: 10,
    borderRadius: "10px 0px 0px 10px",
    margin: "-16px 0px 2px -10px",
  },
  legendColor: {
    color: theme.palette.text.secondary,
    fontSize: "small",
  },
  optionWeight: {
    fontWeight: "bold",
  },
}));
// custom option component
const PaperComponent = withStyles((theme) => ({
  root: {
    padding: "20px 0 28px 12px",
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
  },
}))((props) => <Paper {...props} />);

function SingleQuestionPopUp(props) {
  const [questionData, setQuestionData] = useState({});
  const theme = useTheme();
  const classes = useStyle(props);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  let totalClass; //styling for correct answers
  if (questionData && questionData.status === "correct") {
    totalClass = classes.correctAns;
  }
  if (questionData && questionData.status === "unattempted") {
    totalClass = classes.unAttempted;
  }
  if (questionData && questionData.status === "partial_correct") {
    totalClass = classes.partial_correct;
  }
  useLayoutEffect(() => {
    const math = document.getElementById("responsive-dialog-title");
    if (math) {
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, math, () => {}]);
    }
  });
  useEffect(() => {
    setQuestionData(props.questionData);
  }, [props]);
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {/* header  */}

        <DialogTitle>
          <Grid container>
            <Grid item xs={4} sm={4}>
              <Hidden only={["xs"]}>
                <Typography variant="subtitle1">
                  Question No.{questionData && questionData.questionNo}
                </Typography>
              </Hidden>
              &nbsp;&nbsp;
              {questionData && questionData.status === "wrong" && (
                <CauseErrorMenu />
              )}
              <Hidden only={["sm", "md", "xl", "lg"]}>
                <Typography variant="subtitle1">
                  #{questionData && questionData.questionNo}
                </Typography>
              </Hidden>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Typography
                align="center"
                className={classNames(totalClass, classes.statusText)}
              >
                {questionData && questionData.status}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Typography align="right">
                <CloseIcon fontSize="inherit" onClick={props.handleClose} />
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>

        {/* question content  */}

        <DialogContent id="responsive-dialog-title">
          <DialogContentText>
            <Typography
              color="textPrimary"
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: questionData && questionData.paragraph,
              }}
            />
          </DialogContentText>
          <br />
          <DialogContentText>
            <Typography
              color="textPrimary"
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: questionData && questionData.questionText,
              }}
            />
          </DialogContentText>
          <br />
          {/* options  */}
          {questionData &&
          questionData.options &&
          questionData.options.A &&
          questionData.options.A !== "NA" &&
          ![
            "float",
            "float_p",
            "integer",
            "integer_p",
            "positive_integer",
          ].includes(questionData.q_type) ? (
            Object.keys(questionData.options).map((keyName) => {
              const answeredPercent =
                questionData.studentAnswersCount &&
                questionData.studentAnswersCount[keyName]
                  ? (
                      (questionData.studentAnswersCount[keyName] /
                        props.strength) *
                      100
                    ).toFixed(2)
                  : 0;
              let border = {
                  border: `2px solid ${theme.palette.grey[500]}`,
                },
                correctColor,
                partial,
                overlayBackground = theme.palette.grey[400];
              if (
                ["partial", "partial_p"].includes(questionData.q_type) &&
                questionData.markedAns.includes(keyName) &&
                questionData.markedAns !== "NA"
              ) {
                partial = true;
              }
              if (
                questionData.correctAns.includes(keyName) ||
                (questionData.status === "correct" &&
                  questionData.markedAns.includes(keyName))
              ) {
                border = {
                  border: `2px solid ${theme.palette.action.selected}`,
                };
                correctColor = classes.correctAns;
                overlayBackground = theme.palette.info.light;
              }
              if (
                questionData.markedAns.includes(keyName) &&
                questionData.status === "wrong"
              ) {
                border = {
                  border: `2px solid ${theme.palette.text.primary}`,
                };
                overlayBackground = theme.palette.text.primary;
              }
              return (
                <fieldset
                  key={keyName}
                  style={border}
                  className={classes.optionWrapperDiv}
                >
                  <div
                    className={classes.overlayOptions}
                    style={{
                      width: `${answeredPercent}%`,
                      backgroundColor: overlayBackground,
                    }}
                  />
                  <legend align="right" className={classes.legendColor}>
                    {`${answeredPercent}% marked this`}
                  </legend>

                  <Badge
                    className={classes.badge}
                    color="primary"
                    variant={partial ? "dot" : null}
                  >
                    <PaperComponent elevation={0}>
                      <span
                        className={classNames(
                          correctColor,
                          classes.optionWeight
                        )}
                      >
                        {keyName}
                      </span>
                      &nbsp;
                      <Typography
                        component="span"
                        variant="body1"
                        color="textPrimary"
                        dangerouslySetInnerHTML={{
                          __html: questionData.options[keyName],
                        }}
                      />
                    </PaperComponent>
                  </Badge>
                </fieldset>
              );
            })
          ) : (
            <PaperComponent elevation={1}>
              Your answer:&nbsp;
              <Typography
                component="span"
                align="right"
                className={
                  questionData && questionData.status === "correct"
                    ? classes.correctAns
                    : classes.wrong
                }
                dangerouslySetInnerHTML={{
                  __html:
                    questionData &&
                    questionData.markedAns &&
                    typeof questionData.markedAns === "string"
                      ? questionData.markedAns
                      : resolveMatrixAns(
                          questionData ? questionData["markedAns"] : {}
                        ),
                }}
              />
              <br />
              Correct answer:&nbsp;
              <Typography
                component="span"
                align="right"
                className={classes.correctAns}
                dangerouslySetInnerHTML={{
                  __html: questionData && questionData.correctAns,
                }}
              />
            </PaperComponent>
          )}
          <br />
          {/* show solution  */}
          {questionData && questionData.solution ? (
            <DialogContentText>
              <Typography
                color="textPrimary"
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html:
                    questionData && `Solution: <br/>${questionData.solution}`,
                }}
              />
            </DialogContentText>
          ) : null}
          <br />
        </DialogContent>
        {/* score gained  */}
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                Score
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h3"
                className={totalClass}
                color="textPrimary"
              >
                {questionData && questionData.status === "wrong"
                  ? `${questionData && questionData.marks}`
                  : questionData && questionData.marks && questionData.marks > 0
                  ? `+${questionData.marks}`
                  : questionData && questionData.marks}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
      </Dialog>
    </div>
  );
}
export default SingleQuestionPopUp;
