import ExamSummaryAdv from "mycomponents/ExamSummaryAdv";
import InstructionNeet from "mycomponents/InstructionNeet";
import StudentPremium from "mycomponents/StudentPremium";
import IframePlayer from "mycomponents/videoclasses/IframePlayer";
import Live from "mycomponents/videoclasses/Live";
import LiveClasses from "mycomponents/videoclasses/LiveClasses";
import VideoClasses from "mycomponents/videoclasses/VideoClasses";
import ZoomClasses from "mycomponents/videoclasses/ZoomClasses";
import WorkSheet from "mycomponents/WorkSheet";
import WorkSheets from "mycomponents/WorkSheets";
import React, { Component } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import store from "store";
import { domainConfig } from "../apis/UserService";
// import UploadQuestionPaper from './views/UploadQuestionPaper';
import verify, { regGuard } from "../hoc/authGaurd";
import Plans from "../mycomponents/components/Plans";
import DetailView from "../mycomponents/DetailView";
import ExamPortal from "../mycomponents/ExamPortal";
import ExamSummary from "../mycomponents/ExamSummary";
import Home from "../mycomponents/Home";
import Dashboard from "../mycomponents/Dashboard";
import Attendance from "../mycomponents/Attendance";
import MakePayment from "../mycomponents/MakePayment";
import Payment from "../mycomponents/Payment";
import Instruction from "../mycomponents/Instruction";
import InstructionAdv from "../mycomponents/InstructionAdv";
import Profile from "../mycomponents/Profile";
import Results from "../mycomponents/Results";
import DoubtSession from "../mycomponents/videoclasses/DoubtSession";
import Previous from "../mycomponents/videoclasses/previous";
import PreviousVideos from "../mycomponents/videoclasses/PreviousVideos";
import PreviousVideosMobile from "../mycomponents/videoclasses/PreviousVideosMobile";
import VideoClassesMobile from "../mycomponents/videoclasses/VideoClassesMobile";
// import UnderDevelopment from './views/UnderDevelopment';
import NotFound from "../views/NotFound/NotFound";
import SectionsPage from "../views/SectionsPage/SectionsPage.jsx";

import createNewPassword from "../views/SignIn/Forgot";
import Register from "../views/SignIn/Register";
// B-SAT adverting
import BhashyamLanding from "../views/SignIn/BhashyamLanding";

// Views

// import ProductList from './views/ProductList';
// import UserList from './views/UserList';
// import Typography from './views/Typography';
// import Icons from './views/Icons';
// import Account from './views/Account';
// import Settings from './views/Settings';
// import SignUp from './views/SignUp';
import SignIn from "../views/SignIn/SignIn";
import PrivateRoute from "./PrivateRoute";
import SessionExpired from "../mycomponents/SessionExpired";
import AboutUs from "../views/SectionsPage/FooterComponents/AboutUs";
import PracticePage from "../mycomponents/PracticePage/Index";
import ResultPage from "../mycomponents/resultPage/Index";
import MatchedPwd from "../mycomponents/MatchedPwd";
import PracticeSet from "../mycomponents/PracticePage/PracticeSet/PracticeSet";
import NotificationPage from "../mycomponents/inAppNotification/NotificationPage";
import ExamExpired from "../mycomponents/ExamExpired";
import ZoomLiveMeetingApp from "../mycomponents/videoclasses/ZoomLiveMeetingApp";
import Assignment from "../mycomponents/videoclasses/Assignment";
import Discuss from "../mycomponents/Discuss";
// for onesaz-chat
import ChatPage from "../views/ChatPage";
import PDFAnswerArea from "../mycomponents/PDFAnswerArea";
import PDFTest from "../mycomponents/PDFTest";
import InvoiceBox from "../mycomponents/MakePayment/components/InvoiceBox";
import ChildrenAccounts from "../mycomponents/ChildrenAccounts";
import HomePage from "../mycomponents/HomePage";
import FeeDetails from "../views/FeeDetails/index.js";
import MyProfile from "../mycomponents/HomePage/Profile";
import PacketMoney from "../mycomponents/HomePage/PocketMoney";
import ProgressCard from "../mycomponents/HomePage/ProgressCard";
import MyClassRoom from "../mycomponents/HomePage/MyClassRoom";
import Layout from "../mycomponents/HomePage/Layout.jsx";
import ExamAnalysis from "../mycomponents/ExamAnalysis/index.jsx";
import TopicWiseAnalysis from "../mycomponents/HomePage/TopicWiseAnalysis";
import LeaveApplication from "../mycomponents/Attendance/LeaveApplication";
import TeacherProfile from "../mycomponents/HomePage/MyClassRoom/TeacherProfile";
// Registration form
import NewRegistration from "../views/Registration/index.jsx";
import PersonalDetails from "../views/Registration/PersonalDetails.jsx";
import ParentDetails from "../views/Registration/ParentDetails.jsx";
import PreviousSchoolDetailsForm from "../views/Registration/PreviousSchoolDetails.jsx";
import AddressDetailsForm from "../views/Registration/AddressDetails.jsx";
import AvatarForm from "../views/Registration/AvatarForm.jsx";
import ReviewPage from "../views/Registration/ReviewPage";
import RegistrationLogInPage from "../views/Registration/RegistrationLogInPage.jsx";
import RegDashBoard from "../views/Registration/RegDashBoard.jsx";
import EnquiryForm from "../views/EnquiryForm";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.header = "";
  }
  loadConfig = async () => {
    let data = await domainConfig(window.location.host);
    if (data.status == 200) {
      data = data.data;
      store.set("domainConfig", data);
    }
  };

  componentDidMount() {
    this.loadConfig();
  }

  render() {
    return (
      <>
        {/* {window.location.href.split(/\b\/(.)\b/).length > 1 ? "" : <Header/>} */}

        <Switch>
          <BrowserRouter>
            <Layout>
              <Route
                component={NewRegistration}
                exact
                path="/new-registration"
              />
              <Route
                component={RegistrationLogInPage}
                exact
                path="/reg-login"
              />
              <Route component={regGuard(ReviewPage)} exact path="/review" />
              <Route
                component={regGuard(PersonalDetails)}
                exact
                path="/student-details"
              />
              <Route
                component={regGuard(ParentDetails)}
                exact
                path="/parent-details"
              />
              <Route
                component={regGuard(PreviousSchoolDetailsForm)}
                exact
                path="/previous-school-details"
              />
              <Route
                component={regGuard(AddressDetailsForm)}
                exact
                path="/address"
              />
              <Route
                component={regGuard(AvatarForm)}
                exact
                path="/avatar"
              />
              <Route component={SignIn} exact path="/sign-in" />
              <Route component={verify(SectionsPage)} exact path="/" />
              <Route component={AboutUs} exact path="/FooterAboutUs" />
              <Route component={Register} exact path="/register" />
              <Route component={verify(WorkSheets)} exact path="/worksheets" />
              <Route component={WorkSheet} exact path="/ws" />
              <Route component={verify(HomePage)} exact path="/home-page" />
              <Route component={verify(MyClassRoom)} exact path="/classroom" />
              <Route component={verify(FeeDetails)} exact path="/fee-details" />
              <Route
                component={verify(PacketMoney)}
                exact
                path="/pocket-money"
              />
              <Route component={verify(MyProfile)} exact path="/view-profile" />
              <Route
                component={verify(TeacherProfile)}
                exact
                path="/teacher-profile/:id"
              />

              <Route
                component={verify(ProgressCard)}
                exact
                path="/progress-card"
              />
              <Route
                component={verify(TopicWiseAnalysis)}
                exact
                path="/topicWiseAnalysis"
              />
              <Route
                component={BhashyamLanding}
                exact
                path="/b-sat-registration"
              />
              <Route component={StudentPremium} exact path="/student-premium" />
              <PrivateRoute
                component={verify(ExamPortal)}
                exact
                path="/exam-portal"
              />
              <Route component={verify(Dashboard)} exact path="/dashboard" />
              <Route component={verify(Attendance)} exact path="/attendance" />
              <Route
                component={verify(MakePayment)}
                exact
                path="/make-payment"
              />
              <Route component={verify(InvoiceBox)} exact path="/invoice" />

              <Route component={verify(Payment)} exact path="/payment" />
              <Route component={verify(Home)} exact path="/home" />
              <Route
                component={verify(ChildrenAccounts)}
                exact
                path="/parent-profile"
              />

              <Route
                component={verify(VideoClasses)}
                exact
                path="/previous-classes"
              />
              <Route component={verify(Profile)} exact path="/profile" />
              <Route
                component={verify(LeaveApplication)}
                exact
                path="/leave-application"
              />
              <Route component={verify(DoubtSession)} exact path="/queries" />
              <Route
                component={verify(PreviousVideos)}
                exact
                path="/play-video"
              />
              <Route component={verify(Live)} exact path="/live-class" />
              <Route
                component={verify(ZoomLiveMeetingApp)}
                exact
                path="/live-zoom-meeting"
              />

              <Route
                component={verify(LiveClasses)}
                exact
                path="/video-classes"
              />
              <Route component={verify(IframePlayer)} exact path="/play" />
              <Route
                component={verify(Instruction)}
                exact
                path="/instruction"
              />
              <Route
                component={verify(InstructionAdv)}
                exact
                path="/instructionadv"
              />
              <Route
                component={verify(InstructionNeet)}
                exact
                path="/instructionneet"
              />
              <Route component={verify(Previous)} exact path="/previous" />
              <Route
                component={verify(ZoomClasses)}
                exact
                path="/interactive-classes"
              />
              <Route component={NotFound} exact path="/not-found" />
              <Route
                component={verify(ExamSummary)}
                exact
                path="/exam-summary"
              />
              <Route
                component={verify(ExamSummaryAdv)}
                exact
                path="/exam-summaryadv"
              />
              <Route component={verify(Results)} exact path="/results" />
              <Route component={verify(DetailView)} exact path="/view" />
              <Route
                component={ResultPage}
                exact
                path="/:test_name/new-result"
              />
              {/*B-SAT advertising */}

              <Route component={createNewPassword} exact path="/forgot" />
              <Route component={Plans} exact path="/plans" />

              <Route component={createNewPassword} exact path="/forgot" />
              <Route component={Plans} exact path="/plans" />

              {/* For Webview  in mobile */}
              <Route
                component={verify(VideoClassesMobile)}
                exact
                path="/m/previous-classes"
              />
              <Route
                component={verify(PreviousVideosMobile)}
                exact
                path="/m/play-video"
              />
              <Route component={verify(PracticePage)} exact path="/practice" />
              <Route
                component={verify(PracticeSet)}
                exact
                path="/practice/:subject"
              />
              <Route
                component={verify(MatchedPwd)}
                exact
                path="/id-and-password-matched"
              />
              {/* notification page */}
              <Route
                component={verify(NotificationPage)}
                exact
                path="/notification"
              />
              <Route component={verify(Assignment)} exact path="/assignment" />
              {/* <Route component={verify(Discuss)} exact path="/doubts" /> */}
              {/* onesaz chat */}
              <Route component={verify(ChatPage)} exact path="/doubts" />
              <Route component={SessionExpired} exact path="/session-expired" />
              <Route component={ExamExpired} exact path="/exam-expired" />
              <Route component={PDFAnswerArea} exact path="/pdfExam" />
              <Route component={PDFTest} exact path="/writePDFtest" />
              <Route
                component={verify(ExamAnalysis)}
                exact
                path="/exam-analysis"
              />
              <Route
                component={verify(RegDashBoard)}
                exact
                path="/reg-dashboard"
              />
              <Route component={EnquiryForm} exact path="/enquiry-form" />
            </Layout>

            {/* <Redirect exact from="/" to="/SectionsPage" /> */}

            {/* <Route component={InputIntegerPanel} exact path="/adv" /> */}
          </BrowserRouter>
          <Redirect to="/not-found" />
        </Switch>
        {/* {this.doRedirect()} */}
      </>
    );
  }
}

export default withRouter(Routes);
