import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
// import CauseErrorTabs from "./CauseErrorTabs";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Card } from "@material-ui/core";
import errorIcon from "../../assets/errorIcon.png";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import { useEffect } from "react";
import { ExamTab, ExamTabs } from "../ExamTypeSelectNav";

// lists related to tabs
const subjectIssueList = [
  "Could not derive the formula from the concept",
  "Could not derive the formula from the concept",
  "Could only solve half and unable to link to the concept",
  "Did not understand the question",
  "Lengthy calculation",
  "Used wrong formula",
];
const questionErrorList = [
  "Diagram is not clear",
  "Incorrect units",
  "Not in the list",
  "Print is not good",
];

const sillyMistakes = [
  "calculation mistake",
  "confused with multiple options",
  "Could not manage the time",
  "Did not read the question properly",
  "Incorrectly marked though i got the right answer",
  "Marked without solving the question completely",
];
const conceptRelatedList = [
  "Applied wrong concept",
  "Do not know the concept",
];

const ErrorTypes = [
  "Subject issue",
  "question Error",
  "sillyMistake",
  "Concept Related",
];// tab names

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      // backgroundColor: theme.palette.primary.main,
      // "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
      //   color: theme.palette.common.white,
      // },
    },
  },
}))(MenuItem);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
export default function CauseErrorMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);// menu
  const [selectedIndex, setSelectedIndex] = React.useState("");// selected  mistake
  const classes = useStyles();
  const [value, setValue] = React.useState(0); // for tabs
  const [count, setCount] = React.useState(0);// for closing menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setCount(count + 1);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }; // for tabs
  useEffect(() => {
    if (value.length !== 0 && count === 1) {
      setAnchorEl(null); // to close menu
    }
  }, [selectedIndex, value]);
 
  return (
    <div>
      <Card style={{ width: "500px" }} onClick={handleClick}>
        <Grid container direction="row" paddingLeft={1}>
          <Grid xs={2}>
            <IconButton edge="center" color="inherit" aria-label="menu">
              <img
                src={errorIcon}
                alt=""
                style={{ width: "20px", height: "20px" }}
              />
            </IconButton>
          </Grid>
          <Grid
            xs={9}
            style={{ marginTop: 5, paddingTop: "5px", fontSize: "14px" }}
          >
            Cause of Error
          </Grid>
          <Grid
            xs={1}
            style={{ marginTop: 5, paddingTop: "5px", fontSize: "14px" }}
          >
            <ArrowDropDownIcon />
          </Grid>
        </Grid>
      </Card>
      &nbsp;
      {selectedIndex.length !== 0 ? (
        <Box
          borderLeft={7}
          borderLeftColor="#FFA500"
          style={{
            width: "500px",
            fontSize: "12px",
            padding: 2,
            alignContent: "center",
            borderColor: "primary.main",
            backgroundColor: "#FFFF",
            borderLeftColor: "#FFA500",
            fontColor: "",
          }}
        >
          {ErrorTypes[value]}-{selectedIndex}
        </Box>
      ) : null}
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <div className={classes.root}>
            <ExamTabs value={value} onChange={handleChange}>
              {ErrorTypes.map((name, index) => (
                <ExamTab label={name} key={index} />
              ))}
            </ExamTabs>

            {/* <TabPanel value={value} index="subjectIssue"> */}
            {value === 0 && (
              <List component="nav" aria-label="secondary mailbox folder">
                {subjectIssueList.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    selected={selectedIndex === item}
                    onClick={(event) => handleListItemClick(event, item)}
                  >
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            )}
            {value === 1 && (
              <List component="nav" aria-label="secondary mailbox folder">
                {questionErrorList.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    selected={selectedIndex === item}
                    onClick={(event) => handleListItemClick(event, item)}
                  >
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            )}

            {value === 2 && (
              <List component="nav" aria-label="secondary mailbox folder">
                {sillyMistakes.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    selected={selectedIndex === item}
                    onClick={(event) => handleListItemClick(event, item)}
                  >
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            )}
            {value === 3 && (
              <List component="nav" aria-label="secondary mailbox folder">
                {conceptRelatedList.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    selected={selectedIndex === item}
                    onClick={(event) => handleListItemClick(event, item)}
                  >
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            )}
          </div>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
