//save query to get questions in store
export const SAVE_QUERY_TO_STORE = "SAVE_QUERY_TO_STORE";
export const CLEAR_QUERY_FROM_STORE = "CLEAR_QUERY_FROM_STORE";

//save api body  in store
export const SAVE_BODY_TO_STORE = "SAVE_BODY_TO_STORE";
export const CLEAR_BODY_FROM_STORE = "CLEAR_BODY_FROM_STORE";

// retrieve list of questions constants
export const FETCH_QUESTIONS_LOADING = "FETCH_QUESTIONS_LOADING";
export const FETCH_QUESTIONS_SUCCESS = "FETCH_QUESTIONS_SUCCESS";
export const CATCH_ERROR_QUESTIONS = "CATCH_ERROR_QUESTIONS";
export const CLEAR_ERROR_QUESTIONS = "CLEAR_ERROR_QUESTIONS";

// submit answer constants
export const SAVE_ANSWER_LOADING = "SAVE_ANSWER_LOADING";
export const SAVE_ANSWER_SUCCESS = "SAVE_ANSWER_SUCCESS";
export const CATCH_ERROR_ANSWER = "CATCH_ERROR_ANSWER";
export const CLEAR_ERROR_SUBMIT = "CLEAR_ERROR_SUBMIT";

//bookmark questions
export const BOOKMARK_QUESTION_LOADING = "BOOKMARK_QUESTION_LOADING";
export const BOOKMARK_QUESTION_SUCCESS = "BOOKMARK_QUESTION_SUCCESS";
export const CATCH_ERROR_BOOKMARK = "CATCH_ERROR_BOOKMARK";
export const CLEAR_ERROR_BOOKMARK = "CLEAR_ERROR_BOOKMARK";

//get analytics for single question constant
export const ANALYTICS_SINGLE_QUESTION = "ANALYTICS_SINGLE_QUESTION";
export const ANALYTICS_SINGLE_QUESTION_SUCCESS =
  "ANALYTICS_SINGLE_QUESTION_SUCCESS";
export const CATCH_ERROR_ANALYTICS_SINGLE_QUESTION =
  "CATCH_ERROR_ANALYTICS_SINGLE_QUESTION";
export const CLEAR_ERROR_ANALYTICS_SINGLE_QUESTION =
  "CLEAR_ERROR_ANALYTICS_SINGLE_QUESTION";
