import React, { useEffect, useState } from "react";
import store from "store";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import { Card, Button } from "@material-ui/core";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import EaxmSummaryChart from "./ExamSummaryBarChart";
import AttendanceHistoryChart from "./AttendanceHistoryChart";
import TimeSpentChart from "./TimeSpentChart";
import AnsweringAccuracy from "./AnsweringAccuracy";
import OverAllAnalysisCards from "./Components/OverAllAnalysisCards";
import Header from "../Header";
import { useStyles, StyledBadge, useStyles1 } from "./Components/styles";
import useFeeData from "../MakePayment/UseFeeData";
import useStudentData from "./Components/useStudentData";
import Dropdown from "../../components/CustomDropdown/Dropdown";
import calculateDates from "../../utils/caluculateDateRanges";
import StuProfile from "./Components/StuProfile";
const a = {
  MPC: "JEEMAIN_2022",
  BiPC: "NEET_2021",
};
export default function FullWidthGrid() {
  const classes1 = useStyles1();

  const classes = useStyles();
  const history = useHistory();
  const [modifiedObject, otherFees] = useFeeData();
  const [studentAdmissionNo, omrNo, stuFeeData, avatar] = useStudentData();
  const [oFees, setOFess] = useState(null);
  const [mdObj, setMdObj] = useState(null);
  const [fetchedData, setFetchedData] = useState([]);
  const [data1, setData1] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [recentTestName, setRecentTestName] = useState("");
  const [testDisplayName, setDisplayName] = useState("");
  const [avgData, setAvgData] = useState([]);
  const [results, setResults] = useState([]);
  const [testTypes, setTestTypes] = useState([]);
  const [testsObj, setTestObj] = useState({});
  const [presentData, setPresentData] = useState([]);
  const [absentData, setAbsentData] = useState([]);
  const [group, setGroup] = useState("");
  const [selectedRange, setSelectedRange] = useState("3 months");
  const [dateRange, setDateRange] = useState(calculateDates("3 months"));
  const handleDropdownValueChange = (e, item) => {
    setSelectedRange(item);
    const dates = calculateDates(item);
    setDateRange(dates);
  };
  const presentSummay = [];
  const absentSummary = [];
  useEffect(() => {
    if ((otherFees, modifiedObject)) {
      setOFess(otherFees);
      setMdObj(modifiedObject);
    }
  }, [otherFees, modifiedObject]);
  const balance = oFees ? oFees.Total.balance.toLocaleString("en-IN") : 0;
  const handleClick = () => {
    history.push("/make-payment"); // Replace '/your-route' with the actual route path
  };
  let secName;

  if (stuFeeData) {
    if (stuFeeData.branchData) {
      function getSectionNameBySectionId(sectionId) {
        const sections = Object.values(
          stuFeeData.branchData.batchesAndSections
        ).reduce((acc, batch) => acc.concat(...Object.values(batch)), []);

        const foundSection = sections.find(
          (section) => section.sectionId === sectionId
        );
        return foundSection ? foundSection.sectionName : null;
      }

      const sectionIdToFind = stuFeeData.sectionName;
      secName = getSectionNameBySectionId(sectionIdToFind);
    }
  }
  console.log(dateRange);
  async function fetchData() {
    const id = store.get("user").accountType
      ? studentAdmissionNo
      : store.get("user").userId;
    const instituteId = store.get("user").institute;
    const datesParam = `{"startDate":"${dateRange.startDate}","endDate":"${
      dateRange.endDate
    }"}`;
    try {
      const response = await axios.get(
        `https://admin.onesaz.com/api/analysis/studentTestRecords/${id}?dates=${encodeURIComponent(
          datesParam
        )}&instituteId=${instituteId}`

        // `https://admin.onesaz.com/api/analysis/studentTestRecords/${id}?dates=%7B%22startDate%22:%22%22,%22endDate%22:%22%22%7D&instituteId=${instituteId}`
      );
      setFetchedData(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (studentAdmissionNo.length !== 0) {
      fetchData();
      console.log(
        studentAdmissionNo,
        "============= fired fetch dta ==============="
      );
    }
  }, [studentAdmissionNo, dateRange]);

  useEffect(() => {
    if (stuFeeData) setGroup(stuFeeData.group);
  }, [stuFeeData]);

  const examSummay = [];
  useEffect(() => {
    if (fetchedData.output) {
      if (fetchedData.output.averages) {
        const selectedExamType = fetchedData.output.averages;
        setAvgData(selectedExamType);
      }
    }
  }, [fetchedData]);
  useEffect(() => {
    if (fetchedData.output) {
      if (fetchedData.output.results) {
        const finishedTests = fetchedData.output.results.filter(
          (item) => item.test_status === "FINISHED" || []
        );

        const testTypes = fetchedData.output.results.map(
          (test) => test.test_type
        );
        setTestTypes([...new Set(testTypes)]);
        setResults(finishedTests);
        const testNames = finishedTests.reduce((acc, obj) => {
          acc[obj.testDisplayName] = obj.test_name || "";
          return acc;
        }, {});
        setTestObj(testNames);

        if (finishedTests.length !== 0) {
          if (finishedTests[0].test_name !== undefined) {
            setRecentTestName(finishedTests[0].test_name || "");
          }
        } else return setRecentTestName("");

        setDisplayName(finishedTests[0].testDisplayName);
        setSubjects(Object.keys(fetchedData.output.subjects));
        finishedTests.map((tests, index) => {
          examSummay.push({
            ["examType"]: tests.test_type || "",
            ["testName"]: tests.testDisplayName || "",

            ["subjectMarks"]: [
              {
                ["MATHEMATICS"]: tests.result.MATHEMATICS || 0,
                ["PHYSICS"]: tests.result.PHYSICS || 0,
                ["CHEMISTRY"]: tests.result.CHEMISTRY || 0,
                ["BOTANY"]: tests.result.BOTANY || 0,
                ["ZOOLOGY"]: tests.result.ZOOLOGY || 0,
              },
            ],
            ["testPerformance"]: [
              {
                ["id"]: tests.testDisplayName || "",

                ["data"]: tests.result.Marks || 0,
              },
            ],
            ["additionalInfo"]: {
              [tests.testDisplayName || ""]: {
                ["name"]: tests.testDisplayName || "",
                ["TestName"]: tests.testDisplayName || "",
                ["Rank"]: tests.result.rank || 0,
                ["Percentage"]: tests.result.percentage || 0,
                ["Total"]: tests.result.total || 0,
                ["Marks"]: tests.result.Marks || 0,
              },
            },
          });
        });
        setData1(examSummay);
        const notStartedTests = fetchedData.output.results.filter(
          (item) => item.test_status === "NOT_STARTED"
        );
        notStartedTests.map((tests) => {
          const date = new Date(tests.startDateTime).getMonth();

          absentSummary.push(date);
        });
        setAbsentData(absentSummary);
        finishedTests.map((tests) => {
          const date = new Date(tests.startDateTime).getMonth();

          presentSummay.push(date);
        });
        setPresentData(presentSummay);
      }
    }
  }, [fetchedData]);

  return (
    <div className={classes1.root}>
      {/* <Header /> */}

      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        {results.length !== 0 && (
          <Grid item xs={12} sm={7}>
            <Grid container display="flex">
              <Grid item xs={12} sm={4}>
                <Dropdown
                  width={150}
                  dataToDisplay={[
                    "one week",
                    "two weeks",
                    "1 month",
                    "3 months",
                    "6 months",
                    "1 year",
                  ]}
                  handleDropdownValueChange={handleDropdownValueChange}
                  value={selectedRange}
                  textFieldProps={{
                    size: "small",
                    InputLabelProps: { shrink: false },
                    label: "Select Time Range",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="primary">
                  {selectedRange} - Score
                </Typography>
              </Grid>
            </Grid>
            <OverAllAnalysisCards
              data1={data1}
              fetchedData={fetchedData}
              testTypes={testTypes}
              group={group}
              avgData={avgData}
              results={results}
              subjects={subjects}
            />
          </Grid>
        )}
        <StuProfile
          avatar={avatar}
          studentAdmissionNo={studentAdmissionNo}
          stuFeeData={stuFeeData}
          secName={secName}
          omrNo={omrNo}
          results={results}
          balance={balance}
          handleClick={handleClick}
        />

        {results.length !== 0 && (
          <Grid item xs={12} sm={7}>
            <Paper className={classes1.paper}>
              <EaxmSummaryChart
                data1={data1}
                subjects={subjects}
                testTypes={testTypes}
                group={group}
              />
            </Paper>
          </Grid>
        )}

        {store.get("user").accountType === "parent" && (
          <Grid item xs={12} sm={3}>
            <Paper className={classes.stuProfile1}>
              <Grid container>
                <Grid item xs={12} container justifyContent="flex-start">
                  <Typography color="info" variant="button">
                    Finance (Fee Details)
                  </Typography>
                  &nbsp; &nbsp;
                  <Card
                    elevation={0}
                    style={{ background: "#E7F7FD", padding: 3 }}
                  >
                    <Typography style={{ color: "#118FBE" }} variant="button">
                      Total Balance to Pay
                    </Typography>
                    <Typography style={{ color: "red" }} variant="button">
                      :{balance.toLocaleString("en-IN")}
                    </Typography>
                    &nbsp;
                  </Card>
                  <Button />
                  &nbsp;
                </Grid>
                {/* <Grid item xs={12} sm={6} container justifyContent="flex-end" />
                <Grid item xs={12} sm={2} container justifyContent="flex-end" /> */}
                <Grid item xs={12} sm={4} container justifyContent="flex-end">
                  <Button
                    style={{ background: "#003366", color: "white" }}
                    size="small"
                    variant="contained"
                    onClick={handleClick}
                  >
                    Pay Now
                  </Button>
                </Grid>
                &nbsp;
              </Grid>
            </Paper>
          </Grid>
        )}
        {results.length !== 0 && (
          <>
            <Grid item xs={12} sm={7}>
              <Paper className={classes1.paper}>
                <AttendanceHistoryChart
                  presentData={presentData}
                  absentData={absentData}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={5} />
            <Grid item xs={12} sm={7}>
              <Paper className={classes1.paper}>
                <TimeSpentChart
                  data1={data1}
                  subjects={subjects}
                  testTypes={testTypes}
                  group={group}
                  testsObj={testsObj}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={5} />
            <Grid item xs={12} sm={7}>
              <Paper className={classes1.paper}>
                <AnsweringAccuracy
                  data1={data1}
                  recentTestName={recentTestName}
                  testsObj={testsObj}
                  testDisplayName={testDisplayName}
                />
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
