// imports
import React, { useEffect, useState, useMemo } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory, Link } from "react-router-dom";
import store from "store";
import moment from "moment";
// material ui components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import AutoComplete from "@material-ui/lab/Autocomplete";
import { ThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
// theme
import theme from "../../assets/v3/theme";
// components
import OtpInput from "./OtpInput";
// assets
import IndFlag from "../../assets/svgs/ind.svg";
// hooks
import useFetch from "../../hooks/useFetch";
// apis
import {
  createOtpRegister,
  newRegister,
  getRegistrationFormConfig,
} from "../../apis/UserService";
import { useSnackbar } from "../../context/snackbar";
// colors
import LogoURLMap, { hostnamesMap } from "../../components/LogoURLMap";
import BoxCountdown from "../../components/CountDownTimer";
import StyledBox from "../../components/StyledBox";
// assets
import colors from "../../assets/v3/base/colors";
import Guidelines from "./Guidelines";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";

// form validation schema
const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Phone number must be a number")
    .min(10, "Phone number must be 10 characters")
    .max(10, "Phone number must be 10 characters")
    .required("Phone number is required"),
  grade: Yup.string().required("Grade is required"),
  course: Yup.string("").required("Course is required"),
  boards: Yup.string().required("Board is required"),
});

export const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 500,
    textAlign: "left",
  },
  label: {
    lineHeight: "1.5",
    // marginBottom: theme.spacing(0.5),
  },
  inputWithIcon: {
    border: "none",
  },
  titleContainer: {
    color: `linear-gradient(95.69deg, #ED1B24 0%, #074EA0 100%)`,
  },
  heading: {
    textAlign: "center",
    lineHeight: 1.5,
    fontWeight: "bolder",
    background: `-webkit-linear-gradient(#ED1B24, #074EA0)`,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  back: {
    paddingLeft: 0,
    color: colors.dark.main,
  },
  option: {
    fontWeight: "400",
    textDecoration: "none",
    textTransform: "none",
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  helperText: {
    marginLeft: theme.spacing(1),
  },
  blueGradientBtn: {
    background: `linear-gradient(310deg, ${colors.gradients.tirumala.main}, ${
      colors.gradients.tirumala.state
    })`,
    color: "white",
  },
  blueGradientBtn2: {
    border: "1px solid transparent",
    background: `
      linear-gradient(to right, white, white), 
      linear-gradient(to right, ${colors.gradients.tirumala.state}, ${
      colors.gradients.tirumala.main
    })
    `,
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    color: colors.gradients.tirumala.state,
  },
  pageTitleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.info.dark,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  pageTitleSubContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 900,
    flexGrow: 1,
  },
  pageTitle: {
    color: colors.white.main,
    flexGrow: 1,
  },
  nextText: {
    color: colors.white.main,
  },
  formContainer: {
    backgroundColor: colors.background.default,
    [theme.breakpoints.up("md")]: {
      boxShadow: theme.shadows[3],
      borderRadius: theme.spacing(2),
      paddingTop: theme.spacing(2),
      marginTop: theme.spacing(10),
    },
  },
  relative: {
    position: "relative",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardTitleText: {
    color: colors.info.dark,
    flexGrow: 1,
    fontWeight: "bold",
  },
  cardSubTitle: {
    color: colors.info.dark,
  },
}));

export const PageTitle = ({ title, next }) => {
  const classes = useStyles();
  return (
    <Box className={classes.pageTitleContainer}>
      <Box className={classes.pageTitleSubContainer}>
        <Typography variant="h5" className={classes.pageTitle}>
          {title}
        </Typography>
        {next && (
          <Typography
            variant="caption"
            className={classes.nextText}
            component="p"
          >
            next: {next}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export const CardPageTitle = ({ title, next }) => {
  const classes = useStyles();
  return (
    <Box className={classes.cardTitle} flexGrow={1}>
      <Box className={classes.pageTitleSubContainer}>
        <Typography variant="h5" className={classes.cardTitleText}>
          {title}
        </Typography>
        {next && (
          <Typography
            variant="caption"
            className={classes.cardSubTitle}
            component="p"
          >
            next: {next}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const Footer = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding={2}
      bgcolor={colors.background.default}
    >
      <TermsAndConditions />
      <PrivacyPolicy />
    </Box>
  );
};

const NewRegistration = () => {
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [l1, e1, d1, f1, r1] = useFetch(createOtpRegister);
  const [l2, e2, d2, f2, r2] = useFetch(newRegister);
  const [l3, e3, d3, f3, r3] = useFetch(getRegistrationFormConfig);
  const [otpSent, setOtpSent] = useState(false);
  const [resendTimer, setResendTimer] = useState(90);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [course, setCourse] = useState("JEE_MAINS");
  const [boards, setBoards] = useState("");
  const [grade, setGrade] = useState("");
  const { openSnackbar } = useSnackbar();
  const history = useHistory();
  const instituteId =
    hostnamesMap[window.location.hostname] || "5d679d49c136660a09596d85";
  const payload = d3 ? d3.payload : {};
  const params = Object.fromEntries(
    new URLSearchParams(payload && payload.params ? payload.params : "")
  );
  const date = payload && payload.date ? payload.date : null;
  const sysTime = d3 ? d3.sysTime : null;
  const title = payload && payload.title ? payload.title : null;
  const stuff = LogoURLMap[instituteId] || {};
  const academicYear =
    payload && payload.academicYear ? payload.academicYear : null;

  const clear = () => {
    setOtpSent(false);
    r1();
    r2();
  };

  const countDown = useMemo(() => {
    if (!date || !sysTime) return null;
    const from = new Date(sysTime).getTime();
    const to = new Date(date).getTime();
    const difference = to - from;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }, [date, sysTime]);

  const submitOtp = (values) => {
    const myGrade = `${grade}`.padStart(2, "0")
    const batchKey = (() => {
      if(myGrade === "11") {
        return `${course}_11`;
      }
      return myGrade;
    })
    const data = {
      phoneNumber,
      branchId: params.branch,
      batch: params[batchKey],
      academicYear: params.academicYear,
      current_ay: params.academicYear,
      tt: true,
      otp: values.otp,
      course,
      boards,
      grade: myGrade,
      filterKey: `${boards}_${myGrade}`,
    };
    f2(data);
  };

  useEffect(() => {
    let timer;
    if (otpSent) {
      timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [otpSent]);

  useEffect(() => {
    if (d1) {
      if (d1.data.status === "FAILURE") {
        openSnackbar(d1.data.message, "warning", false, {
          customActions: [
            {
              text: "Login",
              onClick: () => history.push("/reg-login"),
              className: "success",
              closeOnClick: true,
            },
          ],
          showCloseIcon: true,
          actionsPosition: "center",
        });
      } else {
        openSnackbar("OTP sent successfully", "success");
        setOtpSent(true);
        setResendTimer(90);
        setIsResendDisabled(true);
      }
    }
    if (e1) {
      openSnackbar("Failed to send OTP", "error");
    }

    return () => {
      r1();
    };
  }, [d1, e1, openSnackbar]);

  const resendOtp = () => {
    // logic to resend OTP
    f1({ phoneNumber });
    setOtpSent(true);
    setResendTimer(90);
    setIsResendDisabled(true);
  };

  useEffect(() => {
    if (d2) {
      if (d2.data.status === "FAILURE") {
        openSnackbar(d2.data.message, "error");
      } else {
        openSnackbar("Registration successful", "success");
        localStorage.setItem("isAuthenticated", true);
        store.set("user", d2.data);
        history.push(`/student-details`);
      }
    }
    if (e2) {
      openSnackbar(e2, "error");
    }

    return () => {
      r2();
    };
  }, [d2, e2, openSnackbar]);

  useEffect(() => {
    if (d3) {
      if (d3.data && d3.data.status === "FAILURE") {
        openSnackbar(d3.data.message, "error");
      }
    }

    if (e3) {
      openSnackbar("Failed to fetch registration form config", "error");
    }
  }, [d3, e3, openSnackbar]);

  useEffect(() => {
    f3({ page: "new-registration", instituteId });

    return () => {
      r3();
    };
  }, []);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const classes = useStyles();

  const course_options = ["JEE_MAINS", "JEE_ADVANCED", "NEET"];
  const boards_options = ["STATE", "CBSE", "ICSE"];
  const grade_options = ["6", "7", "8", "9", "10", "11"];

  const formatTime = (seconds) => {
    const duration = moment.duration(seconds, "seconds");
    return `${duration.minutes()}:${duration
      .seconds()
      .toString()
      .padStart(2, "0")}`;
  };

  const getSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = number % 100;
    return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
  };
  
  const renderOptionText = (number) => {
    return (
      <>
        {number}
        <sup>{getSuffix(Number(number))}</sup> grade
      </>
    );
  };

  const renderForm = () => {
    if (otpSent) {
      return (
        <StyledBox borderRadius="lg" shadow="lg" padding={2}>
          <Box maxWidth={400} margin="auto" mt={2} mb={2}>
            <Button
              onClick={() => clear()}
              className={classes.back}
              size="small"
              variant="text"
            >
              <ArrowBackIosRoundedIcon />
              &nbsp;Back
            </Button>
            <Typography variant="h3" gutterBottom>
              Verify OTP
            </Typography>
            <Typography variant="body2">
              Please enter the 6-digit verification code sent to you at +91-
              {phoneNumber}
            </Typography>
          </Box>
          <Formik
            initialValues={{ otp: "" }}
            validationSchema={Yup.object().shape({
              otp: Yup.string()
                .matches(/^[0-9]+$/, "OTP must be a number")
                .min(6, "OTP is required")
                .max(6, "OTP is required")
                .required("OTP is required"),
            })}
            onSubmit={(values) => {
              submitOtp(values);
            }}
          >
            {({ errors, touched, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Box maxWidth={400} margin="auto">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className={classes.title}
                  >
                    Enter OTP
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <OtpInput
                        handleOtpChange={(otp) => {
                          setFieldValue("otp", otp);
                        }}
                        containerStyles={{ justifyContent: "flex-start" }}
                      />
                      {touched.otp && errors.otp && (
                        <Typography color="error" variant="caption">
                          {errors.otp}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={1}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={l2}
                          className={classes.blueGradientBtn}
                        >
                          {l2 ? "Verifying OTP..." : "Submit OTP"}
                        </Button>
                        <Button
                          onClick={resendOtp}
                          color="secondary"
                          disabled={isResendDisabled}
                          variant="text"
                        >
                          {isResendDisabled
                            ? `Resend OTP in ${formatTime(resendTimer)}`
                            : "Resend OTP"}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </StyledBox>
      );
    }
    return (
      <StyledBox
        borderRadius="lg"
        shadow="lg"
        padding={4}
        className={classes.relative}
      >
        <Guidelines />
        <Box textAlign="center">
          {stuff && <img src={stuff.url} alt="Institute Logo" height="120" />}
        </Box>
        <Formik
          initialValues={{
            phoneNumber: phoneNumber,
            grade,
            course,
            boards,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setGrade(values.grade || "");
            setCourse(values.course || "");
            setBoards(values.boards || "");
            setPhoneNumber(values.phoneNumber || "");
            f1(values);
          }}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            console.log(errors);
            return (
              <Form onSubmit={handleSubmit}>
                <Box maxWidth={320} margin="auto">
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box marginTop={1}>
                        <Typography variant="body2" className={classes.label}>
                          Select Grade
                        </Typography>
                      </Box>
                      <AutoComplete
                        id="grade"
                        name="grade"
                        options={grade_options}
                        value={values.grade}
                        onChange={(e, value) => {
                          if(value !== "11") {
                            setFieldValue("course", "JEE_MAINS");
                          } else {
                            setFieldValue("course", "");
                          }
                          setFieldValue("grade", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            error={touched.grade && Boolean(errors.grade)}
                            helperText={touched.grade && errors.grade}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            variant="button"
                            className={classes.option}
                          >
                            {renderOptionText(option)}
                          </Typography>
                        )}
                      />
                    </Grid>
                    {values.grade === "11" && (
                      <Grid item xs={12}>
                        <Box marginTop={1}>
                          <Typography variant="body2" className={classes.label}>
                            Select Course
                          </Typography>
                        </Box>
                        <AutoComplete
                          id="course"
                          name="course"
                          options={course_options}
                          value={values.course}
                          onChange={(e, value) => {
                            setFieldValue("course", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              color="secondary"
                              fullWidth
                              error={touched.course && Boolean(errors.course)}
                              helperText={touched.course && errors.course}
                            />
                          )}
                          renderOption={(option) => (
                            <Typography
                              variant="button"
                              className={classes.option}
                            >
                              {option}
                            </Typography>
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.label}
                      >
                        Select Board
                      </Typography>
                      <AutoComplete
                        id="boards"
                        name="boards"
                        options={boards_options}
                        value={values.boards}
                        onChange={(e, value) => {
                          setFieldValue("boards", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            color="secondary"
                            error={touched.boards && Boolean(errors.boards)}
                            helperText={touched.boards && errors.boards}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            variant="button"
                            className={classes.option}
                          >
                            {option}
                          </Typography>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.label}
                      >
                        Father Phone No
                      </Typography>

                      <TextField
                        fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.phoneNumber && Boolean(errors.phoneNumber)
                        }
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        variant="outlined"
                        color="secondary"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                gridGap={10}
                              >
                                <Box>
                                  <img
                                    src={IndFlag}
                                    width="40"
                                    alt="india flag"
                                  />
                                </Box>
                                <Typography variant="button">+91</Typography>
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        textAlign="right"
                        className={classes.buttonContainer}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={l1}
                          className={classes.blueGradientBtn}
                        >
                          {l1 ? "Sending OTP..." : "Register"}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box textAlign="center" mt={2}>
                        <Typography variant="body2">
                          Already signed up?{" "}
                          <Link
                            to="/reg-login"
                            style={{ textDecoration: "none" }}
                          >
                            Login
                          </Link>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </StyledBox>
    );
  };

  if (l1 || l2 || l3) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="background.default"
        paddingBottom={1}
        paddingTop={1}
        flexDirection="column"
      >
        {isLargeScreen ? (
          <Box display="flex" width="100%">
            <Box
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className={classes.titleContainer}
            >
              <Typography variant="h2" className={classes.heading}>
                {stuff ? stuff.title : ""}
              </Typography>
              <Typography variant="h2" color="info" className={classes.heading}>
                {title}( {academicYear})
              </Typography>
              {countDown && <BoxCountdown countDown={countDown} />}
            </Box>
            <Box
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {renderForm()}
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gridGap={10}
            lineHeight={1}
            padding={1}
          >
            {!otpSent && (
              <Typography variant="h4" color="info" className={classes.heading}>
                {title}({academicYear})
              </Typography>
            )}
            {!otpSent && countDown && <BoxCountdown countDown={countDown} />}
            {renderForm()}
          </Box>
        )}
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default NewRegistration;
