// import React, { useState, useEffect } from "react";
// import { makeStyles, Grid, Typography, useMediaQuery } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     fontFamily: "Arial, Helvetica, sans-serif",
//     overflow: "hidden",
//     textAlign: "center",
//     // display: "flex",
//   },
//   cardContainer: {
//     display: "flex",
//     overflow: "hidden",
//     alignItems: "center",
//     justifyContent: "center",
//     display: "flex",
//     position: "relative",
//   },
//   card: {
//     flex: 1,
//     padding: 10,
//     boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
//     textAlign: "center",
//     backgroundColor: "#f1f1f1",
//     margin: 10,
//     cursor: "pointer",
//     transition: "transform 0.3s",
//     // minWidth: "270px",
//     display: "inline-block",
//     verticalAlign: "top",
//     display: "flex",

//     "&:hover": {
//       transform: "scale(1.1)",
//     },
//   },
//   cardStyle: {
//     background: "#EFF2F4",
//     borderRadius: "24px",
//     padding: "20px",
//     margin: "20px",
//     animation: "$slideIn 2s ease-in-out",
//     minWidth: "300px",
//     minHeight: "277px",
//     // display: "flex",

//     [theme.breakpoints.down("xs")]: {
//       minWidth: "unset",
//       minHeight: "200px",
//     },
//     [theme.breakpoints.down("sm")]: {
//       minWidth: "unset",
//       minHeight: "250px",
//     },
//   },
//   "@keyframes slideIn": {
//     "0%": {
//       opacity: 0,
//       transform: "translateY(100%)",
//     },
//     "100%": {
//       opacity: 1,
//       transform: "translateY(0.9)",
//     },
//   },
//   titleStyle: {
//     fontStyle: "normal",
//     fontWeight: "bold",
//     fontSize: "20px",
//     lineHeight: "24px",
//     color: "#31456A",
//     textAlign: "left",
//   },
//   descStyle: {
//     marginTop: "5px",
//     fontStyle: "normal",
//     fontWeight: "normal",
//     fontSize: "16px",
//     lineHeight: "29px",
//     textAlign: "left",
//     color: "rgba(49, 69, 106, 0.7)",
//   },
//   iconShadow: {
//     marginTop: "-20%",
//     textAlign: "center",
//     height: "60px",
//     width: "60px",
//     background: "#EFF2F4",
//     boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
//     borderRadius: "197px",
//   },
// }));

// const HorizontalCardScroll = ({ cards,scrollYFrom,scrollYTo }) => {
//   const classes = useStyles();
//   const [visibleCards, setVisibleCards] = useState(4);
//   const isMobile = useMediaQuery("(max-width:600px)");

//   const handleScroll = () => {
//     const scrollY = window.scrollY || document.documentElement.scrollTop;
//     if (!isMobile && scrollY >= scrollYFrom && scrollY <= scrollYTo) {
//       const numVisibleCards = 4 + Math.floor((scrollY - scrollYFrom) / 100);
//       setVisibleCards(Math.min(numVisibleCards, cards.length));
//     }
//   };
//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     handleScroll(); // Initialize card visibility based on scroll position

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     // <Container className={classes.root}>
//     <Grid
//       container
//       // spacing={2}
//       direction="row"
//       justifyContent="flex-start"
//       display="flex"
//       // justify={isWidthUp("xs", props.width) ? "space-between" : "center"}
//       alignItems="center"
//       // style={{
//       //   transform: "scale(0.9)",
//       //   opacity: 1,
//       // }}
//     >
//       {cards.slice(0, visibleCards).map((card, index) => (
//         <Grid
//           container
//           direction="column"
//           justify="flex-start"
//           alignItems="flex-start"
//           item
//           xs={12}
//           sm={4}
//           md={2} // Adjust for responsiveness
//           // xs={12}
//           // sm={3} // Adjust for responsiveness
//           // md={3} // Adjust for responsiveness
//           // // lg={6} // Adjust for responsiveness
//           className={classes.cardStyle}
//         >
//           <Grid item xs={12} sm={12}>
//             <div
//               style={{
//                 backgroundColor: card.backgroundColor,

//                 padding: "10px 10px 10px 10px",

//                 borderRadius: "100px",
//               }}
//             >
//               <img alt="icon" src={card.icon} />
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={12}>
//             <br />
//             <Typography
//               variant="h1"
//               component="h1"
//               className={classes.titleStyle}
//             >
//               {card.title}
//             </Typography>
//           </Grid>
//           <Grid item xs={12}>
//             <div className={classes.descStyle}>{card.content}</div>
//           </Grid>
//         </Grid>
//       ))}
//     </Grid>
//     // </Container>
//   );
// };

// export default HorizontalCardScroll;
// import React, { useState, useEffect } from "react";
// import { makeStyles, Grid, Typography, useMediaQuery } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     fontFamily: "Arial, Helvetica, sans-serif",
//     overflow: "hidden",
//     textAlign: "left",
//   },
//   cardContainer: {
//     display: "flex",
//     overflow: "hidden",
//     alignItems: "left",
//     position: "relative",
//     margin: "14px",
//     overflow: "hidden",
//     // whiteSpace: "nowrap", // Prevent cards from wrapping to the next line
//     transition: "transform 0.1s ease", // Add transition for smooth scrolling
//   },

//   cardStyle: {
//     background: "#EFF2F4",
//     borderRadius: "24px",
//     padding: "20px",
//     minWidth: "300px",
//     minHeight: "277px",
//     marginRight: "20px",
//     display: "inline-block",
//     verticalAlign: "top",
//   },
//   titleStyle: {
//     fontStyle: "normal",
//     fontWeight: "bold",
//     fontSize: "20px",
//     lineHeight: "24px",
//     color: "#31456A",
//   },
//   descStyle: {
//     marginTop: "5px",
//     fontStyle: "normal",
//     fontWeight: "normal",
//     fontSize: "16px",
//     lineHeight: "29px",

//     color: "rgba(49, 69, 106, 0.7)",
//   },
//   iconShadow: {
//     marginTop: "-20%",
//     textAlign: "center",
//     height: "60px",
//     width: "60px",
//     background: "#EFF2F4",
//     boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
//     borderRadius: "197px",
//   },
// }));

// const HorizontalCardScroll = ({
//   cards,
//   scrollYFrom,
//   scrollYTo,
//   scrollFactor,
// }) => {
//   const classes = useStyles();
//   const isMobile = useMediaQuery("(max-width:600px)");
//   const [scrollLeft, setScrollLeft] = useState(0);
//   const handleScroll = () => {
//     const scrollY = window.scrollY || document.documentElement.scrollTop;
//     if (!isMobile && scrollY >= scrollYFrom && scrollY <= scrollYTo) {
//       const newScrollLeft = (scrollY - scrollYFrom) * scrollFactor;
//       setScrollLeft(newScrollLeft);
//     }
//   };
//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     handleScroll();

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <div className={classes.root}>
//       <div
//         className={isMobile ? null : classes.cardContainer}
//         style={{ transform: `translateX(-${scrollLeft}px)` }}
//       >
//         {cards.map((card, index) => (
//           <Grid
//             container
//             direction="column"
//             justify="flex-start"
//             alignItems="flex-start"
//             item
//             xs={12}
//             sm={3}
//             md={2} // Adjust for responsiveness
//             className={classes.cardStyle}
//             key={index}
//           >
//             <Grid
//               item
//               xs={12}
//               sm={3}
//               container
//               justify="flex-start"
//               alignItems="flex-start"
//             >
//               <div
//                 style={{
//                   backgroundColor: card.backgroundColor,
//                   padding: "7px 7px 7px 7px",
//                   borderRadius: "100px",
//                 }}
//               >
//                 <img
//                   alt="icon"
//                   src={card.icon}
//                   style={{ height: "20px", width: "20px" }}
//                 />
//               </div>
//             </Grid>
//             <Grid item xs={12} sm={12}>
//               <br />
//               <Typography
//                 variant="h1"
//                 component="h1"
//                 className={classes.titleStyle}
//               >
//                 {card.title}
//               </Typography>
//             </Grid>
//             <Grid item xs={12}>
//               <div className={classes.descStyle}>{card.content}</div>
//             </Grid>
//           </Grid>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default HorizontalCardScroll;
import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Grid, Typography, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Arial, Helvetica, sans-serif",
    overflow: "hidden",
    textAlign: "left",
  },
  cardContainer: {
    display: "flex",
    alignItems: "flex-start",
    position: "relative",
    margin: "14px",
    height: "350px", // Set a fixed height for the container
    overflowY: "hidden", // Hide vertical scrollbar
    overflowX: "hidden", // Hide horizontal scrollbar
    // cursor: "grab", // Set cursor to grab for drag scrolling
  },
  cardStyle: {
    background: "#EFF2F4",
    borderRadius: "24px",
    padding: "20px",
    minWidth: "300px",
    minHeight: "277px",
    marginRight: "20px",
    display: "inline-block",
    verticalAlign: "top",
    overflowY: "auto", // Enable vertical scrolling for each card
    
    ":hover": {
      transform: "scale(1.05)", // Scale up the container on hover
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", // Apply box shadow on hover
      cursor: "grabbing", // Change cursor to grabbing on hover
    },
  },
  titleStyle: {
    fontStyle: "Montserrat",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "24px",
    color: "#31456A",
  },
  descStyle: {
    marginTop: "5px",
    fontStyle: "Montserrat",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "29px",
    color: "rgba(49, 69, 106, 0.7)",
  },
  iconShadow: {
    marginTop: "-20%",
    textAlign: "center",
    height: "60px",
    width: "60px",
    background: "#EFF2F4",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    borderRadius: "197px",
  },
}));

const HorizontalCardScroll = ({
  cards,
  scrollYFrom,
  scrollYTo,
  scrollFactor,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);
  const startX = useRef(null);
  const scrollLeftRef = useRef(scrollLeft);

  const handleMouseDown = (e) => {
    e.preventDefault();
    startX.current = e.clientX;
    scrollLeftRef.current = containerRef.current.scrollLeft;
    containerRef.current.style.cursor = "grabbing";
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const x = e.clientX - startX.current;
    containerRef.current.scrollLeft = scrollLeftRef.current - x;
  };

  const handleMouseUp = () => {
    containerRef.current.style.cursor = "grab";
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    if (!isMobile && scrollY >= scrollYFrom && scrollY <= scrollYTo) {
      const newScrollLeft = (scrollY - scrollYFrom) * scrollFactor;
      setScrollLeft(newScrollLeft);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={classes.root}>
      <div
        ref={containerRef}
        className={isMobile ? null : classes.cardContainer}
        style={{ transform: `translateX(-${scrollLeft}px)` }}
        onMouseDown={handleMouseDown}
      >
        {cards.map((card, index) => (
          <div className={classes.cardStyle} key={index}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid
                item
                xs={12}
                sm={3}
                container
                justify="flex-start"
                alignItems="flex-start"
              >
                <div
                  style={{
                    backgroundColor: card.backgroundColor,
                    padding: "8px 8px 8px 8px",
                    borderRadius: "100px",
                    transition: "transform 0.3s ease",
                    // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", // Apply box shadow
                  }}
                >
                  <img
                    alt="icon"
                    src={card.icon}
                    style={{ height: "25px", width: "25px" }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <br />
                <Typography
                  variant="h1"
                  component="h1"
                  className={classes.titleStyle}
                >
                  {card.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.descStyle}>{card.content}</div>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalCardScroll;
