import { Fab, Grid, Tooltip, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

const useStyles = makeStyles((theme) => ({
  smallFab: {
    width: "24px", // Adjust the width as needed
    height: "24px", // Adjust the height as needed
    minWidth: "unset",
    minHeight: "unset",
    fontSize: "12px", // Adjust the font size as needed
    boxShadow: "none", // Disable shadow
  },
  pagination: { padding: "1%" },
}));

const CustomPagination = ({ totalPages, currentPage, onPageChange }) => {
  const [pageNumber, setPageNumber] = useState(currentPage);
  const classes = useStyles();
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPageNumber(newPage);
      onPageChange(newPage);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 6; // Number of pages to display initially
    const pagesBeforeDots = Math.floor(maxPagesToShow / 2);
    const pagesAfterDots = maxPagesToShow - pagesBeforeDots;

    if (totalPages <= maxPagesToShow) {
      // If total pages are less than or equal to maxPagesToShow, display all pages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <Grid item key={i}>
            <Fab
              color={i === pageNumber ? "primary" : "default"}
              onClick={() => handlePageChange(i)}
              className={classes.smallFab}
            >
              {i}
            </Fab>
          </Grid>
        );
      }
    } else {
      // Display the first `pagesBeforeDots` pages
      for (let i = 1; i <= pagesBeforeDots; i++) {
        pageNumbers.push(
          <Grid item key={i}>
            <Fab
              color={i === pageNumber ? "primary" : "default"}
              onClick={() => handlePageChange(i)}
              className={classes.smallFab}
            >
              {i}
            </Fab>
          </Grid>
        );
      }

      // Display "..." to indicate more pages
      pageNumbers.push(
        <Grid item>
          <span key="dots">...</span>
        </Grid>
      );

      // Display the last `pagesAfterDots` pages
      for (let i = totalPages - pagesAfterDots + 1; i <= totalPages; i++) {
        pageNumbers.push(
          <Grid item key={i}>
            <Fab
              color={i === pageNumber ? "primary" : "default"}
              onClick={() => handlePageChange(i)}
              className={classes.smallFab}
            >
              {i}
            </Fab>
          </Grid>
        );
      }
    }

    return pageNumbers;
  };
  if (totalPages < 2) return null;
  return (
    <Grid container spacing={1} className={classes.pagination} justify="center">
      <Grid item>
        <Tooltip title="Previous">
          <div>
            <Fab
              color="primary"
              onClick={() => handlePageChange(pageNumber - 1)}
              disabled={pageNumber === 1}
              className={classes.smallFab}
            >
              <KeyboardArrowLeftIcon />
            </Fab>
          </div>
        </Tooltip>
      </Grid>
      {renderPageNumbers()}
      <Grid item>
        <Tooltip title="Next">
          <div>
            <Fab
              color="primary"
              onClick={() => handlePageChange(pageNumber + 1)}
              disabled={pageNumber === totalPages}
              className={classes.smallFab}
            >
              <KeyboardArrowRightIcon />
            </Fab>
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default CustomPagination;
