import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: "40px",
    height: "40px",
    background: theme.palette.secondary.dark,
    color: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}));
const QuestionNumberPad = ({ numbers, background, onClick, ...props }) => {
  const disableNumberStatus = props.qNo.includes(numbers);
  const classes = useStyles();
  return (
    <Grid item>
      <IconButton
        onClick={onClick}
        style={background}
        className={classes.margin}
        disabled={
          !disableNumberStatus || props.selectedQuestion === numbers
            ? true
            : false
        }
      >
        <Typography>{numbers}</Typography>
      </IconButton>
    </Grid>
  );
};

export default QuestionNumberPad;
