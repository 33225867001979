import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// colors
import colors from "../../assets/v3/base/colors";

const useStyles = makeStyles((theme) => ({
  customButton: {
    color: colors.gradients.tirumala.main,
    textDecoration: "underline",
  },
  iframe: {
    width: "100%",
    height: "500px",
    border: "none",
  },
}));

function createMarkup() {
  return `<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Aptos;
	panose-1:2 11 0 4 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:115%;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;}
.MsoChpDefault
	{font-size:12.0pt;
	font-family:"Aptos",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:115%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:.5in .5in .5in .5in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang="EN-US" style="word-wrap:break-word">

<div class="WordSection1">

<p class="MsoNormal"><b><span lang="EN-IN">Terms And Conditions Of Access / Use</span></b></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Introduction</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">Tirumala provides online courses and
conducts online classes, live demo, for the students seeking to enrol for such
courses (the “Services”), which Services are accessible at&nbsp;https://tirumalaedu.com&nbsp;and
any other websites through which Tirumala makes the Services available and as
applications for mobile, tablet and other smart devices and application program
interfaces the “Applications”.</span></p>

<p class="MsoNormal"><span lang="EN-IN">By accessing or using the Site, Application
or Services or by downloading or posting any content from or on the Site, via
the Applications, you would be indicating that you have read, and that you
understand and agree to be bound by these terms and receive our Services
(“Terms of Services” or “Terms”), whether or not you have registered with the
Site and/or Application.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Therefore, please read these Terms of
service before accessing or using the Site, Application or Services or
downloading or posting any content from or on the Site, via the Application or
through the Services, carefully as they contain important information regarding
your legal rights, remedies and obligations.</span></p>

<p class="MsoNormal"><span lang="EN-IN">If you do not agree to these Terms, then
you have no right to access or use the Site, Application, Services, or
Collective Content (as defined below).</span></p>

<p class="MsoNormal"><span lang="EN-IN">If you are using the Site, Application or
Services then these Terms of Service are binding between you and Tirumala.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Definition</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">In addition to other words and expressions
that may be defined elsewhere in these Terms, unless the context otherwise
requires, the following capitalized terms wherever used in the Agreement shall
have the meanings as ascribed hereunder:</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">&nbsp;</span></li>
 <ul style="margin-top:0in" type="disc">
  <li class="MsoNormal"><b><span lang="EN-IN">“Courses”</span></b><span lang="EN-IN">&nbsp;means
      educational courses listed on the Site or Application.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">“Course Fees”</span></b><span lang="EN-IN">&nbsp;means the amounts that are due and payable by a Student
      for enrolment of Course.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">“Collective Content”</span></b><span lang="EN-IN">&nbsp;means Member Content and Tirumala Content.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">“Content”</span></b><span lang="EN-IN">&nbsp;means
      text, graphics, images, music, software (excluding the Application),
      audio, video, information or other materials.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">“Listing”</span></b><span lang="EN-IN">&nbsp;means
      Courses that are listed by Tirumala as available via the Site,
      Application, and Services.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">“Member”</span></b><span lang="EN-IN">&nbsp;means
      a person, who completes Tirumala’s account registration process,
      including but not limited to Teachers and Students, as described
      under&nbsp;<b>“Account Registration”</b>&nbsp;in Clause 7 below.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">“Member Content”</span></b><span lang="EN-IN">&nbsp;means all Content that a Member posts, uploads,
      publishes, submits, transmits, or includes in their Listing, Member
      profile or Tirumala promotional campaign to be made available through the
      Site, Application or Services.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">“Payment Method”</span></b><span lang="EN-IN">&nbsp;means a payment method that you have added to your Tirumala
      Account, such as a credit card, debit card or net banking.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">“Student”</span></b><span lang="EN-IN">&nbsp;means
      a Member who enrols for Courses on Application or Site, in case of minor
      student the parent or guardian who enrol their child or ward for the
      Courses on Application or Site.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">“Tax”</span></b><span lang="EN-IN">&nbsp;or&nbsp;<b>“Taxes”</b>&nbsp;mean
      any sales taxes, value added taxes (VAT), goods and services taxes (GST),
      service tax, that Tirumala may be required by law to collect and remit to
      governmental agencies,and other similar municipal, state, federal and
      national indirect or other withholding and personal or corporate income
      taxes.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">“Teacher”</span></b><span lang="EN-IN">&nbsp;means
      a Member who has been selected by Tirumala in order to provide services
      through the Site or Application.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">“Tirumala Content”</span></b><span lang="EN-IN">&nbsp;means all Content that Tirumala makes available through
      the Site, Application, Services, or its related promotional campaigns and
      official social media channels, including any Content licensed from a
      third party, but excluding Student Content.</span></li>
 </ul>
</ol>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Terms of Service</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">By using the Site, Applications or
Services, you agree to comply with and be legally bound by the terms and
conditions of these Terms, whether or not you become a registered user of the
Services. These Terms govern your access to and use of the Site, Application
and Services and all Collective Content (defined above), and constitute a
binding legal agreement between you and Tirumala. The user further accepts to
allow Tirumala to reach them through call for providing existing services and
for providing information on new products and services and will not be subject
to DND regulations for such calls.</span></p>

<p class="MsoNormal"><span lang="EN-IN">In addition, certain areas of the Site and
Application (and your access to or use of certain aspects of the Services or
Collective Content) may have different terms and conditions, standards, guidelines,
or policies posted or may require you to agree with and accept additional terms
and conditions. If there is a conflict between these Terms and terms and
conditions posted for a specific area of the Site, Application, Services, or
Collective Content, the latter terms and conditions will take precedence with
respect to your use of or access to that area of the Site, Application,
Services, or Collective Content.</span></p>

<p class="MsoNormal"><span lang="EN-IN">If you do not agree to these Terms, you
have no right to obtain information from or otherwise continue using the Site,
Application or Services. Failure to use the Site, Application or Services in
accordance with these Terms may subject you to civil and criminal liabilities.</span></p>

<p class="MsoNormal"><span lang="EN-IN">The Site, Application and Services comprise
an online platform which creates Courses and Students may learn about and enrol
for the course directly through the Site and Application.</span></p>

<p class="MsoNormal"><span lang="EN-IN">You acknowledge and agree that, by
accessing or using the Site, Application or Services or by downloading or
posting any content from or on the Site or via the Application, you are
indicating that you have read, and that you understand and agree to be bound by
these Terms and receive our Services, whether or not you have registered with
the Site and Application. If you do not agree to these terms, then you have no
right to access or use the Site, Application, Services, or Collective Content.
If you accept or agree to these Terms on behalf of a company or other legal
entity, you represent and warrant that you have the authority to bind that company
or other legal entity to these Terms and, in such event, “you” and “your” will
refer and apply to that company or other legal entity.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">GDPR COMPLIANCE STATEMENT</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">Tirumala respects and complies with the EU
General Data Protection Regulations (GDPR). Some of the key ways we comply with
these regulations are:</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">&nbsp;</span></li>
 <ul style="margin-top:0in" type="disc">
  <li class="MsoNormal"><b><span lang="EN-IN">Consent :&nbsp;</span></b><span lang="EN-IN">We explain what you’re consenting to clearly and without
      ‘legalese’, and ask that you explicitly consent to contact from us.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">Breach Notification :&nbsp;</span></b><span lang="EN-IN">In the event of a breach, we will notify affected users within
      72 hours of first having become aware of the breach.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">Right to Access :&nbsp;</span></b><span lang="EN-IN">Users can request confirmation as to whether or not personal
      data concerning them is being processed, where and for what purpose.
      Further, we shall provide a copy of the personal data, in an electronic
      format.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">Right to be Forgotten :&nbsp;</span></b><span lang="EN-IN">Once we have compared your (the subjects’) rights to “the
      public interest in the availability of the data”, we may delete your
      personal data where you have requested this.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">Data Portability :&nbsp;</span></b><span lang="EN-IN">We allow you to receive the personal data concerning you,
      which we will provide in a ‘commonly used and machine readable format’
      and you have the right to transmit that data to another ‘controller’.</span></li>
  <li class="MsoNormal"><b><span lang="EN-IN">Privacy by Design :&nbsp;</span></b><span lang="EN-IN">We implement appropriate technical and organisational
      measures, in an effective way and protect the rights of data subjects’.
      We hold and process only the data absolutely necessary for the completion
      of our duties (data minimisation), as well as limiting the access to
      personal data to those needing to act out the processing.</span></li>
 </ul>
</ol>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Usage of Site, Application or Services</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">The Site, Application and Services can be
used to facilitate the Students to enrol for Courses. Application and Services
by Tirumala. You may view Listings as an unregistered visitor to the Site,
Application and Services; however, if you wish to enrol for the Courses, you
must first register to create a Tirumala Account (defined below).</span></p>

<p class="MsoNormal"><span lang="EN-IN">Tirumala makes available an online platform
which provides various online Courses for the Students. Unless explicitly
specified otherwise in the Tirumala platform, Tirumala’s responsibilities are
limited to facilitating the availability of the Courses through the Site,
Application and Services</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Account Registration</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">In order to access certain features of the
Site and Application, and to enrol for Courses, you must register to create an
account (“Tirumala Account”) and become a Member. You may register to join the
Services directly via the Site or Application or as described in this section.
A Teacher is also registered and an account (“Tirumala Account”) created once
selected by Tirumala for providing services through the Site or Application.</span></p>

<p class="MsoNormal"><span lang="EN-IN">You can also register to join by logging
into your account with certain third-party social networking sites (“SNS”) (including,
but not limited to, Facebook; each such account, a “Third-Party Account”), via
our Site or Application, as described below. As part of the functionality of
the Site, Application and Services, you may link your Tirumala Account with
Third-Party Accounts, by either: (i) providing your Third-Party Account login
information to Tirumala through the Site, Services or Application; or (ii)
allowing Tirumala to access your Third-Party Account, as permitted under the
applicable terms and conditions that govern your use of each Third-Party
Account. You represent that you are entitled to disclose your Third-Party
Account login information to Tirumala and/or grant Tirumala access to your
Third-Party Account (including, but not limited to, for use for the purposes
described herein), without breach by you of any of the terms and conditions
that govern your use of the applicable Third-Party Account and without
obligating Tirumala to pay any fees or making Tirumala subject to any usage limitations
imposed by such third-party service providers. By granting Tirumala access to
any Third-Party Accounts, you understand that Tirumala will access, make
available and store (if applicable) any Content that you have provided to and
stored in your Third-Party Account (“SNS Content”) so that it is available on
and through the Site, Services and Application via your Tirumala Account and Tirumala
Account profile page. Unless otherwise specified in these Terms, all SNS
Content, if any, will be considered to be Member Content for all purposes of
these Terms. Depending on the Third-Party Accounts you choose and subject to
the privacy settings that you have set in such Third-Party Accounts, personally
identifiable information that you post to your Third-Party Accounts will be
available on and through your Tirumala Account on the Site, Services and
Application. Please note that if a Third-Party Account or associated service
becomes unavailable or Tirumala’s access to such Third-Party Account is
terminated by the third-party service provider, then SNS Content will no longer
be available on and through the Site, Services and Application. You have the
ability to disable the connection between your Tirumala Account and your
Third-Party Accounts, at any time, by accessing the “Settings” section of the
Site and Application. Please note that your relationship with the third-party
service providers associated with your third-party accounts is governed solely
by your agreement(s) with such third-party service providers. Tirumala makes no
effort to review any SNS Content for any purpose, including but not limited to
for accuracy, legality or non-infringement and Tirumala is not responsible for
any SNS Content.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Your Tirumala Account and your Tirumala
Account profile page will be created for your use of the Site and Application
based upon the personal information you provide to us or that we obtain via an
SNS as described above. You may not have more than one (1) active Tirumala
Account. You agree to provide accurate, current and complete information during
the registration process and to update such information to keep it accurate,
current and complete. Tirumala reserves the right to suspend or terminate your Tirumala
Account and your access to the Site, Application and Services if you create
more than one (1) Tirumala Account, or if any information provided during the
registration process or thereafter proves to be inaccurate, fraudulent, not
current, incomplete, or otherwise in violation of these Terms.</span></p>

<p class="MsoNormal"><span lang="EN-IN">You are responsible for safeguarding your
password. You agree that you will not disclose your password to any third party
and that you will take sole responsibility for any activities or actions under
your Tirumala Account, whether or not you have authorized such activities or actions.
You will immediately notify Tirumala of any unauthorized use of your Tirumala
Account.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Course Listings</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">Tirumala will create Listing of various
online Courses and the details about the Course, including, but not limited to,
the subject, topic, number of sessions, mode of conduct, and time slots of the
lectures and pricing and related rules and financial terms will be listed on
the Site and the Application. Listings will be made publicly available via the
Site, Application and Services. You understand and agree that the placement or
ranking of Listings in search results may depend on a variety of factors,
including, but not limited to Students preferences, ratings.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Students will be able to view Courses via
the Site, Application and Services based upon the information provided in the
Listing, Students requirements, and Students’ search parameters and
preferences.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Please note that Tirumala assumes no
responsibility for a Student’s compliance with any agreements with or duties to
third parties, applicable laws, rules and regulations. Tirumala reserves the
right, at any time and without prior notice, to remove or disable access to any
Student for any reason, that Tirumala, in its sole discretion, considers to be
objectionable for any reason, in violation of these Terms or Tirumala’s
then-current Standards, Trademark &amp; Branding Guidelines, or otherwise
harmful to the Site, Application or Services.</span></p>

<p class="MsoNormal"><span lang="EN-IN">When you enrol for a Course, you will also
be required to upload certain information, such as, uploading a profile picture
or verifying phone number. Any Member wishing to enrol for any of the Courses
must meet these requirements.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">No Endorsement</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">By using the Site, Application or Services,
you agree that any legal remedy or liability that you seek to obtain for actions
or omissions of other Members or other third parties will be limited to a claim
against the particular Members or other third parties who caused you harm. You
agree not to attempt to impose liability on or seek any legal remedy from Tirumala
with respect to such actions or omissions.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Payment Terms</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">If an enrolment is requested for any Course
via the Site, Application or Services, We will either pre-approve, confirm or
reject the enrolment request within the period of 30 days from the date of
request for enrolment (<b>‘Enrolment Request Period’</b>), otherwise the
enrolment request will automatically expire. If We are unable to confirm or
decide to reject an enrolment request within the Enrolment Request Period, any
amounts collected by Tirumala for the requested enrolment will be refunded to
the concerned Student. When We confirm an enrolment requested by a Student, Tirumala
will send the Student an email, text message or message via e-mail and the
Application confirming such enrolment, depending on the selections you make via
the Site, Application and Services.</span></p>

<p class="MsoNormal"><span lang="EN-IN">The Course Fees payable will be displayed
to the Student before the Student sends an enrolment request to Tirumala. Upon
receipt of the Students enrolment request, Tirumala may initiate a
pre-authorization and/or charge a nominal amount to Student’s Payment Method
pursuant to the Payments Terms. If a requested enrolment is cancelled (before
any tuitions are provided), any amounts collected by Tirumala will be refunded
to such Student, depending on the selections the Student makes via the Site and
Application, and any pre-authorization of Student’s Payment Method will be
released, if applicable.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Tirumala will collect the Course Fees from
Students at the time of the enrolment request.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Tirumala agrees that no refund will be
permitted once a Couse has been purchased by a Student.</span></p>

<p class="MsoNormal"><span lang="EN-IN">You agree that Tirumala through its Site or
Application would raise system generated invoice to the Student in relation to
the Course for which the Student has enrolled or in relation to any kind of
payment done, as per applicable laws. Tirumala will raise invoice for the above
which shall be inclusive of all applicable Taxes.</span></p>

<p class="MsoNormal"><span lang="EN-IN">You as a Student agree to pay the Course
Fees for any enrolment requested, in connection with your Tirumala Account. Tirumala
will collect the Course Fees pursuant to the Payments Terms.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Once you’re confirmed enrolment transaction
is complete you will receive a confirmation email summarizing your confirmed
enrolment.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Student Cancellation Policy for
     One-on-one Classes</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">This Student Cancellation Policy (“Policy”)
is applicable to Students (“Student”) availing one-on-one coaching classes
provided by Tirumala Inc. (“Tirumala”), in accordance with the various packages
offered and their corresponding prices as determined by Tirumala.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">&nbsp;</span></li>
 <ul style="margin-top:0in" type="disc">
  <li class="MsoNormal"><span lang="EN-IN">Based on the course package selected by
      the Student, the Student shall be provided with such number of sessions
      as stipulated in the course package, at the times stipulated in such
      course package.</span></li>
  <li class="MsoNormal"><span lang="EN-IN">In the event a Student is unable to
      attend a session, the Student should inform the teacher in advance, in
      the timeline mentioned in this Policy and take a leave of absence. All
      such requests shall be communicated via email or through the Tirumala
      portal.</span></li>
  <li class="MsoNormal"><span lang="EN-IN">Except in cases of a medical emergency,
      the Students or Parent (defined hereinafter) shall inform the teacher
      (with copy to Tirumala team) 6 (Six) hours prior to a session to qualify
      for any leave of absence. In case of the Student informing about leave of
      absence, such Student shall copy the Parent as CC in any emails informing
      of such absence.</span></li>
  <li class="MsoNormal"><span lang="EN-IN">In case of sickness, the Student (with
      copy to Parent) or the Parent shall inform the teacher and Tirumala team
      6 (Six) hours prior to such leave of absence and get the approval of
      his/her teacher for such sick leave by submitting a leave of absence
      letter. In case of a medical emergency, the Student may intimate the
      teacher at any time prior to the session provided a medical certificate
      is produced within seven (7) days of the said session. Upon receipt of
      the medical certificate, a compensatory class shall be provided to the
      Student at a time to be mutually agreed upon between the Student and the
      Teacher.</span></li>
  <li class="MsoNormal"><span lang="EN-IN">Such informed absences mentioned in
      abovementioned Sections either qualify for a compensatory class as
      determined by Tirumala to be mutually decided between the Student/Parent
      and the Teacher.</span></li>
  <li class="MsoNormal"><span lang="EN-IN">In case of any uninformed absence from
      the Student, Tirumala team shall inform the Parent of such absence and
      the Student may be compensated with one more class for such missed class.
      If, however, the Student remains absent for more than 1 class without
      informing the Teacher or Tirumala team, Tirumala shall inform the Parent
      of such uninformed absences and no refund for such classes shall be made
      by Tirumala. Additionally, Tirumala reserves the right to cancel the
      course package due to such uninformed absences.</span></li>
  <li class="MsoNormal"><span lang="EN-IN">Students will be entitled, with written
      notice prior to 48 hours of first scheduled class in a week, to take a
      pause break for a week every month, where the Student may select not to
      attend/take any classes/sessions scheduled in any week. Such pause breaks
      can be used altogether at once</span></li>
  <li class="MsoNormal"><span lang="EN-IN">If a teacher is unavailable for any
      reason, with or without intimation, those classes will be compensated to
      the Students by the number of sessions the teacher has remained absent,
      however, no refund shall be made to the Student for such cancelled
      classes. Further, Tirumala shall ensure that all the classes/sessions as
      per eligibility and course package will be compensated by same or
      alternate (at Tirumala’s determination) teacher. A pro-rata refund, upon
      request, shall be made by Tirumala only in cases where the teacher
      remains absent without intimation and if an alternate teacher cannot be arranged
      by Tirumala within 7 working days.</span></li>
  <li class="MsoNormal"><span lang="EN-IN">Any extra classes to be provided will be
      prerogative of teacher, in consultation with Tirumala. Fees for such
      extra classes shall be decided by Tirumala in consultation with the
      teacher and a payment link will be sent directly to the Parent for such
      extra classes in accordance with Payment and Refund Policy.</span></li>
  <li class="MsoNormal"><span lang="EN-IN">No refund for cancellation of classes
      due to technical reasons from Tirumala’s end, alternative classes will be
      given to the Student, as per the schedule fixed by Tirumala.</span></li>
  <li class="MsoNormal"><span lang="EN-IN">If Students are not able to take classes
      due to technical issues at their home, Tirumala will arrange for
      compensatory classes if Students have informed the teacher/ Tirumala’s
      helpdesk at least 6 hours before the scheduled classes.</span></li>
  <li class="MsoNormal"><span lang="EN-IN">If the Student has not joined the class
      within 10 minutes from the scheduled start time, the class may be
      cancelled, at the teacher’s discretion. This will be considered as a
      missed class and the Student shall not be entitled to a refund and/or
      compensatory class.</span></li>
  <li class="MsoNormal"><span lang="EN-IN">Tirumala may transfer the Student to
      another teacher of the same caliber in special cases, at its sole
      discretion.</span></li>
  <li class="MsoNormal"><span lang="EN-IN">This Policy may be revised from time to
      time at Tirumala’s sole discretion. Students and Parents are advised to
      check this Policy regularly for any updates and/or revisions.</span></li>
  <li class="MsoNormal"><span lang="EN-IN">This Policy shall be governed by the
      laws of Delaware and courts at Delaware shall have the jurisdiction to
      settle any disputes arising hereunder.</span></li>
 </ul>
 <li class="MsoNormal"><span lang="EN-IN">Cancellations and Refunds</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">Student may apply for termination of the
subscription of the Course anytime. His/her subscription would get terminated
in accordance with the terms of the Listing and would not be charged from next
billing cycle onwards.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Tirumala will not refund any unutilized
price for any part of the Services. However, termination of the subscription
shall not relieve the Student of any obligations (including, but not limited to
the payment for the Services) as set forth in this Agreement.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Taxes</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">You understand and acknowledge that
appropriate governmental agencies, departments or authorities (the&nbsp;<b>“Tax
Authority”</b>) where your office or residence is located may require Taxes to
be collected from Students on the amount paid for the Course and to be remitted
to the respective Tax Authority. The laws in jurisdictions may vary, but these
Taxes may be required to be collected and remitted as a percentage of the
Course Fees set by Tirumala.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">User Conduct</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">You understand and agree that you are
solely responsible for compliance with any and all laws, rules, regulations,
and Tax obligations that may apply to your use of the Site, Application,
Services and Collective Content. In connection with your use of the Site,
Application, Services and Collective Content, you may not and you agree that
you will not:</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">&nbsp;</span></li>
 <ul style="margin-top:0in" type="disc">
  <li class="MsoNormal"><span lang="EN-IN">violate any local, state, national, or
      other law or regulation, or any order of a court, including, without
      limitation, Tax regulations;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">use manual or automated software,
      devices, scripts, robots, backdoors or other means or processes to
      access, “scrape,” “crawl” or “spider” any web pages or other services
      contained in the Site, Application, Services or Collective Content;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">access or use our Site, Application,
      Services expose or allow to be used or exposed, any Tirumala Content: (i)
      that is not publicly displayed by Tirumala in its search results pages or
      listing pages before an enrolment is confirmed; (ii) in any way that is
      inconsistent with the Tirumala’s Privacy Policy or Terms of Service; or
      (iii) in any way that otherwise violates the privacy rights or any other
      rights of Tirumala’s users or any other third party;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">use the Site, Application, Services or
      Collective Content for any commercial or other purposes that are not
      expressly permitted by these Terms or in a manner that falsely implies Tirumala
      endorsement, partnership or otherwise misleads others as to your
      affiliation with Tirumala;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">dilute, tarnish or otherwise harm the Tirumala
      brand in any way, including through unauthorized use of Collective
      Content, registering and/or using Tirumala or derivative terms in domain
      names, trade names, trademarks or other source identifiers, or
      registering and/or using domains names, trade names, trademarks or other
      source identifiers that closely imitate or are confusingly similar to Tirumala
      domains, trademarks, taglines, promotional campaigns or Collective
      Content;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">copy, store or otherwise access or use
      any information contained on the Site, Application, Services or
      Collective Content for purposes not expressly permitted by these Terms;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">infringe the rights of Tirumala or the
      rights of any other person or entity, including without limitation, their
      intellectual property, privacy, publicity or contractual right;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">interfere with or damage our Site,
      Application or Services, including, without limitation, through the use
      of viruses, cancel bots, Trojan horses, harmful code, flood pings,
      denial-of-service attacks, backdoors, packet or IP spoofing, forged
      routing or electronic mail address information or similar methods or
      technology;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">use our Site, Application or Services to
      transmit, distribute, post or submit any information concerning any other
      person or entity, including without limitation, photographs of others
      without their permission, personal contact information or credit, debit,
      calling card or account numbers;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">use our Site, Application, Services or
      Collective Content in connection with the distribution of unsolicited
      commercial email (“spam”) or advertisements unrelated to lodging in a
      private residence;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">“stalk” or harass any other user of our
      Site, Application, Services or Collective Content, or collect or store
      any personally identifiable information about any other user other than
      for purposes of transacting as an Tirumala Student;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">register for more than one Tirumala
      Account or register for an Tirumala Account on behalf of an individual
      other than yourself, except in case where the parent is registering the
      account for its child or guardian for its ward;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">contact another Member for any purpose
      other than asking a question related to an Enrolment, Course, Listing, or
      the Member’s use of the Site, Application and Services;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">recruit or otherwise solicit any Member
      to join third-party services or websites that are competitive to Tirumala,
      without Tirumala’s prior written approval;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">recruit or otherwise solicit any Member
      to join third-party services, applications or websites, without Our prior
      written approval;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">impersonate any person or entity, or
      falsify or otherwise misrepresent yourself or your affiliation with any
      person or entity;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">use automated scripts to collect
      information from or otherwise interact with the Site, Application,
      Services or Collective Content;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">use the Site, Application, Services or
      Collective Content to find a Student and then complete an enrolment of
      Course independent of the Site, Application or Services, in order to
      circumvent the obligation to pay any Fees related to Tirumala’s provision
      of the Services or for any other reasons;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">violate these Terms or Tirumala’s
      then-current Policies and or Standards;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">engage in disruptive, circumventive,
      abusive or harassing behaviour in any area or aspect of our Platform,
      Application, or Services;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">post, upload, publish, submit or
      transmit any Content that: (i) infringes, misappropriates or violates a
      third party’s patent, copyright, trademark, trade secret, moral rights or
      other intellectual property rights, or rights of publicity or privacy;
      (ii) violates, or encourages any conduct that would violate, any
      applicable law or regulation or would give rise to civil liability; (iii)
      is fraudulent, false, misleading (directly or by omission or failure to
      update information) or deceptive; (iv) is defamatory, obscene,
      pornographic, vulgar or offensive; (v) promotes discrimination, bigotry,
      racism, hatred, harassment or harm against any individual or group; (vi)
      is violent or threatening or promotes violence or actions that are
      threatening to any other person; or (vii) promotes illegal or harmful
      activities or substances;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">systematically retrieve data or other
      content from our Site, Application or Services to create or compile,
      directly or indirectly, in single or multiple downloads, a collection,
      compilation, database, directory or the like, whether by manual methods,
      through the use of bots, crawlers, or spiders, or otherwise;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">use, display, mirror or frame the Site,
      Application, Services or Collective Content, or any individual element
      within the Site, Application, Services or Collective Content, Tirumala’s
      name, any Tirumala trademark, logo or other proprietary information, or
      the layout and design of any page or form contained on a page in the
      Site, Application or Services, without Tirumala’s express written
      consent;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">access, tamper with, or use non-public
      areas of the Site, Application or Services, Tirumala’s computer systems,
      or the technical delivery systems of Tirumala’s providers;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">attempt to probe, scan, or test the
      vulnerability of any Tirumala system or network or breach any security or
      authentication measures;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">avoid, bypass, remove, deactivate,
      impair, descramble, or otherwise circumvent any technological measure
      implemented by Tirumala or any of Tirumala’s providers or any other third
      party (including another user) to protect the Site, Services, Application
      or Collective Content;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">forge any TCP/IP packet header or any
      part of the header information in any email or newsgroup posting, or in
      any way use the Site, Services, Application or Collective Content to send
      altered, deceptive or false source-identifying information;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">attempt to decipher, decompile,
      disassemble or reverse engineer any of the software used to provide the
      Site, Services, Application or Collective Content;</span></li>
  <li class="MsoNormal"><span lang="EN-IN">advocate, encourage, or assist any third
      party in doing any of the foregoing; or</span></li>
  <li class="MsoNormal"><span lang="EN-IN">accept or make a payment for Course Fees
      outside Tirumala. If you do so, you acknowledge and agree that you: (i)
      would be in breach of these Terms; (ii) accept all risks and
      responsibility for such payment, and (iii) hold Tirumala harmless from
      any liability for such payment.</span></li>
 </ul>
</ol>

<p class="MsoNormal"><span lang="EN-IN">Tirumala has the right to investigate and
prosecute violations of any of the above to the fullest extent of the law. In
addition, and as set in these Terms, Tirumala may take a range of actions
against you, including but not limited to deactivating or cancelling Tirumala
Account, for a violation of this Section or these Terms.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Tirumala may access, preserve and disclose
any of your information if we are required to do so by law, or if we believe in
good faith that it is reasonably necessary to (i) respond to claims asserted
against Tirumala or to comply with legal process (for example, summons or
warrants), (ii) enforce or administer our agreements with users, such as these
Terms, (iii) for fraud prevention, risk assessment, investigation, customer
support, product development and debugging purposes, or (iv) protect the
rights, property or safety of Tirumala, its users, or members of the public.</span></p>

<p class="MsoNormal"><span lang="EN-IN">You acknowledge that Tirumala has no
obligation to monitor your access to or use of the Site, Application, Services
or Collective Content or to review or edit any Member Content, but has the
right to do so for the purpose of operating and improving the Site, Application
and Services (including without limitation for fraud prevention, risk
assessment, investigation and customer support purposes), to ensure your
compliance with these Terms, to comply with applicable law or the order or
requirement of a court, administrative agency or other governmental body, to
respond to content that it determines is otherwise objectionable or as set
forth in these Terms.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Tirumala reserves the right, at any time
and without prior notice, to remove or disable access to any Collective Content
that Tirumala, at its sole discretion, considers to be objectionable for any
reason, in violation of these Terms or otherwise harmful to the Site,
Application or Services.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Member Content</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">We may, in our sole discretion, permit you
to post, upload, publish, submit or transmit Member Content. By making
available any Member Content on or through the Site, Application, Services, or
through Tirumala promotional campaigns, you hereby grant to Tirumala a
worldwide, irrevocable, perpetual (or for the term of the protection),
non-exclusive, transferable, royalty-free license, with the right to
sublicense, to use, view, copy, adapt, modify, distribute, license, sell,
transfer, publicly display, publicly perform, transmit, stream, broadcast,
access, view, and otherwise exploit such Member Content on, through, by means
of or to promote or market the Site, Application and Services. Tirumala does
not claim any ownership rights in any such Member Content and nothing in these
Terms will be deemed to restrict any rights that you may have to use and
exploit any such Member Content.</span></p>

<p class="MsoNormal"><span lang="EN-IN">You acknowledge and agree that you are
solely responsible for all Member Content that you make available through the
Site, Applications, Services or through Tirumala promotional campaigns.
Accordingly, you represent and warrant that: (i) you either are the sole and
exclusive owner of all Member Content that you make available through the Site,
Application, Services or through Tirumala promotional campaigns or you have all
rights, licenses, consents and releases that are necessary to grant to Tirumala
the rights in such Member Content, as contemplated under these Terms; and (ii)
neither the Member Content nor your posting, uploading, publication, submission
or transmittal of the Member Content or Tirumala’s use of the Member Content
(or any portion thereof) on, through or by means of the Site, Application, the
Services or Tirumala promotional campaigns will infringe, misappropriate or violate
a third party’s patent, copyright, trademark, trade secret, moral rights or
other proprietary or intellectual property rights, or rights of publicity or
privacy, or result in the violation of any applicable law or regulation.</span></p>

<p class="MsoNormal"><span lang="EN-IN">These Terms and Conditions are published in
accordance with the provisions of Rule 3 (1) of the Information Technology
(Intermediaries guidelines) Rules, 2011 that require publishing the rules and
regulations, privacy policy and terms and conditions for access or usage of the
Site.</span></p>

<p class="MsoNormal"><span lang="EN-IN">You are advised not to post any information
or messages that are, or that may be construed, as being malicious ,
defamatory, inappropriate, slanderous, pornographic or otherwise sexually
oriented or that makes attacks on or the otherwise opines or comments on any
individuals or groups of individuals, educational institutions or any other
entities whatsoever (whether companies, firms, or any other institutions). You
also agree not to post any information to which you do not have copyrights or
other appropriate permissions to post in public forum. Your failure to comply
with these terms may result in removal of your postings without prior notice to
the Student. The IP address of all posts is recorded to aid in enforcing these
conditions.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Hyperlinks</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">The Site, Application and Services may
contain links to third-party websites or resources. You acknowledge and agree
that Tirumala is not responsible or liable for: (i) the availability or
accuracy of such websites or resources; or (ii) the content, products, or services
on or available from such websites or resources. Links to such websites or
resources do not imply any endorsement by Tirumala of such websites or
resources or the content, products, or services available from such websites or
resources. You acknowledge sole responsibility for and assume all risk arising
from your use of any such websites or resources or the Content, products or
services on or available from such websites or resources.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Feedback</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">We welcome and encourage you to provide
feedback, comments and suggestions for improvements to the Site, Application
and Services (“Feedback”). You may submit Feedback by emailing us, through the
“Contact” section of the Site and Application, or by other means of
communication. You acknowledge and agree that all Feedback you give us will be
the sole and exclusive property of Tirumala and you hereby irrevocably assign
to Tirumala and agree to irrevocably assign to Tirumala all of your right,
title, and interest in and to all Feedback, including without limitation all
worldwide patent, copyright, trade secret, moral and other proprietary or
intellectual property rights therein, and waive any moral rights you may have
in such Feedback. At Tirumala’s request and expense, you will execute documents
and take such further acts as Tirumala may reasonably request to assist Tirumala
to acquire, perfect, and maintain its intellectual property rights and other
legal protections for the Feedback.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Copyright Policy</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">Tirumala respects copyright law and expects
its users to do the same. It is Tirumala’s policy to terminate in appropriate
circumstances the Tirumala Accounts of Members or other account holders who
repeatedly infringe or are believed to be repeatedly infringing the rights of
copyright holders.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Term and Termination, Suspension and
     Other Measures</span></li>
</ol>

<p class="MsoNormal"><b><span lang="EN-IN">Term</span></b></p>

<p class="MsoNormal"><span lang="EN-IN">This Agreement shall be effective for till
the time Members access or use the Site, Application or Services or by
downloading or posting any content from or on the Site, via the Application or
through the Services Until such time when you or Tirumala terminate the
Agreement as described below.</span></p>

<p class="MsoNormal"><b><span lang="EN-IN">Termination for convenience</span></b></p>

<p class="MsoNormal"><span lang="EN-IN">You may terminate this Agreement at any
time via the “Cancel Account” feature on the Site or by sending us an email. If
you cancel your Tirumala Account, any confirmed enrolment will be automatically
cancelled and any refund will depend upon the terms of the applicable
cancellation policy. Without limiting our rights specified below, Tirumala may
terminate this Agreement for convenience at any time by giving you notice via
email to your registered email address.</span></p>

<p class="MsoNormal"><b><span lang="EN-IN">Termination for breach, suspension and
other measures</span></b></p>

<p class="MsoNormal"><span lang="EN-IN">Tirumala may immediately, without notice
terminate this Agreement if (i) you have materially breached these Terms or our
Policies, including but not limited to any breach of your warranties outlined
in these Terms or breach of the “User Conduct” provisions in these Terms, (ii)
you have provided inaccurate, fraudulent, outdated or incomplete information
during the Tirumala Account registration, or Listing process or thereafter,
(iii) you have violated applicable laws, regulations or third party rights, or
(iv) Tirumala believes in good faith that such action is reasonably necessary
to protect the safety or property of other Members, Tirumala or third parties,
for fraud prevention, risk assessment, security or investigation purposes.</span></p>

<p class="MsoNormal"><span lang="EN-IN">In addition Tirumala may deactivate or
delay reviews, or other Member Content, cancel any pending or confirmed
enrolments, limit your use of or access to your Tirumala Account and the Site,
Application or Services, temporarily or permanently revoke any special status
associated with your Tirumala Account, or temporarily or permanently suspend
your Tirumala Account if (i) you have breached these Terms or our Policies,
including material and nonmaterial breaches and receiving poor ratings from
Students, or (ii) Tirumala believes in good faith that such action is
reasonably necessary to protect the safety or property of Members, Tirumala or
third parties, for fraud prevention, risk assessment, security or investigation
purposes.</span></p>

<p class="MsoNormal"><span lang="EN-IN">In case of non-material breaches and where
appropriate, you will be given notice of any measure by Tirumala and an
opportunity to resolve the issue to Tirumala’s reasonable satisfaction.</span></p>

<p class="MsoNormal"><b><span lang="EN-IN">Consequences of Termination</span></b></p>

<p class="MsoNormal"><span lang="EN-IN">If we take any of the measures described
above we may (i) communicate to the Students that a pending or confirmed
enrolment has been cancelled, (ii) refund the Students in full for any and all
confirmed enrolments, irrespective of pre-existing cancellation policies, (iii)
support the Students, on an exceptional basis, in finding potential alternative
Courses, and (iv) you will not be entitled to any compensation for confirmed
enrolments that were cancelled.</span></p>

<p class="MsoNormal"><span lang="EN-IN">If You or We terminate this Agreement, we
do not have an obligation to delete or return to you any of your Member
Content, including but not limited to any reviews or Feedback. When this
Agreement has been terminated, you are not entitled to a restoration of your Tirumala
Account or any of your Member Content. If your access to or use of the Site,
Application and Services has been limited or your Tirumala Account has been
suspended or this Agreement has been terminated by us, you may not register a
new Tirumala Account or attempt to access and use the Site, Application and
Services through other Tirumala Accounts.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Survival</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">If you or we terminate this Agreement, the
clauses of these Terms that reasonably should survive termination of the
Agreement will remain in effect.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Disclaimers</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">If you choose to use the Site, Application,
Services or Collective Content, you do so at your sole risk. You acknowledge
and agree that Tirumala does not have an obligation to conduct background or
character checks on any Member, but may conduct such background or character
checks in its sole discretion. If we choose to conduct such checks, to the
extent permitted by applicable law, we disclaim warranties of any kind, either
express or implied, that such checks will identify prior misconduct by a user
or guarantee that a user will not engage in misconduct in the future.</span></p>

<p class="MsoNormal"><span lang="EN-IN">The Site, Application, Services and
Collective Content are provided “as is”, without warranty of any kind, either
express or implied. Without limiting the foregoing, Tirumala explicitly disclaims
any warranties of merchantability, fitness for a particular purpose, quiet
enjoyment or non-infringement, and any warranties arising out of course of
dealing or usage of trade. Tirumala makes no warranty that the site,
application, services, collective content, including, but not limited to, any
Courses, will meet your requirements or be available on an uninterrupted,
secure, or error-free basis. Tirumala makes no warranty regarding the quality
of any Listings, Courses, teachers, Students, the Services or Collective
Content or the accuracy, timeliness, truthfulness, completeness or reliability
of any collective content obtained through the Site, Application or Services.</span></p>

<p class="MsoNormal"><span lang="EN-IN">No advice or information, whether oral or
written, obtained from Tirumala or through the Site, Application, Services or
Collective Content, will create any warranty not expressly made herein.</span></p>

<p class="MsoNormal"><span lang="EN-IN">You are solely responsible for all of your
communications and interactions with other users of the Site, Application or
Services and with other persons with whom you communicate or interact as a
result of your use of the Site, Application or Services, including, but not
limited to, any Students. You understand that Tirumala does not make any
attempt to verify the statements of users of the Site, Application or Services
or to review any Course. Tirumala makes no representations or warranties as to
the conduct of users of the Site, Application or Services or their
compatibility with any current or future users of the Site, Application or
Services. You agree to take reasonable precautions in all communications and
interactions with other users of the site, application or services and with
other persons with whom you communicate or interact as a result of your use of
the Site, Application Or Services, including, but not limited to, Students,
particularly if you decide to meet offline or in person regardless of whether
such meetings are organized by Tirumala. Tirumala explicitly disclaims all
liability for any act or omission of any Student or other third party.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Limitation of Liability</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">You acknowledge and agree that, to the
maximum extent permitted by law, the entire risk arising out of your access to
and use of the Site, Application, Services and Collective Content, your
enrolment of any Course via the Site, Application and Services, and any contact
you have with other users of Tirumala whether in person or online remains with
you. Neither Tirumala nor any other party involved in creating, producing, or
delivering the Site, Application, Services, Collective Content will be liable
for any incidental, special, exemplary or consequential damages, including lost
profits, loss of data or loss of goodwill, service interruption, computer
damage or system failure or the cost of substitute products or services, or for
any damages for personal or bodily injury or emotional distress arising out of
or in connection with these terms, from the use of or inability to use the
Site, Application, Services or Collective Content, from any communications,
interactions or meetings with other users of the Site, Application, or Services
or other persons with whom you communicate or interact as a result of your use
of the Site, Application, Services, or from your Enrolment of any Course via
the Site, Application And Services, whether based on warranty, contract, tort
(including negligence), product liability or any other legal theory, and
whether or not Tirumala has been informed of the possibility of such damage,
even if a limited remedy set forth herein is found to have failed of its essential
purpose.</span></p>

<p class="MsoNormal"><span lang="EN-IN">In no event will Tirumala’s aggregate
liability arising out of or in connection with these Terms and your use of the
Site, Application and Services including, but not limited to, your enrolment of
any course via the Site, Application and Services, or from the use of or
inability to use the Site, Application, Services, or Collective Content and in
connection with any course or interactions with any other members, exceed the
amounts you have paid or owe for enrolments via the Site, Application and
Services as a student in the three (3) month period prior to the event giving
rise to the liability. The limitations of damages set forth above are
fundamental elements of the basis of the bargain between Tirumala and you.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Indemnification</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">You agree to release, defend, indemnify,
and hold Tirumala and its affiliates and subsidiaries, and their officers,
directors, employees and agents, harmless from and against any claims,
liabilities, damages, losses, and expenses, including, without limitation,
reasonable legal and accounting fees, arising out of or in any way connected
with (a) your access to or use of the Site, Application, Services, or
Collective Content or your violation of these Terms; (b) your Member Content;
(c) your (i) interaction with any Member, (ii) enrolment of an Course;
including but not limited to any injuries, losses, or damages (compensatory,
direct, incidental, consequential or otherwise) of any kind arising in
connection with or as a result of an enrolment or attending of a Course.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Entire Agreement</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">Except as they may be supplemented by
additional Tirumala policies, guidelines, standards, or terms for a specific
product, feature, service or offering, these Terms constitute the entire and
exclusive understanding and agreement between Tirumala and you regarding the
Site, Application, Services, Collective Content (excluding Payment Services),
and any enrolments made via the Site, Application and Services (excluding
Payment Services), and these Terms supersede and replace any and all prior oral
or written understandings or agreements between Tirumala and you regarding
enrolments, the Site, Application, Services, and Collective Content (excluding
Payment Services).</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Notices</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">Any notices or other communications
permitted or required hereunder, including those regarding modifications to
these Terms, will be in writing and given by Tirumala (i) via email (in each
case to the address that you provide) or (ii) by posting to the Site or via the
Application. For notices made by e-mail, the date of receipt will be deemed the
date on which such notice is transmitted.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Governing Law and Jurisdiction</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">These Terms and your use of the Services
will be interpreted in accordance with the laws of India excluding its rules on
conflicts of laws. You and we agree to submit any dispute arising under these
Terms to the personal jurisdiction of a court located in Hyderabad for any
actions for which the parties retain the right to seek injunctive or other
equitable relief in a court of competent jurisdiction to prevent the actual or
threatened infringement, misappropriation or violation of a party’s copyrights,
trademarks, trade secrets, patents, or other intellectual property rights.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Any dispute, claim or controversy arising
out of or relating to this Terms including the determination of the scope or
applicability of this Terms to arbitrate, or your use of the Application or
information to which it gives access, shall be determined by arbitration in
India, before a sole arbitrator mutually appointed by Members and Tirumala.
Arbitration shall be conducted in accordance with the Arbitration and
Conciliation Act, 1996. The seat of such arbitration shall be Hyderabad. All
proceedings of such arbitration, including, without limitation, any awards,
shall be in the English language. The award shall be final and binding on the
parties to the dispute.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">No Waiver</span></li>
</ol>

<p class="MsoNormal"><span lang="EN-IN">The failure of Tirumala to enforce any
right or provision of these Terms will not constitute a waiver of future
enforcement of that right or provision. The waiver of any such right or
provision will be effective only if in writing and signed by a duly authorized
representative of Tirumala. Except as expressly set forth in these Terms, the
exercise by either party of any of its remedies under these Terms will be
without prejudice to its other remedies under these Terms or otherwise. If for
any reason a court of competent jurisdiction finds any provision of these Terms
invalid or unenforceable, that provision will be enforced to the maximum extent
permissible and the other provisions of these Terms will remain in full force
and effect.</span></p>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Miscellaneous</span></li>
</ol>

<ol style="margin-top:0in" start="1" type="1">
 <li class="MsoNormal"><span lang="EN-IN">Tirumala shall have no liability of any
     nature, whether in contract, or otherwise, for any losses whatsoever and
     howsoever caused, from or in any manner connected with any of the Services
     provided by Us.</span></li>
 <li class="MsoNormal"><span lang="EN-IN">Tirumala is not liable for any failure or
     delay of performance (or otherwise) arising out of a cause beyond Tirumala’s
     reasonable control.</span></li>
 <li class="MsoNormal"><span lang="EN-IN">You may not assign or transfer these
     Terms, by operation of law or otherwise, without Tirumala’s prior written
     consent. Any attempt by you to assign or transfer these Terms, without
     such consent, will be null and of no effect. Tirumala may assign or
     transfer these Terms, at its sole discretion, without restriction. Subject
     to the foregoing, these Terms will bind and inure to the benefit of the
     parties, their successors and permitted assigns.</span></li>
 <li class="MsoNormal"><span lang="EN-IN">Tirumala reserves the right at any time
     and from time to time to modify or discontinue, temporarily or
     permanently, the Services (or any part thereof) provided by Us with or
     without notice. You agree that Tirumala shall not be liable to You or to
     any third party for any modification, suspension or discontinuance of such
     Services. It is Your responsibility to review these Terms periodically for
     updates/changes.</span></li>
 <li class="MsoNormal"><span lang="EN-IN">You agree not to reproduce, duplicate,
     copy, sell, resell or exploit for any commercial purposes, any portion of
     the information provided by us, including any intellectual property rights
     of Tirumala or any person firm or corporation having posted information
     for availability through the Services provided by us.</span></li>
 <li class="MsoNormal"><span lang="EN-IN">You agree that in the event Your post or
     Your information violates any provision of this Terms, We shall have the
     right to refuse to provide You or any person acting on Your behalf, access
     to the Site and Application, terminate and/ or suspend Your access if
     applicable in the future.</span></li>
</ol>

<ul style="margin-top:0in" type="disc">
 <li class="MsoNormal"><span lang="EN-IN">YOU HAVE READ THESE TERMS OF USE AND
     AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE</span></li>
</ul>

<p class="MsoNormal"><span lang="EN-IN">&nbsp;</span></p>

</div>




</body></html>`;
}

const TermsAndConditions = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="text"
        onClick={handleClickOpen}
        className={classes.customButton}
      >
        Terms and Conditions
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <iframe
              srcDoc={createMarkup()}
              title="Terms and Conditions"
              width="100%"
              style={{ border: "none", minHeight: "80vh" }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TermsAndConditions;
