import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import theme from "../../assets/theme";
import { formatDuration } from "../../common/formateDuration";
import MarksList from "./MarksList";
import PercentageRing from "./PercentageRing";
import subjectList from "./subjects.json";
import { Box } from "@material-ui/core";
function PercentageStats({ totalTime, subject }) {
  const attemptData = useSelector((state) => state.rankAnalysis);
  const [correct, setCorrect] = useState(0);
  const [inCorrect, setInCorrect] = useState(0);
  const [unAttempted, setUnAttempted] = useState(0);
  useEffect(() => {
    if (subjectList[subject]) {
      setCorrect(attemptData.data.YourDetails[subjectList[subject].correct]);
      setInCorrect(attemptData.data.YourDetails[subjectList[subject].wrong]);
      setUnAttempted(
        attemptData.data.YourDetails[subjectList[subject].unAttempted]
      );
    }
  });
  return (
    <React.Fragment>
      {/* display total duration  */}
      <MarksList subjectName="Spent" subjectMarks={formatDuration(totalTime)} />
      <br />
      {/* circular progress bar  */}
      <PercentageRing subject={subject} />
      <br />
      {/* attempts by student  */}
      <Box display="flex" flexDirection="column" gridGap="10px">
        <MarksList
          subjectName="Correct"
          subjectColor={{ color: theme.palette.info.main }}
          subjectMarks={
            attemptData.data.YourDetails ? (correct ? correct : 0) : 0
          }
        />
        <MarksList
          subjectName="Incorrect"
          subjectColor={{ color: theme.palette.text.primary }}
          subjectMarks={
            attemptData.data.YourDetails ? (inCorrect ? inCorrect : 0) : 0
          }
        />
        <MarksList
          subjectName="Un attempted"
          subjectColor={{ color: theme.palette.text.secondary }}
          subjectMarks={
            attemptData.data.YourDetails ? (unAttempted ? unAttempted : 0) : 0
          }
        />
      </Box>
    </React.Fragment>
  );
}

export default PercentageStats;
