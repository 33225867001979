import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CardBody from "components/Card/CardBody.jsx";
// import Container from '@material-ui/core/Container';
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import store from "store";
import { Link, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import examAxios from "../apis/examServerAxios";
import axios from "axios";
import Header from "./Header";
import NetworkProgress from "./NetworkProgress";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import styles1 from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx";
import home_test from "assets/img/home_test.svg";
// import styles from "assets/jss/material-kit-pro-react/customSelectStyle.jsx";

const styles = (theme) => ({
  appBar: {
    color: "white",
    background: "#54bdd5",
    padding: theme.spacing.unit * 1,
  },
  avatar: {
    margin: 0,
  },
  mainContainer: {
    background: "#fafafa",
    //   padding: theme.spacing(10),
  },
  cardTitle: {
    //   color: '#b1b1b1'
    fontSize: "24px",
    color: "#333333",
    marginBottom: theme.spacing.unit * 1,
    marginTop: theme.spacing.unit * 5,
    marginLeft: theme.spacing.unit * 2,
  },
  card: {
    boxShadow:
      "inset 0 4px 7px 1px #ffffff, inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14), 0 10px 20px rgba(0, 21, 64, 0.05)",
    borderRadius: "15px",
    maxWidth: 345,
  },
  media: {
    height: 100,
  },

  rankBadge: {
    marginTop: "-60px",
    width: "7em",
    height: "7em",
    padding: "2em",
    marginRight: "-10%",
    textAlign: "center",
    verticalAlign: "middle",
    background: "beige",
    borderRadius: "10em",
  },
  progress: {
    color: "#54bdd5",
    marginTop: theme.spacing.unit * 10,
  },
  bimage: {
    backgroundSize: "cover",
    height: "90vh",
    backgroundImage: `url("${home_test}")`,
  },
});
class WorkSheets extends React.Component {
  flagid = "NA";
  examStatusMap = {
    live: "STARTED",
    upcoming: "NOT_STARTED",
    previous: "FINISHED",
  };
  examStatus = {
    STARTED: [],
    NOT_STARTED: [],
    FINISHED: [],
  };
  constructor(props) {
    super(props);
    this.props = props;
    this.enterExam = this.enterExam.bind(this);
    this.state = {
      networkRequest: false,
      isMockUser: false,
      type: "JEE MAINS",
      paid: false,
      worksheets: [],
    };
  }

  payment(type) {
    let payAmount = {
      MA: 6000,
      N: 6000,
      MAN: 9000,
    };
    let user = JSON.parse(window.localStorage.getItem("user"));
    console.log(user._id);
    var requestData = {
      _id: user._id,
      key: "97PIzxdO",
      txnid: "123456789",
      // color: "primary",
      hash: "",
      amount: payAmount[type],
      firstname: user["name"],
      email: "kurapati.muralikrish@gmail.com",
      phone: user["phoneNumber"],
      productinfo: JSON.stringify({
        _id: user._id,
        package: type,
      }),
      surl: window.location.origin + "/api/pay/success",
      furl: window.location.origin + "/paymentfailed",
      salt: "UhvE4JAdfK",
      mode: "dropout", // non-mandatory for Customized Response Handling
    };
    var Handler = {
      responseHandler: function(response) {
        console.log(response);
      },
      catchException: function(BOLT) {},
    };
    const ax = axios.create({
      baseURL: window.location.origin + "/api",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    ax.post("/pay/request", JSON.stringify(requestData)).then((res) => {
      console.log(res);
      if (res.data.status === "SUCCESS") {
        requestData.hash = res.data.hash;
        window.bolt.launch(requestData, Handler);
      }
    });
    //  window.bolt.launch( requestData , Handler );
  }

  handleTypeSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    const id = store.get("user")._id;
    this.flagid = id;
    let body = JSON.stringify({
      student_id: id,
    });
    const config = {
      onUploadProgress: (event) => console.log(event),
    };
    this.setState({ networkRequest: true, worksheets: [] });
    // console.log(this.props, "from mock")
    // if (this.props.location.state !== undefined && this.props.location.state.mockuser) {
    //   this.setState({ isMockUser: true, type: this.props.location.state.testtype });
    //   body = JSON.stringify({
    //     phone: "1234567890",
    //     email_id: "mock@gmail.com",
    //     testType: this.props.location.state.testtype
    //   })
    //   examAxios.post("/exams/mocktest", body).then(body => body.data.payload).then(data => {
    //     data.Items.forEach(exam => {
    //       store.set("user", { _id: exam.student_id })
    //       this.examStatus[exam.test_status].push(exam);
    //     })
    //     Object.keys(this.examStatus).forEach(key => {
    //       this.examStatus[key] = this.examStatus[key].sort((a, b) => {
    //         return b.time_started - a.time_started;
    //       })
    //     })
    //     this.setState({ networkRequest: false })
    //   },
    //     err => {
    //       this.setState({ networkRequest: false })
    //     },
    //     () => {
    //       this.setState({ networkRequest: false })
    //     }
    //   )
    // }
    // else
    examAxios
      .post("/worksheet/studentworksheets ", body)
      .then((body) => body.data)
      .then((data) => {
        let worksheets = data.payload.Items;
        console.log(worksheets);
        this.setState({ worksheets: worksheets });
        this.forceUpdate();
        // exams.Items.forEach(exam => {
        //   this.examStatus[exam.test_status].push(exam);
        // });

        // this.examStatus['STARTED'].forEach(exam => {
        //   const remainingTime = Number(exam.test_duration) + Number(moment(exam.time_started).subtract(moment.now()).format('x'));
        //   if (remainingTime < 1) {
        //     let body = JSON.stringify({
        //       student_id: id,
        //       test_name: exam.test_name
        //     });
        //     examAxios.post('/exams/submittest', body).then(val => { this.forceUpdate() }).catch(err => { })
        //   }
        // })

        // if (data.message == "Unpaid account") {
        //   this.setState({
        //     paid: false
        //   })
        // } else {
        //   this.setState({
        //     paid: true
        //   })
        // }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ networkRequest: false });
      });
  }
  enterExam(type, exam) {
    console.log(exam);
    const { history } = this.props;
    switch (type) {
      case "START":
        switch (exam.test_type) {
          case "JEE MAINS":
            history.push("/instruction", exam);
            break;
          case "NEET":
            history.push("/instructionneet", exam);
            break;
          case "JEE ADVANCED":
            history.push("/instructionadv", exam);
            break;
        }
        break;
      case "CONTINUE":
        history.push("./exam-portal", exam);
        break;
      case "PREVIOUS":
        history.push("./view", exam);
        break;
    }
  }
  render() {
    const { classes } = this.props;
    console.log(classes);
    return (
      <React.Fragment>
        {/* <Header /> */}

        <br />
        <div
          id="con-hari"
          className={this.flagid.includes("mock") ? classes.bimage : ""}
        >
          {!this.state.networkRequest ? (
            <React.Fragment>
              <GridContainer justify="space-evenly">
                <GridItem xs={6} sm={3} md={2}>
                  <h3> Worksheets !!</h3>
                </GridItem>
              </GridContainer>
              <GridContainer justify="flex-start">
                {this.state.worksheets.map((w) => {
                  return (
                    <GridItem xs={12} sm={4} md={3}>
                      <Card style={{ margin: "8px" }}>
                        <CardBody>
                          <h5>Worksheet Name: {w.workSheetDisplayName}</h5>
                        </CardBody>
                        <CardActions>
                          <Link
                            to={{
                              pathname: "/ws",
                              state: {
                                worksheet: w,
                              },
                            }}
                            style={{ marginLeft: "auto" }}
                          >
                            <Button round color="primary">
                              practice
                            </Button>
                          </Link>
                        </CardActions>
                      </Card>
                    </GridItem>
                  );
                })}
              </GridContainer>
            </React.Fragment>
          ) : (
            <NetworkProgress> </NetworkProgress>
          )}
        </div>
        {/* <HomeCards> </HomeCards> */}
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
  withStyles(styles1)
)(WorkSheets);
