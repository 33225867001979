import {
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  Hidden,
  makeStyles,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "store";
import theme from "../../../assets/theme";
import {
  clearBodyFromStore,
  clearErrorBookmark,
  clearErrorQuestions,
  clearErrorSubmit,
  fetchQuestionsList,
} from "../../../redux/actions/practicePageActions/actionCreators";
import ComingSoonPlans from "../../components/ComingSoonPlans";
import NetworkProgress from "../../NetworkProgress";
import MultiSelectDropDown from "../MultiSelectDropDown";
import {
  AttemptStatus,
  filterQuestion,
  QuestionType,
} from "../topicDetails/atemptStatusAndQuestionType";
import DisplaySingleQuestion from "./DisplaySingleQuestion";
import HeaderPracticeSet from "./HeaderPracticeSet";
import QuestionComponent from "./QuestionComponent";
import QuestionNumberPad from "./QuestionNumberPad";
import { WrapperCard } from "./WrapperCard";
const drawerWidth = 240;
const useStyle = makeStyles(() => ({
  numberContainer: {
    background: theme.palette.common.white,
    borderLeft: `2px solid ${theme.palette.background.default}`,
    height: "100%",
    overflow: "scroll",
    overflowY: "hidden",
    overflowX: "hidden",
    marginTop: "auto",
    padding: "5%",
  },
  questionContainer: {
    padding: "3%",
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));
const PracticeSet = (props) => {
  const { state } = props.location;
  const classes = useStyle();
  const [showSingleQuestion, setShowSingleQuestion] = useState(false);
  const [singleQuestionData, setSingleQuestionData] = useState({});
  const questionLists = useSelector((state) => state.practiceQuestions);
  const answerSubmittedQues = useSelector((state) => state.submittedAnswer);
  const [limitSize, setLimitSize] = useState(21);
  const [allQuestion, setAllQuestions] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const onLoadMore = () => {
    setLimitSize(limitSize + 12);
  };
  const { isLoading, error, data } = questionLists;
  const dispatch = useDispatch();

  useEffect(() => {
    store.remove("QueryArray");
    dispatch(fetchQuestionsList(state));
    return () => {
      dispatch(clearErrorQuestions());
    };
  }, [state]);

  // load data on component mount
  useEffect(() => {
    setAllQuestions(data);
  }, [data]);

  // view single question controller
  const handleCardClick = (questionDetails) => {
    setShowSingleQuestion(true);
    setSingleQuestionData(questionDetails);
    window.scrollTo(0, 0);
    dispatch(clearErrorSubmit());
    dispatch(clearErrorBookmark());
    dispatch(clearBodyFromStore());
  };

  // to view all test paper
  const handleClickBack = () => {
    dispatch(fetchQuestionsList(state));
    setShowSingleQuestion(false);
    setLimitSize(21);
    setSingleQuestionData({});
    dispatch(clearBodyFromStore());
  };

  // view next question
  const handleNextQuestion = (question) => {
    let index = data.findIndex((item) => item === question);
    const newIndex = (index + 1) % data.length;
    setSingleQuestionData(data[newIndex]);
    if (answerSubmittedQues.data.status) dispatch(fetchQuestionsList(state));
    dispatch(clearErrorSubmit());
    dispatch(clearErrorBookmark());
  };

  // dropdown filter controller
  const getList = (val, name) => {
    setShowSingleQuestion(false);
    const queryArray = store.get("QueryArray") || [];
    if (val.length === 0) {
      setAllQuestions(data);
    }
    val.forEach((item) => queryArray.push(item));

    for (let item of queryArray) {
      let index = queryArray.indexOf(item);
      if (!val.includes(item) && item in name) {
        queryArray.splice(index, 1);
      }
    }
    store.set("QueryArray", [...new Set(queryArray)]);
    const query = store.get("QueryArray");

    const filteredResult = filterQuestion(data, query);
    setAllQuestions(filteredResult);
  };

  // to display question numbers for easy navigation
  const QuestionNumberPanel = (isLoading, error, allQuestion, data) => (
    <div>
      {!isLoading && !error && allQuestion.length > 0 ? (
        <React.Fragment>
          <Grid
            container
            direction="column"
            className={classes.numberContainer}
          >
            <Grid item>
              <Typography variant="subtitle1">Navigate Questions:</Typography>
              <br />
            </Grid>
            <Grid item container>
              {data.map((question) => {
                let background;
                if (question.status === true) {
                  background = {
                    background: `${theme.palette.primary.main}`,
                  };
                }
                if (question.status === false) {
                  background = {
                    background: `${theme.palette.error.main}`,
                  };
                }
                if (
                  question.status === false &&
                  !question.markedAns.replace(/\s/g, "").length
                ) {
                  background = {
                    background: `${theme.palette.text.secondary}`,
                  };
                }
                if (question.questionNo === singleQuestionData.questionNo) {
                  background = {
                    background: `${theme.palette.background.paper}`,
                  };
                }
                return (
                  <QuestionNumberPad
                    key={question.qId}
                    numbers={question.questionNo}
                    background={background}
                    qNo={allQuestion.map((item) => item.questionNo)}
                    selectedQuestion={singleQuestionData.questionNo}
                    onClick={() => {
                      handleCardClick(question);
                      if (answerSubmittedQues.data.status) {
                        dispatch(fetchQuestionsList(state));
                        store.remove(question.qId);
                      }
                    }}
                  />
                );
              })}
            </Grid>
          </Grid>
        </React.Fragment>
      ) : null}
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <HeaderPracticeSet body={state} handleDrawerToggle={handleDrawerToggle} />
      <WrapperCard>
        <Container fixed>
          <Grid container justify="center" alignItems="center">
            <MultiSelectDropDown
              name="Attempt Status"
              listItem={AttemptStatus}
              sendList={getList}
            />
            <MultiSelectDropDown
              name="Question Type"
              listItem={QuestionType}
              sendList={getList}
            />
          </Grid>
        </Container>
      </WrapperCard>
      <Container fixed disableGutters>
        <Grid container direction="row">
          <Grid item sm={8} xs={12} container>
            {!isLoading ? (
              error ? (
                <Typography color="error" align="center" variant="h3">
                  Something broke!
                </Typography>
              ) : allQuestion.length > 0 ? (
                showSingleQuestion ? (
                  // navigate to single question
                  <DisplaySingleQuestion
                    question={singleQuestionData}
                    handleClickBack={handleClickBack}
                    handleNextQuestion={() => {
                      handleNextQuestion(singleQuestionData);
                    }}
                    dataLength={allQuestion.length}
                  />
                ) : (
                  // cards with question summary
                  <React.Fragment>
                    <Grid
                      container
                      direction="row"
                      className={classes.questionContainer}
                    >
                      {allQuestion &&
                        allQuestion.slice(0, limitSize).map((data) => {
                          let border, status, statusColor;
                          if (data.status === true) {
                            border = `2px solid ${theme.palette.primary.main}`;
                            status = "Correct";
                            statusColor = {
                              color: `${theme.palette.primary.main}`,
                            };
                          }
                          if (data.status === false) {
                            border = `2px solid ${theme.palette.error.main}`;
                            status = "Incorrect";
                            statusColor = {
                              color: `${theme.palette.error.main}`,
                            };
                          }
                          if (
                            data.status === false &&
                            !data.markedAns.replace(/\s/g, "").length
                          ) {
                            border = `2px solid ${
                              theme.palette.text.secondary
                            }`;
                            status = "Skipped";
                            statusColor = {
                              color: `${theme.palette.text.secondary}`,
                            };
                          }
                          return (
                            <Grid item md={4} sm={6} xs={12} key={data.qId}>
                              <QuestionComponent
                                border={border}
                                qType={capitalize(data.questionType)}
                                questionNo={data.questionNo}
                                questionBody={data.q}
                                status={status}
                                statusColor={statusColor}
                                quesData={data}
                                onClick={() => {
                                  handleCardClick(data);
                                }}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                    {/* load more question on button click  */}
                    <Box
                      width="100vw"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mb={8}
                    >
                      {allQuestion.length > 20 && (
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={onLoadMore}
                        >
                          <AutorenewIcon fontSize="inherit" /> Load more
                        </Button>
                      )}
                    </Box>
                  </React.Fragment>
                )
              ) : (
                <ComingSoonPlans plans="Questions not available!" />
              )
            ) : (
              <NetworkProgress />
            )}
          </Grid>
          {/* side panel containing question numbers for navigation (desktop and tabs) */}
          <Hidden xsDown>
            <Grid item sm={4}>
              {QuestionNumberPanel(isLoading, error, allQuestion, data)}
            </Grid>
          </Hidden>
        </Grid>
      </Container>
      {/* mobile view question numbers panel  */}
      <Hidden smUp>
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {QuestionNumberPanel(isLoading, error, allQuestion, data)}
        </Drawer>
      </Hidden>
      {/* ----------------------------------------------------------------- */}
    </ThemeProvider>
  );
};

export default PracticeSet;
