import { createTheme } from "@material-ui/core";
// colors
import colors from "../base/colors";
// typography
import typography from "../base/typography";
// shadows
import boxShadows from "../base/boxShadows";
// radius
import borders from "../base/borders";
// components
import inputBase from "./components/inputBase";
import outlinedInput from "./components/outlinedInput";

const theme = createTheme({
  palette: colors,
  typography: typography,
  boxShadows: boxShadows,
  borders: { ...borders },
  overrides: {
    MuiInputBase: { ...inputBase },
    MuiOutlinedInput: { ...outlinedInput },
  }
});

export default theme;