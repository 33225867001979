import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  Checkbox,
  TextField,
  Grid,
  TableBody,
} from "@material-ui/core";
import {
  CustomTableRow,
  CustomTableCellType3,
} from "../../components/CustomTableCells";
import { useStyles } from "../../Dashboard/Components/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useStyles4, Transition } from "../styles";
import RenderTableHeader from "./RenderTableHeader";

const RenderTable = ({
  modifiedObject,
  handleBalanceChangeFirstTable,
  selectedFeeTypesFirstTable,
  config,
  nameFirstTable,
  handleFeeTypeCheckboxChangeFirstTable,
}) => {
  const classes = useStyles();
  const classes1 = useStyles4();
  return (
    <Table className={classes.customTable}>
      <TableHead>
        <RenderTableHeader modifiedObject={modifiedObject} />
      </TableHead>
      <TableBody>
        {Object.entries(modifiedObject).map(
          ([installmentKey, installmentData], installmentIndex) => (
            <>
              {Object.entries(installmentData).map(([feeType, feeData]) => {
                const isOddRow = installmentIndex % 2 !== 0;
                const rowStyle = {
                  backgroundColor: isOddRow ? "#f2f2f2" : "transparent",
                };
                const checkboxKey = `${installmentKey}-${feeType}`;
                const feeTypeInstallment = `${installmentKey}:${feeType}`;
                const isTotalFeeType = feeType === "Total";
                return (
                  <CustomTableRow
                    key={`${installmentKey}_${feeType}`}
                    className={classes.tr}
                    // style={rowStyle}
                  >
                    {Object.keys(installmentData).indexOf(feeType) === 0 && (
                      <CustomTableCellType3
                        rowSpan={Object.keys(installmentData).length}
                      >
                        {installmentKey}
                      </CustomTableCellType3>
                    )}
                    <CustomTableCellType3>
                      {isTotalFeeType ? (
                        <b> {feeType.replace("_", " ").toUpperCase()}</b>
                      ) : (
                        feeType.replace("_", " ").toUpperCase()
                      )}
                    </CustomTableCellType3>
                    <CustomTableCellType3>
                      {isTotalFeeType ? (
                        <b>{feeData.amount.toLocaleString("en-IN")}</b>
                      ) : (
                        feeData.amount.toLocaleString("en-IN")
                      )}
                    </CustomTableCellType3>
                    <CustomTableCellType3>
                      {isTotalFeeType ? (
                        <b> {feeData.balance.toLocaleString("en-IN")}</b>
                      ) : (
                        feeData.balance.toLocaleString("en-IN")
                      )}
                    </CustomTableCellType3>
                    <CustomTableCellType3
                      style={{
                        color: isTotalFeeType ? "#003366" : null,
                      }}
                    >
                      {isTotalFeeType ? (
                        <b> {feeData.paid.toLocaleString("en-IN")}</b>
                      ) : (
                        feeData.paid.toLocaleString("en-IN")
                      )}
                    </CustomTableCellType3>
                    {isTotalFeeType ? null : (
                      <CustomTableCellType3>
                        <Checkbox
                          disabled={feeData.balance === 0}
                          defaultChecked={feeData.balance === 0}
                          style={{
                            color:
                              feeData.balance === 0
                                ? "rgb(8, 197, 167)"
                                : "#197BBD",
                          }}
                          checked={
                            selectedFeeTypesFirstTable.includes(
                              feeTypeInstallment
                            ) || feeData.balance === 0
                          }
                          onChange={() =>
                            handleFeeTypeCheckboxChangeFirstTable(
                              installmentKey,
                              feeType
                            )
                          }
                        />
                        {selectedFeeTypesFirstTable.includes(
                          feeTypeInstallment
                        ) && (
                          <Grid xs={12} sm={6} className={classes1.root}>
                            <TextField
                              id="outlined-name"
                              label="Amount"
                              size="small"
                              type="number"
                              disabled={!config.parentAccessToEnterAmount}
                              value={
                                nameFirstTable[feeTypeInstallment] ||
                                feeData.balance
                              }
                              onChange={(event) =>
                                handleBalanceChangeFirstTable(
                                  installmentKey,
                                  feeType,
                                  event.target.value
                                )
                              }
                              variant="outlined"
                            />
                          </Grid>
                        )}
                      </CustomTableCellType3>
                    )}
                  </CustomTableRow>
                );
              })}
            </>
          )
        )}
      </TableBody>
    </Table>
  );
};
export default RenderTable;
