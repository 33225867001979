import React, { useEffect, useState, useMemo } from "react";
import store from "store";
import {
  Box,
  Typography,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Container,
} from "@material-ui/core";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import theme from "../../assets/v3/theme";
import { getRegistrationFormConfig } from "../../apis/UserService";
import useFetch from "../../hooks/useFetch";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "../../context/snackbar";
import {
  StudentDetailsFields,
  ParentDetailsFields,
  PreviousSchoolDetails,
  AddressFieldDetails,
} from "./utils";
import moment from "moment";
// components
import StyledBox from "../../components/StyledBox";
// assets
import colors from "../../assets/v3/base/colors";
import { PageTitle } from ".";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.default,
  },
  title: {
    marginBottom: theme.spacing(2),
    textTransform: "none",
    textAlign: "left",
    fontSize: "1.2rem",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  key: {
    width: "48%",
    color: "#737373",
    flexShrink: 0,
    textTransform: "none",
    fontWeight: 400,
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  value: {
    flexGrow: 1,
    wordBreak: "break-all",
    textTransform: "none",
    color: "#171717",
    fontWeight: 600,
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  colon: {
    color: theme.palette.grey[600],
    flexShrink: 0,
  },
  heading: {
    textAlign: "left",
  },
  blueGradientBtn: {
    background: `linear-gradient(310deg, ${colors.gradients.tirumala.main}, ${
      colors.gradients.tirumala.state
    })`,
    color: "white",
  },
  blueGradientBtn2: {
    border: "1px solid transparent",
    background: `
      linear-gradient(to right, white, white), 
      linear-gradient(to right, ${colors.gradients.tirumala.state}, ${
      colors.gradients.tirumala.main
    })
    `,
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    color: colors.gradients.tirumala.state,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  white: {
    backgroundColor: "white",
    borderRadius: theme.spacing(2),
  },
}));

const ReviewPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const instituteId = store.get("user").instituteId;
  const sid = store.get("user")._id;
  const [l1, e1, d1, f1, r1] = useFetch(getRegistrationFormConfig);
  const [details, setDetails] = useState({});
  const [termsChecked, setTermsChecked] = useState(false);

  const fieldMap = useMemo(() => {
    return [
      ...StudentDetailsFields,
      ...ParentDetailsFields,
      ...PreviousSchoolDetails,
      ...AddressFieldDetails,
    ].reduce((map, field) => {
      map[field.field] = field;
      return map;
    }, {});
  }, []);

  const initialValues = useMemo(() => {
    return Object.keys(fieldMap).reduce((values, key) => {
      const myKey = fieldMap[key].key;
      if (myKey && details) {
        values[key] = myKey.includes(".")
          ? details[myKey.split(".")[0]]
            ? details[myKey.split(".")[0]][myKey.split(".")[1]]
            : ""
          : details[myKey];

        if (myKey === "dateOfBirth" && values[key]) {
          values[key] = moment(values[key]).format("YYYY-MM-DD");
        }
      } else {
        values[key] = "";
      }
      return values;
    }, {});
  }, [fieldMap, details]);

  useEffect(() => {
    f1({ instituteId, page: "review", sid });

    return () => {
      r1();
    };
  }, []);

  useEffect(() => {
    if (d1) {
      setDetails(d1.details);
    }
    if (e1) {
      console.error("Failed to fetch registration details:", e1);
      openSnackbar("Failed to fetch registration details", "error");
    }
  }, [d1, e1]);

  const renderDetails = (title, fields) => (
    <StyledBox
      // shadow={"lg"}
      borderRadius="lg"
      marginTop={0.5}
      padding={2}
      bgColor="white"
    >
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {fields.map((field) =>{
          const value = initialValues[field.field] || ""
          if(!value) return null
          return (
          <Grid item xs={12} key={field.label}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body2" component="p" className={classes.key}>
                {field.label}
              </Typography>
              <Typography
                variant="body2"
                className={classes.value}
                color="primary"
              >
                :&nbsp;{value}
              </Typography>
            </Box>
          </Grid>
        )})}
      </Grid>
    </StyledBox>
  );

  const handleNext = () => {
    if (termsChecked) {
      openSnackbar("Profile has been saved successfully", "success", false);
      history.push("/reg-dashboard");
    } else {
      openSnackbar("Please accept the terms and conditions", "error");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <PageTitle title="Preview" />
      <Box className={classes.mainContainer} minHeight="100vh">
        <Container maxWidth="md">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              {renderDetails("Student Details", StudentDetailsFields)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDetails("Parent Details", ParentDetailsFields)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDetails("Previous School Details", PreviousSchoolDetails)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDetails("Address Details", AddressFieldDetails)}
            </Grid>
          </Grid>

          <Box mt={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => history.push("/avatar")}
              size="small"
              className={classes.blueGradientBtn2}
            >
              Previous
            </Button>
          </Box>
          <Box mt={2} display="flex" justifyContent="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsChecked}
                  onChange={(e) => setTermsChecked(e.target.checked)}
                  color="info"
                />
              }
              label={
                <Typography variant="caption">
                  I accept the{" "}
                  <Link href="#" target="_blank" color="error">
                    Terms & Conditions
                  </Link>
                </Typography>
              }
            />
            <Button
              variant="contained"
              onClick={handleNext}
              className={classes.blueGradientBtn}
            >
              Submit
            </Button>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default ReviewPage;
