import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { Box, Typography, IconButton, useMediaQuery } from "@material-ui/core";
import "../../../index.css";
import Picture from "./gallery";
import { useHorizontalScroll } from "./useHorizontalScroll";
import InlineAnimatedText from "./AnimatedText";
import { ChevronLeft } from "@material-ui/icons";

function SmoothScroll() {
  const scrollRef = useHorizontalScroll();
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div
      className="App"
      ref={scrollRef}
      style={{ overflowX: "hidden", overflowY: "auto" }}
    >
      <div style={{ whiteSpace: "nowrap", width: "auto" }}>
        <Box sx={{ padding: 1, marginTop: 12 }}>
          <div>
            <Picture />
          </div>
        </Box>
      </div>
    </div>
  );
}

export default SmoothScroll;
