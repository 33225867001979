import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import "./AdvanceNumbersPanel.css";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

var classNames = require("classnames");
const styles = (theme) => ({
  fab: {
    borderRadius: "0px",
    //  backgroundColor: 'unset',
    width: "40px",
    height: "40px",
    fontSize: "14px",
    fontWeight: "bolder",
    boxShadow: "none",
    //  width: 'max-content',
    backgroundSize: "90% 90% !important",
  },
  btnSubmit: {
    color: "#fff",
    // backgroundColor: '#5cb85c',
    background: "linear-gradient(45deg, #0B80F1 30%,#0B80F1 90%)",
    borderColor: "#4cae4c",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginRight: "3%",
    // marginBottom: theme.spacing(.5),
    // width: 'max-content',
    minWidth: theme.spacing.unit * 13,
    // minHeight: theme.spacing(9),
    padding: "-1% 15%",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  newgridsep: {
    minHeight: theme.spacing.unit * 1,
  },
});

class MarkedStatus extends React.Component {
  a = [];
  statusmap = {
    NOT_VISITED: "adv-not-visd",
    NOT_ANSWERED: "adv-not-ansd",
    ANSWERED: "adv-ansd",
    MARKED_FOR_REVIEW: "adv-mark-reviewd",
    ANSWERED_MARKED_FOR_REVIEW: "adv-ans-marked-review",
  };
  statusdisplay = {
    NOT_VISITED: 0,
    NOT_ANSWERED: 0,
    ANSWERED: 0,
    MARKED_FOR_REVIEW: 0,
    ANSWERED_MARKED_FOR_REVIEW: 0,
  };
  componentDidUpdate(props) {
    if (this.props !== props) {
      this.statusCheck();
    }
  }
  statusCheck() {
    Object.keys(this.statusdisplay).forEach((key) => {
      this.statusdisplay[key] = 0;
    });
    this.props.questions
      .filter((question) => {
        // console.log(this.props.schema.questions[question.question_number],this.props.section, this.props.subject , question.subject)
        return (
          this.props.schema.questions[String(question.question_number)] ===
            this.props.section && this.props.subject === question.subject
        );
      })
      .forEach((question) => {
        this.statusdisplay[question.status] += 1;
      });
    this.setState({ statusdisplay: this.statusdisplay });
  }
  constructor(props) {
    super(props);
    this.a = Array(this.props.questions.length)
      .fill(0)
      .map((v, i) => i + 1);
    this.state = { statusdisplay: this.statusdisplay };
  }
  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem>
          <Button
            size="sm"
            key="a"
            className={classNames(classes.fab, this.statusmap["ANSWERED"])}
          >
            {this.state.statusdisplay.ANSWERED}
          </Button>
          <span>Answered</span>
        </GridItem>

        <GridItem>
          <Button
            size="sm"
            key="n_a"
            className={classNames(classes.fab, this.statusmap["NOT_ANSWERED"])}
          >
            {this.state.statusdisplay.NOT_ANSWERED}
          </Button>
          <span> Not Answered</span>
        </GridItem>
        <GridItem>
          <Button
            size="sm"
            key="a_m_r"
            className={classNames(classes.fab, this.statusmap["NOT_VISITED"])}
          >
            {this.state.statusdisplay.NOT_VISITED}
          </Button>
          <span> Not Visited </span>
        </GridItem>
        <GridItem>
          <Button
            size="sm"
            key="m_r"
            className={classNames(
              classes.fab,
              this.statusmap["MARKED_FOR_REVIEW"]
            )}
          >
            {this.state.statusdisplay.MARKED_FOR_REVIEW}
          </Button>
          <span> Marked for Review</span>
        </GridItem>
        <GridItem>
          <Button
            size="sm"
            key="a_m_r"
            className={classNames(
              classes.fab,
              this.statusmap["ANSWERED_MARKED_FOR_REVIEW"]
            )}
          >
            {this.state.statusdisplay.ANSWERED_MARKED_FOR_REVIEW}
          </Button>
          <span>Answered & Marked for Review </span>
          <span style={{ fontSize: "14px" }}>
            (will consider for evaluation)
          </span>
        </GridItem>
      </GridContainer>
    );
  }
}

MarkedStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(
  withWidth(),
  withStyles(styles)
)(MarkedStatus);
