import { makeStyles } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router-dom";
import CustomQuestionNosDispaly from "./videoclasses/CustomTestAssignment/AssignmentTest";

const useStyles = makeStyles((theme) => ({
  answerIframeDiv: {
    height: "auto",
  },
  answerIframeDivResponsive: {
    height: "220px",
  },
}));

const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000"
    : window.location.origin;

const PDFTest = (props) => {
  const classes = useStyles();
  const agent = navigator.userAgent;
  const { search } = props.location;
  const query = new URLSearchParams(search);
  const displayname = query.get("displayname");
  const test_type = query.get("test_type");
  const user = query.get("user");
  const examDuration = query.get("examDuration");
  const examName = query.get("examName");
  const pdfLink = query.get("pdfLink");
  const dimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  const [isInteger, setIsInteger] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("message", (event) => {
      // identify correctness of message from iframe
      if (event.data === "integer_input") {
        setIsInteger(true);
      } else {
        setIsInteger(false);
      }
    });
    return () => {
      window.removeEventListener("message");
      setIsInteger(false);
    };
  }, []);
  const viewURL = `${baseURL}/pdfExam?displayname=${displayname}&test_type=${test_type}&user=${user}&examDuration=${examDuration}&examName=${examName}&pdfLink=${pdfLink}`;
  return (
    <>
      <CustomQuestionNosDispaly
        displayname={displayname}
        test_type={test_type}
        user={user}
        examDuration={examDuration}
        examName={examName}
        pdfLink={pdfLink}
      />
      {agent.includes("Android") && window.Android ? null : (
        <div
          dangerouslySetInnerHTML={{
            __html: `<!DOCTYPE html>
                    <html>
                        <head>
                        </head>
                        <body>
                            <iframe   src=${encodeURI(pdfLink)} width=${
              dimensions.width
            }  height=${dimensions.height} ></iframe>
                        </body>
                    </html>`,
          }}
        />
      )}
    </>
  );
};

export default withRouter(PDFTest);
