import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "store";
import { ReactComponent as Award } from "../../assets/img/award.svg";
import { ReactComponent as Flag } from "../../assets/img/flag.svg";
import theme from "../../assets/theme";
import AccuracyCard from "./AccuracyCard";
import MarksList from "./MarksList";
import RankCard from "./RankCard";
import subjectList from "./subjects.json";
const useStyles = makeStyles((theme) => ({
  container: {
    gap: "20px",
    // padding: "0 20% 0 20%",
    // width: "100vw",
  },
  "@media(max-width: 1024px)": {
    container: {
      // padding: "0 3% 0 3%",
    },
  },
  innerContent: {
    // padding: "2%",
    width: "100%",
    height: "fit-content",
  },
  iconPosition: {
    verticalAlign: "middle",
  },
}));

function getQualificationStatus(marks = {}, qualifyingMarksT = 50) {
  let totalMarks = 0;
  let hasIndividualQualification = false;

  for (const subject in marks) {
    const mark = marks[subject];
    totalMarks += mark;

    if (mark >= qualifyingMarksT) {
      hasIndividualQualification = true;
    }
  }

  // Check if total score qualifies or any individual subject qualifies
  if (totalMarks >= qualifyingMarksT || hasIndividualQualification) {
    return `Qualified`;
  } else {
    return `Not Qualified`;
  }
}

const ComparisonCard = (props) => {
  const { hostname } = window.location;
  const user = store.get("user");
  let rankAnalysis = useSelector((state) => state.rankAnalysis);

  const subjectsFrmStore = useSelector((state) => state.allResult.subjects);

  const testTotalMarks = useSelector((state) => state.allResult.testTotalMarks);
  // console.log(amap,"hello");
  const papermarks = {
    BCONM_1: "198",
    "2k24_paper_2": "180",
    "2k24_paper_1": "180",
    "2k23_paper_2": "180",
    "2k23_paper_1": "180",
    "2k22_paper_2": "180",
    "2k22_paper_1": "180",
    "2k21_paper_2": "180",
    "2k21_paper_1": "180",
    "2k20_paper_2": "198",
    "2k20_paper_1": "198",
    "2k19_paper_1": "186",
    "2k19_paper_2": "186",
    "2k18_paper_1": "180",
    "2k18_paper_2": "180",
    "2k17_paper_1": "183",
    "2k17_paper_2": "183",
    "2k16_paper_1": "186",
    "2k16_paper_2": "195",
    "2k15_paper_1": "264",
    "2k15_paper_2": "240",
    "2k14_paper_1": "180",
    "2k14_paper_2": "180",
    "2k13_paper_1": "180",
    "2k13_paper_2": "180",
    "2k12_paper_1": "210",
    "2k12_paper_2": "198",
    "2k11_paper_1": "240",
    "2k11_paper_2": "240",
    EAMCET_MPC: "160",
    NEET: "720",
    NEET_2021: "720",
    "JEE MAINS": "300",
    BIOP: "300",
    MAINS: "300",
    CUSTOM: "360",
  };
  const [rankData, setRankData] = useState({});
  const [scoreData, setScoreData] = useState({});
  const [accData, setAccData] = useState({});
  const [subRanks, setSubRanks] = useState({});
  const [status, setStatus] = useState("");
  const [totalMarks, setTotalMarks] = useState(undefined);
  if (props.customQuestionsCount) {
    papermarks["CUSTOM"] = testTotalMarks; //props.customQuestionsCount * 4;
  }
  // console.log(props.customQuestionsCount);
  useEffect(() => {
    // console.log(props, "155");
    papermarks["CUSTOM"] = props.customQuestionsCount * 4;
    if (Object.keys(rankAnalysis.data) != 0) {
      // console.log(props.data);
      const rankData = {
        rank: rankAnalysis.data.ranks.total,
        totalStudents: rankAnalysis.data.totalStudents,
      };
      // getting ranks subject wise
      const subRank = subRanks;
      Object.keys(rankAnalysis.data.ranks).forEach((key) => {
        subjectsFrmStore.forEach((subject) => {
          if (
            rankAnalysis.data.ranks[key] != undefined &&
            key
              .toLowerCase()
              .includes(subject.replace(/\s+/g, "").toLowerCase())
          ) {
            subRank[capitalize(subject)] = rankAnalysis.data.ranks[key];
          }
        });
      });

      // score subject wise:
      const scoreData = {};
      subjectsFrmStore.forEach((subject) => {
        if (
          rankAnalysis.data.YourDetails[subjectList[subject].subjectCode] !=
          undefined
        ) {
          scoreData[capitalize(subject)] =
            rankAnalysis.data.YourDetails[subjectList[subject].subjectCode];
        }
      });
      if (
        window.location.hostname.includes("miity") &&
        (user.level == "X0210780" || user.batch == "X0210780") &&
        props.test_name &&
        props.test_name === "47fa08d0-840b-11ef-b43e-8b0246b9782f"
      ) {
        setStatus(getQualificationStatus(scoreData, 50));
      }
      // T for total
      const accData = {};
      ["YourDetails", "Average", "TopperDetails"].forEach((val) => {
        let correct = 0;
        let total = 0;
        subjectsFrmStore.forEach((subject) => {
          if (
            rankAnalysis.data[val][subjectList[subject].correct] != undefined
          ) {
            correct += rankAnalysis.data[val][subjectList[subject].correct];
            total +=
              rankAnalysis.data[val][subjectList[subject].correct] +
              rankAnalysis.data[val][subjectList[subject].wrong];
          }
        });
        if (total != 0) accData[val] = parseInt((correct / total) * 100);
        else {
          accData[val] = 0;
        }
      });

      setSubRanks(subRank);
      setRankData(rankData);
      setAccData(accData);
      setScoreData(scoreData);
      setTotalMarks(rankAnalysis.data.YourDetails.T);
    }
  }, [props]);
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container className={classes.container}>
        {/* grade card  */}
        <Grid item className={classes.innerContent}>
          {status && (
            <>
              <br />
              &emsp;
              <Typography
                variant="body2"
                color={status === "Qualified" ? "primary" : "error"}
              >
                Status:&nbsp;<b>{status}</b>
              </Typography>
              <br />
            </>
          )}
          <RankCard
            icon={<Award className={classes.iconPosition} />}
            background={"#6a1b9a"}
            cardName="MARKS"
            rank={totalMarks}
            total={
              papermarks[props.paper] !== undefined
                ? papermarks[props.paper]
                : papermarks[props.test_type] != undefined
                ? papermarks[props.test_type]
                : "300"
            }
            subjects={Object.keys(scoreData).map((subKey, i) => {
              return (
                <MarksList
                  subjectName={subKey}
                  key={i}
                  subjectMarks={scoreData[subKey]}
                  MarksColor={{ color: theme.palette.text.primary }}
                />
              );
            })}
          />
        </Grid>
        <Grid item className={classes.innerContent}>
          {rankAnalysis.data.showRank === false ? null : (
            <React.Fragment>
              {/* rank card  */}
              {hostname.includes("bhashyam") &&
              user.level == "2119611" ? null : (
                <RankCard
                  cardName="RANK"
                  icon={<Flag className={classes.iconPosition} />}
                  rank={rankData.rank}
                  total={rankData.totalStudents}
                  background={"#3358B8"}
                  subjects={Object.keys(subRanks).map((subKey, i) => {
                    return (
                      <MarksList
                        key={i}
                        subjectName={subKey}
                        subjectMarks={subRanks[subKey]}
                        MarksColor={{ color: theme.palette.text.primary }}
                      />
                    );
                  })}
                />
              )}
            </React.Fragment>
          )}
        </Grid>
        {(hostname.includes("bhashyam") && user.level == "2119611") ||
        (hostname.includes("miity") && user.level === "X0210780") ? null : (
          <Grid item className={classes.innerContent}>
            <AccuracyCard
              accuracy={accData.YourDetails}
              avg={accData.Average}
              topper={accData.TopperDetails}
            />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default ComparisonCard;
