import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { CustomSelect } from "./CustomSelect";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 130,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const MultiSelectDropDown = ({ name, listItem, sendList }) => {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState([]);

  const handleChange = (event, listItem) => {
    setSelectedItem(event.target.value);
    sendList(event.target.value, listItem);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel color="primary" id="demo-mutiple-checkbox-label">
        {name}
      </InputLabel>
      <CustomSelect
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        value={selectedItem}
        onChange={(event) => handleChange(event, listItem)}
        input={<Input />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {Object.keys(listItem).map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={selectedItem.indexOf(name) > -1} />
            <ListItemText primary={listItem[name]} />
          </MenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  );
};

export default MultiSelectDropDown;
