const optionsMap = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
};
const splitOn = ",";

/**
 *
 * @param {String} options
 * @return
 *  returns the string e.g "A or B" in case of valid else --
 */
function convertOption(options) {
  // console.log(options);
  let result = [];
  if (options === null || options === undefined) return "--";
  options = String(options);
  options.split(splitOn).forEach((op) => {
    if (isNaN(op)) {
      result.push(op.toUpperCase());
    } else {
      const alpha = optionsMap[Number(op)];
      if (alpha) result.push(alpha);
    }
  });
  return result.sort().join(" or ");
}
export default convertOption;
