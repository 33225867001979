import React from "react";
import CustomQuestionNosDispaly from "./videoclasses/CustomTestAssignment/AssignmentTest";

const PDFAnswerArea = (props) => {
  const { search } = props.location;
  const query = new URLSearchParams(search);
  const displayname = query.get("displayname");
  const test_type = query.get("test_type");
  const user = query.get("user");
  const examDuration = query.get("examDuration");
  const examName = query.get("examName");
  const pdfLink = query.get("pdfLink");

  return (
    <CustomQuestionNosDispaly
      displayname={displayname}
      test_type={test_type}
      user={user}
      examDuration={examDuration}
      examName={examName}
      pdfLink={pdfLink}
    />
  );
};

export default PDFAnswerArea;
