// import React from 'react'
// import moment from 'moment'
// import { makeStyles } from '@material-ui/core/styles'
// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
// import TableContainer from '@material-ui/core/TableContainer'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
// import Paper from '@material-ui/core/Paper'
// import Typography from '@material-ui/core/Typography'
// import { green, grey, lightBlue, red, blue } from '@material-ui/core/colors'
// import numberWithCommas from '../../../utils/numberWithCommas'

// const formatAllowanceType = text => {
//   return text
//     .toLowerCase() // Convert to lowercase
//     .split('_') // Split on underscores
//     .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
//     .join(' ') // Join the words with spaces
// }

// const useStyles = makeStyles(theme => ({
//   root: {
//     margin: '20px',
//     // width: '100%'
//     minWidth:"320px"
//   },
//   table: {
//     minWidth: 620,
 
//     fontSize: '0.8rem',
//     [theme.breakpoints.up('sm')]: {
//       fontSize: '0.9rem',
//     },
//   },
//   header: {
//     backgroundColor: lightBlue[700],
//     '& th': {
//       color: theme.palette.common.white,
//       fontWeight: 'bold'
//     }
//   },
//   rowOdd: {
//     backgroundColor: grey[200],
//     '& > *': {
//       lineHeight: 0
//     }
//   },
//   rowEven: {
//     backgroundColor: grey[100],
//     '& > *': {
//       lineHeight: 0
//     }
//   },



//   totals: {
//     display: 'flex',
//     flexDirection: 'column', // Change to 'row' if you want them side by side
//     justifyContent: 'space-between',
//     alignItems: 'flex-start',
//     minWidth:"620px",
//     margin: theme.spacing(2, 0),
//     padding: theme.spacing(1),
//     backgroundColor: lightBlue[50],
//     borderRadius: theme.shape.borderRadius,
//     [theme.breakpoints.up('sm')]: {
//       flexDirection: 'row', // On larger screens, display as row
//     },
//   },
//   totalCreditItem: {
//     margin: theme.spacing(1, 0),
//     fontWeight: 'bold',
//     color: green[700],
//     [theme.breakpoints.up('sm')]: {
//       margin: theme.spacing(1),
//     },
//   },
//   totalItem: {
//     margin: theme.spacing(1, 0),
//     fontWeight: 'bold',
//     color: blue[700],
//     [theme.breakpoints.up('sm')]: {
//       margin: theme.spacing(1),
//     },
//   },
//   totalDebitItem: {
//     fontWeight: 'bold',
//     color: red[700],
//     margin: theme.spacing(1, 0),
//     [theme.breakpoints.up('sm')]: {
//       margin: theme.spacing(1),
//     },
//   },
// }))

// const SimpleTable = ({ rows, balance }) => {
//   const classes = useStyles()
//   const totals = rows.reduce(
//     (acc, row) => {
//       if (row.entryType === 'debit') {
//         acc.debitTotal += row.amount
//       } else if (row.entryType === 'credit') {
//         acc.creditTotal += row.amount
//       }
//       return acc
//     },
//     { debitTotal: 0, creditTotal: 0 }
//   )

//   return (
//     <div className={classes.root}>
//     <TableContainer component={Paper} style={{boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px"}}>
//       <div className={classes.totals}>
//         <Typography variant='h6' className={classes.totalItem}>
//           Balance: {numberWithCommas(balance)}
//         </Typography>

//         <Typography variant='h6' className={classes.totalCreditItem}>
//           Total Credit: {numberWithCommas(totals.creditTotal)}
//         </Typography>
//         <Typography variant='h6' className={classes.totalDebitItem}>
//           Total Debit: {numberWithCommas(totals.debitTotal)}
//         </Typography>
//       </div>
//       <Table className={classes.table} aria-label='simple table'>
//         <TableHead className={classes.header}>
//           <TableRow>
//             <TableCell align='center'>S.No</TableCell>
//             <TableCell align='left'>Date</TableCell>

//             <TableCell align='left'>Entry Type</TableCell>
//             <TableCell align='left'>Description</TableCell>
//             <TableCell align='right'>Amount</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {rows.map((row, index) => (
//             <TableRow
//               key={row._id}
//               className={index % 2 === 0 ? classes.rowEven : classes.rowOdd}
//               style={{ height: '20px' }} // Set row height
//             >
//               <TableCell align='center'>{index + 1}</TableCell>
//               <TableCell align='left'>
//                 {moment(row.receiptDate).format('DD/MM/YYYY hh:mm')}
//               </TableCell>

//               <TableCell align='left'>{row.entryType}</TableCell>
//               <TableCell align='left'>
//                 {row.allowanceType
//                   ? formatAllowanceType(row.allowanceType)
//                   : Object.keys(row.fees)
//                       .map(fee => formatAllowanceType(fee))
//                       .join(', ')}
//               </TableCell>
//               <TableCell align='right'>
//                 {numberWithCommas(row.amount)}
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//     </div>
//   )
// }

// export default SimpleTable
import React, { useState,useEffect } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { green, grey, lightBlue, red, blue } from '@material-ui/core/colors'
import TextField from '@material-ui/core/TextField'
import numberWithCommas from '../../../utils/numberWithCommas'
import { set } from 'date-fns'
import { Box } from '@material-ui/core'

const formatAllowanceType = text => {
  return text
    .toLowerCase() // Convert to lowercase
    .split('_') // Split on underscores
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' ') // Join the words with spaces
}

const useStyles = makeStyles(theme => ({
  root: {
    margin: '20px',
    minWidth: "320px"
  },
  table: {
    minWidth: 620,
    fontSize: '0.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.9rem',
    },
  },
  header: {
    backgroundColor: lightBlue[700],
    '& th': {
      color: theme.palette.common.white,
      fontWeight: 'bold'
    }
  },
  rowOdd: {
    backgroundColor: grey[200],
    '& > *': {
      lineHeight: 0
    }
  },
  rowEven: {
    backgroundColor: grey[100],
    '& > *': {
      lineHeight: 0
    }
  },
  totals: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minWidth: "620px",
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1),
    backgroundColor: lightBlue[50],
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  totalCreditItem: {
    margin: theme.spacing(1, 0),
    fontWeight: 'bold',
    color: green[700],
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(1),
    },
  },
  totalItem: {
    margin: theme.spacing(1, 0),
    fontWeight: 'bold',
    color: blue[700],
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(1),
    },
  },
  totalDebitItem: {
    fontWeight: 'bold',
    color: red[700],
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(1),
    },
  },
  searchBox: {
    marginBottom: theme.spacing(2),
  },
}))

const SimpleTable = ({ rows, balance }) => {
  const classes = useStyles()

  // State for search input
  const [searchQuery, setSearchQuery] = useState('')
const [finalRows, setFinalRows] = useState(rows)
  // Function to handle search input changes
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
  }
  useEffect(() => { 
    if (!rows || rows.length === 0) return;
  
    console.log(rows);
  
    const filteredRows = rows.filter((row) => {
      // Safely format allowanceType
      let allowanceType = '';
      if (row.allowanceType) {
        allowanceType = formatAllowanceType(row.allowanceType);
      } else if (row.fees && Object.keys(row.fees).length > 0) {
        allowanceType = Object.keys(row.fees)
          .map(fee => formatAllowanceType(fee))
          .join(', ');
      }
  
      // Check each field safely to avoid errors
      const searchQueryLower = searchQuery.toLowerCase();
  
      const hasMatchingAllowanceType = allowanceType.toLowerCase().includes(searchQueryLower);
      const hasMatchingEntryType = (row.entryType || '').toLowerCase().includes(searchQueryLower);
      const hasMatchingDescription = (row.description || '').toLowerCase().includes(searchQueryLower);
      const hasMatchingReceiptDate = moment(row.receiptDate).format('DD/MM/YYYY').includes(searchQuery);
      const hasMatchingAmount = (row.amount || '').toString().includes(searchQuery);
  
      return (
        hasMatchingAllowanceType ||
        hasMatchingEntryType ||
        hasMatchingDescription ||
        hasMatchingReceiptDate ||
        hasMatchingAmount
      );
    });
  
    setFinalRows(filteredRows);
  }, [searchQuery, rows]);
  
  // Filter rows based on search query
  const totals = finalRows.reduce(
    (acc, row) => {
      if (row.entryType === 'debit') {
        acc.debitTotal += row.amount
      } else if (row.entryType === 'credit') {
        acc.creditTotal += row.amount
      }
      return acc
    },
    { debitTotal: 0, creditTotal: 0 }
  )
  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center" width={300}>
      <TextField
        className={classes.searchBox}
        label="Search"
        variant="outlined"
        size="small"
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
      />
      </Box>
      <TableContainer component={Paper} style={{ boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px" }}>
        <div className={classes.totals}>
          <Typography variant='h6' className={classes.totalItem}>
            Balance: {balance&&numberWithCommas(balance)}
          </Typography>
          <Typography variant='h6' className={classes.totalCreditItem}>
            Total Credit: {totals&&numberWithCommas(totals.creditTotal)}
          </Typography>
          <Typography variant='h6' className={classes.totalDebitItem}>
            Total Debit: {totals&&numberWithCommas(totals.debitTotal)}
          </Typography>
        </div>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell align='center'>S.No</TableCell>
              <TableCell align='left'>Date</TableCell>
              <TableCell align='left'>Entry Type</TableCell>
              <TableCell align='left'>Description</TableCell>
              <TableCell align='right'>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finalRows.map((row, index) => (
              <TableRow
                key={row._id}
                className={index % 2 === 0 ? classes.rowEven : classes.rowOdd}
                style={{ height: '20px' }}
              >
                <TableCell align='center'>{index + 1}</TableCell>
                <TableCell align='left'>
                  {moment(row.receiptDate).format('DD/MM/YYYY hh:mm')}
                </TableCell>
                <TableCell align='left'>{row.entryType}</TableCell>
                <TableCell align='left'>
                  {row.allowanceType
                    ? formatAllowanceType(row.allowanceType)
                    : Object.keys(row.fees)
                        .map(fee => formatAllowanceType(fee))
                        .join(', ')}
                </TableCell>
                <TableCell align='right'>
                  {row&&numberWithCommas(row.amount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default SimpleTable
