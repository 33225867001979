import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import login from "assets/v2/login.png";
import onesaz from "assets/v2/onesaz.png";
import React, { Fragment, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import store from "store";
import AlertComponent from "../AlertComponent";
import { logoutUser } from "../../apis/UserService";
import clearLocalStorage from "../../common/clearStore";

const styles = (theme) => ({
  landingFooter: {
    background: "#FAFAFA",
    height: "200px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "20px",
    },
  },
  footerSeperator: {
    minHeight: "2px",
    background: "#EFF2F4",
    borderRadius: "5px",
    margin: "0px 20px",
  },
  footerTop: {
    display: "flex",
    minHeight: "100px",
    height: "100px",
  },
  footerBottom: {
    height: "100px",
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
  },
  copyRight: {
    color: "rgba(49, 69, 106, 0.7)",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "180%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "20px",
      marginTop: "40px",
    },
  },
  ftop: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: "rgba(49, 69, 106, 0.7)",
    textTransform: "initial",
  },
});

function Footer(props) {
  const history = useHistory();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [alertText, setAlertText] = useState("");

  const { classes } = props;
  const handleCloseAlertBox = () => {
    setConfirmOpen(false);
    setAlertText("");
  };
  const logout = async () => {
    const exam = store.get("startedExam");
    if (exam && exam.test_status === "STARTED") {
      setConfirmOpen(true);
      setAlertText(
        `Please, Submit Test : ${exam.testDisplayName} Before Logging out!`
      );
    } else {
      await logoutUser({ id: store.get("user") ? store.get("user")._id : "" });
      clearLocalStorage();
      history.push("/sign-in");
    }
  };
  return (
    <Fragment>
      <Grid
        container
        className={classes.landingFooter}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={11} sm={8} className={classes.footerTop}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={7}>
              <Link to="/">
                <img
                  alt="logo"
                  style={{ width: 150, height: 40 }}
                  src={onesaz}
                />
              </Link>
            </Grid>
            {/*<Grid item xs>
                            <Button variant="none">
                                <Link to="/" style={{ textDecoration: 'none' }} className={classes.ftop}>
                                    FAQ
                                </Link>
                            </Button>
                            </Grid>
                        
                        <Grid item xs>
                            <Button>
                                <Link to="/" style={{ textDecoration: 'none' }}  className={classes.ftop}>
                                    Product
                                </Link>
                            </Button>
                            </Grid>
                            <Grid item xs>
                            <Button>
                                <Link to="/" style={{ textDecoration: 'none' }}  className={classes.ftop}>
                                    Contact
                                </Link>
                            </Button>
                            </Grid> */}
            {store.get("user") === undefined ? (
              <Grid item xs={5}>
                <Link to="/sign-in">
                  <Button
                    variant="none"
                    style={{ textTransform: "none" }}
                    className={classes.ftop}
                  >
                    <span>
                      <img alt="login" src={login} /> Log In
                    </span>
                  </Button>{" "}
                </Link>
              </Grid>
            ) : (
              <Button
                onClick={logout}
                variant="none"
                style={{ textTransform: "none" }}
                className={classes.ftop}
              >
                <span>Log out</span>
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={10} md={8} className={classes.footerSeperator} />
        <Grid item xs={12} sm={10} md={8} className={classes.footerBottom}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} sm={6}>
              <div className={classes.copyRight}>
                Made in 🇮🇳 for India. &copy; 2020 Onesaz. All rights reserved.
                <br />
              </div>
            </Grid>
            {/* <Grid item xs={3} sm>
                        <img src={twitter}/>
                    </Grid>
                    <Grid item xs={3} sm>
                    <img src={facebook}/>
                    </Grid>
                    <Grid item xs={3} sm>
                    <img src={youtube}/>
                    </Grid> */}
          </Grid>
        </Grid>
        <AlertComponent open={confirmOpen} closeAlert={handleCloseAlertBox}>
          {alertText}
        </AlertComponent>
      </Grid>
    </Fragment>
  );
}

export default withStyles(styles)(Footer);
