import * as actionType from "../../actions/practicePageActions/actions";

// bookmark question
const INITIAL_BOOKMARK_STATE = {
  isLoading: false,
  data: {},
  error: null,
};
export const BookmarkQuestionReducer = (
  bookmarked = INITIAL_BOOKMARK_STATE,
  action
) => {
  switch (action.type) {
    case actionType.BOOKMARK_QUESTION_LOADING:
      return {
        ...bookmarked,
        isLoading: true,
      };
    case actionType.BOOKMARK_QUESTION_SUCCESS:
      return {
        ...bookmarked,
        isLoading: false,
        data: action.payload,
      };
    case actionType.CATCH_ERROR_BOOKMARK:
      return {
        ...bookmarked,
        isLoading: false,
        error: action.payload,
      };
    case actionType.CLEAR_ERROR_BOOKMARK:
      return {
        ...bookmarked,
        isLoading: false,
        error: null,
        data: {},
      };
    default:
      return bookmarked;
  }
};
