import * as actionType from "../../actions/editProfileAction/actions";

const INITIAL_STATE = {
  isLoading: false,
  data: {},
  error: null,
};
export const EditProfileReducer = (editProfile = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.EDIT_PROFILE_LOADING:
      return {
        ...editProfile,
        isLoading: true,
      };
    case actionType.EDIT_PROFILE_SUCCESS:
      return {
        ...editProfile,
        isLoading: false,
        data: action.payload,
      };
    case actionType.CATCH_ERROR_EDIT_PROFILE:
      return {
        ...editProfile,
        isLoading: false,
        error: action.payload,
      };
    case actionType.CLEAR_ERROR_EDIT_PROFILE:
      return INITIAL_STATE;
    default:
      return editProfile;
  }
};
