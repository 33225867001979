import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
// import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import examAxios from "../apis/examServerAxios";
import convertOption from "../common/optionConverter";
import GridContainer from "../components/Grid/GridContainer.jsx";
import Header from "./Header";
import NetworkProgress from "./NetworkProgress";

const styles = (theme) => ({
  heading: {
    textAlign: "center",
    fontWeight: 900,
    lineHeight: 1.1,
  },
  scoreCard: {
    clipPath: "circle(35% at 50% 50%)",
    padding: "2%",
    background: "#54bdd5",
    color: "white",
  },
  appBar: {
    color: "white",
    background: "#212121",
    padding: theme.spacing.unit * 1,
  },
  root: {
    flexGrow: 2,
  },
  profile: {
    flexGrow: 1,
  },
  box: {
    // minHeight: theme.spacing(3),
    marginTop: theme.spacing.unit * 1,
    padding: theme.spacing.unit * 1,
  },
  tablelines: {
    color: "#555",
    border: "1px solid #ddd",
    borderCollapse: "collapse",
    padding: "10px",
  },
  tablelinesWithCenter: {
    background: "#54bdd5",
    color: "white",
    border: "1px solid #ddd",
    borderCollapse: "collapse",
    padding: "10px",
    textAlign: "center",
  },
  right: {
    background: "#5cb85c",
    padding: "2% 3%",
    color: "white",
    fontWeight: "bold",
    fontSize: "75%",
    borderRadius: ".25em",
  },
  wrong: {
    background: "#d9534f",
    padding: "2% 3%",
    color: "white",
    fontWeight: "bold",
    fontSize: "75%",
    borderRadius: ".25em",
  },
});
function scoremapFunc() {
  return {
    totalQuestions: 0,
    totalAttempted: 0,
    incorrectAnswers: 0,
    correctAnswers: 0,
  };
}
class Results extends React.Component {
  loaded = false;
  questions = [];
  scoremap = {
    totalQuestions: 0,
    totalAttempted: 0,
    incorrectAnswers: 0,
    correctAnswers: 0,
  };
  optionsmap = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
  };
  marks = 0;
  alphaMap = { 1: "A", 2: "B", 3: "C", 4: "D" };
  constructor(props) {
    super(props);
    this.props = props;
    this.subjectWiseAnalysis = {
      PHYSICS: { ...this.scoremap },
      CHEMISTRY: { ...this.scoremap },
      MATHEMATICS: { ...this.scoremap },
    };
  }
  componentDidMount() {
    const { history } = this.props;
    if (!this.props.location.state) {
      history.push("/home");
      return;
    }
    // console.log(this.props.location.state)
    const body = JSON.stringify({
      student_id: store.get("user")._id,
      test_name: this.props.location.state.test_name,
    });
    Object.keys(this.scoremap).forEach((key) => {
      this.scoremap[key] = 0;
    });
    examAxios
      .post("/exams/results", body)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          this.questions = res.data.payload;
          this.keyAvailable = true;
          this.scoremap.totalQuestions = this.questions.length;
          this.questions = this.questions.map((question) => {
            question.correct_answer = convertOption(question.correct_answer);
            //   console.log(question.markedAnswer,question.markedAnswer!=="NA")
            if (!this.subjectWiseAnalysis[question.question_details.subject]) {
              this.subjectWiseAnalysis[
                question.question_details.subject
              ] = scoremapFunc();
            }
            this.subjectWiseAnalysis[
              question.question_details.subject
            ].totalQuestions += 1;
            if (question.markedAnswer && question.markedAnswer !== "NA") {
              this.scoremap.totalAttempted += 1;
              this.subjectWiseAnalysis[
                question.question_details.subject
              ].totalAttempted += 1;
              if (question.correct_answer.includes(question.markedAnswer)) {
                this.scoremap.correctAnswers += 1;
                this.subjectWiseAnalysis[
                  question.question_details.subject
                ].correctAnswers += 1;
                question.decision = "CORRECT";
              } else {
                this.scoremap.incorrectAnswers += 1;
                this.subjectWiseAnalysis[
                  question.question_details.subject
                ].incorrectAnswers += 1;
                question.decision = "WRONG";
              }
            }
            return question;
          });
          this.marks =
            4 * this.scoremap.correctAnswers - this.scoremap.incorrectAnswers;
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        console.log("***");
        this.loaded = true;
        this.forceUpdate();
      });
  }
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Header />
        <br />
        {this.loaded && this.keyAvailable && (
          <GridContainer
            direction="row"
            justify="center"
            alignItems="center"
            style={{ background: "white" }}
          >
            {/* <Grid container justify="space-around"> */}
            <GridItem xs={12} style={{ textAlign: "-webkit-center" }}>
              {/* <Box className={classes.box}> */}
              <Typography variant="h5" className={classes.heading}>
                Result of {this.props.location.state.test_name}
              </Typography>
              <br />
              <Typography component="h6" className={classes.heading}>
                <Typography variant="span" style={{ fontSize: "24px" }}>
                  Total:
                </Typography>{" "}
                <br /> <br /> <br />
                <span component="span" className={classes.scoreCard}>
                  {this.marks} / {this.questions.length * 4}{" "}
                </span>
              </Typography>
              <br />
              <br />
              <Typography variant="h6" className={classes.heading}>
                {Object.keys(this.subjectWiseAnalysis).map((subjectname) => (
                  <span className={classes.heading}>
                    {subjectname}:{" "}
                    {4 * this.subjectWiseAnalysis[subjectname].correctAnswers -
                      this.subjectWiseAnalysis[subjectname].incorrectAnswers}
                    &nbsp; &nbsp; &nbsp;
                  </span>
                ))}
              </Typography>
              <br />
              {/* </Box> */}
            </GridItem>
            <GridItem xs={12} style={{ textAlign: "-webkit-center" }}>
              {/* <GridContainer className={classes.box} style={{ textAlign: '-webkit-center' }}> */}
              <table className={classes.tablelines}>
                <tbody className={classes.tablelines}>
                  <tr className={classes.tablelines}>
                    <th className={classes.tablelinesWithCenter}>Subject</th>
                    <th className={classes.tablelinesWithCenter}>
                      Total Questions
                    </th>
                    <th className={classes.tablelinesWithCenter}>
                      {" "}
                      Correct Answers{" "}
                    </th>
                    <th className={classes.tablelinesWithCenter}>
                      {" "}
                      Incorrect Answers
                    </th>
                    <th className={classes.tablelinesWithCenter}>
                      {" "}
                      Un Attempted
                    </th>
                  </tr>
                  {Object.entries(this.subjectWiseAnalysis).map((key) => {
                    return (
                      <tr>
                        <td className={classes.tablelines}>{key[0]}</td>
                        <td className={classes.tablelines}>
                          {key[1].totalQuestions}
                        </td>
                        <td className={classes.tablelines}>
                          {key[1].correctAnswers}
                        </td>
                        <td className={classes.tablelines}>
                          {key[1].incorrectAnswers}
                        </td>
                        <td className={classes.tablelines}>
                          {key[1].totalQuestions - key[1].totalAttempted}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td className={classes.tablelinesWithCenter}> Total </td>
                    <td className={classes.tablelines}>
                      {this.scoremap.totalQuestions}{" "}
                    </td>
                    <td className={classes.tablelines}>
                      {" "}
                      {this.scoremap.correctAnswers}{" "}
                    </td>
                    <td className={classes.tablelines}>
                      {" "}
                      {this.scoremap.incorrectAnswers}{" "}
                    </td>
                    <td className={classes.tablelines}>
                      {" "}
                      {this.scoremap.totalQuestions -
                        this.scoremap.totalAttempted}{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* </GridContainer> */}
            </GridItem>
            <GridItem xs={12} style={{ textAlign: "-webkit-center" }}>
              <br />
              {/* <GridContainer className={classes.box} style={{ textAlign: '-webkit-center' }}> */}
              <table className={classes.tablelines}>
                <thead className={classes.tablelinesWithCenter}>
                  <tr className={classes.tabltablelinesWithCenterelines}>
                    <th className={classes.tablelinesWithCenter}>
                      {" "}
                      Question No.{" "}
                    </th>
                    <th className={classes.tablelinesWithCenter}>
                      {" "}
                      selected Option
                    </th>
                    <th className={classes.tablelinesWithCenter}> Status</th>
                    <th className={classes.tablelinesWithCenter}>
                      {" "}
                      Correct Option
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.questions.map((q) => {
                    return (
                      <tr key={q.question_number}>
                        <td className={classes.tablelines}>
                          {" "}
                          {q.question_number}
                        </td>
                        <td className={classes.tablelines}>
                          {" "}
                          {q.markedAnswer ? q.markedAnswer : "---"}
                        </td>
                        <td className={classes.tablelines}>
                          <Typography
                            variant="span"
                            className={classNames(
                              q.decision === "CORRECT" ? classes.right : "",
                              q.decision === "WRONG" ? classes.wrong : ""
                            )}
                          >
                            {q.decision ? q.decision : "N/A"}{" "}
                          </Typography>
                        </td>
                        {/* <td className={classes.tablelinesWithCenter}> {this.alphaMap[q.correct_answer]}</td> */}
                        <td className={classes.tablelines}>
                          {" "}
                          {q.correct_answer}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* </GridContainer> */}
            </GridItem>
            {/* </Grid> */}
          </GridContainer>
        )}
        {this.loaded && !this.keyAvailable && (
          <Grid container direction="row" justify="center" alignItems="center">
            <Typography
              component="h1"
              variant="h3"
              style={{ marginTop: "10%" }}
            >
              Key is not uploaded to evaluate
            </Typography>
          </Grid>
        )}
        {!this.loaded && <NetworkProgress> </NetworkProgress>}
        {/* <Results> 64880  </Results> */}
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(Results);
