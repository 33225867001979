import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import TotalGrades from "./TotalGradesComparison";
import CompareMarksAndGrades from "./CompareMarksAndGrades";
import { useState } from "react";
import { useSelector } from "react-redux";
import subjectList from "./subjects.json";
function TotalMarks({ subjects }) {
  const [rankData, setRankData] = useState({});
  const ranks = useSelector((state) => state.rankAnalysis);

  const rank = () => {
    if (subjects && ranks.data && subjectList[subjects]) {
      return {
        yourDetails: {
          m: ranks.data.YourDetails[subjectList[subjects].subjectCode],
          c: ranks.data.YourDetails[subjectList[subjects].correct],
          w: ranks.data.YourDetails[subjectList[subjects].wrong],
          u: ranks.data.YourDetails[subjectList[subjects].unAttempted],
        },
        TopperDetails: {
          m: ranks.data.TopperDetails[subjectList[subjects].subjectCode],
          c: ranks.data.TopperDetails[subjectList[subjects].correct],
          w: ranks.data.TopperDetails[subjectList[subjects].wrong],
          u: ranks.data.TopperDetails[subjectList[subjects].unAttempted],
        },
        Average: {
          m: ranks.data.Average[subjectList[subjects].subjectCode],
          c: ranks.data.Average[subjectList[subjects].correct],
          w: ranks.data.Average[subjectList[subjects].wrong],
          u: ranks.data.Average[subjectList[subjects].unAttempted],
        },
      };
    }
  };
  useEffect(() => {
    setRankData(rank);
  }, [ranks, subjects]);
  return (
    <React.Fragment>
      <Grid container direction="column">
        <Grid item xs={12}>
          <CompareMarksAndGrades ranks={rankData} />
        </Grid>
        <Grid item xs={12}>
          <TotalGrades />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TotalMarks;
