import React, { useEffect, useState, useMemo } from "react";

import Highcharts from "highcharts";
import m from "../Dashboard/assets/greenTick.png";
import p from "../Dashboard/assets/blueTick.png";
import c from "../Dashboard/assets/blackTick.png";
import rc from "../Dashboard/assets/blackCross.png";
import rp from "../Dashboard/assets/blueCross.png";
import rm from "../Dashboard/assets/greenCross.png";
import um from "../Dashboard/assets/greenUnattempted.png";
import up from "../Dashboard/assets/blueUnattempted.png";
import uc from "../Dashboard/assets/blackUnattempted.png";

export const subColors = {
  MATHEMATICS: {
    color: "#02f03e",
  },

  PHYSICS: {
    color: "#02b0f0",
  },

  CHEMISTRY: {
    color: "black",
  },
};
const StudentExamBehaviourChart = ({ data = {}, examDurationInMinutes }) => {
  // const [finalData, setFinalData] = useState([]);
  const [myData1, setMyData1] = useState([]);
  const doneIcons = (sub) => {
    if (sub === "MATHEMATICS") {
      return `url(${m})`;
    }
    if (sub === "CHEMISTRY") {
      return `url(${c})`;
    }
    if (sub === "PHYSICS") {
      return `url(${p})`;
    }
  };
  const crossIcons = (sub) => {
    if (sub === "MATHEMATICS") {
      return `url(${rm})`;
    }
    if (sub === "CHEMISTRY") {
      return `url(${rc})`;
    }
    if (sub === "PHYSICS") {
      return `url(${rp})`;
    }
  };
  const unAttemptedIcons = (sub) => {
    if (sub === "MATHEMATICS") {
      return `url(${um})`;
    }
    if (sub === "CHEMISTRY") {
      return `url(${uc})`;
    }
    if (sub === "PHYSICS") {
      return `url(${up})`;
    }
  };
  useEffect(() => {
    const myData = Object.entries(data).map(([k, v]) => ({ name: k, data: v }));
    const myDataValues = myData.map((subject) => {
      subject.data = subject.data.map((question) => {
        if (question.decision === "CORRECT") {
          question.marker = {
            symbol: doneIcons(question.subject),
            width: "12px",
            height: "12px",
          };
        }
        if (question.decision === "WRONG") {
          question.marker = {
            symbol: crossIcons(question.subject),
            width: "12px",
            height: "12px",
          };
        }
        if (question.decision === null) {
          question.marker = {
            symbol: unAttemptedIcons(question.subject),
            width: "12px",
            height: "12px",
          };
        }
        return question;
      });
      return subject;
    });
    setMyData1(myDataValues);
  }, [data]);
  console.log(myData1);

  const finalData = useMemo(() => {
    const subjectsData = [];
    myData1.forEach((i) => {
      if (subColors[i.name]) {
        subjectsData.push({ ...i, ...subColors[i.name] });
      }
    });
    return subjectsData;
  }, [myData1]);
  // useEffect(() => {
  //   const subjectsData = [];
  //   const mergeSubColors = () => {
  //     myData1.map((i) => {
  //       if (subColors[i.name]) {
  //         subjectsData.push({ ...i, ...subColors[i.name] });
  //       }
  //       setFinalData(subjectsData);
  //     });
  //   };
  //   mergeSubColors();
  // }, [myData1]);

  const metadata = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "scatter",
      zoomType: "xy",
      maxWidth: "1200px",

      scroll: "auto",
      overflow: "auto",
    },
    title: {
      text: "Exam Behaviour",
    },
    subtitle: {
      text: "time spent on each question vs timeline",
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      scatter: {
        dataLabels: {
          enabled: true,
          formatter: function() {
            return `
        
                  <label style="color:  red;font-weight: 500; font-size:8
                  for="Marks" >${
                    this.point.qNo !== undefined ? this.point.qNo : 0
                  }</label> <br/>              
                `;
          },
        },
        marker: {
          radius: 7,
          symbol: "circle",
          states: {
            hover: {
              enabled: true,
              lineColor: "rgb(100,100,100)",
            },
          },
        },
        states: {
          hover: {
            marker: {
              enabled: false,
            },
          },
        },
      },
    },
    xAxis: {
      title: { text: "Exam Time Line(minutes)" },
      // max: examDurationInMinutes,
    },
    yAxis: {
      title: { text: "Time spent on each question(minutes)" },
    },
    series: finalData || [],
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function(props) {
        return `
    
        <table>
        <tr>
          <td>Q.No</td>
          <td>${this.point.qNo}</td>
        </tr>
        <tr>
            <td>subject</td>
            <td>${this.point.subject}</td>
          </tr>
          <tr>
            <td>Timespent</td>
            <td>${this.point.timeSpent}</td>
          </tr>
          <tr>
            <td>status</td>
            <td>${this.point.status}</td>
          </tr>
          <tr>
          <td>Final Decision</td>
          <td>${
            this.point.decision === null ? "un_attempted" : this.point.decision
          }</td>
        </tr><tr>
          <tr>
          <td>Answer marked</td>
          <td>${
            this.point.markedAnswer === "NA" ? "_" : this.point.markedAnswer
          }</td>
        </tr>
        <tr>
        <td>No of times visited</td>
        <td>${this.point.qBehaviour.filter((q) => q.type === "DISPLAY").length}
        <br/> 
     
        </td>
       
      </tr>
      <tr>
      <td>Marked Answers for every visiting </td>
      <td>
      ${this.point.qBehaviour
        .filter((item) => item.type === "EXIT")
        .map((item) => item.markedAnswer)
        .map((value) => {
          if (value === "NA") {
            return "_";
          } else {
            return value;
          }
        })
        .join(",")}
      </td>
      </tr>
        <tr>
        <td>last action</td>
        <td>${
          this.point.type === "DISPLAY"
            ? "You entered the question"
            : this.point.type === "EXIT"
            ? "You exicted the question"
            : "You altered the answer"
        }</td>
      </tr>

        </table>
       
       `;
      },
      valueDecimals: 2,
    },
  };

  const chartDetails = () =>
    Highcharts.chart("student-behaviour-analysis", metadata);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) chartDetails();
  }, [data]);

  return <div id="student-behaviour-analysis" />;
};

export default StudentExamBehaviourChart;
