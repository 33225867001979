import React from "react";
import { Tab, Tabs, withStyles } from "@material-ui/core";

const OnTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#8e9aaf",
    boxSizing: "border-box",
    marginRight: "1%",

    "&:hover": {
      color: "#08C5A7",
      opacity: 1,
    },
  },
  selected: {
    color: theme.palette.primary.main,
    opacity: 1,
  },
  disabled: {
    color: theme.palette.primary.main,
  },
}))((props) => <Tab disableRipple {...props} />);

const CustomTab = withStyles((theme) => ({
  root: {
    lineHeight: "18px",
    fontSize: "14px",
  },
}))((props) => <OnTab disableRipple {...props} />);
const CustomTabs = withStyles({
  indicator: {
    backgroundColor: "transparent",
    float: "left",
  },
})((props) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons="off"
    TabIndicatorProps={{ children: <div /> }}
  />
));
export { CustomTab, CustomTabs };
