// import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
// import Typography from '@material-ui/core/Typography';
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
// import markedReview from  "assets/img/adv-marked_review.svg";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { withRouter } from "react-router-dom";
// import Container from '@material-ui/core/Container';
import compose from "recompose/compose";
import "./AdvanceNumbersPanel";
const styles = (theme) => ({
  fab: {
    borderRadius: "0px",
    //  backgroundColor: 'unset',
    width: "40px",
    height: "40px",
    fontSize: "14px",
    fontWeight: "bolder",
    boxShadow: "none",
    //  width: 'max-content',
    backgroundSize: "90% 90% !important",
  },
  proceed: {
    color: "#fff",
    backgroundColor: "#337ab7",
    borderColor: "#2e6da4",
    padding: ".5% 7%",
    width: "max-content",
  },
});
function InstructionAdv(props) {
  const statusmap = {
    NOT_VISITED: "adv-not-visd",
    NOT_ANSWERED: "adv-not-ansd",
    ANSWERED: "adv-ansd",
    MARKED_FOR_REVIEW: "adv-mark-reviewd",
    ANSWERED_MARKED_FOR_REVIEW: "adv-ans-marked-review",
  };
  let checkedFlag = false;
  function isChecked(val) {
    checkedFlag = val.target.checked;
  }
  function proceed() {
    const { history } = props;
    if (!checkedFlag) {
      alert("Please accept terms and conditions before proceeding.");
    } else {
      history.push("/exam-portal", { ...props.location.state });
    }
  }
  const { classes } = props;
  return (
    <div
      style={{
        background: "white",
        fontSize: "18px",
        fontWeight: "400",
        fontFamily: "Roboto",
        lineHeight: "1.5",
      }}
    >
      <GridContainer justify="space-around">
        <GridItem
          xs={12}
          style={{
            padding: "16px",
            paddingLeft: "32px",
            fontSize: "24px",
            fontWeight: "700",
            background: "#2d70b6",
            color: "#ffffff",
          }}
        >
          JEE ADVANCE
        </GridItem>
        <GridItem
          xs={12}
          style={{
            padding: "8px",
            paddingLeft: "32px",
            fontSize: "18px",
            fontWeight: "600",
            background: "#bce8f5",
            color: "#676568",
          }}
        >
          Instructions
        </GridItem>
        <GridItem xs={12} style={{ textAlign: "center" }}>
          <Typography variant="h5" component="h5" style={{ padding: "16px" }}>
            {" "}
            READ THE INSTRUCTIONS CAREFULLY{" "}
          </Typography>
        </GridItem>
      </GridContainer>
      {/* <h4>READ THE INSTRUCTIONS CAREFULLY</h4> */}
      <GridContainer justify="space-evenly" style={{ padding: "40px" }}>
        <GridItem>
          <Typography component="h2" variant="h5">
            GENERAL INSTRUCTIONS
          </Typography>
          <ol
            type="1"
            style={{
              fontSize: "18px",
              fontWeight: "400",
              fontFamily: "Roboto",
              lineHeight: "1.5",
            }}
          >
            <li>Total duration of the paper is 3 hours (180 minutes).</li>
            <li>
              The on-screen computer countdown timer on the top right corner of
              computer screen will display the remaining time (in minutes)
              available to you for completing the paper. When the on-screen
              countdown timer reaches zero, the paper will end by itself{" "}
              <strong>
                – No input from your side will be accepted after the timer
                reaches zero
              </strong>{" "}
              and whatever answers have been saved by you will automatically be
              submitted for evaluation.
            </li>
            <li>
              The <strong>“Submit”</strong> button present at the bottom right
              corner of the screen will remain deactivated during the entire 180
              minutes duration of the paper. Note that the{" "}
              <strong>“Submit”</strong> button will be activated only after the
              timer has reached zero and the saved responses will be
              automatically submitted. It is <strong>NOT</strong> required to
              click on <strong>“Submit”</strong> after the timer has reached
              zero.
            </li>
            <li>
              The Question Palette displayed on the right side of screen will
              show the status of each question as per one of the following
              symbols <i>(the question numbers appear inside the symbols)</i>:
              <Table>
                <TableRow>
                  <TableCell style={{ border: "1px solid grey" }}>
                    <Button
                      size="sm"
                      key="a"
                      className={classNames(
                        classes.fab,
                        statusmap["NOT_VISITED"]
                      )}
                    >
                      1
                    </Button>
                  </TableCell>
                  <TableCell style={{ border: "1px solid grey" }}>
                    <strong>“Not Visited”</strong> - You have not visited the
                    question yet.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: "1px solid grey" }}>
                    <Button
                      size="sm"
                      key="a"
                      className={classNames(
                        classes.fab,
                        statusmap["NOT_ANSWERED"]
                      )}
                    >
                      2
                    </Button>
                  </TableCell>
                  <TableCell style={{ border: "1px solid grey" }}>
                    <strong>“Not Answered”</strong> - You have not answered the
                    question yet.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: "1px solid grey" }}>
                    {" "}
                    <Button
                      size="sm"
                      key="a"
                      className={classNames(classes.fab, statusmap["ANSWERED"])}
                    >
                      3
                    </Button>{" "}
                  </TableCell>
                  <TableCell style={{ border: "1px solid grey" }}>
                    <strong>“Answered”</strong> - You have answered the
                    question. All these questions will be evaluated.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: "1px solid grey" }}>
                    {" "}
                    <Button
                      size="sm"
                      key="a"
                      className={classNames(
                        classes.fab,
                        statusmap["MARKED_FOR_REVIEW"]
                      )}
                    >
                      4
                    </Button>{" "}
                  </TableCell>{" "}
                  <TableCell style={{ border: "1px solid grey" }}>
                    <strong>“Marked for Review”</strong> - You have NOT answered
                    the question but have ONLY marked the question for review.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: "1px solid grey" }}>
                    <Button
                      size="sm"
                      key="a"
                      className={classNames(
                        classes.fab,
                        statusmap["ANSWERED_MARKED_FOR_REVIEW"]
                      )}
                    >
                      5
                    </Button>{" "}
                  </TableCell>
                  <TableCell style={{ border: "1px solid grey" }}>
                    <strong>“Answered and Marked for Review”</strong> - You have
                    answered the question and have also marked it for review.
                    All these questions will be evaluated.
                  </TableCell>
                </TableRow>
              </Table>
            </li>

            <li>
              The <strong>“Marked for Review”</strong> status for a question
              indicates you would like to look at that question again.
            </li>
            <li>
              You can click on the &gt; arrow symbol, which appears to the left
              of question palette, to collapse the question palette thereby
              maximizing the question window. To view the question palette
              again, you can click on &lt; symbol which appears on the right
              side of question window.
            </li>
            <li>
              Before you start the paper, select your default language (either
              ENGLISH or HINDI) for viewing the questions by selecting your
              preferred language from the drop down menu under{" "}
              <strong>“Choose your default language”</strong> located below the{" "}
              <strong>“Instructions to Candidates”</strong> section
            </li>
            <li>
              Anytime during the paper, you can change the question viewing
              language of the displayed question. To change the question viewing
              language (either ENGLISH or HINDI) of the displayed question,
              select the preferred language from the drop down menu under{" "}
              <strong>“View in”</strong> located in the upper right side of the
              question viewing window.
            </li>
            <li>
              Anytime during the paper, you can change the default question
              viewing language of the question paper. You can click on{" "}
              <strong>“Profile”</strong> image on top right corner of computer
              screen to change the default language (either ENGLISH or HINDI) of
              the entire question paper during the exam. On clicking of Profile
              image, you will get an option to change the default question
              viewing language.
            </li>
            <li>
              You can click on downward arrow symbol Scroll Down to navigate to
              the bottom and upward arrow Scroll Up to navigate to the top of
              the question area, without scrolling.
            </li>
            <li>
              You can also use the computer mouse to scroll up/down the question
              viewing area to view complete contents of the question viewing
              area.
            </li>
            <li>
              At the end of this <strong>“Instructions to Candidates”</strong>{" "}
              section, you must click on the checkbox beside the{" "}
              <strong>
                “I have read all the instructions and shall abide by them”
              </strong>{" "}
              and then only you will be able to proceed to view and answer the
              questions at the start of the paper. Your on-screen clock will
              start at the designated time of the start of the paper.
            </li>
            <li>
              The full question paper can be viewed anytime during the paper by
              clicking the <strong>“Question Paper”</strong> button on the top
              right corner of the computer screen.
            </li>
            <li>
              These instructions can be viewed anytime during the paper by
              clicking <strong>“Instructions”</strong> button located at the top
              right corner of the computer screen.
            </li>
          </ol>
          <hr />
          <div>
            <h2>Other Important Instructions</h2>
            <h4>NAVIGATING THROUGH PARTS/SECTIONS OF QUESTION PAPER</h4>
            <ol type="a">
              <li>
                Parts (PHYSICS, CHEMISTRY and MATHEMATICS) and sections of the
                parts thereof in the question paper are displayed on the top of
                the screen. Questions within a section can be viewed by clicking
                on the corresponding section name. The section which you will be
                viewing will be highlighted.
              </li>
              <li>
                After clicking the <strong>“Save & Next”</strong> button on the
                last question of a part/section, you will automatically be taken
                to the first question of the next part/section.
              </li>
              <li>
                You can shuffle between parts/sections and questions within
                parts/sections anytime during the paper as per your convenience.
              </li>
              <li>
                You can view the corresponding section summary which will be
                visible in every section above the question palette.
              </li>
            </ol>
            <Typography component="h2" variant="h5">
              NAVIGATING TO A QUESTION
            </Typography>

            <p>To navigate between questions, you need to do the following:</p>
            <ol type="i">
              <li>
                Click on the question number in the Question Palette at the
                right of the screen to go to that numbered question directly.{" "}
                <strong>
                  Note that using this option does NOT save the answer (if it is
                  answered) to the current question. To save the answer, you
                  must click on “Save & Next”
                </strong>{" "}
                button.
              </li>
              <li>
                Click on <strong>“Save & Next”</strong> button to save the
                answer for the current question and then go to the next
                question.
              </li>
              <li>
                Click on <strong>“Mark for Review & Next”</strong> button to
                mark it for review (with or without answering the question) and
                go to the next question.
              </li>
            </ol>
            <Typography component="h2" variant="h5">
              ANSWERING A QUESTION
            </Typography>

            {/* <p>hello</p>  */}
            {/* <ul> */}
            <li>
              Follow the procedure, given in{" "}
              <strong>“Instructions to Candidates”</strong> sheet (handed over
              to you before the start of the paper), for answering a particular
              type of question.
            </li>
            <li>
              To change the answer of a question that has already been answered,
              if required, first click on the <strong>“Clear Response”</strong>{" "}
              button to clear the saved answer and then follow the <br />
              &nbsp; &nbsp; &nbsp; procedure for answering that type of
              question.
            </li>
            <li>
              To mark a question ONLY for review (i.e. without answering it),
              click on the <strong>“Mark for Review & Next”</strong> button.
            </li>
            <li>
              To mark a question for review (after answering it), click on{" "}
              <strong>“Mark for Review & Next”</strong> button – the answered
              question which is also marked for review will be evaluated.
            </li>
            <li>
              To save the answer, click on the <strong>“Save & Next”</strong>{" "}
              button - the answered question will be evaluated
            </li>
            {/* </ul> */}

            <Typography component="h2" variant="h5">
              END OF PAPER
            </Typography>
            <li>
              When the on-screen computer countdown timer on the top right
              corner of computer screen reaches zero, the paper will end by
              itself – No input from your side will be accepted after the timer
              reaches zero and whatever answers have been saved by you will be
              automatically submitted for evaluation. Note that the{" "}
              <strong>“Submit”</strong> button will be activated only after the
              timer has reached zero and the saved responses will be
              automatically submitted. It is NOT required to click on{" "}
              <strong>“Submit”</strong> after the timer has reached zero.
            </li>
          </div>

          {/* Choose your default language: <br/>
Please note all questions will appear in your default language. This language can be changed for a particular question later on. <br/>
I have read and understood the instructions. All computer hardware allotted to me are in proper working condition. I declare that I am not in possession of / not wearing / not carrying any prohibited gadget like mobile phone, bluetooth devices etc. /any prohibited material with me into the Examination Hall. I agree that in case of not adhering to the instructions, I shall be liable to be debarred from this Test and/or to disciplinary action, which may include ban from future Test / Examinations */}
        </GridItem>
        <GridItem xs={12}>
          <hr />
          <h3
            style={{
              padding: "1%",
              fontSize: 20,
              fontWeight: 400,
              color: "#a94442",
            }}
          >
            {" "}
            Please note all questions will appear in your default language. This
            language can be changed for a particular question later on.{" "}
          </h3>
          <hr />
          <h3 style={{ padding: "1%", fontSize: 14, fontWeight: 400 }}>
            <input type="checkbox" onChange={isChecked} />I have read and
            understood the instructions. All computer hardware allotted to me
            are in proper working condition. I declare that I am not in
            possession of / not wearing / not carrying any prohibited gadget
            like mobile phone, bluetooth devices etc. /any prohibited material
            with me into the Examination Hall.I agree that in case of not
            adhering to the instructions, I shall be liable to be debarred from
            this Test and/or to disciplinary action, which may include ban from
            future Tests / Examinations
          </h3>
          <hr />
        </GridItem>
        <GridItem item xs={12} container justify="space-around">
          <Typography
            component="h6"
            className={classes.proceed}
            onClick={proceed}
          >
            Proceed
          </Typography>
        </GridItem>{" "}
      </GridContainer>
    </div>
  );
}

export default compose(
  withRouter,
  withStyles(styles)
)(InstructionAdv);
