import {
  Box,
  Button,
  Grid,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { capitalize } from "lodash";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import store from "store";
import {
  getSolvedQuestionsAggregate,
  getTopics,
} from "../../apis/practiceService";
import theme from "../../assets/theme";
import RedirectUnpaidPublicUsers from "../../utils/RedirectUnpaidPublicUsers";
import BottomNav from "../BottomNav";
import ComingSoonPlans from "../components/ComingSoonPlans";
import { CustomTabs, CustomTab } from "./CustomTabs";
import Header from "../Header";
import Practice from "./Practice";
import SearchBar from "./SearchBar";
import { availableSubjects } from "./topicDetails/decodeCodeP";
import mapTopicAndSubTopic from "./topicDetails/TopicAndSubTopicMap";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ProgressAnalytics from "./ProgressAnalytics";
import structureStudentProgressData from "./structureStudentProgressData";
import Fieldset from "../Fieldset";
import NetworkProgress from "../NetworkProgress";
import useFetch from "../../hooks/useFetch";
import ResultHeader from "../resultPage/ResultHeader";
const useStyle = makeStyles(() => ({
  boxContainer: {
    margin: "0 17% 0 17%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 3% 0 3%",
    },
  },
  "@media screen and (max-width: 1199px) and (min-width: 959px)": {
    boxContainer: {
      paddingTop: "8%",
    },
  },
}));

let getAllSubjects = Object.values(availableSubjects);

function Index() {
  const user = store.get("user");
  const [, , tList, getTopic] = useFetch(getTopics);
  const [subjectIndex, setSubjectIndex] = useState(
    store.get("practiceSubject")
      ? getAllSubjects.indexOf(store.get("practiceSubject"))
      : 0
  );
  const [allTopics, setAllTopics] = useState([]);
  const [topicObject, setTopicObject] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [openProgress, setOpenProgress] = useState(false);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    getTopic({
      instituteId: user.instituteId || user.institute,
      batch: user.batch || user.level,
    });
  }, []);

  const MemoisedPractice = memo(Practice);
  // navigate subjects
  const handleSubjectIndex = (event, index) => {
    setSubjectIndex(index);
    store.set("practiceSubject", getAllSubjects[index]);
  };

  // get topics and subtopics of selected subject
  const listOfTopics = useCallback(
    (searchKey, topicList) => {
      const topics =
        topicList &&
        topicList.result &&
        topicList.result[getAllSubjects[subjectIndex]]
          ? topicList.result[getAllSubjects[subjectIndex]]
          : mapTopicAndSubTopic(getAllSubjects[subjectIndex]);
      const topicArray = Object.keys(topics);
      const topicObject = {};
      const finalList = topicArray.filter((topic) =>
        topic.includes(searchKey.toUpperCase())
      );
      finalList.forEach((item) => {
        if (item in topics) {
          topicObject[item] = topics[item];
        }
      });
      setTopicObject(topicObject);
      setAllTopics(finalList);
    },
    [subjectIndex]
  );

  // handle search for topics
  const handleSearch = (event) => {
    setSearchKey(event.target.value);
  };

  useEffect(() => {
    let subjectName = store.get("practiceSubject");
    if (subjectName) {
      store.get("practiceSubject");
    } else {
      store.set("practiceSubject", getAllSubjects[subjectIndex]);
    }
    listOfTopics(searchKey, tList);
  }, [subjectIndex, searchKey, tList]);

  const classes = useStyle();

  useEffect(() => {
    const result = [];
    getAllSubjects.forEach((subject) => {
      result.push(
        getSolvedQuestionsAggregate({
          instituteId: user.institute,
          subject: subject,
          student_id: user._id,
        })
      );
    });
    Promise.all(result)
      .then((values) => {
        setIsLoading(true);
        const output = {};
        values.forEach((value, index) => {
          output[getAllSubjects[index]] = value;
        });
        setData(output);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  const handleOpenProgressAnalytics = () => {
    setOpenProgress(true);
  };
  const handleCloseProgress = () => {
    setOpenProgress(false);
  };

  // handle unpaid users
  if (RedirectUnpaidPublicUsers()) {
    return <Redirect to="/plans" />;
  }

  if (isLoading) {
    return <NetworkProgress />;
  }

  return (
    <ThemeProvider theme={theme}>
      {/* <Header /> */}
      <br />
      <ResultHeader testDisplayName="Practice" path="/practice" />
      <Box className={classes.boxContainer} pt="2%" mb={10}>
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            {/* tabs for switching subjects  */}
            <CustomTabs value={subjectIndex} onChange={handleSubjectIndex}>
              {getAllSubjects.map((subject) => {
                return (
                  <CustomTab
                    key={subject}
                    label={
                      <Fieldset
                        value={data[subject] && data[subject][subject]}
                        margin="-15px 0px 2px -10px"
                      >
                        <Typography>{capitalize(subject)}</Typography>
                      </Fieldset>
                    }
                  />
                );
              })}
            </CustomTabs>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            container
            justify="flex-end"
            alignItems="center"
          >
            <SearchBar handleSearch={handleSearch} value={searchKey} />
          </Grid>
        </Grid>
        <br />
        <Grid container direction="column">
          <Grid item xs={12} container direction="row" justify="flex-end">
            <Tooltip title="Progress Chart">
              <Button
                startIcon={<AssessmentIcon />}
                color="primary"
                variant="outlined"
                size="small"
                onClick={handleOpenProgressAnalytics}
              >
                My Stats
              </Button>
            </Tooltip>
          </Grid>
          <br />
          <Grid item xs={12}>
            {allTopics.length === 0 ? (
              <ComingSoonPlans plans="Loading..." />
            ) : (
              <MemoisedPractice
                topics={topicObject}
                arrayOfTopic={allTopics}
                subject={getAllSubjects[subjectIndex]}
                percentData={data && data[getAllSubjects[subjectIndex]]}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <ProgressAnalytics
        open={openProgress}
        handleCloseProgress={handleCloseProgress}
        progressData={
          data &&
          structureStudentProgressData(data[getAllSubjects[subjectIndex]])
        }
        isLoading={isLoading}
        error={error}
      />
      <BottomNav />
    </ThemeProvider>
  );
}

export default Index;
