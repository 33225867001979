import * as actionType from "../../actions/resultPageActions/actions";

// result details and time spent analysis
const INITIAL_RESULT_DETAILS = {
  isLoading: false,
  data: [],
  dataResult: [],
  subjects: [],
  errorResult: null,
  errorTimeSpent: null,
  keyAvailable: false,
  behaviour: null,
  fetchBehaviourErr: null
};
const resultDetailsReducer = (result = INITIAL_RESULT_DETAILS, action) => {
  switch (action.type) {
    case actionType.FETCH_RESULT:
      return {
        ...result,
        isLoading: true,
      };
    case actionType.FETCH_RESULT_SUCCESS:
      return {
        ...result,
        isLoading: false,
        dataResult: action.payloadResult.questions,
        testTotalMarks: action.payloadResult.testTotalMarks,
        keyAvailable: action.payloadResult.keyAvailable,
      };
    case actionType.FETCH_TIME_SPENT_SUCCESS:
      return {
        ...result,
        isLoading: false,
        data: action.payload,
      };
    case actionType.FETCH_RESULT_ERROR:
      return {
        ...result,
        isLoading: false,
        errorResult: action.payloadResult,
      };
    case actionType.ALL_SUBJECTS:
      return {
        ...result,
        isLoading: false,
        subjects: action.payload,
      };
    case actionType.FETCH_TIME_SPENT_ERROR:
      return {
        ...result,
        isLoading: false,
        errorTimeSpent: action.payloadTimeSpent,
      };
    case actionType.CLEAR_RESULT:
      return {
        ...result,
        isLoading: false,
        dataResult: [],
        data: [],
        subjects: [],
        error: null,
        keyAvailable: false,
      };
    case actionType.FETCH_STUDENT_BEHAVIOUR_SUCCESS:
      return {
        ...result,
        behaviour: action.payload
      };
      case actionType.FETCH_STUDENT_BEHAVIOUR_ERR:
        return {
          ...result,
          fetchBehaviourErr: action.payload
        };
    default:
      return result;
  }
};

// rank analysis details
const INITIAL_RANK_DETAILS = {
  isLoading: false,
  data: {},
  error: null,
};
const rankAnalysisReducer = (rank = INITIAL_RANK_DETAILS, action) => {
  switch (action.type) {
    case actionType.FETCH_RANK:
      return {
        ...rank,
        isLoading: true,
      };
    case actionType.FETCH_RANK_SUCCESS:
      return {
        ...rank,
        isLoading: false,
        data: action.payload,
      };
    case actionType.FETCH_RANK_ERROR:
      return {
        ...rank,
        isLoading: false,
        error: action.payload,
      };
    case actionType.CLEAR_RANK:
      return {
        ...rank,
        isLoading: false,
        data: {},
        error: null,
      };
    default:
      return rank;
  }
};

// compare question details reducer
const INITIAL_COMPARE_STATE = {
  isLoading: true,
  data: {},
  error: null,
};
const compareQuesDetailsReducer = (details = INITIAL_COMPARE_STATE, action) => {
  switch (action.type) {
    case actionType.FETCH_COMPARISON_QUESTIONS:
      return {
        ...details,
      };
    case actionType.FETCH_COMPARISON_QUESTIONS_SUCCESS:
      return {
        ...details,
        isLoading: false,
        data: action.payload,
      };
    case actionType.FETCH_COMPARISON_QUESTIONS_ERROR:
      return {
        ...details,
        isLoading: false,
        error: action.payload,
      };
    case actionType.CLEAR_COMPARE_QUESTIONS:
      return {
        ...details,
        isLoading: false,
        data: {},
        error: null,
      };
    default:
      return details;
  }
};
export { resultDetailsReducer, rankAnalysisReducer, compareQuesDetailsReducer };
