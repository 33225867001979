// styles.js
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "transparent",
    "& .Mui-selected": {
      color: "inherit"
    },
    "& .MuiBottomNavigationAction-root": {
      minWidth: 0,
      color: "inherit"
    }
  },
  appBarTop: {
    top: 0,
    bottom: "auto"
  },
  appBarBottom: {
    top: "auto",
    bottom: 0
  },
  toolbarButtons: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%"
  },
  iconButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "inherit",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },
  text: {
    width: "40px",
    height: "20px",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginLeft: theme.spacing(1)
    }
  },
  catText: {
    width: "335px",
    height: "28px",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#171717",
    flex: "none",
    order: 0,
    alignSelf: "stretch",
    flexGrow: 0
  },
  catContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "40px",
    width: "100%",
    height: "100%",
    flex: "none",
    order: 1,
    alignSelf: "stretch",
    flexGrow: 1
  },
  mainBackground: {
    background: "#EBEFF3",
    width: "100%",
    marginBottom: "70px",
    marginTop: "0px",
    padding: "0px",
    // position: "absolute",
    marginBottom: "70px"
  },
  cursor: {
    cursor: "pointer"
  },
  icon: {
    width: "24px",
    height: "24px"
  },
}));

export default useStyles;
