import { Button, Grid, makeStyles } from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import store from "store";
import {
  bookmarkQuestion,
  clearBodyFromStore,
  saveBodyToStore,
  savedAnswer,
} from "../../../redux/actions/practicePageActions/actionCreators";
import AlertComponent from "../../AlertComponent";
import ActionButtonsComponent from "./ActionButtonsComponent";
import SingleQuestionComponent from "./SingleQuestionComponent";
const useStyle = makeStyles(() => ({
  rootContainer: {
    height: "fit-content",
  },
}));
var timeInterval;
const DisplaySingleQuestion = ({
  question,
  handleClickBack,
  handleNextQuestion,
  dataLength,
}) => {
  const classes = useStyle();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [value, setValue] = useState("");
  const [checkboxValue, setCheckboxValue] = useState([]);
  const [integerVal, setIntegerVal] = useState("");
  const [timerVal, setTimerVal] = useState("");
  const [timerHasStarted, setTimerHasStarted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const dispatch = useDispatch();
  const student_Id = store.get("user")._id;
  let body = {};
  useEffect(() => {
    setTimerHasStarted(false);
  }, [question]);
  // alert controller
  const handleCloseAlertBox = () => {
    setConfirmOpen(false);
    setIsSubmitted(false);
    setValue("");
    dispatch(clearBodyFromStore());
  };

  ///////////////////////// handle timer ///////////////////////////////////////////
  if (!question.answer) {
    if (store.get(question.qId) == undefined) {
      if (timeInterval) clearInterval(timeInterval);
      store.set(question.qId, Date.now());
      timeInterval = setInterval(
        () =>
          setTimerVal(parseInt((Date.now() - store.get(question.qId)) / 1000)),
        1000
      );
    }
    if (!timerHasStarted) {
      if (timeInterval) clearInterval(timeInterval);
      timeInterval = setInterval(
        () =>
          setTimerVal(parseInt((Date.now() - store.get(question.qId)) / 1000)),
        1000
      );
      setTimerHasStarted(!timerHasStarted);
    }
  }
  ////////////////////////////////////////////////////////////////////////////////////

  // request body for submitting answer
  body = {
    ...body,
    markedAns:
      question.q_type === "integer"
        ? integerVal
        : question.q_type === "multiple"
        ? [...new Set(checkboxValue)].join("")
        : value,
    subject: question.subjectName,
    topic: question.topic,
    subTopic: question.subTopic === "NA" ? null : question.subTopic,
    student_id: student_Id,
    qId: question.qId ? `${question.qId}` : null,
    instituteId: question.instituteId,
  };

  // radio button option change
  const handleOptionChange = (event) => {
    const saveBody = {
      isSubmitted: false,
      value: event.target.value,
      checkboxValue: checkboxValue,
      integerVal: integerVal,
      isError: false,
    };
    dispatch(saveBodyToStore(saveBody));
    setValue(event.target.value);
  };

  // multiple selection using checkbox
  const handleCheckbox = (event, isChecked, value) => {
    isChecked
      ? setCheckboxValue([...checkboxValue, value])
      : setCheckboxValue(checkboxValue.filter((data) => data !== value));
  };

  // input integer value
  const setIntegerValue = (intValue) => {
    setIntegerVal(intValue);
    const saveBody = {
      isSubmitted: false,
      value: value,
      integerVal: intValue,
      isError: false,
    };
    dispatch(saveBodyToStore(saveBody));
  };

  // clear response
  const handleClearResponse = () => {
    setValue("");
    setIntegerVal();
    setCheckboxValue();
    setIsError(false);
    dispatch(clearBodyFromStore());
  };

  // submit answer controller
  const handleSubmitAnswer = async () => {
    try {
      clearInterval(timeInterval);
      body["timeSpent"] = parseInt(
        (Date.now() - store.get(question.qId)) / 1000
      );
      dispatch(savedAnswer(body));
      setIsSubmitted(true);
      const saveBody = {
        isSubmitted: true,
        value: value,
        integerVal: integerVal,
        isError: false,
      };
      dispatch(saveBodyToStore(saveBody));
    } catch (err) {
      console.error("ERROR: ", err.message);
      setIsError(true);
      setIsSubmitted(false);
      setConfirmOpen(true);
    }
  };

  // view solution controller
  const handleViewSolution = async () => {
    try {
      clearInterval(timeInterval);
      body["timeSpent"] = parseInt(
        (Date.now() - store.get(question.qId)) / 1000
      );
      const {
        student_id,
        topic,
        subject,
        subTopic,
        instituteId,
        qId,
        timeSpent,
      } = body;
      const markedAns = "";
      dispatch(
        savedAnswer({
          student_id,
          topic,
          subject,
          subTopic,
          instituteId,
          qId,
          timeSpent,
          markedAns,
        })
      );
      setIsSubmitted(true);
      const saveBody = {
        isSubmitted: true,
        value: value,
        integerVal: integerVal,
        isError: false,
      };
      dispatch(saveBodyToStore(saveBody));
    } catch (error) {
      setIsError(true);
      setIsSubmitted(false);
      setConfirmOpen(true);
    }
  };
  // bookmark question controller
  const handleBookmark = async () => {
    try {
      const { student_id, topic, subject, subTopic, instituteId, qId } = body;
      dispatch(
        bookmarkQuestion({
          subject,
          topic,
          subTopic,
          student_id,
          qId,
          instituteId,
        })
      );
    } catch (error) {
      setIsError(true);
      setIsSubmitted(false);
      setConfirmOpen(true);
    }
  };
  return (
    <Grid item container classes={{ root: classes.rootContainer }} spacing={2}>
      <Grid item xs={12}>
        <br />
        <Button onClick={handleClickBack} color="inherit">
          <ArrowBackIosRoundedIcon fontSize="inherit" />
          &nbsp; View all questions
        </Button>
      </Grid>
      <Grid item xs={12}>
        <SingleQuestionComponent
          questionData={question}
          handleOptionChange={handleOptionChange}
          handleCheckbox={handleCheckbox}
          setIntegerValue={setIntegerValue}
          timer={timerVal}
          handleBookmark={handleBookmark}
          body={body}
        />
      </Grid>
      <ActionButtonsComponent
        handleClearResponse={handleClearResponse}
        handleViewSolution={handleViewSolution}
        handleSubmitAnswer={handleSubmitAnswer}
        handleNextQuestion={() => {
          if (dataLength > 1) {
            handleNextQuestion();
            dispatch(clearBodyFromStore());
            setIsSubmitted(false);
          }
          store.remove(body.qId);
        }}
        question={question}
        isSubmitted={isSubmitted}
        value={
          question.q_type === "integer"
            ? integerVal
            : question.q_type === "multiple"
            ? [...new Set(checkboxValue)].join("")
            : value
        }
      />
      {/* error handling  */}
      <AlertComponent
        open={confirmOpen}
        alertType={"error"}
        closeAlert={handleCloseAlertBox}
      >
        "Something went wrong!"
      </AlertComponent>
    </Grid>
  );
};

export default DisplaySingleQuestion;
