import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = (textStyles) =>
  makeStyles((theme) => ({
    scrollContainer: {
      overflow: "hidden",
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    scrollText: {
      textAlign: "right",
      whiteSpace: "nowrap",
      transform: "translateX(-100%)",
      animation: "$scroll 15s linear infinite",
      ...textStyles,
    },
    "@keyframes scroll": {
      from: {
        transform: "translateX(100%)",
      },
      to: {
        transform: "translateX(-100%)",
      },
    },
  }));

const TextScroll = ({
  text,
  variant = "body1",
  component = "p",
  textStyles = {},
}) => {
  const classes = useStyles(textStyles)();

  return (
    <Box className={classes.scrollContainer}>
      <Typography
        variant={variant}
        component={component}
        className={classes.scrollText}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default TextScroll;
