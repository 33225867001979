import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import ContentWrap from "../HomePage/Components/ContentWrap";
import GradeCircle from "./GradeCircle";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "40px",
  },
  paper: {
    padding: "8px 0",
  },
}));
function RankCard({ background, cardName, rank, total, subjects, icon }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <ContentWrap>
        <Grid
          className={classes.paper}
          container
          direction="row"
          alignItems="center"
        >
          <Grid item xs={7}>
            <Box display="flex" flexDirection="column" gridGap="8px" pr="8px">
              {subjects}
            </Box>
          </Grid>
          <Grid item xs={5}>
            <GradeCircle
              text={cardName}
              score={rank}
              totalMarks={total}
              color={background}
            />
          </Grid>
        </Grid>
      </ContentWrap>
    </React.Fragment>
  );
}

export default RankCard;
