import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/styles";
import React from "react";
import theme from "../../assets/theme";
const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    fontWeight: 600,
    borderColor: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      margin: 0,
    },
  },
  body: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "24px",
    borderColor: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      padding: "5px 0px 5px 0px",
      margin: 0,
      fontSize: 16,
      fontWeight: "normal",
      lineHeight: "24px",
    },
  },
}))((props) => <TableCell {...props} />);
const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(236, 240, 243, 0.25)",
    },
  },
}))((props) => <TableRow {...props} />);
export { StyledTableCell, StyledTableRow };
