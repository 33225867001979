import { Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  root: {
    margin: "15px 0 13px 8px",
  },
}));
function ExamTypeHeading({ title, variant, children, color = "textPrimary" }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography
        variant={variant || "h6"}
        component="div"
        color={color}
        className={classes.root}
      >
        {title}
        &emsp;{children}
      </Typography>
    </React.Fragment>
  );
}

export default ExamTypeHeading;
