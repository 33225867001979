import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Close from "@material-ui/icons/Close";
// core components
import headerStyle from "assets/jss/material-kit-pro-react/components/headerStyle.jsx";
import onesazlogo from "assets/v2/onesaz.png";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import { logoutUser } from "../../apis/UserService";
import AlertComponent from "../AlertComponent";
import clearLocalStorage from "../../common/clearStore";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      logo: onesazlogo,
      confirmOpen: false,
      alertText: "",
    };
    props = this.props;
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.headerColorChange = this.headerColorChange.bind(this);
    this.logout = this.logout.bind(this);
  }
  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }
  handleCloseAlertBox = () => {
    this.setState({ confirmOpen: false, alertText: "" });
  };
  async logout() {
    const exam = store.get("startedExam");
    if (exam && exam.test_status === "STARTED") {
      this.setState({
        confirmOpen: true,
        alertText: `Please, Submit Test : ${
          exam.testDisplayName
        } Before Logging Out!`,
      });
    } else {
      const { history } = this.props;
      await logoutUser({ id: store.get("user")._id });
      clearLocalStorage();
      history.push("/sign-in");
    }
  }
  componentDidMount() {
    // const url = window.loca
    if (this.props.changeColorOnScroll) {
      window.addEventListener("scroll", this.headerColorChange);
    }
  }
  headerColorChange() {
    const { classes, color, changeColorOnScroll } = this.props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  }
  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      window.removeEventListener("scroll", this.headerColorChange);
    }
  }
  render() {
    const { classes, color, links, fixed, absolute } = this.props;
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed,
    });
    return (
      <AppBar className={appBarClasses}>
        <Toolbar
          className={classes.container}
          style={{ backgrounColor: "white" }}
        >
          {/* <GridContainer justify="space-between">  */}
          <Grid item xs={8} sm={10}>
            <Button variant="none">
              <Link to="/">
                {/* {brand} */}
                {!window.location.host.includes("sr.onesaz") ? (
                  <img
                    style={{ width: 150, height: 40 }}
                    src={this.state.logo}
                    alt="..."
                  />
                ) : (
                  <Fragment>
                    <img
                      style={{ width: "37px", height: 10 }}
                      src={this.state.logo}
                      alt="..."
                    />
                    {store.get("user") &&
                    (store.get("user").classVal == "XII" ||
                      store.get("user").classVal == "XI" ||
                      store.get("user").classVal == "11" ||
                      store.get("user").classVal == "12") ? (
                      <span
                        style={{
                          color: "orange",
                          borderLeft: "2px solid #56c2ee",
                          paddingLeft: "2px",
                        }}
                      >
                        {"SR Live Classes"}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "#125aa6",
                          fontSize: "18px",
                          borderLeft: "2px solid #56c2ee",
                          paddingLeft: "2px",
                        }}
                      >
                        {"Educe Live Classes"}
                      </span>
                    )}
                  </Fragment>
                )}
              </Link>
            </Button>
          </Grid>
          {/* <GridItem xs={6}> 
            <Button onClick={this.logout} style={{marginLeft:"auto"}} >

               
               <Logout/>
            </Button>
            </GridItem> */}
          <Hidden smDown implementation="css" className={classes.hidden}>
            <div className={classes.collapse}>{links}</div>
          </Hidden>
          {/* <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu color="primary"/>
            </IconButton>
          </Hidden> */}
          {/* </GridContainer> */}
        </Toolbar>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={this.state.mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={this.handleDrawerToggle}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.closeButtonDrawer}
            >
              <Close />
            </IconButton>
            <div className={classes.appResponsive}>{links}</div>
            {/* <Button onClick={this.logout} >

               
               <Logout/>
            </Button> */}
          </Drawer>
        </Hidden>
        <AlertComponent
          open={this.state.confirmOpen}
          closeAlert={this.handleCloseAlertBox}
        >
          {this.state.alertText}
        </AlertComponent>
      </AppBar>
    );
  }
}

Header.defaultProp = {
  color: "white",
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
};

export default compose(
  withRouter,
  withStyles(headerStyle)
)(Header);
