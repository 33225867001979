import Button from "@material-ui/core/Button/index";
import Grid from "@material-ui/core/Grid/index";
import Hidden from "@material-ui/core/Hidden";
import { withStyles, ThemeProvider } from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography/index";
import quote from "assets/img/quote.png";
import PropTypes from "prop-types";
import React, { Component, Fragment, Suspense, lazy } from "react";
import { Link, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import { changePassword, sendOTP } from "../../apis/UserService";
import { CustomProgress } from "./components/CustomInput";
import theme from "../../assets/theme";
import InputBase1 from "./components/InputBase1";
import NetworkProgress from "../../mycomponents/NetworkProgress";
import { noForgetPwdInstitutes } from "../../utils/allowForgetPassword";
const LazyLoadImages = lazy(() => import("../../mycomponents/LazyLoadImages"));
const style = () => ({
  signInBg: {
    background: theme.palette.background.paper,
    minHeight: "100vh",
  },
  separator: {
    marginTop: "20px",
    background: theme.palette.primary.main,
    width: "55px",
    height: "3px",
    borderRadius: "5px",
  },
  link: {
    color: theme.link.main,
    "&:hover": {
      color: theme.link.hover,
    },
  },
});
// Service methods
const create_NewPassword = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 1500);
  });
};

class createNewPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quote: "",
      logo: "",
      mini: "",
      netReq: false,
      values: {
        name: "",
        password: "",
        phoneNumber: "",
        class: "",
        otp: "",
        confirmPassword: "",
      },
      passwordChanged: false,
      touched: {
        userId: false,
        password: false,
      },
      errors: {
        userId: null,
        password: null,
        otp: null,
      },
      classes: [
        { class: 8, className: "VIII" },
        { class: 9, className: "IX" },
        { class: 10, className: "X" },
      ],
      isValid: true,
      isLoading: false,
      submitError: null,
    };
  }
  componentDidMount() {
    window.recaptchaVerifier = new window.firebase.auth.RecaptchaVerifier(
      "forgot-button",
      {
        size: "invisible",
        callback: function(response) {
          // window.firebase.auth().recaptchaVerifier
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //   onSignInSubmit();
          this.handlecreateNewPassword();
        },
      }
    );
    console.log(window.location.host);
    const company = window.location.hostname.split(".")[0];
    const mainlogo_url =
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/" +
      company +
      ".png";
    const minilogo_url =
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/" +
      company +
      "_mini.png";
    this.setState({ logo: mainlogo_url, mini: minilogo_url });
    if (
      store.get("domainConfig") &&
      store.get("domainConfig").signin &&
      store.get("domainConfig").signin.mini
    ) {
      this.setState({ miniDimensions: store.get("domainConfig").signin.mini });
    }
    if (window.location.host.includes("localhost")) {
      this.setState({
        logo: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/www.png",
        mini:
          "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/onesaz_mini.png",
        quote: quote,
      });
    }
    this.forceUpdate();
  }

  handleTypeSelect = (event) => {
    this.state.values.class = event.target.value;
    this.setState({ [event.target.name]: event.target.value });
  };

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  showOTP = false;

  otpSent = false;

  errorMessage = null;

  handleFieldChange = (field, value) => {
    console.log(field === "otp");
    if (field === "otp") {
      this.setState({ errors: { ...this.state.errors, otp: null } });
      this.forceUpdate();
    }
    const newState = { ...this.state };
    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;
    newState.mismatch = false;
    //
    if (field === "phoneNumber") {
      newState.showOTP = false;
      this.otpSent = false;
    }
    this.setState(newState);
  };

  resendOTP = async () => {
    try {
      let res = await sendOTP({
        phoneNumber: this.state.phoneNumber,
      });
    } catch (err) {
      alert(err);
    }
  };
  handlecreateNewPassword = async () => {
    try {
      const { history } = this.props;
      const { values } = this.state;

      if (values.phoneNumber === "" || values.phoneNumber === null) {
        alert("please enter your Registered phone number");
        return;
      }

      if (values.password === "" || values.password === null) {
        alert("please enter password");
        return;
      }
      if (values.password !== values.confirmPassword) {
        this.setState({ mismatch: true });
        return;
      }

      if (!this.otpSent) {
        // await sendOTP({
        //   phoneNumber: values.phoneNumber,
        // });
        this.setState({
          netReq: true,
        });
        //   this.setState({netReq: false})
        var phoneNumber = values.phoneNumber;
        var appVerifier = window.recaptchaVerifier; // ===google===

        var confirmationResult = await window.firebase // ===google===
          .auth() // ===google===
          .signInWithPhoneNumber("+91" + phoneNumber, appVerifier); // ===google===
        console.log(confirmationResult.verificationId); // ===google===
        this.setState({
          showOTP: true,
          confirmationResult: confirmationResult, // ===google===
          netReq: false,
        });
        this.otpSent = true;
      } else {
        this.setState({ netReq: true });
        if (values.otp === "" || values.otp === null) {
          alert("please enter the otp sent to your phone");
        }
        try {
          var verificationResult = await this.state.confirmationResult.confirm(
            values.otp
          ); // ===google===
          var credential = window.firebase.auth.PhoneAuthProvider.credential(
            this.state.confirmationResult.verificationId,
            values.otp
          ); // ===google===
        } catch (error) {
          this.setState({
            errors: {
              ...this.state.errors,
              otp: "Invalid otp",
            },
          });
          console.log(error, this.state);
          this.setState({ netReq: false });
          return;
        }
        var cuser = await window.firebase // ===google===
          .auth() // ===google===
          .currentUser.getIdToken(/* forceRefresh */ true); // ===google===
        //   .then(function(idToken) {
        //     // Send token to your backend via HTTPS
        //     // ...
        //   }).catch(function(error) {
        //     // Handle error
        //   });
        console.log(cuser); // ===google===
        const userBody = {
          phoneNumber: values.phoneNumber,
          // name: values.name,
          password: values.password,
          // class: values.class,
          otp: values.otp,
          idToken: cuser, // ===google===
        };
        if (window.location.host.includes("valleyoak")) {
          userBody.instituteId = "5e521e0da587c5bcefdb7c90";
          userBody.branchid = "5e521e0da587c5bcefdb7c90_1";
        }
        let res = await changePassword(userBody);

        if (res.data.status !== null && res.data.status === "FAILURE") {
          alert(res.data.message);
        } else {
          this.setState({ passwordChanged: true });
          // localStorage.setItem("isAuthenticated", true);
          store.remove("firstLogin");
          // history.push("/home");
          setTimeout(() => {
            history.push("/sign-in");
          }, 1000);
        }
        this.setState({ netReq: false });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        serviceError: error,
        netReq: false,
      });
    }
  };
  render() {
    const { classes } = this.props;
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      isLoading,
    } = this.state;

    // const showEmailError = touched.email && errors.email;
    // const showPasswordError = touched.password && errors.password;

    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.signInBg}
          >
            <Grid item xs={12} lg={7}>
              <Hidden mdDown>
                <Suspense fallback={<NetworkProgress />}>
                  <LazyLoadImages
                    alt="logo"
                    style={{ marginTop: "-5%" }}
                    src={this.state.logo}
                  />
                </Suspense>
              </Hidden>
            </Grid>

            <Grid item xs={12} sm={10} lg={5} style={{ minHeight: "100%" }}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{ minHeight: "100%", minWidth: "320px" }}
              >
                {this.state.miniDimensions && (
                  <Grid item xs={12}>
                    <Suspense fallback={<NetworkProgress />}>
                      <LazyLoadImages
                        alt="logo"
                        src={this.state.mini}
                        width={
                          this.state.miniDimensions.width
                            ? this.state.miniDimensions.width
                            : "100px"
                        }
                        height={
                          this.state.miniDimensions.height
                            ? this.state.miniDimensions.height
                            : "100px"
                        }
                      />
                    </Suspense>

                    <br />

                    <br />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Typography component="div" variant="h3" color="textPrimary">
                    Change Password
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.separator} />
                </Grid>
                {/* registered phone */}
                <Grid item xs={12}>
                  <br />
                  <InputBase1
                    disabled={this.state.showOTP}
                    placeholder="Registered phone number"
                    name="phoneNumber"
                    onChange={(event) =>
                      this.handleFieldChange("phoneNumber", event.target.value)
                    }
                    type="text"
                    value={values.phoneNumber}
                    variant="outlined"
                  />
                </Grid>
                {/* password */}
                <Grid item xs={12}>
                  <InputBase1
                    style={{ marginTop: "20px" }}
                    disabled={this.state.showOTP}
                    placeholder="New password"
                    name="password"
                    onChange={(event) =>
                      this.handleFieldChange("password", event.target.value)
                    }
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                </Grid>
                {/* confirm */}
                <Grid item xs={12}>
                  <InputBase1
                    style={{ marginTop: "20px" }}
                    disabled={this.state.showOTP}
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    onChange={(event) =>
                      this.handleFieldChange(
                        "confirmPassword",
                        event.target.value
                      )
                    }
                    type="password"
                    value={values.confirmPassword}
                    variant="outlined"
                  />
                  {/* show otp */}

                  {this.state.showOTP && (
                    <Grid item xs={12}>
                      <br />
                      <InputBase1
                        placeholder="Please Enter the OTP"
                        name="otp"
                        onChange={(event) =>
                          this.handleFieldChange("otp", event.target.value)
                        }
                        type="text"
                        value={values.otp}
                        variant="outlined"
                      />

                      {errors.otp !== null && (
                        <Typography variant="body2">{errors.otp}</Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
                {/* button  */}
                {this.state.netReq ? (
                  <Grid item xs={12}>
                    <br />
                    <CustomProgress />
                    <br />{" "}
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={12}>
                  <br />
                  <Button
                    size="large"
                    disabled={noForgetPwdInstitutes[window.location.hostname]}
                    onClick={this.handlecreateNewPassword}
                    variant="contained"
                    color="primary"
                    id="forgot-button"
                  >
                    {this.state.showOTP ? "Change Now" : "Send OTP"}
                  </Button>
                </Grid>
                {/* remember  */}
                <Grid item xs={12}>
                  {store.get("firstLogin") ? (
                    this.state.passwordChanged && (
                      <Typography variant="subtitle2" color="textSecondary">
                        <span className={classes.link}>
                          Password Changed Successfully
                        </span>
                        &nbsp;
                        <Typography component="span" variant="subtitle1">
                          <Link className={classes.link} to="/sign-in">
                            Login
                          </Link>
                        </Typography>
                      </Typography>
                    )
                  ) : (
                    <Typography variant="subtitle2" color="textSecondary">
                      {!this.state.passwordChanged ? (
                        "Remember Password ?"
                      ) : (
                        <span className={classes.link}>
                          Password Changed Successfully
                        </span>
                      )}{" "}
                      &nbsp;
                      <Typography component="span" variant="subtitle1">
                        <Link className={classes.link} to="/sign-in">
                          Login
                        </Link>
                      </Typography>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      </ThemeProvider>
    );
  }
}

createNewPassword.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(style)
)(createNewPassword);
