
function calculateDates(selectedRange) {
  const currentDate = new Date();
  const startDate = new Date(currentDate);

  if (selectedRange === "one week") {
    startDate.setDate(currentDate.getDate() - 7);
  } else if (selectedRange === "two weeks") {
    startDate.setDate(currentDate.getDate() - 14);
  } else if (selectedRange === "1 month") {
    startDate.setMonth(currentDate.getMonth() - 1);
  } else if (selectedRange === "3 months") {
    startDate.setMonth(currentDate.getMonth() - 3);
  } else if (selectedRange === "6 months") {
    startDate.setMonth(currentDate.getMonth() - 6);
  } else if (selectedRange === "1 year") {
    startDate.setFullYear(currentDate.getFullYear() - 1);
  }

  const endDate = currentDate;

  // Format dates as year-month-day
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  };
}

export default calculateDates;
