import { CircularProgress, createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { getCourseSubjects, getMeetings } from "apis/videoService";
import React, { Component, Fragment } from "react";
// @material-ui/core components
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import Header from "../Header";
import "./VideoClasses.css";
import VideoClassesMobile from "./VideoClassesMobile";
const styles = (theme) =>
  createStyles({
    videoBg: {
      minHeight: "90vh",
      height: "max-content",
      overflow: "scroll",
      minWidth: "100vw",
      background: "#F0F3F5",
    },
    videoCount: {
      color: "#31456A",
      fontSize: "30px",
      lineHeight: "37px",
      fontWeight: "bold",
      marginLeft: "5px",
    },
    "@media(max-width: 600px)": {
      videoCount: {
        marginLeft: "2%",
      },

      // video: {
      //   margin: "2%",
      //   width: "97vw",
      // },
      // videoTopic: {
      //   width: "50%",
      // },
    },
    selectMenu1: {
      background: "#EFF2F4",
    },
    // videoTopic: {
    //   overflow: "hidden",
    //   whiteSpace: "nowrap",
    //   textOverflow: "ellipsis",
    //   color: "#31456a",
    //   fontWeight: "600",
    //   fontSize: "16px",
    // },
    videoPlayer: {
      marginTop: "20px",
      height: "100%",

      // width: '100%',
      minHeight: "400px",
      overflow: "scroll",
    },
    watchNow: {
      marginTop: "20px",
      textTransform: "none",
      height: "56px",
      width: "200px",
      background: "#08C5A7",
      boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
      borderRadius: "16px",
      // fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "18px",

      /* or 18px */
      textAlign: "center",
      color: "#FFFFFF",
      "&:hover": {
        background: "#0eab8f",
      },
    },
    customTabRoot: {
      color: "#08c5a7",
      textTransform: "capitalize",
      height: "34px",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
    },
    customTabIndicator: {
      background: "#08c5a7",
    },
    // video: {
    //   margin: "10px 5px",
    //   padding: "13px 20px",
    //   background: "#EFF2F4",
    //   display: "flex",
    //   justifyContent: "space-between",
    //   "&:hover": {
    //     background: "#FAFAFA",
    //     boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    //     borderRadius: "13px",
    //   },
    //   // width: "100vw",
    //   // boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    //   boxSizing: "border-box",
    //   // fontWeight: '600',
    //   lineHeight: "22px",
    //   height: "50px",
    //   // margin-right: 2%;
    //   borderRadius: "16px",
    //   // text-transform: none;
    // },
  });
const CustomProgress = withStyles({
  root: {
    color: "#60c6a8",
  },
})(CircularProgress);

class VideoClasses extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      selectedValue: 0,
      selectedSubject: 0,
      meetingResp: null,
      netReq: false,
      videos: [],
    };
  }

  changeSubject = (event, subject) => {
    console.log(subject);
    this.setState({ selectedSubject: subject });
    this.getVideos(subject);
  };
  handleTypeSelect = (event) => {
    // console.log();
    this.setState({ selectedSubject: event.target.value });
    this.getVideos(event.target.value);
  };

  getVideos = async (subject) => {
    let subMap = {};

    const course = store.get("user").course;
    const classVal = store.get("user").classVal;
    const courseClass = classVal + "_" + course;

    this.setState({ netReq: true });
    let instituteId = store.get("user").institute;

    if (store.get("user").level) {
      instituteId = [store.get("user").institute, store.get("user").level].join(
        "_"
      );
    }

    if (store.get("user") && course && classVal) {
      let subjects = await getCourseSubjects(courseClass);
      if (subjects) {
        subjects = subjects[courseClass].subjects;

        if (courseClass) this.setState({ subjects: subjects });

        subjects.map((sub, index) => {
          subMap[index] = sub.value;
        });

        // this.setState({subjectsMap: subMap})
        // console.log(this.state);
      }
    } else {
      subMap = {
        0: "M",
        1: "P",
        2: "C",
        3: "G",
      };
      this.setState({
        subjects: [
          { name: "MATHEMATICS", value: "M" },
          { name: "PHYSICS", value: "P" },
          { name: "CHEMISTRY", value: "C" },
          { name: "GENERAL", value: "G" },
        ],
      });
    }

    this.setState({ subjectsMap: subMap });
try{
    const val = await getMeetings({
      instituteId: instituteId || "mockInstituteId",
      subject: subMap[subject],
    });
    console.log(val);
    if(val)
     this.setState({ videos: val.data.payload, netReq: false });
  }catch(err){
  console.log(err)    
  }
  };

  componentWillUnmount() {}
  componentDidMount() {
    this.getVideos(this.state.selectedSubject);
  }

  render() {
    const { classes, history } = this.props;
    // if(store.get("user")&& store.get("user").institute=="5d72f343059f3004e1ceaa81")
    //   // history.push()
    //   history.push('/interactive-classes');
    return (
      <Fragment>
        {/* <Header /> */}
        <VideoClassesMobile />
      </Fragment>
    );
  }
}

export default compose(withRouter)(VideoClasses);
