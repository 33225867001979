import React, { useState } from 'react';
// material ui
import makeStyles from '@material-ui/styles/makeStyles';
import { ThemeProvider } from '@material-ui/core/styles';
// onesaz chat
// import ChatApp from '@onesaz/chat';
//theme
import theme from '../../assets/theme';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100vh - 38px)',
    width: '100vw',
    backgroundColor: '#dddbd1',
    paddingTop: '19px',
    paddingBottom: '19px'
  },
  colorBox: {
    height: '147px',
    width: '100%',
    backgroundImage: 'linear-gradient(to right, #039be5, #00bfa5)',
    position: 'absolute',
    top: '0px',
    left: '0px'
  }
}))

const ChatPage = ({history}) => {
  const classes = useStyles();
  const [isAppInitialized, setStatus] = useState(false);

  const handleInitializationStatus = (bool) => {
    setStatus(bool);
  }

  const handleExitClick = () => {
    history.push('/home')
  }

  const rootActions = {
    status: handleInitializationStatus,
    exit: handleExitClick
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        {
          isAppInitialized ? <div className={classes.colorBox}></div> : null
        }
        {/* <ChatApp rootActions={rootActions}/> */}
      </div>
    </ThemeProvider>
  )
}

export default ChatPage;