import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  Dialog,
  IconButton,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Button from "components/CustomButtons/Button.jsx";
import { makeStyles } from "@material-ui/core/styles";
import { getUser } from "../apis/UserService";
import useFetch from "../hooks/useFetch";
import store from "store";
import { useReactToPrint } from "react-to-print";
import { Print } from "@material-ui/icons";
import LogoURLMap from "../components/LogoURLMap";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";

const isEmpty = (obj) =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object;

const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid #e6e6e6",
    padding: "20px",
    marginTop: "20px",
    margin: "10px",
    width: "775px",
  },
  headerImage: {
    width: "200px",
    height: "200px",
  },
  watermark: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // Center the watermark
    opacity: 0.1, // Make it transparent
    zIndex: 0, // Ensure it stays behind the main content
    width: "500px", // Adjust size according to your preference
    height: "500px",
    pointerEvents: "none", // Make it unclickable
  },
  headerTitle: {
    fontSize:
      store.get("user").institute === "5ea3572fe301133d0a73e9e0"
        ? "50px"
        : "30px",
    margin: 0,
    fontWeight: "bold",
  },
  headerSubtitle: {
    fontSize: "25px",
    marginTop: "-20px",
    fontWeight: 500,
    // textAlign: "center"
  },
  divider: {
    margin: "10px 0",
    borderTop: "2px solid black",
  },
  formTitle: {
    marginBottom: "20px",
    fontWeight: "bold",
  },
  formSubtitle: {
    fontWeight: "bold",
    marginBottom: "20px",
  },
  dottedLine: {
    width: "100%",
    borderBottom: "1px dotted #000",
    // padding: "8px 0",
    fontSize: "14px",
    fontWeight: "bold",
    minHeight: "15px", // Ensure consistent height
    marginLeft: "20px", // Adjust this value as needed for alignment
  },
  signature: {
    textDecoration: "underline",
  },
}));

const EnquiryForm = ({ openPrintDialog, handleClose, formatAddress }) => {
  const classes = useStyles();
  const [l, e, response, getUserData, reset] = useFetch(getUser);
  const instId = store.get("user").institute;
  const user = store.get("user")._id;
  const [data, setData] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    getUserData(user);
  }, []);

  useEffect(() => {
    if (response) {
      setData(response);
    }
  }, [response]);

  const ComponentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => ComponentRef.current,
  });
  const address = data && data.address ? data.address : "";

  const firstLine = address.length > 40 ? address.substring(0, 40) : address;
  const secondLine = address.length > 40 ? address.substring(40) : "";

  // const onButtonClick = () => {
  //   const input = document.getElementById("resultsTable");
  //   const totalPages = Math.ceil(input.offsetHeight / input.clientHeight);
  //   html2canvas(input).then(canvas => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF();
  //     let offset = 0;
  //     for (let i = 0; i < totalPages; i++) {
  //       const pageData = canvas.toDataURL("image/png", 1.0);
  //       pdf.addImage(pageData, "PNG", 10, 10 + offset, 190, 0);
  //       offset -= 235;
  //       if (i < totalPages - 1) {
  //         pdf.addPage();
  //       }
  //     }

  //     pdf.save("download.pdf");
  //   });
  // };
  // const onButtonClick = () => {
  //   const input = document.getElementById("resultsTable");

  //   html2canvas(input, { scale: 2 }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();

  //     const imgWidth = pdfWidth;
  //     const imgHeight = (canvas.height * pdfWidth) / canvas.width;

  //     let heightLeft = imgHeight;
  //     let position = 0;

  //     pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //     heightLeft -= pdfHeight;

  //     while (heightLeft > 0) {
  //       position = heightLeft - imgHeight;
  //       pdf.addPage();
  //       pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //       heightLeft -= pdfHeight;
  //     }

  //     pdf.save("download.pdf");
  //   }).catch((error) => {
  //     setError("Failed to generate PDF");
  //     console.error(error);
  //   });
  // };

  if (l) {
    return <Typography>Loading...</Typography>; // Display while loading data
  }
  return (
    <Dialog open={openPrintDialog} onClose={handleClose} maxWidth="lg">
      <Grid container justify="flex-end">
        <IconButton onClick={handlePrint}>
          <Print color="info" />
        </IconButton>
        &nbsp;
        {/* <IconButton onClick={onButtonClick}>
          <CloudDownloadIcon color="primary" />
        </IconButton>
        &nbsp; */}
        <Button round onClick={handleClose} color="secondary">
          Close
        </Button>
      </Grid>
      <div ref={ComponentRef} id="resultsTable">
        <Container className={classes.container}>
          <img
            src={LogoURLMap[instId].url} // Use the logo URL from your LogoURLMap
            alt="Watermark Logo"
            className={classes.watermark}
          />
          {/* Header Section */}
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <img
                src={LogoURLMap[instId].url}
                alt="MIITY logo"
                className={classes.headerImage}
              />
            </Grid>
            <Grid item xs={8} conatiner justifyContent="center">
              <Typography className={classes.headerTitle}>
                {LogoURLMap[instId].title}
              </Typography>
              &nbsp;&nbsp;&nbsp;
              <Typography variant="h6" className={classes.headerSubtitle}>
                {LogoURLMap[instId].subTitle}
              </Typography>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={8}>
              <Typography
                variant="h5"
                align="center"
                className={classes.formTitle}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <u>ENQUIRY FORM</u>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" align="right">
                Reg No:<b>{data && data.registrationNo}</b>
              </Typography>
            </Grid>
          </Grid>

          {/* Form Fields */}
          <Grid container spacing={3}>
            {/* Name of the student */}
            <Grid item xs={12}>
              <Typography className={classes.label}>
                1.&nbsp; Name of the student:
              </Typography>
              <div className={classes.dottedLine}>
                {data && data.fullName
                  ? `${data.fullName.toUpperCase()} ${
                      data.lastName ? data.lastName.toUpperCase() : ""
                    }`
                  : ""}
              </div>
            </Grid>

            {/* Name of the Father and Occupation */}
            <Grid item container xs={6}>
              <Grid item xs={5.5}>
                <Typography className={classes.label}>
                  2.&nbsp; Name of the Father:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.dottedLine}>
                  {(data &&
                    data.personalDetails &&
                    data.personalDetails.fatherName &&
                    data.personalDetails.fatherName.toUpperCase()) || (
                    <>&nbsp;</>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              <Grid item xs={5.5}>
                <Typography className={classes.label}>Occupation: </Typography>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.dottedLine}>
                  {(data &&
                    data.personalDetails &&
                    data.personalDetails.fatherOccupation &&
                    data.personalDetails.fatherOccupation.toUpperCase()) || (
                    <>&nbsp;</>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              <Grid item xs={5.5}>
                <Typography className={classes.label}>
                  3.&nbsp; Name of the Mother:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.dottedLine}>
                  {(data &&
                    data.personalDetails &&
                    data.personalDetails.motherName &&
                    data.personalDetails.motherName.toUpperCase()) || (
                    <>&nbsp;</>
                  )}
                </div>
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={5.5}>
                <Typography className={classes.label}>Occupation: </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <div className={classes.dottedLine}>
                  {(data &&
                    data.personalDetails &&
                    data.personalDetails.motherOccupation &&
                    data.personalDetails.motherOccupation.toUpperCase()) || (
                    <>&nbsp;</>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              <Grid item xs={5.5}>
                <Typography className={classes.label}>
                  4.&nbsp; Whatsapp Number:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.dottedLine}>
                  {(data &&
                    data.personalDetails &&
                    data.personalDetails.fatherNumber) || <>&nbsp;</>}
                </div>
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={5.5}>
                <Typography className={classes.label}>
                  Alternate Number:{" "}
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <div className={classes.dottedLine}>
                  {(data &&
                    data.personalDetails &&
                    data.personalDetails.alternateMobileNo) || <>&nbsp;</>}
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" className={classes.label}>
                5.&nbsp; <b>Address for Communication:</b>
              </Typography>
              &nbsp;
              <div className={classes.dottedLine} style={{ marginTop: "5px" }}>
                {firstLine.toUpperCase() || <>&nbsp;</>}
              </div>
              &nbsp;
              <div className={classes.dottedLine}>
                {secondLine.toUpperCase() || <>&nbsp;</>}
              </div>
            </Grid>
          </Grid>

          {/* New Section: Details about the School Presently Studying */}
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.label}>
                6.&nbsp; <b>Details about the School Presently Studying:</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.label}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;School name and Address:
              </Typography>
              &nbsp;
              <div className={classes.dottedLine}>
                {`${
                  data &&
                  data.preSchoolDetails &&
                  !isEmpty(data.preSchoolDetails.school)
                    ? data.preSchoolDetails.school.toUpperCase()
                    : ""
                } ${data &&
                  data.preSchoolDetails &&
                  data.preSchoolDetails.address &&
                  ","}
                  
      ${(data &&
        data.preSchoolDetails &&
        formatAddress(data.preSchoolDetails.address).toUpperCase()) ||
        ""}`}
              </div>
            </Grid>

            <Grid item xs={12} display="flex">
              <Typography className={classes.label}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Board (School is affiliated to):
              </Typography>
              &nbsp;
              <div className={classes.dottedLine}>
                {(data &&
                  data.preSchoolDetails &&
                  data.preSchoolDetails.board &&
                  data.preSchoolDetails.board.toUpperCase()) || <>&nbsp;</>}
              </div>
            </Grid>

            {/* <Grid item xs={12}>
              <Typography className={classes.label}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                contact number:
              </Typography>
              <div className={classes.dottedLine}>
                {(data &&
                  data.preSchoolDetails &&
                  data.preSchoolDetails.principalDetail &&
                  data.preSchoolDetails.principalDetail.toUpperCase()) || (
                  <>&nbsp;</>
                )}
              </div>
            </Grid> */}

            <Grid item xs={6}>
              <Typography className={classes.label}>
                7. &nbsp;Reference name:{" "}
                <b className={classes.dottedLine}>
                  {(data && data.referral && data.referral.toUpperCase()) || (
                    <>&nbsp;</>
                  )}
                </b>
              </Typography>
            </Grid>
            {/* <Grid item xs={6}>
              <Typography className={classes.label}>
                &nbsp;Mobile No:
              </Typography>
              <div className={classes.dottedLine}>
                {(data &&
                  data.personalDetails &&
                  data.personalDetails.alternateMobileNo) ||
                  "" ||
                  (data &&
                    data.personalDetails &&
                    data.personalDetails.fatherNumber) || <>&nbsp;</>}
              </div>
            </Grid> */}
          </Grid>

          {/* Signature */}
          <Box mt={12} textAlign="right">
            <Typography className={classes.signature}>
              Signature of the employee
            </Typography>
          </Box>
        </Container>
      </div>
    </Dialog>
  );
};

export default EnquiryForm;
