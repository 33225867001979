import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { useParams, useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    background: '#EBEFF3'
  },
  root: {
    position: 'absolute',
    maxWidth: '100%',
    height: 'auto',
    background: 'url(avatar.jpg)',
    left: 0,
    right: 0,
    [theme.breakpoints.up('sm')]: {
      top: 73
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 50
    },
    top: 0
  },
  subroot: {
    width: '100%',
    top: '264px', // Adjusted to overlap the root card by a few pixels
    height: 'auto',
    position: 'absolute',
    background: '#EBEFF3',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '28px 28px 0 0',
    padding: '20px ',
    left: 0,
    margin: 0
  },
  CardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0',
    width: '100%',
    marginBottom: 0
  },
  media: {
    height: 300
  },
  title: {
    width: '100%',
    color: '#2A2A2A',
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '28px'
  },
  qua_exp: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'row'
  },
  qual: {
    width: '50%', // Adjusted to take 50% area each
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  expo: {
    width: '50%', // Adjusted to take 50% area each
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  Qualification: {
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    color: '#2A2A2A',
    fontSize: '16px'
  },
  qualification: {
    fontFamily: 'sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    color: '#2A2A2A'
  },
  Experience: {
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#2A2A2A'
  },
  experience: {
    fontFamily: 'sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    color: '#2A2A2A'
  },
  lang: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    paddingLeft: 0
  },
  LanguagesKnown: {
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#2A2A2A'
  },
  languagesknown: {
    fontFamily: 'sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    color: '#2A2A2A'
  },
  bio: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    paddingLeft: 0
  },
  About: {
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#2A2A2A'
  },
  about: {
    fontFamily: 'sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    color: '#2A2A2A',
    textAlign: 'justify'
  },
  divider: {
    width: '100%',
    margin: '8px 0'
  }
}))

const TeacherProfile = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [teacherDetails, setTeacherDetails] = useState([])
  const { id } = useParams()
  const history = useHistory()

  // useEffect(() => {
  //   // Adding styles for full height and hidden overflow
  //   const rootElement = document.getElementById('root')
  //   rootElement.style.height = '100%'
  //   document.body.style.height = '100%'
  //   document.documentElement.style.height = '100%'
  //   document.body.style.margin = '0'
  //   document.body.style.padding = '0'
  //   // document.body.style.overflow = 'hidden';

  //   return () => {
  //     // Cleanup styles when component is unmounted
  //     rootElement.style.height = ''
  //     document.body.style.height = ''
  //     document.documentElement.style.height = ''
  //     document.body.style.margin = ''
  //     document.body.style.padding = ''
  //     document.body.style.overflow = ''
  //   }
  // }, [])

  useEffect(() => {
    const TeachersData = [
      {
        id: 1,
        avatar: '/images/avatar1.jpg',
        name: 'Johnson',
        subject: 'Mathematics',
        phone: '1234567890',
        email: 'jhonson@gmail.com',
        qualification: 'B.Tech',
        experience: '5 Years',
        languagesknown: 'Telugu,Hindi,English',
        about:
          'Lorem ipsum dolor sit amet consectetur. Vehicula volutpat vitae diam semper posuere cursus consectetur.'
      },
      {
        id: 2,
        avatar: '/images/avatar2.jpg',
        name: 'Jane Smith',
        subject: 'Physics',
        phone: '1234567890',
        email: 'janesmith@gmail.com',
        qualification: 'B.Tech',
        experience: '5Years',
        languagesknown: 'Telugu,Hindi,English',
        about:
          'Lorem ipsum dolor sit amet consectetur. Vehicula volutpat vitae diam semper posuere cursus consectetur.'
      },
      {
        id: 3,
        avatar: '/images/avatar3.jpg',
        name: 'Emily Johnson',
        subject: 'Chemistry',
        phone: '1234567890',
        email: 'emilyjohnson@gmail.com',
        qualification: 'B.Tech',
        experience: '5Years',
        languagesknown: 'Telugu,Hindi,English',
        about:
          'Lorem ipsum dolor sit amet consectetur. Vehicula volutpat vitae diam semper posuere cursus consectetur.'
      },
      {
        id: 4,
        avatar: '/images/avatar4.jpg',
        name: 'John Doe',
        subject: 'English',
        phone: '1234567890',
        email: 'jhondoe@gmail.com',
        qualification: 'B.Tech',
        experience: '5Years',
        languagesknown: 'Telugu,Hindi,English',
        about:
          'Lorem ipsum dolor sit amet consectetur. Vehicula volutpat vitae diam semper posuere cursus consectetur.'
      }
    ]
    setTeacherDetails(TeachersData)
  }, [])

  const teacherProfile = teacherDetails.find(
    teacher => teacher.id === parseInt(id)
  )

  if (!teacherProfile) {
    return <div>Teacher not found</div>
  }

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Card>
          <CardMedia className={classes.media} image={teacherProfile.avatar} />
        </Card>
      </div>
      <div className={classes.subroot} sx={{ margin: 0 }}>
        <CardContent className={classes.CardContent}>
          <Typography className={classes.title}>
            {teacherProfile.name}
          </Typography>
          <Typography>{teacherProfile.subject}</Typography>
          <Typography>{teacherProfile.phone}</Typography>
          <Typography>{teacherProfile.email}</Typography>
        </CardContent>
        <Divider className={classes.divider} />
        <div className={classes.qua_exp}>
          <div className={classes.qual}>
            <Typography className={classes.Qualification}>
              Qualification
            </Typography>
            <Typography className={classes.qualification}>
              {teacherProfile.qualification}
            </Typography>
          </div>
          <div className={classes.expo}>
            <Typography className={classes.Experience}>Experience</Typography>
            <Typography className={classes.experience}>
              {teacherProfile.experience}
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <CardContent className={classes.lang}>
          <Typography className={classes.LanguagesKnown}>
            Languages Known
          </Typography>
          <Typography className={classes.languagesknown}>
            {teacherProfile.languagesknown}
          </Typography>
        </CardContent>
        <Divider className={classes.divider} />
        <CardContent className={classes.bio}>
          <Typography className={classes.About}>About</Typography>
          <Typography className={classes.about}>
            {teacherProfile.about}
          </Typography>
        </CardContent>
      </div>
    </div>
  )
}

export default TeacherProfile
