import Fab from "@material-ui/core/Fab";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
const styles = (theme) => ({
  Fab: {
    width: "33px",
    height: "33px",
    margin: "6px",
    background: "white",
  },
});
function typeInTextarea(el, newText) {
  if (
    (el.value != undefined &&
      el.value != null &&
      (newText == "." && el.value.includes("."))) ||
    (newText == "-" && el.value.includes("-"))
  ) {
    //   alert(  el.value.includes("."));
    return el.value;
  }
  if (newText == "clear") {
    el.value = "";
    return el.value;
  }
  var start = el.selectionStart;
  var end = el.selectionEnd;
  // var isnegative = false;
  // if(el.value.includes("-"))
  //     {
  //         el.value = el.value.replace("-",'')
  //         isnegative = true;
  //     }
  var text = el.value;
  var before = text.substring(0, start);
  if (newText == "backspace") {
    before = text.substring(0, start > 0 ? start - 1 : 0);
    newText = "";
  }
  var after = text.substring(end, text.length);
  if (newText == "-" && el.selectionStart == 0) {
    el.value = "-" + el.value;
  } else if (newText != "-" && newText != ".") {
    el.value = before + newText + after;
  }
  // only two decimals are allowed
  if (el.value.includes(".") && el.value.split(".").length == 2) {
    console.log(el.value.split(".").length == 2);
    el.value =
      el.value.split(".")[1].length > 2 ? el.value.slice(0, -1) : el.value;
  } else if (newText != "-") {
    // check if placing . causes more than two decimals;
    if (newText == ".") {
      var dotpre = before + newText + after;
      el.value = dotpre.split(".")[1].length > 2 ? el.value : dotpre;
    } else {
      el.value = before + newText + after;
    }
  }
  //   this.state.current_question.markedAnswer=el.value;
  //   console.log(this.state.current_question.markedAnswer)
  //   this.forceUpdate();
  el.selectionStart = el.selectionEnd = start + newText.length;
  el.focus();

  return el.value;
}
// eslint-disable-next-line

function doGetCaretPosition(ctrl) {
  var CaretPos = 0;

  if (ctrl.selectionStart || ctrl.selectionStart == 0) {
    // Standard.
    CaretPos = ctrl.selectionStart;
  } else if (document.selection) {
    // Legacy IE
    ctrl.focus();
    var Sel = document.selection.createRange();
    Sel.moveStart("character", -ctrl.value.length);
    CaretPos = Sel.text.length;
  }

  return CaretPos;
}

function setCaretPosition(ctrl, pos) {
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);
  } else if (ctrl.createTextRange) {
    var range = ctrl.createTextRange();
    range.collapse(true);
    range.moveEnd("character", pos);
    range.moveStart("character", pos);
    range.select();
  }
}

class InputIntegerPanelWs extends React.Component {
  vacl = 10;
  constructor(props) {
    super(props);
    this._this = this;
    this.props = props;
    this.handleInteger = this.handleInteger.bind(this);
    this.typeInTextarea = this.typeInTextarea.bind(this);
    this.evenlis = null;
    this.state = { value: "hello", dot: false, minus: false };
    this.MoveLeft = this.MoveLeft.bind(this);
  }
  typeInTextarea() {
    typeInTextarea();
  }
  handleInteger(event) {
    if (event.target) {
      // eslint-disable-next-line

      this.state.current_question.markedAnswer = event.target.value;
    } else {
      var e = new InputEvent("input", {
        bubbles: true,
        cancelable: false,
        data: event,
      });
      let TargetInput = document.getElementById(
        "target-" + this.props.questionNumber
      );

      if (TargetInput != null) {
        if (this.evenlis == null) {
          this.evenlis = 1;
          TargetInput.addEventListener(
            "input",
            function(e) {
              if (!e.isTrusted) {
                // eslint-disable-next-line

                // var val = typeInTextarea(TargetInput, e.data);

                TargetInput.click();
              }
            },
            false
          );
          TargetInput.addEventListener("keydown", function(e) {
            var code = e.which || e.keyCode;
            if (code == "38") {
              // Up
            } else if (code == "40") {
              // Down
            } else if (code == "37") {
              // Left
              console.log(e.target.selectionStart, e.keyCode);
              setCaretPosition(
                TargetInput,
                e.target.selectionStart < 0 ? 0 : e.target.selectionStart - 1
              );
              //    e.target.selectionStart = e.target.selectionStart<0? 0: e.target.selectionStart-1
            } else if (code == "39") {
              // Right
              setCaretPosition(
                TargetInput,
                e.target.selectionStart < 0 ? 0 : e.target.selectionStart + 1
              );
              console.log(e.target.selectionStart);
            }
          });
        }
        TargetInput.dispatchEvent(e);
      }
    }
  }
  valueChanged = (event) => {
    console.log(this.props.questionNumber);
    // this.props.setIntegerValue.func(this.props.setIntegerValue.questionNumber,event.target.value);
    this.props.setIntegerValue(this.props.questionNumber, event.target.value);
  };

  MoveLeft(val) {
    let TargetInput = document.getElementById(
      "target-" + this.props.questionNumber
    );
    var event = new KeyboardEvent("keydown", {
      bubbles: true,
      cancelable: false,
      keyCode: val,
    });

    TargetInput.dispatchEvent(event);
  }
  MoveRight(val) {
    let TargetInput = document.getElementById("target");
    var event = new KeyboardEvent("keydown", {
      bubbles: true,
      cancelable: false,
      keyCode: val,
    });

    TargetInput.dispatchEvent(event);
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <input
          value={this.props.markedanswer}
          maxLength="0"
          style={{
            padding: "10px",
            width: "134px",
            border: "2px solid grey",
            borderRadius: "5px",
          }}
          type="text"
          id={"target-" + this.props.questionNumber}
          onChange={(event) => event.preventDefault()}
          onClick={this.valueChanged.bind(this)}
        />
        {/* <Hidden xsDown> */}
        <GridContainer
          direction="column"
          justify="space-between"
          style={{
            textAlign: "center",
            background: "#e2e2e2",
            maxWidth: "max-content",
            borderRadius: "10px",
            marginTop: "8px",
            padding: "10px 0px",
          }}
        >
          <GridItem style={{ textAlign: "center" }}>
            <Button
              style={{ color: "black", background: "white" }}
              onClick={() => this.handleInteger("backspace")}
            >
              {" "}
              Backspace{" "}
            </Button>
          </GridItem>
          <GridItem style={{ textAlign: "center" }}>
            <Fab
              className={classes.Fab}
              onClick={() => this.handleInteger("7")}
              size="small"
            >
              7
            </Fab>
            <Fab
              className={classes.Fab}
              onClick={() => this.handleInteger("8")}
              size="small"
            >
              8
            </Fab>
            <Fab
              className={classes.Fab}
              onClick={() => this.handleInteger("9")}
              size="small"
            >
              9
            </Fab>
          </GridItem>
          <GridItem style={{ textAlign: "center" }}>
            <Fab
              className={classes.Fab}
              onClick={() => {
                this.handleInteger("4");
              }}
              size="small"
            >
              4
            </Fab>
            <Fab
              className={classes.Fab}
              onClick={() => this.handleInteger("5")}
              size="small"
            >
              5
            </Fab>
            <Fab
              className={classes.Fab}
              onClick={() => this.handleInteger("6")}
              size="small"
            >
              6
            </Fab>
          </GridItem>
          <GridItem style={{ textAlign: "center" }}>
            <Fab
              className={classes.Fab}
              onClick={() => this.handleInteger("1")}
              size="small"
            >
              1
            </Fab>
            <Fab
              className={classes.Fab}
              onClick={() => this.handleInteger("2")}
              size="small"
            >
              2
            </Fab>
            <Fab
              className={classes.Fab}
              onClick={() => this.handleInteger("3")}
              size="small"
            >
              3
            </Fab>
          </GridItem>
          <GridItem style={{ textAlign: "center" }}>
            <Fab
              className={classes.Fab}
              onClick={() => this.handleInteger("0")}
              size="small"
            >
              0
            </Fab>
            <Fab
              className={classes.Fab}
              onClick={() => this.handleInteger(".")}
              size="small"
            >
              .
            </Fab>
            <Fab
              className={classes.Fab}
              onClick={() => this.handleInteger("-")}
              size="small"
            >
              -
            </Fab>
          </GridItem>
          <GridItem style={{ textAlign: "center" }}>
            <Button
              style={{ color: "black", background: "white" }}
              onClick={() => {
                this.MoveLeft(37);
              }}
              size="sm"
              round
            >
              {" "}
              <KeyboardArrowLeftIcon />
            </Button>
            <Button
              style={{ color: "black", background: "white" }}
              onClick={() => {
                this.MoveRight(39);
              }}
              size="sm"
              round
            >
              {" "}
              <KeyboardArrowRightIcon />
            </Button>
          </GridItem>
          <GridItem style={{ textAlign: "center" }}>
            <Button
              style={{ color: "black", background: "white" }}
              size="sm"
              round
              onClick={() => this.handleInteger("clear")}
            >
              {" "}
              &nbsp; &nbsp; &nbsp; Clear All &nbsp; &nbsp;&nbsp;{" "}
            </Button>
          </GridItem>
        </GridContainer>
        {/* </Hidden> */}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(InputIntegerPanelWs);
