import React from "react";
import { withStyles } from "@material-ui/core/styles";
import "../index.css";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Hidden from "@material-ui/core/Hidden";

// import ButtonGroup from '@material-ui/core/ButtonGroup';
const styles = (theme) => ({
  subjectPanel: {
    padding: "0px 15px",
  },
  // button: {
  //     margin: theme.spacing.unit*4,
  //     marginTop: '1em'
  //   },
  //   input: {
  //     display: 'none',
  //   },
  // subject: {
  //     width: 'auto',
  //     textAlign: 'center',
  //     margin: '12px',
  //     borderRadius: '2em',
  //     color: '#566573',
  //     fontSize: 20
  // },
  // activesubject: {
  // backgroundColor: 'orange',
  // color: 'white'
  // }
});
class Subjects extends React.Component {
  a = 0;
  clickme(index) {
    console.log("28", index);
    this.props.displayQuestion(index);
  }
  render() {
    // const { classes } = this.props;
    const props = this.props;
    // console.log(props);
    let prevSubjectCount = 0;
    const subIndex = {};
    if (props.qNumPerSubject)
      Object.keys(props.qNumPerSubject).forEach((sub) => {
        subIndex[sub] = prevSubjectCount + 1;
        prevSubjectCount += props.qNumPerSubject[sub];
      });

    return (
      //  <Grid container xs={12} sm={12} >
      <React.Fragment>
        {/* <Hidden smDown>  
                        <GridItem  xs={12} sm={3} className="jee-heading">
                            <span style={{marginLeft: '20px'}}>  
                              
                            </span> 
                            
                        </GridItem>
                  </Hidden>  */}

        <Hidden smUp>
          {props.qNumPerSubject &&
            Object.keys(props.qNumPerSubject).map((subject, index) => {
              // const { qNumPerSubject } = props;
              return (
                <GridItem
                  xs
                  sm={3}
                  key={subject}
                  style={{ textAlign: "center", paddingRight: "10px" }}
                >
                  <span
                    button
                    style={{
                      fontSize: "9px",
                      padding: "6px",
                      color: "white",
                      borderRadius: "25px",
                      backgroundColor: "#f19733",
                      fontWeight: "bold",
                    }}
                    size="sm"
                    color="warning"
                    round
                    onClick={() => {
                      this.clickme(subIndex[subject]);
                    }}
                  >
                    {subject}
                  </span>
                </GridItem>
              );
            })}
        </Hidden>
        <Hidden xsDown>
          {props.qNumPerSubject &&
            Object.keys(props.qNumPerSubject).map((subject, index) => {
              // const { qNumPerSubject } = props;
              return (
                <GridItem
                  xs
                  sm={3}
                  key={subject}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    size="sm"
                    color="warning"
                    round
                    onClick={() => this.clickme(subIndex[subject])}
                  >
                    {subject}
                  </Button>
                </GridItem>
              );
            })}
        </Hidden>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Subjects);
