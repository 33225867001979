import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ButtonCard from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import store from "store";
import Header from "./Header";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import axios from "axios";
const styles = (theme) => ({});
class StudentPremium extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    const isAuthenticated = store.get("user");
    let showLogin = false;
    if (!isAuthenticated) {
      showLogin = true;
    }
  }
  payment(type) {
    let payAmount = {
      MA: 6000,
      N: 6000,
      MAN: 9000,
    };
    let user = JSON.parse(window.localStorage.getItem("user"));
    console.log(user._id);
    var requestData = {
      _id: user._id,
      key: "97PIzxdO",
      txnid: "123456789",
      // color: "primary",
      hash: "",
      amount: payAmount[type],
      firstname: user["name"],
      email: "kurapati.muralikrish@gmail.com",
      phone: user["phoneNumber"],
      productinfo: JSON.stringify({
        _id: user._id,
        package: type,
      }),
      surl: window.location.origin + "/api/pay/success",
      furl: window.location.origin + "/paymentfailed",
      salt: "UhvE4JAdfK",
      mode: "dropout", // non-mandatory for Customized Response Handling
    };
    var Handler = {
      responseHandler: function(response) {
        console.log(response);
      },
      catchException: function(BOLT) {},
    };
    const ax = axios.create({
      baseURL: window.location.origin + "/api",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    ax.post("/pay/request", JSON.stringify(requestData)).then((res) => {
      console.log(res);
      if (res.data.status === "SUCCESS") {
        requestData.hash = res.data.hash;
        window.bolt.launch(requestData, Handler);
      }
    });
    //  window.bolt.launch( requestData , Handler );
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        {/* {this.showLogin && <Login></Login>} */}
        <GridContainer justify="space-evenly" style={{ marginTop: "5%" }}>
          <GridItem xs={12} sm={5} md={4}>
            <Card raised style={{ minHeight: "340px", marginBottom: "5%" }}>
              <h3 style={{ textAlign: "center" }}> JEE MAIN + ADVANCE</h3>
              <CardBody>
                <p>
                  {" "}
                  This package includes completed scheduled tests for yearly
                  plan and the best material to prepare
                </p>
                {/* <p>   </p> */}
                <br />
                <strong>
                  {" "}
                  Get this package at <s>Rs. 7000/- </s>, &nbsp;&nbsp; Rs.
                  6000/-{" "}
                </strong>
                <br /> <br />
                <ButtonCard
                  color="rose"
                  onClick={() => {
                    this.payment("MA");
                  }}
                >
                  {" "}
                  Proceed to pay{" "}
                </ButtonCard>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={4}>
            <Card raised style={{ minHeight: "340px", marginBottom: "5%" }}>
              <h3 style={{ textAlign: "center" }}> NEET</h3>
              <CardBody>
                <p>
                  {" "}
                  This package includes completed scheduled tests for yearly
                  plan and the best material to prepare
                </p>
                {/* <p>   </p> */}
                <br />
                <strong>
                  {" "}
                  Get this package at <s>Rs. 7500/- </s>, &nbsp;&nbsp; Rs.
                  6000/-{" "}
                </strong>
                <br /> <br />
                <ButtonCard
                  color="rose"
                  onClick={() => {
                    this.payment("N");
                  }}
                >
                  {" "}
                  Proceed to pay{" "}
                </ButtonCard>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={4}>
            <Card raised style={{ minHeight: "340px", marginBottom: "5%" }}>
              <h3 style={{ textAlign: "center", fontSize: "22px" }}>
                {" "}
                JEE MAIN + ADVANCE + NEET
              </h3>
              <CardBody>
                <p>
                  {" "}
                  This package includes completed scheduled tests for yearly
                  plan and the best material to prepare
                </p>
                {/* <p>   </p> */}
                <br />
                <strong>
                  {" "}
                  Get this package at <s>Rs. 11,500/- </s>, &nbsp;&nbsp; Rs.
                  9000/-{" "}
                </strong>
                <br /> <br />
                <ButtonCard
                  color="rose"
                  onClick={() => {
                    this.payment("MAN");
                  }}
                >
                  {" "}
                  Proceed to pay{" "}
                </ButtonCard>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

// export default StudentPremium;
export default compose(
  withRouter,
  withStyles(styles)
)(StudentPremium);
