import { applyMiddleware, createStore } from "redux";
import root_reducer from "../reducers/rootReducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../actions/rootSaga";

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

const storeRedux = createStore(root_reducer, applyMiddleware(...middleware));

// log data (only for development use)
storeRedux.subscribe(() => {
  if (process.env.NODE_ENV === "development") {
    console.log(
      "ITEMS_FROM_REDUX_STORE: ",
      storeRedux.getState(),
      process.env.NODE_ENV
    );
  }
});

sagaMiddleware.run(rootSaga);

const stateMapper = (state) => {
  return state;
};

export { storeRedux, stateMapper };
