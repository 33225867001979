import {
  Button,
  Grid,
  IconButton,
  Popover,
  Typography,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import store from "store";
import analyticsAxios from "../../../apis/analyticsAxios";
import examAxios from "../../../apis/examServerAxios";
import CustomButton from "../../../components/CustomButtons/CustomButton";
import Dropdown from "../../../components/CustomDropdown/Dropdown";
import AlertComponent from "../../AlertComponent";
import ExamSummary from "../../ExamSummary";
import NetworkProgress from "../../NetworkProgress";
import { analyticsConstantData } from "../../nta-constants";
import DisplayQuesNosPopOver from "./DisplayQuesNosPopOver";
import HandlingUserAnswerByType from "./HandlingUserAnswerByType";
import { useStyles } from "./Styles";
const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000"
    : window.location.origin;

const CustomQuestionNosDispaly = ({
  displayname,
  test_type,
  user,
  examDuration,
  examName,
  pdfLink,
}) => {
  const prop = store.get("currentExam") || {};
  let questions = [],
    studentAnswers = [];
  let timer;
  let remainingTime = Date.now();
  const classes = useStyles();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [allStudentAns, setAllStudentAns] = useState([]);
  const [displayTime, setDisplayTime] = useState(0);
  const [ifPaperLoading, setIfPaperLoading] = useState(false);
  const [sub, setSub] = React.useState(null);
  const [subjects, setSubjects] = useState({});
  const [current_question, setCurrentQuestion] = useState({});
  const [ci, setCi] = useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clickedOption, setClickedOption] = useState("");
  const [questionPaper, setQuestionPaper] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [confirmationType, setConfirmationType] = useState("SUMMARY");
  const [statusdisplay, setStatusdisplay] = useState({
    NOT_VISITED: 0,
    NOT_ANSWERED: 0,
    ANSWERED: 0,
    MARKED_FOR_REVIEW: 0,
    ANSWERED_MARKED_FOR_REVIEW: 0,
  });
  // get exam paper and student answers and arrange as per Code
  const getExamPaper = async (test, userId) => {
    try {
      setIfPaperLoading(true);
      const params = { student_id: userId, test_name: test };
      const allQuestions = (await examAxios.post("/exams/writetest", params))
        .data;
      if (allQuestions.payload.test_status === "FINISHED") {
        setTimeout(() => {
          window.parent.location.replace(`${baseURL}/home`);
        }, 1000);
        return;
      }
      let qNumPerSubject = {};
      questions = allQuestions.payload.Items.map((item) => {
        if (!item.status) {
          item.status = "NOT_VISITED";
        }
        if (qNumPerSubject[item.subject] == undefined) {
          qNumPerSubject[item.subject] = 1;
        } else {
          qNumPerSubject[item.subject] += 1;
        }
        return item;
      });
      // arrange question as per code A
      if (allQuestions.payload.code !== undefined) {
        const examCode = "Code" + allQuestions.payload.code;
        if (allQuestions.payload[examCode] !== undefined) {
          const codeQuestionsOrder = allQuestions.payload[examCode].split(",");
          const rearrangeQuestions = [];
          codeQuestionsOrder.forEach((q_num) => {
            rearrangeQuestions.push(
              ...questions.filter((q) => q.question_number == q_num)
            );
          });
          questions = rearrangeQuestions;
        }
      }
      studentAnswers = (await examAxios.post("/exams/studentanswers", params))
        .data.payload.Items[0].answers;
      let reArrangeStudentAnswers = []; // this is as per question order;
      questions.forEach((q) => {
        reArrangeStudentAnswers.push(
          ...studentAnswers.filter(
            (val) => val.question_number == q.question_number
          )
        );
      });
      if (questions.length > 0) {
        studentAnswers = reArrangeStudentAnswers;
        studentAnswers = studentAnswers.map((answer, index) => {
          if (!answer.status) {
            answer.status = "NOT_VISITED";
          }
          questions[index].status = answer.status;
          questions[index].markedAnswer = answer.markedAnswer;
          // console.log(questions[index].markedAnswer, "markedAnswer");
          return answer;
        });
        setAllStudentAns(studentAnswers);
        setQuestionPaper(questions);
        setSubjects(qNumPerSubject);
        setCurrentQuestion(questions[0]);
        displayQuestion(1);
      }
      let res = allQuestions;
      remainingTime =
        Number(examDuration) +
        Number(
          moment(res.payload.time_started)
            .subtract(res.payload.time_check)
            .format("x")
        );
      timer = setInterval(() => {
        remainingTime = remainingTime - 1000;
        if (remainingTime < 0) {
          clearInterval(timer);
          setDisplayTime(0);
          setConfirmOpen(true);
          setAlertText("Exam time finished!");
          submit("YES");
          if (navigator.userAgent.includes("Android") && window.Android) {
            window.Android.submitPDFExam(
              `${baseURL}/${examName}/new-result?test=${displayname}&type=CUSTOM`
            );
          } else {
            setTimeout(() => {
              window.parent.location.replace(
                `${baseURL}/${examName}/new-result?test=${displayname}&type=CUSTOM`
              );
            });
          }
        } else {
          const seconds = remainingTime / 1000;
          const s = parseInt(seconds % 60) + "";
          const m = parseInt((seconds / 60) % 60) + "";
          const h = parseInt((seconds / (60 * 60)) % 24) + "";

          setDisplayTime(
            [h.padStart(2, "0"), m.padStart(2, "0"), s.padStart(2, "0")].join(
              ":"
            )
          );
        }
      }, 1000);
    } catch (err) {
      console.log(err);
    } finally {
      setIfPaperLoading(false);
    }
  };
  // current question number on screen
  const displayQuestion = (index, status = "NOT_ANSWERED") => {
    if (index < 1 || index > questionPaper.length) {
      return;
    }
    let update_c_q = {};
    update_c_q = questionPaper[index - 1];
    if (questionPaper[index - 1].status == "NOT_VISITED") {
      questionPaper[index - 1].status = status;
      // this has to change only when it is not visited
      // console.log(allStudentAns[index - 1], questionPaper[index - 1]);
      allStudentAns[index - 1].status = status;
      let update_opts = {
        body: JSON.stringify({
          student_id: user,
          test_name: examName,
          question_number: update_c_q.question_number,
          question_state: update_c_q.status,
          status: update_c_q.status,
          question_answer: 1,
          markedAnswer: update_c_q.markedAnswer,
          endDateTime: prop.endDateTime,
        }),
      };
      examAxios.post("/exams/updateanswer", update_opts.body).catch((err) => {
        console.log("error in saving the not_answered state", err);
      });
    }
    if (current_question)
      analyticsUPStream(
        current_question.question_number,
        "EXIT",
        current_question.status,
        current_question.markedAnswer,
        current_question.subject
      );
    // analytics
    analyticsUPStream(
      update_c_q.question_number
        ? update_c_q.question_number
        : questionPaper[index - 1].question_number,
      "DISPLAY",
      update_c_q.status,
      update_c_q.markedAnswer,
      update_c_q.subject
    );
    setCurrentQuestion(questionPaper[index - 1]);
    setCi(index);
  };
  // analytics api
  const analyticsUPStream = (
    q_no,
    type,
    status,
    markedAnswer = "NA",
    subject
  ) => {
    let danalyticsData = {
      ...analyticsConstantData,
      Data: {
        exam_name_and_student_id: [examName, user].join("_"),
        question_number: q_no,
        action: {
          type: type,
          status: status,
          markedAnswer: markedAnswer,
          subject: subject,
          timestamp: Date.now(),
        },
      },
    };
    analyticsAxios.post("", danalyticsData).catch((err) => {
      console.log("error in posting data to upstream", err);
    });
  };
  // ----------pop over handling-----------
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };
  //  ------button drop down handling---------
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // load question paper and sync time spent data with DB
  useEffect(() => {
    getExamPaper(examName, user);
  }, []);
  // set initial subject
  useEffect(() => {
    if (!isEmpty(subjects)) {
      setSub(Object.keys(subjects)[0]);
    }
  }, [subjects]);
  // change question number as per subject
  useEffect(() => {
    const q = questionPaper.find((q) => Number(q.question_number) === ci);
    if (q) {
      if (sub !== q.subject) {
        displayQuestion(
          Number(questionPaper.find((q) => q.subject === sub).question_number)
        );
      }
    }
  }, [sub]);
  // change subjects as per question number
  useEffect(() => {
    const q = questionPaper.find((q) => Number(q.question_number) === ci);
    if (q) {
      if (sub !== q.subject) {
        setSub(q.subject);
      }
    }
  }, [ci]);
  // save answer to db
  const attempt = (status, index, noskip = true) => {
    if (
      (noskip && !current_question.markedAnswer) ||
      (current_question.markedAnswer == "NA" && status !== "MARKED_FOR_REVIEW")
    ) {
      setAlertText("Please mark an answer.");
      setConfirmOpen(true);
      return;
    }
    if (
      allStudentAns.filter((q) => q.status === "ANSWERED").length ===
      questionPaper.length
    ) {
      setAlertText("You have attempted all the questions.");
      setConfirmOpen(true);
      return;
    }
    let prev_status = questionPaper[index - 1].status;
    questionPaper[index - 1].status = status;
    allStudentAns[index - 1].status = status;

    if (index > questionPaper.length) {
      return;
    }
    // console.log(questionPaper[index - 1], index);
    let update_c_q = questionPaper[index - 1];
    let update_opts = {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        student_id: user,
        test_name: examName,
        question_number: update_c_q.question_number,
        question_state: update_c_q.status,
        status: update_c_q.status,
        question_answer: 1,
        markedAnswer: update_c_q.markedAnswer,
        endDateTime: prop.endDateTime,
      }),
    };
    // let  updateUrl = examServerUrl+ '/exams/updateanswer';
    store.set(questionPaper[index - 1].question_number, update_opts.body);
    store.each((val, key) => {
      console.log(key, ":", val);
    });
    examAxios
      .post("/exams/updateanswer", update_opts.body)
      .catch((err) => {
        // handle error
        questionPaper[index - 1].status = prev_status;
        allStudentAns[index - 1].status = prev_status;
      })
      .then((res) => {
        // console.log(res.status)
        store.remove(questionPaper[index - 1].question_number);
      })
      .catch((error) => console.log(error));
    if (index >= questionPaper.length) return;
    if (questionPaper[index].status == "NOT_VISITED") {
      questionPaper[index].status = "NOT_ANSWERED";
      allStudentAns[index].status = "NOT_ANSWERED";
    }
    displayQuestion(index + 1);
  };
  // clear options
  const clear = (index) => {
    const update_c_q = current_question;
    update_c_q.markedAnswer = "NA";
    update_c_q.status = "NOT_ANSWERED";
    let update_opts = {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        student_id: user,
        test_name: examName,
        question_number: update_c_q.question_number,
        question_state: update_c_q.status,
        status: update_c_q.status,
        question_answer: 1,
        markedAnswer: update_c_q.markedAnswer,
        endDateTime: prop.endDateTime,
      }),
    };
    examAxios
      .post("/exams/updateanswer", update_opts.body)
      .then(() => (allStudentAns[index - 1].status = "NOT_ANSWERED"))
      .catch((err) => {
        console.log("err in clearing..", err);
      });
    setCurrentQuestion(update_c_q);
  };
  // single answer handler
  const handleSingleAnswer = (event) => {
    let update_c_q = current_question;
    update_c_q.markedAnswer = event.target.value;
    // console.log(update_c_q.markedAnswer);
    setCurrentQuestion(update_c_q);
  };
  // integer type answer handler
  const handleIntegerAnswer = (value) => {
    let update_c_q = current_question;
    update_c_q.markedAnswer = value;
    setCurrentQuestion(update_c_q);
  };
  // multiple type answer handler
  const handleMultipleAnswer = (event, option) => {
    let current_q = current_question;
    if (current_q.markedAnswer == "NA") {
      current_q.markedAnswer = "";
      setCurrentQuestion(current_q);
    }
    if (event.target.checked) {
      if (current_q.markedAnswer.includes(option)) {
      } else {
        current_q.markedAnswer += option;
        setCurrentQuestion(current_q);
      }
    } else {
      if (current_q.markedAnswer.includes(option)) {
        current_q.markedAnswer = current_q.markedAnswer.replace(option, "");
        setCurrentQuestion(current_q);
      }
    }
  };

  const handleCloseAlertBox = () => {
    setAlertText("");
    setConfirmOpen(false);
  };
  // submit exam
  const submit = (val = "submit") => {
    const { startDateTime = "", endDateTime = "" } = prop;
    const allStatus = {};
    Object.keys(statusdisplay).forEach((key) => {
      allStatus[key] = 0;
    });
    questionPaper.forEach((question) => {
      allStatus[question.status] += 1;
    });
    setStatusdisplay({ ...statusdisplay, ...allStatus });
    switch (val) {
      case "YES":
        let body = JSON.stringify({
          student_id: user,
          test_name: examName,
          code: prop.code,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        });
        // Upload the pending items to server
        setConfirmationType("PENDING_ITEMS");
        handlePendingAnswers();
        // then submit exam
        examAxios.post("/exams/submittest", body).then((val) => {
          setConfirmationType("RESULT");
          clearInterval(timer);
          Object.keys(subjects).forEach((subject) => {
            store.remove(subject);
          });
          remainingTime = -1;
          setIsSubmitted(true);
          examAxios
            .post("/exams/results", body)
            .then((respResults) => {
              // console.log(respResults);
              store.remove("currentExam");
            })
            .catch((err) => {
              console.log(err);
            });
        });
        break;
      case "NO":
        setIsSubmitted(false);
        break;
      case "submit":
        analyticsUPStream(
          current_question.question_number,
          "EXIT",
          current_question.status,
          current_question.markedAnswer,
          current_question.subject
        );
        setIsSubmitted(true);
        break;
      case "VIEW_RESULT":
        if (navigator.userAgent.includes("Android") && window.Android) {
          window.Android.submitPDFExam(
            `${baseURL}/${examName}/new-result?test=${displayname}&type=CUSTOM`
          );
        } else {
          setTimeout(() => {
            window.parent.location.replace(
              `${baseURL}/${examName}/new-result?test=${displayname}&type=CUSTOM`
            );
          });
        }
        store.remove("currentExam");
        break;
    }
  };
  // handle pending answers in store
  async function handlePendingAnswers() {
    store.each(async (val, key) => {
      if (!isNaN(key)) {
        await examAxios
          .post("/exams/updateanswer", val)
          .catch((err) => {})
          .then((results) => {
            if (results) return results.data;
          })
          .then((res) => {
            // console.log(res.status)
            store.remove(key);
          });
      }
    });
  }
  if (ifPaperLoading) return <NetworkProgress />;
  return isSubmitted ? (
    <ExamSummary
      test_type={test_type}
      testDisplayName={displayname}
      type={confirmationType}
      statusdisplay={statusdisplay}
      submit={submit}
    />
  ) : (
    <AppBar color="transparent" className={classes.appBar}>
      <Toolbar>
        <Grid container style={{ margin: "1% 0 1% 0" }} justify="space-between">
          <Grid item>
            <Dropdown
              width={180}
              dataToDisplay={Object.keys(subjects)}
              handleDropdownValueChange={(e, item) => {
                setSub(item);
              }}
              value={sub || ""}
              textFieldProps={{
                size: "small",
                InputLabelProps: { shrink: false },
                placeholder: "subject",
                style: {
                  background: "white",
                  height: 40,
                  borderRadius: `10px 10px 10px 10px`,
                },
              }}
            />
          </Grid>
          {current_question ? (
            <Grid item>
              <Button
                size="small"
                className={classes.buttonStyle}
                onClick={handleClick}
              >
                {current_question.question_number}
              </Button>
            </Grid>
          ) : null}

          <Grid item>
            <IconButton
              disabled={ci === 1}
              onClick={() => displayQuestion(ci - 1)}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>

            <IconButton
              disabled={questionPaper.length === ci}
              onClick={() => displayQuestion(ci + 1)}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Grid>

          {/* --------------------handling Options-------------- */}
          <Grid item>
            {" "}
            <HandlingUserAnswerByType
              setClickedOption={setClickedOption}
              clickedOption={clickedOption}
              question={current_question}
              selectedQno={ci}
              saveAnswer={() => attempt("ANSWERED", ci)}
              handleSingleAnswer={handleSingleAnswer}
              handleIntegerAnswer={handleIntegerAnswer}
              handleMultipleAnswer={handleMultipleAnswer}
              clear={() => clear(ci)}
            />
          </Grid>

          {/* -------------------showing ANSWERED qno's count-------------- */}
          <Grid item>
            <Typography variant="body2">Time Left:</Typography>
            <Typography variant="body2" color="error">
              {displayTime}
            </Typography>
          </Grid>

          <Grid item>
            <CheckCircleOutlineIcon fontSize="small" color="primary" />
            <Typography variant="body2">
              {allStudentAns.filter((q) => q.status === "ANSWERED").length}/
              {questionPaper.length}
            </Typography>
          </Grid>

          <Grid item>
            <CustomButton label="SUBMIT" handleGetClick={() => submit()} />
          </Grid>
          {navigator.userAgent.includes("Android") && window.Android && (
            <Grid item>
              <CustomButton
                label="Show PDF"
                handleGetClick={() =>
                  window.Android.showpdfbottomsheet(pdfLink)
                }
              />
            </Grid>
          )}
        </Grid>

        {/* --------------Displaying all questions with subject Names in popover-------------- */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "right-end",
            horizontal: "right-end",
            horizontal: "right-end",
          }}
          className={classes.root}
        >
          <Grid style={{ margin: "2%" }}>
            <DisplayQuesNosPopOver
              subjectNames={Object.keys(subjects)}
              setSelectedQno={displayQuestion}
              questionPaper={questionPaper}
              selectedQno={ci}
              close={handleClose}
            />
          </Grid>
        </Popover>
        <AlertComponent
          open={confirmOpen}
          closeAlert={handleCloseAlertBox}
          ifThemeRequired={false}
        >
          {alertText}
        </AlertComponent>
      </Toolbar>
    </AppBar>
  );
};
export default withRouter(CustomQuestionNosDispaly);
