import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import useStyles from "./styles"; // Adjust the path as necessary
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import store from "store";
import homeIcon from "../../assets/img/navigationBarAssets/home.svg";
import examsIcon from "../../assets/img/navigationBarAssets/exams.svg";
import analysisIcon from "../../assets/img/navigationBarAssets/analysis.svg";
import profileIcon from "../../assets/img/navigationBarAssets/profile.svg";
import restrictedLevels from "../../views/SignIn/restrictedLevel";

const NavigationBar = ({ history }) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const student = restrictedLevels.includes(
    `${store.get("user") ? store.get("user").level : ""}`
  );

  return [
    "/sign-in",
    "/register",
    "/exam-analysis",
    "/exam-portal",
    "/instructionadv",
    "/instructionneet",
    "/instruction",
    "/live-zoom-meeting",
    "/data-sync",
  ].includes(location.pathname) ? null : (
    <AppBar
      position="fixed"
      className={isMobile ? classes.appBarBottom : classes.appBarTop}
    >
      <Toolbar className={classes.toolbarButtons}>
        <BottomNavigation showLabels className={classes.root}>
          <BottomNavigationAction
            onClick={() => history.push("/home-page")}
            label="Home"
            value="home-page"
            icon={
              <img src={homeIcon} alt="home-page" className={classes.icon} />
            }
          />
          {student ? null : (
            <BottomNavigationAction
              label="Exams"
              value="exams"
              onClick={() => history.push("/home")}
              icon={
                <img src={examsIcon} alt="exams" className={classes.icon} />
              }
            />
          )}
          {student ? null : (
            <BottomNavigationAction
              label="Analysis"
              value="analysis"
              onClick={() => history.push("/exam-analysis")}
              icon={
                <img
                  src={analysisIcon}
                  alt="analysis"
                  className={classes.icon}
                />
              }
            />
          )}

          <BottomNavigationAction
            label="Profile"
            value="profile"
            onClick={() => history.push("/profile")}
            icon={
              <img src={profileIcon} alt="profile" className={classes.icon} />
            }
          />
        </BottomNavigation>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(NavigationBar);
