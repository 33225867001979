import { Grid } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  contentMargin: {
    marginLeft: theme.spacing(3),
  },
  checked: {
    backgroundColor: "linear-gradient(60deg,#5db85c, #5db85c)",
    color: "#5db85c", //'#535d8c'
  },
}));
const TrueFalseInput = ({ markedAnswer, setTrueFalseValue }) => {
  const [val, setVal] = useState(markedAnswer);
  const classes = useStyles();

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setVal(value);
    setTrueFalseValue(value);
  };
  useEffect(() => {
    setVal(markedAnswer);
  }, [markedAnswer]);

  return (
    <Grid container direction="column" className={classes.contentMargin}>
      <br />
      <RadioGroup
        aria-label="trueFalseSelection"
        name="trueFalseSelection"
        value={val}
        onChange={handleRadioChange}
        row
      >
        <FormControlLabel
          value="true"
          control={<Radio color="primary" />}
          label="True"
        />
        <FormControlLabel
          value="false"
          control={<Radio color="primary" />}
          label="False"
        />
      </RadioGroup>
      <br />
    </Grid>
  );
};

export default TrueFalseInput;
