import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Slide } from "@material-ui/core";

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxHeight: "700px",
    maxWidth: "900px",
    height: "300px",
    width: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "350px",
      height: "200px",
    },
  },
  buttonStyle: {
    borderRadius: 8,
    background: "white",
    border: "2px solid #3BBDED",
    height: "40px",
    width: "40px",
  },
  appBar: { position: "relative" },
}));
