import { Grid, Hidden, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AvTimerOutlinedIcon from "@material-ui/icons/AvTimerOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ErrorIcon from "@material-ui/icons/Error";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import TimerIcon from "@material-ui/icons/Timer";
import { inRange } from "lodash";
import React, { useEffect, useState } from "react";
import theme from "../../assets/theme";
import timeRange from "../../utils/timeRangeForResults";
import CustomTypography from "./CustomTypography";
import TimeSpentChart from "./TimeSpentChart";

const useStyles = makeStyles(() => ({
  chartContainer: {
    background: theme.palette.common.white,
    padding: "16px",
    filter: "drop-shadow(0px 5px 10px rgba(142, 154, 175, 0.1))",
    // borderRadius: "0px 0px 10px 10px",
  },
  scoreGap: {
    gap: "10px",
  },
  iconCss: {
    verticalAlign: "middle",
  },
}));

const TimeSpentTagging = ({
  questions = [],
  sub = "",
  testData = {},
  handleTagClick,
}) => {
  const { testType = "" } = testData;
  const [displayData, setDisplayData] = useState({});
  const [series, setSeries] = useState([]);
  const [activeBtn, setActiveBtn] = useState("");
  const classes = useStyles();

  useEffect(() => {
    // time tagging objects
    const taggingType = {
      "Perfect Attempts": {
        count: 0,
        background: theme.palette.primary.main,
        icon: () => <TimerIcon className={classes.iconCss} fontSize="small" />,
        questionNos: [],
      },
      "Too Fast Correct": {
        count: 0,
        background: theme.palette.info.dark,
        icon: () => (
          <AvTimerOutlinedIcon className={classes.iconCss} fontSize="small" />
        ),
        questionNos: [],
      },
      "Overtime Correct": {
        count: 0,
        background: theme.palette.info.light,
        icon: () => (
          <QueryBuilderIcon className={classes.iconCss} fontSize="small" />
        ),
        questionNos: [],
      },
      "Incorrect Attempts": {
        count: 0,
        background: theme.palette.warning.light,
        icon: () => <CancelIcon className={classes.iconCss} fontSize="small" />,
        questionNos: [],
      },
      "Overtime Incorrect": {
        count: 0,
        background: theme.palette.warning.dark,
        icon: () => (
          <DeleteForeverIcon className={classes.iconCss} fontSize="small" />
        ),
        questionNos: [],
      },
      Unattempted: {
        count: 0,
        background: theme.palette.text.hint,
        icon: () => <ErrorIcon className={classes.iconCss} fontSize="small" />,
        questionNos: [],
      },
    };

    //   count no. of questions in each time-interval category
    questions.forEach((questionObj) => {
      const { accuracy, status, questionNo } = questionObj;
      if (status === "unattempted") {
        taggingType["Unattempted"] = {
          ...taggingType["Unattempted"],
          count: taggingType["Unattempted"].count + 1,
          ...taggingType["Unattempted"].questionNos.push(questionNo),
        };
      }
      if (["correct", "partial_correct"].includes(status)) {
        if (
          inRange(
            accuracy,
            timeRange[sub][testType][0],
            timeRange[sub][testType][1]
          )
        ) {
          taggingType["Perfect Attempts"] = {
            ...taggingType["Perfect Attempts"],
            count: taggingType["Perfect Attempts"].count + 1,
            ...taggingType["Perfect Attempts"].questionNos.push(questionNo),
          };
        }
        if (accuracy > timeRange[sub][testType][1]) {
          taggingType["Overtime Correct"] = {
            ...taggingType["Overtime Correct"],
            count: taggingType["Overtime Correct"].count + 1,
            ...taggingType["Overtime Correct"].questionNos.push(questionNo),
          };
        }
        if (accuracy < timeRange[sub][testType][0]) {
          taggingType["Too Fast Correct"] = {
            ...taggingType["Too Fast Correct"],
            count: taggingType["Too Fast Correct"].count + 1,
            ...taggingType["Too Fast Correct"].questionNos.push(questionNo),
          };
        }
      }
      if (status === "wrong") {
        if (accuracy < timeRange[sub][testType][1]) {
          taggingType["Incorrect Attempts"] = {
            ...taggingType["Incorrect Attempts"],
            count: taggingType["Incorrect Attempts"].count + 1,
            ...taggingType["Incorrect Attempts"].questionNos.push(questionNo),
          };
        } else {
          taggingType["Overtime Incorrect"] = {
            ...taggingType["Overtime Incorrect"],
            count: taggingType["Overtime Incorrect"].count + 1,
            ...taggingType["Overtime Incorrect"].questionNos.push(questionNo),
          };
        }
      }
    });
    const seriesData = []; //store data to be displayed on radial chart
    Object.keys(taggingType).forEach((item) => {
      seriesData.push({
        name: item,
        data: [taggingType[item].count],
        showInLegend: false,
      });
    });
    setDisplayData({ ...taggingType });
    setSeries(seriesData);
    setActiveBtn("");
  }, [questions, sub]);

  return (
    <Grid container className={classes.chartContainer}>
      <Hidden xsDown>
        <Grid item xs={12}>
          <Typography variant="h3" color="textPrimary">
            Sincerity Score
          </Typography>
        </Grid>
      </Hidden>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <TimeSpentChart
            colors={Object.keys(displayData).map(
              (key) => displayData[key]["background"]
            )}
            series={series}
            subject={sub}
            data={displayData}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Grid container className={classes.scoreGap}>
            {Object.keys(displayData).map((tagging) => (
              <CustomTypography
                key={tagging}
                tagName={tagging}
                taggingData={displayData[tagging]}
                isActiveTag={activeBtn}
                handleTagClick={() => {
                  handleTagClick({ ...displayData[tagging] });
                  setActiveBtn(tagging);
                }}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TimeSpentTagging;
