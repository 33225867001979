import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
const useStyles = makeStyles(() => ({
  root: {
    margin: "15px 0 13px 8px",
    color: "#31456A",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "37px",
  },
}));
function TypeHeading({ title, variant }) {
  const classes = useStyles();

  return (
    <Typography component="div" color="textPrimary" className={classes.root}>
      {title}
    </Typography>
  );
}

export default TypeHeading;
