import { makeStyles } from "@material-ui/core";

const bSatStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '10px'
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    backgroundColor: 'transparent',
    padding: '8px 0px'
  },
  headerContainer: {
    backgroundColor: '#fafafa',
    width: '100%',
    position: 'fixed',
    top: '0px',
    left: '0px',
    boxShadow: '0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 12px -5px #0a71e961',
    zIndex: '9999',
  },
  header: {
    margin: 'auto',
    maxWidth: '960px',
    backgroundColor: '#fafafa',
    padding: '10px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: '10px'
    }
  },
  headerButtonsContainer: {
    width: '250px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '180px'
    }
  },
  headerButton: {
    width: '100px',
    [theme.breakpoints.down('xs')]: {
      width: '80px'
    }
  },
  cardHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  logo: {
    width: '100px'
  },
  title: {
    width: '400px',
    [theme.breakpoints.down('xs')]: {
      width: '280px'
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content_1Container: {
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '10px',
      paddingBottom: '10px',
    }
  },
  content_1: {
    fontWeight: 'bold',
    margin : 'auto',
    textAlign: 'left',
    minWidth: '300px',
    maxWidth: '960px',
    fontSize: '30px',
    lineHeight: '40px',
    paddingLeft: '30px',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xs')]: {
      paddignLeft: '0px',
      lineHeight: '100%',
      paddignLeft: '10px',
      margin: '10px 15px',
      fontSize: '18px'
    },
  },
  subContent: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0px'
    },
  },
  content_2: {
    height: '100%',
    maxWidth: '570px',
    textAlign: 'center',
    padding: '20px 10px',
    margin: '10px',
    borderTop: '1.2px solid white',
    boxShadow: '-8px -8px 20px #ffffff, 8px 8px 20px #d1d9e6',
    borderLeft: '1.2px solid white',
    borderRadius: '24px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '10px',
      paddingBottom: '10px',
    }
  },
  content_2bsat: {
    fontSize: '130px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '90px'
    }
  },
  content_3Container: {
    margin: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '0px',
      width: '100%'
    },
  },
  content_3: {
    textAlign: 'center',
    padding: '10px',
    maxWidth: '730px',
    [theme.breakpoints.down('xs')]: {
      padding: '5px'
    },
  },
  content_3Heading: {
    margin: '20px',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    },
  },
  modelContainer: {
    height: '100%',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    alignItems: 'flex-end',
    borderTop: '1.2px solid white',
    boxShadow: '-8px -8px 20px #ffffff, 8px 8px 20px #d1d9e6',
    borderLeft: '1.2px solid white',
    borderRadius: '24px',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      width: '90%',
      margin: '10px'
    },
  },
  model: {
    width: '185px',
    margin: '0px',
    borderRadius: '12px',
    [theme.breakpoints.down('xs')]: {
      width: '110px',
    },
  },
  content_4: {
    textAlign: 'center'
  },
  registerButton: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'transparent',
  },
  item: {
    width: '100px',
    height: '100%',
    margin: 'auto',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: '2px solid rgb(49, 69, 106)',
    borderRadius: '12px',
    [theme.breakpoints.down('md')]: {
      gridGap: '10px',
      maxWidth: '100px',
      width: '100%'
    },
  },
  iconsContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '5px'
  },
  iconContainer: {
    height: '40px',
    width: '40px',
    fill: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      height: '30px',
      width: '30px'
    },
  },
  iconDescription: {
    margin: '5px auto',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    lineHeight: '100%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px'
    },
  },
  datesContainer: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  datePaper: {
    padding: '20px',
    borderTop: '1.2px solid white',
    boxShadow: '-8px -8px 20px #ffffff, 8px 8px 20px #d1d9e6',
    borderLeft: '1.2px solid white',
    borderRadius: '24px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px'
    },
  },
  date: {
    padding: '10px',
    margin: '10px',
    color: '#31456A',
    display: 'inline',
    borderTop: '1.2px solid white',
    boxShadow: '-8px -8px 20px #ffffff, 8px 8px 20px #d1d9e6',
    borderLeft: '1.2px solid white',
    borderRadius: '24px',
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      margin: '5px'
    },
  },
  dateText: {
    color: '#08C5A7',
    margin: '10px',
    marginTop: '0px',
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    },
  },
  classNum: {
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    },
  },
  timeSlot: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    },
  },
  secContent: {
    paddingTop: '10px',
    paddingBottom: '10px',
    maxWidth: '940px',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.text.primary,
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      margin: '0px'
    },
  },
  square: {
    width: '150px',
    margin: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '100px'
    }
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  programsContainer: {
    maxWidth: '900px',
    margin: '10px',
    padding: '20px',
    borderTop: '1.2px solid white',
    boxShadow: '-8px -8px 20px #ffffff, 8px 8px 20px #d1d9e6',
    borderLeft: '1.2px solid white',
    borderRadius: '24px',
    '& > h5': {
      fontWeight: 'bold',
      padding: '10px',
      paddingTop: '0px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      width: '580px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
      width: '300px'
    }
  },
  programs: {
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    // display: 'grid',
    // gridTemplateColumns: '1fr 1fr',
    // gridGap: '10px',
    width: '100%'
  }
}));


export default bSatStyles;