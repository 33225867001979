import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// colors
import colors from "../../assets/v3/base/colors";

const useStyles = makeStyles((theme) => ({
  customButton: {
    color: colors.gradients.tirumala.main,
    textDecoration: "underline",
  },
}));

function createPrivacyMarkup() {
  return `<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Aptos;
	panose-1:2 11 0 4 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:115%;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;}
.MsoChpDefault
	{font-size:12.0pt;
	font-family:"Aptos",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:115%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:.5in .5in .5in .5in;}
div.WordSection1
	{page:WordSection1;}
-->
</style>

</head>

<body lang="EN-US" style="word-wrap:break-word">

<div class="WordSection1">

<p class="MsoNormal"><span lang="EN-IN">Privacy Policy for Tirumala Users</span></p>

<p class="MsoNormal"><span lang="EN-IN">This Privacy Policy discloses the privacy
practices for your use of the online platform https://tirumalaedu.com.</span></p>

<p class="MsoNormal"><span lang="EN-IN">This Privacy Policy applies to the
https://tirumalaedu.com website and all corresponding domains, subdomains, web
pages and websites associated therewith (collectively, the “Website”) and our
video streaming service that provides live and time-shifted television
programming delivered over the Internet via applications on consumer devices
including desktop computers, mobile phones, tablets and connected television
devices that link to or reference this Privacy Policy (collectively, with the
Website, the “Services”). This Privacy Policy only governs the Services and
does not apply offline. This Privacy Policy does not alter the terms of any
other agreement that you may have with us. By accessing or using the Services,
you agree to the terms of this Privacy Policy and consent to the collection and
use of information as discussed in this Privacy Policy. If you do not agree to
the terms of this Privacy Policy, do not access the Services or provide your
information to Tirumala.</span></p>

<p class="MsoNormal"><span lang="EN-IN">By visiting this Website, you agree to be
bound by the terms and conditions of this Privacy Policy. Please read this
Privacy Policy carefully. By using the Website, you indicate that you
understand, agree and consent to this Privacy Policy. If you do not agree with
the terms of this Privacy Policy, please do not use/access this Website.</span></p>

<p class="MsoNormal"><span lang="EN-IN">We’ll review this Privacy Policy from time
to time to make sure it is up-to-date. If you are just a visitor, then please
note that this Privacy Policy is subject to change at any time without notice.
To make sure you are aware of any changes, please review this Policy
periodically. If you are our registered user, we will notify you before we make
changes to this Policy and give you the opportunity to review the revised
Policy before you choose to continue using our services.</span></p>

<p class="MsoNormal"><span lang="EN-IN">By Personal Data, we mean any information
that can either itself identify you as an individual ("Personally
Identifying Information ") or that can be connected to you indirectly by
linking it to Personally Identifying Information.</span></p>

<p class="MsoNormal"><span lang="EN-IN">•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; COLLECTION OF INFORMATION</span></p>

<p class="MsoNormal"><span lang="EN-IN">As a visitor, you can browse our Website to
find out more about our Website. You are not required to provide us with any
Personal Data as a visitor. When you visit the Site, we collect and store
certain information in order to improve security, analyse trends and administer
the Website with a view to assist us in improving customer experience. We use
this information to evaluate traffic patterns on our Website so that we can
make it more useful to our visitors. We collect your Personal Data when you
register with us, when you express an interest in obtaining information about
us or our products and services, when you participate in activities on our
Website or otherwise contact us.</span></p>

<p class="MsoNormal"><span lang="EN-IN">We will only collect your Personal Data if
we have a proper reason for doing so, e.g.: to comply with our legal and
regulatory obligations; for the performance of our contract with you or to take
steps at your request before entering into a contract; for our legitimate
interests or those of a third party; or where you have given consent. A
legitimate interest is when we have a business or commercial reason to use your
information, so long as this is not overridden by your own rights and
interests. In some cases, we may also have a legal obligation to collect
personal information from you or may otherwise need the personal information to
protect your vital interests or those of another person (for example, to
prevent payment fraud or confirm your identity.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Our primary goal in collecting your
Personal Data and other personal information is to provide you a safe,
efficient, smooth and customized experience. This allows us to provide courses,
study material, tutorials that most likely meet your needs and to customize our
Website to make your experience safer and easier. We also use your Personal
Identifiable Information together with other Personally Non-Identifiable Information
to help us better understand our users and to improve the content and
functionality of our Website. The information we learn from you helps us
personalize and continually improve your experience at our Website. We do not
voluntarily make this information available to third parties, nor do we use it
for any other purpose, except as set out herein.</span></p>

<p class="MsoNormal"><span lang="EN-IN">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When you visit our Website, as
part of a purchase of one of our courses or registering on our Website, we may
collect a variety of information that you will voluntarily provide to us via
one of our contact forms, via a chat or phone session (Personal Identifying
Information), such as:</span></p>

<p class="MsoNormal"><span lang="EN-IN">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Contact Information, such as
name, email address, display picture, mailing address, phone number, IP
address, geographic location, or phone number</span></p>

<p class="MsoNormal"><span lang="EN-IN">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Information to enable us to
check and verify your identity, e.g. your date of birth, mark-sheets,
Passport/Aadhar /Voter ID details</span></p>

<p class="MsoNormal"><span lang="EN-IN">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Billing Information, such as
debit card number, credit card number and billing address</span></p>

<p class="MsoNormal"><span lang="EN-IN">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Unique Identifiers, such as
username or password</span></p>

<p class="MsoNormal"><span lang="EN-IN">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Reviews or ratings, syllabus
and course preferences, account settings, (including preferences set in the
" Account " section of our Website); and</span></p>

<p class="MsoNormal"><span lang="EN-IN">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Information provided to us
through our service, interaction with our customer service, participation in
surveys or marketing promotions</span></p>

<p class="MsoNormal"><span lang="EN-IN">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Examination and Academic
Information relating to competitive examinations that you wish to appear in,
exam application number, date of exam and test center</span></p>

<p class="MsoNormal"><span lang="EN-IN">&nbsp;</span></p>

<p class="MsoNormal"><span lang="EN-IN">We collect information about you and your
use of our service, your interactions with us as well as information regarding
your computer or other device used to access our service).This information
includes your activity on our Website, such as classes, study materials, courses
and search queries; details of your interactions with customer service , such
as the date , time and reason for contacting us ,transcripts of any chat
conversations, and if you call us , your phone number and call recordings;
device IDs or other unique identifiers, device and software characteristics
(such as type and configuration), connection information, statistics on page
views , referring source ( for example, referral URLs ), IP address (which may
tell us your general location), browser and standard web server log
information, information collected via the use of cookies, web beacons and
other technologies.</span></p>

<p class="MsoNormal"><span lang="EN-IN">With the help of back end applications, we
aim at providing better services and better interaction with teachers,
depending on the beneficiary’s line of interest. We also may ask for this
information at other times, such as when you enter contests or other promotions
sponsored by us and/or our partners. If you use a feature that requires payment
of a fee options like NEFT or Card will appear. In case of payments via credit
or debit card, we will redirect you to registered payment gateways such as
razorpay, ccavenue, payubiz and other such registered gateways. You may store
and save your payment details like card numbers with the gateway. We do not have
access to this data. All payment transactions are processed through secure
payment gateway providers. We do not store any card information (other than the
last 4 digits of your card) in our servers.</span></p>

<p class="MsoNormal"><span lang="EN-IN">When you use one of our paid products, we
track the web pages, and information that has been accessed by you, and store
it on our servers. This enables us to track items that you have completed, and
those that you need to see.</span></p>

<p class="MsoNormal"><span lang="EN-IN">The user full name and his/her display
picture can be publicly displayed on the Website. Depending on what profile and
what services they are seeking, Tirumala can ask for more information relating
to address, billing information, certificates, past employment information etc.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Tirumala can use technologies such as
cookies, clear gifs, log files, and flash cookies for several purposes,
including to help understand how you interact with our site and services, in
order to provide a better experience.</span></p>

<p class="MsoNormal"><span lang="EN-IN">•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; USAGE AND RETENTION OF
INFORMATION</span></p>

<p class="MsoNormal"><span lang="EN-IN">We use the personal information we collect,
including your Personal Data where it is necessary to deliver the services you
have requested, where it is necessary to exercise or comply with legal rights
or obligations or for normal business purposes of the kind set out in this
Policy. We will use your personal information to provide, analyse, administer
and improve our services, to provide you with a personalized experience on our
Website (especially, by offering you services that is best suited for you), to
contact you about your account and our services, to provide you customer
service, to provide you with personalized marketing and to detect, prevent,
mitigate and investigate fraudulent or illegal activities. We further use your
personal information to determine your general geographic location, provide localized
courses and classes, provide you with customized and personalized study
material, recommendations, determine your Internet service provider , and help
us quickly and efficiently respond to inquiries and requests and enforcing our
terms (such as determining free trial eligibility) and communicate with you
concerning our service (for example by email, push notifications, text
messaging ,and online messaging channels), so that we can send you details
about new features and content available on the Website, special offers,
promotional announcements, surveys, and to assist you with operational requests
such as password reset requests.</span></p>

<p class="MsoNormal"><span lang="EN-IN">•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; COMMUNITY</span></p>

<p class="MsoNormal"><span lang="EN-IN">Tirumala is a community. We offer several
features that allow members to connect and communicate in public or semi-public
spaces, such as Forums and Teams. Please be sensibile and judge before posting
in these community spaces or sharing your personal information with others on
Tirumala. Be aware that any personal information you submit there can be read,
collected, or used by others, or could be used to send you unsolicited
messages.</span></p>

<p class="MsoNormal"><span lang="EN-IN">•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SHARING AND DISCLOSING
PERSONAL INFORMATION</span></p>

<p class="MsoNormal"><span lang="EN-IN">We use other companies, agents or
contractors ("Service Providers") to perform services on our behalf
or to assist us with the provision of services to you. We engage Service
Providers to provide marketing, advertising, communications, infrastructure and
IT services, to personalize and optimize our service, to process credit card
transactions or other payment methods, to provide customer service, to collect
debts, to analyze and enhance data (including data about users’ interactions
with our service), and to process and administer consumer surveys. In the
course of providing such services, these Service Providers may have access to
your personal or other information.</span></p>

<p class="MsoNormal"><span lang="EN-IN">We do not authorize them to use or disclose
your personal information except in connection with providing their services.</span></p>

<p class="MsoNormal"><span lang="EN-IN">•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SECURITY</span></p>

<p class="MsoNormal"><span lang="EN-IN">We shall try to take all precautions to
protect the personal information both online and offline. We will try to
protect your information using technical and administrative security measures
to reduce the risks of loss, misuse, unauthorized access, disclosure and
alteration. We have standard SSL certification which basically helps us create
a secure connection between our server and user to render any information or
action. Some of the safeguards we use are firewalls and data encryption,
physical access controls to our data centres and information access
authorization controls. Only user passwords are encrypted in 64-bit encryption
and stored because generally users use the same password on multiple sites, to
prevent any kind of theft, piracy or unauthorised access. If you believe your
account has been abused or hacked, please contact us by sending us an email at
legal@Tirumala.com.</span></p>

<p class="MsoNormal"><span lang="EN-IN">We do not sell, transfer or rent your
personal information to third parties for their marketing purposes without your
explicit consent and we only use your information as described in the Privacy
Policy. We view protection of your privacy as a very important community
principle. We understand clearly that you and your Information is one of our
most important assets. We store and process your personal information on
computers located in India that are protected by physical as well as
technological security devices. We use third parties to verify and certify our
privacy principles. If you object to your Information being transferred or used
in this way, please do not use this Website.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Under no circumstances, we rent, trade,
transfer or share your personal information that we have collected with any
other company for their marketing purposes without your consent. We reserve the
right to communicate your personal information to any third party that makes a
legally-compliant request for its disclosure.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Keeping your Personal Data secure</span></p>

<p class="MsoNormal"><span lang="EN-IN">We have appropriate security measures to
prevent your Personal Data from being accidentally lost or used or accessed
unlawfully. Processing your Personal Data will be done only in an authorised
manner and subject to a duty of confidentiality. We also have
procedures–in-place to deal with any suspected data security breach. We will
notify you about any applicable regulator of a suspected data security breach
where we are legally required to do so.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Notwithstanding anything contained anywhere
in this Agreement; we cannot assure absolute security to your personal data and
by entering into or by logging into our website, you explicitly agree not to
sue us for any data breach.</span></p>

<p class="MsoNormal"><span lang="EN-IN">•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; COOKIES</span></p>

<p class="MsoNormal"><span lang="EN-IN">We transfer cookies, which are small files
containing a string of character, to your IP address, giving the browser
distinct identification, in order to keep track of the user’s preferences.
Furthermore, these files also help in logging-in faster and they act as a
mechanism to determine user trends. The data thus retrieved from the user’s IP
address, enables us to enhance our offers, including but not limited to more
content in areas of greater interest to a majority of users. Our Website uses
"Cookies" to identify the areas of our Website that you have visited.
A Cookie is a small piece of data stored on your computer or mobile device by
your web browser. We use Cookies to personalize the Content that you see on our
Website. Most web browsers can be set to disable the use of Cookies. However,
if you disable Cookies, you may not be able to access functionality on our
Website correctly or at all. We never place Personally Identifiable Information
in Cookies.</span></p>

<p class="MsoNormal"><span lang="EN-IN">•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; THIRD PARTIES AND LINKS</span></p>

<p class="MsoNormal"><span lang="EN-IN">We may pass your details to other companies
in our group. We may also pass your details to our agents and subcontractors to
help us with any of our uses of your data set out in our Privacy Policy. For
example, we may use third parties to assist us with delivering services to you,
to help us to collect payments from you, to analyse data and to provide us with
marketing or customer service assistance. We may exchange information with
third parties for the purposes of fraud protection and credit risk reduction.
We may transfer our databases containing your personal information if we sell
our business or part of it. Other than as set out in this Privacy Policy, we
shall NOT sell or disclose your personal data to third parties without
obtaining your prior consent unless it is necessary for the purposes set out in
this Privacy Policy or unless we are required to do so by law. The Site may
contain advertising of third parties and links to other sites or frames of
other sites. Please be aware that we are not responsible for the privacy
practices or content of those third parties or other sites, nor for any third
party to whom we transfer your data in accordance with our Privacy Policy.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Our Website may contain links to other
websites that are not under our direct control. These websites may have their
own policies regarding privacy. We have no control of or responsibility for
linked websites and provide these links solely for the convenience and
information of our visitors. You accessing such linked Websites shall be at
your own risk. These websites are not subject to this Privacy Policy. You
should check the privacy policies, if any, of those individual websites to see
how the operators of those third-party websites will utilize your personal
information. In addition, these websites may contain a link to Websites of our
affiliates. The websites of our affiliates are not subject to this Privacy
Policy, and you should check their individual privacy policies to see how the
operators of such websites will utilize your personal information.</span></p>

<p class="MsoNormal"><span lang="EN-IN">•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CONSULTING</span></p>

<p class="MsoNormal"><span lang="EN-IN">We use third parties to help us provide
services to You including the fulfilment of service, processing of payments,
monitoring site activity, conducting surveys, maintaining our database,
administering emails, and administering contents, and to provide aggregate,
comparative information on the performance of our website to us and a select
group.</span></p>

<p class="MsoNormal"><span lang="EN-IN">•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CHOICE</span></p>

<p class="MsoNormal"><span lang="EN-IN">It is open for you to customize our usage
of your personal information to communicate with you, to send you marketing
information, how we provide you with customized and relevant advertising, and
whether you want to stay signed into your account.</span></p>

<p class="MsoNormal"><span lang="EN-IN">If you do not wish to receive marketing
communications from us, you can unsubscribe from the link in the email you
would receive or change your Communication preferences or indicate your
communication preferences. You can also reach us via chat, WhatsApp, call or
email to block promotional communication to you. Keep in mind, we do not sell
or rent your personal information to third parties for their marketing purposes
without your explicit consent.</span></p>

<p class="MsoNormal"><span lang="EN-IN">•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; OWNERSHIP OF RIGHTS</span></p>

<p class="MsoNormal"><span lang="EN-IN">All rights, including copyright, in this
Website are owned by or licensed to us. Any use of this Website or its
contents, including copying or storing it or them in whole or part, other than
for your own personal, non-commercial use is prohibited without our permission.
You are prohibited from modifying, copying, distributing, transmitting,
displaying, printing, publishing, selling, licensing, creating derivative works
or using any content available on or through our Website for commercial or
public purposes. You may not modify, distribute or re-post something on this
Website for any purpose</span></p>

<p class="MsoNormal"><span lang="EN-IN">The Site contains copyrighted material,
trademarks and other proprietary information, including, but not limited to,
text, software, photos, video, graphics, music, sound, and the entire contents
of Tirumala is protected by copyright as a collective work under the applicable
copyright laws. Tirumala owns a copyright in the selection, coordination,
arrangement and enhancement of such content, as well as copyright or license to
use in the content original to it. You may not modify, publish, transmit, participate
in the transfer or sale, create derivative works, or in any way exploit, any of
the content, in whole or in part. You may download / print / save copyrighted
material for your personal use only. Except as otherwise expressly stated under
copyright law, no copying, redistribution, retransmission, publication or
commercial exploitation of downloaded material without the express permission
of Tirumala is permitted. If copying, redistribution or publication of
copyrighted material is expressly permitted by Tirumala, then no changes in or
deletion of author attribution, trademark legend or copyright notice shall be
made.</span></p>

<p class="MsoNormal"><span lang="EN-IN">You acknowledge that you do not acquire any
ownership rights by downloading copyrighted material. Trademarks that are
located within or on our Website or a website otherwise owned or operated in
conjunction with Tirumala shall not be deemed to be in the public domain but
rather the exclusive property of Tirumala, unless such site is under license
from the trademark owner thereof in which case such license is for the
exclusive benefit and use of Tirumala, unless otherwise stated.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Tirumala does not have any express burden
or responsibility to provide you with indications, markings or anything else
that may aid you in determining whether the material in question is copyrighted
or trademarked. You shall be solely liable for any damage resulting from any
infringement of copyrights, trademarks, proprietary rights or any other harm
resulting from such a submission. By submitting material to any public area of
the Website, you warrant that the owner of such material has expressly granted
Tirumala the royalty-free, perpetual, irrevocable, non-exclusive right and
license to use, reproduce, modify, adapt, publish, translate and distribute
such material (in whole or in part) worldwide and/or to incorporate it in other
works in any form, media or technology now known or hereafter developed for the
full term of any copyright that may exist in such material. You also permit any
other end user to access, view, store or reproduce the material for that end
user's personal use. You hereby grant Tirumala, the right to edit, copy,
publish and distribute any material made available on the Website by you.</span></p>

<p class="MsoNormal"><span lang="EN-IN">If you come across any abuse or violation
of these Terms, please report to Venkateshwara Rao@Tirumala.com</span></p>

<p class="MsoNormal"><span lang="EN-IN">•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CONSENT</span></p>

<p class="MsoNormal"><span lang="EN-IN">By submitting data to us or our agent or
using the Site, you consent to our use of your data in the manner set out in
this Privacy Policy. Notwithstanding anything to the contrary contained herein
or elsewhere, you hereby consent to Tirumala to disclose and/or advertise to
the public that you have or had subscribed to a particular Course with Tirumala
in the event you have or had subscribed to a Course or Listing with Tirumala
for the purposes of showing that the rank was secured as a result of the
Courses offered by Tirumala.</span></p>

<p class="MsoNormal"><span lang="EN-IN">Venkateshwara Rao@Tirumala.com</span></p>

<p class="MsoNormal"><span lang="EN-IN">•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CONSENT TO THIS POLICY</span></p>

<p class="MsoNormal"><span lang="EN-IN">The Terms of Use Agreement is incorporated
herein by reference in its entirety.</span></p>

<p class="MsoNormal"><span lang="EN-IN">•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; GENERAL</span></p>

<p class="MsoNormal"><span lang="EN-IN">Modification:</span></p>

<p class="MsoNormal"><span lang="EN-IN">We may at any time modify the Terms of Use
of our Website without any prior notification to you. Should you wish to
terminate your account due to a modification to the Terms or the Privacy
Policy, you may do so email us at help@Tirumala.com. However, if you continue to
use the service you shall be deemed to have agreed to accept and abide by the
modified Terms of this Website.</span></p>

<p class="MsoNormal"><span lang="EN-IN">PRIVILEGED/EXCLUSIVE SERVICE:</span></p>

<p class="MsoNormal"><span lang="EN-IN">By having a Tirumala account, you have
explicitly given consent for us to capture images (followed by analysis), camera/mic
permissions to make video calls and record the same.</span></p>

<p class="MsoNormal"><span lang="EN-IN">TIRUMALA MAY DISCLOSE INFORMATION:</span></p>

<p class="MsoNormal"><span lang="EN-IN">o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; as required by law, such as
to comply with a subpoena, or similar legal process;</span></p>

<p class="MsoNormal"><span lang="EN-IN">o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; to enforce applicable ToU
(Terms of Use), including investigation of potential violations thereof;</span></p>

<p class="MsoNormal"><span lang="EN-IN">o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; when we believe in good faith
that the disclosure is necessary to protect our rights, protect your safety or
the safety of others, investigate fraud, address security or technical issues
or respond to a government request;</span></p>

<p class="MsoNormal"><span lang="EN-IN">o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; with our trusted service
providers who work on our behalf and do not have an independent use of the
information we disclose to them and have agreed to and adhered to the rules set
forth in this Policy;</span></p>

<p class="MsoNormal"><span lang="EN-IN">o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; to protect against imminent
harm to the rights, property or safety of the Application/Website or our users
or the public as required or permitted by law;</span></p>

<p class="MsoNormal"><span lang="EN-IN">o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; with third party service
providers in order to personalize the Application/Website/Services/products for
a better user experience and to perform behavioural analysis;</span></p>

<p class="MsoNormal"><span lang="EN-IN">&nbsp;</span></p>

<p class="MsoNormal"><span lang="EN-IN">&nbsp;</span></p>

<p class="MsoNormal"><span lang="EN-IN">Governing Law and Jurisdiction:</span></p>

<p class="MsoNormal"><span lang="EN-IN">In the event of any dispute arising between
the parties with respect to this Agreement, the same shall be referred to the
Sole Arbitrator and the arbitration shall be in accordance with Arbitration and
Conciliation Act of 1996. The language of arbitration proceeding shall be
English. The seat and place of arbitration shall be Rajahmundry and the
decision of the Arbitrator shall be final and binding on both parties herein.</span></p>

<p class="MsoNormal"><span lang="EN-IN">This contract shall be subject to the exclusive
jurisdiction of courts in Rajahmundry, Andhra Pradesh, India and shall be
governed by the&nbsp;Indian&nbsp;laws.</span></p>

</div>




</body></html>`;
}

const PrivacyPolicy = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="text"
        onClick={handleClickOpen}
        className={classes.customButton}
      >
        Privacy Policy
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Privacy Policy</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <iframe
              srcDoc={createPrivacyMarkup()}
              title="Privacy Policy"
              width="100%"
              style={{ border: "none", minHeight: "80vh" }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PrivacyPolicy;
