const paper_1_2k20 = {
  "questions":{ "1": "1",
   "2": "1",
   "3": "1",
   "4": "1",
   "5": "1",
   "6": "1",
   "7": "2",
   "8": "2",
   "9": "2",
   "10": "2",
   "11": "2",
   "12": "2",
   "13": "3",
   "14": "3",
   "15": "3",
   "16": "3",
   "17": "3",
   "18": "3",
   "19": "1",
   "20": "1",
   "21": "1",
   "22": "1",
   "23": "1",
   "24": "1",
   "25": "2",
   "26": "2",
   "27": "2",
   "28": "2",
   "29": "2",
   "30": "2",
   "31": "3",
   "32": "3",
   "33": "3",
   "34": "3",
   "35": "3",
   "36": "3",
   "37": "1",
   "38": "1",
   "39": "1",
   "40": "1",
   "41": "1",
   "42": "1",
   "43": "2",
   "44": "2",
   "45": "2",
   "46": "2",
   "47": "2",
   "48": "2",
   "49": "3",
   "50": "3",
   "51": "3",
   "52": "3",
   "53": "3",
   "54": "3"},
   "sections": {
       1: [1,19,37],
       2: [7,25,43],
       3: [13,31,49]
   }
} 

export  default paper_1_2k20;