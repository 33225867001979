const paper_2_2k11 = {
   "questions":{ "1": "1",
    "2": "1",
    "3": "1",
    "4": "1",
    "5": "1",
    "6": "1",
    "7": "1",
    "8": "1",
    "9": "2",
    "10": "2",
    "11": "2",
    "12": "2",
    "13": "3",
    "14": "3",
    "15": "3",
    "16": "3",
    "17": "3",
    "18": "3",
    "19": "4",
    "20": "4",
    "21": "1",
    "22": "1",
    "23": "1",
    "24": "1",
    "25": "1",
    "26": "1",
    "27": "1",
    "28": "1",
    "29": "2",
    "30": "2",
    "31": "2",
    "32": "2",
    "33": "3",
    "34": "3",
    "35": "3",
    "36": "3",
    "37": "3",
    "38": "3",
    "39": "4",
    "40": "4",
    "41": "1",
    "42": "1",
    "43": "1",
    "44": "1",
    "45": "1",
    "46": "1",
    "47": "1",
    "48": "1",
    "49": "2",
    "50": "2",
    "51": "2",
    "52": "2",
    "53": "3",
    "54": "3",
    "55": "3",
    "56": "3",
    "57": "3",
    "58": "3",
    "59": "4",
    "60": "4"},
    "sections": {
        1: [1,21,41],
        2: [9,29,49],
        3: [13,33,53],
        4: [19,39,59]
    }
} 

export  default paper_2_2k11;