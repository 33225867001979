import { withStyles } from "@material-ui/core/styles";
import React from "react";
import theme from "../../assets/theme";
import { Select, ListItemIcon } from "@material-ui/core";
const CustomSelect = withStyles(() => ({
  root: {
    background: "blue",
    padding: "13px 21px 13px 21px",
    borderRadius: "10px 10px 0px 0px",
    "&:focus": {
      background: theme.palette.action.selected,
      borderRadius: "10px 10px 0px 0px",
      color: theme.palette.common.white,
      borderColor: theme.palette.action.selected,
    },
    "&:hover": {
      background: theme.palette.action.selected,
      borderRadius: "10px 10px 0px 0px",
    },
  },
  select: {
    background: theme.palette.action.selected,
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    border: "none",
    "&:not([multiple]) option, &:not([multiple]) optgroup": {
      backgroundColor: theme.palette.action.selected,
      padding: "10px",
    },
  },
  icon: {
    marginRight: "13px",
  },
  iconOpen: {
    transform: "rotate(180deg)",
  },
}))((props) => <Select {...props} style={{ width: "100%" }} />);
const CustomListItemIcon = withStyles(() => ({
  root: {
    minWidth: "10px",
  },
}))((props) => <ListItemIcon {...props} />);

export { CustomSelect, CustomListItemIcon };
