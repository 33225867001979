// react
import React from "react";
import { useState, useEffect } from "react";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import borderRadius from "highcharts-border-radius";
// loadash
import cloneDeep from "lodash/cloneDeep";
import { Box, Typography, Grid } from "@material-ui/core";
import { useStyles } from "../Components/styles";


borderRadius(Highcharts);
const AttendanceHistoryChart = ({ presentData, absentData }) => {


  const months = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const presentElementCounts = {};
  const absentElementsCounts = {};

  presentData &&
    presentData.forEach((element) => {
      presentElementCounts[element] = (presentElementCounts[element] || 0) + 1;
    });
  const presents = {};
  for (const key in presentElementCounts) {
    const monthName = months[key];
    presents[monthName] = presentElementCounts[key] || 0;
  }

  absentData &&
    absentData.forEach((element) => {
      absentElementsCounts[element] = (absentElementsCounts[element] || 0) + 1;
    });
  delete presents.undefined;
  const absents = {};
  for (const key in absentElementsCounts) {
    const monthName = months[key];
    absents[monthName] = absentElementsCounts[key] || 0;
  }
  delete absents.undefined;
  const total = Object.values(presents).map((value1, i) => {
    const value2 = Object.values(absents)[i] || 0;
    return Number(value1) + Number(value2);
  });

  const defaultOptionsDataSet1 = {
    chart: {
      type: "areaspline",
      zoomType: "x",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
      align: "left",
    },
    xAxis: {
      categories: Object.keys(presents),

      crosshair: {
        width: 2,
        color: "gray",
        dashStyle: "shortdot",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },

    plotOptions: {
      areaspline: {},
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      crosshairs: true,
    },

    series: [
      {
        name: "Present",

        data: Object.values(presents),

        stack: "students",

        color: {
          linearGradient: {
            x1: 1,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [
              0,
              Highcharts.Color("#03AD92")
                .setOpacity(1)
                .get("rgba"),
            ],
            [
              1,
              Highcharts.Color("#03AD92")
                .setOpacity(1)
                .get("rgba"),
            ],
          ],
        },
        fillColor: {
          linearGradient: {
            x1: 1,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [
              0,
              Highcharts.Color("#e6ffe6")
                .setOpacity(0.7)
                .get("rgba"),
            ],
            [
              1,
              Highcharts.Color("#e6ffe6")
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
      },

      {
        name: "Absent",

        data: Object.values(absents),
        stack: "students",

        color: {
          linearGradient: {
            x1: 1,
            y1: 0,
            x2: 0,
            y2: 0,
          },
          stops: [
            [
              0,
              Highcharts.Color("#FFAB00")
                .setOpacity(1)
                .get("rgba"),
            ],
            [
              1,
              Highcharts.Color("#FFAB00")
                .setOpacity(1)
                .get("rgba"),
            ],
          ],
        },
        fillColor: {
          linearGradient: {
            x1: 1,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [
              0,
              Highcharts.Color("#ffffe6")
                .setOpacity(0.7)
                .get("rgba"),
            ],
            [
              1,
              Highcharts.Color("#ffffe6")
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
      },
      {
        name: "total",

        data: total,
        stack: "students",

        color: {
          linearGradient: {
            x1: 1,
            y1: 0,
            x2: 0,
            y2: 0,
          },
          stops: [
            [
              0,
              Highcharts.Color("#9C27B0")
                .setOpacity(1)
                .get("rgba"),
            ],
            [
              1,
              Highcharts.Color("#9C27B0")
                .setOpacity(1)
                .get("rgba"),
            ],
          ],
        },
        fillColor: {
          linearGradient: {
            x1: 1,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [
              0,
              Highcharts.Color("#ffe6ff")
                .setOpacity(0.7)
                .get("rgba"),
            ],
            [
              1,
              Highcharts.Color("#ffe6ff")
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
      },
    ],
  };
  const currentOptions = cloneDeep(defaultOptionsDataSet1);

  return (
    <>
      {/* <Box m={1} className={classes.examSummary}>
        <Box className={classes.title}>
          <Typography
            style={{ color: " #31456A", fontSize: "18px", fontWeight: 600 }}
          >
            Attendance History
          </Typography>
        </Box>
      </Box> */}
      <Grid container spacing={3} direction="row" display="flex">
        <Grid item xs={12}>
          <Typography
            style={{ color: " #31456A", fontSize: "18px", fontWeight: 600 }}
          >
            Exam Attendance History
          </Typography>
        </Grid>
      </Grid>
      <div>
        <HighchartsReact highcharts={Highcharts} options={currentOptions} />
      </div>
    </>
  );
};
export default AttendanceHistoryChart;
