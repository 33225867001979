import React from "react";
import ResultHeader from "../../resultPage/ResultHeader";
import NoResultMsg from "../../resultPage/NoResultMsg";

export default function TopicWiseAnalysis() {
  return (
    <>
      <ResultHeader
        testDisplayName="View Topic Wise Analysis"
        path="/home-page"
      />
      <NoResultMsg text="No Data Available" />
    </>
  );
}
