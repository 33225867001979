import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import NetworkProgress from "../../NetworkProgress";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import MarksList from "../../resultPage/MarksList";
const useStyle = makeStyles(() => ({
  contentWidth: {
    minWidth: "35vw",
    overflow: "auto",
  },
}));
function AnalyticsPopUp({ analyticsData, ...props }) {
  const theme = useTheme();
  const classes = useStyle();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [option, setOptions] = useState({});
  useEffect(() => {
    setOptions({
      ...option,
      chart: {
        type: "bar",
      },
      title: {
        text: "Time spent analysis.",
      },
      subtitle: {
        text: "No. of students submitted this test in respective time interval",
      },
      xAxis: {
        categories: analyticsData.data ? analyticsData.data.timeSpentData : [],
        title: {
          text: "Time division",
        },
      },
      yAxis: {
        min: 0,
        allowDecimals: false,
        title: {
          text: "students",
          align: "high",
        },
        labels: {
          overflow: "justify",
        },
      },
      tooltip: {
        valueSuffix: "",
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      legend: {
        reversed: true,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "No. of students",
          data: analyticsData.data ? analyticsData.data.students : [],
        },
      ],
    });
  }, [analyticsData]);
  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={() => {
        props.handleClose();
      }}
      aria-labelledby="analytics-dialog-title"
    >
      <DialogTitle id="analytics-dialog-title">
        <Grid container spacing={2} justify="space-between">
          <Grid item>Analytics</Grid>
          <Grid item>
            <Typography align="right">
              <CloseIcon
                fontSize="inherit"
                onClick={() => {
                  props.handleClose();
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {analyticsData.isLoading ? (
          <NetworkProgress />
        ) : analyticsData.error ? (
          <Typography color="error" variant="h5" align="center">
            Something went wrong!
          </Typography>
        ) : (
          <React.Fragment>
            <DialogContentText className={classes.contentWidth}>
              {analyticsData.data.students ? (
                <HighchartsReact options={option} highcharts={Highcharts} />
              ) : (
                <Typography variant="h5" align="center">
                  Data not Available!
                </Typography>
              )}
            </DialogContentText>
            <br />
            <DialogContentText>
              {analyticsData.data.students && (
                <Grid container direction="column" spacing={2}>
                  <MarksList
                    subjectName="Correct"
                    subjectColor={{ color: theme.palette.info.main }}
                    subjectMarks={
                      analyticsData.data.correct
                        ? analyticsData.data.correct
                        : 0
                    }
                  />
                  <MarksList
                    subjectName="Incorrect"
                    subjectColor={{ color: theme.palette.text.primary }}
                    subjectMarks={
                      analyticsData.data.wrong ? analyticsData.data.wrong : 0
                    }
                  />
                  <MarksList
                    subjectName="Skipped"
                    subjectColor={{ color: theme.palette.text.secondary }}
                    subjectMarks={
                      analyticsData.data.skipped
                        ? analyticsData.data.skipped
                        : 0
                    }
                  />
                </Grid>
              )}
            </DialogContentText>
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default AnalyticsPopUp;
