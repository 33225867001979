import { Grid, Hidden } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CheckBoxOutlineBlankRoundedIcon from "@material-ui/icons/CheckBoxOutlineBlankRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../assets/theme";
import { formatDuration } from "../../common/formateDuration";
import { fetchCompareQuesDetails } from "../../redux/actions/resultPageActions/actionCreators";
import NetworkProgress from "../NetworkProgress";
import DataTable from "./DataTable";
import ContentWrap from "../HomePage/Components/ContentWrap";
const useStyles = makeStyles(() => ({
  container: {
    // padding: "0 20% 0 20%",
    // width: "100vw",
  },
  "@media(max-width: 1024px)": {
    container: {
      // padding: "0 1% 0 1%",
    },
  },
  correct: { color: theme.palette.info.main },
  wrong: { color: theme.palette.text.primary },
  contentHead: {
    padding: "20px 0",
  },
}));
const CompareResult = (props) => {
  const tableHead = ["#", "Status", "My Time / Average ", "C", "W", "U"];
  const [data, setData] = useState([]);
  const compareResult = useSelector((state) => state.compareResult);
  const dispatch = useDispatch();

  useEffect(() => {
    const dataS = [];
    !compareResult.isLoading &&
      compareResult.data.questionDetails.map((detail) => {
        const valList = [];
        if (detail.yourTimeTaken) {
          detail.yourTimeTaken = formatDuration(detail.yourTimeTaken);
        }
        if (detail.averageTimeTaken) {
          detail.averageTimeTaken = formatDuration(detail.averageTimeTaken);
        }
        Object.values(detail).forEach((value) => {
          if (value == "C") {
            valList.push(
              <CheckRoundedIcon
                fontSize="inherit"
                className={classes.correct}
              />
            );
          } else if (value == "W" || value == "W") {
            valList.push(
              <ClearRoundedIcon fontSize="inherit" className={classes.wrong} />
            );
          } else if (value == "U") {
            valList.push(
              <CheckBoxOutlineBlankRoundedIcon
                fontSize="inherit"
                color="disabled"
              />
            );
          } else valList.push(<strong>{String(value)}</strong>);
        });
        dataS.push(valList);
      });
    setData(dataS);
  }, [compareResult]);
  useEffect(() => {
    const body = JSON.stringify({
      student_id: props.id,
      test_name: props.test_name,
    });
    dispatch(fetchCompareQuesDetails(body));
  }, []);
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container className={classes.container}>
        <div style={{ width: "inherit" }}>
          <ContentWrap backgroundColor="white">
            {/* comparison heading */}
            <Hidden only={["xs"]}>
              <Grid item xs={12} className={classes.contentHead}>
                <Typography variant="h3" color="textPrimary">
                  Compare Result with&nbsp;
                  {data[0] ? data[0][4].props.children : ""}&nbsp;Students
                </Typography>
              </Grid>
            </Hidden>
            <Hidden only={["md", "lg", "xl", "sm"]}>
              <Grid item xs={12} className={classes.contentHead}>
                <Typography variant="subtitle1" color="textPrimary">
                  Compare Result
                </Typography>
              </Grid>
            </Hidden>
            {/* table items */}
            {compareResult.isLoading ? (
              <NetworkProgress />
            ) : compareResult.error == null ? (
              <DataTable
                style={{ width: "100%" }}
                tableData={Object.values(data)}
                tableHead={tableHead}
                heading="Students Attempted"
                questionNos={data[0] ? data[0][4].props.children : ""}
              />
            ) : (
              compareResult.error
            )}
          </ContentWrap>
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default CompareResult;
