import CheckBoxOutlineBlankRoundedIcon from "@material-ui/icons/CheckBoxOutlineBlankRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import React, { useEffect, useState } from "react";
import { ReactComponent as Award } from "../../assets/img/awardNew.svg";
import theme from "../../assets/theme";
import GradesList from "./GradesList";

function CompareMarksAndGrades({ ranks }) {
  const [dataFromProps, setDataFromProps] = useState({});
  useEffect(() => {
    if (ranks && ranks.yourDetails) {
      setDataFromProps(ranks);
    }
  }, [ranks]);
  return (
    <React.Fragment>
      <GradesList
        topicName="Answers"
        topicColor={{ color: theme.palette.text.primary }}
        correct={<CheckRoundedIcon />}
        wrong={<ClearRoundedIcon />}
        unattempted={<CheckBoxOutlineBlankRoundedIcon />}
        totalMarks={<Award />}
      />
      <br />
      <GradesList
        topicName="My results"
        topicColor={{ fontWeight: "normal" }}
        correct={
          dataFromProps.yourDetails && dataFromProps.yourDetails.c
            ? dataFromProps.yourDetails.c
            : 0
        }
        wrong={
          dataFromProps.yourDetails && dataFromProps.yourDetails.w
            ? dataFromProps.yourDetails.w
            : 0
        }
        unattempted={
          dataFromProps.yourDetails && dataFromProps.yourDetails.u
            ? dataFromProps.yourDetails.u
            : 0
        }
        totalMarks={
          dataFromProps.yourDetails && dataFromProps.yourDetails.m
            ? dataFromProps.yourDetails.m
            : 0
        }
      />
      <GradesList
        topicName="Average"
        topicColor={{ fontWeight: "normal" }}
        correct={
          dataFromProps.yourDetails && dataFromProps.Average.c
            ? dataFromProps.Average.c
            : 0
        }
        wrong={
          dataFromProps.yourDetails && dataFromProps.Average.w
            ? dataFromProps.Average.w
            : 0
        }
        unattempted={
          dataFromProps.yourDetails && dataFromProps.Average.u
            ? dataFromProps.Average.u
            : 0
        }
        totalMarks={
          dataFromProps.yourDetails && dataFromProps.Average.m
            ? dataFromProps.Average.m
            : 0
        }
      />
      <GradesList
        topicName="Topper"
        topicColor={{ fontWeight: "normal" }}
        correct={
          dataFromProps.yourDetails && dataFromProps.TopperDetails.c
            ? dataFromProps.TopperDetails.c
            : 0
        }
        wrong={
          dataFromProps.yourDetails && dataFromProps.TopperDetails.w
            ? dataFromProps.TopperDetails.w
            : 0
        }
        unattempted={
          dataFromProps.yourDetails && dataFromProps.TopperDetails.u
            ? dataFromProps.TopperDetails.u
            : 0
        }
        totalMarks={
          dataFromProps.yourDetails && dataFromProps.TopperDetails.m
            ? dataFromProps.TopperDetails.m
            : 0
        }
      />
    </React.Fragment>
  );
}

export default CompareMarksAndGrades;
