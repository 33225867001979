import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box } from '@material-ui/core';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';

const LogoutConfirmation = ({ open, onClose, onConfirm, lottieOptions }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm Logout"}</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Lottie options={lottieOptions} height={100} width={100} />
        </Box>
        <DialogContentText id="alert-dialog-description">
          <b>Are you sure you want to Quit?</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="secondary"
        >
          Disagree
        </Button>
        <Button
          onClick={() => {
            onClose();
            onConfirm();
          }}
          color="primary"
          autoFocus
          variant="contained"
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LogoutConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  lottieOptions: PropTypes.object.isRequired,
};

export default LogoutConfirmation;