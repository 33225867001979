import React from 'react'
import { Button } from '@material-ui/core'
import { useStyles } from '../../Attendance/styles'

const TestTypeButtons = ({ onSelectTestType, testTypes, selectedTestType }) => {
  const classes = useStyles()
  return (
    <div className={classes.testTypeButtons}>
      {testTypes.map((type, index) => (
        <Button
          key={index}
          variant='contained'
          onClick={() => onSelectTestType(index)}
          color={index === selectedTestType ? 'primary' : ''}
          className={
            index === selectedTestType ? classes.activeBtn : classes.inActiveBtn
          }
        >
          {type}
        </Button>
      ))}
    </div>
  )
}

export default TestTypeButtons
