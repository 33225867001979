// react
import React, { forwardRef } from "react";
// mui
import { makeStyles,withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";


export const CssTextField = withStyles({
    root: {
      "& label.Mui-focused": {
        
        fontSize: 10,
        color: "black",
      },
  
      "& .MuiInput-underline:after": {
        borderBottomColor: "#3BBDED",
        border: "1px solid #31456A",
        background: "#3BBDED0F",
      },
  
      "& .MuiFormLabel-root": {
        
        fontSize: "10px",
        lineHeight:"10px"
      },
      "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
          borderColor: "#3BBDED",
          background: "#3BBDED0F",
        },
        // "&.Mui-focused fieldset": {
        //   border: "1px solid  #ffff",
        // //   background: "#3BBDED0F",
  
        //   color: "#3BBDED",
        // },
        "&.Mui-focused fieldset": {
          border: "2px solid  #d1d1e0",
          //   background: "#3BBDED0F",
          borderRadius: "10px",
          color: "#3BBDED",
        },
        "& fieldset": {
          borderColor: "#DFE4E7",
        //   borderRadius: `20px 20px 20px 20px`,
          height: 39,
        },
  
        "& .MuiFormLabel-root": {
          color: "#31456A",
          fontWeight: 600,
          fontSize: 13,
          
          height: 37,
        
        },
      },
    },
  })(TextField);

const CustomDropdown = forwardRef((props, ref) => {
  const {
    dataToDisplay,
    value,
    handleDropdownValueChange,
    label,
    isLoading = Boolean,
    name,
    variant,
    multiple,
    // width = 220,
    width,
    textFieldProps,
    filled,
  } = props;

  return (
    <div>
      <Autocomplete
        id="combo-box-demo"
        ref={ref}
        // freeSolo
        multiple={multiple}
        limitTags={1}
        options={dataToDisplay}
        getOptionLabel={(option) => option}
        style={{ width: width }}
        value={value}
        name={name}
        size="small"
        onChange={handleDropdownValueChange}
        loading={isLoading}
        renderInput={(params) => {
          return (
            <CssTextField
              {...params}
              {...textFieldProps}
              label={label}
              focused={value !== "" ? true : null}
              fullWidth
             
              variant={variant ? variant : "outlined"}
              size="small"
            />
          );
        }}
      />
    </div>
  );
});

export default CustomDropdown;
