import { Card, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    boxShadow: "inset 0 0 10px rgba(0,0,0,0.1)",
    borderRadius: 15,
    padding: theme.spacing(2),
    background: props.backgroundColor || "#EBEFF3",
  }),
}));

const CardInsideOut = ({ children, backgroundColor = "#EBEFF3" }) => {
  const classes = useStyles({ backgroundColor });

  return <Card className={classes.root}>{children}</Card>;
};

export default CardInsideOut;
