import React, { useEffect, useState } from "react";
import CalendarComponent from "./AttComponent";

export default function FullWidthGrid() {
  return (
    <>
      <CalendarComponent />
    </>
  );
}
