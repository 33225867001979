import { Typography, Hidden, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import React, { useState } from "react";
import theme from "../../assets/theme";
import classNames from "classnames";
import { StyledTableCell, StyledTableRow } from "./CustomTableRowAndCells";
import SingleQuestionPopUp from "./SingleQuestionPopUp";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  table: {
    padding: "1%",
    borderRadius: "0px 0px 10px 10px",
    background: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  hoverRow: {
    "&:hover": {
      opacity: "0.8",
      boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    },
    cursor: "pointer",
  },
  tableRowStyle: {
    background: `${theme.palette.common.white}`,
  },
  tableRowAlt: {
    background: "rgba(236, 240, 243, 0.25)",
  },
  tableCellColor: {
    color: theme.palette.text.secondary,
  },
}));
function DataTable({ tableData, tableHead, heading, questionNos }) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [paperData, setPaperData] = useState([]);
  const results = useSelector((state) => state.allResult);
  const handleCloseAlertBox = () => {
    setConfirmOpen(false);
    setPaperData({});
  };
  const handleClick = (key) => {
    const finalData = results.data.filter((data, index) => index === key);
    setConfirmOpen(true);
    setPaperData(finalData);
  };
  const classes = useStyles();
  return (
    <React.Fragment>
      <TableContainer className={classes.table}>
        <Hidden only={["md", "lg", "xl", "sm"]}>
          <Box padding="0 16px 16px 0">
            <Grid container direction="row" justify="space-between">
              <Typography variant="subtitle2">{heading}</Typography>
              <Typography variant="subtitle2">{questionNos}</Typography>
            </Grid>
          </Box>
        </Hidden>
        <Table size="small">
          {/* table headings */}
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">{tableHead[0]}</StyledTableCell>
              <StyledTableCell align="left">
                {tableHead[1] === "Status" ? (
                  <CheckRoundedIcon fontSize="inherit" />
                ) : (
                  ""
                )}
              </StyledTableCell>
              <StyledTableCell align="left">{tableHead[2]}</StyledTableCell>
              <StyledTableCell align="right">
                <Typography
                  variant="subtitle1"
                  style={{ color: theme.palette.info.main }}
                >
                  {tableHead[3]}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1" color="textPrimary">
                  {tableHead[4]}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1" color="textSecondary">
                  {tableHead[5]}
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {/* table contents */}
          <TableBody>
            {tableData.map((prop, key) => {
              return (
                <StyledTableRow
                  key={key}
                  className={classNames(
                    classes.hoverRow,
                    key % 2 == 1 ? classes.tableRowStyle : classes.tableRowAlt
                  )}
                  onClick={() => handleClick(key)}
                >
                  <StyledTableCell className={classes.tableCellColor}>
                    {prop[0].props.children}
                  </StyledTableCell>
                  <StyledTableCell>{prop[1]}</StyledTableCell>
                  <StyledTableCell className={classes.tableCellColor}>
                    {`${prop[2].props.children} / ${prop[3].props.children}`}
                  </StyledTableCell>
                  <StyledTableCell align="right">{prop[5]}</StyledTableCell>
                  <StyledTableCell align="right">{prop[7]}</StyledTableCell>
                  <StyledTableCell align="right">{prop[6]}</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <SingleQuestionPopUp
        open={confirmOpen}
        questionData={paperData ? paperData[0] : null}
        handleClose={handleCloseAlertBox}
      />
    </React.Fragment>
  );
}

export default DataTable;
