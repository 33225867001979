import { Paper, withStyles } from "@material-ui/core";
import React from "react";
import theme from "../../assets/theme";
const PaperComponent = withStyles(() => ({
  root: {
    padding: "28px 0 28px 20px",
    margin: theme.spacing(1),
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
    border: "none",
    borderRadius: "10px",
    overflow: "auto",
    width: "inherit",
    [theme.breakpoints.down("xs")]: {
      width: "82vw",
    },
  },
}))((props) => <Paper {...props} />);
export { PaperComponent };
