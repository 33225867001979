import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import theme from "../../assets/theme";
const useStyles = makeStyles(() => ({
  textColor: {
    color: theme.palette.info.main,
  },
}));
function GradesList(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={props.topicColor}
          >
            {props.topicName}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container display="flex" justify="space-between">
            <Typography align="right" variant="subtitle1" color="textPrimary">
              {props.correct}
            </Typography>
            <Typography align="right" variant="subtitle1" color="textPrimary">
              {props.wrong}
            </Typography>
            <Typography align="right" variant="subtitle1" color="textPrimary">
              {props.unattempted}
            </Typography>
            <Typography
              variant="subtitle1"
              align="right"
              className={classes.textColor}
            >
              {props.totalMarks}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default GradesList;
