// react
import React from "react";
import { useState, useEffect } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import borderRadius from "highcharts-border-radius";
// loadash
import cloneDeep from "lodash/cloneDeep";
import { Box, Typography, Grid } from "@material-ui/core";
import Dropdown from "../../../components/CustomDropdown/Dropdown";
import { useStyles, StyledBadge } from "../Components/styles";
import colors from "./colors";
import useTimeData from "./useTimeData";

import CustomCheckBoxListMenu from "../Components/CustomCheckBoxListMenu";

borderRadius(Highcharts);
const a = {
  MPC: "JEEMAIN_2022",
  BiPC: "NEET_2021",
};
const examTypes = ["JEEMAIN_2022", "NEET_2021"];
const EaxmSummaryChart = ({ data1, subjects, testTypes, group, testsObj }) => {
  const classes = useStyles();
  const [exam, setExam] = useState("");
  const [finalData, setFinalData] = useState([]);
  const [values, setValues] = useState([]);

  const [timeData] = useTimeData({ exam, testsObj, group, values });
  const [names, setNames] = useState([]);

  const [testNames, setTestNames] = useState([]);
  const [graphChecked, setGraphChecked] = useState(names.slice(0, 2));
  const [state, setState] = React.useState({
    checkedA: true,
  });
  const [graphAnchorEl, setGraphAnchorEl] = useState(null);
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const matchingTypes = testTypes.filter((testType) =>
    examTypes.includes(testType)
  );

  const setGraphopen = Boolean(graphAnchorEl);
  const handleGraphClick = (event) => {
    setGraphAnchorEl(event.currentTarget);
  };
  const handleGraphClose = () => {
    setGraphAnchorEl(null);
  };
  const handleGraphToggle = (val) => () => {
    const currentIndex = graphChecked.indexOf(val);
    const newChecked = [...graphChecked];
    if (currentIndex === -1) {
      newChecked.push(val);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setGraphChecked(newChecked);
  };
  useEffect(() => {
    if (exam === "") {
      const values1 = data1.filter((test) => test.examType === a[group]);
      setValues(values1);
    } else {
      const values1 = data1.filter((test) => test.examType === exam);
      setValues(values1);
    }
  }, [exam, data1]);

  useEffect(() => {
    setTestNames(values.map((obj) => obj.testName));
  }, [values]);
  useEffect(() => {
    if (timeData && timeData !== undefined) {
      setNames(Object.keys(timeData));
    }
  }, [timeData]);

  useEffect(() => {
    if (timeData && timeData !== undefined) {
      const filteredData = Object.keys(timeData)
        .filter((key) => graphChecked.includes(key))
        .reduce((obj, key) => {
          obj[key] = timeData[key];
          return obj;
        }, {});

      const transformData = (data) => {
        const result = [];

        subjects.forEach((subject) => {
          const subjectData = {
            name: subject,
            data: [],
            stack: "subjectMarks",
          };

          Object.keys(data).forEach((key) => {
            const subjectMarks = data[key][subject] || 0;
            subjectData.data.push(subjectMarks);
          });

          result.push(subjectData);
        });

        return result;
      };

      const convertedData = transformData(filteredData).map((subject) => ({
        name: subject.name,
        data: subject.data.map((ms) => {
          let seconds = Math.floor((ms / 1000) % 60);
          let minutes = Math.floor((ms / (1000 * 60)) % 60);
          return Number(`${minutes}.${seconds}`);
        }),
        time: subject.data.map((ms) => {
          let seconds = Math.floor((ms / 1000) % 60);
          let minutes = Math.floor((ms / (1000 * 60)) % 60);
          return { minutes, seconds };
        }),
        // convert milliseconds to minutes
        stack: subject.stack,
      }));

      setFinalData(convertedData);
    }
  }, [exam, timeData, graphChecked, names]);
  useEffect(() => {
    setGraphChecked(names.slice(0, 6));
  }, [exam, names]);

  let chartData = [];
  const add = () => {
    finalData.map((i) => {
      if (colors[i.name]) {
        chartData.push({ ...i, ...colors[i.name] });
      }
    });
  };
  add();

  const defaultOptionsDataSet1 = {
    chart: {
      type: "areaspline",
      zoomType: "x",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
      align: "left",
    },
    xAxis: {
      categories: graphChecked || [],
      crosshair: {
        width: 2,
        color: "gray",
        dashStyle: "shortdot",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },

    plotOptions: {
      areaspline: {
        threshold: null,
      },
    },

    tooltip: {
      shared: true,
      crosshairs: true,
    },
    credits: {
      enabled: false,
    },
    series: chartData || [],
    // tooltip: {
    //   shared: true,
    //   useHTML: true,
    //   formatter: function(props) {
    //     return `

    //     <table>
    //     <tr>
    //       <td>Subject</td>
    //       <td>${this.series.name!==undefined?this.series.name:0}</td>
    //     </tr>
    //     <tr>
    //         <td>subject</td>
    //         <td>${this.point.time.map((item)=>`${item.minutes}:${item.seconds}`)}</td>
    //       </tr>

    //     </table>

    //    `;
    //   },

    // },
  };
  const currentOptions = cloneDeep(defaultOptionsDataSet1);
  return (
    <>
      <Grid container spacing={3} direction="row" display="flex">
        <Grid item xs={4}>
          <Typography
            style={{ color: " #31456A", fontSize: "18px", fontWeight: 600 }}
          >
            Time Spent
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            width={180}
            dataToDisplay={testTypes || []}
            handleDropdownValueChange={(e, item) => {
              setExam(item);
            }}
            value={exam || ""}
            textFieldProps={{
              size: "small",
              InputLabelProps: { shrink: false },
              placeholder: matchingTypes[0],
              label: "exam Type",
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <CustomCheckBoxListMenu
            list={names || []}
            open={setGraphopen}
            handleButtonClick={handleGraphClick}
            anchorEl={graphAnchorEl}
            handleClose={handleGraphClose}
            handleToggle={handleGraphToggle}
            checked={graphChecked}
          />
        </Grid>
      </Grid>
      <div>
        <HighchartsReact highcharts={Highcharts} options={currentOptions} />
      </div>
    </>
  );
};
export default EaxmSummaryChart;
