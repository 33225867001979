const paper_1_2k10 = {
   "questions":{ "1": "1",
    "2": "1",
    "3": "1",
    "4": "1",
    "5": "1",
    "6": "1",
    "7": "1",
    "8": "1",
    "9": "2",
    "10": "2",
    "11": "2",
    "12": "2",
    "13": "2",
    "14": "3",
    "15": "3",
    "16": "3",
    "17": "3",
    "18": "3",
    "19": "4",
    "20": "4",
    "21": "4",
    "22": "4",
    "23": "4",
    "24": "4",
    "25": "4",
    "26": "4",
    "27": "4",
    "28": "4",
    "29": "1",
    "30": "1",
    "31": "1",
    "32": "1",
    "33": "1",
    "34": "1",
    "35": "1",
    "36": "1",
    "37": "2",
    "38": "2",
    "39": "2",
    "40": "2",
    "41": "2",
    "42": "3",
    "43": "3",
    "44": "3",
    "45": "3",
    "46": "3",
    "47": "4",
    "48": "4",
    "49": "4",
    "50": "4",
    "51": "4",
    "52": "4",
    "53": "4",
    "54": "4",
    "55": "4",
    "56": "4",
    "57": "1",
    "58": "1",
    "59": "1",
    "60": "1",
    "61": "1",
    "62": "1",
    "63": "1",
    "64": "1",
    "65": "2",
    "66": "2",
    "67": "2",
    "68": "2",
    "69": "2",
    "70": "3",
    "71": "3",
    "72": "3",
    "73": "3",
    "74": "3",
    "75": "4",
    "76": "4",
    "77": "4",
    "78": "4",
    "79": "4",
    "80": "4",
    "81": "4",
    "82": "4",
    "83": "4",
    "84": "4"},
    "sections": {
        1: [1,29,57],
        2: [9,37,65],
        3: [14,42,70],
        4: [19,47,75]
    }
} 

export  default paper_1_2k10;