// import {Hidden} from "@material-ui/core";
import { Hidden } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
// Material components
import Grid from "@material-ui/core/Grid/index";
import MenuItem from "@material-ui/core/MenuItem";
//import validate from "validate.js";
//import _ from "underscore";
// Material helpers
import { withStyles } from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography";
import quote from "assets/img/quote.png";
// Externals
import PropTypes from "prop-types";
import React, { Component, Fragment, Suspense, lazy } from "react";
import { Link, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import { domainConfig, sendOTP, userRegister } from "../../apis/UserService";
import AlertComponent from "../../mycomponents/AlertComponent";
import {
  CustomLabel,
  CustomOutline,
  CustomProgress,
  CustomSelect,
} from "./components/CustomInput";
// poster import
import poster from "../../assets/RegisterExamIcons/sign_up_poster.jpeg";
// Component styles
import { getAppSettingsInfo } from "../../apis/appSettings";
import NetworkProgress from "../../mycomponents/NetworkProgress";
import profileEditCondition from "../../utils/profileEditCondition";
import InputBase1 from "./components/InputBase1";
import restrictedLevel from "./restrictedLevel";
import styles from "./styles";
const LazyLoadImages = lazy(() => import("../../mycomponents/LazyLoadImages"));

// Form validation schema
//import schema from "./schema";

// Service methods
// const signIn = () => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(true);
//     }, 1500);
//   });
// };
const alertType = {
  error: "error",
  warning: "warning",
  success: "success",
};
// for
const batchMap = {
  MPC_CBSE: "X0210779",
  MPC_STATE: "X0210774",
  BIPC_CBSE: "X0210773",
  BIPC_STATE: "X0210772",
  MEC_CBSE: "X0210768",
  MEC_STATE: "X0210769",
  MPC: "X0210779",
};
const motionBatchMap = {
  MPC_CBSE: "X0210767",
  MPC_SSC: "X0210765",
  BIPC_CBSE: "X0210766",
  BIPC_SSC: "X0210764",
};
class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mismatch: false,
      quote: "",
      logo: "",
      mini: "",
      netReq: false,
      loadResend: false,
      values: {
        name: "",
        lastName: "",
        password: "",
        phoneNumber: "",
        class: "",
        otp: "",
        branch: "",
        branchId: "",
        confirmPassword: "",
        courseName: "",
      },
      touched: {
        userId: false,
        lastName: false,
        password: false,
        class: false,
        phoneNumber: false,
        branch: false,
        branchId: false,
        otp: false,
        confirmPassword: false,
        courseName: false,
      },
      errors: {
        userId: null,
        lastName: null,
        password: null,
        confirmPassword: null,
        class: null,
        phoneNumber: null,
        branch: null,
        branchId: null,
        otp: null,
        courseName: null,
      },
      classes: [
        { class: 8, className: "VIII" },
        { class: 9, className: "IX" },
        { class: 10, className: "X" },
      ],
      isValid: true,
      isLoading: false,
      submitError: null,
      signUpStatus: {
        message: "",
        alertType: null,
      },
      confirmOpen: false,
    };
  }
  setDomainConfig = async () => {
    let data = await domainConfig(window.location.host);
    if ((data.status = 200)) {
      data = data.data;
      if (data && data.signup && data.signup.branches) {
        this.setState({
          instituteId: data.instituteId,
          branches: data.signup.branches,
        });
      }
      if (data && data.instituteId) {
        this.setState({
          instituteId: data.instituteId,
        });
      }
      if (data && data.signin) {
        this.setState({
          miniDimensions: data.signin.mini,
        });
      }
    }
  };
  componentDidMount() {
    const { history } = this.props;
    // get class for bhashyam from store institute
    // if (window.location.host.includes("bhashyam")) {
    //   store.get("examFields")
    //     ? this.setState((prevState) => ({
    //         ...prevState,
    //         values: {
    //           ...prevState.values,
    //           class: store.get("examFields").grade,
    //         },
    //       }))
    //     : history.push("/b-sat-registration");
    // }
    window.recaptchaVerifier = new window.firebase.auth.RecaptchaVerifier(
      "register-button",
      {
        size: "invisible",
        callback: function(response) {
          // window.firebase.auth().recaptchaVerifier
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //   onSignInSubmit();
          this.handleSignIn();
        },
      }
    );
    this.setDomainConfig();
    // console.log(window.location.host);
    const company = window.location.hostname.split(".")[0];
    const mainlogo_url =
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/" +
      company +
      ".png";
    const minilogo_url =
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/" +
      company +
      "_mini.png";
    this.setState({ logo: mainlogo_url, mini: minilogo_url });
    if (window.location.host.includes("localhost")) {
      this.setState({
        logo: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/onesaz.png",
        mini:
          "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/onesaz_mini.png",
        quote: quote,
      });
    }
    if (window.location.host.includes("bhashyam")) {
      this.setState({
        logo: poster,
      });
    }
    this.forceUpdate();
  }

  handleTypeSelect = (event) => {
    this.state.values[event.target.name] = event.target.value;
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name == "branch") {
      this.setState({ branchMissed: false });
    }
  };

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  showOTP = false;

  otpSent = false;

  errorMessage = null;

  handleFieldChange = (field, value) => {
    // console.log(field == "otp");
    if (field == "otp") {
      const newState = { ...this.state };
      newState.errors.otp = null;
      this.setState(newState);
      this.forceUpdate();
    }
    const newState = { ...this.state };
    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;
    newState.mismatch = false;
    if (
      newState.errors.userId ||
      newState.errors.lastName ||
      newState.errors.password ||
      newState.errors.confirmPassword ||
      newState.errors.phoneNumber ||
      newState.errors.class ||
      newState.errors.branch ||
      newState.errors.otp ||
      newState.errors.branchId ||
      newState.errors.courseName
    ) {
      Object.keys(newState.errors).forEach((v) => (newState.errors[v] = false));
    }
    //
    if (field == "phoneNumber") {
      newState.showOTP = false;
      this.otpSent = false;
    }
    this.setState(newState);
  };

  resendOTP = async () => {
    try {
      let res = await sendOTP({
        phoneNumber: this.state.phoneNumber,
      });
    } catch (err) {
      alert(err);
    }
  };
  handleCloseAlertBox = () => {
    this.setState({ ...this.state, confirmOpen: false });
  };

  alertMessageAndType = (message, alert = alertType.error) => {
    const alertState = { ...this.state };
    alertState.signUpStatus.message = message;
    alertState.signUpStatus.alertType = alert;
    alertState.confirmOpen = true;
    this.setState(alertState);
    this.setState(alertState);
  };

  validateForm() {
    let values = this.state.values;
    let errors = { ...this.state.errors };
    let formIsValid = true;

    if (!values.name) {
      formIsValid = false;
      errors.userId = "*Please enter your name.";
    }
    if (!values.lastName) {
      formIsValid = false;
      errors.lastName = "*Please enter your last name.";
    }
    if (!values.phoneNumber) {
      formIsValid = false;
      errors.phoneNumber = "*Please enter your mobile no.";
    }
    if (
      !values.courseName &&
      (window.location.host.includes("viitjee") ||
        window.location.host.includes("motion"))
    ) {
      formIsValid = false;
      errors.courseName = "*Please select a course.";
    }
    if (!values.password) {
      formIsValid = false;
      errors.password = "*Please enter your password.";
    }
    if (!values.class) {
      formIsValid = false;
      errors.class = "*Please choose a class from the given list";
    }
    if (values.branch && values.branch == "") {
      formIsValid = false;
      errors.branch = "*Please choose a branch";
    }
    if (values.password !== values.confirmPassword) {
      formIsValid = false;
      this.setState({ mismatch: true });
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  handleResentOtp = async () => {
    const { values } = this.state;
    this.setState({ loadResend: true });
    await sendOTP({
      phoneNumber: values.phoneNumber,
    });
    this.setState({ loadResend: false });
  };
  handleSignIn = async () => {
    try {
      const queryString = new URLSearchParams(this.props.location.search);
      const referCode = queryString.get("referralCode");
      if (this.validateForm()) {
        const { history } = this.props;
        const { values } = this.state;

        if (
          this.state.branches &&
          (values.branch == "" || values.name == null)
        ) {
          this.setState({ branchMissed: true });
          return;
        }

        if (!this.otpSent) {
          // await sendOTP({
          //   phoneNumber: values.phoneNumber,
          // });
          this.setState({
            netReq: true,
          });
          //   this.setState({netReq: false})
          var phoneNumber = values.phoneNumber;
          var appVerifier = window.recaptchaVerifier; // ===google===

          var confirmationResult = await window.firebase // ===google===
            .auth()
            .signInWithPhoneNumber("+91" + phoneNumber, appVerifier); // ===google===
          console.log(confirmationResult.verificationId); // ===google===
          this.setState({
            showOTP: true,
            confirmationResult: confirmationResult, // ===google===
            netReq: false,
          });
          this.otpSent = true;
          // .then(function (confirmationResult) {
          // // SMS sent. Prompt user to type the code from the message, then sign the
          // // user in with confirmationResult.confirm(code).
          //     this.setState({
          //         showOTP: true
          //     })
          //     // // this.forceUpdate();
          //     // this.otpSent = true;
          // window.confirmationResult = confirmationResult;

          // // console.log(confirmationResult);
          //   return confirmationResult.confirm("12345")
          // }).catch(function (error) {
          // // Error; SMS not sent
          // // ...

          // if(error.code=="auth/invalid-verification-code"){
          //     console.log(error)
          //     this.setState({errors: {otp: "OTP is invalid" }})
          // }
          // });
        } else {
          this.setState({ netReq: true });
          if (values.otp == "" || values.otp == null) {
            // alert("please enter the otp sent to your phone");
            this.alertMessageAndType(
              "please enter the otp sent to your phone.",
              alertType.warning
            );
          }
          try {
            var verificationResult = await this.state.confirmationResult.confirm(
              //===google===
              values.otp // ===google===
            ); // ===google===
            // var credential = window.firebase.auth.PhoneAuthProvider.credential(this.state.confirmationResult.verificationId, values.otp);
          } catch (error) {
            this.setState({
              errors: {
                ...this.state.errors,
                otp: "Invalid otp",
              },
            });
            console.log(error, this.state);
            this.setState({ netReq: false });
            return;
          }
          var cuser = await window.firebase //    ===google===
            .auth() //    ===google===
            .currentUser.getIdToken(/* forceRefresh */ true); //   ===google===
          //   .then(function(idToken) {
          //     // Send token to your backend via HTTPS
          //     // ...
          //   }).catch(function(error) {
          //     // Handle error
          //   });
          console.log(cuser); //    ===google===
          const userBody = {
            phoneNumber: values.phoneNumber,
            name: values.name,
            password: values.password,
            class: values.class,
            otp: values.otp,
            idToken: cuser, //       ===google===
          };
          if (values.lastName) userBody.lastName = values.lastName;
          // if(this.state.instituteId){
          userBody.instituteId = this.state.instituteId;
          // }
          // attach referral code to userBody
          if (referCode) {
            userBody.referredBy = referCode;
          }
          ///////////////////////////////////
          console.log(userBody);
          if (values.branch && values.branch.length > 3) {
            userBody.branchId = values.branch;
            userBody.branchName = this.state.branches.filter((branch) => {
              return branch.id == values.branch;
            })[0].name;
          }
          if (window.location.host.includes("valleyoak")) {
            userBody.instituteId = "5e521e0da587c5bcefdb7c90";
            userBody.branchid = "5e521e0da587c5bcefdb7c90_1";
            userBody.level = "211210";
          }
          if (
            window.location.hostname == "onesaz.com" ||
            window.location.hostname == "www.onesaz.com"
          ) {
            userBody.instituteId = "5d679d49c136660a09596d85";
            userBody.branchid = "5d679d49c136660a09596d85_1";
          }
          // add level id for bhashyam new registration
          // if (window.location.host.includes("bhashyam")) {
          //   const dataTopPopulate = store.get("examFields");
          //   const { grade, examType, examDate, examTimeSlot } = dataTopPopulate;
          //   userBody.level = "2119611";
          //   userBody.examDate = `${examDate} 2022`;
          //   userBody.examTimeSlot = examTimeSlot;
          //   userBody.class = grade;
          //   if (grade > 6) {
          //     userBody.stream = examType;
          //     userBody.course =
          //       examType == "NEET"
          //         ? "BiPC"
          //         : examType == "JEE"
          //         ? "MPC"
          //         : examType;
          //   }
          //   console.log(userBody);
          // }
          // add level id for viitjee new registration
          if (window.location.host.includes("viitjee")) {
            userBody.level = "X0211026";
            userBody.course = values.courseName == "IIT" ? "MPC" : "BiPC";
          }
          if (window.location.host.includes("agastya")) {
            userBody.level = "X0210761";
          }
          if (window.location.host.includes("sriabhidakp")) {
            userBody.level = "1918210";
          }
          if (window.location.host.includes("miity")) {
            userBody.level = "X0210780";
            userBody.branchId = "MIITY";
            userBody.section = "X0210780_101";
          }
          if (window.location.host.includes("excellencia")) {
            if (userBody.class == "6") userBody.level = "X0210747";
            if (userBody.class == "7") userBody.level = "X0210746";
            if (userBody.class == "8") userBody.level = "X0210770";
            if (userBody.class == "9") userBody.level = "X0210771";
            if (userBody.class == "11") {
              userBody.course = values.courseName;
              userBody.level = batchMap[values.courseName] || "X0210779";
            }
          }
          if (window.location.host.includes("motion")) {
            if (userBody.class == "11") {
              userBody.course = values.courseName;
              userBody.level = motionBatchMap[values.courseName];
              userBody.branchId = "TESTING";
            }
          }
          let res = await userRegister(userBody);
          if (res.data.status !== null && res.data.status == "FAILURE") {
            // alert(res.data.message);
            this.alertMessageAndType(res.data.message, alertType.error);
          } else {
            // alert("Succefully Registered!");
            localStorage.setItem("isAuthenticated", true);
            store.set("user", res.data);

            const appSettings = await getAppSettingsInfo({
              instituteId: res.data.institute || res.data.instituteId,
            });
            if (appSettings && appSettings.result);
            {
              const inRestrictedLevel =
                restrictedLevel.includes(
                  `${store.get("user") ? store.get("user").level : ""}`
                ) && !res.data.accountType;
              const settings = inRestrictedLevel
                ? {
                    ...appSettings.result,
                    studentAppSettings: {
                      exams: true,
                      profile: true,
                    },
                  }
                : appSettings.result;
              store.set("appSettings", settings);
            }
            // removing examFeilds from local store for bhashyam
            // if (window.location.hostname.includes("bhashyam")) {
            //   store.remove("examFields");
            // }
            // check for after login then redirect accordingly
            if (profileEditCondition()) {
              history.push("/profile");
            } else history.push("/home-page");
          }
          this.setState({ netReq: false });
        }
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        serviceError: error,
        netReq: false,
      });
    }
  };
  handleGoogleOTPResend = async () => {
    try {
      if (this.otpSent) {
        this.setState({ netReq: true });

        const phoneNumber = this.state.values.phoneNumber;
        const appVerifier = window.recaptchaVerifier;

        const confirmationResult = await window.firebase
          .auth()
          .signInWithPhoneNumber("+91" + phoneNumber, appVerifier);

        console.log(confirmationResult.verificationId);
        this.setState({
          confirmationResult: confirmationResult,
          netReq: false,
        });
      } else {
        console.log("No OTP sent yet, cannot resend.");
      }
    } catch (error) {
      console.error("Error resending OTP: ", error);
      this.setState({ netReq: false, error: "Failed to resend OTP" });
    }
  };
  render() {
    const { classes } = this.props;
    const { hostname } = window.location;
    const {
      values,
      touched,
      errors,
      isValid,
      // isLoading,
      signUpStatus,
      loadResend,
    } = this.state;
    const showPasswordError = touched.password && errors.password;
    // extract common usable class values
    const classAndLabels = [
      {
        value: "8",
        label: "VIII",
      },
      {
        value: "9",
        label: "IX",
      },
      {
        value: "10",
        label: "X",
      },
      {
        value: "11",
        label: "XI",
      },
    ];
    // data to be displayed in class dropdown
    const classValues = hostname.includes("bhashyam")
      ? [
          {
            value: "4",
            label: "III-IV",
          },
          {
            value: "5",
            label: "IV-V",
          },
          {
            value: "6",
            label: "V-VI",
          },
          {
            value: "7",
            label: "VI-VII",
          },
          {
            value: "8",
            label: "VII-VIII",
          },
          {
            value: "9",
            label: "VIII-IX",
          },
          {
            value: "10",
            label: "IX-X",
          },
          // {
          //   value: "11",
          //   label: "X-XI",
          // },
        ]
      : hostname.includes("viitjee")
      ? [{ value: "10", label: "10th" }]
      : hostname.includes("excellencia")
      ? [
          { value: "6", label: "6th" },
          { value: "7", label: "7th" },
          { value: "8", label: "8th" },
          { value: "9", label: "9th" },
          { value: "11", label: "11th" },
        ]
      : hostname.includes("valleyoak")
      ? [{ value: "10", label: "X" }]
      : hostname.includes("miity")
      ? [{ value: "11", label: "11th" }]
      : hostname.includes("motion")
      ? [{ value: "11", label: "11th" }]
      : hostname.includes("agastya")
      ? [{ value: "10", label: "X" }]
      : hostname.includes("sriabhidakp")
      ? [{ value: "10", label: "10th" }]
      : [
          ...classAndLabels,
          {
            value: "12",
            label: "XII",
          },
        ].filter((item) => {
          if (
            window.location.hostname == "onesaz.com" ||
            window.location.hostname == "localhost" ||
            window.location.hostname == "www.onesaz.com"
          ) {
            if (item.value !== "11" && item.value !== "12") {
              return false;
            } else return true;
          }
          return true;
        });

    return (
      <Fragment>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item xs={12} lg={7}>
            <Hidden mdDown>
              <Suspense fallback={<NetworkProgress />}>
                <LazyLoadImages
                  alt="logo"
                  style={{ marginTop: "-5%" }}
                  src={this.state.logo}
                />
              </Suspense>
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={10} lg={5} style={{ minHeight: "100%" }}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{ minHeight: "100%", minWidth: "320px" }}
            >
              <Grid item xs={12}>
                <br />

                {/* <img width="130px" height="50px" src={this.state.mini}></img> */}

                <br />
                <br />
                <br />
              </Grid>
              {this.state.miniDimensions && (
                <Grid item xs={12}>
                  <Suspense fallback={<NetworkProgress />}>
                    <LazyLoadImages
                      alt="logo"
                      src={this.state.mini}
                      width={
                        this.state.miniDimensions.width
                          ? this.state.miniDimensions.width
                          : "100px"
                      }
                      height={
                        this.state.miniDimensions.height
                          ? this.state.miniDimensions.height
                          : "100px"
                      }
                    />
                  </Suspense>

                  <br />
                  <br />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography component="div" className={classes.registerHeader}>
                  {hostname.includes("miity")
                    ? "MSAT-2024 Registration"
                    : "Sign up"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.separator} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">
                  Enter name as per your 10<sup>th</sup> record.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <InputBase1
                  placeholder="First Name"
                  style={{ marginTop: "20px" }}
                  className={classes.textField}
                  label="First Name"
                  name="name"
                  onChange={(event) =>
                    this.handleFieldChange("name", event.target.value)
                  }
                  type="text"
                  value={values.name}
                  variant="outlined"
                />
                {errors.userId && (
                  <Typography className={classes.fieldError} variant="body2">
                    {errors.userId}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputBase1
                  placeholder="Last Name"
                  style={{ marginTop: "20px" }}
                  className={classes.textField}
                  label="lastName"
                  name="lastName"
                  onChange={(event) =>
                    this.handleFieldChange("lastName", event.target.value)
                  }
                  type="text"
                  value={values.lastName}
                  variant="outlined"
                />
                {errors.lastName && (
                  <Typography className={classes.fieldError} variant="body2">
                    {errors.lastName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputBase1
                  style={{ marginTop: "20px" }}
                  className={classes.textField}
                  placeholder="Whatsapp No."
                  name="phoneNumber"
                  onChange={(event) =>
                    this.handleFieldChange("phoneNumber", event.target.value)
                  }
                  type="text"
                  value={values.phoneNumber}
                  variant="outlined"
                />
                <Typography className={classes.fieldInfo} variant="body2">
                  &nbsp;<sup>*</sup>Please note that whatsapp no. is your
                  userId.
                </Typography>
                {errors.phoneNumber && (
                  <Typography className={classes.fieldError} variant="body2">
                    {errors.phoneNumber}
                  </Typography>
                )}
              </Grid>
              {hostname.includes("bhashyam") ? null : (
                <Grid item xs={12}>
                  <FormControl
                    style={{ marginTop: "20px", minWidth: "320px" }}
                    variant="outlined"
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <CustomLabel htmlFor="simple-select1">
                      {hostname.includes("bhashyam")
                        ? "Admission required in class"
                        : "Class"}
                    </CustomLabel>
                    <CustomSelect
                      placeholder="Your Class"
                      style={{ textAlign: "left" }}
                      MenuProps={{
                        className: classes.selectMenu,
                        // making grades in grid for bhashyam
                        // classes: {
                        //   list: hostname.includes('localhost') ? classes.selectClassMenu : null,
                        //   paper: hostname.includes('localhost') ? classes.selectClassPaper : null
                        // }
                      }}
                      variant="outlined"
                      classes={{
                        select: classes.select,
                      }}
                      value={values.class}
                      name="class"
                      onChange={this.handleTypeSelect}
                      input={
                        <CustomOutline
                          displayEmpty
                          placeholder="Your Class"
                          label="Class"
                          labelWidth={40}
                          name="class"
                          id="simple-select1"
                        />
                      }
                      inputProps={{
                        placeholder: "Your Class",
                        name: "class",
                        id: "simple-select1",
                      }}
                    >
                      {hostname.includes("bhashyam") ? null : (
                        <MenuItem
                          disabled
                          value=""
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                        >
                          Select Your class
                        </MenuItem>
                      )}
                      {classValues.map((val, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: hostname.includes("bhashyam")
                                ? classes.selectedMenuItem
                                : classes.selectMenuItemSelected,
                            }}
                            value={val.value}
                          >
                            {hostname.includes("bhashyam") ? (
                              <Typography
                                variant={"body1"}
                                className={classes.classOptionText}
                              >
                                {val.value}
                                <sup className={classes.powerTh}>th</sup>
                              </Typography>
                            ) : (
                              val.label
                            )}
                          </MenuItem>
                        );
                      })}
                    </CustomSelect>
                  </FormControl>
                  {errors.class && (
                    <Typography className={classes.fieldError} variant="body2">
                      {errors.class}
                    </Typography>
                  )}
                </Grid>
              )}

              {this.state.branches && (
                <Grid item xs={12}>
                  <FormControl
                    style={{ marginTop: "20px", minWidth: "320px" }}
                    variant="outlined"
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <CustomLabel htmlFor="simple-select2">
                      Select Branch
                    </CustomLabel>

                    <CustomSelect
                      placeholder="Select Branch"
                      style={{ textAlign: "left" }}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      variant="outlined"
                      classes={{
                        select: classes.select,
                      }}
                      value={values.branch}
                      name="branch"
                      onChange={this.handleTypeSelect}
                      input={
                        <CustomOutline
                          displayEmpty
                          placeholder="Your Branch"
                          label="Branch"
                          labelWidth={40}
                          name="branch"
                          id="simple-select2"
                        />
                      }
                      inputProps={{
                        placeholder: "Select Branch",
                        name: "branch",
                        id: "simple-select2",
                      }}
                    >
                      <MenuItem
                        disabled
                        value=""
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Select Branch
                      </MenuItem>

                      {this.state.branches.map((branch, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={branch.id}
                          >
                            {branch.name}
                          </MenuItem>
                        );
                      })}
                    </CustomSelect>
                    {this.state.branchMissed && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2"
                      >
                        {"Please Select branch"}
                      </Typography>
                    )}
                  </FormControl>
                  {errors.branch && (
                    <Typography className={classes.fieldError} variant="body2">
                      {errors.branch}
                    </Typography>
                  )}
                </Grid>
              )}
              {/* for course selection in viitjee  */}
              {hostname.includes("viitjee") && values.class == "10" && (
                <Grid item xs={12}>
                  <FormControl
                    style={{ marginTop: "20px", minWidth: "320px" }}
                    variant="outlined"
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <CustomLabel htmlFor="simple-select1">Course</CustomLabel>
                    <CustomSelect
                      placeholder="Course"
                      style={{ textAlign: "left" }}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      variant="outlined"
                      classes={{
                        select: classes.select,
                      }}
                      value={values.courseName}
                      name="courseName"
                      onChange={this.handleTypeSelect}
                      input={
                        <CustomOutline
                          displayEmpty
                          placeholder="Course"
                          label="Course"
                          labelWidth={40}
                          name="courseName"
                          id="simple-select1"
                        />
                      }
                      inputProps={{
                        placeholder: "Course",
                        name: "courseName",
                        id: "simple-select1",
                      }}
                    >
                      <MenuItem
                        disabled
                        value=""
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Select Course
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="IIT"
                      >
                        IIT
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="NEET"
                      >
                        NEET
                      </MenuItem>
                    </CustomSelect>
                  </FormControl>
                  {errors.courseName && (
                    <Typography className={classes.fieldError} variant="body2">
                      {errors.courseName}
                    </Typography>
                  )}
                </Grid>
              )}
              {hostname.includes("excellencia") && values.class == "11" && (
                <Grid item xs={12}>
                  <FormControl
                    style={{ marginTop: "20px", minWidth: "320px" }}
                    variant="outlined"
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <CustomLabel htmlFor="simple-select1">Course</CustomLabel>
                    <CustomSelect
                      placeholder="Course"
                      style={{ textAlign: "left" }}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      variant="outlined"
                      classes={{
                        select: classes.select,
                      }}
                      value={values.courseName}
                      name="courseName"
                      onChange={this.handleTypeSelect}
                      input={
                        <CustomOutline
                          displayEmpty
                          placeholder="Course"
                          label="Course"
                          labelWidth={40}
                          name="courseName"
                          id="simple-select1"
                        />
                      }
                      inputProps={{
                        placeholder: "Course",
                        name: "courseName",
                        id: "simple-select1",
                      }}
                    >
                      <MenuItem
                        disabled
                        value=""
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Select Course
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="MPC_CBSE"
                      >
                        MPC_CBSE
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="MPC_STATE"
                      >
                        MPC_STATE
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="BIPC_CBSE"
                      >
                        BIPC_CBSE
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="BIPC_STATE"
                      >
                        BIPC_STATE
                      </MenuItem>{" "}
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="MEC_CBSE"
                      >
                        MEC_CBSE
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="MEC_STATE"
                      >
                        MEC_STATE
                      </MenuItem>
                    </CustomSelect>
                  </FormControl>
                  {errors.courseName && (
                    <Typography className={classes.fieldError} variant="body2">
                      {errors.courseName}
                    </Typography>
                  )}
                </Grid>
              )}
              {hostname.includes("motion") && values.class == "11" && (
                <Grid item xs={12}>
                  <FormControl
                    style={{ marginTop: "20px", minWidth: "320px" }}
                    variant="outlined"
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <CustomLabel htmlFor="simple-select1">Course</CustomLabel>
                    <CustomSelect
                      placeholder="Course"
                      style={{ textAlign: "left" }}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      variant="outlined"
                      classes={{
                        select: classes.select,
                      }}
                      value={values.courseName}
                      name="courseName"
                      onChange={this.handleTypeSelect}
                      input={
                        <CustomOutline
                          displayEmpty
                          placeholder="Course"
                          label="Course"
                          labelWidth={40}
                          name="courseName"
                          id="simple-select1"
                        />
                      }
                      inputProps={{
                        placeholder: "Course",
                        name: "courseName",
                        id: "simple-select1",
                      }}
                    >
                      <MenuItem
                        disabled
                        value=""
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Select Course
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="MPC_CBSE"
                      >
                        MPC TALENT TEST CBSE
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="MPC_SSC"
                      >
                        MPC TALENT TEST SSC
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="BIPC_CBSE"
                      >
                        BIPC TALENT TEST CBSE
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="BIPC_SSC"
                      >
                        BIPC TALENT TEST SSC
                      </MenuItem>
                    </CustomSelect>
                  </FormControl>
                  {errors.courseName && (
                    <Typography className={classes.fieldError} variant="body2">
                      {errors.courseName}
                    </Typography>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <InputBase1
                  style={{ marginTop: "20px" }}
                  className={classes.textField}
                  placeholder="Password"
                  name="password"
                  onChange={(event) =>
                    this.handleFieldChange("password", event.target.value)
                  }
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                {errors.password && (
                  <Typography className={classes.fieldError} variant="body2">
                    {errors.password}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputBase1
                  style={{ marginTop: "20px" }}
                  className={classes.textField}
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  onChange={(event) =>
                    this.handleFieldChange(
                      "confirmPassword",
                      event.target.value
                    )
                  }
                  type="password"
                  value={values.confirmPassword}
                  variant="outlined"
                />
                {showPasswordError && (
                  <Typography className={classes.fieldError} variant="body2">
                    {errors.password[0]}
                  </Typography>
                )}

                {this.state.mismatch && (
                  <Typography className={classes.fieldError} variant="body2">
                    {"Password and confirm password don't match"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {this.state.showOTP && (
                  <React.Fragment>
                    <InputBase1
                      style={{ marginTop: "20px" }}
                      OTP
                      className={classes.textField}
                      placeholder="Enter OTP"
                      // style={{ borderRadius: '7px'}}
                      label="Please Enter the OTP"
                      name="otp"
                      onChange={(event) =>
                        this.handleFieldChange("otp", event.target.value)
                      }
                      type="text"
                      value={values.otp}
                      variant="outlined"
                    />
                    <br />
                    <Typography>
                      Didn't received OTP?
                      <Button
                        size="small"
                        variant="text"
                        color="primary"
                        onClick={this.handleGoogleOTPResend}
                      >
                        Resend OTP
                      </Button>
                      &nbsp;{loadResend && <NetworkProgress size={20} />}
                    </Typography>
                  </React.Fragment>
                )}

                {errors.otp !== null && (
                  <Typography className={classes.fieldError} variant="body2">
                    {errors.otp}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {this.state.netReq ? (
                  <CustomProgress className={classes.progress} />
                ) : (
                  ""
                )}

                <Button
                  style={{ marginTop: "20px" }}
                  className={classes.actionButton}
                  color="primary"
                  disabled={!isValid}
                  onClick={this.handleSignIn}
                  size="large"
                  variant="contained"
                  id="register-button"
                >
                  Sign Up
                </Button>
              </Grid>
              <Grid item xs={12}>
                <br />
                <Typography variant="body1" className={classes.dont}>
                  Already have an account?{" "}
                  <Link
                    className={classes.signUpUrl}
                    style={{
                      color: "#60c6a8",
                      fontSize: "16px",
                      marginTop: "10px",
                      fontWeight: "600",
                    }}
                    to="/sign-in"
                  >
                    Login
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* alert box start */}
        <AlertComponent
          open={this.state.confirmOpen}
          alertType={signUpStatus.alertType}
          closeAlert={this.handleCloseAlertBox}
        >
          {signUpStatus.message}
        </AlertComponent>
        {/* alert box end  */}
      </Fragment>
    );
  }
}

SignIn.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles)
)(SignIn);
