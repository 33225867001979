import React, { Component } from "react";
import YoutubeControllers from "./YoutubeControllers";
import "./IframePlayer.css";

let LoadYouTube;

class YoutubePLayer extends Component {
  state = {
    playerState: {},
    playerStateChange: {},
  };
  componentDidMount() {
    // disable right click on player
    document.getElementById("yt").addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    // load youtube iframe api
    if (!LoadYouTube) {
      LoadYouTube = new Promise((resolve) => {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        window.onYouTubeIframeAPIReady = () => resolve(window.YT);
      });
    }
    LoadYouTube.then((YT) => {
      this.player = new YT.Player(this.youtubePlayerAnchor, {
        height: this.props.height || 390,
        width: this.props.width || 640,
        videoId: this.props.YTid,
        playerVars: {
          autoplay: 0,
          controls: 0,
          modestbranding: 1,
          enablejsapi: 1,
          rel: 0,
          showinfo: 0,
        },
        events: {
          onStateChange: this.onPlayerStateChange,
          onReady: this.onPlayerReady,
          onPlaybackQualityChange: this.onPlaybackQualityChange,
        },
        origin: `${window.location.origin}`,
      });
    });
  }
  onPlayerStateChange = (e) => {
    this.setState({ playerStateChange: e.target });
    if (typeof this.props.onStateChange === "function") {
      this.props.onStateChange(e);
    }
  };
  onPlayerReady = (e) => {
    this.setState({ playerState: e.target });
    if (typeof this.props.onPlayerReady === "function") {
      this.props.onPlayerReady(e);
    }
  };
  onPlaybackQualityChange = (e) => {
    e.target.setPlaybackQuality("large");
  };
  render() {
    return (
      <div id="yt" className="player-bg">
        <div className="player">
          <div
            id="overlay"
            style={{
              width: "100%",
              height: "100%",
              background: "transparent",
              position: "absolute",
            }}
          />
          <div
            className="yt_player"
            id="youtube"
            ref={(r) => {
              this.youtubePlayerAnchor = r;
            }}
          />
          <YoutubeControllers
            play={this.state.playerState}
            playerStateChange={this.state.playerStateChange}
            id={this.props.YTid}
          />
        </div>
      </div>
    );
  }
}
export default YoutubePLayer;
