const paper_1_2k11 = {
   "questions":{ "1": "1",
    "2": "1",
    "3": "1",
    "4": "1",
    "5": "1",
    "6": "1",
    "7": "1",
    "8": "2",
    "9": "2",
    "10": "2",
    "11": "2",
    "12": "3",
    "13": "3",
    "14": "3",
    "15": "3",
    "16": "3",
    "17": "4",
    "18": "4",
    "19": "4",
    "20": "4",
    "21": "4",
    "22": "4",
    "23": "4",
    "24": "1",
    "25": "1",
    "26": "1",
    "27": "1",
    "28": "1",
    "29": "1",
    "30": "1",
    "31": "2",
    "32": "2",
    "33": "2",
    "34": "2",
    "35": "3",
    "36": "3",
    "37": "3",
    "38": "3",
    "39": "3",
    "40": "4",
    "41": "4",
    "42": "4",
    "43": "4",
    "44": "4",
    "45": "4",
    "46": "4",
    "47": "1",
    "48": "1",
    "49": "1",
    "50": "1",
    "51": "1",
    "52": "1",
    "53": "1",
    "54": "2",
    "55": "2",
    "56": "2",
    "57": "2",
    "58": "3",
    "59": "3",
    "60": "3",
    "61": "3",
    "62": "3",
    "63": "4",
    "64": "4",
    "65": "4",
    "66": "4",
    "67": "4",
    "68": "4",
    "69": "4"},
    "sections": {
        1: [1,24,47],
        2: [8,31,54],
        3: [12,35,58],
        4: [17,40,63]
    }
} 

export  default paper_1_2k11;