import {
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Hidden,
} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import React, { useEffect, useState, useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  controller: {
    bottom: "55px",
    position: "relative",
    padding: "1%",
  },
  btnStyle: {
    color: theme.palette.error.main,
  },
  slider: { width: "15%" },
  progress: {
    padding: "0 1%",
    width: "auto",
  },
  duration: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

// formate video duration as per requirement
function fmtMSS(seconds) {
  const format = (val) => `0${Math.floor(val)}`.slice(-2);
  const hours = seconds / 3600;
  const minutes = (seconds % 3600) / 60;
  return [hours, minutes, seconds % 60].map(format).join(":");
}

const YoutubeControllers = ({ play, playerStateChange, id = "" }) => {
  const agent = navigator.userAgent;
  const classes = useStyles();
  const [progressBar, setProgressBar] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [value, setValue] = useState(
    play.getVolume && play.getVolume() ? play.getVolume() : 100
  );
  const [isMute, setIsMute] = useState(
    play.isMuted && play.isMuted() ? play.isMuted() : false
  );
  // get video's total duration from youtube API
  const duration = useCallback(() => {
    playerStateChange.getDuration &&
      setVideoDuration(fmtMSS(playerStateChange.getDuration()));
  }, [playerStateChange]);
  // handle volume change of player
  const handleChange = (event, newValue) => {
    setValue(newValue);
    play.setVolume(newValue);
  };
  useEffect(() => {
    // get current status of video
    if (playerStateChange.getDuration) {
      const playerTotalTime = playerStateChange.getDuration();
      var playerCurrentTime = playerStateChange.getCurrentTime();

      var playerTimeDifference = (playerCurrentTime / playerTotalTime) * 100;
      setProgressBar(playerTimeDifference);
      duration();
    }
  });

  // slider for navigation through video
  const handleSeek = (event, newValue) => {
    if (playerStateChange.getDuration) {
      const totalTime = playerStateChange.getDuration();
      const seekValue = totalTime * (newValue / 100);
      play.seekTo(seekValue, true);
      setProgressBar(seekValue);
    }
  };

  // full screen handler
  const handleFullScreen = () => {
    play.playVideo(); //won't work on mobile
    // access ing full screen status through document obj
    let isInFullScreen =
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);
    const iframeDIV = document.getElementById("yt");

    let requestFullScreen =
      iframeDIV.requestFullScreen ||
      iframeDIV.mozRequestFullScreen ||
      iframeDIV.webkitRequestFullScreen;

    let cancelFullScreen =
      document.exitFullscreen ||
      document.mozCancelFullScreen ||
      document.webkitExitFullscreen ||
      document.msExitFullscreen;

    if (!isInFullScreen) {
      if (requestFullScreen) {
        requestFullScreen.bind(iframeDIV)();
        // switch to full screen and change height and width of iframe container
        document.getElementById("youtube").style.width = "100vw";
        document.getElementById("youtube").style.height = "100vh";
      }
    } else {
      cancelFullScreen.call(document);
      // if exiting full screen switch width and height to default value
      // To be handled manually as youtube api doesn't exposes full screen handlers
      document.getElementById("youtube").style.width = "100%";
      document.getElementById("youtube").style.height = "400px";
    }
  };

  return (
    <Grid container direction="column" className={classes.controller}>
      {/* Progress bar */}
      <Slider
        component="span"
        color="secondary"
        classes={{ root: classes.progress }}
        value={progressBar}
        onChange={handleSeek}
        aria-labelledby="continuous-slider"
      />
      <Grid item container alignItems="center">
        <Grid item xs={6}>
          <Grid item container direction="row">
            {/* play/pause controllers  */}
            {play.getPlayerState && play.getPlayerState() === 1 ? (
              <IconButton
                id="play-button"
                className={classes.btnStyle}
                size="small"
                onClick={() => {
                  play.pauseVideo();
                  if (agent.includes("Android")) {
                    if (window.Android) window.Android.playYoutubeVideo(id);
                  }
                }}
              >
                <PauseIcon />
              </IconButton>
            ) : (
              <IconButton
                id="play-button"
                className={classes.btnStyle}
                size="small"
                onClick={() => {
                  play.playVideo();
                  if (agent.includes("Android")) {
                    if (window.Android) window.Android.playYoutubeVideo(id);
                  }
                }}
              >
                <PlayArrowIcon />
              </IconButton>
            )}
            &emsp;
            {/* mute/unmute controllers  */}
            <IconButton
              className={classes.btnStyle}
              size="small"
              onClick={() => {
                setIsMute(!isMute);
                isMute ? play.unMute() : play.mute();
              }}
            >
              {isMute ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </IconButton>
            &emsp;
            {/* volume controller  */}
            <Hidden smDown>
              <Slider
                component="span"
                value={value}
                color="secondary"
                classes={{ root: classes.slider }}
                onChange={handleChange}
                aria-labelledby="continuous-slider"
              />
              &nbsp;&nbsp;&nbsp;
            </Hidden>
            {/* duration display */}
            <Typography
              className={classes.duration}
              variant="caption"
              color="error"
            >
              {playerStateChange.getDuration &&
                `${fmtMSS(
                  playerStateChange.getCurrentTime()
                )}/${videoDuration}`}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={6} container direction="row" justify="flex-end">
          {/* full screen controller  */}
          <IconButton
            className={classes.btnStyle}
            size="small"
            onClick={handleFullScreen}
          >
            <FullscreenIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default YoutubeControllers;
