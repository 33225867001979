import React from "react";
import { Container, Typography, Grid, makeStyles } from "@material-ui/core";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  image: {
    width: "90%",
    height: "70%",
    borderRadius: "8px",
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  highlightedText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  content: {
    lineHeight: "2",
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    fontSize: "18px",
  },
}));

const AboutUs = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        <Typography variant="h3" gutterBottom align="center">
          About Us
        </Typography>
      </motion.div>

      <motion.div
        className={classes.section}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <Typography variant="h4" gutterBottom>
          Welcome to <span className={classes.highlightedText}>Onesaz</span>!
        </Typography>
        <Typography variant="body1" className={classes.content}>
          At <span className={classes.highlightedText}>Onesaz</span>, we believe
          in revolutionizing the way businesses operate through innovative ERP
          solutions and seamless digital experiences.
        </Typography>
      </motion.div>

      <motion.div
        className={classes.section}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.4 }}
      >
        <Typography variant="h4" gutterBottom>
          Who We Are
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <motion.img
              src="https://thumbs.dreamstime.com/b/teamwork-team-together-collaboration-business-communication-outd-outdoors-concept-48568990.jpg"
              alt="Teamwork"
              className={classes.image}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" className={classes.content}>
              <span className={classes.highlightedText}>Onesaz</span> is a
              leading provider of enterprise resource planning (ERP) solutions
              that help businesses streamline their operations, improve
              efficiency, and drive growth. Our mission is to empower
              organizations with cutting-edge technology that simplifies complex
              processes and enhances decision-making.
            </Typography>
          </Grid>
        </Grid>
      </motion.div>

      <motion.div
        className={classes.section}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.6 }}
      >
        <Typography variant="h4" gutterBottom>
          Our Mission
        </Typography>
        <Typography variant="body1" className={classes.content}>
          Our mission is to transform businesses by providing comprehensive and
          customizable ERP solutions that meet the unique needs of each client.
          We are committed to delivering exceptional value through innovative
          technology, expert support, and a customer-centric approach.
        </Typography>
      </motion.div>

      <motion.div
        className={classes.section}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.8 }}
      >
        <Typography variant="h4" gutterBottom>
          What We Do
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <motion.img
              src="https://images.unsplash.com/photo-1488590528505-98d2b5aba04b"
              alt="What We Do"
              className={classes.image}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" className={classes.content}>
              At <span className={classes.highlightedText}>Onesaz</span>, we
              offer a range of ERP solutions designed to integrate all aspects
              of a business into a single, cohesive system. From finance and
              human resources to supply chain management and customer
              relationship management, our solutions are built to optimize every
              facet of your operations.
            </Typography>
          </Grid>
        </Grid>
      </motion.div>

      <motion.div
        className={classes.section}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 1.0 }}
      >
        <Typography variant="h4" gutterBottom>
          Our Values
        </Typography>
        <Typography variant="body1" className={classes.content}>
          <ul>
            <li>
              Innovation: We are dedicated to continuous improvement and
              innovation in everything we do.
            </li>
            <li>
              Integrity: We uphold the highest standards of integrity in our
              actions and decisions.
            </li>
            <li>
              Customer Success: Our customers' success is our top priority. We
              work closely with them to ensure they achieve their business
              goals.
            </li>
            <li>
              Collaboration: We believe in the power of teamwork and
              collaboration, both within our organization and with our clients.
            </li>
          </ul>
        </Typography>
      </motion.div>

      <motion.div
        className={classes.section}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 1.2 }}
      >
        <Typography variant="h4" gutterBottom>
          Our Team
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <motion.img
              src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d"
              alt="Team"
              className={classes.image}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" className={classes.content}>
              Our team of experienced professionals is passionate about
              technology and committed to delivering exceptional results. We
              bring together a diverse set of skills and expertise to provide
              our clients with the best possible solutions and support.
            </Typography>
          </Grid>
        </Grid>
      </motion.div>

      <motion.div
        className={classes.section}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 1.4 }}
      >
        <Typography variant="h4" gutterBottom>
          Why Choose Us?
        </Typography>
        <Typography variant="body1" className={classes.content}>
          <ul>
            <li>
              Tailored Solutions: We understand that every business is unique.
              Our ERP solutions are highly customizable to meet your specific
              needs.
            </li>
            <li>
              Expert Support: Our team of experts is always here to provide
              support and guidance, ensuring you get the most out of our
              solutions.
            </li>
            <li>
              Proven Track Record: We have a proven track record of success,
              with numerous satisfied clients across various industries.
            </li>
          </ul>
        </Typography>
      </motion.div>

      <motion.div
        className={classes.section}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 1.6 }}
      >
        <Typography variant="h4" gutterBottom>
          Get in Touch
        </Typography>
        <Typography variant="body1" className={classes.content}>
          We would love to hear from you! Whether you have a question about our
          services, need support, or are interested in learning more about how{" "}
          <span className={classes.highlightedText}>Onesaz</span> can help your
          business, feel free to reach out.
          <br />
          <br />
          <h6>Contact Us:</h6>
          <br />
          H.NO: 1-1-21/39, Plot No. 21, Road No. 6, Near Community Hall,
          <br />
          Address: Kukatpally, Hyderabad-500085.
        </Typography>
      </motion.div>
    </Container>
  );
};

export default AboutUs;
