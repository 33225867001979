import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchNotificationsList,
  fetchNotificationsSuccess,
  catchErrorNotifications,
} from "./actionCreator";
import { getAllNotifications } from "../../../apis/notificationService";
import store from "store";

// Utility function to compare two dates
function compare(a, b) {
  const createdDateA = a.created_at || 0;
  const createdDateB = b.created_at || 0;
  return createdDateB - createdDateA;
}

// Utility function to get sorted notifications from storage
function getSortedNotifications(storageKey) {
  const cachedNotifications = store.get(storageKey) || [];
  return cachedNotifications.slice().sort(compare);
}

// Utility function to update storage with new notifications
function updateStorage(storageKey, notifications) {
  store.set(storageKey, notifications);
}

function* fetchNotifications(api_payload) {
  try {
    const storageKey = `notification_${api_payload.body["instituteIdLevelId"]}`;
    let sortedList = getSortedNotifications(storageKey);

    if (sortedList.length > 0) {
      const timeStamp = sortedList[0].created_at || Date.now();
      const notificationData = yield call(getAllNotifications, {
        ...api_payload.body,
        created_at: timeStamp,
      });

      const result = yield notificationData.data;
      if (result.output && result.output.length > 0) {
        sortedList = sortedList.slice().concat(result.output);
        updateStorage(storageKey, sortedList);
      }
      yield put(fetchNotificationsSuccess(sortedList));
      return;
    } else {
      const notificationData = yield call(
        getAllNotifications,
        api_payload.body
      );
      const result = yield notificationData.data;
      const finalOutput = result.output || [];
      updateStorage(storageKey, finalOutput);
      yield put(fetchNotificationsSuccess(finalOutput));
    }
  } catch (error) {
    yield put(catchErrorNotifications(error.message));
  }
}

function* notificationSagaWatcher() {
  yield takeLatest(fetchNotificationsList().type, fetchNotifications);
}

export default notificationSagaWatcher;
