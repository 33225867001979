const paper_2_2k10 = {
   "questions":{ "1": "1",
    "2": "1",
    "3": "1",
    "4": "1",
    "5": "1",
    "6": "1",
    "7": "2",
    "8": "2",
    "9": "2",
    "10": "2",
    "11": "2",
    "12": "3",
    "13": "3",
    "14": "3",
    "15": "3",
    "16": "3",
    "17": "3",
    "18": "4",
    "19": "4",
    "20": "1",
    "21": "1",
    "22": "1",
    "23": "1",
    "24": "1",
    "25": "1",
    "26": "2",
    "27": "2",
    "28": "2",
    "29": "2",
    "30": "2",
    "31": "3",
    "32": "3",
    "33": "3",
    "34": "3",
    "35": "3",
    "36": "3",
    "37": "4",
    "38": "4",
    "39": "1",
    "40": "1",
    "41": "1",
    "42": "1",
    "43": "1",
    "44": "1",
    "45": "2",
    "46": "2",
    "47": "2",
    "48": "2",
    "49": "2",
    "50": "3",
    "51": "3",
    "52": "3",
    "53": "3",
    "54": "3",
    "55": "3",
    "56": "4",
    "57": "4"},
    "sections": {
        1: [1,20,39],
        2: [7,26,45],
        3: [12,31,50],
        4: [18,37,56]
    }
} 

export  default paper_2_2k10;