import { call, put, takeLatest } from "redux-saga/effects";
import {
  getCompareResultQuesDetails,
  getRankAnalysis,
  getResultDetails,
  getTimeSpentAnalysis,
  getStudentAnswersCount,
  getStudentBehaviour,
} from "../../../apis/resultService";
import { formatDuration } from "../../../common/formateDuration";
import convertOption from "../../../common/optionConverter";
import {
  catchErrorCompareQuestion,
  catchErrorRank,
  catchErrorResult,
  catchErrorTimeSpent,
  fetchAllSubjects,
  fetchCompareQuesDetails,
  fetchCompareQuesDetailsSuccess,
  fetchRankAnalysis,
  fetchRankAnalysisSuccess,
  fetchResultDetails,
  fetchResultSuccess,
  fetchStudentBehaviourFailure,
  fetchStudentBehaviourStart,
  fetchStudentBehaviourSuccess,
  fetchTimeSpentAnalysisSuccess,
} from "./actionCreators";
function scoremapFunc() {
  return {
    totalQuestions: 0,
    totalAttempted: 0,
    incorrectAnswers: 0,
    correctAnswers: 0,
  };
}
//merging two array of objects based on identical key-value pair
function mergeArrayObjects(arr1, arr2) {
  const map = new Map();
  arr1.forEach((item) => map.set(Number(item.questionNo), item));
  arr2.forEach((item) =>
    map.set(Number(item.name), { ...map.get(Number(item.name)), ...item })
  );
  const mergedArray = Array.from(map.values());
  // console.log({ result: arr1, timeSpent: arr2, mergedArray });
  return mergedArray;
}
// fetch result details and time spent analysis
function* fetchResults(api_payload) {
  let questions = [];
  let chartdata = [];

  let scoremap = {
    totalQuestions: 0,
    totalAttempted: 0,
    incorrectAnswers: 0,
    correctAnswers: 0,
  };
  const subjectWiseAnalysis = {};
  Object.keys(scoremap).forEach((key) => {
    scoremap[key] = 0;
  });
  try {
    // result side effect
    const resultData = yield call(getResultDetails, api_payload.body);
    const studentAnswersCount = yield call(
      getStudentAnswersCount,
      api_payload.body["test_name"]
    );
    const studentAnswers = yield studentAnswersCount.data;
    const result = yield resultData.data;
    if (result.status === "SUCCESS") {
      questions = result.payload;
      questions = questions.map((question) => {
        if (!subjectWiseAnalysis[question.question_details.subject]) {
          subjectWiseAnalysis[
            question.question_details.subject
          ] = scoremapFunc();
        }
        subjectWiseAnalysis[
          question.question_details.subject
        ].totalQuestions += 1;
        if (
          ["A", "B", "C", "D"].filter(
            (option) => question.question_details[option] != "NA"
          ).length == 0
        ) {
          question.question_details.q_type = "integer";
        }
        question.correct_answer = ![
          "integer",
          "integer_p",
          "float",
          "float_p",
          "single_integer",
        ].includes(question.question_details.q_type)
          ? convertOption(question.correct_answer)
          : String(question.correct_answer);
        if (question.markedAnswer && question.markedAnswer != "NA") {
          scoremap.totalAttempted += 1;
          subjectWiseAnalysis[
            question.question_details.subject
          ].totalAttempted += 1;

          if (question.status == "correct") {
            scoremap.correctAnswers += 1;
            subjectWiseAnalysis[
              question.question_details.subject
            ].correctAnswers += 1;
            question.decision = "CORRECT";
          } else {
            scoremap.incorrectAnswers += 1;
            subjectWiseAnalysis[
              question.question_details.subject
            ].incorrectAnswers += 1;
            question.decision = "WRONG";
          }
        }
        return question;
      });
    }
    // time spent analysis side effect
    const timeSpentData = yield call(getTimeSpentAnalysis, api_payload.body);
    const timeSpent = yield timeSpentData.data;
    if (timeSpent) {
      if (timeSpent.status === "SUCCESS") {
        timeSpent.payload.Items.sort((a, b) => {
          const _a = parseInt(a.question_number);
          const _b = parseInt(b.question_number);
          if (_a > _b) return 1;
          if (_a < _b) return -1;
          return 0;
        });
        yield put(
          fetchStudentBehaviourStart({
            exam: api_payload.body.test_name,
            student_id: api_payload.body.student_id,
          })
        );
      }
    }
    chartdata = timeSpent.payload.Items.map((item) => {
      return {
        name: item.question_number,
        timeSpent:
          item.timespent / 60000 < 180 ? formatDuration(item.timespent) : 0,
        subjectName: item.subject == undefined ? "" : item.subject,
        accuracy: item && item.timespent / 60000 < 180 ? item.timespent : 0,
      };
    });
    let questionSubjectMap = {};
    questions.forEach((item) => {
      questionSubjectMap[item.question_number] = item.question_details.subject;
    });
    chartdata.forEach((item) => {
      delete questionSubjectMap[item.name];
    });
    let missingChartData = Object.keys(questionSubjectMap).map((key) => {
      return {
        name: key,
        timeSpent: 0,
        subjectName:
          questionSubjectMap[key] == undefined ? "" : questionSubjectMap[key],
        accuracy: 0,
      };
    });
    chartdata = [...chartdata, ...missingChartData];
    const res = questions.map((data) => {
      return {
        questionNo: `${data.question_number}`,
        marks: data.marks,
        subjectName: data.question_details.subject,
        questionText: data.question_details.q,
        status: data.status,
        correctAns: data.correct_answer,
        markedAns: data.markedAnswer,
        decision: data.decision ? data.decision : null,
        q_type: data.question_details.q_type,
        options: {
          A: data.question_details.A,
          B: data.question_details.B,
          C: data.question_details.C,
          D: data.question_details.D,
        },
        paragraph:
          data.question_details && data.question_details.paragraph
            ? data.question_details.paragraph
            : null,
        solution:
          data.question_details && data.question_details.solution
            ? data.question_details.solution
            : null,
        studentAnswersCount:
          studentAnswers.questionNumbers[data.question_number],
      };
    });
    const totalData = mergeArrayObjects(res, chartdata);
    const subjects = [
      ...new Set(
        res.map((data) => {
          return data.subjectName.toUpperCase();
        })
      ),
    ];
    yield put(fetchAllSubjects(subjects));
    yield put(fetchTimeSpentAnalysisSuccess(totalData));
    yield put(
      fetchResultSuccess({
        questions: questions,
        testTotalMarks: result.testTotalMarks,
        keyAvailable: questions.length > 0,
      })
    );
  } catch (error) {
    yield put(catchErrorResult(error.message));
    yield put(catchErrorTimeSpent(error.message));
  }
}
// fetch rank analysis
function* fetchRank(api_payload) {
  try {
    const rankData = yield call(getRankAnalysis, api_payload.body);
    const rank = yield rankData.data;
    if (rank.status === "SUCCESS") {
      yield put(fetchRankAnalysisSuccess(rank.payload));
    }
    if (rank.status === "FAILURE") {
      yield put(catchErrorRank(rank.message));
    }
  } catch (error) {
    yield put(catchErrorRank(error.message));
  }
}
// fetch compare question details
function* fetchQuestionDetails(api_payload) {
  try {
    const quesDetails = yield call(
      getCompareResultQuesDetails,
      api_payload.body
    );
    const details = yield quesDetails.data;
    if (details.status === "SUCCESS") {
      yield put(fetchCompareQuesDetailsSuccess(details.payload));
    }
  } catch (error) {
    yield put(catchErrorCompareQuestion(error.message));
  }
}

function* fetchStudentBehaviourAsync(item) {
  try {
    const { exam, student_id } = item.payload;
    const behaviour = yield getStudentBehaviour(exam, student_id);
    if (behaviour.status === "SUCCESS") {
      yield put(fetchStudentBehaviourSuccess(behaviour.output));
    }
  } catch (error) {
    yield put(fetchStudentBehaviourFailure(error.message));
  }
}
// saga watcher
function* resultSagaWatcher() {
  yield takeLatest(fetchResultDetails().type, fetchResults);
  yield takeLatest(fetchRankAnalysis().type, fetchRank);
  yield takeLatest(fetchCompareQuesDetails().type, fetchQuestionDetails);
  yield takeLatest(
    fetchStudentBehaviourStart().type,
    fetchStudentBehaviourAsync
  );
}
export default resultSagaWatcher;
