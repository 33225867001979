import React from "react";
import { useSelector } from "react-redux";
import store from "store";
// material ui
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
// components
import PercentageStats from "./PercentageStats";
import TimeSpentList from "./TimseSpentList";
import StudentExamBehaviourChart from "./SudentExamBehaviourChart";
// assests
import theme from "../../assets/theme";
import ContentWrap from "../HomePage/Components/ContentWrap";
const useStyle = makeStyles(() => ({
  spanStyle: {
    color: theme.palette.info.main,
    textTransform: "lowercase",
  },
  answerDiv: {
    background: theme.palette.common.white,
    // padding: "3%",
    // maxWidth:"1200px",
    // width:"900px",
    scroll: "auto",
    overflow: "auto",
    borderRadius: "0px 0px 10px 10px",
    filter: "drop-shadow(0px 5px 10px rgba(142, 154, 175, 0.1))",
    marginTop: "1rem",
    // [theme.breakpoints.down("sm")]: {
    //   marginRight: "3%",
    // },
  },
}));

function MyStatistics({ timeSpentData, subject }) {
  const searchParams = new URLSearchParams(window.location.search);

  const maxTimeSpent = () => {
    const timeSpentList = timeSpentData
      .filter((item) => item.accuracy)
      .map((data) => data.accuracy);
    const maxTime = Math.max(...timeSpentList);
    return maxTime;
  };
  const classes = useStyle();
  const studentBehaviour = useSelector((s) => {
    const resultsState = s.allResult;
    const behaviour = resultsState.behaviour;
    return behaviour;
  });

  const testDuration = searchParams.get("test_duration");
  const time_exam_started = searchParams.get("time_started");
  const examDurationInMinutes =
    Number(testDuration) ||
    (store.get("examObject") && store.get("examObject").test_duration) / 60000;

  function sortArrayOfObjectsByAProp(list, sortBy) {
    return list.reduce((a, c) => {
      const val = c[sortBy];
      if (a[val]) a[val].push(c);
      else a[val] = [c];
      return a;
    }, {});
  }

  const haveTimeSpentData = timeSpentData && timeSpentData.length > 0;
  const haveStudentBehaviour = studentBehaviour && studentBehaviour.length > 0;
  const isOffLineExam = haveTimeSpentData
    ? timeSpentData.reduce((a, c) => a + c.timeSpent, 0) === 0
    : false;

  console.log(studentBehaviour);
  const getChartData = () => {
    const timeStarted =
      time_exam_started ||
      (store.get("examObject") && store.get("examObject").time_started);
    let timeSpentMap = null;
    let allItems = null;
    if (haveTimeSpentData && !isOffLineExam) {
      timeSpentMap = timeSpentData.reduce((a, c) => {
        const t = `${c.timeSpent}`.split(" ").reduce((ac, cu) => {
          if (cu.includes("m")) return Number(cu.replace("m", "")) * 60 + ac;
          if (cu.includes("s")) return Number(cu.replace("s", "")) + ac;
        }, 0);
        a[c.questionNo] = {
          label: c.timeSpent,
          value: t / 60,
          decision: c.decision,
        };
        return a;
      }, {});
    }

    if (haveStudentBehaviour) {
      allItems = studentBehaviour.reduce((a, c) => {
        const items = c.question_behaviour;
        const lastItem = items[items.length - 1];
        const newItem = {
          x: (lastItem.timestamp - timeStarted) / 60000,
          y:
            timeSpentMap[c.question_number] &&
            timeSpentMap[c.question_number].value,
          timeSpent:
            timeSpentMap[c.question_number] &&
            timeSpentMap[c.question_number].label,
          qNo: c.question_number,
          decision:
            timeSpentMap[c.question_number] &&
            timeSpentMap[c.question_number].decision,
          qBehaviour: c.question_behaviour,
          // decision: timeSpentMap[c.question_number].decision ,
          ...lastItem,
        };
        return [...a, newItem];
      }, []);
      allItems = sortArrayOfObjectsByAProp(allItems, "subject");
    }
    return allItems;
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <ContentWrap>
        <Grid container direction="row" justify="space-between" spacing={2}>
          {haveStudentBehaviour && haveTimeSpentData && !isOffLineExam ? (
            <Grid item xs={12}>
              <Typography variant="h3" color="textPrimary">
                My Exam Behaviour
              </Typography>
              <div className={classes.answerDiv}>
                <StudentExamBehaviourChart
                  data={getChartData()}
                  examDurationInMinutes={examDurationInMinutes}
                />
              </div>
            </Grid>
          ) : null}
          <Hidden only={["xs"]}>
            <Grid item xs={12}>
              <Typography variant="h3" color="textPrimary">
                My Statistics
              </Typography>
            </Grid>
          </Hidden>
          <Hidden only={["md", "lg", "xl", "sm"]}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textPrimary">
                My Statistics in{" "}
                <span className={classes.spanStyle}>{subject}</span>
              </Typography>
            </Grid>
          </Hidden>
          <br />
          <Grid item xs={12} sm={6}>
            <PercentageStats
              totalTime={
                timeSpentData
                  ? timeSpentData
                      .filter((item) => item.accuracy)
                      .map((data) => {
                        // console.log(data);
                        return data.accuracy;
                      })
                      .reduce((a, b) => a + b, 0)
                  : 0
              }
              subject={subject}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Hidden only={["md", "lg", "xl", "sm"]}>
              <Grid item xs={12}>
                <Typography variant="body1" color="textPrimary">
                  Time spent on questions in&nbsp;
                  <span className={classes.spanStyle}>{subject}</span>
                </Typography>
                <br />
              </Grid>
            </Hidden>
            {timeSpentData
              ? timeSpentData.map((data, index) => {
                  return (
                    <TimeSpentList
                      Qno={data.name}
                      key={index}
                      indexes={index}
                      timeSpent={data.timeSpent}
                      accuracy={data.accuracy}
                      maxTime={maxTimeSpent()}
                    />
                  );
                })
              : ""}
          </Grid>
        </Grid>
      </ContentWrap>
    </div>
  );
}

export default MyStatistics;
