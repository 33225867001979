import React, { useState, useEffect } from 'react';
import store from 'store';

// Material helpers
import { ThemeProvider } from "@material-ui/core/styles";
import theme from '../../../assets/theme';
import { Button, Grid, Typography, Paper, Avatar, withStyles } from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { SvgIcon } from '@material-ui/core';

// api
import { allIndiaExamTimeSlots } from '../../../apis/UserService';

// components 
import RegisterExamCard from "./components/RegisterForBsat";
import Footer from '../../SectionsPage/Footer';
import BookExamCard from './components/BookExamCard';
import FaqDropDown from './components/faqDropDown';

// icons
import eligibility from '../../../assets/RegisterExamIcons/Eligibility.png';
import examDateImg from '../../../assets/RegisterExamIcons/ExamDate.png';
import examFee from '../../../assets/RegisterExamIcons/ExamFee.png';
import descImage from '../../../assets/RegisterExamIcons/cover.jpg';

//styles
import styles from './styles';

const BhashyamLanding = ({ history, classes }) => {
  const [studentGrade, setStudentGrade] = useState();
  const [examType, setExamType] = useState('');
  const [dateAndTimeSlots, setDateAndTimeSlots] = useState({});
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [resError, setResError] = useState('');
  const [activeFaq, setActieFaq] = useState('none');

  useEffect(() => {
    if (window.location.host.includes("bhashyam") || window.location.host.includes("localhost")) {
      allIndiaExamTimeSlots()
        .then((res) => {
          if (res.status === 200 & Object.keys(res.data.payload).length > 0) {
            setDateAndTimeSlots(res.data.payload);
            setStudentGrade(4);
            setResError('')
          } else {
            setResError('No data')
          }
        })
        .catch((err) => {
          console.log(err);
          setResError(err);
        });
    }
  }, [allIndiaExamTimeSlots]);

  useEffect(() => {
    if (studentGrade < 7) {
      setExamType('Foundation')
    } else {
      setExamType('JEE')
    }
  }, [studentGrade])

  const handleGradeClick = (value) => {
    setDate('');
    setTime('');
    setStudentGrade(value);
  }

  const handleDateAndTime = (examDate, timeSlot) => {
    setDate(examDate);
    setTime(timeSlot);
  }

  const handleExamType = (examType) => {
    setExamType(examType);
    setDate('');
    setTime('');
  }

  const handleBookExam = () => {
    const feildsToPopulate = {
      grade: studentGrade,
      examType: examType,
      examDate: date,
      examTimeSlot: time
    }
    store.set('examFields', feildsToPopulate);
    history.push('/register')
  }

  const handleFaqClick = (id) => {
    (activeFaq === 'none' || !(activeFaq === id)) ? setActieFaq(id) : setActieFaq('none')
  }

  const faqs = [
    {
      ques: 'What is the purpose of B-SAT?',
      ans: 'B-SAT is a talent reward examination to honour the meritorious students. It gives them a chance for studying in Bhashyam Educational Institutions at free of cost.'
    },
    {
      ques: 'Who can appear for B-SAT exam?',
      ans: 'Students currently studying in 4th, 5th, 6th, 7th, 8th, 9th and 10th classes can appear for this exam.'
    },
    {
      ques: 'What is the mode of test?',
      ans: ' B-SAT exam would be conducted in online mode.'
    },
    {
      ques: 'Will there be offline (in campus) exam?',
      ans: 'Yes, Any Student who is expecting a concession of more than 40% will have to   appear for 2nd round exam in off line mode. Offline centers would be allotted to the selected students to take the test. '
    },
    {
      ques: 'Do I need to attend exam exactly in specified time slot?',
      ans: 'Yes. Time slot is fixed and student will lose time in case of late joining.'
    },
    {
      ques: 'Whom to (or) who should I contact for any additional queries?',
      ans: 'You could get information through the following mail bsat@bhashyam.in (or) Feel free to contact: 9347070487, 7337443737,  9666253536, 9848778936'
    }
  ]

  const grades = [4, 5, 6, 7, 8, 9, 10]

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <RegisterExamCard />
        <hr></hr>
        <Grid
          container
          justify='center'
          xs={12}
          className={classes.gradesContainer}
        >
          <Grid item xs={12} className={classes.gradeInstruction}>
            <Typography variant={'body1'}>Please choose PRESENT studying class</Typography>
          </Grid>
          {
            grades.map((grade, index) => (
              <Grid item key={index} xs={6} sm={4} md={3} className={classes.gradeButtonContainer}>
                <Button
                  color='primary'
                  size='medium'
                  variant={
                    grade == studentGrade ? 'contained' : 'outlined'
                  }
                  disabled={
                    resError ? true : false
                  }
                  className={classes.gradeButton}
                  onClick={() => handleGradeClick(grade)}
                >
                  <span>{grade}<sup>th</sup></span>
                </Button>
              </Grid>
            ))
          }
        </Grid>
        <Grid
          container
          direction='row'
          justify='center'
          alignItems='center'
          xs={12}
          style={{ margin: 'auto', marginTop: '20px', maxWidth: '337px' }}
        >
          {
            studentGrade > 6 ? (
              <Grid item xs={12} className={classes.typeButtons}>
                <Button
                  color='primary'
                  size='small'
                  variant={
                    examType == 'JEE' ? 'contained' : 'outlined'
                  }
                  onClick={() => handleExamType('JEE')}
                  style={{ transition: 'all 0.3s ease-in-out' }}
                >
                  IIT
                </Button>
                <Button
                  color='primary'
                  size='small'
                  variant={
                    examType == 'NEET' ? 'contained' : 'outlined'
                  }
                  onClick={() => handleExamType('NEET')}
                  style={{ transition: 'all 0.3s ease-in-out' }}
                >
                  NEET
                </Button>
              </Grid>
            ) : null
          }
          {
            resError ? (
              <Typography className={classes.errorMessage}>*Something went WRONG... try some time later</Typography>
            ) : null
          }
          {
            studentGrade && dateAndTimeSlots && examType ? (
              <Grid item xs={12} style={{ maxWidth: '337px', marginTop: '20px' }}>
                <BookExamCard
                  grade={studentGrade}
                  examType={examType}
                  data={dateAndTimeSlots}
                  date={date}
                  time={time}
                  handleDateAndTime={handleDateAndTime}
                  handleBookExam={handleBookExam}
                />
              </Grid>
            ) : null
          }
        </Grid>
        <div className={classes.descHead}>
          <Typography variant={'h3'}>BSAT 2022 DETAILS</Typography>
        </div>
        <div className={classes.descriptionContainer}>
          <div className={classes.imageContianer}>
            <img src={descImage} className={classes.descImage} alt="side" />
          </div>
          <div className={classes.descItemsContainer}>
            <div className={classes.descItem}>
              <Avatar src={eligibility} className={classes.itemImage} alt="icon" />
              <div className={classes.itemContent}>
                <Typography variant={'h6'}>Eligibility</Typography>
                <Typography variant={'body2'}>IV, V, VI, VII, VIII, IX, X</Typography>
              </div>
            </div>
            <div className={classes.descItem}>
              <Avatar src={examFee} className={classes.itemImage} alt="icon" />
              <div className={classes.itemContent}>
                <Typography variant={'h6'}>Exam Fee</Typography>
                <Typography variant={'body2'}>100/-</Typography>
              </div>
            </div>
            <div className={classes.descItem}>
              <Avatar src={examDateImg} className={classes.itemImage} alt="icon" />
              <div className={classes.itemContent}>
                <Typography variant={'h6'}>Exam Dates</Typography>
                <Typography variant={'body2'}>
                  Online: Jan 9<sup>th</sup>, 10<sup>th</sup>, 11<sup>th</sup>, 12<sup>th</sup>, 13<sup>th</sup>
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.faqContainer}>
          <Typography variant={'h3'} className={classes.faqText}>Frequently Asked Questions</Typography>
          <div className={classes.faqList}>
            {
              faqs.map((faq, index) => (
                <FaqDropDown
                  key={index}
                  id={index}
                  question={faq.ques}
                  answer={faq.ans}
                  isActive={
                    activeFaq === index ? true : false
                  }
                  handleFaqClick={handleFaqClick}
                />
              ))
            }
          </div>
        </div>
        <div className={classes.contactInfoContainer}>
          <Paper className={classes.contactInfo}>
            <SvgIcon component={ContactSupportIcon} />
            <Typography variant={'body1'}>9347070487, 7337443737,  9666253536, 9848778936</Typography>
            <Typography variant={'body1'}>
              <a href='https://bsat@bhashyam.in'>
                bsat@bhashyam.in
              </a>
            </Typography>
          </Paper>
        </div>
      </div>
      <Footer />
    </ThemeProvider>
  )
}

export default withStyles(styles)(BhashyamLanding);