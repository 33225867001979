const MathsTopicAndSubtopic = require("./MAT.json");
const PhysicsTopicAndSubtopic = require("./PHY.json");
const ChemistryTopicAndSubtopic = require("./CHE.json");
const BiologyTopicAndSubtopic = require("./BIO.json");
const BotanyTopicAndSubtopic = require("./BOT.json");
const ZoologyTopicAndSubtopic = require("./ZOO.json");

const mapTopicAndSubTopic = (subject) => {
  if (subject === "MATHEMATICS") {
    return MathsTopicAndSubtopic;
  }
  if (subject === "PHYSICS") {
    return PhysicsTopicAndSubtopic;
  }
  if (subject === "CHEMISTRY") {
    return ChemistryTopicAndSubtopic;
  }
  if (subject === "BIOLOGY") {
    return BiologyTopicAndSubtopic;
  }
  if (subject === "BOTANY") {
    return BotanyTopicAndSubtopic;
  }
  if (subject === "ZOOLOGY") {
    return ZoologyTopicAndSubtopic;
  }
};
export default mapTopicAndSubTopic;
