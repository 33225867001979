import {
  roseColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb,
  // onesazPill,
} from "assets/jss/material-kit-pro-react.jsx";

const navPillsStyle = (theme) => ({
  root: {
    // marginTop: "20px",
    paddingLeft: "0",
    marginBottom: "0",
    overflow: "visible !important",
    background:
      "linear-gradient(39.24deg, #FFFFFF -30.86%, #ECF1F7 -10.72%, #EFF2F4 112.31%)",
    boxShadow:
      "inset 4px 4px 6px rgba(18, 46, 101, 0.1), inset -4px -4px 4px rgba(255, 255, 255, 0.7)",
    // background: 'linear-gradient(180deg, #ECF0F3 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(39.24deg, #FFFFFF -30.86%, #EDF1F4 -10.72%, #ECF0F3 112.31%)',
    // boxShadow: 'inset 4px 4px 6px rgba(209, 217, 230, 0.15), inset -4px -4px 7px rgba(255, 255, 255, 0.7)',
    borderRadius: "31px",
    border: "1.2px solid #e9e8e8",
    heigth: "50px",
    width: "300px",
    // transform: 'rotate(90deg)'
  },
  flexContainer: {
    [theme.breakpoints.down("xs")]: {
      // display: "flex",
      // flexWrap: "wrap"
    },
  },
  displayNone: {
    display: "none !important",
  },
  fixed: {
    overflowX: "visible",
  },
  horizontalDisplay: {
    display: "block",
  },
  pills: {
    float: "left",
    position: "relative",
    display: "block",
    borderRadius: "30px",
    minWidth: "100px",
    textAlign: "center",
    transition: "all .3s",
    padding: "10px 15px",
    color: grayColor[15],
    height: "auto",
    opacity: "1",
    maxWidth: "100%",
    margin: "0 5px",
    minHeight: "unset",
  },
  pillsWithIcons: {
    borderRadius: "4px",
  },
  tabIcon: {
    width: "30px",
    height: "30px",
    display: "block",
    margin: "15px 0",
  },
  horizontalPills: {
    width: "100%",
    float: "none !important",
    "& + button": {
      margin: "10px 0",
    },
  },
  labelContainer: {
    padding: "0!important",
    color: "inherit",
  },
  label: {
    lineHeight: "24px",
    textTransform: "none",
    position: "relative",
    display: "block",
    color: "inherit",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    // lineHeight: '100%',
  },
  contentWrapper: {
    marginTop: "20px",
    "& .react-swipeable-view-container > div > div": {
      paddingLeft: "15px",
      paddingRight: "15px",
    },
  },
  onesaz: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: "#73E7D4",
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb("#73E7D4") +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb("#73E7D4") +
        ", 0.4)",
    },
  },
  primary: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: primaryColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.4)",
    },
  },
  info: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: infoColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.4)",
    },
  },
  success: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: successColor[0],
      boxShadow:
        "0 2px 2px 0 rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.14), 0 3px 1px -2px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.2), 0 1px 5px 0 rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.12)",
    },
  },
  warning: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: warningColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.4)",
    },
  },
  danger: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: dangerColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.4)",
    },
  },
  rose: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: roseColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.4)",
    },
  },
  alignCenter: {
    alignItems: "center",
    justifyContent: "center",
  },
});

export default navPillsStyle;
