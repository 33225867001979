import React from "react";

const CacheImg = ({ imageURLs = [] }) => {
  return (
    <div style={{ display: "none" }}>
      {imageURLs.map((image, index) => {
        return <img key={index + 1} alt={index} src={image} />;
      })}
    </div>
  );
};

export default CacheImg;
