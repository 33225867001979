import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  paperStyle: {
    background: (props) => props.taggingData.background,
    color: theme.palette.common.white,
    borderColor: "transparent",
    minHeight: "28px",
  },
  count: { marginRight: "2%" },
  tagBar: { cursor: "pointer" },
  activeTag: {
    borderBottom: (props) => `3px solid ${props.taggingData.background}`,
    color: theme.palette.text.primary,
  },
}));
const CustomTypography = ({ tagName, ...props }) => {
  const classes = useStyles(props);
  return (
    <Grid
      item
      xs={12}
      className={classes.tagBar}
      onClick={props.handleTagClick}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Grid item>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gridGap="12px"
          >
            <Paper component="button" classes={{ root: classes.paperStyle }}>
              <props.taggingData.icon />
            </Paper>
            <Typography
              variant="body2"
              color="textSecondary"
              component="span"
              className={
                props.isActiveTag === tagName ? classes.activeTag : null
              }
            >
              &nbsp;{tagName}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.count}>
          <Typography variant="body2" color="textSecondary">
            {props.taggingData.count}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomTypography;
