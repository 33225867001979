import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import { getAssignments, recordAttendance } from "apis/videoService";
import React, { Component, Fragment } from "react";
import store from "store";
import Header from "../Header";
import Alert from "../notifications/Alert";
import TalkChat from "./TalkChat";
import { Container } from "@material-ui/core";
import YoutubePLayer from "./YoutubePLayer";

const styles = (theme) => ({
  videoBg: {
    minHeight: "90vh",
    height: "max-content",
    overflow: "scroll",
    minWidth: "100vw",
    background: "#F0F3F5",
  },
  videoPlayer: {
    marginTop: "20px",
    height: "100%",
    // width: '100%',
    minHeight: "400px",
    overflow: "scroll",
  },
  videoDiv: {
    position: "relative",
  },
  gridStyle: {
    marginTop: "100px",
  },
  gridPlayer: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "56.25%",
  },
  responsiveIframe: {
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    width: "100%",
    height: "100%",
    padding: "2%",
  },
  "@media screen and (max-width: 601px) and (min-width: 424px)": {
    responsiveIframe: {
      position: "absolute",
    },
    gridPlayer: {
      marginLeft: "0",
    },
    gridStyle: {
      marginTop: "80px",
    },
  },
  "@media(max-width: 424px)": {
    responsiveIframe: {
      padding: "0",
      position: "relative",
      marginTop: "-28em",
    },
    gridPlayer: {
      marginLeft: "0",
    },
    gridStyle: {
      marginTop: "80px",
    },
    chatGrid: {
      marginTop: "-20em !important",
    },
  },
  YoutubePLayerContainer: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  watchNow: {
    marginTop: "20px",
    textTransform: "none",
    height: "56px",
    width: "200px",
    background: "#08C5A7",
    boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
    borderRadius: "16px",
    // fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",

    /* or 18px */
    textAlign: "center",
    color: "#FFFFFF",
    "&:hover": {
      background: "#0eab8f",
    },
  },
  coverChat: {
    marginTop: "-176px",
    background: "white",
    minHeight: "140px",
    position: "absolute",
    width: "400px",
  },
});

class Live extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      selectedValue: 0,
      selectedSubject: 0,
      meetingResp: null,
      netReq: false,
      videos: [],
      live: this.props.location.state && this.props.location.state.live,
      chat: this.props.location.state && this.props.location.state.chat,
      documents: [],
    };
    const { history } = props;
    if (!this.state.live) {
      history.pop();
    }
  }

  checkChat = () => {};
  mouClick = (event) => {};

  getAttendance = async (params) => {
    try {
      let userId = store.get("user") ? store.get("user").userId : undefined;
      let liveUrl = this.state.chat;

      let data = {
        userId,
        liveUrl,
        flipFlag: params.flipFlag,
      };
      let res = await recordAttendance(data);
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount = async () => {
    await this.getAttendance({ flipFlag: true });

    let instituteId = store.get("user").institute;
    if (store.get("user").level) {
      instituteId = [store.get("user").institute, store.get("user").level].join(
        "_"
      );
    }

    let liveUrl = this.state.chat;

    console.log("============ in live ========================");
    console.log(instituteId, liveUrl);
    console.log("====================================");
    let d = await getAssignments({ instituteId, liveUrl });

    if (d.data && d.data.Item && d.data.Item.videoassignments) {
      this.setState({
        documents: d.data.Item.videoassignments,
      });
    }
  };

  componentWillUnmount = async () => {
    await this.getAttendance({ flipFlag: false });
  };

  render() {
    const { classes, location } = this.props;
    return (
      <Fragment>
        {store.get("user").level &&
          store.get("domainConfig") &&
          store.get("domainConfig").alerts &&
          store.get("domainConfig").alerts[store.get("user").level] && (
            <Alert
              message={
                store.get("domainConfig").alerts[store.get("user").level]
                  .message
              }
              title={
                store.get("domainConfig").alerts[store.get("user").level].title
              }
            />
          )}
        {/* <Header /> */}
        {this.state.live &&
          (location.state.data && location.state.data.provider === "youtube" ? (
            <Container className={classes.YoutubePLayerContainer}>
              <br />
              <br />
              <div className={classes.videoDiv} container="div">
                <YoutubePLayer YTid={`${this.state.live}`} width="100%" />
              </div>
            </Container>
          ) : (
            <Grid
              container
              direction="row"
              justify="center"
              //alignItems="center"
              className={classes.gridStyle}
            >
              <Grid item xs={12} sm={9} md={8}>
                <div className={classes.gridPlayer}>
                  <iframe
                    title="videos"
                    className={classes.responsiveIframe}
                    id="video-onesaz"
                    src={`https://player.vimeo.com/video/${
                      this.state.live
                    }?quality=360p&h=${this.state.chat.split("/")[1]}`}
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={3} md={4}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item className={classes.chatGrid}>
                    <TalkChat
                      currentUser={store.get("user")}
                      other={{ id: this.state.live }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}

        <br />
        <Grid
          container
          direction="column"
          justify="center"
          //alignItems="center"
          style={{ marginLeft: "10px" }}
        >
          {/* <Grid item xs={12} sm={3} md={4}>
            <Typography variant="h5" gutterBottom>
              Documents
            </Typography>
          </Grid> */}
          <Grid item xs={12} sm={9} md={8}>
            {this.state.documents.map((e, i) => {
              return (
                <a target="_blank" href={e}>
                  <Typography className={classes.description} variant="body1">
                    {i + 1 + ". "}
                    {e.split("live_event_assignments/").length >= 2 &&
                      e.split("live_event_assignments/")[1]}
                  </Typography>
                </a>
              );
            })}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Live);
