import React, { useState } from 'react'
import moment from 'moment'
// mui
import Accordion from '@material-ui/core/Accordion'
import Typography from '@material-ui/core/Typography'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandIcon from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { PAYMENT_MODE, PAYMENT_STATUS } from '../../GlobalConstants'

// components
import ContentTypeA from './contentTypeA'
// styles
import feeDetailsStyles from './styles'
// utils
import numberWithCommas from '../../utils/numberWithCommas'
import InvoiceBox from '../../mycomponents/MakePayment/components/InvoiceBox'

const formatter = {
  format: fig => `₹${numberWithCommas(fig)}`
}

const TxnCard = ({ txn }) => {
  const classes = feeDetailsStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    receiptDate,
    amount,
    fees = {},
    status,
    receiptNo,
    txnId,
    paymentMode
  } = txn || {}
  const [count, setCount] = React.useState(0)
  const [open, setOpen] = useState(false)

  const [showFullTxnId, setShowFullTxnId] = useState(false)

  const handleDownloadReceipt = () => {
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
    setCount(0)
  }

  const toggleTxnId = () => {
    setShowFullTxnId(!showFullTxnId)
  }

  const truncatedTxnId =
    txnId.length > 20 ? `${txnId.substring(0, 20)}...` : txnId
  const color = {
    F: 'secondary',
    Ok: 'success',
    OK: 'success',
    ok: 'success',
    Pending: 'secondary'
  }

  return txn ? (
    <div className={classes.txnContainer}>
      <Accordion
        style={{ borderRadius: '16px' }}
        className={`${classes.accordion}`}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <div className={classes.descTitleContainer}>
            <Typography
              variant='caption'
              className={classes.subHeading}
              color='textPrimary'
            >
              {receiptNo || txnId || ''}
              <br />
              {/* {`${moment(receiptDate).format("DD.MM.YYYY")}`} */}
            </Typography>
            <Typography
              variant='caption'
              className={classes.subHeading}
              style={{ color: status === 'Ok' ? '#4daf50' : '#F54458' }}
            >
              {formatter.format(amount) || ''}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: '100%' }}>
            {Object.keys(fees).map((item, i) => {
              return (
                <div className={classes.descTitleContainer} key={`a-${i}`}>
                  <Typography
                    variant='caption'
                    color='textPrimary'
                    className={classes.descFeeName}
                  >
                    {item}
                  </Typography>
                  <Typography
                    variant='caption'
                    color='textPrimary'
                    className={classes.descFeeValue}
                  >
                    {formatter.format(fees[item] ? fees[item] : '')}
                  </Typography>
                </div>
              )
            })}
            <div className={classes.txnDescContainer}>
              <Typography variant='h6' className={classes.txnDetailsText}>
                Transaction Details
              </Typography>
              <ContentTypeA
                itemA={{
                  name: 'Transaction Id',
                  value: showFullTxnId ? txnId : truncatedTxnId,
                  valueColor: 'primary',
                  action:
                    txnId.length > 20 ? (
                      <Typography
                        variant='caption'
                        color='primary'
                        onClick={toggleTxnId}
                        style={{ cursor: 'pointer' }}
                      >
                        {showFullTxnId ? 'View Less' : 'View More'}
                      </Typography>
                    ) : null
                }}
                itemB={{
                  name: 'Receipt No',
                  value: `${receiptNo || txnId} `,
                  valueColor: 'primary'
                }}
              />
              <ContentTypeA
                itemA={{
                  name: 'Date & Time',
                  value: `${moment(receiptDate).format('DD.MM.YYYY hh:mm a')}`,
                  valueColor: 'primary'
                }}
                itemB={{
                  name: 'Status',
                  value: `${PAYMENT_STATUS[status]} `,
                  valueColor: color[status]
                }}
              />
              <ContentTypeA
                itemA={{
                  name: 'Payment Mode',
                  value: `${PAYMENT_MODE[paymentMode]} `,
                  valueColor: 'primary'
                }}
                // itemB={{
                //   name: 'Status',
                //   value: `${status === 'Ok' ? 'SUCCESS' : status} `,
                //   valueColor: 'success'
                // }}
              />
            </div>
            {status === 'Ok' && (
              <Box
                onClick={handleDownloadReceipt}
                style={{
                  display: 'inline-block',
                  paddingTop: '8px',
                  color: '#3f51b5',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  fontSize: '0.9rem',
                  fontWeight: 'bold'
                }}
              >
                <b>Download Receipt</b>
              </Box>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        maxWidth='md'
        fullWidth
        fullScreen={isMobile}
      >
        <DialogTitle>
          Invoice
          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <InvoiceBox
            data={txn}
            handleCloseDialog={handleCloseDialog}
            balance={0}
            count={count}
            setCount={setCount}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <b style={{ color: 'error' }}>No Data Available</b>
  )
}

export default TxnCard
