const extractString = (inputString) => {
  const stringArray = inputString.split("_");
  const ifArrayHasNumbers = stringArray.slice(0, 3);
  if (
    ifArrayHasNumbers.every((element) => {
      return element.match(/^[0-9]+$/) != null;
    })
  ) {
    let result;
    result = stringArray
      .filter((el) => !ifArrayHasNumbers.includes(el))
      .join(" ");
    if (result.includes("MAINS")) result = result.replace("MAINS", "MAIN");
    return result;
  }
  return stringArray.join(" ");
};
export default extractString;
