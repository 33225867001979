import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/styles";
import React from "react";
import theme from "../../assets/theme";
const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      marginRight: "2%",
    },
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
  },
}))((props) => (
  <LinearProgress variant={props.variant || "determinate"} {...props} />
));

const BarProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: "0px 5px 5px 0px",
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.info.main,
  },
}))((props) => <LinearProgress {...props} />);

export { BorderLinearProgress, BarProgress };
