import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
// theme
import theme from "../../assets/v3/theme";

const getStyles = (props) =>
  makeStyles(() => {
    const { palette = {}, borders = {}, boxShadows } = theme;
    const {
      variant = "",
      bgColor = "",
      color = "",
      opacity = "",
      borderRadius = "",
      shadow = "",
      ...rest
    } = props;
    const { grey } = palette;
    const { borderRadius: radius } = borders;

    const greyColors = {
      "grey-100": grey[100],
      "grey-200": grey[200],
      "grey-300": grey[300],
      "grey-400": grey[400],
      "grey-500": grey[500],
      "grey-600": grey[600],
      "grey-700": grey[700],
      "grey-800": grey[800],
      "grey-900": grey[900],
      "grey-mb": grey.mb,
    };
    const validColors = [
      "transparent",
      "white",
      "black",
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "text",
      "grey-100",
      "grey-200",
      "grey-300",
      "grey-400",
      "grey-500",
      "grey-600",
      "grey-700",
      "grey-800",
      "grey-900",
      "grey-mb",
      "newUi.info",
    ];
    const validBorderRadius = ["xs", "sm", "md", "lg", "xl", "xxl", "section"];
    const validBoxShadows = [
      "xs",
      "sm",
      "md",
      "lg",
      "xl",
      "xxl",
      "inset",
      "mobileCard",
      "mobileInlineShadow",
    ];

    // background value
    let backgroundValue = bgColor;

    if (validColors.includes(bgColor)) {
      if (bgColor.startsWith("newUi.")) {
        backgroundValue =
          palette.newUi &&
          palette.newUi[bgColor.split(".")[1]]; // Explicit check for palette.newUi
      } else {
        backgroundValue =
          (palette[bgColor] && palette[bgColor].main) || greyColors[bgColor];
      }
    }

    // color value
    let colorValue = color;

    if (validColors.find(function (el) { return el === color; })) {
      colorValue =
        (palette[color] && palette[color].main) || greyColors[color];
    }

    // borderRadius value
    let borderRadiusValue = borderRadius;

    if (validBorderRadius.find(function (el) { return el === borderRadius; })) {
      borderRadiusValue = radius[borderRadius];
    }

    // boxShadow value
    let boxShadowValue = boxShadows;

    if (validBoxShadows.find(function (el) { return el === shadow; })) {
      boxShadowValue = boxShadows[shadow];
    }

    return {
      root: {
        background: backgroundValue,
        color: colorValue,
        opacity: opacity || 1,
        borderRadius: borderRadiusValue,
        boxShadow: boxShadowValue,
        ...rest,
      },
    };
  });

const StyledBox = ({ children, marginTop, padding, className, ...props }) => {
  const classes = getStyles(props)();
  let classNameNew = classes.root;
  if (className) {
    classNameNew = `${classNameNew} ${className}`;
  }
  return <Box className={classNameNew} marginTop={marginTop} padding={padding}>{children}</Box>;
};

export default StyledBox;
