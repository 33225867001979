import { Button, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import axios from "axios";
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import store from "store";
import paymentService from "../../utils/paymentService";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "65vh",
    paddingTop: "20px",
  },
  hrStyle: {
    background: theme.palette.primary.main,
    width: "30%",
    height: "2px",
    border: "none",
  },
  paper: {
    padding: theme.spacing(5),
    borderTop: "1.2px solid white",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    borderLeft: "1.2px solid white",
    borderRadius: "24px",
    marginBottom: "5px",
  },
  linkColor: {
    color: "#08C5A7",
  },
  btnText: {
    fontSize: "10px",
  },
}));
function PlansContent(props) {
  const classes = useStyles();

  const { history } = props;
  const signup = () => {
    history.push("/register");
  };

  const logPayment = (body) => {
    const url = window.location.origin;
    const ax = axios.create({
      baseURL: url + "/api",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    ax.post("/pay/success", JSON.stringify(body)).then((res) => {
      window.location.reload();
      if (res.status == 200) {
        console.log(res);
        history.push("/profile");
      }
    });
  };
  const updatePaymentStatus = (response) => {
    // const map = {
    //   crash: 1132214,
    // };
    const user = store.get("user");
    user.statusType = "paid";
    user.package = "fulltests_2021";
    user.level = 1132216;
    store.set("user", user);
    logPayment(response);
    // alert(store.get("user").statusType);
    // console.log(response);
  };
  const payment = (type) => {
    const url = "https://onesaz.com";
    // const url = window.location.origin;
    let payAmount = {
      MAINS2022: 12800,
      MA: 6000,
      N: 6000,
      MAN: 9000,
      crash: 5900,
      fulltests_2021: 1260,
      crashtests_2020: 580,
    };
    let user = JSON.parse(window.localStorage.getItem("user"));
    // console.log(user._id);
    var requestData = {
      _id: user._id,
      key: "97PIzxdO",
      txnid: store.get("user").userId,
      // color: "primary",
      hash: "",
      amount: payAmount[type],
      firstname: user["name"],
      email: "support@onesaz.com",
      phone: user["phoneNumber"],
      productinfo: JSON.stringify({
        _id: user._id,
        package: type,
      }),
      surl: url + "/api/pay/success",
      furl: url + "/api/paymentfailed",
      salt: "salt", //"UhvE4JAdfK",
      // mode: "dropout" // non-mandatory for Customized Response Handling
    };
    var Handler = {
      responseHandler: (BOLT) => {
        if (BOLT.response.txnStatus != "CANCEL") {
          console.log(BOLT.response);
          updatePaymentStatus(BOLT.response);
        }
        return BOLT.response;
      },
      catchException: function(BOLT) {},
    };
    const ax = axios.create({
      baseURL: url + "/api",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    ax.post("/pay/request", JSON.stringify(requestData)).then((res) => {
      console.log(res);
      if (res.data.status == "SUCCESS") {
        requestData.hash = res.data.hash;
        requestData.txnid = res.data.txnid;
        window.bolt.launch(requestData, Handler);
      }
    });
    //  window.bolt.launch( requestData , Handler );
  };

  const paynow = (planType) => {
    switch (planType) {
      case "crash":
        paymentService(planType);
        break;
      default:
        paymentService(planType);
        break;
    }
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {/* <Grid item xs sm={6}>
          <Paper className={classes.paper}>
            <Typography className={classes.head}>
              Free
              <hr className={classes.hrStyle} />
            </Typography>
            <Typography>
              <CheckCircleRoundedIcon fontSize="small" /> &nbsp;10 Prevous Paper
              <br />
              <CheckCircleRoundedIcon fontSize="small" /> &nbsp;Best Analysis
              <br />
              <CheckCircleRoundedIcon fontSize="small" /> &nbsp;Onesaz Exam
              Paper
            </Typography>
            <br />
            <Typography align="center">
              <Button >More Details</Button>
            </Typography>
          </Paper>
        </Grid> */}
        {/* <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <Typography align="center" color="textPrimary" variant="subtitle1">
              {props.courseName}
              <hr className={classes.hrStyle} />
            </Typography>
            <Typography>
              <CheckCircleRoundedIcon fontSize="inherit" /> &nbsp;Live classes
              <br />
              <CheckCircleRoundedIcon fontSize="inherit" /> &nbsp;Recorded
              Classes
              <br />
              <CheckCircleRoundedIcon fontSize="inherit" /> &nbsp;Online Test
              Series
              <br />
              <CheckCircleRoundedIcon fontSize="inherit" /> &nbsp;Live &
              Interactive Doubt Clarification
              <br />
              <CheckCircleRoundedIcon fontSize="small" /> &nbsp;Online Daily
              Practice Sheets
              <br />
              <CheckCircleRoundedIcon fontSize="small" /> &nbsp; Duration
              July'20 - Sept'20
              <br />
              <CheckCircleRoundedIcon fontSize="small" /> &nbsp; Starts from
              24th July
            </Typography>
            <br />
            <Typography align="center">
              {store.get("user") && store.get("user").userId != null ? (
                <Fragment>
                  {store.get("user").statusType !== "paid" ? (
                    <Fragment>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          paynow("crash");
                        }}
                      >
                        Pay Rs. 5,900/-
                      </Button>
                      <div style={{ fontSize: "10px" }}>(including GST)</div>
                    </Fragment>
                  ) : (
                    <Button disabled variant="contained" color="primary">
                      Already Subscribed
                    </Button>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      signup();
                    }}
                  >
                    Sign up Now
                  </Button>
                  <div style={{ fontSize: "10px" }}>Starts at Rs. 5,900/- </div>
                </Fragment>
              )}
            </Typography>
          </Paper>
        </Grid> */}
        {/* Test Series */}
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <Typography align="center" color="textPrimary" variant="subtitle1">
              {props.courseName}
              <hr className={classes.hrStyle} />
            </Typography>
            <Typography>
              <CheckCircleRoundedIcon fontSize="small" /> &nbsp;Onesaz Online
              Test Series
              <br />
              <CheckCircleRoundedIcon fontSize="small" /> &nbsp;Target 2021-2022
              <br />
              <CheckCircleRoundedIcon fontSize="small" /> &nbsp; If you are
              targeting JEE Main and Advance in 2022, try Onesaz test series to
              know your approximately estimated marks in the Real exams
              <br />
              <CheckCircleRoundedIcon fontSize="small" /> &nbsp; The test
              package consists of 1 Free Practice Test + 30 Jee Mains Full Tests
              + 4 Advance Full Tests
              <br />
              <CheckCircleRoundedIcon fontSize="small" /> &nbsp; The series is
              designed by the proficient faculties, try our free practice test
              to get the essence of the tests standard
              <br />
              <CheckCircleRoundedIcon fontSize="small" /> &nbsp; It provides you
              with the ideal way of preparing for the target exam following an
              exact exam-like test format with detailed analysis
              <br />
              {/* <CheckCircleRoundedIcon fontSize="small" /> &nbsp;{" "} */}
              {/* <a
                className={classes.linkColor}
                onClick={() => {
                  window.open(
                    "https://onesaz-new.s3.ap-south-1.amazonaws.com/schedules/Onesaz+Online+Test+Series+2020-2021_FULL_TESTS.pdf"
                  );
                }}
              >
                Click here for Test Schedule and Syllabus
              </a> */}
            </Typography>
            <br />
            <Typography align="center">
              {store.get("user") && store.get("user").userId != null ? (
                <Fragment>
                  {store.get("user").statusType != "paid" ? (
                    <Fragment>
                      {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          paynow("MAINS2022");
                        }}
                      >
                        Explore{" "}
                      </Button> */}
                      <div className={classes.btnText}>(Explore)</div>
                    </Fragment>
                  ) : (
                    <Button disabled variant="contained" color="primary">
                      Already Subscribed
                    </Button>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      signup();
                    }}
                  >
                    Sign up Now
                  </Button>
                </Fragment>
              )}
            </Typography>
          </Paper>
        </Grid>
        {/* <Grid item xs={10}>
          <Typography variant="div">
        If you are targeting JEE Main and Advance 2021 exam, it’s time to complement it with the brilliance of Onesaz Online Test Series. The series designed by the proficient faculties focuses majorly on the concept which emphasizes more and more practice. It provides you with the ideal way of preparing for the target exam following an exact exam-like test format. The test package consists of 4 Unit Tests + 7 Cumulative Tests + 3 Part Tests + 17 Full Tests. For best result, kindly attempt the Mock Tests under real exam environment and go through performance in-depth analysis for best result.
        </Typography></Grid> */}
      </Grid>
    </div>
  );
}
export default withRouter(PlansContent);
