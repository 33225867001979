import { Chip } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
// import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import examAxios from "../apis/examServerAxios";
import GridContainer from "../components/Grid/GridContainer.jsx";
import Header from "./Header";
import CheckedOptions from "./IconA";
import InputIntegerPanelWs from "./InputIntegerPanelWs";
import "./UpComingCard.css";

// var moment = require("moment");
const options = ["A", "B", "C", "D"];
const styles = (theme) => ({
  success: {
    // color: 'green'
  },
  disabledColor: {
    color: "black",
  },
  sumbit: {
    background: "linear-gradient(45deg, #2B9FF8 30%, #2B9FF8 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 32,
    padding: "0 30px",
    boxShadow: "0px 3px 6px #1E394E66;",
  },
  qheading: {
    textAlign: "left",
    font: "Bold 22px/27px Roboto",
    letterSpacing: "0",
    color: "#111727",
    opacity: "1",
  },
  solheading: {
    margin: "16px",
    padding: "16px",
    borderRadius: "8px",
    background: "#00800021",
    // opacity: '0.4'
  },
  sheading: {
    textAlign: "left",
    font: "Regular 20px/26px Roboto",
    letterSpacing: "0",
    color: "#51CC8F",
    opacity: "1",
  },
  card: {
    boxShadow:
      "inset 0 4px 7px 1px #ffffff, inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14), 0 10px 20px rgba(0, 21, 64, 0.05)",
    borderRadius: "15px",
    maxWidth: 345,
    marginBottom: theme.spacing.unit * 2,
  },
  media: {
    height: 100,
  },

  rankBadge: {
    marginTop: "-60px",
    width: "7em",
    height: "7em",
    padding: "2em",
    marginRight: "-10%",
    textAlign: "center",
    verticalAlign: "middle",
    background: "beige",
    borderRadius: "10em",
  },
  wsBack: {
    left: "0px",
    // minWidth: '1366px',
    background: "#ECF8FF 0% 0% no-repeat padding-box",
    opacity: "1",
    minHeight: "500px",
  },
  instructionsHeading: {
    color: "#FFDD00",
  },
  instructionsBanner: {
    height: "50px",
    backgroundColor: "#494B5E",
  },
  textField: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
  },
  dense: {
    marginTop: theme.spacing.unit * 2,
  },
  imageIcon: {
    height: "100%",
  },
  iconRoot: {
    textAlign: "center",
  },
  checkedRadioOptions: {
    width: "40px",
    height: "40px",
  },
  radioOptions: {
    width: "40px",
    height: "40px",
    backgroundColor: "#e94733", //'#eb5944' ,//'#64cc82', //'#72e192', //'#2aabe2',
    color: "white",
  },
  radio: {
    padding: "4px",
    color: "black",
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    //  boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    "&$checked": {
      // background: 'linear-gradient(60deg,#5db85c, #5db85c)',
      background: "linear-gradient(45deg, #51beed 30%, #51beed 90%)",
      color: "#fff",
    },
  },
  checked: {
    backgroundColor: "linear-gradient(60deg,#51beed, #5db85c)",
    color: "#51beed", //'#535d8c',5db85c
  },
  wrongChip: {
    color: "#f30707",
    backgroundColor: "#ff07070d",
    letterSpacing: "2.2px",
  },
  correctChip: {
    color: "#008000",
    backgroundColor: "#00800021",
    letterSpacing: "2.2px",
  },
  wrong: {
    color: "#fff !important",
    background: "linear-gradient(45deg, #ed5851 30%, #ed5151 90%)",
  },
  correct: {
    color: "#fff !important",
    background: "linear-gradient(45deg, #008000 30%, #008000 90%)",
  },
  multipleradio: {
    padding: "4px",
    color: "black",
  },
  multiplechecked: {
    // backgroundColor: 'linear-gradient(60deg,#5db85c, #5db85c)',
    color: "#51beed !important", //'#535d8c'
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  expired: {
    background: "red",
    borderRadius: "25px",
    color: "white",
    fontWeight: "600",
  },
  btnAttempt: {
    //   maxWidth: 'max-content',
    // marginLeft: '15%',
    minwidth: "90%",
  },
  avatar: {
    margin: 10,
  },
  group: {
    margin: theme.spacing.unit * 1,
  },
  labelMargin: {
    marginBottom: theme.spacing.unit * 2,
  },
  btndefault: {
    border: "1px solid grey",
    color: "#333",
    backgroundColor: "#fff",
    borderColor: "#ccc",
  },
  btnreview: {
    color: "#fff",
    backgroundColor: "#337ab7",
    borderColor: "#2e6da4",
  },
  btnwarning: {
    color: "#fff",
    backgroundColor: "#f0ad4e",
    borderColor: "#eea236",
  },
  btnSubmit: {
    color: "#fff",
    // backgroundColor: '#5cb85c',
    background: "linear-gradient(45deg, #0B80F1 30%,#0B80F1 90%)",
    borderColor: "#4cae4c",
  },
  btnsuccess: {
    color: "#fff",
    // backgroundColor: '#5cb85c',
    background: "linear-gradient(45deg, #5db85c 30%,#5db85c 90%)",
    borderColor: "#4cae4c",
  },
  btnsuccess1: {
    color: "#fff",
    // backgroundColor: '#5cb85c',
    background: "linear-gradient(45deg, #178DFF 30%,#178DFF 90%)",
    borderColor: "#4cae4c",
  },
  // btnSubmit: {
  // background: 'green',
  // color: 'white'
  //     },
  button: {
    margin: theme.spacing.unit * 1,
  },
  input: {
    display: "none",
  },
  bigAvatar: {
    marginTop: -10,
  },
  root: {
    flexGrow: 1,
  },
  profile: {
    flexGrow: 4,
  },
  subjectBar: {
    // height: theme.spacing(8)
  },
  paper: {
    padding: theme.spacing.unit * 4,
    textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  gridsep: {
    minHeight: theme.spacing.unit * 3,
  },
  newgridsep: {
    minHeight: theme.spacing.unit * 1,
  },
  mathDisplay: {
    display: "none",
  },
});
class WorkSheet extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      questions: [],
      networkProgress: true,
    };
    console.log(this.props, this.props.location.state.worksheet.WorkSheetId);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.setIntegerValue = this.setIntegerValue.bind(this);
  }
  submit = (num) => {
    const body = {
      //    106d7b10-cae6-11e9-92f8-679707ce3a41
      workSheetId: this.props.location.state.worksheet.WorkSheetId,
      studentId: store.get("user")._id,
      questionNumber: num + "",
      studentAnswer: this.state.questions[num - 1].markedAnswer,
      topic: this.state.questions[num - 1].topic,
      subTopic: this.state.questions[num - 1].subTopic,
      q_Type: this.state.questions[num - 1].q_type,
    };
    examAxios.post("/worksheet/submitanswer", body).then((res) => {
      console.log(res);
      res = res.data;
      let update_c_q = this.state.questions[num - 1];
      update_c_q.answer = res.correctAnswer;
      update_c_q.status = res.status;
      update_c_q.solution = res.solution;
      this.state.questions[num - 1] = update_c_q;
      this.setState({ networkProgress: false });
      this.forceUpdate();
    });
  };
  handleChange = (event, num) => {
    console.log(event.target.value, num, this.state.questions[num - 1]);
    let update_c_q = this.state.questions[num - 1];
    update_c_q.markedAnswer = event.target.value;
    this.state.questions[num - 1] = update_c_q;
    this.forceUpdate();
  };
  handleMultiple = (questionNumber, option) => (event) => {
    // html_current_question.questionNumber
    questionNumber = parseInt(questionNumber) - 1;
    console.log(
      questionNumber,
      option,
      this.state.questions[parseInt(questionNumber)].markedAnswer,
      event.target.checked
    );
    if (this.state.questions[parseInt(questionNumber)].markedAnswer === "NA") {
      this.state.questions[parseInt(questionNumber)].markedAnswer = "";
    }
    if (event.target.checked) {
      if (
        this.state.questions[parseInt(questionNumber)].markedAnswer.includes(
          option
        )
      ) {
      } else {
        this.state.questions[parseInt(questionNumber)].markedAnswer += option;
        this.forceUpdate();
      }
    } else {
      if (
        this.state.questions[parseInt(questionNumber)].markedAnswer.includes(
          option
        )
      ) {
        this.state.questions[
          parseInt(questionNumber)
        ].markedAnswer = this.state.questions[
          parseInt(questionNumber)
        ].markedAnswer.replace(option, "");
        this.forceUpdate();
      }
    }

    // this.state.current_question.markedAnswer += option;
  };
  isChecked = (questionNumber, option) => {
    // console.log(option)
    if (
      this.state.questions[parseInt(questionNumber) - 1].markedAnswer === "NA"
    ) {
      return false;
    }
    return this.state.questions[
      parseInt(questionNumber) - 1
    ].markedAnswer.includes(option);
  };

  setIntegerValue = (questionNumber, value) => {
    console.log(questionNumber, value);
    this.state.questions[parseInt(questionNumber) - 1].markedAnswer = value;
  };
  componentDidUpdate() {
    const math = document.getElementById("student-math-ws");
    if (math)
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, math, () => {}]);
  }
  componentDidMount() {
    const body = {
      studentId: store.get("user")._id,
      //"106d7b10-cae6-11e9-92f8-679707ce3a41"
      workSheetId: this.props.location.state.worksheet.WorkSheetId,
    };
    examAxios.post("/worksheet/worksheetquestions", body).then((res) => {
      console.log(res.data);
      res = res.data;
      if (res.status === "SUCCESS") {
        if (res.payload && res.payload.Items) {
          res.payload.Items = res.payload.Items.map((item) => {
            if (item.markedAnswer === undefined) item.markedAnswer = "NA";
            return item;
          });
          console.log(res.payload.Items);
          this.setState({ questions: res.payload.Items });
        }
      }
    });
    //   console.log(moment(this.props.exam.time_started).format('MMM DD YYYY'))
  }
  render() {
    const { classes } = this.props;
    // const props = this.props;
    const html_current_questions = [];

    const v = this.state.questions.forEach((question) => {
      const html_current_question = { ...question };
      const options = ["A", "B", "C", "D"];
      html_current_question.questionNumber = question.questionNumber;
      html_current_question.q = (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <div dangerouslySetInnerHTML={{ __html: question.q }} />
        </Grid>
      );
      options.forEach((option) => {
        html_current_question[option] = (
          <span dangerouslySetInnerHTML={{ __html: question[option] }} />
        );
      });
      html_current_question.solution = (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <div dangerouslySetInnerHTML={{ __html: question.solution }} />
        </Grid>
      );
      if (html_current_question.paragraph !== undefined)
        html_current_question.paragraph = (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <div dangerouslySetInnerHTML={{ __html: question.paragraph }} />
          </Grid>
        );
      html_current_questions.push(html_current_question);
    });
    const values = [];
    html_current_questions.forEach((html_current_question) => {
      let val = undefined;
      console.log(html_current_question);
      switch (html_current_question.q_type) {
        /// for single option
        case "list":
        case "single":
          val = (
            <React.Fragment>
              <GridContainer justify="center" style={{ padding: "24px" }}>
                <GridItem xs={12} sm={10}>
                  <Typography component="div" className="qpanel-ws">
                    {html_current_question.paragraph !== undefined && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            Paragraph
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography component="div">
                            {html_current_question.paragraph}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {/* <Divider variant="middle"></Divider> */}
                    <GridContainer direction="row" justify="flex-start">
                      <GridItem xs={8} sm={10}>
                        <br />
                        <Typography
                          component="span"
                          variant="span"
                          className={classes.qheading}
                        >
                          {" "}
                          Q {html_current_question.questionNumber}.
                        </Typography>
                        {/* <Hidden xsUp> 

                                               </Hidden> */}
                      </GridItem>
                      <GridItem xs={4} sm={2}>
                        {html_current_question.status === "unattempted" ? (
                          ""
                        ) : (
                          <Chip
                            style={{ marginTop: "16px" }}
                            label={html_current_question.status}
                            className={
                              html_current_question.status !== "unattempted"
                                ? html_current_question.status === "wrong"
                                  ? classes.wrongChip
                                  : classes.correctChip
                                : ""
                            }
                            size="small"
                          />
                        )}
                      </GridItem>
                      <br />
                      <br />
                      <GridItem xs={12}>{html_current_question.q}</GridItem>
                    </GridContainer>
                    <GridContainer direction="row" justify="flex-start">
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormLabel component="legend" />
                        <RadioGroup
                          className={classes.group}
                          value={
                            html_current_question.markedAnswer === undefined
                              ? ""
                              : html_current_question.markedAnswer
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event,
                              html_current_question.questionNumber
                            )
                          }
                        >
                          {options.map((option) => {
                            return (
                              <FormControlLabel
                                className={classes.labelMargin}
                                checked={
                                  html_current_question.markedAnswer === option
                                }
                                key={
                                  html_current_question.questionNumber +
                                  "_" +
                                  option
                                }
                                value={option}
                                control={
                                  <Radio
                                    disabled={
                                      html_current_question.status !==
                                      "unattempted"
                                    }
                                    classes={{
                                      root: classes.radio,
                                      checked:
                                        html_current_question.status ===
                                        "unattempted"
                                          ? classes.checked
                                          : classes[
                                              html_current_question.status
                                            ],
                                    }}
                                    icon={
                                      <CheckedOptions option={option}>
                                        {" "}
                                      </CheckedOptions>
                                    }
                                    checkedIcon={
                                      <CheckedOptions
                                        style={{ fontSize: "10px" }}
                                        option={option}
                                      >
                                        {" "}
                                      </CheckedOptions>
                                    }
                                  />
                                }
                                label={
                                  <Grid
                                    container
                                    className={
                                      html_current_question.status !==
                                      "unattempted"
                                        ? classes.disabledColor
                                        : ""
                                    }
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                  >
                                    &nbsp; &nbsp;&nbsp; &nbsp;{" "}
                                    {html_current_question[option]} &nbsp;&nbsp;{" "}
                                    {html_current_question.answer !==
                                      undefined &&
                                    html_current_question.answer.includes(
                                      option
                                    ) ? (
                                      <DoneIcon style={{ color: "green" }} />
                                    ) : (
                                      ""
                                    )}{" "}
                                  </Grid>
                                }
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </GridContainer>

                    {/* <Divider variant="middle"></Divider> */}
                    {html_current_question.status === "unattempted" ? (
                      <GridContainer
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <GridItem xs={5} sm={2}>
                          {" "}
                          <Button
                            classes={{ root: classes.sumbit }}
                            color="primary"
                            onClick={() =>
                              this.submit(html_current_question.questionNumber)
                            }
                          >
                            Submit
                          </Button>
                          <br />{" "}
                        </GridItem>
                      </GridContainer>
                    ) : (
                      <GridContainer direction="row" justify="flex-start">
                        <React.Fragment>
                          <GridItem xs={12} className={classes.solheading}>
                            <Typography
                              variant="h6"
                              component="h6"
                              style={{ color: "green" }}
                            >
                              SOLUTION
                            </Typography>

                            <br />
                            {/* <Typography variant="span" component="span"> */}
                            {html_current_question.solution}
                            {/* </Typography> */}
                          </GridItem>
                        </React.Fragment>
                      </GridContainer>
                    )}
                    {/* <Typography component="div" style={{ top: '24em', left: this.expandfromleft.left, position: 'absolute', zIndex: '1', display: this.props.width !== "lg" ? 'none' : 'unset' }}>
                                                <Fab onClick={() => { this.expandQuestion(); this.forceUpdate() }}>
                                                    {this.expandfromleft.icon}
                                                </Fab>
                                        </Typography> */}
                  </Typography>
                </GridItem>
              </GridContainer>
            </React.Fragment>
          );
          break;
        // for multiple options / partial
        case "multiple":
        case "partial":
        case "single_partial":
          val = (
            <React.Fragment>
              <GridContainer justify="center" style={{ padding: "24px" }}>
                <GridItem xs={12} sm={10}>
                  <Typography component="div" className="qpanel-ws">
                    <GridContainer direction="row" justify="flex-start">
                      <GridItem xs={8} sm={10}>
                        <br />
                        <Typography
                          component="span"
                          variant="span"
                          className={classes.qheading}
                        >
                          {" "}
                          Q {html_current_question.questionNumber}.
                        </Typography>
                      </GridItem>
                      <GridItem xs={4} sm={2}>
                        {html_current_question.status === "unattempted" ? (
                          ""
                        ) : (
                          <Chip
                            style={{ marginTop: "16px" }}
                            label={html_current_question.status}
                            className={
                              html_current_question.status !== "unattempted"
                                ? html_current_question.status === "wrong"
                                  ? classes.wrongChip
                                  : classes.correctChip
                                : ""
                            }
                            size="small"
                          />
                        )}
                        {/* <Typography component="span" variant="span" className={classes.qheading} style={{fontSize: '12px'}} >
                                                                {html_current_question.status=="unattempted"?'': html_current_question.status}
                                                     </Typography> */}
                      </GridItem>

                      <GridItem xs={12}>{html_current_question.q}</GridItem>
                    </GridContainer>
                    <GridContainer direction="row" justify="flex-start">
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormLabel component="legend" />
                        <FormGroup
                          className={classes.group}
                          //value={this.state.current_question.markedAnswer===undefined? "": this.state.current_question.markedAnswer}
                          //onChange={this.handleChange}
                        >
                          {options.map((option) => {
                            return (
                              <FormControlLabel
                                className={classes.labelMargin}
                                checked={
                                  html_current_question.markedAnswer === option
                                }
                                key={
                                  html_current_question.questionNumber +
                                  "_" +
                                  option
                                }
                                value={option}
                                control={
                                  <Checkbox
                                    disabled={
                                      html_current_question.status !==
                                      "unattempted"
                                    }
                                    checked={this.isChecked(
                                      html_current_question.questionNumber,
                                      option
                                    )}
                                    onChange={this.handleMultiple(
                                      html_current_question.questionNumber,
                                      option
                                    )}
                                    classes={{
                                      root: classes.multipleradio,
                                      checked: classes.multiplechecked,
                                    }}
                                  />
                                }
                                label={
                                  <Grid
                                    container
                                    direction="row"
                                    className={
                                      html_current_question.status !==
                                      "unattempted"
                                        ? classes.disabledColor
                                        : ""
                                    }
                                    justify="flex-start"
                                    alignItems="center"
                                  >
                                    &nbsp; &nbsp;&nbsp; &nbsp;{" "}
                                    {html_current_question[option]} &nbsp;&nbsp;{" "}
                                    {html_current_question.answer !==
                                      undefined &&
                                    html_current_question.answer.includes(
                                      option
                                    ) ? (
                                      <DoneIcon style={{ color: "green" }} />
                                    ) : (
                                      ""
                                    )}{" "}
                                  </Grid>
                                }
                              />
                            );
                          })}
                        </FormGroup>
                      </FormControl>
                    </GridContainer>

                    {html_current_question.status === "unattempted" ? (
                      <GridContainer justify="center">
                        <GridItem xs={5} sm={2} alignItems="center">
                          {" "}
                          <Button
                            classes={{ root: classes.sumbit }}
                            color="primary"
                            onClick={() =>
                              this.submit(html_current_question.questionNumber)
                            }
                          >
                            Submit
                          </Button>
                          <br />{" "}
                        </GridItem>
                      </GridContainer>
                    ) : (
                      <GridContainer direction="row" justify="flex-start">
                        <GridItem xs={12} className={classes.solheading}>
                          <Typography
                            variant="h6"
                            component="h6"
                            style={{ color: "green" }}
                          >
                            SOLUTION
                          </Typography>

                          <br />
                          {/* <Typography variant="span" component="span"> */}
                          {html_current_question.solution}
                          {/* </Typography> */}
                        </GridItem>
                      </GridContainer>
                    )}
                    {/* <Typography component="div" style={{ top: '24em', left: this.expandfromleft.left, position: 'absolute', zIndex: '1', display: this.props.width !== "lg" ? 'none' : 'unset' }}>
                                                <Fab onClick={() => { this.expandQuestion(); this.forceUpdate() }}>
                                                    {this.expandfromleft.icon}
                                                </Fab>
                                        </Typography> */}
                  </Typography>
                </GridItem>
              </GridContainer>
            </React.Fragment>
          );
          break;
        case "integer":
        case "float":
          val = (
            <React.Fragment>
              <GridContainer
                justify="center"
                spacing={3}
                style={{ padding: "20px" }}
              >
                <GridItem xs={12} sm={10}>
                  <Typography
                    component="div"
                    className="qpanel"
                    style={{
                      padding: "0px 18px",
                      minWidth: "100%",
                      maxWidth: "100%",
                      minHeight: "max-content",
                    }}
                  >
                    <GridContainer direction="row" justify="flex-start">
                      <GridItem xs={8} sm={10}>
                        <br />
                        <Typography
                          component="span"
                          variant="span"
                          className={classes.qheading}
                        >
                          {" "}
                          Q {html_current_question.questionNumber}
                        </Typography>
                      </GridItem>
                      <GridItem xs={4} sm={2}>
                        {html_current_question.status === "unattempted" ? (
                          ""
                        ) : (
                          <Chip
                            style={{ marginTop: "16px" }}
                            label={html_current_question.status}
                            className={
                              html_current_question.status !== "unattempted"
                                ? html_current_question.status === "wrong"
                                  ? classes.wrongChip
                                  : classes.correctChip
                                : ""
                            }
                            size="small"
                          />
                        )}
                        {/* <Typography component="span" variant="span" className={classes.qheading} style={{fontSize: '12px'}} >
                                                                {html_current_question.status=="unattempted"?'': html_current_question.status}
                                                     </Typography> */}
                      </GridItem>
                      <GridItem xs={12}>{html_current_question.q}</GridItem>
                    </GridContainer>

                    {html_current_question.status === "unattempted" ? (
                      <React.Fragment>
                        <GridContainer
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <GridItem xs={6} sm={4}>
                            {
                              <InputIntegerPanelWs
                                markedAnswer={
                                  html_current_question.markedAnswer === "NA"
                                    ? ""
                                    : html_current_question.markedAnswer
                                }
                                setIntegerValue={this.setIntegerValue}
                                questionNumber={
                                  html_current_question.questionNumber
                                }
                              >
                                {" "}
                              </InputIntegerPanelWs>
                            }
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <br />
                          <GridItem xs={5} sm={2} alignItems="center">
                            <br />{" "}
                            <Button
                              classes={{ root: classes.sumbit }}
                              color="primary"
                              onClick={() =>
                                this.submit(
                                  html_current_question.questionNumber
                                )
                              }
                            >
                              Submit
                            </Button>
                            <br />{" "}
                          </GridItem>
                        </GridContainer>
                      </React.Fragment>
                    ) : (
                      <GridContainer direction="row" justify="flex-start">
                        <React.Fragment>
                          <GridItem xs={12}>
                            <br />
                            <Typography component="span" variant="span">
                              Your Answer: {html_current_question.markedAnswer}
                            </Typography>
                            <br />
                          </GridItem>
                          <GridItem xs={12} className={classes.solheading}>
                            <Typography
                              variant="h6"
                              component="h6"
                              style={{ color: "green" }}
                            >
                              SOLUTION
                            </Typography>

                            <br />
                            {/* <Typography variant="span" component="span"> */}
                            {html_current_question.solution}
                            {/* </Typography> */}
                          </GridItem>
                        </React.Fragment>
                      </GridContainer>
                    )}
                  </Typography>
                  {/* <Typography component="div" style={{ top: '24em', left: this.expandfromleft.left, position: 'absolute', zIndex: '1', display: this.props.width !== "lg" ? 'none' : 'unset' }}>
                                                <Fab onClick={() => { this.expandQuestion(); this.forceUpdate() }}>
                                                    {this.expandfromleft.icon}
                                                </Fab>
                                        </Typography> */}
                </GridItem>
              </GridContainer>
            </React.Fragment>
          );
          break;
        default:
          val = (
            <React.Fragment>
              <GridContainer justify="center" style={{ padding: "24px" }}>
                <GridItem xs={10}>
                  <Typography component="div" className="qpanel-ws">
                    {html_current_question.paragraph !== undefined && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            Paragraph
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography component="div">
                            {html_current_question.paragraph}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {/* <Divider variant="middle"></Divider> */}
                    <GridContainer direction="row" justify="flex-start">
                      <GridItem>
                        <Typography
                          component="span"
                          variant="span"
                          className={classes.qheading}
                        >
                          {" "}
                          Q {html_current_question.questionNumber}.
                        </Typography>
                      </GridItem>
                      <br /> <br />
                      <GridItem xs={12}>{html_current_question.q}</GridItem>
                    </GridContainer>
                    <GridContainer direction="row" justify="flex-start">
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormLabel component="legend" />
                        <RadioGroup
                          className={classes.group}
                          value={
                            html_current_question.markedAnswer === undefined
                              ? ""
                              : html_current_question.markedAnswer
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event,
                              html_current_question.questionNumber
                            )
                          }
                        >
                          {options.map((option) => {
                            return (
                              <FormControlLabel
                                className={classes.labelMargin}
                                checked={
                                  html_current_question.markedAnswer === option
                                }
                                key={
                                  html_current_question.questionNumber +
                                  "_" +
                                  option
                                }
                                value={option}
                                control={
                                  <Radio
                                    classes={{
                                      root: classes.radio,
                                      checked: classes.checked,
                                    }}
                                    icon={
                                      <CheckedOptions option={option}>
                                        {" "}
                                      </CheckedOptions>
                                    }
                                    checkedIcon={
                                      <CheckedOptions
                                        style={{ fontSize: "10px" }}
                                        option={option}
                                      >
                                        {" "}
                                      </CheckedOptions>
                                    }
                                  />
                                }
                                label={
                                  <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    className={
                                      html_current_question.status !==
                                      "unattempted"
                                        ? classes.disabledColor
                                        : ""
                                    }
                                    alignItems="center"
                                  >
                                    &nbsp; &nbsp;&nbsp; &nbsp;{" "}
                                    {html_current_question[option]}{" "}
                                  </Grid>
                                }
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </GridContainer>

                    {/* <Divider variant="middle"></Divider> */}
                    {html_current_question.status === "unattempted" ? (
                      <GridContainer justify="center">
                        <GridItem xs={5} sm={2} alignItems="center">
                          {" "}
                          <Button
                            classes={{ root: classes.sumbit }}
                            color="primary"
                            onClick={() =>
                              this.submit(html_current_question.questionNumber)
                            }
                          >
                            Submit
                          </Button>
                          <br />{" "}
                        </GridItem>
                      </GridContainer>
                    ) : (
                      <GridContainer direction="row" justify="flex-start">
                        <React.Fragment>
                          <GridItem xs={2}>
                            <Typography
                              component="span"
                              variant="span"
                              className={classes.sheading}
                            >
                              {" "}
                              Solution:
                            </Typography>
                          </GridItem>
                          <GridItem xs={9} className={classes.solheading}>
                            <Typography variant="span" component="span">
                              {html_current_question.solution}
                            </Typography>
                          </GridItem>
                        </React.Fragment>
                      </GridContainer>
                    )}
                    {/* <Typography component="div" style={{ top: '24em', left: this.expandfromleft.left, position: 'absolute', zIndex: '1', display: this.props.width !== "lg" ? 'none' : 'unset' }}>
                                                <Fab onClick={() => { this.expandQuestion(); this.forceUpdate() }}>
                                                    {this.expandfromleft.icon}
                                                </Fab>
                                        </Typography> */}
                  </Typography>
                </GridItem>
              </GridContainer>
            </React.Fragment>
          );
          break;
      }
      values.push(val);
    });

    console.log(this.props.location.state.worksheet.workSheetDisplayName);
    return (
      <React.Fragment>
        {/* <Header /> */}
        <br />
        <GridContainer
          justify="space-around"
          alignItems="center"
          style={{ background: "#eaf6fc", padding: "30px" }}
        >
          <GridItem xs={10} style={{ padding: "30px" }}>
            <Typography variant="h5" component="h3">
              Work sheet:{" "}
              {this.props.location.state.worksheet.workSheetDisplayName}
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer
          direction="column"
          alignItems="stretch"
          spacing={16}
          justify="center"
          id="student-math-ws"
          className={classes.wsBack}
        >
          {values.map((value) => {
            return (
              <GridItem>
                <Card>{value}</Card>
              </GridItem>
            );
          })}
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(WorkSheet);
