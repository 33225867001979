import React, { Fragment, useState, Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import { Link, withRouter } from "react-router-dom";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Header from "../Header";
import Alert from "../notifications/Alert";
import Footer from "../../views/SectionsPage/Footer";
import videoService, {
  getMeetings,
  getMeetingDetails,
  getCourseSubjects,
} from "apis/videoService";

import { CustomSelect, CustomLabel, CustomOutline } from "./CustomInput";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import store from "store";
import $ from "jquery";
import compose from "recompose/compose";
import { useHistory } from "react-router-dom";
import BottomNav from "../BottomNav";
import TalkChat from "./TalkChat";
const styles = (theme) => ({
  videoBg: {
    minHeight: "90vh",
    height: "max-content",
    overflow: "scroll",
    minWidth: "100vh",
    background: "#F0F3F5",
  },
  videoPlayer: {
    marginTop: "20px",
    height: "100%",
    // width: '100%',
    minHeight: "400px",
    overflow: "scroll",
  },
  watchNow: {
    marginTop: "20px",
    textTransform: "none",
    height: "56px",
    width: "200px",
    background: "#08C5A7",
    boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
    borderRadius: "16px",
    // fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",

    /* or 18px */
    textAlign: "center",
    color: "#FFFFFF",
    "&:hover": {
      background: "#0eab8f",
    },
  },
  coverChat: {
    marginTop: "-176px",
    background: "white",
    minHeight: "140px",
    position: "absolute",
    width: "400px",
  },
});

const OnTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#fff",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#08C5A7",
    marginRight: "2%",
    background: "#EFF2F4",
    border: "1px solid #08C5A7",
    boxSizing: "border-box",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    borderRadius: "16px",
    "&:focus": {
      opacity: 1,

      boxShadow: "inset 3px 3px 7px #D1D9E6, inset -3px -3px 7px #FFFFFF",
      borderRadius: "15px",
    },
  },
  selected: {
    boxShadow: "inset 3px 3px 7px #D1D9E6, inset -3px -3px 7px #FFFFFF",
    borderRadius: "15px",
  },
}))((props) => <Tab disableRipple {...props} />);

const SubjectTab = withStyles((theme) => ({
  root: {
    lineHeight: "18px",
    fontSize: "14px",
  },
}))((props) => <OnTab disableRipple {...props} />);
const OnTabs = withStyles({
  indicator: {
    backgroundColor: "transparent",
    "& > div": {
      maxWidth: "0px",
      width: "100%",
      boxShadow: "inset 3px 3px 7px #D1D9E6, inset -3px -3px 7px #FFFFFF",
      borderRadius: "15px",
      backgroundColor: "#635ee7",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

class DoubtSession extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      selectedValue: 0,
      selectedSubject: -1,
      meetingResp: null,
      netReq: false,
      videos: [],
      subjectNames:{
        0: "MATHS",
        1: "PHYSICS",
        2: "CHEMISTRY",
        3: "GENERAL"
    }
    };
  }
  handleChange = async (event, newValue) => {
    console.log(event, newValue);
    this.setState({ selectedValue: newValue });
    if (newValue == 1) {
      let instituteId = store.get("user").institute;
      if (store.get("user").level) {
        instituteId = [
          store.get("user").institute,
          store.get("user").level,
        ].join("_");
      }

      if (document.getElementById("zmmtg-root"))
        document.getElementById("zmmtg-root").style.display = "none";
      const val = await getMeetings({
        instituteId: instituteId || "mockInstituteId",
      });
      this.setState({ videos: val.data.payload });
    }
  };

  changeSubject = (event, subject) => {
    this.setState({ selectedSubject: subject});
  };

  handleTypeSelect = (event) => {
    // console.log();
    this.setState({ selectedSubject: event.target.value, showChat: false  });
    // this.getVideos(event.target.value);
  };

  componentWillUnmount() {
    if (this.state.ZoomMtg) {
      this.state.ZoomMtg.leaveMeeting({ leaveUrl: window.location.href });
    }
  }
  checkChat = () => {
    console.log("hello");
    console.log(
      $("#chat")
        .contents()
        .find(".sc-htpNat.dALCZV button")
        .fadeOut()
    );
  };
  joinMeeting = async () => {
    this.setState({ netReq: true });

    let subject = this.state.subjectsMap[this.state.selectedSubject];

    let instituteId = store.get("user").institute;
    if (store.get("user").level) {
      instituteId = [store.get("user").institute, store.get("user").level].join(
        "_"
      );
    }
    let liveDetails = await videoService.getMeetingDetails({
      instituteId: instituteId || "mockInstituteId",
      subject: subject,
    });

    this.setState({ netReq: false });
    if (liveDetails.data && liveDetails.data.status == "success") {
      liveDetails = liveDetails.data.payload;
      // liveDetails = liveDetails.
      if (liveDetails.url) {
        const val = liveDetails.url.split("/");
        // this.setState({live: val[0], chat: liveDetails.url });
        const { history } = this.props;
        history.push("/live-class", { live: val[0], chat: liveDetails.url });
      }
    }
  };
  mouClick = (event) => {
    console.log("hello", event);
    document.getElementById("chat").addEventListener("click", (event) => {
      console.log("hello", event);
    });
  };
  async componentDidMount() {
    let subMap = {};
    const course = store.get("user").course;
    const classVal = store.get("user").classVal;
    const courseClass = classVal + "_" + course;
    const levelId = store.get("user").level;
    let instituteId = store.get("user").institute;
    if (store.get("user").level) {
      instituteId = [store.get("user").institute, store.get("user").level].join(
        "_"
      );
    }

    if (store.get("user") && course && classVal) {
      let subjects = await getCourseSubjects(courseClass);
      let todayClasses = await videoService.getMeetingDetails({
        instituteId: instituteId || "mockInstituteId",
      });
      //
      console.log(levelId);
      if (
        todayClasses.data &&
        todayClasses.data.payload &&
        [191811, 1918111, 11184, 291811].indexOf(parseInt(levelId)) < 0
      ) {
        const url = todayClasses.data.payload.url;
        const val = url.split("/");
        const { history } = this.props;
        // history.push("/live-class", { live: val[0], chat: url });
      }
      if (subjects) {
        subjects = subjects[courseClass].subjects;

        if (courseClass) this.setState({ subjects: subjects });

        subjects.map((sub, index) => {
          subMap[index] = sub.value;
        });
        console.log(subMap, courseClass, "hello");
        this.setState({ subjectsMap: subMap });
        // console.log(this.state);
      }
    } else {
      subMap = {
        0: "M",
        1: "P",
        2: "C",
        3: "G",
      };

      this.setState({
        subjects: [
          { name: "MATHEMATICS", value: "M" },
          { name: "PHYSICS", value: "P" },
          { name: "CHEMISTRY", value: "C" },
          { name: "GENERAL", value: "G" },
        ],
        subjectsMap: subMap
      });
    }
    this.setState({ showJoin: true });
    console.log(this.state.subjects);
  }

  render() {
    const { classes, history } = this.props;
    if(store.get("user")&& store.get("user").institute=="5d72f343059f3004e1ceaa81")
       history.push('/interactive-classes');  
    return (
      <Fragment>
        {/* <Header /> */}
        <div className={classes.videoBg}>
          <Grid container direction="row" justify="center">
            <Grid item xs={10} sm={10} md={8} style={{ height: "fit-content" }}>
            <br/><br/>
              <div>
                {this.state.subjects ? (
                  <FormControl
                    style={{ marginTop: "20px", minWidth: "320px" }}
                    variant="outlined"
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <CustomLabel htmlFor="simple-select1">Subject</CustomLabel>
                    <CustomSelect
                      placeholder="Subject"
                      style={{ textAlign: "left", background: "#EFF2F4" }}
                      MenuListProps={{
                        className: classes.selectMenu1,
                      }}
                      MenuProps={{
                        classes: {
                          list: classes.selectMenu1,
                        },
                      }}
                      variant="outlined"
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.selectedSubject}
                      name="subject"
                      onChange={this.handleTypeSelect}
                      input={
                        <CustomOutline
                          displayEmpty
                          placeholder="Subject "
                          label="Subject"
                          labelWidth={40}
                          name="subject"
                          id="simple-select1"
                        />
                      }
                      inputProps={{
                        placeholder: "Subject ",
                        name: "subject",
                        id: "simple-select1",
                      }}
                    >
                      <MenuItem
                        //disabled
                        value={-1}
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Select Subject
                      </MenuItem>
                      {this.state.subjects.map((subject, index) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={index}
                          >
                            {subject.name}
                          </MenuItem>
                        );
                      })}
                    </CustomSelect>
                  </FormControl>
                ) : (
                  ""
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={10} md={8}>
                <br/>
               
                {this.state.selectedSubject>-1 &&
            <TalkChat
                  label={"Ask doubt"}
                  currentUser={store.get("user")}
                  other={{ 
                      id: this.state.subjects[this.state.selectedSubject].name+"_"+"Subject",
                      name: this.state.subjects[this.state.selectedSubject].name+" "+"Subject" }}
                />
  }
   <br/> <br/> <br/>
              {/* <div id="shift-here">
                {this.state.selectedValue == 0 && (
                  <Fragment>
                    <h5>{this.state.topic}</h5>
                    {this.state.showJoin ? (
                      <Button
                        className={classes.watchNow}
                        onClick={() => this.joinMeeting()}
                      >
                        Join Now
                      </Button>
                    ) : (
                      "loading live class..."
                    )}
                    <br /> <br />
                   
                  </Fragment>
                )}
              </div> */}
            </Grid>
          </Grid>
        
        </div>
        <BottomNav></BottomNav>
        {/* <Footer></Footer> */}
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(DoubtSession);
