import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

const styles = (theme) => ({
  progress: {
    color: "#54bdd5",
    marginTop: theme.spacing.unit * 10,
  },
});

const NetworkProgress = ({ size = 40, ...props }) => {
  const { classes } = props;
  return (
    <Grid container direction="row" justify="center" alignItems="flex-end">
      <CircularProgress
        size={size}
        className={classes.progress}
        variant="indeterminate"
      />
    </Grid>
  );
};

export default withStyles(styles)(NetworkProgress);
