const styles = (theme) => ({
  root: {
    marginTop: '100px',
    backgroundColor: 'rgba(236, 240, 243, 0.8)',
    paddingBottom: '30px'
  },
  gradeInstruction: {
    maxWidth: '840px',
    margin: 'auto',
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '760px'
    },
    '@media (max-width: 760px)':{
      maxWidth: '570px',
    },
    '& > p': {
      fontSize: '24px',
      color: '#31456A',
      // textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingBottom: '10px'
      }
    }
  },
  gradesContainer: {
    margin: ' 20px auto',
    maxWidth: '960px',
  },
  gradeButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '200px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '180px'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(50vw - 40px)'
    }
  },
  typeButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  gradeButton: {
    maxWidth: '200px',
    width: '100%',
    transition: 'all 0.3s ease-in-out'
  },
  signInContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contactInfoContainer: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  contactInfo: {
    width: '300px',
    margin: '20px',
    padding: '20px',
    borderTop: '1.2px solid white',
    boxShadow: '-8px -8px 20px #ffffff, 8px 8px 20px #d1d9e6',
    borderLeft: '1.2px solid white',
    borderRadius: '24px',
  },
  errorMessage: {
    color: 'red',
    margin: '20px auto',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  descHead: {
    marginTop: '60px',
    paddingBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > h3': {
      fontSize: '30px',
      color: '#3BBDED',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px'
      }
    }
  },
  descriptionContainer: {
    maxWidth: '1200px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      padding: ' 0px 30px',
      flexDirection: 'column'
    }
  },
  imageContianer: {
    width: '50%',
    padding: '0px 20px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  descImage: {
    width: '500px',
    [theme.breakpoints.down('md')]: {
      width: '370px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '150px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  descItemsContainer: {
    width: '50%',
    padding: '0px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  descItem: {
    margin: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemImage: {
    width: '64px',
    height: '64px',
    boxShadow: '0px 0px 15px #cdcdcd',
    '& > img': {
      width: '45px',
      height: '45px'
    }
  },
  itemContent: {
    margin: '10px',
    '& > h6': {
      fontSize: '16px',
      color: '#3BBDED',
    },
    '& > p': {
      fontSize: '14px',
    }
  },
  faqList: {
    padding: '20px',
    marginBottom: '30px',
    maxWidth: '940px',
    margin: 'auto',
    marginBottom: '0px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridRowGap: '10px',
    gridColumnGap: '30px',
    [theme.breakpoints.down('sm')]: {
      gridColumnGap: '20px',
      gridGap: '0px',
      width: '90%'
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      padding: '10px'
    }
  },
  faqText: {
    color: '#31456A',
    margin: 'auto',
    maxWidth: '940px',
    textAlign: 'center',
    marginTop: '40px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    }
  }
})

export default styles;