import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Talk from "talkjs";

const styles = (theme) => ({
  chatBox: {
    height: "600px",
  },
  "@media screen and (max-width: 1000px) and (min-width: 598px)": {
    chatBox: {
      height: "400px !important",
    },
  },
  //   "@media screen and (max-width: 600px) ":{
  //     chatBox: {
  //       height: "100% !important",
  //     },
  // },
  query: {
    marginTop: "20px",
    textTransform: "none",
    // height: "56px",
    // width: "200px",
    background: "#08C5A7",
    boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
    borderRadius: "16px",
    // fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",

    /* or 18px */
    textAlign: "center",
    color: "#FFFFFF",
    "&:hover": {
      background: "#0eab8f",
    },
  },
});
const TalkChat = (props) => {
  const [isMounted, setIsMounted] = useState(false);
  const talkjsContainer = useRef();
  const mountTalkJs = () => {
    const currentUser = props.currentUser;
    Talk.ready.then(() => {
      let me = new Talk.User({
        id: currentUser.userId,
        name: currentUser.name + "\n (ID - " + currentUser.userId + ")",
      });

      window.talkSession = new Talk.Session({
        //appId: 't6wG37Dg', //// --- test mode
        appId: "nz7sAnMs", //// --- production mode
        me: me,
      });

      let other = new Talk.User({
        id: props.other.id,
        name: props.other.name || props.other.id,
        welcomeMessage: "Welcome",
      });

      let conversation = window.talkSession.getOrCreateConversation(
        //this.props.other.id.toString()
        Talk.oneOnOneId(me, other)
      );

      conversation.setParticipant(me);
      conversation.setParticipant(other);

      let inbox = window.talkSession.createChatbox(conversation);

      inbox.mount(talkjsContainer.current);
    });
  };
  useEffect(() => {
    setIsMounted(false);
    return () => {
      if (window.talkSession) {
        window.talkSession.destroy();
      }
    };
  }, [props.other.id]);

  const { classes } = props;
  return (
    <Fragment>
      {isMounted ? (
        <div className={classes.chatBox} ref={talkjsContainer}>
          {props.label ? (
            <Fragment>
              <br /> <br /> <br />
            </Fragment>
          ) : (
            ""
          )}
        </div>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.query}
          disableElevation
          onClick={(e) => {
            setIsMounted(true);
            mountTalkJs();
          }}
        >
          {props.label ? props.label : "Open Chat"}
        </Button>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(TalkChat);
