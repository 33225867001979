const paper_1_2k15 = {
   "questions":{ "1": "1",
    "2": "1",
    "3": "1",
    "4": "1",
    "5": "1",
    "6": "1",
    "7": "1",
    "8": "1",
    "9": "2",
    "10": "2",
    "11": "2",
    "12": "2",
    "13": "2",
    "14": "2",
    "15": "2",
    "16": "2",
    "17": "2",
    "18": "2",
    "19": "3",
    "20": "3",
    "21": "1",
    "22": "1",
    "23": "1",
    "24": "1",
    "25": "1",
    "26": "1",
    "27": "1",
    "28": "1",
    "29": "2",
    "30": "2",
    "31": "2",
    "32": "2",
    "33": "2",
    "34": "2",
    "35": "2",
    "36": "2",
    "37": "2",
    "38": "2",
    "39": "3",
    "40": "3",
    "41": "1",
    "42": "1",
    "43": "1",
    "44": "1",
    "45": "1",
    "46": "1",
    "47": "1",
    "48": "1",
    "49": "2",
    "50": "2",
    "51": "2",
    "52": "2",
    "53": "2",
    "54": "2",
    "55": "2",
    "56": "2",
    "57": "2",
    "58": "2",
    "59": "3",
    "60": "3"},
    "sections": {
        1: [1,21,41],
        2: [9,29,49],
        3: [19,39,59]
    }
} 

export  default paper_1_2k15;