import { Button, Grid, Hidden, makeStyles } from "@material-ui/core";
import React from "react";
const useStyle = makeStyles((theme) => ({
  buttonContainer: {
    minHeight: "100px",
    background: theme.palette.common.white,
    bottom: 0,
    width: "inherit",
    borderTop: `2px solid ${theme.palette.background.default}`,
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      margin: "auto",
    },
  },
  grid: {
    display: "flex",
  },
}));
const ActionButtonsComponent = ({
  handleSubmitAnswer,
  handleViewSolution,
  handleClearResponse,
  handleNextQuestion,
  isSubmitted,
  question,
  ...props
}) => {
  const classes = useStyle();
  const { answer, q_type } = question;
  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      alignItems="center"
      className={classes.buttonContainer}
      spacing={3}
    >
      <Grid item xs={6}>
        {q_type === "single" && !answer && !isSubmitted ? (
          <Button color="inherit" onClick={handleClearResponse}>
            Clear&nbsp;<Hidden smDown>Response</Hidden>
          </Button>
        ) : null}
      </Grid>
      <Grid item xs={6} container justify="flex-end">
        <Grid item className={classes.grid}>
          {/* {!answer && !isSubmitted && (
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={handleViewSolution}
            >
              View Solution
            </Button>
          )} */}
          &nbsp;&nbsp;
          {isSubmitted || answer ? (
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleNextQuestion}
            >
              Next
            </Button>
          ) : (
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleSubmitAnswer}
              disabled={props.value ? false : true}
            >
              Submit
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActionButtonsComponent;
