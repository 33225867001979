import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const CustomInstructions = ({ question_number, schema = {} }) => {
  const [instruction, setInstruction] = useState(null);
  useEffect(() => {
    if (schema[question_number] && schema[question_number].section) {
      setInstruction(
        schema.instructions
          ? schema.instructions[schema[question_number].section]
          : null
      );
    }
  }, [question_number]);

  return instruction ? (
    <div dangerouslySetInnerHTML={{ __html: instruction }} />
  ) : (
    <Typography variant="caption">Instruction not available.</Typography>
  );
};

export default CustomInstructions;
