import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function BackIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M17.3438 10C17.3438 10.1243 17.2944 10.2436 17.2065 10.3315C17.1186 10.4194 16.9994 10.4688 16.8751 10.4688H4.25711L9.08133 15.2938C9.12738 15.3367 9.16432 15.3884 9.18994 15.4459C9.21556 15.5034 9.22934 15.5655 9.23045 15.6285C9.23156 15.6914 9.21998 15.7539 9.1964 15.8123C9.17283 15.8706 9.13774 15.9237 9.09322 15.9682C9.04871 16.0127 8.99569 16.0478 8.93732 16.0714C8.87896 16.0949 8.81644 16.1065 8.7535 16.1054C8.69056 16.1043 8.62849 16.0905 8.57099 16.0649C8.51349 16.0393 8.46174 16.0023 8.41883 15.9563L2.79383 10.3313C2.70604 10.2434 2.65674 10.1242 2.65674 10C2.65674 9.87581 2.70604 9.75667 2.79383 9.66878L8.41883 4.04378C8.50769 3.96098 8.62521 3.9159 8.74665 3.91804C8.86809 3.92018 8.98396 3.96938 9.06984 4.05526C9.15572 4.14115 9.20492 4.25701 9.20706 4.37845C9.2092 4.49989 9.16413 4.61742 9.08133 4.70628L4.25711 9.53128H16.8751C16.9994 9.53128 17.1186 9.58066 17.2065 9.66857C17.2944 9.75648 17.3438 9.87571 17.3438 10Z"
        fill="#2A2A2A"
      />
    </SvgIcon>
  );
}
