export const PAYMENT_STATUS = {
  Ok: "Success",
  F: "Failure",
  To: "Timed Out",
  Pending: "Pending",
  pending: "Pending",
  revert: "Reverted",
  Cancelled: "Cancelled",
  success: "success",
};
  
  export const FORM_MODES = {
    CREATE: "CREATE",
    EDIT: "EDIT",
    UPDATE: "UPDATE",
  };
  
  export const PAYMENT_MODE = {
     CA: "Cash",
  CC: "Credit card",
  DC: "Debit Card",
  CQ: "Cheque",
  NB: "Net Banking",
  UPI: "UPI",
  BT_ADJUST: "Branch transfer adjustment",
  WIB_ADJUST: "Within branch transfer adjustment",
  CD_ADJUST: "Caution deposit adjustment",
  ONLINE: "Online",
  Online: "Online",
  SWIPE: "SWIPE",
  };
  