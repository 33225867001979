// CategoryList.js
import React, { useState } from "react";
import store from "store";
import { Grid, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStyles from "./styles"; // Adjust the path as necessary
import CatCard from "./Components/catCard"; // Adjust the path as necessary
import pinkAanalysis from "../../assets/img/navigationBarAssets/pinkAanalysis.svg";
import greenExamAanalysis from "../../assets/img/navigationBarAssets/greenExamAnalysis.svg";
import feeDetails from "../../assets/img/navigationBarAssets/feeDetails.svg";
import myClassroom from "../../assets/img/navigationBarAssets/myClassroom.svg";
import attendance from "../../assets/img/navigationBarAssets/attendance.svg";
import profileYellow from "../../assets/img/navigationBarAssets/profileYellow.svg";
import progressCard from "../../assets/img/navigationBarAssets/progressCard.svg";
import TopicWiseAnalysis from "../../assets/img/navigationBarAssets/topicWiseAnalysis.svg";
import liveClasses from "../../assets/img/navigationBarAssets/liveClasses.svg";
import library from "../../assets/img/navigationBarAssets/library.svg";
import restrictedLevel from "../../views/SignIn/restrictedLevel";
import ExamAnalysis from "../ExamAnalysis";

const ParentPortal = [
  { title: "View Exams", icon: pinkAanalysis, value: "exams" },
  { title: "Exam Analysis", icon: greenExamAanalysis, value: "analysis" },
  { title: "My Classroom", icon: myClassroom, value: "classRoom" },
  { title: "View Attendance", icon: attendance, value: "attendance" },
  { title: "Fee Details", icon: feeDetails, value: "feeDetails" },
  { title: "Pocket Money", icon: feeDetails, value: "pocketMoney" },
  { title: "View My Profile", icon: profileYellow, value: "profile" },
  { title: "Progress Card", icon: progressCard, value: "progressCard" }
];
const studentPortal = restrictedLevel.includes(
  `${store.get("user") ? store.get("user").level : ""}`
)
  ? [
      { title: "View Exams", icon: pinkAanalysis, value: "exams" },
      { title: "View My Profile", icon: profileYellow, value: "profile" }
    ]
  : [
      { title: "View Exams", icon: pinkAanalysis, value: "exams" },
      { title: "Exam Analysis", icon: greenExamAanalysis, value: "analysis" },
      { title: "My Classroom", icon: myClassroom, value: "classRoom" },
      { title: "View Attendance", icon: attendance, value: "attendance" },
      // { title: "Fee Details", icon: feeDetails, value: "feeDetails" },

      { title: "Progress Card", icon: progressCard, value: "progressCard" },
      { title: "Live Classes", icon: liveClasses, value: "liveClasses" },
      { title: "View Library", icon: library, value: "viewLibrary" },
      {
        title: "Topic Wise Analysis",
        icon: TopicWiseAnalysis,
        value: "topicWiseAnalysis"
      },
      {
        title: "Previous Classes",
        icon: liveClasses,
        value: "PreviousClasses"
      },
      {
        title: "Practice",
        icon: liveClasses,
        value: "practice"
      },
      { title: "View My Profile", icon: profileYellow, value: "profile" }
    ];

const CategoryList = ({ handleCatClick, data }) => {
  const classes = useStyles();
  const user = store.get("user") && store.get("user").accountType;
  const isNotMobile = useMediaQuery("(min-width:800px)");
  const show = !isNotMobile;
  const categories =
    user === "parent"
      ? ParentPortal.filter(
          category =>
            data &&
            data.parentAppSettings &&
            data.parentAppSettings[category.value]
        )
      : studentPortal.filter(
          category =>
            data &&
            data.studentAppSettings &&
            data.studentAppSettings[category.value]
        );

  return (
    <>
      {categories && show ? (
        <>
          <Typography
            className={classes.catText}
            style={{ marginTop: "32px", marginBottom: "20px" }}
          >
            Categories
          </Typography>
          <div className={classes.catContainer}>
            <Grid container spacing={2}>
              {categories &&
                categories.map((category, index) =>
                  store.get("user").institute === "6422b982a32e9b7c5498b129" &&
                  (category.title === "Fee Details" ||
                    category.title === "Pocket Money") ? null : (
                    <Grid
                      item
                      xs={6}
                      md={6}
                      sm={4}
                      lg={4}
                      key={category.value}
                      role="button"
                      tabIndex={index}
                      onClick={() => handleCatClick(category.value)}
                      onKeyDown={event => {
                        if (event.key === "Enter" || event.key === " ") {
                          handleCatClick(category.value)();
                        }
                      }}
                      className={classes.cursor}
                    >
                      <CatCard title={category.title} icon={category.icon} />
                    </Grid>
                  )
                )}
            </Grid>
          </div>
        </>
      ) : (
        <ExamAnalysis />
      )}
    </>
  );
};

export default CategoryList;
