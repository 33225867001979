import { Grid, ThemeProvider, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import theme from "../../assets/theme";
import ResultHeader from "./ResultHeader";
const useStyles = makeStyles(() => ({
  noKey: {
    margin: "10%",
  },
}));
const NoResultMsg = ({
  text = "Your exam got submitted successfully. Results will be announced soon",
  header = false,
  title,
}) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      {header && <ResultHeader testDisplayName={title} />}
      <Grid container direction="row" justify="center" alignItems="center">
        <Typography
          align="center"
          component="h3"
          variant="h3"
          className={classes.noKey}
        >
          {text}
        </Typography>
      </Grid>
    </ThemeProvider>
  );
};

export default NoResultMsg;
