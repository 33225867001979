import {
  Box,
  CardActionArea,
  createMuiTheme,
  Grid,
  MuiThemeProvider,
  Tooltip
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import theme from '../../assets/theme'
const themeBtn = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        margin: '0 !important',
        padding: '0 !important',
        background: 'none !important',
        color: '#3358B8 !important',
        height: 'fit-content !important',
        boxShadow: 'none !important',
        textTransform: 'capitalize !important',
        fontSize: '14px !important',
        [theme.breakpoints.up('sm')]: {
          fontSize: '16px' // Tablet
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '16px' // Desktop
        }
      }
    }
  }
})

const styles = () => ({
  title: {
    marginTop: '10px',
    width: '80%',
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px' // Desktop
    }
  },
  anchor: {
    color: theme.link.main,
    '&:hover': {
      color: theme.link.hover
    }
  },
  pos: {
    marginBottom: 12,
    fontSize: '12px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px' // Desktop
    }
  },
  actionArea: {
    '&:hover $focusHighlight': {
      opacity: 0.5
    }
  },
  focusHighlight: {}
})
const ExamCard = props => {
  const { classes } = props
  return (
    <Card
      style={{
        boxShadow: `4px 4px 8px rgba(189, 194, 199, 0.75),
                    8px 8px 12px rgba(189, 194, 199, 0.25),
                   -4px -4px 8px rgba(255, 255, 255, 0.75),
                   -8px -8px 12px rgba(255, 255, 255, 0.25)`,
        background: '#EBEFF3'
      }}
    >
      <CardActionArea
        classes={{
          root: classes.actionArea,
          focusHighlight: classes.focusHighlight
        }}
        onClick={props.onClick}
      >
        <CardContent>
          <Box>
            <CardActions>
              <Grid
                container
                justifyContent='flex-start'
                display='flex'
                flexDirection='row'
                alignItems='center'
                direction='row'
                spacing={1}
              >
                <Tooltip title={props.title}>
                  <Typography
                    className={classes.title}
                    style={{ whiteSpace: 'break-spaces' }}
                    color='textPrimary'
                    variant='subtitle1'
                    gutterBottom
                  >
                    {props.testDisplayName}
                  </Typography>
                </Tooltip>
                <MuiThemeProvider theme={themeBtn}>
                  {props.button}
                </MuiThemeProvider>
              </Grid>
            </CardActions>
          </Box>
          <Box
            mt={1}
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
          >
            {props.timeStarted && (
              <Typography
                className={classes.pos}
                color='textSecondary'
                variant='body2'
              >
                {props.timeStarted}
              </Typography>
            )}
            {props.examType && (
              <Typography>
                {props.examType}

                <a
                  className={classes.anchor}
                  href='#pablo'
                  onClick={e => e.preventDefault()}
                >
                  {props.examPaper}
                </a>
              </Typography>
            )}
            {props.endDateTime && (
              <Typography color='textSecondary' variant='body2'>
                {props.endDateTime}
              </Typography>
            )}
            {props.shouldStart}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default withStyles(styles)(ExamCard)
