import axios from "./examServerAxios";

export async function getRandomQuestion(body) {
  try {
    let data = await axios.post("/practice/allquestions", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function saveAnswer(body) {
  try {
    let data = await axios.post("/practice/saveAnswer", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function skipQuestionApi(body) {
  try {
    let data = await axios.post("/practice/skipquestion", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function bookmarkQuestionApi(body) {
  try {
    let data = await axios.post("/practice/bookmarkquestion", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getAnalyticsSingleQuestionApi(body) {
  try {
    let data = await axios.post("/practice/QuestionAnanlysis", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getSolvedQuestionsAggregate(body) {
  try {
    let data = await axios.get("/practice/questionsAggregate", {
      params: body,
    });
    return data.data;
  } catch (error) {
    return error;
  }
}

export async function getTopics(body) {
  try {
    let data = await axios.get("/exams/topics", {
      params: body,
    });
    return data.data;
  } catch (error) {
    return error;
  }
}
