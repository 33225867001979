import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import Container from '@material-ui/core/Container';
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import store from "store";
const styles = (theme) => ({
  heading: {
    textAlign: "center",
    fontWeight: 900,
    lineHeight: 1.1,
  },
  subHeading: {
    fontWeight: 700,
    lineHeight: 1.1,
  },
  proceed: {
    color: "#fff",
    backgroundColor: "#337ab7",
    borderColor: "#2e6da4",
    padding: ".5% 7%",
    width: "max-content",
  },
  newgridsep: {
    minHeight: theme.spacing.unit * 1,
  },
  examHeading: {
    color: "white",
    backgroundColor: "#f7931e",
    padding: "5px 0",
    fontWeight: 900,
    // minHeight: theme.spacing(9)
  },
  tablelines: {
    color: "#555",
    border: "1px solid #ddd",
    borderCollapse: "collapse",
    padding: "10px",
  },
  box: {
    // minHeight: theme.spacing(3),
    marginTop: theme.spacing.unit * 1,
    padding: theme.spacing.unit * 1,
  },
  renderWhite: {
    minHeight: "50%",
    padding: "10%",
  },
});
class ExamSummary extends React.Component {
  checkedFlag = false;
  type = "SUMMARY";
  constructor(props) {
    super(props);
    this.props = props;
    this.submitExam = this.submitExam.bind(this);
    this.type = "SUMMARY";
    this.state = {
      type: "SUMMARY",
      user: store.get("user") ? store.get("user") : {},
    };
  }

  submitExam(val) {
    if (val) {
      this.setState({ type: "PENDING_ITEMS" });
      this.type = "PENDING_ITEMS";
      this.forceUpdate();
      this.props.submit("YES");
    } else {
      this.props.submit("NO");
    }
  }
  viewResult() {
    // console.log('view');
    this.type = "VIEW_RESULT";
    this.setState({ type: this.type });
    this.props.submit("VIEW_RESULT");
  }
  componentDidUpdate() {
    switch (this.props.type) {
      case "RESULT":
      case "VIEW_RESULT":
        this.type = this.props.type;
        break;
    }
  }
  // proceed() {
  //     const { history } = this.props;
  //     if (!this.checkedFlag) {
  //         alert('Please accept terms and conditions before proceeding.');
  //     } else {
  //         history.push('/exam-portal', { ...this.props.location.state });
  //     }
  // }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {/* <Grid item xs={12} className={classes.newgridsep}></Grid> */}
        <GridContainer style={{ background: "white" }}>
          <GridItem xs={12} className={classes.examHeading}>
            <Typography
              component="h4"
              variant="h5"
              style={{ padding: "1% 10%", fontWeight: "bold" }}
            >
              {this.props.testDisplayName}
            </Typography>
          </GridItem>
          {(() => {
            switch (this.type) {
              case "SUMMARY":
                return (
                  <React.Fragment>
                    <GridItem xs={12}>
                      <h3 variant="h5" className={classes.heading}>
                        Exam Summary
                      </h3>
                    </GridItem>
                    <GridItem
                      xs={12}
                      className={classes.heading}
                      style={{
                        textAlign: "-webkit-center",
                        overflowX: "auto",
                        padding: "0px 10px",
                      }}
                    >
                      {/* <GridContainer className={classes.box} style={{ textAlign: '-webkit-center' }}> */}
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className={classes.tablelines}
                              style={{ borderBottomWidth: "2px" }}
                            >
                              {" "}
                              Section Name
                            </TableCell>
                            <TableCell
                              className={classes.tablelines}
                              style={{ borderBottomWidth: "2px" }}
                            >
                              {" "}
                              No of Questions{" "}
                            </TableCell>
                            <TableCell
                              className={classes.tablelines}
                              style={{ borderBottomWidth: "2px" }}
                            >
                              {" "}
                              Answered
                            </TableCell>
                            <TableCell
                              className={classes.tablelines}
                              style={{ borderBottomWidth: "2px" }}
                            >
                              {" "}
                              Not Answered
                            </TableCell>
                            <TableCell
                              className={classes.tablelines}
                              style={{ borderBottomWidth: "2px" }}
                            >
                              {" "}
                              Marked for Review
                            </TableCell>
                            <TableCell
                              className={classes.tablelines}
                              style={{ borderBottomWidth: "2px" }}
                            >
                              {" "}
                              Answered & Marked for Review(will be considered
                              for evaluation)
                            </TableCell>
                            <TableCell
                              className={classes.tablelines}
                              style={{ borderBottomWidth: "2px" }}
                            >
                              {" "}
                              Not Visited
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <tr>
                            <td className={classes.tablelines}>
                              {this.props.test_type}
                            </td>
                            <td className={classes.tablelines}>
                              {this.props.statusdisplay.ANSWERED +
                                this.props.statusdisplay.NOT_ANSWERED +
                                this.props.statusdisplay.MARKED_FOR_REVIEW +
                                this.props.statusdisplay
                                  .ANSWERED_MARKED_FOR_REVIEW +
                                this.props.statusdisplay.NOT_VISITED}
                            </td>
                            <td className={classes.tablelines}>
                              {this.props.statusdisplay.ANSWERED}
                            </td>
                            <td className={classes.tablelines}>
                              {this.props.statusdisplay.NOT_ANSWERED}
                            </td>
                            <td className={classes.tablelines}>
                              {this.props.statusdisplay.MARKED_FOR_REVIEW}
                            </td>
                            <td className={classes.tablelines}>
                              {
                                this.props.statusdisplay
                                  .ANSWERED_MARKED_FOR_REVIEW
                              }
                            </td>
                            <td className={classes.tablelines}>
                              {this.props.statusdisplay.NOT_VISITED}
                            </td>
                          </tr>
                        </TableBody>
                      </Table>
                      {/* </GridContainer> */}
                    </GridItem>
                    <GridItem xs={12}>
                      {/* <GridContainer className={classes.box}> */}
                      <Typography variant="h6" className={classes.heading}>
                        Are you sure you want to submit for final marking?
                        <br />
                        No changes will be allowed after submission.
                      </Typography>
                      {/* </GridContainer> */}
                    </GridItem>
                    <Grid item xs={12}>
                      <Typography variant="h6" className={classes.heading}>
                        <button
                          onClick={() => {
                            this.type = "RECORD";
                            this.setState({ type: "RECORD" });
                            this.forceUpdate();
                          }}
                          className="btn-next"
                        >
                          YES
                        </button>
                        <button
                          onClick={() => this.submitExam(false)}
                          className="btn-next"
                        >
                          NO
                        </button>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <GridContainer className={classes.renderWhite} />
                    </Grid>
                  </React.Fragment>
                );
              case "RECORD":
                return (
                  <React.Fragment>
                    <GridItem xs={12}>
                      {/* <GridContainer className={classes.box}> */}
                      <h3 className={classes.heading}>
                        Thank You, your responses will be submitted for final
                        marking - click OK to complete final submission.
                      </h3>
                      {/* </GridContainer> */}
                    </GridItem>
                    <GridItem xs={12}>
                      <Typography variant="h6" className={classes.heading}>
                        <button
                          onClick={() => this.submitExam(true)}
                          className="btn-next"
                        >
                          Ok
                        </button>
                        <button
                          onClick={() => this.submitExam(false)}
                          className="btn-next"
                        >
                          Cancel
                        </button>
                      </Typography>
                    </GridItem>
                    <Grid item xs={12}>
                      <GridContainer className={classes.renderWhite} />
                    </Grid>
                  </React.Fragment>
                );
              case "PENDING_ITEMS":
                return (
                  <React.Fragment>
                    <GridItem xs={12}>
                      {/* <GridContainer className={classes.box}> */}
                      <h3 className={classes.heading}>
                        submitting your answers.....
                        <CircularProgress />
                      </h3>
                      {/* </GridContainer> */}
                    </GridItem>
                    {/* <GridItem item xs={12}>
                            <h5 className={classes.heading}>
                                <button onClick={this.viewResult.bind(this)} className="btn-next">
                                    View result
                                  </button>
                            </h5>
                        </GridItem> */}
                    <Grid item xs={12}>
                      <GridContainer className={classes.renderWhite} />
                    </Grid>
                  </React.Fragment>
                );
              case "RESULT":
                return (
                  <React.Fragment>
                    <GridItem xs={12}>
                      {/* <GridContainer className={classes.box}> */}
                      <h3 className={classes.heading}>
                        Thank you, Submitted Successfully.
                      </h3>
                      {/* </GridContainer> */}
                    </GridItem>
                    {this.state.user && (
                      <Fragment>
                        {this.state.user.instituteId !==
                          "5ea3572fe301133d0a73e9e0" && (
                          <GridItem item xs={12}>
                            <h5 className={classes.heading}>
                              <button
                                onClick={this.viewResult.bind(this)}
                                className="btn-next"
                              >
                                View result
                              </button>
                            </h5>
                          </GridItem>
                        )}
                      </Fragment>
                    )}
                    <Grid item xs={12}>
                      <GridContainer className={classes.renderWhite} />
                    </Grid>
                  </React.Fragment>
                );
            }
          })()}
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(ExamSummary);
