import * as actionType from "../../actions/practicePageActions/actions";

const INITIAL_STATE = {
  isSubmitted: false,
  value: "",
  integerVal: "",
  checkboxValue: [],
  isError: false,
};
export const SaveBodyReducer = (body = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SAVE_BODY_TO_STORE:
      return (body = action.body);
    case actionType.CLEAR_BODY_FROM_STORE:
      return {
        ...body,
        isSubmitted: false,
        value: "",
        integerVal: "",
        checkboxValue: [],
        isError: false,
      };
    default:
      return body;
  }
};
