import { adminAxios } from "./baseAxios";

export async function getStudentFeeInfo(body) {
  try {
    return (await adminAxios.post("/parent-portal/all-ay-dues", { ...body }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getStudentDueInfo(body) {
  try {
    return (await adminAxios.post("/parent-portal/due-amounts", { ...body }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
