import { Card, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    boxShadow: `4px 4px 8px rgba(189, 194, 199, 0.75),
                    8px 8px 12px rgba(189, 194, 199, 0.25),
                   -4px -4px 8px rgba(255, 255, 255, 0.75),
                   -8px -8px 12px rgba(255, 255, 255, 0.25)`,
    borderRadius: theme.shape.borderRadius,
    padding: "8px 16px",
    background: props.backgroundColor || "#EBEFF3",
  }),
}));

const ContentWrap = ({ children, backgroundColor = "#EBEFF3" }) => {
  const classes = useStyles({ backgroundColor });

  return <Card className={classes.root}>{children}</Card>;
};

export default ContentWrap;
