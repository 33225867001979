import {
  Avatar,
  Divider,
  Grid,
  ListItem,
  // ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import { capitalize } from 'lodash'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'
import theme from '../../assets/theme'



const useStyles = makeStyles(() => ({
  grid: {
    padding: theme.spacing(1),
    cursor: 'pointer',
    color: '#FFFFFF',
    '&:hover': {
      background: '#e6e6ff',
      // color: theme.palette.white,
      borderRadius: '10px'
    }
  },
  textMsg: {
    fontWeight: 400,
    lineHeight: '20px',
    color: '#2A2A2A',
    fontSize: '14px'
  },
  notificationTime: { float: 'right' },
  message: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    lineHeight: '1.5em',
    maxHeight: '3em',
    // show only two lines of text when text is large
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2
  },
  messageBox: {
    overflow: 'auto'
  },
  primaryColor: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main
  },
  secondaryColor: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.dark
  },

  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    width: '100%',
    background: '#EBEFF3',
    boxShadow:
      '-8px -8px 12px rgba(255, 255, 255, 0.25), -4px -4px 8px rgba(255, 255, 255, 0.75), 8px 8px 12px rgba(189, 194, 199, 0.25), 4px 4px 8px rgba(189, 194, 199, 0.75)',
    borderRadius: '8px',
    flex: 'none',
    order: 1,
    alignSelf: 'stretch',
    flexGrow: 0,
    minWidth:"320px"
  },
  title: {
    lineHeight: '20px',
    color: '#2A2A2A',
    fontWeight: 700
  },
  dateMessage: {
    color: '#737373',
    fontWeight: 400,
    lineHeight: '20px',
    fontSize: '12px'
  }
}))

const NotificationContent = ({ content,handleClose }) => {
  const classes = useStyles()
  const history = useHistory()
  const { pathname } = window.location
  const { created_at, message, title } = content
  // formate display-time for notification creation time
  const notificationTime = moment().isSame(created_at, 'day')
    ? moment(created_at)
        .startOf('milliseconds')
        .fromNow()
    : moment(created_at).format('ll')
  // change text based on recent and past notifications
  const textColor = moment().isSame(created_at, 'day')
    ? 'textPrimary'
    : 'textSecondary'
  return (
    <React.Fragment>
      <div className={classes.card}>
        <ListItem
          className={classes.grid}
          onClick={() => {
            if (pathname !== '/notification') {
              history.push('/notification')
              handleClose();
            }
          }}
        >
          <ListItemText>
            {pathname === '/notification' ? (
              <React.Fragment>
                <Grid container>
                  <Grid
                    container
                    justifyContent='flex-start'
                    alignContent='center'
                    alignItems='center'
                    item
                    sm={10}
                    xs={12}
                  >
                    <Avatar
                      className={
                        moment().isSame(created_at, 'day')
                          ? classes.primaryColor
                          : classes.secondaryColor
                      }
                    >
                      <SupervisorAccountIcon />
                    </Avatar>
                    &nbsp; &nbsp;
                    {title && (
                      <Typography
                        variant='button'
                        color={textColor}
                        className={classes.title}
                      >
                        {capitalize(title)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item sm={10} xs={12}>
                    <Typography
                      variant='caption'
                      className={classes.textMsg}
                      color={textColor}
                    >
                      {message}
                    </Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : (
              <Grid container>
                <Grid
                  container
                  justifyContent='flex-start'
                  alignContent='center'
                  alignItems='center'
                  item
                  sm={10}
                  xs={12}
                >
                  <Avatar
                    className={
                      moment().isSame(created_at, 'day')
                        ? classes.primaryColor
                        : classes.secondaryColor
                    }
                  >
                    <SupervisorAccountIcon />
                  </Avatar>
                  &nbsp; &nbsp;
                  {title && (
                    <Typography
                      variant='button'
                      color={textColor}
                      className={classes.title}
                    >
                      {capitalize(title)}
                    </Typography>
                  )}
                </Grid>
                <Grid item sm={10} xs={12}>
                  <Typography
                    variant='caption'
                    className={classes.textMsg}
                    color={textColor}
                  >
                    {message}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid container item xs={12} justifyContent='flex-start'>
              <Typography
                className={classes.dateMessage}
                color={textColor}
                variant='caption'
              >
                {notificationTime}
              </Typography>
            </Grid>
          </ListItemText>
        </ListItem>
        <Divider variant='inset' />
      </div>
    </React.Fragment>
  )
}

export default NotificationContent
