import { withStyles } from "@material-ui/core";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import theme from "../../assets/theme";
const AccordionSummary = withStyles({
  root: {
    borderRadius: "10px 10px 0 0",
    backgroundColor: "white",
    border: "none",
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);
const AccordionDetails = withStyles(() => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: "white",
  },
}))(MuiAccordionDetails);

export { AccordionDetails, AccordionSummary };
