import { useState, useEffect } from "react";

import {
  getObjectEntries,
  objectExists,
  getObjectKeys,
} from "../../utils/general.utils/object.utils";
import useStudentData from "../Dashboard/Components/useStudentData";

const useFeeData = () => {
  const [paidMap, setPaidMap] = useState({});
  const [installments, setInstallments] = useState(null);

  const [studentAdmissionNo, omrNo, stuFeeData, avatar] = useStudentData();

  const { transactions, student, feeStructure, config } = stuFeeData;
  const [ins, setIns] = useState(null);
  const [modifiedObject, setModifiedObject] = useState(null);
  const [otherFees, setOtherFees] = useState(null);
  const [columnsValues, setColumnsValues] = useState([]);

  // console.log(stuFeeData);

  const getInstallmentData = (list) => {
    if (installments) {
      if (list && list.length > 0) {
        return list.reduce((a, c, i) => {
          let pp;
          if (a[`installment${i}`]) {
            pp = a[`installment${i}`].all || 0;
          } else {
            pp = 0;
          }
          const p = pp + c.percent || 0;
          a[`installment${i + 1}`] = { ...c, all: p, percent: c.percent };
          return a;
        }, {});
      }
      return {};
    }
  };
  useEffect(() => {
    if (stuFeeData.feeStructure) {
      setInstallments(stuFeeData.feeStructure.installments);
    }
  }, [stuFeeData.feeStructure]);
  console.log(stuFeeData);
  useEffect(() => {
    const ins = getInstallmentData(installments);
    setIns(ins);
    const columns = [
      {
        field: "feeType",
        headerName: "Fee Type",
        editable: false,
        minWidth: 120,
      },
      {
        field: "amount",
        headerName: "Fee Amount",
        editable: false,
        minWidth: 80,
      },
      {
        field: "concession",
        headerName: "Concession Amount",
        editable: false,
        minWidth: 80,
      },
      {
        field: "paid",
        headerName: "Paid Amount",
        editable: false,
        minWidth: 80,
      },
      {
        field: "balance",
        headerName: "Balance Amount",
        editable: false,
        minWidth: 80,
      },
    ];
    if (installments) {
      // Dynamically add installment columns based on the data
      installments.forEach((installment, index) => {
        const installmentKey = `installment${index + 1}`;
        columns.push({
          field: installmentKey,
          headerName: `Installment ${index + 1}`,
          editable: false,
          minWidth: 100,
        });
      });

      columns.push({
        field: "money",
        headerName: "Paying Amount",
        editable: false,
        flex: 1,
        minWidth: 200,
        maxWidth: 200,
      });

      const result1 = {
        columns: columns,
        miscRows: [],
        miscColumns: [
          {
            field: "money",
            headerName: "Paying Amount",
            editable: false,
            flex: 1,
            minWidth: 200,
            maxWidth: 200,
          },
        ],
      };
      setColumnsValues(result1);
      // console.log(JSON.stringify(result1, null, 2));
    }
  }, [installments]);

  const [rows, setRows] = useState([]);

  // console.log(columnsValues);
  useEffect(() => {
    if (config) {
      const getRows = () => {
        if (student && ins) {
          const addTutionFee =
            Number(paidMap[config[0].regConf.regFeeName] || 0) -
              Number(config[0].regConf.amt) >
            0
              ? Number(paidMap[config[0].regConf.regFeeName] || 0) -
                Number(config[0].regConf.amt)
              : 0;

          const items =
            feeStructure.plans && feeStructure.plans.length > 0
              ? feeStructure.plans.map((item) => {
                  console.log(ins, item);
                  const i = item.installments ? ins : {};
                  if (item) {
                    if (item.feeType && item.feeType === "tution_fee") {
                      return {
                        ...item,
                        amount:
                          Number(item.amount) -
                          (Number(
                            student.concession &&
                              student.concession[item.feeType]
                          ) || 0),
                        concession:
                          Number(
                            student.concession &&
                              student.concession[item.feeType]
                          ) || 0,
                        balance:
                          Number(item.amount) -
                          Number(paidMap[item.feeType] || 0) -
                          addTutionFee,
                        paid: Number(paidMap[item.feeType] || 0) + addTutionFee,
                        ...i,
                      };
                    }
                  }
                  return {
                    ...item,
                    amount:
                      Number(item.amount) -
                      (Number(
                        student.concession && student.concession[item.feeType]
                      ) || 0),
                    concession:
                      Number(
                        student.concession && student.concession[item.feeType]
                      ) || 0,
                    balance:
                      Number(item.amount) - Number(paidMap[item.feeType] || 0),
                    // Number(student.concession[item.feeType]) || 0,
                    paid: Number(paidMap[item.feeType] || 0),
                    ...i,
                  };
                })
              : [];

          if (!config[0].regConf.include) {
            return [
              {
                _id: "test_test_test",
                feeType: config[0].regConf.regFeeName,
                amount: Number(config[0].regConf.amt),
                balance:
                  Number(config[0].regConf.amt) -
                    Number(paidMap[config[0].regConf.regFeeName] || 0) >=
                  0
                    ? Number(config[0].regConf.amt) -
                      Number(paidMap[config[0].regConf.regFeeName] || 0)
                    : 0,
                paid:
                  Number(config[0].regConf.amt) -
                    Number(paidMap[config[0].regConf.regFeeName] || 0) >=
                  0
                    ? Number(paidMap[config[0].regConf.regFeeName] || 0)
                    : Number(config[0].regConf.amt),
              },
              ...items,
            ];
          }

          return items;
        }
      };

      const rowItems = getRows();
      console.log(rowItems);
      const lastRow = rowItems
        ? [
            rowItems.reduce(
              (acc, cur) => {
                Object.keys(cur).forEach((key) => {
                  if (key === "feeType") acc[key] = "Total";
                  else if (!key.includes("installment"))
                    acc[key] = (acc[key] || 0) + cur[key];
                });
                return acc;
              },
              {
                feeType: "Total",
                _id: 0,
              }
            ),
          ]
        : [];
      console.log(rowItems, lastRow);
      const r = [];
      r.push(rowItems || []);
      r.push(lastRow || []);

      setRows(r.flat());

      // setRows({ ...rowItems, ...lastRow });
    }
  }, [transactions, student, feeStructure, config, ins]);
  console.log(rows);
  useEffect(() => {
    if (transactions && config[0].regConf) {
      const pMap = objectExists(transactions)
        ? transactions.reduce((a, c) => {
            if (c.status !== "Ok") return a;
            const feesInTxn = getObjectKeys(c.fees);
            feesInTxn.forEach((type) => {
              a[type] = (Number(a[type]) || 0) + Number(c.fees[type]);
            });
            return a;
          }, {})
        : {};

      if (config[0].regConf.include) {
        setPaidMap({
          ...pMap,
          tution_fee:
            (pMap.tution_fee || 0) + (pMap[config[0].regConf.regFeeName] || 0),
        });
      } else {
        setPaidMap({ ...pMap });
      }
    }
  }, [transactions]);

  useEffect(() => {
    if (rows.length > 0 && columnsValues.columns) {
      console.log(columnsValues);
      const result = columnsValues.columns
        .filter((column) => column.field.startsWith("installment"))
        .reduce((acc, column) => {
          const installment1 = {};
          let dueDate;

          rows.forEach((row) => {
            if (row[column.field]) {
              const feeType = row.feeType;
              const installmentData = row[column.field];
              dueDate = installmentData.dueDate;
              const installmentAmount =
                (row.amount * installmentData.percent) / 100;
              const installmentPaid = Math.min(installmentAmount, row.paid); // Paid amount for this installment
              const installmentBalance = installmentAmount - installmentPaid; // Balance for this installment

              installment1[feeType] = {
                amount: installmentAmount,
                balance: installmentBalance,
                paid: installmentPaid,
              };

              // Adjust row balance and paid amounts for the next iteration
              row.paid -= installmentPaid;
              row.balance -= installmentBalance;
            }
          });

          // For the Total installment, calculate the modified total amount, balance, and paid
          const modifiedTotalAmount = Object.values(installment1).reduce(
            (sum, fee) => sum + fee.amount,
            0
          );
          const modifiedTotalPaid = Object.values(installment1).reduce(
            (sum, fee) => sum + fee.paid,
            0
          );
          const modifiedTotalBalance = Object.values(installment1).reduce(
            (sum, fee) => sum + fee.balance,
            0
          );

          installment1.Total = {
            amount: modifiedTotalAmount,

            balance: modifiedTotalBalance,
            paid: modifiedTotalPaid,
            dueDate: dueDate, // Use the dueDate from the last installment, assuming it's the same for all installments
          };

          acc[column.field] = installment1;
          return acc;
        }, {});

      setOtherFees(
        rows.reduce((acc, row) => {
          if (!row.installments) {
            acc[row.feeType] = {
              amount: row.amount,
              balance: row.balance,
              paid: row.paid,
              concession: row.concession,
            };
          }
          return acc;
        }, {})
      );
      console.log(result);
      setModifiedObject({
        ...result,
      });
    }
  }, [rows, columnsValues]);

  return [modifiedObject, otherFees, rows, transactions, config];
};
export default useFeeData;
