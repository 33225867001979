import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import "./VideoClasses.css";
import VideoClassesMobile from "./VideoClassesMobile";

const Previous = (props) => {
  const institute = store.get("user").institute;
  const { history } = props;
  switch (institute) {
    // case "5d72f343059f3004e1ceaa81":
    //     history.push('/interactive-classes');
    //       break;
    default:
      history.push("/previous-classes");
  }
  return (
    <Fragment>
      <VideoClassesMobile />
    </Fragment>
  );
};

export default compose(withRouter)(Previous);
