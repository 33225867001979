import { Grid, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { Link } from "react-router-dom";
import theme from "../../assets/theme";
const style = () => ({
  testNameBanner: {
    background: theme.palette.common.white,
    boxShadow: "0px 0px 20px #D1D9E6",
  },
  title: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "28px",
  },
  link: {
    color: theme.palette.text.primary,
  },
  toolBar: {
    padding: "0 17% 0 17%",
    width: "100vw",
  },
  "@media(max-width: 1024px)": {
    toolBar: {
      padding: "0 3% 0 3%",
    },
  },
  header: {
    marginTop: "1%",
    padding: "8px 0",
  },
  icon: {
    verticalAlign: "middle",
  },
});
function ResultHeader({ testDisplayName, path = "/home", ...props }) {
  const { classes } = props;
  return (
    <Grid className={classes.header} container direction="row">
      &emsp;
      <Link
        to={{
          pathname: path,
        }}
        className={classes.link}
      >
        <ArrowBackIcon style={{ fontWeight: "lighter" }} />
      </Link>
      &emsp;
      <Typography className={classes.title} color="textPrimary">
        <b>{testDisplayName}</b>
      </Typography>
    </Grid>
  );
}

export default withStyles(style)(ResultHeader);
