import React, { useEffect } from "react";
import store from "store";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../Components/styles";
import borderRadius from "highcharts-border-radius";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../../components/CustomDropdown/Dropdown";
import {
  fetchResultDetails,
  clearResult,
} from "../../../redux/actions/resultPageActions/actionCreators";
import { subColors } from "../ExamSummaryBarChart/colors";
import useStudentData from "../Components/useStudentData";

borderRadius(Highcharts);
const TrellisChart = ({data1,recentTestName,testsObj,testDisplayName}) => {
 
  const [, , stuFeeData] = useStudentData({});

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const resultData = useSelector((state) => state.allResult);
  const [answeringData, setAnsweringData] = useState([]);
  const [allSubjectsData, setAllSubjectsData] = useState([]);
  const [stuId, setStuId] = useState("");
 const [testNames, setTestNames] = useState([]);
//  const [values, setValues] = useState([]);
  const [test, setTest] = useState("");
  // useEffect(() => {
  //   // if (exam === "") {
  //     // const values1 = data1.filter((test) => test.examType === a[group]);
  //     setValues(data1);
  //   // } else {
  //   //   const values1 = data1.filter((test) => test.examType === exam);
  //   //   setValues(values1);
  //   // }
  // }, [exam, data1]);
  useEffect(() => {
    setTestNames(data1.map((obj) => obj.testName));
  }, [data1]);
  useEffect(() => {
    if (stuFeeData.student) {
      setStuId(stuFeeData.student.studentIds);
    }
  }, [stuFeeData]);
  useEffect(() => {
    const body = {
      code: null,
      student_id: store.get("user").accountType
        ? store.get("user").studentIds
        : store.get("user")._id,

      test_name: test === "" ? recentTestName : testsObj[test],
    };
    try {
      dispatch(fetchResultDetails(body));
    } catch (error) {
      console.log(error);
    }
    return () => {
      dispatch(clearResult());
    };
    // }
  }, [test, recentTestName, testsObj]);

  useEffect(() => {
    if (resultData.data) {
      const answeringAccuracy = [];
      const a = () => {
        Object.entries(resultData)[1]
          .flat()
          .map((test) => {
            answeringAccuracy.push({ [test.subjectName]: test.status });
          });
        setAnsweringData(answeringAccuracy);
      };
      a();
    }
  }, [resultData]);

  useEffect(() => {
    if (resultData.data) {
      const subjects = resultData.subjects;
      const result = subjects.reduce((acc, curr) => {
        acc[curr] = {
          wrong: 0,
          correct: 0,
          partial_correct: 0,
          unattempted: 0,
        };
        answeringData.forEach((obj) => {
          if (obj[curr] === "wrong") {
            acc[curr]["wrong"] += 1;
          } else if (obj[curr] === "correct") {
            acc[curr]["correct"] += 1;
          } else if (obj[curr] === "partial_correct") {
            acc[curr]["partial_correct"] += 1;
          } else if (obj[curr] === "unattempted") {
            acc[curr]["unattempted"] += 1;
          }
        });
        return acc;
      }, {});
      const result1 = Object.entries(result).map(([subject, scores]) => {
        const {
          correct = 0,
          wrong = 0,
          unattempted = 0,
          partial_correct = 0,
        } = scores;
        return {
          name: subject,
          data: [correct, wrong, unattempted, partial_correct],
        };
      });
      setData(result1);
    }
  }, [answeringData]);
  let subjectsData = [];
  useEffect(() => {
    const mergeSubColors = () => {
      data.map((i) => {
        if (subColors[i.name]) {
          subjectsData.push({ ...i, ...subColors[i.name] });
        }
        setAllSubjectsData(subjectsData);
      });
    };
    mergeSubColors();
  }, [data]);

  const classes = useStyles();
  const options = {
    chart: {
      type: "bar",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    legend: {
      enabled: true,
    },
    xAxis: {
      categories: ["correct", "wrong", "unattempted", "partial_correct"],
      lineColor: "transparent",
    },
    yAxis: {
      title: "",
      min: 0,
      visible: false,
    },
    plotOptions: {
      series: {
        pointPadding: -1,
        pointWidth: 8,
        borderRadius: "8px",

        dataLabels: [
          {
            enabled: true,
          },
        ],
      },
    },
    credits: {
      enabled: false,
    },

    trellis: {
      rows: 2,
      columns: 2,
      height: 200,
      width: 200,
    },
    series: allSubjectsData || [],
  };

  return (
    <>
      <Grid container spacing={3} direction="row" display="flex">
        <Grid item xs={4}>
          <Typography
            style={{ color: " #31456A", fontSize: "18px", fontWeight: 600 }}
          >
            Answering Accuracy
          </Typography>
        </Grid>
        <Grid item xs={4} container justifyContent="flex-end">
          <Dropdown
            width={200}
            dataToDisplay={testNames || []}
            handleDropdownValueChange={(e, item) => {
              setTest(item);
            }}
            value={test}
            textFieldProps={{
              size: "small",
              InputLabelProps: { shrink: false },
              placeholder: "Select Test",
              label: "Select Test",
            }}
          />
        </Grid>
      </Grid>
      <Typography style={{ align: "center" }}>
        &nbsp;&nbsp;{test === "" ? testDisplayName : test}
      </Typography>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};

export default TrellisChart;
