import store from "store";

export default function clearLocalStorage() {
  const userKey = "user";
  const userData = store.get(userKey);

  store.each((value, key) => {
    // Check if the key contains "assignment" or is "notification"
    if (key && (key.includes("assignment") || key.includes("notification"))) {
      return;
    }
    store.remove(key);
  });
  if (userData && userData.userId) {
    store.set("USER_ID", userData.userId);
  }
}
