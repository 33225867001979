import React, { useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const styles = theme => ({
  accordion: {
    margin: '16px 0', // Remove top margin
    background: '#EBEFF3',
    boxShadow:
      '-8px -8px 12px rgba(255, 255, 255, 0.25), -4px -4px 8px rgba(255, 255, 255, 0.75), 8px 8px 12px rgba(189, 194, 199, 0.25), 4px 4px 8px rgba(189, 194, 199, 0.75)',
    borderRadius: '16px',
    border: 'none', // Ensure no border,
    borderTop: 'none', // Add this line,
    '&:before': {
      display: 'none',
      borderRadius: '16px'
    },
    '& .MuiButtonBase-root': {
      borderRadius: '16px'
    }
    // padding: '3px'
  },
  accordionSummary: {
    background: '#EBEFF3',
    borderTop: 'none'
  },

  expandedAccordionSummary: {
    background: '#3358B8',
    color: '#FFFFFF',
    borderTop: 'none'
  },

  icon: {
    color: '#2A2A2A'
  },
  firstAccordionSummary: {
    background: '#3358B8', // Set your desired background color for the first item
    borderTop: 'none', // Add this line
    color: '#FFFFFF'
  }
})

const ReusableAccordion = ({
  classes,
  renderSummary,
  renderDetails,
  item,
  index,
  firstOneColor,
  containerStyles,
  count,
}) => {
  const [expanded, setExpanded] = useState(false) // Initialize to 0 to expand the first item by default
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Box
      style={{
        marginLeft: '26px',
        marginRight: '26px',
        marginBottom: expanded ? '20px' : null,
        marginTop: expanded ? '20px' : null,
        ...containerStyles
      }}
    >
      <Accordion
        key={index}
        style={{ borderRadius: '16px' }}
        expanded={expanded === index || count === 1}
        onChange={handleChange(index)}
        className={`${classes.accordion}
         `}
      >
        <AccordionSummary
          expandIcon={
            <svg
              width='14'
              height='9'
              viewBox='0 0 14 9'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M13.6922 1.94229L7.44217 8.19229C7.38412 8.2504 7.31519 8.2965 7.23932 8.32795C7.16344 8.35941 7.08212 8.37559 6.99998 8.37559C6.91785 8.37559 6.83652 8.35941 6.76064 8.32795C6.68477 8.2965 6.61584 8.2504 6.55779 8.19229L0.307794 1.94229C0.190518 1.82502 0.124634 1.66596 0.124634 1.5001C0.124634 1.33425 0.190518 1.17519 0.307794 1.05792C0.425069 0.94064 0.584129 0.874756 0.749981 0.874756C0.915834 0.874756 1.07489 0.94064 1.19217 1.05792L6.99998 6.86651L12.8078 1.05792C12.8659 0.999847 12.9348 0.953784 13.0107 0.922357C13.0865 0.890931 13.1679 0.874756 13.25 0.874756C13.3321 0.874756 13.4134 0.890931 13.4893 0.922357C13.5652 0.953784 13.6341 0.999847 13.6922 1.05792C13.7502 1.11598 13.7963 1.18492 13.8277 1.26079C13.8592 1.33666 13.8753 1.41798 13.8753 1.5001C13.8753 1.58223 13.8592 1.66354 13.8277 1.73941C13.7963 1.81528 13.7502 1.88422 13.6922 1.94229Z'
                fill={firstOneColor && index === 0 ? '#FFFFFF' : '#2A2A2A'}
              />
            </svg>
          }
          className={`${classes.accordionSummary} ${
            firstOneColor && index === 0 ? classes.firstAccordionSummary : ''
          }`}
        >
          {renderSummary(item, index, expanded === index)}
        </AccordionSummary>
        <AccordionDetails>
          {renderDetails(item, index, expanded === index)}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

ReusableAccordion.propTypes = {
  renderSummary: PropTypes.func.isRequired,
  renderDetails: PropTypes.func.isRequired
}

export default withStyles(styles)(ReusableAccordion)
