import React from "react";
import { Box, Grid, Paper, Divider } from "@material-ui/core";

import { useStyles } from "./styles";


const SmallCard=({img,heading,value1,value2,background})=>{
    const classes = useStyles();
    return(
      <Grid item xs={12} md={3}>
    <Paper
      item
      className={classes.paper1}
      elevation={3}
      style={{
        background:background
      }}
    >
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} 
        style={{ color: "#FFFFFF" }}
        >
         {img}
          &nbsp; {heading}
        </Grid>

        <Grid item xs={5} style={{ color: "#FFFFFF" }}>
          Percentage
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          style={{ background: "#FFFFFF" }}
        />
        <Grid item xs={5} style={{ color: "#FFFFFF" }}>
          Marks
        </Grid>
      
        <Grid item xs={5} style={{ color: "#FFFFFF" }}>
          {value1}
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          style={{ background: "#FFFFFF" }}
        />
        <Grid item xs={5} style={{ color: "#FFFFFF" }}>
          {value2}
        </Grid>
      </Grid>
    </Paper>
  </Grid>
)}
export default SmallCard;