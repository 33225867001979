import React, { useEffect, useMemo, useState, forwardRef } from "react";
import store from "store";
import { useHistory } from "react-router-dom";
// mui
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import ResultHeader from "../../resultPage/ResultHeader";
// assets
import BackIcon from "../../../assets/icons/BackIcon";
// hooks
import useFetch from "../../../hooks/useFetch";
// apis
import { getStudentFeeInfo } from "../../../apis/finance";
// styles
import feeDetailsStyles from "../../../views/FeeDetails/styles";
// utils
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Information from "./Information";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
    fontSize: "0.6rem"
  },

  header: {
    fontWeight: "bold"
  },
  title: {
    margin: theme.spacing(2),
    fontWeight: 700,
    fontSize: "1.125rem"
  }
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PocketMoney() {
  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const [ay, setAy] = useState();
  const [balance, setBalance] = useState("");
  const { loginIndex, institute, studentIds } = store.get("user") || {};
  const classes = useStyles();
  const [isLoading, error, data, fetchData, resetFetch] = useFetch(
    getStudentFeeInfo
  );

  // const classes = feeDetailsStyles();
  const { currentYear = {}, dueYears = {} } = data || {};
  const isParent = /^[0-9]{10}$/.test(loginIndex) && !!studentIds;
  // const isParent = true;

  const details_fetched = useMemo(() => {
    return data && data.status === "SUCCESS";
  }, [data, isLoading, error]);

  const error_fetching = useMemo(() => {
    if (!data) return false;
    if (data && data.status === "FAILURE") return data.message;
    return false;
  }, [data, isLoading, error]);

  const rows = useMemo(() => {
    if (!data) return "";
    setBalance(data.balance);
    const trans = data.transactions.map(el => ({
      ...el,
      entryType: el.entryType === "CREDIT" ? "debit" : null,
      date: new Date(el.receiptDate),
      // branch: el.branchId ? getBranchName(el.branchId) : "",
      amount: parseFloat(el.amount) // Converting the amount from string to float
    }));

    const allowances = data.allowances.map(el => ({
      ...el,
      date: new Date(el.receiptDate),
      amount: parseFloat(el.amount) // Ensure amount is numeric
    }));

    const totalRows = [...allowances, ...trans].sort((a, b) => a.date - b.date);
    console.log(totalRows);

    return totalRows;
  }, [data]);

  useEffect(() => {
    if (isParent) {
      const body = {
        admissionNo: `${loginIndex}`,
        instituteId: institute,
        studentId: studentIds,
        branch: store.get("user").branchId,
        isPocketMoney: true
      };

      fetchData(body);
    }

    return () => {
      resetFetch();
    };
  }, []);

  if (isLoading) {
    return (
      <div className={classes.container}>
        <div className={classes.headContainer}>
          <IconButton onClick={() => history.goBack()}>
            <BackIcon />
          </IconButton>
          <div>
            <Typography className={classes.title}>Pocket Money</Typography>
          </div>
        </div>
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (error_fetching || error) {
    return (
      <div className={classes.container}>
        <div className={classes.headContainer}>
          <IconButton onClick={() => history.goBack()}>
            <BackIcon />
          </IconButton>
          <div>
            <Typography className={classes.title}>Pocket Money</Typography>
          </div>
        </div>
        <div>
          <Typography
            color="error"
            variant="h6"
            style={{
              width: "100%",
              textAlign: "center"
            }}
          >
            Error fetching details
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={classes.container}>
        <ResultHeader testDisplayName="Pocket Money" path="/home-page" />
        {rows.length !== 0 && <Information rows={rows} balance={balance} />}
      </div>
    </>
  );
}
