const availableSubjects = {
  M: "MATHEMATICS",
  P: "PHYSICS",
  C: "CHEMISTRY",
};

const decodeCodeP = (codeP = "P.5.42") => {
  const codePArray = codeP.split(".");
  const subject = availableSubjects[codePArray[0]];

  const topicIndex = Number(codePArray[1]) - 1;
  const subTopicCode = codePArray[2];
  return {
    subject,
    topicIndex,
    subTopicCode,
  };
};
export { decodeCodeP, availableSubjects };
