import {
  Grid,
  Input,
  makeStyles,
  Typography,
  Container,
  Snackbar,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { getUser } from "../apis/UserService";
import React, { useEffect, useState } from "react";
import theme from "../assets/theme";
import Button from "components/CustomButtons/Button.jsx";
import store from "store";
import { useDispatch, useSelector } from "react-redux";
import { editProfileLoading } from "../redux/actions/editProfileAction/actionCreator";
import NetworkProgress from "./NetworkProgress";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { sendConfirmationSMS } from "../apis/smsService";
import PrintEnquiryProfile from "./PrintEnquiryProfile";
import { Print } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  text: {
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },

    "&:not(.Mui-disabled):hover::before": {
      borderColor: theme.palette.primary.main,
    },
  },
  editProfile: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 3%",
    },
  },
  successColor: {
    color: theme.palette.primary.main,
  },
}));

const occupation = [
  "Farmer/Agricultural",
  "Government Service",
  "Teacher/Professor",
  "Defense Personnel (Army, Navy, Air Force)",
  "Doctor/Nurse",
  "Engineer (Software, Civil, Mechanical, etc.)",
  "Business Owner/Entrepreneur",
  "Private Service",
  "Bank Employee",
  "Lawyer",
  "Journalist/Media Professional",
  "Artist/Musician/Actor",
  "Construction Worker",
  "Housewife/Homemaker",
  "Other",
];

const ProfileEditForm = () => {
  const history = useHistory();
  const { hostname } = window.location;
  let payAmount = {
    Foundation: 100,
    BiPC: 100,
    MPC: 100,
    MAINS2021: 2500,
    MA: 6000,
    N: 6000,
    MAN: 9000,
    crash: 5900,
    fulltests_2021: 1260,
    crashtests_2020: 580,
  };
  const profileData = {
    fatherName: "",
    previousSchool: "",
    presentAddress: "",
    alternateContact: "",
    statusType: "unpaid",
    fatherOccupation: "",
    motherName: "",
    motherOccupation: "",
    board: "",
    referral: "",
    email: "",
    schoolAddress: "",
    district:"",
    howDoYouKnow:""
  };
  const formErrors = {
    fatherName: null,
    previousSchool: null,
    presentAddress: null,
    alternateContact: null,
    schoolAddress: null,
    email: null,
    board:null,
    referral:null,
    howDoYouKnow:null,
    fatherOccupation: null,
    motherName: null,
    motherOccupation: null,
    district:null
  };
  const user = store.get("user");
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(profileData);
  const [error, setError] = useState(formErrors);
  const profileEdit = useSelector((state) => state.profileEdit);
  const { data, isLoading } = profileEdit;

  // payment handler
  const logPayment = (body, confirmationText) => {
    const url = window.location.host.includes("localhost")
      ? "https://onesaz.com"
      : window.location.origin;
    const ax = axios.create({
      baseURL: url + "/api",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    ax.post("/pay/success", JSON.stringify(body)).then((res) => {
      if (res.status == 200) {
        console.log(res);
        const user = store.get("user");
        dispatch(
          editProfileLoading(user._id, {
            ...formData,
            statusType: "paid",
          })
        );
        user.package = "allindiatests_2022";
        user.level = 2119611;
        store.set("user", {
          ...user,
          ...formData,
          statusType: "paid",
        });

        sendConfirmationSMS(user.phoneNumber || user.phoneno, confirmationText)
          .then((res) => {
            history.push("/home");
          })
          .catch((err) => {
            console.log("payment", err);
            history.push("/home");
          });
      }
    });
  };
  // update profile details
  const updateProfile = (userId, data, response) => {
    const url = window.location.host.includes("localhost")
      ? "https://onesaz.com"
      : window.location.origin;
    const ax = axios.create({
      baseURL: url + "/api",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    ax.post(`/users/editprofile/${userId}`, { ...data, statusType: "paid" })
      .then((res) => {
        if (res.status == 200) {
          const confirmationText = `Student, Your registration is successful!! ${
            user.examDate
              ? `Your exam details are: Date: ${user.examDate} and Time slot: ${
                  user.examTimeSlot
                }`
              : ""
          }. ${window.location.origin}/sign-in`;
          logPayment(response, confirmationText);
        }
      })
      .catch((error) => {
        console.log("payment", error);
        return error.message;
      });
  };
  const updatePaymentStatus = (response) => {
    updateProfile(user._id, formData, response);
    // alert(store.get("user").statusType);
    // console.log(response);
  };
  const payment = (type) => {
    const url = window.location.host.includes("localhost")
      ? "https://onesaz.com"
      : window.location.origin;
    let user = JSON.parse(window.localStorage.getItem("user"));
    // console.log(user._id);
    var requestData = {
      _id: user._id,
      key: "jmKsogxT",
      txnid: store.get("user").userId,
      // color: "primary",
      hash: "",
      amount: payAmount[type],
      firstname: user["name"],
      email: "support@onesaz.com",
      phone: user["phoneNumber"],
      productinfo: JSON.stringify({
        _id: user._id,
        package: "allindiatests_2022",
      }),
      service_provider: "payu_paisa",
      surl: url + "/api/pay/success",
      furl: url + "/api/paymentfailed",
      salt: "VCHHRU2kQZ", // "UhvE4JAdfK",
      // mode: "dropout" // non-mandatory for Customized Response Handling
    };
    var Handler = {
      responseHandler: (BOLT) => {
        if (BOLT.response.txnStatus != "CANCEL") {
          console.log(BOLT.response);
          updatePaymentStatus(BOLT.response);
        }
        return BOLT.response;
      },
      catchException: function(BOLT) {},
    };
    const ax = axios.create({
      baseURL: url + "/api",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    ax.post("/pay/request", JSON.stringify(requestData)).then((res) => {
      console.log(res);
      if (res.data.status == "SUCCESS") {
        requestData.hash = res.data.hash;
        requestData.txnid = res.data.txnid;
        window.bolt.launch(requestData, Handler);
      }
    });
    //  window.bolt.launch( requestData , Handler );
  };
  // ---------------snackbar utils----------------
  const [snackBarState, setSnackBarState] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, openSnackBar } = snackBarState;
  const handleCloseSnackBar = () => {
    setSnackBarState({ ...snackBarState, openSnackBar: false });
  };

  // validate input values
  const validateForm = () => {
    let values = formData;
    console.log(values);
    let errors = { ...formErrors };
    let formIsValid = true;
    if (!values.fatherName) {
      formIsValid = false;
      errors.fatherName = "*Please enter your father's name.";
    }
    if (!values.fatherOccupation) {
      formIsValid = false;
      errors.fatherOccupation = "*Please select father's occupation.";
    }
    if (!values.motherName) {
      formIsValid = false;
      errors.motherName = "*Please enter your mother's name.";
    }
    if (!values.motherOccupation) {
      formIsValid = false;
      errors.motherOccupation = "*Please select mother's occupation.";
    }
    if (!values.email) {
      formIsValid = false;
      errors.email = "*Please enter email";
    }
    if (!values.board) {
      formIsValid = false;
      errors.board = "*Please select board";
    }
    if (!values.howDoYouKnow) {
      formIsValid = false;
      errors.howDoYouKnow = "*Please select how do you know about us";
    }
    if(values.howDoYouKnow==="Reference By"&& !values.referral){
      formIsValid = false;
      errors.referral = "*Please enter referral name and contact";
    }
   
    if (!values.schoolAddress) {
      formIsValid = false;
      errors.schoolAddress = "*Please enter school address";
    }

    if (!values.previousSchool) {
      formIsValid = false;
      errors.previousSchool = "*Please enter previous school name";
    }
    if (!values.district) {
      formIsValid = false;
      errors.district = "*Please enter previous school district";
    }
    if (!values.presentAddress) {
      formIsValid = false;
      errors.presentAddress = "*Please enter present address";
    }
    if (/^\d{10}$/.test(values.alternateContact) === false) {
      formIsValid = false;
      errors.alternateContact = "*Please enter a valid 10 digit contact no.";
    }
  
    if (
      values.alternateContact ==
      (user.personalDetails
        ? user.personalDetails.fatherNumber
        : user.phoneNumber)
    ) {
      formIsValid = false;
      errors.alternateContact =
        "*Please enter a different phone no. then what used for registration";
    }
    setError(errors);

    return formIsValid;
  };
  const handleChange = (e) => {
    e.preventDefault();

   const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    Object.keys(error).forEach((i) => (error[i] = null));
  };
  console.log(formData);

  const handleProfileEdit = () => {
    if (validateForm()) {
      if (hostname.includes("bhashyam")) {
        payment(user.course);
      } else {
        formData.referral = formData.howDoYouKnow==="Reference By" ? formData.referral :formData.howDoYouKnow;
        dispatch(editProfileLoading(user._id, formData));
      }
    }
  };

  useEffect(() => {
    // update user with newly added data in store
    if (data.status && data.status === "success") {
      const profile = {
        ...user,
        ...formData,
        statusType: hostname.includes("bhashyam")
          ? "paid"
          : formData.statusType,
      };
      store.set("user", profile);
      setSnackBarState({
        openSnackBar: true,
        vertical: "top",
        horizontal: "center",
      });
      setTimeout(() => {
        history.push("/home");
      }, 1000);
    }
  }, [data]);
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <br />
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.editProfile}
      >
        {profileEdit.error && (
          <Typography color="error">Something went wrong!</Typography>
        )}
        {isLoading && <NetworkProgress />}
        {data.status && data.status === "success" ? null : (
          <React.Fragment>
            <Grid item>
              <Typography color="error">
                Please complete your profile
                {hostname.includes("bhashyam") ? " and Make Payment " : " "}
                before proceeding to write exams.
                <sup>*</sup>
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={2} style={{ alignItems: "end" }}>
                <Grid item xs={12} sm={6}>
                  <Input
                    placeholder="Father's name*"
                    inputProps={{ "aria-label": "Father's name" }}
                    fullWidth
                    type="text"
                    value={formData.fatherName}
                    name="fatherName"
                    onChange={handleChange}
                    classes={{ underline: classes.text }}
                  />
                  {error.fatherName && (
                    <Typography color="error" variant="body2">
                      {error.fatherName}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Father's Occupation<span>*</span>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.fatherOccupation}
                      onChange={handleChange}
                      name="fatherOccupation"
                    >
                      {occupation.map((job) => (
                        <MenuItem key={job} value={job}>
                          {job}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {error.fatherOccupation && (
                    <Typography color="error" variant="body2">
                      {error.fatherOccupation}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    placeholder="Mother's name *"
                    inputProps={{ "aria-label": "Mother's name" }}
                    fullWidth
                    type="text"
                    value={formData.motherName}
                    name="motherName"
                    onChange={handleChange}
                    classes={{ underline: classes.text }}
                  />
                                    {error.motherName && (
                    <Typography color="error" variant="body2">
                      {error.motherName}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Mother's Occupation <span>*</span>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.motherOccupation}
                      onChange={handleChange}
                      name="motherOccupation"
                    >
                      {occupation.map((job) => (
                        <MenuItem key={job} value={job}>
                          {job}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {error.motherOccupation && (
                    <Typography color="error" variant="body2">
                      {error.motherOccupation}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    placeholder="Present address (village/town name)*"
                    inputProps={{
                      "aria-label": "Present address (village/town name)",
                    }}
                    fullWidth
                    type="text"
                    value={formData.presentAddress}
                    name="presentAddress"
                    onChange={handleChange}
                    classes={{ underline: classes.text }}
                  />
                  {error.presentAddress && (
                    <Typography color="error" variant="body2">
                      {error.presentAddress}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    placeholder="Email*"
                    inputProps={{ "aria-label": "Email" }}
                    fullWidth
                    type="text"
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                    classes={{ underline: classes.text }}
                  />
                   {error.email && (
                    <Typography color="error" variant="body2">
                      {error.email}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    placeholder="Alternate contact no.*"
                    inputProps={{ "aria-label": "Alternate contact no." }}
                    fullWidth
                    type="tel"
                    value={formData.alternateContact}
                    name="alternateContact"
                    onChange={handleChange}
                    classes={{ underline: classes.text }}
                  />
                  {error.alternateContact && (
                    <Typography color="error" variant="body2">
                      {error.alternateContact}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"> Previous School Board<span>*</span></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.board}
                      onChange={handleChange}
                      name="board"
                    >
                      <MenuItem value="State Board">State Board</MenuItem>
                      <MenuItem value="CBSE">CBSE</MenuItem>
                      <MenuItem value="ICSE">ICSE</MenuItem>
                    </Select>
                  </FormControl>
                  {error.board && (
                    <Typography color="error" variant="body2">
                      {error.board}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    placeholder="Previous school Name*"
                    inputProps={{ "aria-label": "Previous school" }}
                    fullWidth
                    type="text"
                    value={formData.previousSchool}
                    name="previousSchool"
                    onChange={handleChange}
                    classes={{ underline: classes.text }}
                  />
                  {error.previousSchool && (
                    <Typography color="error" variant="body2">
                      {error.previousSchool}
                    </Typography>
                  )}
                </Grid>
              
               
                <Grid item xs={12} sm={6}>
                  <Input
                    placeholder="School's Address (village / town name)*"
                    inputProps={{ "aria-label": "Email" }}
                    fullWidth
                    type="text"
                    value={formData.schoolAddress}
                    name="schoolAddress"
                    onChange={handleChange}
                    classes={{ underline: classes.text }}
                  />
                   {error.schoolAddress && (
                    <Typography color="error" variant="body2">
                      {error.schoolAddress}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    placeholder="District*"
                    inputProps={{ "aria-label": "District" }}
                    fullWidth
                    type="text"
                    value={formData.district}
                    name="district"
                    onChange={handleChange}
                    classes={{ underline: classes.text }}
                  />
                  {error.district && (
                    <Typography color="error" variant="body2">
                      {error.district}
                    </Typography>
                  )}
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">How do you know about us?<span>*</span></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.howDoYouKnow}
                      onChange={handleChange}
                      name="howDoYouKnow"
                    >
                      <MenuItem value="News Paper">News Paper</MenuItem>
                      <MenuItem value="Walk-in">Walk-in</MenuItem>
                      <MenuItem value="website">Website</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                      <MenuItem value="Reference By">Reference By</MenuItem>

                    </Select>
                  </FormControl>
                  {error.howDoYouKnow && (
                    <Typography color="error" variant="body2">
                      {error.howDoYouKnow}
                    </Typography>
                  )}
                </Grid>
                {formData.howDoYouKnow==="Reference By"&& <Grid item xs={12} sm={6}>
                  <Input
                    placeholder="Reference name & contact (e.g: John,7334339863)*"
                    inputProps={{ "aria-label": "Email" }}
                    fullWidth
                    type="text"
                    value={formData.referral}
                    name="referral"
                    onChange={handleChange}
                    classes={{ underline: classes.text }}
                  />
                   {error.referral && (
                    <Typography color="error" variant="body2">
                      {error.referral}
                    </Typography>
                  )}
                </Grid>}
               
              </Grid>
            </Grid>
            <br />
            {/* // submit button */}
            <Grid container justify="flex-end">
              <Button round color="primary" onClick={handleProfileEdit}>
                {hostname.includes("bhashyam")
                  ? `Save and Pay ₹${payAmount[user.course]}.00`
                  : "Save"}
              </Button>
            </Grid>
          </React.Fragment>
        )}
        <br />
      </Grid>
      {/* snackbar */}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnackBar}
        onClose={handleCloseSnackBar}
        message={"Profile updated!"}
        key={vertical + horizontal}
      />
    </Container>
  );
};

export default ProfileEditForm;
