import { Grid, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "65vh",
    paddingTop: "20px",
  },
  hrStyle: {
    background: theme.palette.primary.main,
    width: "30%",
    height: "2px",
    border: "none",
  },
  paper: {
    padding: theme.spacing(5),
    borderTop: "1.2px solid white",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    borderLeft: "1.2px solid white",
    borderRadius: "24px",
    marginBottom: "5px",
  },
  aTagColor: {
    color: "#08C5A7",
  },
  subBtnText: {
    fontSize: "10px",
  },
}));

function PaymentPopup(props) {
  const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Grid item xs={12} sm={12}>
        <Paper className={classes.paper}>
          <Typography>
            <CheckCircleRoundedIcon fontSize="small" /> &nbsp;Onesaz Online Test
            Series
            <br />
            <CheckCircleRoundedIcon fontSize="small" /> &nbsp;Target 2021-2022
            <br />
            <CheckCircleRoundedIcon fontSize="small" /> &nbsp; If you are
            targeting JEE Main and Advance in 2022, its time to try Onesaz test
            series
            <br />
            <CheckCircleRoundedIcon fontSize="small" /> &nbsp; The test package
            consists of 1 Free Practice Test + 30 Jee Mains Full Tests + 4
            Advance Full Tests
            <br />
            <CheckCircleRoundedIcon fontSize="small" /> &nbsp; The series is
            designed by the proficient faculties, try our free practice test to
            get the essence of the tests standard
            <br />
            <CheckCircleRoundedIcon fontSize="small" /> &nbsp; It provides you
            with the ideal way of preparing for the target exam following an
            exact exam-like test format with detailed analysis
            <br />
            {/* <CheckCircleRoundedIcon fontSize="small" /> &nbsp;{" "}
            <a
              className={classes.aTagColor}
              onClick={() => {
                window.open(
                  "https://onesaz-new.s3.ap-south-1.amazonaws.com/schedules/Onesaz+Online+Test+Series+2020-2021_FULL_TESTS.pdf"
                );
              }}
            >
              Click here for Click here for Test Schedule and Syllabus
            </a> */}
          </Typography>
          <br />
          <Typography align="center">
            {/* <Button variant="contained" color="primary" onClick={props.onClick}>
              Explore{" "}
            </Button> */}
            {/* <div className={classes.subBtnText}>(including GST)</div> */}
          </Typography>
        </Paper>
      </Grid>
    </Dialog>
  );
}

export default PaymentPopup;
