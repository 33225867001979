import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CustomTable from "components/Table/Table.jsx";
import examAxios from "../apis/examServerAxios";
import NetworkProgress from "./NetworkProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
var moment = require("moment");
const styles = (theme) => ({});

class QuestionList extends React.Component {
  tableHead = [
    "Q.NO",
    "Status",
    "Your Time ",
    "Avg Time",
    "Students Attempted",
    "Students Answered Correctly",
    "Students Un Attempted",
    "Students Answered Wrongly",
  ];
  //  tableData=[[" h ", "your Details", "Average", "Topper Details"],["ello  ", "your Details", "Average", "Topper Details"]];

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      networkRequest: true,
      data: [],
    };
  }
  componentDidMount() {
    const body = JSON.stringify({
      student_id: this.props.id,
      test_name: this.props.test_name,
    });

    examAxios
      .post("/analysis/questiondetails", body)
      .then((res) => res.data)
      .then((res) => {
        if (res.status == "SUCCESS") {
          res = res.payload;
          const data = this.state.data;
          res.questionDetails.map((detail) => {
            const valList = [];
            if (detail.yourTimeTaken) {
              const duration = moment.duration(detail.yourTimeTaken, "ms");
              let timeTaken = "";
              if (duration.minutes() > 0) {
                timeTaken = duration.minutes() + "m ";
              }
              timeTaken = timeTaken + duration.seconds() + "s";
              detail.yourTimeTaken = timeTaken;
            }
            if (detail.averageTimeTaken) {
              const duration = moment.duration(detail.averageTimeTaken, "ms");
              let timeTaken = "";
              if (duration.minutes() > 0) {
                timeTaken = duration.minutes() + "m ";
              }
              timeTaken = timeTaken + duration.seconds() + "s";
              detail.averageTimeTaken = timeTaken;
            }
            Object.values(detail).forEach((value) => {
              if (value == "C") {
                valList.push(
                  <span>
                    {" "}
                    <i class="material-icons" style={{ color: "green" }}>
                      done
                    </i>
                  </span>
                );
              } else if (value == "W" || value == "W") {
                valList.push(
                  <span>
                    {" "}
                    <i class="material-icons" style={{ color: "red" }}>
                      close
                    </i>
                  </span>
                );
              } else if (value == "U") {
                valList.push(<strong>{String("--")}</strong>);
              } else valList.push(<strong>{String(value)}</strong>);
            });
            data.push(valList);
          });

          this.setState({ data: data, networkRequest: false });
        } else {
          this.setState({ err: res.message });
        }
        // this.forceUpdate();
      });
  }
  // classes,
  //     tableHead,
  //     tableData,
  //     tableHeaderColor,
  //     hover,
  //     colorsColls,
  //     coloredColls,
  //     customCellClasses,
  //     customClassesForCells,
  //     striped,
  //     tableShopping,
  //     customHeadCellClasses,
  //     customHeadClassesForCells
  render() {
    console.log(this.data);
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            textAlign="center"
          >
            <Typography className={classes.heading}>QuestionList</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {this.state.networkRequest ? (
              <NetworkProgress />
            ) : this.state.err == undefined ? (
              <CustomTable
                style={{ width: "100%" }}
                tableData={Object.values(this.state.data)}
                tableHead={this.tableHead}
              >
                {" "}
              </CustomTable>
            ) : (
              this.state.err
            )}
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(QuestionList);
