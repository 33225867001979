import examAxios from "apis/examServerAxios";
import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import Advance from "./Advance";
import "./App.css";
import CustomTypePanel from "./CustomTypePanel";
import HomePanel from "./HomePanel";
import NetworkProgress from "./NetworkProgress";

class ExamPortal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schema: null,
      testData: store.get("examObject"),
    };
    this.props = props;
  }
  isLoggedin = false;
  testname = null;
  startedTime = 0;
  remainingTime = 0;
  id = store.get("user")._id;
  loadingMsg = "Loading...";
  componentDidMount() {
    // this.lockHistoryState();

    if (this.state.testData && this.state.testData.test_name) {
      const url = `/exams/getSchema?testName=${this.state.testData.test_name}`;
      examAxios
        .get(url)
        .then((res) => res.data.output)
        .then((res) => {
          // console.log(res);
          this.setState({
            schema: res,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // if (!this.props.location.state) {
    //   const { history } = this.props;
    //   history.push("/home");
    // }
  }
  // componentWillUnmount() {
  //   window.removeEventListener("popstate", this.preventBackNavigation);
  // }

  // lockHistoryState = () => {
  //   window.history.pushState(null, "", window.location.href);
  //   window.addEventListener("popstate", this.preventBackNavigation);
  // };

  // preventBackNavigation = (event) => {
  //   event.preventDefault();
  //   window.history.pushState(null, "", window.location.href);
  // };

  render() {
    const { testData } = this.state;
    if (testData && testData.test_type.includes("CUSTOM") && testData.pdfLink) {
      return (
        <Redirect
          to={{
            pathname: "/writePDFtest",
            search: `?examDuration=${testData.test_duration}&user=${
              this.id
            }&test_type=${testData.test_type}&displayname=${
              testData.testDisplayName
            }&examName=${testData.test_name}&pdfLink=${testData.pdfLink}`,
          }}
        />
      );
    }

    return this.remainingTime >= 0 && testData ? (
      <React.Fragment>
        {testData.test_type &&
        (testData.test_type.includes("JEE ADVANCED") ||
          (this.state.schema && this.state.schema.questions)) ? (
          <Advance
            paper={testData.paper}
            examDuration={testData.test_duration}
            user={this.id}
            displayName={testData.testDisplayName}
            examName={testData.test_name}
            schema={this.state.schema}
          />
        ) : testData.test_type.includes("CUSTOM") ? (
          <CustomTypePanel
            examDuration={testData.test_duration}
            user={this.id}
            test_type={testData.test_type}
            displayname={testData.testDisplayName}
            examName={testData.test_name}
          />
        ) : (
          <HomePanel
            examDuration={testData.test_duration}
            user={this.id}
            test_type={testData.test_type}
            displayname={testData.testDisplayName}
            examName={testData.test_name}
          />
        )}
      </React.Fragment>
    ) : (
      <NetworkProgress />
    );
  }
}

export default compose(withRouter)(ExamPortal);
