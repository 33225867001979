import * as actionType from "./actions";

// fetching list of notifications
export const fetchNotificationsList = (body) => ({
  type: actionType.FETCH_NOTIFICATIONS_LOADING,
  body,
});

export const fetchNotificationsSuccess = (notifications) => ({
  type: actionType.FETCH_NOTIFICATIONS_SUCCESS,
  payload: notifications,
});

/* ------------------api error handling action creators------------------- */

export const catchErrorNotifications = (error) => ({
  type: actionType.CATCH_ERROR_NOTIFICATIONS,
  payload: error,
});

/* ------------clear error from store if any on component unmount------------ */

export const clearErrorNotifications = () => ({
  type: actionType.CLEAR_ERROR_NOTIFICATIONS,
});
