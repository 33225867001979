import store from "store";
// react
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
// mui
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import BottomNav from "../BottomNav";
import CssBaseline from "@material-ui/core/CssBaseline";
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import { Box, Grid, IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import balance from "./assets/balance.png";
import totalFee from "./assets/totalFee.png";
import concession from "./assets/concession.png";
import paid from "./assets/paid.png";

// theme
import theme from "../../assets/theme";
// header component
import Header from "../components/Header";
// styles
import { useStyles, StyledBadge } from "../Dashboard/Components/styles";

const data = [
  { name: 'John', age: 25, email: 'john@example.com' },
  { name: 'Mary', age: 32, email: 'mary@example.com' },
  { name: 'David', age: 42, email: 'david@example.com' },
];
const Dashboard = () => {
  // const accountType = store.get("user").accountType;
  const classes = useStyles();
  const history = useHistory();

  const [showInstallment, setShowInstallment] = useState(true);
  const handleExpand = () => {
    setShowInstallment(!showInstallment);
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  function handleClick() {
    history.push("/payment");
  }

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        {/* <Header /> */}
        <CssBaseline />
        <div className={classes.bgCustom}>
          {/* ---------Top Small Cards------------- */}

          <Box className={classes.TopSmallCardsContainer}>
            <Grid item>
              <Paper item className={classes.paper}>
                <Grid container direction="row" spacing={1}>
                  <Grid item className={classes.CardFontStyle}>
                    <img src={totalFee} alt="" style={{ height: "20px" }} />
                    &nbsp; Total Fee
                  </Grid>
                  &nbsp;
                  <Grid item className={classes.numberFontStyle}>
                    229
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item>
              <Paper item className={classes.paper}>
                <Grid container direction="row" spacing={1}>
                <Grid item className={classes.CardFontStyle}>
                    <img src={concession} alt="" style={{ height: "20px" }} />
                    &nbsp; Concession
                  </Grid>
                 
                  &nbsp;
                  <Grid item className={classes.numberFontStyle}>
                    229
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item>
              <Paper item className={classes.paper}>
                <Grid container direction="row" spacing={1}>
                <Grid item className={classes.CardFontStyle}>
                    <img src={paid} alt="" style={{ height:"20px" }} />
                    &nbsp; Paid &nbsp;
                  </Grid>
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  <Grid item className={classes.numberFontStyle}>
                    229
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item>
              <Paper item className={classes.paper}>
                <Grid container direction="row" spacing={1}>
                <Grid item className={classes.CardFontStyle}>
                    <img src={balance} alt="" style={{ height: "20px"}} />
                    &nbsp; Balance
                  </Grid>
                  &nbsp;
                  &nbsp;
                  <Grid item className={classes.numberFontStyle}>
                    229
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Box>
          {/* <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Age</TableCell>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.age}</TableCell>
              <TableCell>{row.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> */}
        </div>
        <BottomNav />
      </React.Fragment>
    </ThemeProvider>
  );
};

export default Dashboard;
