import store from "store";
import axios from "axios";
import { history } from "../index";
// payment amount
const payAmount = {
  MAINS2022: 12800,
  MA: 6000,
  N: 6000,
  MAN: 9000,
  crash: 5900,
  fulltests_2021: 1260,
  crashtests_2020: 580,
};

const { host } = window.location;

const url = host.includes("localhost")
  ? "https://onesaz.com"
  : "https://bhashyam.onesaz.com";

const paymentService = (type) => {
  let user = JSON.parse(window.localStorage.getItem("user"));
  //console.log(user._id);
  var requestData = {
    _id: user._id,
    key: "jmKsogxT",
    txnid: store.get("user").userId,
    // color: "primary",
    hash: "",
    amount: payAmount[type],
    firstname: user["name"],
    email: "support@onesaz.com",
    phone: user["phoneNumber"],
    productinfo: JSON.stringify({
      _id: user._id,
      package: type,
    }),
    service_provider: "payu_paisa",
    surl: url + "/api/pay/success",
    furl: url + "/api/paymentfailed",
    salt: "VCHHRU2kQZ", //"UhvE4JAdfK",
    // mode: "dropout" // non-mandatory for Customized Response Handling
  };
  var Handler = {
    responseHandler: (BOLT) => {
      if (BOLT.response.txnStatus !== "CANCEL") {
        console.log(BOLT.response);
        updatePaymentStatus(BOLT.response);
      }
      return BOLT.response;
    },
    catchException: function(BOLT) {},
  };

  const updatePaymentStatus = (response) => {
    logPayment(response);
  };

  const logPayment = (body) => {
    const ax = axios.create({
      baseURL: url + "/api",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    ax.post("/pay/success", JSON.stringify(body)).then((res) => {
      window.location.reload();
      if (res.status == 200) {
        const user = store.get("user");
        user.package = "MAINS2022";
        user.level = 2119611;
        store.set("user", {
          ...user,
          statusType: "paid",
        });
        window.location.reload();
        history.push("/home");
        console.log(res);
      }
    });
  };
  const ax = axios.create({
    baseURL: url + "/api",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  ax.post("/pay/request", JSON.stringify(requestData)).then((res) => {
    console.log(res);
    if (res.data.status == "SUCCESS") {
      requestData.hash = res.data.hash;
      requestData.txnid = res.data.txnid;
      window.bolt.launch(requestData, Handler);
    }
  });
};
export default paymentService;
