import React from "react";
import Button from "@material-ui/core/Button";


const CustomButtton = ({ handleGetClick, label, icon }) => (
  <Button
  size="small"
    style={{
      background: "linear-gradient(180deg, #3BBDED 24.27%, #118FBE 100%)",
      "&:hover": {
        boxShadow: "1px 1px #73D0F2",
      },
      "&:active": {
        background: "#118FBE",
      },
    }}
    type="submit"
    variant="contained"
    onClick={handleGetClick}
    startIcon={icon}
  >
    {label}
  </Button>
);
export default CustomButtton;
