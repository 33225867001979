import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Box,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PaymentConfirmDialog from "./PaymentConfirmDialog";
import { Transition } from "./styles";
import RenderTable from "./components/RenderTable";
import OtherFeesTable from "./components/OtherFeesTable";

const YourComponent = ({
  modifiedObject,
  otherFees,
  config,
  concessionFeeType,
}) => {
  const matches = useMediaQuery("(min-width:850px)");
  const matchesButton = useMediaQuery("(min-width:550px)");
  const [openDialog, setOpenDialog] = useState(false);
  const [nameFirstTable, setNameFirstTable] = useState({}); // Separate state variable for first table text fields
  const [nameSecondTable, setNameSecondTable] = useState({}); // Separate state variable for second table text fields
  const [paymentData, setPaymentData] = useState({});
  const [balancesFirstTable, setBalancesFirstTable] = useState({});
  const [balancesSecondTable, setBalancesSecondTable] = useState({});
  const [selectedFeeTypesFirstTable, setSelectedFeeTypesFirstTable] = useState(
    []
  );

  const [
    selectedFeeTypesSecondTable,
    setSelectedFeeTypesSecondTable,
  ] = useState([]);
  const handleFeeTypeCheckboxChangeFirstTable = (installment, feeType) => {
    const feeTypeInstallment = `${installment}:${feeType}`;

    if (selectedFeeTypesFirstTable.includes(feeTypeInstallment)) {
      setSelectedFeeTypesFirstTable((prevSelected) =>
        prevSelected.filter((selected) => selected !== feeTypeInstallment)
      );
    } else {
      setSelectedFeeTypesFirstTable((prevSelected) => [
        ...prevSelected,
        feeTypeInstallment,
      ]);
    }

    const isChecked = selectedFeeTypesFirstTable.includes(feeTypeInstallment);
    const balance = isChecked
      ? balancesFirstTable[feeTypeInstallment] || ""
      : "";

    handleBalanceChangeFirstTable(installment, feeType, balance);
  };
  const handleBalanceChangeFirstTable = (installment, feeType, balance) => {
    const feeTypeInstallment = `${installment}:${feeType}`;

    const updatedBalances = {
      ...balancesFirstTable,
      [feeTypeInstallment]: balance,
    };
    setBalancesFirstTable(updatedBalances);
    setNameFirstTable((prevNames) => ({
      ...prevNames,
      [feeTypeInstallment]: balance,
    }));
  };

  const handleFeeTypeCheckboxChangeSecondTable = (feeType, secondBalance) => {
    const feeTypeInstallment = `${feeType}`;

    if (selectedFeeTypesSecondTable.includes(feeTypeInstallment)) {
      setSelectedFeeTypesSecondTable((prevSelected) =>
        prevSelected.filter((selected) => selected !== feeTypeInstallment)
      );
    } else {
      setSelectedFeeTypesSecondTable((prevSelected) => [
        ...prevSelected,
        feeTypeInstallment,
      ]);
    }
    const isChecked = selectedFeeTypesSecondTable.includes(feeTypeInstallment);
    const balance = isChecked
      ? balancesSecondTable[feeTypeInstallment] || ""
      : "";

    handleBalanceChangeSecondTable(feeType, balance);
  };

  const handleBalanceChangeSecondTable = (feeType, balance) => {
    const feeTypeInstallment = `${feeType}`;

    const updatedBalances = {
      ...balancesSecondTable,
      [feeTypeInstallment]: balance,
    };
    setBalancesSecondTable(updatedBalances);
    setNameSecondTable((prevNames) => ({
      ...prevNames,
      [feeTypeInstallment]: balance,
    }));
  };

  const handleProceed = () => {
    const selectedFeesFirstTable = {};
    const selectedFeesSecondTable = {};
    selectedFeeTypesFirstTable.forEach((feeType) => {
      const [installment, feeTypeName] = feeType.split(":");
      const feeTypeInstallment = `${installment}:${feeTypeName}`;
      const installmentData = modifiedObject[installment];
      if (installmentData && installmentData[feeTypeName]) {
        const balance =
          nameFirstTable[feeTypeInstallment] ||
          installmentData[feeTypeName].balance;

        if (!selectedFeesFirstTable[installment]) {
          selectedFeesFirstTable[installment] = {};
        }

        selectedFeesFirstTable[installment][feeTypeName] = parseFloat(balance);
      }
    });

    selectedFeeTypesSecondTable.forEach((feeType) => {
      const balance = nameSecondTable[feeType] || otherFees[feeType].balance;
      selectedFeesSecondTable[feeType] = balance;
    });
    setOpenDialog(true);
    setPaymentData({ ...selectedFeesFirstTable, ...selectedFeesSecondTable });
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      &nbsp;
      <Grid container>
        <Grid xs={12} sm={12}>
          <Box
            padding={1}
            // paddingTop={2}
            display="flex"
            direction="row"
            flexDirection={matches ? "row" : "column"}
            style={{
              border: "1px solid #ff6600", // Set the border style for all sides
              borderLeftWidth: "4px", // Set the left border thickness
              borderRadius: "10px",
              maxWidth: 900,
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              background: "white",
            }}
          >
            <Typography variant="button">
              Total Amount:{" "}
              {otherFees
                ? (
                    otherFees.Total.amount + otherFees.Total.concession
                  ).toLocaleString("en-IN")
                : 0}
            </Typography>
            &nbsp; &nbsp;
            <Typography variant="button">
              Concession Amount:{" "}
              {otherFees
                ? otherFees.Total.concession.toLocaleString("en-IN")
                : 0}
            </Typography>
            &nbsp; &nbsp;
            <Typography variant="button">
              Paid Amount:{" "}
              {otherFees ? otherFees.Total.paid.toLocaleString("en-IN") : 0}
            </Typography>
            &nbsp; &nbsp;
            <Typography variant="button">
              Total Balance :{" "}
              {otherFees
                ? (
                    otherFees.Total.balance - otherFees.Total.concession
                  ).toLocaleString("en-IN")
                : 0}
            </Typography>
          </Box>
        </Grid>
        &nbsp;
        {Object.keys(concessionFeeType).length !== 0 && (
          <Grid xs={12} sm={12}>
            <Box
              // padding={1}
              display="flex"
              direction="row"
              flexDirection={matches ? "row" : "column"}
              style={{
                maxWidth: 700,
              }}
            >
              <Typography variant="body2" color="info">
                <b>Concession Amounts : </b> &nbsp;
              </Typography>
              {Object.entries(concessionFeeType).map(
                ([feeType, concession]) =>
                  concession !== 0 &&
                  concession !== undefined && (
                    <>
                      <Typography variant="caption">
                        <b>
                          {feeType}&nbsp;:&nbsp;
                          {concession === 0
                            ? null
                            : ` ${
                                concession
                                  ? concession.toLocaleString("en-IN")
                                  : ""
                              }   `}
                          &nbsp;
                        </b>
                      </Typography>
                    </>
                  )
              )}
            </Box>
          </Grid>
        )}
        &nbsp;&nbsp;
        {!matchesButton &&
          (selectedFeeTypesFirstTable.length !== 0 ||
            selectedFeeTypesSecondTable.length !== 0) && (
            <Grid xs={12} sm={2}>
              <Button
                onClick={handleProceed}
                variant="contained"
                color="primary"
              >
                Proceed
              </Button>
            </Grid>
          )}
      </Grid>
      <Box
        // padding={1}
        display="flex"
        direction="row"
        spacing={2}
        flexDirection={matches ? "row" : "column"}
      >
        <TableContainer component={Paper}>
          {modifiedObject && (
            <RenderTable
              modifiedObject={modifiedObject}
              handleBalanceChangeFirstTable={handleBalanceChangeFirstTable}
              selectedFeeTypesFirstTable={selectedFeeTypesFirstTable}
              config={config}
              nameFirstTable={nameFirstTable}
              handleFeeTypeCheckboxChangeFirstTable={
                handleFeeTypeCheckboxChangeFirstTable
              }
            />
          )}
        </TableContainer>
        &nbsp; &nbsp;
        {otherFees !== null && (
          <OtherFeesTable
            handleFeeTypeCheckboxChangeSecondTable={
              handleFeeTypeCheckboxChangeSecondTable
            }
            otherFees={otherFees}
            selectedFeeTypesSecondTable={selectedFeeTypesSecondTable}
            balancesSecondTable={balancesSecondTable}
            nameSecondTable={nameSecondTable}
            config={config}
            handleBalanceChangeSecondTable={handleBalanceChangeSecondTable}
          />
        )}
      </Box>
      {matchesButton
        ? (selectedFeeTypesFirstTable.length !== 0 ||
            selectedFeeTypesSecondTable.length !== 0) && (
            <Grid container direction="row">
              <Grid item xs={6} />
              <Grid item xs={4} />
              <Grid item xs={2}>
                <Button
                  onClick={handleProceed}
                  variant="contained"
                  color="primary"
                >
                  Proceed
                </Button>
              </Grid>
            </Grid>
          )
        : null}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle>Summary</DialogTitle>
        <PaymentConfirmDialog
          open={openDialog}
          data={paymentData}
          handleCloseDialog={handleCloseDialog}
        />
      </Dialog>
    </>
  );
};
export default YourComponent;
