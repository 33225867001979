import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useLayoutEffect, useState } from "react";
const useStyle = makeStyles((theme) => ({
  scrollText: {
    overflow: "auto",
    whiteSpace: "initial",
  },
  accordion: {
    background: theme.palette.common.white,
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
}));
function ShowSolution({ solution }) {
  const [solutionText, setSolutionText] = useState("");
  const classes = useStyle();
  useLayoutEffect(() => {
    setSolutionText(solution);
    const math = document.getElementById("solution");
    if (math) {
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, math, () => {}]);
    }
  });

  return (
    <React.Fragment>
      <Accordion classes={{ root: classes.accordion }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color="primary">Solution: </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            id="solution"
            align="left"
            variant="body2"
            color="textPrimary"
            className={classes.scrollText}
            dangerouslySetInnerHTML={{
              __html: `${solutionText}`,
            }}
          />
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}

export default ShowSolution;
