import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import theme from "../../assets/theme";
const useStyle = makeStyles(() => ({
  listItem: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "2%",
    },
  },
}));
function MarksList(props) {
  const classes = useStyle();
  return (
    <React.Fragment>
      <Box
        display="flex"
        flexWrap="nowrap"
        justifyContent="space-between"
        className={classes.listItem}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          style={props.subjectColor}
        >
          {props.subjectName}
        </Typography>
        <Typography variant="body1" style={props.MarksColor}>
          {props.subjectMarks}
        </Typography>
      </Box>
    </React.Fragment>
  );
}

export default MarksList;
