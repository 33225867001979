import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";

const InputBase1 = withStyles({
  input: {
    "&::placeholder": {
      color: "rgba(49, 69, 106, 0.5)",
      marginLeft: "10px",
    },
    // userSelect: "none",
    webkitUserSelect: "none", // For older versions of Safari and Chrome
    mozUserSelect: "none", // For Firefox
    msUserSelect: "none", // For Internet Explorer
    minWidth: "320px",
    paddingLeft: "15px",
    height: "42px",
    boxShadow: "inset 2px 2px 5px #D1D9E6",
    border: "double 1px transparent",
    borderRadius: "12px",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(130deg, #fff, #D1D9E6)",
    backgroundOrigin: "border-box",
  },
})(InputBase);
export default InputBase1;
