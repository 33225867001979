import React from 'react';

// material
import { Typography, makeStyles, SvgIcon } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '10px'
  },
  question: {
    position: 'relative',
    padding: '18px 50px 18px 32px',
    width: '100%',
    color: "rgba(49, 69, 106)",
    boxShadow: "inset 2px 2px 5px #D1D9E6",
    border: "double 1px transparent",
    borderRadius: "12px",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(130deg, #fff, #D1D9E6)",
  },
  questionText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px'
    }
  },
  expandIcon: {
    position: 'absolute',
    top: '20px',
    right: '18px',
    transition: 'all 0.3s ease-in-out'
  },
  answer: {
    opacity: '0',
    margin: '0px',
    paddingLeft: '20px',
    paddingRight: '20px',
    color: theme.palette.text.primary,
    borderTop: '1.2px solid white',
    boxShadow: '-8px -8px 20px #ffffff, 8px 8px 20px #d1d9e6',
    borderLeft: '1.2px solid white',
    borderRadius: '24px',
    transition: 'all 0.3s ease-in-out',
  },
  answerText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    }
  },
  transition: {
    opacity: '1',
    padding: '20px'
  }
}))

const FaqDropDown = ({ question, answer, isActive, id, handleFaqClick }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div
        className={classes.question}
        onClick={() => handleFaqClick(id)}
      >
        <Typography className={classes.questionText}>
          {question}
        </Typography>
        <SvgIcon
          component={ExpandMoreIcon}
          className={classes.expandIcon}
          style={
            isActive ? { transform: 'rotate(-180deg)' } : null
          }
        />
      </div>
      <div
        className={
          isActive ? `${classes.answer} ${classes.transition}` : classes.answer
        }
      >
        <Typography variant={'body1'} className={classes.answerText}>
          {
            isActive ? answer : null
          }
        </Typography>
      </div>
    </div>
  )
}

export default FaqDropDown;