import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import Icon from "@material-ui/core/Icon";
import withStyles from "@material-ui/core/styles/withStyles";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";

const styles = (theme) => ({
  heading: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#31456A",
  },
  text: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "29px",
    fontFeatureSettings: "'liga' off",
    // color: 'rgba(49, 69, 106, 0.7)'
    color: "rgba(35,50,77,0.7)",
  },
  iconShadow: {
    marginTop: "-20%",
    marginBottom: "25px",
    [theme.breakpoints.down("xs")]: {
      // marginBottom: '15px',
      marginTop: "2px",
    },
    textAlign: "center",
    height: "60px",
    width: "60px",
    background: "#EFF2F4",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    borderRadius: "197px",
  },
});

function InfoArea({ ...props }) {
  const { classes, title, description, iconColor, vertical, className } = props;
  // eslint-disable-next-line

  // const iconWrapper = classNames({
  //   [classes.iconWrapper]: true,
  //   [classes[iconColor]]: true,
  //   [classes.iconWrapperVertical]: vertical,
  // });
  const iconClasses = classNames({
    [classes.icon]: true,
    [classes.iconVertical]: vertical,
  });
  // eslint-disable-next-line

  // const infoAreaClasses = classNames({
  //   [classes.infoArea]: true,
  //   [className]: className !== undefined,
  // });
  let icon = null;
  switch (typeof props.icon) {
    case "string":
      icon = <Icon className={iconClasses}>{props.icon}</Icon>;
      break;
    // default:
    //   icon = <props.icon className={iconClasses} />;
    //   break;
  }
  return (
    // <div className={infoAreaClasses}>

    //   <div className={iconWrapper}>{icon}</div>
    //   <div className={classes.descriptionWrapper}>
    //     <h4 className={classes.heading}>{title}</h4>
    //     <div className={classes.description}>{description}</div>
    //   </div>
    // </div>
    <Grid container direction="row" justify="center">
      <Grid item xs={10} sm={2}>
        {/* {icon} */}
        <div className={classes.iconShadow}>
          {props.icon ? (
            <img
              alt="icon"
              src={props.icon}
              style={{
                marginTop: "25%",
              }}
            />
          ) : (
            <React.Fragment>{props.image}</React.Fragment>
          )}
        </div>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container direction="column">
          <Grid item xs={9} className={classes.heading}>
            {title}
            <br />
            <br />
          </Grid>
          <Grid item xs={12}>
            <span className={classes.text}>{description}</span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

InfoArea.defaultProps = {
  iconColor: "gray",
};

InfoArea.propTypes = {
  classes: PropTypes.object.isRequired,
  // icon: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  // title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  iconColor: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  vertical: PropTypes.bool,
  className: PropTypes.string,
};

export default withStyles(styles)(InfoArea);
