import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Xarrow from 'react-xarrows';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
  },
  imageWrapper: {
    position: 'relative',
  },
  image: {
    width: '300px',
    height: '200px',
  },
  messageBox: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imageWrapper}>
        <img
          id="image"
          src="https://via.placeholder.com/300x200"
          alt="Sample"
          className={classes.image}
        />
        <Paper id="message" className={classes.messageBox}>
          <Typography variant="body2">Look here!</Typography>
        </Paper>
        <Xarrow start="image" end="message" />
      </div>
    </div>
  );
};

export default App;
