import { recordAttendance } from "apis/videoService";
import React, { Fragment, useEffect, useState } from "react";
import store from "store";
import { zoomSignature } from "../../apis/videoService";
import constants from "../../constants";
const credentials = {
  "5e7370083d8520a40c4ceaaa": {
    ZOOM_APIKey: "wvbNv8fsTiqEmvrL0Bz4kQ",
    ZOOM_APISecret: "xyme1EfByoS7kJxSWVEsgiN7hfpae5QlNGiF",
  },
  "61e850055876f313e30ea1de": {
    ZOOM_APIKey: "7M1pcQtuT3esxN22nMPPMA",
    ZOOM_APISecret: "EE6gsKQSWNnVU8L1kTioOGl2RKDlccidjj9T",
  },
  "5ee85fc60f25a93146ea0bf9": {
    ZOOM_APIKey: "oTMwrovhR4m9Wl8ofllkw",
    ZOOM_APISecret: "74yt7JdSpAeLxhHiIwt7HnX7XLOy8wgp",
  },
  "5dc1388b22bdc209de6a4d80": {
    ZOOM_APIKey: "jX9mjaXdSTS97CC6GB1kFQ",
    ZOOM_APISecret: "q206DOZpRleTMvU0TIbzFVUxocNCzq19",
  },
  "5e9098aaa218252d49be5a3b": {
    ZOOM_APIKey: "fP0DxPcCRHS22dKMWPlnYg",
    ZOOM_APISecret: "QAgH5MCZOz5qMz7HZhIpSMOCXs1XLLLL",
  },
  "65772522ee64cfa8dc9851f2": {
    ZOOM_APIKey: "Yld8wVmQQOyfIjlWRbWj_Q",
    ZOOM_APISecret: "AnS5EEl8OrG5o54e7AQVo1u6P952u8TI",
  },
  "5d679d49c136660a09596d85": {
    ZOOM_APIKey: "Yld8wVmQQOyfIjlWRbWj_Q",
    ZOOM_APISecret: "AnS5EEl8OrG5o54e7AQVo1u6P952u8TI",
  },
  "5ee85fc60f25a93146ea0s01n": {
    ZOOM_APIKey: "F9fexz9_S6u_mNC00FkjXQ",
    ZOOM_APISecret: "qeTURltw8m0aJnxHoW1T1MOez7Gkhs3e",
  },
};
const ZoomLiveMeetingApp = (props) => {
  const { state } = props.location;
  const instituteId =
    store.get("user").institute || store.get("user").instituteId;
  const { data } = state;
  const [zoomMtg, setZoomMtg] = useState({});

  const getAttendance = async (flipFlag) => {
    let userId = store.get("user") ? store.get("user").userId : undefined;
    let liveUrl = data.url;

    let dataS = {
      userId,
      liveUrl,
      flipFlag: flipFlag,
    };
    return recordAttendance(dataS);
  };

  const joinMeeting = async () => {
    import("@zoomus/websdk").then(async (zoom) => {
      const ZoomMtg = zoom.ZoomMtg;
      const sdkKey = instituteId
        ? credentials[instituteId].ZOOM_APIKey
        : constants.ZOOM_SDK_KEY;
      setZoomMtg(ZoomMtg);
      const response = await zoomSignature({
        meetingNumber: data.meetingId,
      });
      document.getElementById("zmmtg-root").style.display = "block";
      ZoomMtg.setZoomJSLib("https://source.zoom.us/2.17.0/lib", "/av");
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();
      ZoomMtg.i18n.load("en-US");
      ZoomMtg.i18n.reload("en-US");

      ZoomMtg.init({
        leaveUrl: "/video-classes",
        isSupportChat: true,
        disableInvite: true,
        meetingInfo: ["topic"],
        success: function() {
          console.log("hello");
          ZoomMtg.showInviteFunction({ show: false });
          ZoomMtg.join({
            meetingNumber: data.meetingId,
            userName: `${store.get("user").name}(${store.get("user").userId})`,
            userEmail: store.get("user").email,
            signature: response.signature,
            sdkKey: sdkKey,
            passWord: data.password,
            tk: "",
            success() {
              ZoomMtg.showPureSharingContent({
                show: false,
              });
              console.log("hello");
              console.log("join meeting success", 145);
              getAttendance(true);
            },
            error(res) {
              console.log(res);
              document.getElementById("zmmtg-root").style.display = "none";
            },
          });
        },
        error: function(er) {
          console.log("hello", er);
        },
      });
    });
  };

  useEffect(() => {
    joinMeeting();
    return () => {
      getAttendance(false);
      if (zoomMtg && zoomMtg.hasOwnProperty("leaveMeeting")) {
        zoomMtg.leaveMeeting({ leaveUrl: window.location.href });
      }
    };
  }, []);

  return <Fragment />;
};

export default ZoomLiveMeetingApp;
