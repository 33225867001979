export const subColors = {
  MATHEMATICS: {
    color: "#197BBD",
  },

  PHYSICS: {
    color: "#FFAB00",
  },

  CHEMISTRY: {
    color: "#ED3B64",
  },

  BOTANY: {
    color: "#9C27B0",
  },
  ZOOLOGY:{
    color:"green",
  }
};
export const avgColors = {
  MATHEMATICSAVG: {
    color: "#ff00ff",
  },

  PHYSICSAVG: {
    color: "#00ff00",
  },

  CHEMISTRYAVG: {
    color: "#00ffff",
  },

  BIOLOGYAvg: {
    color: "#9C27B0",
  },
  BOTANYAVG:{
    color:"black",
  },
  ZOOLOGYAVG:{
    color:"brown",
  }
};
