import * as actionType from "../../actions/practicePageActions/actions";

// list of questions reducer (solved or unsolved)
const INITIAL_STATE_ALL_QUESTIONS = {
  isLoading: false,
  data: [],
  error: null,
};
export const PracticeQuestionsReducer = (
  practice = INITIAL_STATE_ALL_QUESTIONS,
  action
) => {
  switch (action.type) {
    case actionType.FETCH_QUESTIONS_LOADING:
      return {
        ...practice,
        isLoading: true,
      };
    case actionType.FETCH_QUESTIONS_SUCCESS:
      return {
        ...practice,
        isLoading: false,
        data: action.payload,
      };
    case actionType.CATCH_ERROR_QUESTIONS:
      return {
        ...practice,
        isLoading: false,
        error: action.payload,
      };
    case actionType.CLEAR_ERROR_QUESTIONS:
      return {
        ...practice,
        isLoading: false,
        error: null,
        data: [],
      };
    default:
      return practice;
  }
};
