import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React from "react";
import { PaperComponent } from "./PaperComponent";

function CheckboxField(props) {
  return (
    <React.Fragment>
      <PaperComponent>
        <FormControlLabel
          disabled={props.disabled}
          value={props.keyName}
          control={<Checkbox onChange={props.onChange} color="primary" />}
          label={
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: `${props.label}`,
              }}
            />
          }
          className={props.className}
        />
      </PaperComponent>
    </React.Fragment>
  );
}

export default CheckboxField;
