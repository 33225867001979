import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VpnKeyRoundedIcon from "@material-ui/icons/VpnKeyRounded";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import examAxios from "../apis/examServerAxios";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
const style = {
  background: "#08C5A7",
  textTransform: "capitalize",
};
const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

function PasswordChange(props) {
  // const anchorRef = React.createRef();
  const { classes } = props;
  let user = store.get("user") ? store.get("user") : {};
  let mockUser = true;
  let pwdMatched = store.get("matched");
  mockUser = user._id && user._id.includes("mocktest_");
  // let flag = false;

  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [old, setOld] = useState(null);
  const [, setOpen] = useState(false);
  const [showOld, setShowOld] = useState(false);
  const [newPass, setNewPass] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const [match, setMatch] = useState(true);
  const [netReq, setNetReq] = useState(false);
  const handleChange = (key, event) => {
    switch (key) {
      case "old":
        setOld(event.target.value);
        break;
      case "new":
        setNewPass(event.target.value);
        break;
      case "confirm":
        setConfirm(event.target.value);
        break;
    }
    setMatch(true);
  };
  const submit = (event) => {
    // console.log(old, newPass, confirm);
    setMatch(true);
    setErrMsg("");
    if (newPass != confirm || newPass === old) {
      setMatch(false);
      event.preventDefault();
      return;
    }
    const body = {
      oldPassword: old,
      newPassword: newPass,
      confirm: confirm,
    };
    const url = "/users/" + store.get("user")._id;
    setNetReq(true);
    setSuccessMsg("");
    examAxios
      .put(url, body)
      .then((body) => {
        console.log(body);
        if (body.status == 200) {
          setSuccessMsg("Updated successfully !!");
          store.set("matched", false);
          setTimeout(() => {
            setOpen(false);
          }, 4000);
        }
        setNetReq(false);
      })
      .catch((err) => {
        setNetReq(false);
        setErrMsg(err.response.data.message);
      });

    event.preventDefault();
  };
  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal + " " + classes.modalLogin,
      }}
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="login-modal-slide-title"
      aria-describedby="login-modal-slide-description"
    >
      <Card plain className={classes.modalLoginCard}>
        <form onSubmit={submit}>
          <DialogTitle
            id="login-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          />
          <DialogContent
            id="login-modal-slide-description"
            className={classes.modalBody}
          >
            {pwdMatched && (
              <React.Fragment>
                <Typography align="center" style={{ color: style.background }}>
                  Successfully LoggedIn!
                </Typography>
                <Typography align="center" style={{ color: style.background }}>
                  Your userId and Password are similar!
                </Typography>
              </React.Fragment>
            )}
            <p
              style={{
                textAlign: "center",
                fontWeight: "300",
                fontSize: "22px",
              }}
              className={`${classes.description} ${classes.textCenter}`}
            >
              Change your password !!
            </p>
            <CardBody className={classes.cardLoginBody}>
              <CustomInput
                id="login-modal-pass"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyRoundedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => setShowOld(!showOld)}
                    >
                      {!showOld ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </InputAdornment>
                  ),
                  placeholder: "Old Password",
                  value: old,
                  type: showOld ? "text" : "password",
                  onChange: (e) => handleChange("old", e),
                }}
              />
              <CustomInput
                id="login-modal-pass"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                  placeholder: "New Password",
                  value: newPass,
                  type: "password",
                  onChange: (e) => handleChange("new", e),
                }}
              />
              <CustomInput
                id="login-modal-pass"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  placeholder: "Confirm Password",
                  value: confirm,
                  type: "password",
                  onChange: (e) => handleChange("confirm", e),
                }}
              />
              {!match && newPass != old && (
                <div style={{ color: "red" }}>
                  New & confirm passwords should match <br />
                </div>
              )}
              {newPass === old && !match && (
                <Typography color="error" variant="body2">
                  Please choose a different password than your previous one!
                </Typography>
              )}

              {<span style={{ color: "red" }}>{errMsg}</span>}

              {<span style={{ color: "green" }}>{successMsg}</span>}
            </CardBody>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center", display: "grid" }}>
            {netReq && (
              <CircularProgress
                size={30}
                color="primary"
                style={{ textAlign: "center", color: "green" }}
              />
            )}
            {!netReq && !successMsg.includes("success") ? (
              <Button style={style} round type="submit">
                Change Now
              </Button>
            ) : (
              <Button style={style} round onClick={props.handleClose}>
                Close
              </Button>
            )}
          </DialogActions>
        </form>
      </Card>
    </Dialog>
  );
}

export default compose(
  withRouter,
  withStyles(headerLinksStyle, javascriptStyles)
)(PasswordChange);
