import * as actionType from "./actions";

// fetch result details
export const fetchResultDetails = (body) => ({
  type: actionType.FETCH_RESULT,
  body,
});
export const fetchResultSuccess = (results) => ({
  type: actionType.FETCH_RESULT_SUCCESS,
  payloadResult: results
});
// fetch time spent analysis
export const fetchTimeSpentAnalysisSuccess = (timeSpent) => ({
  type: actionType.FETCH_TIME_SPENT_SUCCESS,
  payload: timeSpent,
});
// fetch all subjects
export const fetchAllSubjects = (subjects) => ({
  type: actionType.ALL_SUBJECTS,
  payload: subjects,
});
// fetch rank analysis
export const fetchRankAnalysis = (body) => ({
  type: actionType.FETCH_RANK,
  body,
});
export const fetchRankAnalysisSuccess = (ranks) => ({
  type: actionType.FETCH_RANK_SUCCESS,
  payload: ranks,
});

// fetch compare question details
export const fetchCompareQuesDetails = (body) => ({
  type: actionType.FETCH_COMPARISON_QUESTIONS,
  body,
});
export const fetchCompareQuesDetailsSuccess = (quesDetails) => ({
  type: actionType.FETCH_COMPARISON_QUESTIONS_SUCCESS,
  payload: quesDetails,
});
// error handling
export const catchErrorResult = (error) => ({
  type: actionType.FETCH_RESULT_ERROR,
  payloadResult: error,
});
export const catchErrorTimeSpent = (error) => ({
  type: actionType.FETCH_TIME_SPENT_ERROR,
  payloadTimeSpent: error,
});
export const catchErrorRank = (error) => ({
  type: actionType.FETCH_RANK_ERROR,
  payload: error,
});
export const catchErrorCompareQuestion = (error) => ({
  type: actionType.FETCH_COMPARISON_QUESTIONS_ERROR,
  payload: error,
});

// clear action
export const clearResult = () => ({
  type: actionType.CLEAR_RESULT,
});
export const clearRank = () => ({
  type: actionType.CLEAR_RANK,
});
export const clearCompareQuestion = () => ({
  type: actionType.CLEAR_COMPARE_QUESTIONS,
});

// STUDENT behaviour
// fetch student behaviour
export const fetchStudentBehaviourStart = (payload) => ({
  type: actionType.FETCH_STUDENT_BEHAVIOUR,
  payload
});

export const fetchStudentBehaviourSuccess = (payload) => ({
  type: actionType.FETCH_STUDENT_BEHAVIOUR_SUCCESS,
  payload
});

export const fetchStudentBehaviourFailure = (payload) => ({
  type: actionType.FETCH_STUDENT_BEHAVIOUR_ERR,
  payload
});
