
import React, { useRef, useEffect, useState } from "react";
import { css } from "@emotion/react";
import bg from "./logos/Why choose us bg.png";

import Fearures from "./logos/Fearures bg.png";
const styles = (theme) => ({
    myText: css`
      width: 100%;
      height: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-word;
      font-weight: 900;
      font-style: normal;
      font-family: "Montserrat", sans-serif;
      color: #ffffff;
      font-size: 36px;
      letter-spacing: 0px;
      line-height: 70px;
      text-align: left;
      padding-left: 20px;
    `,
    subMyText: css`
      width: 558px;
      height: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-word;
      font-weight: 500;
      font-style: normal;
      font-family: "Montserrat", sans-serif;
      color: #ffffff;
      font-size: 17px;
      letter-spacing: 0px;
      line-height: 1.2;
  
      padding-left: 20px;
    `,
    empowerText: css`
      width: 558px;
      height: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-word;
      font-weight: 500;
      font-style: normal;
      font-family: "Montserrat", sans-serif;
      color: #ffffff;
      font-size: 17px;
      letter-spacing: 0px;
    `,
  
    empowerMangBox: css`
      box-sizing: border-box;
      flex: 1;
      width: fit-content;
      height: 100%;
      display: flex;
  
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 8px 8px 8px;
      background: linear-gradient(
        102.43674125762817deg,
        rgba(23, 33, 51, 0.5) 0%,
        rgba(23, 33, 51, 1) 100%
      );
      overflow: visible;
      transform: "translateY(0)";
      opacity: 1;
      transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out";
      align-content: center;
      display: flex;
      flex-wrap: wrap;
      gap: 40;
      border-radius: 24px;
      border: 1px solid #000000;
    `,
    enhanceBox: css`
      box-sizing: border-box;
      flex: 1;
      width: fit-content;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      transform: "translateY(0)";
      opacity: 1;
      transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out";
      align-items: center;
      padding: 8px 8px 8px 8px;
      background: linear-gradient(
        102.43674125762817deg,
        rgba(23, 33, 51, 0.5) 0%,
        rgba(23, 33, 51, 1) 100%
      );
      overflow: visible;
      align-content: center;
      display: flex;
      flex-wrap: wrap;
      gap: 40;
      border-radius: 24px;
      border: 1px solid #000000;
    `,
    
    slideUp: {
      transform: "translateY(0)",
      opacity: 1,
    },
    manageBg: {
      background: "rgba(236, 240, 243, 0.8)",
      // transform: "matrix(1, 0, 0, -1, 0, 0)",
      minHeight: "120%",
    },
    studentBg: {
      background: "#FAFAFA",
      minHeight: "120%",
      backgroundImage: `url("${bg}")`,
    },
    heading: {
      marginBottom: "20px",
      fontStyle: "normal",
      fontWeight: "800",
      fontSize: "36px",
      lineHeight: "44px",
      color: "#31456A",
    },
    infoAreaMargin: {
      marginBottom: "90px",
      [theme.breakpoints.down("md")]: {
        marginBottom: "80px",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "70px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "60px",
      },
    },
    studentImg: {
      width: "450px",
      marginLeft: "-15%",
      marginTop: "-15%",
      [theme.breakpoints.down("md")]: {
        width: "120%",
        transform: "scale(1)",
      },
      [theme.breakpoints.down("sm")]: {
        transform: "scale(1)",
      },
      [theme.breakpoints.down("xs")]: {
        transform: "scale(1.25)",
      },
    },
    manageImg: {
      width: "620px",
      [theme.breakpoints.down("xs")]: {
        transform: "scale(1.25)",
      },
      [theme.breakpoints.down("sm")]: {
        transform: "scale(1)",
      },
      [theme.breakpoints.down("md")]: {
        width: "110%",
        transform: "scale(1)",
      },
    },
    sendMessage: {
      textTransform: "none",
      height: "56px",
      width: "200px",
      background: "#08C5A7",
      boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
      borderRadius: "16px",
      // fontFamily: 'Montserrat',
  
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "100%",
      /* or 18px */
      textAlign: "center",
      color: "#FFFFFF",
      "&:hover": {
        background: "#0eab8f",
        transform: "scale(1.5)",
      },
    },
    appScreenShot: {
      // boxSizing: "border-box",
      // width: "auto",
      animation: "$move 1s ease-in",
      backgroundImage: `url("${Fearures}")`, // Set the SVG image as the background
      // maxWidth:"1400px",
      // animation: "$txt 1s ease-in-out",
      height: "auto",
      display: "block",
      alignItems: "center",
      padding: "14px",
      // margin: 4,
      // marginTop: "4px",
      // WebkitBackdropFilter: "blur(3px)",
      // backdropFilter: "blur(3px)",
      // boxShadow: "0px 8px 120px 0px rgba(11, 29, 62, 0.1)",
      // backgroundColor: "#0f0d3b",
      overflow: "visible",
      // position: "absolute",
      // right:"4%",
      // left:"3%",
      // marginRight: "50px",
      // paddingRight: "30px",
      marginLeft: "4%",
      marginRight: "4%",
      borderRadius: "24px",
      // border: "3px solid #3bbded",
      transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
      "&:hover": {
        transform: "scale(1.1)",
        margin: "50px",
      },
    },
    "@keyframes move": {
      "0%": {
        transform: "scale(0.8)", // End with a smaller scale
      },
      "100%": {
        transform: "scale(0.7)", // Start with a larger scale
      },
      // '0%': {
      //   transform: 'translateX(100%)',
      // },
      // '100%': {
      //   transform: 'translateX(-100%)',
      // },
    },
    slideUp: {
      transform: "translateY(0)",
      opacity: 1,
      transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
    },
  });
  export default styles;