import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
import { Clear, Save } from "@material-ui/icons";
import React from "react";
import InputIntegerPanel from "../../InputIntegerPanel";

const opt = ["A", "B", "C", "D", "E"];

const useStyles = makeStyles((theme) => ({
  int: {
    padding: "10px",
    height: "40px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
}));

const HandlingUserAnswerByType = ({
  question,
  saveAnswer,
  handleSingleAnswer,
  handleIntegerAnswer,
  handleMultipleAnswer,
  clear,
}) => {
  const classes = useStyles();
  //------------------mapping options from array------------------
  const OptionMap = opt.slice(0, question.noOfOptions).map((i) => i);
  const isChecked = (option) => {
    if (question.markedAnswer == "NA") {
      return false;
    }
    return question.markedAnswer.includes(option);
  };
  if (question.q_type === "integer") {
    window.parent.postMessage("integer_input");
  } else {
    window.parent.postMessage("other");
  }
  return (
    <Grid container alignItems="center">
      <Grid item sm={7} xs={12}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          wrap="nowrap"
        >
          {question.q_type === "single" ? (
            <RadioGroup
              value={
                question.markedAnswer == undefined ? "" : question.markedAnswer
              }
              onChange={handleSingleAnswer}
              style={{ flexWrap: "nowrap" }}
              row
            >
              {OptionMap.map((option) => {
                return (
                  <FormControlLabel
                    checked={question.markedAnswer == option}
                    labelPlacement="top"
                    style={{ color: "black" }}
                    value={option}
                    control={
                      <Radio size="small" style={{ color: "#3BBDED" }} />
                    }
                    label={option}
                  />
                );
              })}
            </RadioGroup>
          ) : null}
          {["multiple", "partial"].includes(question.q_type)
            ? OptionMap.map((opt) => (
                <FormControlLabel
                  labelPlacement="top"
                  style={{ color: "black" }}
                  checked={question.markedAnswer == opt}
                  key={question.question_number + "_" + opt}
                  value={opt}
                  control={
                    <Checkbox
                      size="small"
                      style={{ color: "#3BBDED" }}
                      checked={isChecked(opt)}
                      onChange={(e) => handleMultipleAnswer(e, opt)}
                    />
                  }
                  label={opt}
                />
              ))
            : null}
          {question.q_type === "integer" ? (
            <Grid item className={classes.int}>
              <InputIntegerPanel
                markedanswer={
                  question.markedAnswer == "NA" ? "" : question.markedAnswer
                }
                setIntegerValue={handleIntegerAnswer}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item sm={5} xs={12}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Button
            size="small"
            variant="outlined"
            startIcon={<Save fontSize="small" color="primary" />}
            onClick={saveAnswer}
            style={{ margin: "1% 0 1% 0" }}
          >
            Save
          </Button>
          &emsp; &emsp;
          <Button
            size="small"
            variant="outlined"
            startIcon={<Clear />}
            onClick={clear}
            style={{ margin: "1% 0 1% 0" }}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default HandlingUserAnswerByType;
