import React, { useEffect } from "react";
import {
  Grid,
  useMediaQuery,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { motion, useAnimation } from "framer-motion";
import svgImag from "./logos/hero frame.svg";
import cover from "assets/v2/cover.png";
import sriabida from "./logos/sriabida.png";
import edify from "./logos/edify.png";
import trurito from "./logos/turito.png";
import vinners from "./logos/vinners.png";
import lp from "./logos/lp.png";
import miity from "./logos/miity.png";

const styles = (theme) => ({
  backgroundDiv: {
    width: "100%",
    height: "1050px",

    backgroundImage: `url("${svgImag}")`,
    backgroundSize: "cover",
    position: "relative",
  },
  tryFreeButton: {
    background: "#08C5A7",
    boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
    borderRadius: "12px",
    textTransform: "none",
    height: "50px",
    width: "170px",
    fontWeight: "600",
    fontSize: "16px",
    textAlign: "center",
    color: "#FFFFFF",
    marginRight: "80px",
    "&:hover": {
      background: "#0eab8f",
      transform: "scale(1.5)",
    },
  },
  textContent: {
    width: "100%",
    marginTop: "30px",
    height: "150px",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    wordBreak: "break-word",
    fontWeight: 900,
    fontStyle: "normal",
    animation: "$txt 1s ease-in-out",
    fontFamily: "Georgia",
    color: "#FFFF",
    fontSize: "50px",
    letterSpacing: "2px",
    lineHeight: "80px",
    textAlign: "center",
  },
  logsHeading: {
    width: "100%",
    height: "100px",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    wordBreak: "break-word",
    fontWeight: 900,
    fontStyle: "normal",
    animation: "$txt 1s ease-in-out",
    fontFamily: "Georgia",
    color: "#31456A",
    fontSize: "40px",
    letterSpacing: "2px",
    lineHeight: "40px",
    textAlign: "center",
  },
  appScreenShot: {
    width: "100%",
    animation: "$move 4s ease-out",
    maxWidth: "1400px",
    margin: "20px",
    // height: "540px",
    height: "auto", // Adjust height to auto for responsiveness
    display: "block",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%", // Ensure the screenshot fits within the screen on smaller devices
    },
    "&:hover": {
      transform: "scale(0.4)",
    },
  },
  "@keyframes txt": {
    "0%": {
      transform: "scale(1)",
    },
    "100%": {
      transform: "scale(0.9)",
    },
  },
  "@keyframes move": {
    "0%": {
      transform: "scale(0.9)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  "@keyframes slideIn": {
    "0%": {
      opacity: 0,
      transform: "translateX(100%)",
    },
    "100%": {
      transform: "translateX(-100%)",
    },
  },
  "@keyframes slideOut": {
    "0%": {
      opacity: 0,
      transform: "translateX(-100%)",
    },
    "100%": {
      transform: "translateX(100%)",
    },
  },
});

function SectionLanding(props) {
  const { classes } = props;
  const matches = useMediaQuery("(max-width:500px)");
  const [showBox, setShowBox] = React.useState(false);

  const handleButtonClick = () => {
    window.scrollTo({ top: 7000, behavior: "smooth" });
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowBox(true);
    } else {
      setShowBox(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const controls = useAnimation();

  useEffect(() => {
    const animateLogos = async () => {
      while (true) {
        await controls.start({
          x: [0, -1000], // Adjust the value -1000 based on the total width of your logos
          transition: {
            duration: 10, // Adjust the duration as needed
            ease: "linear",
          },
        });
        controls.set({ x: 1000 }); // Reset position
      }
    };
    animateLogos();
  }, [controls]);

  const logos = [
    "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/bhashyam_mini.png",
    "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/sr_mini.png",
    "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/tirumala_mini.png",
    sriabida,
    edify,
    miity,
    vinners,
    lp,
    trurito,
  ];
  const logoStyles = {
    "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/bhashyam_mini.png": {
      width: "100px",
      height: "100px",
    },
    "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/sr_mini.png": {
      width: "90px",
      height: "90px",
    },
    "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/tirumala_mini.png": {
      height: "100px",
      width: "100px",
    },
    [sriabida]: { width: "220px", height: "100px" },
    [edify]: {
      width: "130px",
      height: "75px",
      alignContent: "center",
      alignItems: "center",
      marginTop: "20px",
      paddingTop: "20px",
      backgroundRepeat: "none",
    },
    [miity]: { width: "140px", height: "100px" },
    [vinners]: { width: "70x", height: "100px" },
    [lp]: { width: "160px", height: "100px" },
    [trurito]: { width: "150px", height: "70px" },
  };
  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF", top: "50px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12} className={classes.backgroundDiv}>
            <Grid item xs={12} container md={12} sm={12}>
              <div className={classes.textContent}>
                Revolutionize Education
                {!matches ? <br /> : null}
                <span> Management With Our </span>
                <b style={{ color: "#3BBDED" }}> Onesaz </b>
                {!matches ? <br /> : null}
                ERP Solutions
                <Typography
                  style={{
                    marginTop: "10px",
                    fontSize: "20px",
                  }}
                >
                  Transform the way educational institutions operate with our
                  cutting-edge ERP solutions tailored for the ed tech industry.
                </Typography>
                <Button
                  className={classes.tryFreeButton}
                  onClick={handleButtonClick}
                >
                  Try it Now
                </Button>
                {showBox && (
                  <div
                    className={classes.appScreenShot}
                    style={{
                      transform: "scale(0.9)",
                      opacity: 1,
                      width: "1300px",
                      height: "562px",
                      margin: "auto",
                      borderRadius: "24px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "24px",
                      }}
                      className={classes.talkBubble}
                    >
                      <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/TNqBmnYhBv4"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{ borderRadius: "24px" }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginTop: matches ? "70px" : "70px", marginBottom: "50px" }}
        >
          <div>
            <div className="App">
              <motion.div
                className="carousel"
                animate={controls}
                style={{ display: "flex", width: "100%", overflow: "hidden" }}
              >
                <motion.div
                  className="inner-carousel"
                  style={{
                    display: "flex",
                    width: "200%",
                  }}
                >
                  {logos.concat(logos).map((logo, index) => (
                    <motion.div
                      className="item"
                      key={index}
                      style={{
                        minWidth: "10%",
                        padding: "10px",
                      }}
                      whileHover={{ scale: 1.2 }}
                      whileTap={{ scale: 2 }}
                      transition={{ duration: 0.1 }}
                    >
                      <Box style={{ margin: "20px" }}>
                        <img src={logo} alt="logo" style={logoStyles[logo]} />
                      </Box>
                    </motion.div>
                  ))}
                </motion.div>
              </motion.div>
            </div>
          </div>
        </Grid>
      </div>
    </>
  );
}

export default withStyles(styles)(SectionLanding);
