import {
  Grid,
  Button,
  Typography,
  makeStyles,
  Box,
  Hidden,
} from "@material-ui/core";
import { capitalize } from "lodash";
import React from "react";
import store from "store";
import Fieldset from "../Fieldset";

const useStyle = makeStyles((theme) => ({
  subTopic: {
    width: "52px",
    height: "64px",
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: "64px",
  },
  btn: {
    height: "64px",
    verticalAlign: "middle",
    lineHeight: "64px",
  },
  gridStyle: {
    borderTop: `0.5px solid ${theme.palette.background.default}`,
    paddingLeft: "5em",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
    },
  },
}));
const SubtopicList = ({ subtopic, index, ...props }) => {
  const classes = useStyle();
  return (
    <React.Fragment>
      <Grid container className={classes.gridStyle} spacing={1}>
        <Hidden xsDown>
          <Grid item xs={1}>
            <Typography
              className={classes.subTopic}
              variant="subtitle2"
              color="textSecondary"
            >
              {index + 1}
            </Typography>
          </Grid>
        </Hidden>
        <Grid item xs={7} sm={8}>
          <Fieldset value={props.percentage}>
            <Typography className={props.textScrollClass} variant="subtitle2">
              {capitalize(subtopic)}
            </Typography>
          </Fieldset>
        </Grid>

        <Grid item container xs={3}>
          <Box className={classes.btn}>
            {store.get(
              "practice" + store.get("practiceSubject") + "subTopic"
            ) === subtopic ? (
              <Button
                disableRipple
                size="small"
                color="primary"
                variant="contained"
                onClick={props.handleClick}
              >
                Resume
              </Button>
            ) : (
              <Button
                disableRipple
                size="small"
                color="primary"
                variant="outlined"
                onClick={props.handleClick}
              >
                Start
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SubtopicList;
