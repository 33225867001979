import * as actionType from "../../actions/notificationsAction/actions";

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: null,
};
export const NotificationListReducer = (
  notifications = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionType.FETCH_NOTIFICATIONS_LOADING:
      return {
        ...notifications,
        isLoading: true,
      };
    case actionType.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...notifications,
        isLoading: false,
        data: action.payload,
      };
    case actionType.CATCH_ERROR_NOTIFICATIONS:
      return {
        ...notifications,
        isLoading: false,
        error: action.payload,
      };
    case actionType.CLEAR_ERROR_NOTIFICATIONS:
      return INITIAL_STATE;
    default:
      return notifications;
  }
};
