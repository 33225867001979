import * as actionType from "../../actions/practicePageActions/actions";

export const SaveQueryReducer = (query = {}, action) => {
  switch (action.type) {
    case actionType.SAVE_QUERY_TO_STORE:
      return (query = action.query);
    case actionType.CLEAR_QUERY_FROM_STORE:
      return {};
    default:
      return query;
  }
};
