import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Checkbox,
  TextField,
  Box,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Dialog,
  Card,
  CardContent,
} from "@material-ui/core";
import {
  CustomTableRow,
  CustomTableCellType3,
} from "../../components/CustomTableCells";
import { useStyles } from "../../Dashboard/Components/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import startCase from "lodash/startCase";
import PaymentConfirmDialog from "../PaymentConfirmDialog";
import { useStyles4, Transition } from "../styles";
const RenderTableHeader = ({modifiedObject}) => {
    const classes = useStyles();
    const classes1 = useStyles4();
    const totalInstallments = Object.keys(modifiedObject).length;
    const totalFeeTypes = Object.keys(modifiedObject).reduce(
      (total, installmentKey) => {
        return total + Object.keys(modifiedObject[installmentKey]).length;
      },
      0
    );

    return (
      <>
        <CustomTableRow >
          <CustomTableCellType3
            className={classes.tableHeaderStyle}
            rowSpan={totalFeeTypes > 0 ? totalInstallments * totalFeeTypes : 1}
          >
            Installments
          </CustomTableCellType3>
        </CustomTableRow>
        <CustomTableRow>
          <CustomTableCellType3 className={classes.tableHeaderStyle}>
            Fee Type
          </CustomTableCellType3>
          <CustomTableCellType3 className={classes.tableHeaderStyle}>
            Amount
          </CustomTableCellType3>
          <CustomTableCellType3 className={classes.tableHeaderStyle}>
            Balance
          </CustomTableCellType3>
          <CustomTableCellType3 className={classes.tableHeaderStyle}>
            Paid
          </CustomTableCellType3>
          <CustomTableCellType3 className={classes.tableHeaderStyle}>
            pay Amount
          </CustomTableCellType3>
        </CustomTableRow>
      </>
    );
  };
export default RenderTableHeader;