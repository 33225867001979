import React,{ forwardRef } from "react";

const OnlinePaymentReq = forwardRef(({ postUrl, merchantId, reqData }, ref) => (
  <form
    ref={ref}
    method="POST"
    id="payment-form"
    action={postUrl}
    style={{ display: "none" }}
    onSubmit={() => {
      console.log("i am fired");
    }}
  >
    <div>
      <input name="merchantId" defaultValue={merchantId} type="text" required />
    </div>
    <div>
      <input name="reqData" defaultValue={reqData} type="text" required />
    </div>
    <input type="submit" />
  </form>
));

export default OnlinePaymentReq;