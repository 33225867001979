import { Grid, Hidden, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as MenuIcon } from "../../assets/img/menu-alt-right.svg";
import theme from "../../assets/theme";
import MarkedAndTotal from "./MarkedAndTotal";
import MyStatistics from "./MyStatistics";
import {
  BotanyIcon,
  ChemIcon,
  MathsIcon,
  PhyIcon,
  ZoologyIcon,
} from "./SubjectIcons";
import { SubjectTab, SubjectTabs } from "./SubjectTabs";
import { CustomListItemIcon, CustomSelect } from "./SubjectTabsMobile";
import TimeSpentTagging from "./TimeSpentTagging";
const useStyles = makeStyles(() => ({
  container: {
    // padding: "0 20% 0 20%",
    width: "100%",
  },
  "@media(max-width: 1024px)": {
    container: {
      // padding: "0 2% 0 2%",
    },
  },
  menuName: {
    verticalAlign: "top",
  },
  ansHead: {
    background: theme.palette.common.white,
    padding: "20px",
  },
}));
function MyAnswers() {
  const [value, setValue] = useState(0);
  const [finalResult, setFinalResult] = useState([]);
  const [dataMobileDevice, setDataMobileDevice] = useState([]);
  const [timeTag, setTimeTag] = useState({});
  const result = useSelector((state) => state.allResult);
  const rankAnalysis = useSelector((state) => state.rankAnalysis);
  const { data = {} } = rankAnalysis;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTimeTag({});
  };
  const [state, setState] = useState({
    key: 0,
  });

  const handleMobileChange = (event) => {
    setState({
      ...state,
      [event.target.name]: Number(event.target.value),
    });
    setTimeTag({});
  };
  //extracting data subject wise
  const subjectWiseData = (totalData, subjectKey) => {
    return totalData.data.filter(
      (item) =>
        item.subjectName.toUpperCase() === totalData.subjects[subjectKey]
    );
  };
  useEffect(() => {
    const finalData = subjectWiseData(result, value);
    const dataForMobileDevice = subjectWiseData(result, state.key);
    setFinalResult(finalData);
    setDataMobileDevice(dataForMobileDevice);
  }, [value, state.key, result]);
  const classes = useStyles();
  //handle time tagging
  const handleTagClick = (arg) => {
    setTimeTag(arg);
  };
  const SubjectSelectionTabs = () => (
    <React.Fragment>
      <Hidden smUp>
        <CustomSelect
          disableUnderline
          value={state.key}
          name="key"
          onChange={handleMobileChange}
          className={classes.selectEmpty}
          IconComponent={MenuIcon}
        >
          {result.subjects.map((subject, index) => {
            return (
              <MenuItem key={index} value={index}>
                {(() => {
                  switch (subject) {
                    case "MATHEMATICS":
                      return (
                        <React.Fragment>
                          <CustomListItemIcon>
                            <MathsIcon />
                          </CustomListItemIcon>
                          <Typography
                            component="span"
                            className={classes.menuName}
                          >
                            Maths
                          </Typography>
                        </React.Fragment>
                      );
                    case "PHYSICS":
                      return (
                        <React.Fragment>
                          <CustomListItemIcon>
                            <PhyIcon />
                          </CustomListItemIcon>
                          <Typography
                            component="span"
                            className={classes.menuName}
                          >
                            Physics
                          </Typography>
                        </React.Fragment>
                      );
                    case "CHEMISTRY":
                      return (
                        <React.Fragment>
                          <CustomListItemIcon>
                            <ChemIcon />
                          </CustomListItemIcon>
                          <Typography
                            component="span"
                            className={classes.menuName}
                          >
                            Chemistry
                          </Typography>
                        </React.Fragment>
                      );
                    case "BOTANY":
                      return (
                        <React.Fragment>
                          <CustomListItemIcon>
                            <BotanyIcon />
                          </CustomListItemIcon>
                          <Typography
                            component="span"
                            className={classes.menuName}
                          >
                            Botany
                          </Typography>
                        </React.Fragment>
                      );
                    case "ZOOLOGY":
                      return (
                        <React.Fragment>
                          <CustomListItemIcon>
                            <ZoologyIcon />
                          </CustomListItemIcon>
                          <Typography
                            component="span"
                            className={classes.menuName}
                          >
                            Zoology
                          </Typography>
                        </React.Fragment>
                      );
                    case "BIOLOGY":
                      return (
                        <React.Fragment>
                          <CustomListItemIcon>
                            <ZoologyIcon />
                          </CustomListItemIcon>
                          <Typography
                            component="span"
                            className={classes.menuName}
                          >
                            Biology
                          </Typography>
                        </React.Fragment>
                      );
                    default:
                      return (
                        <React.Fragment>
                          <CustomListItemIcon>
                            <ZoologyIcon />
                          </CustomListItemIcon>
                          <Typography
                            component="span"
                            className={classes.menuName}
                          >
                            {subject}
                          </Typography>
                        </React.Fragment>
                      );
                  }
                })()}
              </MenuItem>
            );
          })}
        </CustomSelect>
      </Hidden>
      {/*subject  menu for pc  */}
      <Hidden only={["xs"]}>
        <SubjectTabs
          value={value}
          variant={result.subjects.length > 3 ? "scrollable" : "fullWidth"}
          onChange={handleChange}
        >
          {result.subjects.map((sub, index) => {
            return (
              <SubjectTab
                key={index}
                label={(() => {
                  switch (sub) {
                    case "MATHEMATICS":
                      return (
                        <div>
                          <MathsIcon
                            fill={
                              value === index && theme.palette.action.selected
                            }
                          />
                          Maths
                        </div>
                      );
                    case "PHYSICS":
                      return (
                        <div>
                          <PhyIcon
                            fill={
                              value === index && theme.palette.action.selected
                            }
                          />{" "}
                          Physics
                        </div>
                      );
                    case "CHEMISTRY":
                      return (
                        <div>
                          <ChemIcon
                            fill={
                              value === index && theme.palette.action.selected
                            }
                          />{" "}
                          Chemistry
                        </div>
                      );
                    case "BOTANY":
                      return (
                        <div>
                          <BotanyIcon
                            fill={
                              value === index && theme.palette.action.selected
                            }
                          />{" "}
                          Botany
                        </div>
                      );
                    case "ZOOLOGY":
                      return (
                        <div>
                          <ZoologyIcon
                            fill={
                              value === index && theme.palette.action.selected
                            }
                          />{" "}
                          Zoology
                        </div>
                      );
                    case "BIOLOGY":
                      return (
                        <div>
                          <ZoologyIcon
                            fill={
                              value === index && theme.palette.action.selected
                            }
                          />{" "}
                          Biology
                        </div>
                      );
                    default:
                      return (
                        <div>
                          <ZoologyIcon
                            fill={
                              value === index && theme.palette.action.selected
                            }
                          />{" "}
                          {sub}
                        </div>
                      );
                  }
                })()}
              />
            );
          })}
        </SubjectTabs>
      </Hidden>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.container}
        // spacing={2}
      >
        {data.testType &&
        (data.testType.includes("MAIN") || data.testType === "JEE ADVANCED") ? (
          <React.Fragment>
            <Grid item xs={12}>
              {/*subject  menu for mobile devices  */}
              <SubjectSelectionTabs />
            </Grid>
            <Grid item xs={12}>
              <Hidden xsDown>
                <TimeSpentTagging
                  questions={finalResult}
                  sub={result.subjects[state.key]}
                  testData={data}
                  handleTagClick={(param) => {
                    handleTagClick(param);
                  }}
                />
              </Hidden>
              <Hidden smUp>
                <TimeSpentTagging
                  questions={dataMobileDevice}
                  sub={result.subjects[state.key]}
                  testData={data}
                  handleTagClick={(param) => {
                    handleTagClick(param);
                  }}
                />
              </Hidden>
            </Grid>
          </React.Fragment>
        ) : null}
        <Hidden only={["xs"]}>
          <Grid item xs={12} className={classes.ansHead}>
            <Typography variant="h3" color="textPrimary">
              My Answers
            </Typography>
          </Grid>
        </Hidden>
        {data.testType &&
        (data.testType.includes("MAIN") ||
          data.testType === "JEE ADVANCED") ? null : (
          <Grid item xs={12}>
            {/*subject  menu for mobile devices  */}
            <SubjectSelectionTabs />
          </Grid>
        )}

        <Grid item xs={12}>
          {/* pc display  */}

          <Hidden xsDown>
            <React.Fragment>
              {/* my marked answers  */}
              <MarkedAndTotal
                subject={result.subjects[value]}
                markedAns={finalResult}
                timeTag={timeTag}
              />
              {/* my statics  */}
              <MyStatistics
                timeSpentData={result.data}
                subject={result.subjects[value]}
              />
            </React.Fragment>
          </Hidden>

          {/* mobile and tablet display */}
          <Hidden smUp>
            <React.Fragment>
              {/* my marked answers  */}
              <MarkedAndTotal
                subject={result.subjects[state.key]}
                markedAns={dataMobileDevice}
                timeTag={timeTag}
              />
              {/* my statics  */}
              <MyStatistics
                timeSpentData={dataMobileDevice}
                subject={result.subjects[state.key]}
              />
            </React.Fragment>
          </Hidden>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default MyAnswers;
