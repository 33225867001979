import React from "react";
import classNames from "classnames";
// mui
import Typography from "@material-ui/core/Typography";
import { Box, IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    width: "100%"
  },
  subName: {
    fontWeight: 500,
    fontSize: "0.75rem"
  },
  subValue: {
    fontWeight: 700,
    fontSize: "1rem",
    wordBreak: "break-word"
  },
  primary: {
    color: theme.palette.primary.main
  },
  success: {
    color: theme.palette.success.main
  },
  secondary: {
    color: theme.palette.secondary.main
  },
  rightAlign: {
    textAlign: "right"
  },
  leftAlign: {
    textAlign: "left"
  }
}));

function ItemDisplay({ name, value, valueColor, c = "A" }) {
  const classes = useStyles();

  return (
    <div>
      <Typography
        className={classNames({
          [classes.subName]: true,
          [classes.rightAlign]: c === "B" ? true : false,
          [classes.textAlign]: c === "A" ? true : false
        })}
      >
        {name}
      </Typography>
      <Typography
        className={classNames({
          [classes.subValue]: true,
          [classes.primary]: valueColor === "primary",
          [classes.success]: valueColor === "success",
          [classes.secondary]: valueColor === "secondary",
          [classes.rightAlign]: c === "B",
          [classes.textAlign]: c === "A"
        })}
      >
        {value}
      </Typography>
    </div>
  );
}

export default function ContentTypeA({
  itemA,
  itemB,
  bIsBtn = false,
  btnText = "View",
  handleClick,
  handleToggleStatus
}) {
  const classes = useStyles();
  console.log(itemA);
  const { value } = itemA;
  const dueAmount =
    itemA.name === "Due" && parseFloat(value.replace(/[^0-9.-]+/g, ""));
  return (
    <div className={classes.container}>
      <ItemDisplay {...itemA} c="A" />

      {bIsBtn ? (
        <>
          {/* <Button variant="text" color="primary" onClick={handleClick}>
            {btnText}
          </Button> */}
          <IconButton
            edge="start"
            color="error"
            onClick={handleClick}
            aria-label="close"
          >
            <InfoIcon color="primary" />
          </IconButton>
          
        </>
      ) : (
        <ItemDisplay {...itemB} c="B" />
      )}
    </div>
  );
}
// import React from "react";
// import classNames from "classnames";
// // mui
// import Typography from "@material-ui/core/Typography";
// import { Box } from "@material-ui/core";
// import Button from "@material-ui/core/Button";
// import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "flex-start",
//     paddingRight: theme.spacing(0.5),
//     paddingLeft: theme.spacing(0.5),
//     width: "100%",
//   },
//   subName: {
//     fontWeight: 500,
//     fontSize: "0.75rem",
//   },
//   subValue: {
//     fontWeight: 700,
//     fontSize: "1rem",
//     wordBreak: "break-word"
//   },
//   primary: {
//     color: theme.palette.primary.main,
//   },
//   success: {
//     color: theme.palette.success.main,
//   },
//   secondary: {
//     color: theme.palette.secondary.main,
//   },
//   rightAlign: {
//     textAlign: "right"
//   },
//   leftAlign: {
//     textAlign: "left"
//   }
// }));

// function ItemDisplay({ name, value, valueColor, c = "A" }) {
//   const classes = useStyles();

//   return (
//     <div>
//       <Typography
//         className={classNames({
//           [classes.subName]: true,
//           [classes.rightAlign]: c === "B" ? true : false,
//           [classes.textAlign]: c === "A" ? true : false,
//         })}
//       >
//         {name}
//       </Typography>
//       <Typography
//         className={classNames({
//           [classes.subValue]: true,
//           [classes.primary]: valueColor === "primary",
//           [classes.success]: valueColor === "success",
//           [classes.secondary]: valueColor === "secondary",
//           [classes.rightAlign]: c === "B",
//           [classes.textAlign]: c === "A",
//         })}
//       >
//         {value}
//       </Typography>
//     </div>
//   );
// }

// export default function ContentTypeA({
//   itemA,
//   itemB,
//   bIsBtn = false,
//   btnText = "View",
//   handleClick,
// }) {
//   const classes = useStyles();
//   console.log(itemA);
//   const { value } = itemA;
//   const dueAmount = itemA.name === "Due" && parseFloat(value.replace(/[^0-9.-]+/g, ''));
//   return (
//     <div className={classes.container}>
//       <ItemDisplay {...itemA} c="A" />
//       {bIsBtn ? (
//         <Box display="flex" alignItems="center">
//           <Button variant="text" color="primary" onClick={handleClick}>
//             {btnText}
//           </Button>
//           {itemA.name === "Due" && dueAmount > 0 && (
//             <Button
//               variant="contained"
//               color="primary"
//               className={classes.payNowBtn}
//               // onClick={() => history.push("/fee-payment")}
//               style={{ marginLeft: "8px" }}
//             >
//               Pay Now
//             </Button>
//           )}
//         </Box>
//       ) : (
//         <ItemDisplay {...itemB} c="B" />
//       )}
//     </div>
//   );
// }
