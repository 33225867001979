import * as actionType from "../../actions/practicePageActions/actions";

const INITIAL_ANALYTICS_STATE = {
  isLoading: false,
  data: {},
  error: null,
};
export const AnalyticsReducer = (
  analytics = INITIAL_ANALYTICS_STATE,
  action
) => {
  switch (action.type) {
    case actionType.ANALYTICS_SINGLE_QUESTION:
      return {
        ...analytics,
        isLoading: true,
      };
    case actionType.ANALYTICS_SINGLE_QUESTION_SUCCESS:
      return {
        ...analytics,
        isLoading: false,
        data: action.payload,
      };
    case actionType.CATCH_ERROR_ANALYTICS_SINGLE_QUESTION:
      return {
        ...analytics,
        isLoading: false,
        error: action.payload,
      };
    case actionType.CLEAR_ERROR_ANALYTICS_SINGLE_QUESTION:
      return {
        ...analytics,
        isLoading: false,
        error: null,
        data: {},
      };
    default:
      return analytics;
  }
};
