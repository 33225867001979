import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ChipDispaly from "./ChipDispaly";

const DisplayQuesNosPopOver = ({
  setSelectedQno,
  subjectNames,
  questionPaper = [],
  selectedQno,
  close,
}) => {
  const sorted = {};
  // ------sorting schema subject wise----------------
  questionPaper.forEach((item) => {
    const sub = item.subject;
    if (sorted[sub]) {
      sorted[sub].push(item);
    } else {
      sorted[sub] = [item];
    }
  });
  const handleClick = (number) => {
    setSelectedQno(number);
    close();
  };
  const subjectMap = Object.values(sorted);
  return (
    <>
      {subjectMap.map((item, index) => (
        <>
          <Grid>
            <Typography>{subjectNames[index]}</Typography>
          </Grid>
          <Grid>
            <ChipDispaly
              handleClick={handleClick}
              sourceData={item}
              selectedQno={selectedQno}
            />
          </Grid>
        </>
      ))}
    </>
  );
};
export default DisplayQuesNosPopOver;
