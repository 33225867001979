import Highcharts from "highcharts";
export default {
  MATHEMATICS: {
    color: {
      linearGradient: {
        x1: 1,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [
          0,
          Highcharts.Color("#197BBD")
            .setOpacity(1)
            .get("rgba"),
        ],
        [
          1,
          Highcharts.Color("#197BBD")
            .setOpacity(1)
            .get("rgba"),
        ],
      ],
    },
    fillColor: {
      linearGradient: {
        x1: 1,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [
          0,
          Highcharts.Color("#D1E4F1")
            .setOpacity(0.7)
            .get("rgba"),
        ],
        [
          1,
          Highcharts.Color("#D1E4F1")
            .setOpacity(0)
            .get("rgba"),
        ],
      ],
    },
    
    marker: {
      
        symbol:"triangle",
        radius:3
    },
    shadow: true,
  },

  PHYSICS: {
    color: {
      linearGradient: {
        x1: 1,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [
          0,
          Highcharts.Color("#FFAB00")
            .setOpacity(1)
            .get("rgba"),
        ],
        [
          1,
          Highcharts.Color("#FFAB00")
            .setOpacity(1)
            .get("rgba"),
        ],
      ],
    },
    fillColor: {
      linearGradient: {
        x1: 1,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [
          0,
          Highcharts.Color("#FFF6E5")
            .setOpacity(0.7)
            .get("rgba"),
        ],
        [
          1,
          Highcharts.Color("#FFF6E5")
            .setOpacity(0)
            .get("rgba"),
        ],
      ],
    },
    marker: {
        symbol: 'diamond',
        radius:3
    },
    shadow: true,

  },

  CHEMISTRY: {
    color: {
      linearGradient: {
        x1: 1,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [
          0,
          Highcharts.Color("#ED3B64")
            .setOpacity(1)
            .get("rgba"),
        ],
        [
          1,
          Highcharts.Color("#ED3B64")
            .setOpacity(1)
            .get("rgba"),
        ],
      ],
    },
    fillColor: {
      linearGradient: {
        x1: 1,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [
          0,
          Highcharts.Color("#ffe6ee")
            .setOpacity(0.7)
            .get("rgba"),
        ],
        [
          1,
          Highcharts.Color("#ffe6ee")
            .setOpacity(0)
            .get("rgba"),
        ],
      ],
    },
    marker: {
        symbol: 'square',
        radius:3
    },
    shadow: true,
  },

  BIOLOGY: {
    color: {
      linearGradient: {
        x1: 1,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [
          0,
          Highcharts.Color("#3BBDED")
            .setOpacity(1)
            .get("rgba"),
        ],
        [
          1,
          Highcharts.Color("#3BBDED")
            .setOpacity(1)
            .get("rgba"),
        ],
      ],
    },
    fillColor: {
      linearGradient: {
        x1: 1,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [
          0,
          Highcharts.Color("#EBF8FD")
            .setOpacity(0.7)
            .get("rgba"),
        ],
        [
          1,
          Highcharts.Color("#EBF8FD")
            .setOpacity(0)
            .get("rgba"),
        ],
      ],
    },
  },
  marker: {
    symbol: 'circle',
    radius:3,
},
shadow: true,

};
