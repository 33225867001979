import { Grid, makeStyles, Paper, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SingleQuestionPopUp from "./SingleQuestionPopUp";
const PaperComponent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    background: theme.palette.common.white,
    border: "none",
    boxShadow: "none",
    borderRadius: "10px",
    height: "40px",
    width: "40px",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.9",
      boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    },
  },
}))((props) => <Paper {...props} />);
const useStyles = makeStyles((theme) => ({
  correctAns: {
    color: theme.palette.common.white,
    background: theme.palette.action.selected,
  },
  wrongAns: {
    color: theme.palette.common.white,
    background: theme.palette.text.primary,
  },
  partial_correct: {
    color: theme.palette.common.white,
    background: theme.palette.warning.main,
  },
  timeTagClass: {
    background: (props) => props.timeTag.background,
    color: theme.palette.common.white,
  },
}));
function AnswerGrid({ marked, ...props }) {
  const { questionNos = [] } = props.timeTag;
  const rankAnalysis = useSelector((state) => state.rankAnalysis);
  const { data = {} } = rankAnalysis;
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [paperData, setPaperData] = useState({});
  const handleCloseAlertBox = () => {
    setConfirmOpen(false);
    setPaperData({});
  };
  const handleClick = (data) => {
    setConfirmOpen(true);
    setPaperData(data);
  };
  const classes = useStyles(props);
  return (
    <React.Fragment>
      {marked
        ? marked.map((data) => {
            let totalClass;
            if (data.status === "correct") {
              totalClass = classes.correctAns;
            }
            if (data.status === "wrong") {
              totalClass = classes.wrongAns;
            }
            if (data.status === "partial_correct") {
              totalClass = classes.partial_correct;
            }
            if (questionNos.includes(data.questionNo)) {
              totalClass = classes.timeTagClass;
            }
            return (
              <Grid
                item
                key={data.questionNo}
                onClick={() => handleClick(data)}
              >
                <PaperComponent className={totalClass}>
                  {data.questionNo}
                </PaperComponent>
              </Grid>
            );
          })
        : ""}
      <SingleQuestionPopUp
        open={confirmOpen}
        questionData={paperData ? paperData : null}
        handleClose={handleCloseAlertBox}
        strength={data.totalStudents}
      />
    </React.Fragment>
  );
}
export default AnswerGrid;
