import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import theme from "../../../assets/theme";
import { PaperComponent } from "../PaperComponent";
import classNames from "classnames";

const useStyle = makeStyles((theme) => ({
  correctAns: {
    color: theme.palette.primary.main,
  },
  wrongAns: {
    color: theme.palette.error.light,
  },
  unAttempted: {
    color: theme.palette.text.secondary,
  },
  wrongInteger: {
    color: theme.palette.text.primary,
  },
  resultHead: {
    padding: "10px",
  },
  statusText: {
    textTransform: "capitalize",
  },
}));
function PracticeResult({ result, data }) {
  const classes = useStyle();
  const math = document.getElementById("result");
  if (math) {
    window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, math, () => {}]);
  }
  let totalClass; //styling for correct answers
  if (result.status === "correct") {
    totalClass = classes.correctAns;
  }
  if (result.status === "wrong") {
    totalClass = classes.wrongAns;
  }
  if (
    result.status === "wrong" &&
    !result.markedAns.replace(/\s/g, "").length
  ) {
    totalClass = classes.unAttempted;
  }
  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.resultHead}>
        <Grid item container direction="row" justify="center">
          <Typography
            variant="subtitle1"
            align="center"
            className={classNames(totalClass, classes.statusText)}
          >
            {result.status && !result.markedAns.replace(/\s/g, "").length
              ? "Skipped"
              : result.status}
          </Typography>
        </Grid>
        <br />
        <br />
        {data.q_type === "integer" ? (
          <PaperComponent>
            <div id="result">
              Your answer:&nbsp;
              <Typography
                component="span"
                align="right"
                className={
                  result && result.status === "correct"
                    ? classes.correctAns
                    : classes.wrongInteger
                }
                dangerouslySetInnerHTML={{
                  __html: result && result.markedAns,
                }}
              />
              <br />
              Correct answer:&nbsp;
              <Typography
                component="span"
                align="right"
                className={classes.correctAns}
                dangerouslySetInnerHTML={{
                  __html: result && result.correctAns,
                }}
              />
            </div>
          </PaperComponent>
        ) : (
          Object.keys(data.options).map((keyName) => {
            let border, correctColor;
            if (result.correctAns && result.correctAns.includes(keyName)) {
              border = { border: `2px solid ${theme.palette.primary.main}` };
              correctColor = classes.correctAns;
            }
            if (
              result.markedAns &&
              result.markedAns.includes(keyName) &&
              result.status === "wrong"
            ) {
              border = {
                border: `2px solid ${theme.palette.error.light}`,
              };
            }
            return (
              <PaperComponent key={keyName} style={border}>
                <span className={correctColor}>{keyName}. </span>&nbsp;
                <Typography
                  component="span"
                  variant="body1"
                  className={correctColor}
                  dangerouslySetInnerHTML={{
                    __html: data.options[keyName],
                  }}
                />
              </PaperComponent>
            );
          })
        )}
      </Grid>
    </React.Fragment>
  );
}

export default PracticeResult;
