// define time range
const timeRange = {
  PHYSICS: {
    "JEE MAINS": [90000, 120000],
    MAINS: [90000, 120000],
    JEEMAIN_2021: [90000, 120000],
    JEEMAIN_2025: [90000, 120000],
    JEEMAIN_2022: [90000, 120000],
    "JEE ADVANCED": [150000, 180000],
  },
  CHEMISTRY: {
    "JEE MAINS": [60000, 90000],
    MAINS: [90000, 120000],
    JEEMAIN_2021: [60000, 90000],
    JEEMAIN_2025: [60000, 90000],
    JEEMAIN_2022: [60000, 90000],
    "JEE ADVANCED": [90000, 150000],
  },
  MATHEMATICS: {
    "JEE MAINS": [120000, 180000],
    MAINS: [90000, 120000],
    JEEMAIN_2021: [120000, 180000],
    JEEMAIN_2025: [120000, 180000],
    JEEMAIN_2022: [120000, 180000],
    "JEE ADVANCED": [180000, 240000],
  },
};
export default timeRange;
