import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import {
  CustomTableRow,
  CustomTableCellType3,
} from "../components/CustomTableCells";
import Paper from "@material-ui/core/Paper";
import { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
// loadash
import startCase from "lodash/startCase";
import { useStyles5 } from "./styles";
import useFeeData from "./UseFeeData";

const MyTable = ({ concessionFeeType }) => {
  const classes = useStyles5();
  const [modifiedObject, otherFees, rows, transactions] = useFeeData();
  const [data, setData] = useState([]);
  const [transformData, setTransformData] = useState([]);

  // Initialize an empty object for fee sums
  useEffect(() => {
    const feeSums = {};

    // Loop through each installment
    for (const installmentKey in modifiedObject) {
      const installment = modifiedObject[installmentKey];

      // Loop through each fee type in the installment
      for (const feeType in installment) {
        // Skip the "Total" key
        if (feeType === "Total") {
          continue;
        }

        // If the fee type is not already in feeSums, initialize it
        if (!feeSums[feeType]) {
          feeSums[feeType] = {
            amount: 0,
            balance: 0,
            paid: 0,
            concession: 0,
          };
        }

        // Update the sums for the fee type
        const fee = installment[feeType];
        feeSums[feeType].amount += fee.amount;
        feeSums[feeType].balance += fee.balance;
        feeSums[feeType].paid += fee.paid;
      }
    }
    // feeSums.push(otherFees);
    setData({ ...feeSums, ...otherFees });
  }, [modifiedObject, otherFees]);
  useEffect(() => {
    const transformedData = Object.keys(data).map((feeType) => {
      return {
        feeType,
        ...data[feeType],
      };
    });
    transformedData.forEach((item) => {
      const feeType = item.feeType;
      if (concessionFeeType.hasOwnProperty(feeType)) {
        item.concession = concessionFeeType[feeType];
      }
    });
    setTransformData(transformedData);
  }, [data]);

  console.log(transformData);
  return (
    <Box padding={2}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Fee Table">
          <TableHead>
            <CustomTableRow className={classes.tableHead}>
              <CustomTableCellType3>Fee Type</CustomTableCellType3>
              <CustomTableCellType3>Amount</CustomTableCellType3>
              <CustomTableCellType3>Concession</CustomTableCellType3>
              <CustomTableCellType3>Paid</CustomTableCellType3>
              <CustomTableCellType3>Balance</CustomTableCellType3>
            </CustomTableRow>
          </TableHead>
          <TableBody>
            {transformData.map((row, index) => (
              <CustomTableRow
                key={index}
                className={index % 2 === 0 ? "" : classes.oddRow}
              >
                <CustomTableCellType3
                  className={row.feeType === "Total" ? classes.tableTotal : ""}
                >
                  {startCase(row.feeType)}
                </CustomTableCellType3>
                <CustomTableCellType3
                  className={row.feeType === "Total" ? classes.tableTotal : ""}
                >
                  {row.amount.toLocaleString("en-IN")}
                </CustomTableCellType3>
                <CustomTableCellType3
                  className={row.feeType === "Total" ? classes.tableTotal : ""}
                >
                  {row.concession ? row.concession.toLocaleString("en-IN") : 0}
                </CustomTableCellType3>
                <CustomTableCellType3
                  className={row.feeType === "Total" ? classes.tableTotal : ""}
                >
                  {row.paid.toLocaleString("en-IN")}
                </CustomTableCellType3>
                <CustomTableCellType3
                  className={row.feeType === "Total" ? classes.tableTotal : ""}
                >
                  {row.balance.toLocaleString("en-IN")}
                </CustomTableCellType3>
              </CustomTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MyTable;
