import { getAssignments, recordAttendance } from "apis/videoService";
import React, { Component, Fragment } from "react";
import store from "store";
import Header from "../Header";
import PreviousVideosMobile from "./PreviousVideosMobile";

class PreviousVideos extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      live: this.props.location.state && this.props.location.state.live,
      chat: this.props.location.state && this.props.location.state.chat,
      documents: [],
    };
    const { history } = props;
    if (!this.state.live) {
      history.pop();
    }
  }
  getAttendance = async (params) => {
    try {
      let userId = store.get("user") ? store.get("user").userId : undefined;
      let liveUrl = this.state.chat;

      let data = {
        userId,
        liveUrl,
        flipFlag: params.flipFlag,
      };
      let res = await recordAttendance(data);
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount = async () => {
    await this.getAttendance({ flipFlag: true });

    let instituteId = store.get("user").institute;
    if (store.get("user").level) {
      instituteId = [store.get("user").institute, store.get("user").level].join(
        "_"
      );
    }

    let liveUrl = this.state.chat;

    console.log("============ in live ========================");
    console.log(instituteId, liveUrl);
    console.log("====================================");
    let d = await getAssignments({ instituteId, liveUrl });

    if (d.data && d.data.Item && d.data.Item.videoassignments) {
      this.setState({
        documents: d.data.Item.videoassignments,
      });
    }
  };

  componentWillUnmount = async () => {
    await this.getAttendance({ flipFlag: false });
  };

  render() {
    return (
      <Fragment>
        {/* <Header /> */}
        <PreviousVideosMobile
          live={this.state.live}
          chat={this.state.chat}
          provider={this.props.location.state.provider}
        />
      </Fragment>
    );
  }
}

export default PreviousVideos;
