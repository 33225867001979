import { Button, Hidden, ListItemIcon } from "@material-ui/core";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import { AccountCircle, EventAvailable } from "@material-ui/icons";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import AccountCircleImage from "assets/img/AccountCircleImage2.png";
import ClassNames from "classnames";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import store from "store";
import LinkHome from "../components/Header/LinkHome.jsx";
import NotificationTab from "../mycomponents/inAppNotification/Index";
import { withStyles } from "@material-ui/core/styles";

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  selected: {},
})(MuiListItem);

export default function ParentHeaders(classes, user, handleOpen, logout) {
  return (
    <React.Fragment>
      <Hidden mdUp>
        <List className={classes.list + " " + classes.mlAuto}>
          <ListItem
            selected={window.location.pathname == "/home"}
            className={classes.drawItem}
            button
          >
            <Link to="/home" className={classes.onesazNav}>
              <ListItemIcon>
                <EventAvailable />
              </ListItemIcon>
              Results
            </Link>
            &nbsp;&nbsp;&nbsp;
          </ListItem>
          {/* -------------------Attendance--------------- */}
          <ListItem
            selected={window.location.pathname == "/attendance"}
            className={classes.drawItem}
            button
          >
            <Link to="/attendance" className={classes.onesazNav}>
              <ListItemIcon>
                <EventAvailable />
              </ListItemIcon>
              Attendance
            </Link>
            &nbsp;
          </ListItem>
          <ListItem
            selected={window.location.pathname == "/parent-profile"}
            className={classes.drawItem}
            button
          >
            <Link to="/parent-profile" className={classes.onesazNav}>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              Profile
            </Link>
            &nbsp;
          </ListItem>
          <ListItem className={classes.drawItem}>
            <Button onClick={logout}>
              Logout &nbsp; <LogoutIcon />
            </Button>
          </ListItem>
        </List>
      </Hidden>
      <LinkHome dropdownHoverColor="info" />
      <Hidden smDown>
        <Link
          to="/home"
          className={ClassNames(
            classes.onesazNav,
            window.location.pathname == "/home" ? classes.onactive : ""
          )}
        >
          Results
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Link
          to="/make-payment"
          className={ClassNames(
            classes.onesazNav,
            window.location.pathname == "/make-payment" ? classes.onactive : ""
          )}
        >
          Fee
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Link
          to="/attendance"
          className={ClassNames(
            classes.onesazNav,
            window.location.pathname == "/attendance" ? classes.onactive : ""
          )}
        >
          Attendance
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Link
          to="/parent-profile"
          className={ClassNames(
            classes.onesazNav,
            window.location.pathname == "/parent-profile"
              ? classes.onactive
              : ""
          )}
        >
          Profile
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <NotificationTab />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <CustomDropdown
          left
          caret={false}
          hoverColor="transparent"
          dropdownHeader={store.get("user") ? store.get("user").name : ""}
          buttonText={
            <img
              src={AccountCircleImage}
              className={classes.img}
              alt="profile"
            />
          }
          buttonProps={{
            className:
              classes.navLink +
              " " +
              classes.imageDropdownButton +
              " " +
              classes.root,
          }}
          dropdownList={[
            user.institute === "5fd25b12ed14200ff702f738" ||
            user.institute === "5e7370083d8520a40c4ceaaa" ? null : (
              <span onClick={handleOpen}>change password</span>
            ),

            <Fragment>
              <span onClick={logout}>
                Log out
                <LogoutIcon
                  style={{
                    marginLeft: "4px",
                    height: "18px",
                    verticalAlign: "bottom",
                  }}
                />
              </span>
            </Fragment>,
          ]}
        />
      </Hidden>
    </React.Fragment>
  );
}
