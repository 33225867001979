import React from "react";
// import Typography from "@material-ui/core/Typography";
// import { useInView } from "react-intersection-observer";

// const keyframes = `
//   @keyframes slidein {
//     from {
//       margin-left: 100%;
//       width: 300%;
//     }
//     to {
//       margin-left: 0%;
//       width: 100%;
//     }
//   }
// `;

//   return (
//     <div ref={ref}>
//       <style>{keyframes}</style>
//       <Typography
//         variant="body1"
//         style={{ ...animationStyles, display: "inline" }}
//       >
//         Insightful Graphs: Visualize recent exam performance with interactive
//         graphs
//       </Typography>
//     </div>
//   );
// };

// export default InlineAnimatedText;
import { motion } from "framer-motion";
// const InlineAnimatedText = () => {
//   const { ref, inView } = useInView({
//     triggerOnce: true, // Only trigger once
//     threshold: 1, // Trigger animation when 50% of the text is in view
//   });
function App() {
  const animationStyles = {
    animationDuration: "3s",
    width: "auto",
    height: "auto",
    whiteSpace: "pre",
    fontWeight: 800,
    fontStyle: "normal",
    fontFamily: "Montserrat",
    color: "#31456a",
    fontSize: "25px",

    // position: "absolute",
    // animationName: inView ? "slidein" : "none", // Apply animation only when in view
  };
  return (
    <div>
      <motion.h1
        animate={{ x: [50, 150, 50], opacity: 1, scale: 1 }}
        transition={{
          duration: 5,
          delay: 0.5,
          ease: [0.5, 0.71, 1, 1.5],
        }}
        initial={{ opacity: 0, scale: 0.5 }}
        whileHover={{ scale: 1.2 }}
      >
        <div style={{ ...animationStyles, display: "inline" }}>
          Insightful Graphs: Visualize recent exam performance with interactive
          graphs
        </div>
      </motion.h1>
    </div>
  );
}
export default App;
