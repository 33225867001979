import Divider from "@material-ui/core/Divider";
// import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
// import Button from '@material-ui/core/Button';
// import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import Badge from "components/Badge/Badge.jsx";
// import Card from '@material-ui/core/Card';
import Card from "components/Card/Card.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import styles1 from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx";
//charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import { withRouter, Link } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import examAxios from "../apis/examServerAxios";
import convertOption from "../common/optionConverter";
import GridContainer from "../components/Grid/GridContainer.jsx";
import Comparision from "./Comparision";
import ComparisionCard from "./ComparisionCard";
import Header from "./Header";
import NetworkProgress from "./NetworkProgress";
import QuestionList from "./QuestionList";
import { Button, Switch } from "@material-ui/core";

var moment = require("moment");
//import { NavPills } from 'components/NavPills/NavPills.jsx';
// 6380335205
const styles = (theme) => ({
  badgeWrap: {
    whiteSpace: "inherit",
  },
  badgeCorrect: {
    backgroundColor: "#008000bf",
  },
  badgeWrong: {
    backgroundColor: "#ff0000bf",
  },
  badgeUnAttempted: {
    backgroundColor: "gray",
  },
  questionIndex: {
    backgroundColor: "3ebded", // excluded hash
  },
  questionCorrect: {
    // marginLeft: 10,
    // marginRight: 10,
    backgroundColor: "#0080000a",
    //border: "1px solid green",
    borderRadius: "10px",
    padding: "1%",
  },
  marksScored: {
    backgroundColor: "goldenrod",
  },
  questionWrong: {
    //  marginLeft: 10,
    // marginRight: 10,
    //border: "1px solid red",
    backgroundColor: "#ff00000a",
    borderRadius: "10px",
    padding: "1%",
  },
  questionUnAttempt: {
    //  marginLeft: 10,
    // marginRight: 10,
    //border: "1px solid",
    borderRadius: "10px",
    padding: "1%",
  },

  optionCorrect: {
    backgroundColor: " #0080001a",
    //border: "1px solid #2aabe2",
    borderRadius: "10px",
    paddingLeft: "1%",
  },
  optionWrong: {
    border: "1px solid red",
    borderRadius: "10px",
    paddingLeft: "1%",
  },
  optionUnAttempt: {
    //border: "1px solid",
    //backgroundColor: "#0000001a",
    borderRadius: "10px",
    paddingLeft: "1%",
  },
  customContainerFluid: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
  },
  customContainer: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    "@media (min-width: 576px)": {
      maxWidth: "540px",
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px",
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px",
    },
  },
  heading: {
    textAlign: "center",
    fontWeight: 900,
    lineHeight: 1.1,
  },
  scoreCard: {
    clipPath: "circle(35% at 50% 50%)",
    padding: "2%",
    background: "#54bdd5",
    color: "white",
  },
  appBar: {
    color: "white",
    background: "#212121",
    padding: theme.spacing.unit * 1,
  },
  root: {
    flexGrow: 2,
  },
  profile: {
    flexGrow: 1,
  },
  box: {
    // minHeight: theme.spacing(3),
    marginTop: theme.spacing.unit * 1,
    padding: theme.spacing.unit * 1,
  },
  tablelines: {
    color: "#555",
    border: "1px solid #ddd",
    borderCollapse: "collapse",
    padding: "10px",
  },
  tablelinesWithCenter: {
    background: "#54bdd5",
    color: "white",
    border: "1px solid #ddd",
    borderCollapse: "collapse",
    padding: "10px",
    textAlign: "center",
  },
  right: {
    background: "#5cb85c",
    padding: "2% 3%",
    color: "white",
    fontWeight: "bold",
    fontSize: "75%",
    borderRadius: ".25em",
  },
  wrong: {
    background: "#d9534f",
    padding: "2% 3%",
    color: "white",
    fontWeight: "bold",
    fontSize: "75%",
    borderRadius: ".25em",
  },
  legendLabel: {
    background: "#93A6A5",
    height: "5px",
    width: "5px",
    whiteSpace: "pre-wrap",
  },
});
function scoremapFunc() {
  return {
    totalQuestions: 0,
    totalAttempted: 0,
    incorrectAnswers: 0,
    correctAnswers: 0,
  };
}
const chartoptions = {
  title: {
    text: "My chart",
  },
  series: [
    {
      data: [1, 2, 3],
    },
  ],
};
class DetailView extends React.Component {
  loaded = false;
  questions = [];
  keyAvailable = false;
  alphaMap = { 1: "A", 2: "B", 3: "C", 4: "D" };
  scoremap = {
    totalQuestions: 0,
    totalAttempted: 0,
    incorrectAnswers: 0,
    correctAnswers: 0,
  };
  rank = "NA";
  comparisionCardData = {};

  optionsmap = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
  };
  marks = 0;
  chartdata = [];
  subjectChartdata = {};
  subjectCharts = {};
  chartoptions;
  subjectWiseBarCharts = {};
  subjectWiseBarChartsData = {};
  subjectSummaryBarData = {};
  subjectSummaryBar = {};
  xAxisCategories = [];
  subjectSummaryPieData = [];
  subjectSummaryPie = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      rankCardData: {},
      scoreCardData: {},
      accuracyCardData: {},
      comparisionCardData: {},
      customQuestionsCount: 30,
      checkedA: true,
    };
    this.subjectWiseAnalysis = {
      // PHYSICS: {...this.scoremap},
      // CHEMISTRY:{...this.scoremap},
      // MATHEMATICS:{...this.scoremap}
    };
  }
  // pieColors = {
  //   MATHEMATICS: ["#E4D360", "#B1AEAE"],
  //   CHEMISTRY: ["#60C1E4", "#B1AEAE"],
  //   PHYSICS: ["#E4A260", "#B1AEAE"]
  // };
  pieColors = {
    MATHEMATICS: ["#E4D360", "#B1AEAE"],
    CHEMISTRY: ["#60C1E4", "#B1AEAE"],
    PHYSICS: ["#E4A260", "#B1AEAE"],
  };
  // subjectColor = {
  //   MATHEMATICS: "#E4D360",
  //   CHEMISTRY: "#60C1E4",
  //   PHYSICS: "#E4A260",
  //   MATHS: "#E4D360"
  // };
  subjectColor = {
    MATHEMATICS: "#556080",
    CHEMISTRY: "#6CA6BF",
    PHYSICS: "#71C285",
    MATHS: "#556080",
    BOTANY: "#90ed7d",
    ZOOLOGY: "#f2e95b",
  };
  //  subjectChartoptions =
  componentDidUpdate() {
    const math = document.getElementById("question-math-answer-key");
    // console.log("hhhhh", "examscreen")
    if (math && window.MathJax)
      window.MathJax.Hub.Queue([
        "Typeset",
        window.MathJax.Hub,
        math,
        () => {
          console.log("hello");
          // console.log(math,math.style.visibility)
          // math.style.visibility = "";
          //  console.log(math,math.style.visibility)
        },
      ]);
  }

  componentDidMount() {
    const { history } = this.props;
    // console.log(this.props.location.state.test_type)
    if (!this.props.location.state) {
      history.push("/home");
      return;
    }
    console.log(this.props.location.state);
    const body = JSON.stringify({
      student_id: store.get("user")._id,
      test_name: this.props.location.state.test_name,
      code: this.props.location.state.code,
    });
    Object.keys(this.scoremap).forEach((key) => {
      this.scoremap[key] = 0;
    });
    examAxios
      .post("/exams/results", body)
      .then((res) => {
        if (res.data.status == "SUCCESS") {
          this.questions = res.data.payload;
          this.setState({ customQuestionsCount: this.questions.length });
          this.keyAvailable = true;
          this.scoremap.totalQuestions = this.questions.length;
          this.questions = this.questions.map((question) => {
            //   console.log(question.markedAnswer,question.markedAnswer!="NA")
            if (!this.subjectWiseAnalysis[question.question_details.subject]) {
              this.subjectWiseAnalysis[
                question.question_details.subject
              ] = scoremapFunc();
            }
            this.subjectWiseAnalysis[
              question.question_details.subject
            ].totalQuestions += 1;
            // let q_type = "single";
            if (
              ["A", "B", "C", "D"].filter(
                (option) => question.question_details[option] != "NA"
              ).length == 0
            ) {
              console.log(
                ["A", "B", "C", "D"].filter(
                  (option) => question.question_details[option] != "NA"
                ).length
              );
              question.q_type = "integer";
            }
            question.correct_answer =
              question.q_type != "integer"
                ? convertOption(question.correct_answer)
                : String(question.correct_answer); //convertOption(question.correct_answer);
            // if(question.q_type==undefined)

            if (question.markedAnswer && question.markedAnswer != "NA") {
              this.scoremap.totalAttempted += 1;
              this.subjectWiseAnalysis[
                question.question_details.subject
              ].totalAttempted += 1;
              // if (question.correct_answer.includes(question.markedAnswer)) {
              //   this.scoremap.correctAnswers += 1;
              //   this.subjectWiseAnalysis[
              //     question.question_details.subject
              //   ].correctAnswers += 1;
              //   question.decision = "CORRECT";
              // }

              if (question.status == "correct") {
                this.scoremap.correctAnswers += 1;
                this.subjectWiseAnalysis[
                  question.question_details.subject
                ].correctAnswers += 1;
                question.decision = "CORRECT";
              } else {
                this.scoremap.incorrectAnswers += 1;
                this.subjectWiseAnalysis[
                  question.question_details.subject
                ].incorrectAnswers += 1;
                question.decision = "WRONG";
              }
            }
            return question;
          });
          this.marks =
            4 * this.scoremap.correctAnswers - this.scoremap.incorrectAnswers;
        }
        // const showInLegend = true;
        Object.entries(this.subjectWiseAnalysis).forEach((subject) => {
          subject = subject[0];
          this.subjectChartdata[subject] = [
            {
              name: "Correct",
              color: "#71C285",
              y: this.subjectWiseAnalysis[subject].correctAnswers,
            },
            {
              name: "Wrong",
              color: "#FF6666",
              // color: "orange",
              y: this.subjectWiseAnalysis[subject].incorrectAnswers,
            },
            {
              name: "Un Attempted",
              color: "#93A6A5",
              y:
                this.subjectWiseAnalysis[subject].totalQuestions -
                this.subjectWiseAnalysis[subject].totalAttempted,
            },
          ];
          const chartdata = {
            chart: {
              width: 140,
              height: 140,
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: "pie",
              options3d: {
                enabled: true,
                alpha: 45,
                beta: 0,
              },
            },
            title: {
              useHTML: true,
              text: '<b style="font-size:14px">' + subject + "</b>",
            },
            credits: {
              enabled: false,
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.y}</b>",
            },
            plotOptions: {
              pie: {
                colors: this.pieColors[subject],
                //  showInLegend: showInLegend,
                size: 100,
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.y}</b>",
                  distance: -15,
                  color: "white",
                  // filter: {
                  //     property: 'percentage',
                  //     operator: '>',
                  //     value: 4
                  // }
                },
                // showInLegend: true
              },
            },
            series: [
              {
                name: "Info",
                colorByPoint: true,
                data: this.subjectChartdata[subject],
              },
            ],
          };
          this.subjectCharts[subject] = chartdata;
          // showInLegend = false;
        });

        // for charts
        // exams/analytics/timespent

        examAxios
          .post("/analysis/timespent", body)
          .then((res) => res.data)
          .then((res) => {
            console.log(res.payload.count, "143");
            if (res != undefined && res != null) {
              if (res.status == "SUCCESS") {
                this.forceUpdate();
                res.payload.Items.sort((a, b) => {
                  const _a = parseInt(a.question_number);
                  const _b = parseInt(b.question_number);
                  if (_a > _b) return 1;
                  if (_a < _b) return -1;
                  return 0;
                });
                let statusColor = "grey";
                this.chartdata = res.payload.Items.map((item) => {
                  this.xAxisCategories.push(item.question_number);
                  if (item.subject != undefined) {
                    if (
                      this.subjectWiseBarChartsData[item.subject] == undefined
                    ) {
                      this.subjectWiseBarChartsData[item.subject] = [];
                    }
                    this.subjectWiseBarChartsData[item.subject].push({
                      name: item.question_number,
                      y:
                        item.timespent / 60000 < 180
                          ? moment.duration(item.timespent, "ms")
                          : 0,
                      color:
                        item.subject == undefined
                          ? "green"
                          : this.subjectColor[item.subject],
                    });

                    if (this.subjectSummaryBarData[item.subject] == undefined) {
                      this.subjectSummaryBarData[item.subject] = [];
                      this.subjectSummaryPie[item.subject] = {
                        name: item.subject,
                        y: 0,
                        color: this.subjectColor[item.subject],
                      };
                    }
                    if (
                      this.questions[Number(item.question_number) - 1] &&
                      this.questions[Number(item.question_number) - 1]
                        .decision != undefined
                    ) {
                      switch (
                        this.questions[Number(item.question_number) - 1]
                          .decision
                      ) {
                        case "CORRECT":
                          statusColor = "#5cb85c";
                          break;
                        case "WRONG":
                          statusColor = "orange";
                          break;
                        default:
                          statusColor = "grey";
                          break;
                      }
                    }
                    this.subjectSummaryPie[item.subject].y +=
                      item.timespent / 60000 < 180 ? item.timespent / 1000 : 0;
                    this.subjectSummaryBarData[item.subject].push({
                      name: item.question_number,
                      y:
                        item.timespent / 60000 < 180
                          ? moment.duration(item.timespent, "ms")
                          : 0,
                      color: statusColor,
                    });
                  }

                  return {
                    name: item.question_number,
                    y: item.timespent / 60000 < 180 ? item.timespent / 1000 : 0,
                    color:
                      item.subject == undefined
                        ? "green"
                        : this.subjectColor[item.subject],
                  };
                });

                Object.keys(this.subjectSummaryPie).forEach((subject) => {
                  this.subjectSummaryPieData.push(
                    this.subjectSummaryPie[subject]
                  );
                });

                //subjectwise
                Object.keys(this.subjectWiseBarChartsData).forEach(
                  (subject) => {
                    const chartoptions = {
                      chart: {
                        type: "column",
                      },
                      title: {
                        text: "Time Spent on  " + subject + " Questions",
                      },
                      credits: {
                        enabled: false,
                      },
                      plotOptions: {
                        series: {
                          showInLegend: true,
                        },
                      },
                      xAxis: {
                        categories: this.xAxisCategories,
                      },
                      yAxis: {
                        title: {
                          text: "Time Spent (sec)",
                        },
                      },
                      tooltip: {
                        headerFormat:
                          '<span style="font-size:10px">Time spent on</span><table>',
                        pointFormat:
                          '<tr><td style="color:{series.color};padding:0">Question {point.name}: </td>' +
                          '<td style="padding:0"><b>{ point.y:.2f} secs</b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                      },
                      series: [
                        {
                          name: "Question Number",
                          data: this.subjectWiseBarChartsData[subject],
                        },
                      ],
                    };
                    this.subjectWiseBarCharts[subject] = chartoptions;
                  }
                );

                // for subject summary on time
                Object.keys(this.subjectSummaryBarData).forEach((subject) => {
                  const chartoptions = {
                    chart: {
                      type: "column",
                    },
                    title: {
                      text: "Time Spent on  " + subject + " Questions",
                    },
                    credits: {
                      enabled: false,
                    },
                    plotOptions: {
                      series: {
                        showInLegend: true,
                      },
                    },
                    xAxis: {
                      categories: this.xAxisCategories,
                    },
                    yAxis: {
                      title: {
                        text: "Time Spent (sec)",
                      },
                    },
                    tooltip: {
                      headerFormat:
                        '<span style="font-size:10px">Time spent on</span><table>',
                      pointFormat:
                        '<tr><td style="color:{series.color};padding:0">Question {point.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.2f} secs</b></td></tr>',
                      footerFormat: "</table>",
                      shared: true,
                      useHTML: true,
                    },
                    series: [
                      {
                        name: "Question Number",
                        data: this.subjectSummaryBarData[subject],
                      },
                    ],
                  };
                  this.subjectSummaryBar[subject] = chartoptions;
                });
                // console.log(this.chartdata,"143")
                this.forceUpdate();
              }
            }
            // start  of
            this.chartoptions = {
              chart: {
                type: "column",
              },
              title: {
                text: "Time Spent on Questions",
              },
              credits: {
                enabled: false,
              },
              plotOptions: {
                series: {
                  showInLegend: true,
                },
              },
              xAxis: {
                categories: this.xAxisCategories,
              },
              yAxis: {
                title: {
                  text: "Time Spent (sec)",
                },
              },
              tooltip: {
                headerFormat:
                  '<span style="font-size:10px">Time spent on</span><table>',
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0"> {point.name}: </td>' +
                  '<td style="padding:0"><b>{point.y} secs</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
              },
              series: [
                {
                  type: "column",
                  name: "Question Number",
                  data: this.chartdata,
                },
                {
                  type: "pie",
                  name: "Time Spent on Subject",
                  data: this.subjectSummaryPieData,
                  center: [200, 10],
                  size: 100,
                  showInLegend: false,
                  dataLabels: {
                    enabled: true,
                  },
                },
              ],
            };
            this.forceUpdate();
            //end of chart n timespent
          });

        // rank api
        examAxios
          .post("/analysis/rank", body)
          .then((res) => res.data)
          .then((res) => {
            if (res.status == "SUCCESS") {
              // this.rank = res.payload.rank;
              this.comparisionCardData = res.payload;
              this.setState({ comparisionCardData: res.payload });
              console.log(this.comparisionCardData);
              this.forceUpdate();
            }
          }); //end of rank api
        this.forceUpdate();
        //end of results
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loaded = true;
        this.forceUpdate();
      });
  }
  redirectToNewResultUI = (event) => {
    const { history, location } = this.props;
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
    history.push({
      pathname: `/${location.state.test_name}/new-result`,
      search: `?paper=${location.state.paper}&test=${
        location.state.testDisplayName
      }&type=${location.state.test_type}`,
    });
  };
  render() {
    const dd = [
      {
        name: "Point 1",
        color: "#00FF00",
        y: 2,
      },
      {
        name: "Point 2",
        color: "#ffeded",
        y: 5,
      },
    ];
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Header> </Header>
        {this.loaded && this.keyAvailable && (
          <GridContainer style={{ padding: "24px", background: "white" }}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
              spacing={10}
            >
              <Grid item>
                <br />
                <Typography variant="h5" className={classes.heading}>
                  Result of {this.props.location.state.testDisplayName}
                  {/* toggle new result ui */}
                  <Typography component="span" style={{ marginLeft: "20px" }}>
                    <Switch
                      checked={this.state.checkedA}
                      onChange={this.redirectToNewResultUI}
                      color="primary"
                      name="checkedA"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item>
                <Divider variant="middle" />
              </Grid>
              <GridItem style={{ background: "#F3FBFF", padding: "80px 0px" }}>
                {/* <Divider variant="middle" /> */}
                <Comparision
                  customQuestionsCount={this.state.customQuestionsCount}
                  paper={this.props.location.state.paper}
                  test_type={this.props.location.state.test_type}
                  data={this.state.comparisionCardData}
                />
              </GridItem>
              {/* <Grid item>
               
                <Grid container justify="space-around" alignItems="center">
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="h3">
                          Total :{" "}
                          <strong style={{ color: "#2aabe2" }}>
                            {" "}
                            {this.marks} / {this.questions.length * 4}{" "}
                          </strong>
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="h3">
                          Rank :{" "}
                          <strong style={{ color: "#2aabe2" }}>
                            {" "}
                            {this.rank}{" "}
                          </strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container direction="column">
                      {Object.entries(this.subjectWiseAnalysis).map(entry => {
                        const sub = entry[0];
                        return (
                          <Grid item>
                            <Typography variant="h5">
                              {sub} :{" "}
                              <strong style={{ color: "#2aabe2" }}>
                                {" "}
                                {entry[1].correctAnswers * 4 -
                                  entry[1].incorrectAnswers}{" "}
                              </strong>
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                
              </Grid> */}
              {/* <GridItem xs={4} >
                         <Card style={{ background: "linear-gradient(180deg, #47A5F8,#33B3FE)"}}>
                      <p>  Rank: 1</p> 
               
                </Card>
                </GridItem> */}
              <Grid item>
                <Typography
                  variant="h5"
                  style={{
                    backgroundColor: "#ebebeb",
                    borderLeft: "4px solid #2aabe2",
                    padding: "10px",
                  }}
                  gutterBottom
                >
                  {/* Comparision Cards on  */}
                  Comparision of{" "}
                  <strong>
                    {" "}
                    <span style={{ color: "green" }}> C </span>{" "}
                    <span style={{ color: "red" }}> W</span>{" "}
                    <span style={{ color: "black" }}>U </span>{" "}
                  </strong>
                  analysis
                </Typography>
              </Grid>
              <GridItem>
                <ComparisionCard
                  data={this.state.comparisionCardData}
                  test_name={this.props.location.state.test_name}
                  id={store.get("user")._id}
                />
              </GridItem>
              <br /> <br /> <br /> <br />
              <Grid item>
                <Typography
                  variant="h5"
                  style={{
                    backgroundColor: "#ebebeb",
                    borderLeft: "4px solid #2aabe2",
                    padding: "10px",
                  }}
                  gutterBottom
                >
                  Your Attempt vs Other Students
                  <span>
                    {" "}
                    <i class="material-icons" style={{ color: "#00b1ff" }}>
                      star
                    </i>
                  </span>
                  <span>
                    {" "}
                    <i class="material-icons" style={{ color: "#00b1ff" }}>
                      star
                    </i>
                  </span>
                  <span>
                    {" "}
                    <i class="material-icons" style={{ color: "#00b1ff" }}>
                      star
                    </i>
                  </span>
                </Typography>
              </Grid>
              <GridItem>
                <QuestionList
                  test_name={this.props.location.state.test_name}
                  id={store.get("user")._id}
                />
                <br />
              </GridItem>
              {/* <Grid item>
                <Typography
                  variant="h5"
                  style={{
                    backgroundColor: "#ebebeb",
                    borderLeft: "4px solid #2aabe2",
                    padding: "10px"
                  }}
                  gutterBottom
                >
                 Accuracy and marks obtained per subject
                </Typography>
              </Grid> */}
              {/* <GridItem>
                <Card>
                <Grid container justify="space-around" alignItems="center">
                  {Object.entries(this.subjectWiseAnalysis).map(entry => {
                    const sub = entry[0];
                    return (
                      <Grid item>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={this.subjectCharts[sub]}
                        />
                        <br />
                         <strong> Marks Obtained: </strong>{" "}
                        <bold>
                          {" "}
                          {entry[1].correctAnswers * 4 -
                            entry[1].incorrectAnswers}
                        </bold> 
                      </Grid>
                    );
                  })}

                  <GridItem
                    xs={12}
                    style={{
                      borderBottom: "1px solid grey",
                      marginRight: "5%"
                    }}
                  >
                    <br /> <br />
                    <GridContainer justify="flex-end">
                      <GridItem xs={4} sm={2}>
                        {" "}
                        <span className={classes.legendLabel}>
                          {" "}
                          {"  "}{" "}
                        </span>{" "}
                        <strong style={{ fontSize: "10px" }}>
                          {" "}
                         
                          UN ATTEMPTED
                        </strong>{" "}
                      </GridItem>
                      <GridItem xs={4} sm={2}>
                        {" "}
                        <span
                          className={classes.legendLabel}
                          style={{ background: "#FF6666" }}
                        >
                          {" "}
                          {"  "}{" "}
                        </span>
                        <strong style={{ fontSize: "10px" }}> INCORRECT</strong>
                      </GridItem>
                      <GridItem xs={4} sm={2}>
                        {" "}
                        <span
                          className={classes.legendLabel}
                          style={{ background: "#71C285" }}
                        >
                          {" "}
                          {"  "}{" "}
                        </span>
                        <strong style={{ fontSize: "10px" }}> CORRECT</strong>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={12}>
                    <br />
                    <br />
                    <Button color="primary">
                      Total Questions: {this.scoremap.totalQuestions}
                    </Button>
                    <Button color="teriary">
                      Attempted Questions: {this.scoremap.totalAttempted}
                    </Button>
                    <Button
                      style={{
                        pointer: "unset",
                        textAlign: "center",
                        color: "white",
                        background: "#3288F9"
                      }}
                    >
                      Total Marks: {this.marks}
                    </Button>
                  </GridItem>
                </Grid>
                <br/>  <br/>
                </Card>
              </GridItem> */}
              <br />
              <br />
              <Grid item>
                <Typography
                  variant="h5"
                  style={{
                    backgroundColor: "#ebebeb",
                    borderLeft: "4px solid #2aabe2",
                    padding: "10px",
                  }}
                  gutterBottom
                >
                  Time spent on each questions
                </Typography>
              </Grid>
              <GridItem>
                <Card>
                  <GridContainer
                    justify="space-around"
                    style={{ marginTop: "10px" }}
                  >
                    {Object.keys(this.subjectSummaryPie).map((subject) => {
                      return (
                        <GridItem xs={3} style={{ textAlign: "center" }}>
                          {" "}
                          <span
                            className={classes.legendLabel}
                            style={{
                              background: this.subjectColor[subject],
                            }}
                          >
                            {" "}
                            {"  "}{" "}
                          </span>{" "}
                          <strong style={{ fontSize: "10px" }}>
                            {" "}
                            {subject}
                          </strong>{" "}
                        </GridItem>
                      );
                    })}
                    {/* <GridItem xs={3}>
                    {" "}
                    <span
                      className={classes.legendLabel}
                      style={{ background: this.subjectColor["PHYSICS"] }}
                    >
                      {" "}
                      {"  "}{" "}
                    </span>{" "}
                    <strong style={{ fontSize: "10px" }}> PHYSICS</strong>{" "}
                  </GridItem>
                  <GridItem xs={3}>
                    {" "}
                    <span
                      className={classes.legendLabel}
                      style={{ background: this.subjectColor["CHEMISTRY"] }}
                    >
                      {" "}
                      {"  "}{" "}
                    </span>
                    <strong style={{ fontSize: "10px" }}> CHEMISTRY</strong>
                  </GridItem>
                  <GridItem xs={3}>
                    {" "}
                    <span
                      className={classes.legendLabel}
                      style={{ background: this.subjectColor["MATHEMATICS"] }}
                    >
                      {" "}
                      {"  "}{" "}
                    </span>
                    <strong style={{ fontSize: "10px" }}> MATHEMATICS</strong>
                  </GridItem> */}
                  </GridContainer>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={this.chartoptions}
                  />
                </Card>
              </GridItem>
              <br />
              <br />
              <br />
              <br />
              <Grid item>
                <Typography
                  variant="h5"
                  style={{
                    backgroundColor: "#ebebeb",
                    borderLeft: "4px solid #2aabe2",
                    padding: "10px",
                  }}
                  gutterBottom
                >
                  Answer Key
                </Typography>
              </Grid>
              <GridItem>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  alignItems="stretch"
                  spacing={5}
                  id="question-math-answer-key"
                >
                  {this.questions.map((q) => {
                    return (
                      <React.Fragment>
                        {/* <GridItem xs={12}> */}
                        <Card
                          className={classNames(
                            q.decision == "CORRECT"
                              ? classes.questionCorrect
                              : q.decision == "WRONG"
                              ? classes.questionWrong
                              : classes.questionUnAttempt
                          )}
                        >
                          <Grid container justify="space-between">
                            <Grid item>
                              <Badge className={classes.questionIndex}>
                                {q.question_details.question_number}
                              </Badge>
                            </Grid>
                            <Grid item>
                              <strong>Marks Scored:</strong>{" "}
                              <Badge className={classes.marksScored}>
                                {q.marks}
                              </Badge>
                            </Grid>
                            <Grid item>
                              <Badge
                                className={classNames(
                                  q.decision == "CORRECT"
                                    ? classes.badgeCorrect
                                    : q.decision == "WRONG"
                                    ? classes.badgeWrong
                                    : ""
                                )}
                              >
                                {q.decision == "CORRECT"
                                  ? "correct"
                                  : q.decision == "WRONG"
                                  ? "wrong"
                                  : "un attempted"}
                              </Badge>
                            </Grid>
                          </Grid>
                          <br />
                          <Divider variant="middle" />
                          <br />
                          <Typography>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: q.question_details.q,
                              }}
                            />
                            {/* </div> */}
                          </Typography>
                          <br />
                          {(() => {
                            switch (q.q_type) {
                              case "float":
                              case "double":
                              case "integer":
                                return (
                                  <React.Fragment>
                                    {" "}
                                    <div>
                                      <Typography
                                        className={classNames(
                                          q.decision == "CORRECT"
                                            ? classes.optionCorrect
                                            : classes.optionUnAttempt
                                        )}
                                      >
                                        Your Answer: {q.markedAnswer} <br />
                                        Correct Answer : {q.correct_answer + ""}
                                        {/* {JSON.stringify(q)} */}
                                      </Typography>
                                    </div>
                                    <br />
                                  </React.Fragment>
                                );
                                break;
                              case "single":
                              case "mutliple":
                                {
                                  return ["A", "B", "C", "D"]
                                    .filter((option) => q[option] != "NA")
                                    .map((option) => {
                                      return (
                                        <React.Fragment>
                                          {" "}
                                          <div>
                                            <Typography
                                              className={classNames(
                                                q.decision == "CORRECT" &&
                                                  q.markedAnswer.includes(
                                                    option
                                                  ) &&
                                                  q.markedAnswer != "NA"
                                                  ? classes.optionCorrect
                                                  : classes.optionUnAttempt
                                              )}
                                            >
                                              <div
                                                style={{
                                                  padding: "20px",
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    option +
                                                    ".  " +
                                                    q.question_details[option] +
                                                    (q.markedAnswer &&
                                                    q.markedAnswer == option
                                                      ? q.markedAnswer ==
                                                        q.correct_answer
                                                        ? "<span> <i class=material-icons style=color:green >done</i></span>"
                                                        : "<span> <i class=material-icons style=color:red >clear</i></span>"
                                                      : ""),
                                                }}
                                              />
                                            </Typography>
                                          </div>
                                          <br />
                                        </React.Fragment>
                                      );
                                    });
                                }
                                break;
                              default:
                                {
                                  return ["A", "B", "C", "D"]
                                    .filter((option) => q[option] != "NA")
                                    .map((option) => {
                                      return (
                                        <React.Fragment>
                                          {" "}
                                          <div>
                                            <Typography
                                              className={classNames(
                                                q.correct_answer.includes(
                                                  option
                                                ) && q.correct_answer != "NA"
                                                  ? classes.optionCorrect
                                                  : classes.optionUnAttempt
                                              )}
                                            >
                                              <div
                                                style={{
                                                  padding: "20px",
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    option +
                                                    ".  " +
                                                    q.question_details[option] +
                                                    (q.markedAnswer != "NA" &&
                                                    q.markedAnswer.includes(
                                                      option
                                                    )
                                                      ? q.correct_answer.includes(
                                                          option
                                                        )
                                                        ? "<span> <i class=material-icons style=color:green >done</i></span>"
                                                        : "<span> <i class=material-icons style=color:red >clear</i></span>"
                                                      : ""),
                                                }}
                                              />
                                            </Typography>
                                          </div>
                                          <br />
                                        </React.Fragment>
                                      );
                                    });
                                }
                                break;
                            }
                          })() // end of switch
                          }

                          {/* <div>
                                <Typography
                                  className={classNames(
                                    q.correct_answer == 2 ||
                                      q.correct_answer == "B"
                                      ? classes.optionCorrect
                                      : classes.optionUnAttempt
                                  )}
                                >
                                  <div
                                    style={{
                                      padding: "20px"
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                      " B)  " +
                                      q.question_details.B +
                                      (q.markedAnswer && q.markedAnswer == "B"
                                        ? q.markedAnswer == q.correct_answer
                                          ? "<span> <i class=material-icons style=color:green >done</i></span>"
                                          : "<span> <i class=material-icons style=color:red >clear</i></span>"
                                        : "")
                                    }}
                                  />
                                </Typography>
                              </div>
                              <br />
                              <div>
                                <Typography
                                  className={classNames(
                                    q.correct_answer == 3 ||
                                      q.correct_answer == "C"
                                      ? classes.optionCorrect
                                      : classes.optionUnAttempt
                                  )}
                                >
                                  <div
                                    style={{
                                      padding: "20px"
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                      " C)  " +
                                      q.question_details.C +
                                      (q.markedAnswer && q.markedAnswer == "C"
                                        ? q.markedAnswer == q.correct_answer
                                          ? "<span> <i class=material-icons style=color:green >done</i></span>"
                                          : "<span> <i class=material-icons style=color:red >clear</i></span>"
                                        : "")
                                    }}
                                  />
                                </Typography>
                              </div>
                              <br />
                              <div>
                                <Typography
                                  className={classNames(
                                    q.correct_answer == 4 ||
                                      q.correct_answer == "D"
                                      ? classes.optionCorrect
                                      : classes.optionUnAttempt
                                  )}
                                >
                                  <div
                                    style={{
                                      padding: "20px"
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                      " D)  " +
                                      q.question_details.D +
                                      (q.markedAnswer && q.markedAnswer == "D"
                                        ? q.markedAnswer == q.correct_answer
                                          ? "<span> <i class=material-icons style=color:green >done</i></span>"
                                          : "<span> <i class=material-icons style=color:red >clear</i></span>"
                                        : "")
                                    }}
                                  />
                                </Typography>
                              </div>
                              */}
                          <Divider variant="middle" />
                          <Grid container justify="flex-start">
                            <Grid item style={{ padding: "20px" }}>
                              <br />
                              <Badge>{q.question_details.topic} </Badge>
                              <Badge className={classes.badgeWrap}>
                                {" "}
                                {q.question_details.subTopic}{" "}
                              </Badge>
                              <br /> <br />
                              <strong> Solution: </strong>
                              <Typography
                                dangerouslySetInnerHTML={{
                                  __html: q.question_details.solution,
                                }}
                              />
                            </Grid>
                          </Grid>
                          {/* <br />
                              <br /> */}
                        </Card>
                        {/* </GridItem> */}
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </GridItem>
            </Grid>

            <br />
            <br />
            <br />

            <br />

            {/* {//////////////////////////////////////////////////////////////////////////////////////////////////} */}
          </GridContainer>
        )}
        {this.loaded && !this.keyAvailable && (
          <Grid container direction="row" justify="center" alignItems="center">
            <Typography
              component="h1"
              variant="h3"
              style={{ marginTop: "10%" }}
            >
              Key is not uploaded to evaluate
            </Typography>
          </Grid>
        )}
        {!this.loaded && <NetworkProgress> </NetworkProgress>}
        {/* <Results>   </Results> */}
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(DetailView);
