import * as actionType from "./actions";
// query to fetch question list
export const saveQueryToStore = (query) => ({
  type: actionType.SAVE_QUERY_TO_STORE,
  query,
});
export const clearQueryFromStore = () => ({
  type: actionType.CLEAR_QUERY_FROM_STORE,
});

// body to answer question
export const saveBodyToStore = (body) => ({
  type: actionType.SAVE_BODY_TO_STORE,
  body,
});
export const clearBodyFromStore = () => ({
  type: actionType.CLEAR_BODY_FROM_STORE,
});
// fetching list of questions
export const fetchQuestionsList = (body) => ({
  type: actionType.FETCH_QUESTIONS_LOADING,
  body,
});

export const fetchQuestionSuccess = (questions) => ({
  type: actionType.FETCH_QUESTIONS_SUCCESS,
  payload: questions,
});

// submitting answers
export const savedAnswer = (body) => ({
  type: actionType.SAVE_ANSWER_LOADING,
  body,
});

export const saveAnswerSuccess = (answer) => ({
  type: actionType.SAVE_ANSWER_SUCCESS,
  payload: answer,
});

//bookmarked questions
export const bookmarkQuestion = (body) => ({
  type: actionType.BOOKMARK_QUESTION_LOADING,
  body,
});
export const bookmarkQuestionSuccess = (bookmarkedStatus) => ({
  type: actionType.BOOKMARK_QUESTION_SUCCESS,
  payload: bookmarkedStatus,
});

// get analytics of single question
export const getAnalyticsSingleQuestion = (body) => ({
  type: actionType.ANALYTICS_SINGLE_QUESTION,
  body,
});
export const getAnalyticsSingleQuestionSuccess = (analytics) => ({
  type: actionType.ANALYTICS_SINGLE_QUESTION_SUCCESS,
  payload: analytics,
});

/* ------------------api error handling action creators------------------- */

export const catchErrorQuestions = (error) => ({
  type: actionType.CATCH_ERROR_QUESTIONS,
  payload: error,
});
export const catchErrorAnswer = (error) => ({
  type: actionType.CATCH_ERROR_ANSWER,
  payload: error,
});
export const catchErrorBookmarkQuestion = (error) => ({
  type: actionType.CATCH_ERROR_BOOKMARK,
  payload: error,
});
export const catchErrorAnalytics = (error) => ({
  type: actionType.CATCH_ERROR_ANALYTICS_SINGLE_QUESTION,
  payload: error,
});

/* ------------clear error from store if any on component unmount------------ */

export const clearErrorQuestions = () => ({
  type: actionType.CLEAR_ERROR_QUESTIONS,
});
export const clearErrorSubmit = () => ({
  type: actionType.CLEAR_ERROR_SUBMIT,
});
export const clearErrorBookmark = () => ({
  type: actionType.CLEAR_ERROR_BOOKMARK,
});
export const clearErrorAnalytics = () => ({
  type: actionType.CLEAR_ERROR_ANALYTICS_SINGLE_QUESTION,
});
