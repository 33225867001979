import React from 'react';
// material
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: '320px',
    maxWidth: '580px',
    margin: ' 110px auto',
    display: 'flex',
    position: 'relative',
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      margin: ' 110px 10px',
    },
    maxHeight: 'calc(100vh - 150px)'
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    margin: '0px',
    backgroundColor: '#00bfa5',
    alignItems: 'center'
  },
  title: {
    fontSize: 30,
    color: 'white'
  },
  subHeader: {
    fontSize: 15,
    color: 'white'
  },
  pos: {
    marginBottom: 12,
  },
  cardContent: {
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    flexGrow: 1,
    '&::-webkit-scrollbar': {
      width: '6px!important',
      height: '6px!important'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#00bfa5'
    }
  },
  qnsAndSchema: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#ededed',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  noOfQns: {
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  markingSchema: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  syllabusContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  subject: {
    textAlign: 'center'
  },
  chapter: {
    textAlign: 'center'
  }
}));

const SyllabusCard = ({ handleClose, grade, examType, examDetails}) => {
  const classes = useStyles();
  const bullet = "\u2022"
  const currentExamDetais = examDetails[grade];
  const examPattern = examDetails[grade].examPattern;
  const examSyllabus = examDetails[grade].syllabus;
  const examDuration = examDetails[grade].duration;
  return (
    <div className={classes.rootContainer}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.header}
          classes={{
            title: classes.title,
            subheader: classes.subHeader
          }}
          title='Exam Details'
          subheader={`${examPattern.total} Objective Qns   ${bullet}   ${examDuration}`}
          action={
            <IconButton
              onClick={handleClose}
            >
              <CloseIcon style={{ color: 'white' }}></CloseIcon>
            </IconButton>
          }
        >
        </CardHeader>
        <CardContent className={classes.cardContent}>
          <div className={classes.qnsAndSchema}>
            <div>
              <Typography variant={'body1'} className={classes.noOfQns}>
                Mathematics: {examPattern[examType].maths}
              </Typography>
              <Typography variant={'body1'} className={classes.noOfQns}>
                {
                  grade < 6 ? (
                    `Reasoning: ${examPattern[examType].reasoning}`
                  ) : (examType === 'NEET' ? (
                    `Physics, Chemistry and Biology: ${examPattern[examType].pcb}`
                  ) : (
                    `Physics and Chemistry: ${examPattern[examType].pc}`
                  ))
                }
              </Typography>
            </div>
            <div>
              <Typography variant={'body1'} className={classes.markingSchema}>
                {`Each question carries ${currentExamDetais.examWeightage.positive} marks`}
              </Typography>
              <Typography variant={'body1'} className={classes.markingSchema}>
                {
                  currentExamDetais.examWeightage.negative ? (
                    `-1 mark for each wrong answer`
                  ) : 'No negative marks'
                }
              </Typography>
            </div>
          </div>
          <div className={classes.syllabusContainer}>
            <div>
              <hr style={{ marginTop: '0px' }}></hr>
              <Typography variant='h4' style={{ textAlign: 'center' }}>Syllabus</Typography>
            </div>
            {
              grade < 6 ? (
                <div>
                  <hr></hr>
                  <Typography variant='h5' className={classes.subject}>
                    Simple Reasoning and maths
                  </Typography>
                </div>
              ) : (
                Object.keys(examSyllabus[examType]).map((subject, index) => {
                  return (
                    (
                      <div key={index}>
                        <hr></hr>
                        <Typography variant={'h5'} className={classes.subject}>{subject.toLocaleUpperCase()}</Typography>
                        {
                          examSyllabus[examType][subject].map((chapter, index) => (
                            <Typography
                              variant={'body1'}
                              key={index}
                              className={classes.chapter}
                              style={
                                index % 2 === 0 ? { backgroundColor: '#fafafa' } : null
                              }
                            >
                              {chapter}
                            </Typography>
                          ))
                        }
                      </div>
                    )
                  )
                })
              )
            }
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default SyllabusCard;
