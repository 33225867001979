import React from "react";
import { Tab, Tabs, withStyles } from "@material-ui/core";
import theme from "../../assets/theme";
const OnTab = withStyles(() => ({
  root: {
    textTransform: "none",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    opacity: 1,
    color: theme.palette.common.white,
    background: theme.palette.action.selected,
    boxSizing: "border-box",
    borderRight: "1px solid white",
    "&:focus": {
      color: theme.palette.action.selected,
    },
  },
  selected: {
    color: theme.palette.action.selected,
    background: theme.palette.common.white,
  },
}))((props) => <Tab disableRipple {...props} />);

const SubjectTab = withStyles(() => ({
  root: {
    lineHeight: "18px",
    fontSize: "14px",
  },
}))((props) => <OnTab disableRipple {...props} />);
const SubjectTabs = withStyles({
  root: {
    background: theme.palette.common.white,
    filter: "drop-shadow(0px 5px 10px rgba(142, 154, 175, 0.1))",
    [theme.breakpoints.down("sm")]: {
      marginRight: "3%",
      borderRadius: "10px 10px 0px 0px",
    },
  },
  indicator: {
    backgroundColor: "transparent",
    float: "left",
  },
})((props) => (
  <Tabs
    {...props}
    scrollButtons="off"
    TabIndicatorProps={{ children: <div /> }}
  />
));

export { SubjectTab, SubjectTabs };
