import React, { useState, useEffect } from "react";
import store from "store";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import { Card, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useStyles, StyledBadge } from "./styles";
import { Link } from "react-router-dom";

const PayNowBox = ({
  avatar,
  studentAdmissionNo,
  stuFeeData,
  secName,
  omrNo,
  balance,
  results,
  handleClick,
}) => {
  const classes = useStyles();
  const [type, setType] = useState("");
  console.log(stuFeeData);
  useEffect(() => {
    if (stuFeeData) {
      if (stuFeeData.config) {
        if (stuFeeData.config[0].attendanceConf) {
          setType(stuFeeData.config[0].attendanceConf.type);
        } else setType("");
      }
    }
  }, [stuFeeData]);

  return (
    <Grid item xs={12} sm={3}>
      <Grid item>
        <Paper className={classes.stuProfile}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              className={classes.imgBox}
              spacing={1}
            >
              <StyledBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                <Avatar
                  alt={store.get("user").name}
                  src={avatar ? avatar : "/static/images/avatar/2.jpg"}
                  className={classes.img}
                />
              </StyledBadge>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
              className={classes.studentName}
            />
            <Grid
              item
              xs={12}
              style={{
                paddingLeft: "20px",
              }}
            >
              {/*  student name */}
              <Typography align="center" className={classes.name} color="info">
                {store.get("user").name}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            style={{
              paddingLeft: "15px",
              paddingBottom: "4px,",
            }}
          >
            <table className={classes.batch}>
              <tbody>
                <tr>
                  <td>&nbsp;&nbsp;Adm No</td>
                  <td>:</td>
                  <td style={{ color: "#3268a8" }}>
                    {store.get("user").accountType === "parent"
                      ? studentAdmissionNo
                      : store.get("user").userId}
                  </td>
                </tr>

                <tr>
                  <td>&nbsp;&nbsp;Class</td>
                  <td>:</td>
                  <td style={{ color: "#3268a8" }}>
                    {store.get("user").classVal}
                  </td>
                </tr>

                <tr>
                  <td>&nbsp;&nbsp;Batch</td>
                  <td>:</td>
                  <td style={{ color: "#3268a8" }}>
                    {stuFeeData ? stuFeeData.batchName : null}
                  </td>
                </tr>
                {secName && (
                  <tr>
                    <td>&nbsp;&nbsp;Section</td>
                    <td>:</td>
                    <td style={{ color: "#3268a8" }}>{secName || ""}</td>
                  </tr>
                )}

                <tr>
                  <td>&nbsp;&nbsp;OMR</td>
                  <td>:</td>
                  <td style={{ color: "#3268a8" }}>{omrNo}</td>
                </tr>

                {store.get("user").accountType === "parent" && (
                  <tr>
                    <td>&nbsp;&nbsp;Id</td>
                    <td>:</td>

                    <td style={{ color: "#3268a8" }}>
                      {store.get("user").userId}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Grid>
          &nbsp;
          {type ? (
            <Grid style={{ paddingLeft: "15px",}}>
            <Button variant="outlined" color="info" style={{padding: 3,}}>
               <Link to="/attendance"  color="info">View Attendance Report</Link>
            </Button>
            </Grid>
          ) : null}
          &nbsp;
        </Paper>
      </Grid>

      {store.get("user").accountType === "parent" &&
        results.length === 0 &&
        balance !== 0 && (
          <Grid item xs={12} sm={3}>
            <Paper className={classes.stuProfile1}>
              <Grid container>
                <Grid item xs={12} container justifyContent="flex-start">
                  <Typography color="info" variant="button">
                    Finance (Fee Details)
                  </Typography>
                  &nbsp; &nbsp;
                  <Card
                    elevation={0}
                    style={{ background: "#E7F7FD", padding: 3 }}
                  >
                    <Typography style={{ color: "#118FBE" }} variant="button">
                      Total Balance to Pay
                    </Typography>
                    <Typography style={{ color: "red" }} variant="button">
                      :{balance.toLocaleString("en-IN")}
                    </Typography>
                    &nbsp;
                  </Card>
                  <Button />
                  &nbsp;
                </Grid>
                <Grid item xs={12} sm={6} container justifyContent="flex-end" />
                <Grid item xs={12} sm={2} container justifyContent="flex-end" />
                <Grid item xs={12} sm={4} container justifyContent="flex-end">
                  <Button
                    style={{ background: "#003366", color: "white" }}
                    size="small"
                    variant="contained"
                    onClick={handleClick}
                  >
                    Pay Now
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
    </Grid>
  );
};
export default PayNowBox;
