import CircularProgress from "@material-ui/core/CircularProgress";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/styles";

export const CustomInput = withStyles({
  input: {
    "&::placeholder": {
      color: "rgba(49, 69, 106, 0.5)",
      marginLeft: "10px",
    },
    minWidth: "320px",
    paddingLeft: "15px",
    height: "42px",
    boxShadow: "inset 2px 2px 5px #D1D9E6",
    border: "double 1px transparent",
    borderRadius: "12px",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(130deg, #fff, #D1D9E6)",
    backgroundOrigin: "border-box",
  },
})(InputBase);

export const CustomSelect = withStyles({
  // root: {
  //     // marginLeft: '-7px',
  //     borderColor: '#60c6a8',
  //     minWidth: '320px',
  //     boxShadow: 'rgb(209, 217, 230) 2px 2px 5px inset',
  //     paddingLeft: '8px',
  //     borderRadius: '12px',
  //     backgroundImage: 'linear-gradient(white, white), linear-gradient(130deg, rgb(255, 255, 255), rgb(209, 217, 230))',
  //     backgroundOrigin: 'border-box',
  //     "&:hover, &:focus":{
  //         borderColor: '#60c6a8',
  //     },
  // },
  select: {
    paddingLeft: "5px",
    "&:focus": {
      background: "inherit",
    },
  },
})(Select);

export const CustomOutline = withStyles({
  root: {
    // marginLeft: '-7px',
    height: "57px",
    padding: "0px 15px",
    borderColor: "#60c6a8",
    minWidth: "337px",
    boxShadow: "rgb(209, 217, 230) 2px 2px 5px inset",
    paddingLeft: "8px",
    borderRadius: "12px",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(130deg, rgb(255, 255, 255), rgb(209, 217, 230))",
    backgroundOrigin: "border-box",
    "&:hover, &:focus": {
      borderColor: "#60c6a8",
    },
    borderWidth: "0px !important",
  },
  outlined: {
    borderWidth: "10px !important",
  },
})(InputBase);

export const CustomProgress = withStyles({
  root: {
    color: "#60c6a8",
  },
})(CircularProgress);

export const CustomLabel = withStyles({
  root: {
    color: "#31456A",
    // display: 'none',
  },
  shrink: {
    display: "none",
  },
})(InputLabel);
