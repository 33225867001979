import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { Slide } from "@material-ui/core";

export const useStyles1 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "1700px",
    overflow: "auto",
    padding: "4% 8% 8% 8%",
    [theme.breakpoints.down("sm")]: {
      padding: "4% 2% 2% 2%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "20%",
    },
    height: "3452px",
    top: "20px",
    background: "#ECF0F3",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "#FAFAFA",
    boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    borderRadius: "10px",
  },
}));
export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
    borderRadius: "10px",
    padding: 3,
  },
  tab: {
    textTransform: "none",
    fontWeight: "bold",
    minWidth: 0,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  selectedTab: {
    color: theme.palette.primary.main,
  },
}));
export const useStyles3 = makeStyles({
  Table: {
    width: "100%",
    borderBottom: "none",
    "& .MuiTableCell-root": {
      borderBottom: "none",
    },
  },

  feeFontHeaderStyle: {
    fontSize: "12px",
    padding: "2px 10px",
    lineHeight: 1.2,
    border: "1px solid #000",
  },
  feeFontStyle: {
    fontWeight: 600,
    fontSize: "12px",
    padding: "2px 10px",
    lineHeight: 1.2,
    border: "1px solid #000",
  },
  fontstyle: {
    fontWeight: 600,
    fontSize: "12px",
    padding: "2px 10px",
    lineHeight: 1.2,
  },
  key: {
    fontWeight: 500,

    fontSize: "12px",
    padding: "2px 10px",
    lineHeight: 1.2,
    minWidth: 150,
  },
  FeeRowHeight: {
    height: 3,
    fontSize: "12px",
    border: "1px solid #000",
  },
  rowHeight: {
    height: 3,
    fontSize: "12px",
  },
});
export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const useStyles4 = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 100,
      height: 20,
    },
  },
  totalColor: {
    color: "blue",
  },
}));
export const useStyles5 = makeStyles({
  table: {
    minWidth: 620,
  },
  boldCell: {
    fontWeight: "bold",
  },
  oddRow: {
    backgroundColor: "#F5F5F5", // Change this to the desired background color for odd rows
  },
  tableHead: {
    backgroundColor: "#DCDCDC",
  },
  tableTotal: {
    color: "blue",
  },
});
