import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

// material ui-
import Typography from '@material-ui/core/Typography';
import { Paper, Button } from '@material-ui/core';

// icons
import { ReactComponent as GradCap } from '../../../../assets/RegisterExamIcons/graduation-cap.svg';
import { ReactComponent as Bicycle } from '../../../../assets/RegisterExamIcons/bicycle.svg';
import { ReactComponent as GadgetGift } from '../../../../assets/RegisterExamIcons/gadget-gift.svg';
import { ReactComponent as Medal } from '../../../../assets/RegisterExamIcons/medal.svg';
import { ReactComponent as OnlineClasses } from '../../../../assets/RegisterExamIcons/online-class.svg';
import { ReactComponent as Rupee } from '../../../../assets/RegisterExamIcons/rupee-sign.svg';
import image06 from '../../../../assets/RegisterExamIcons/06_.png';
import image11 from '../../../../assets/RegisterExamIcons/11_.png';
import image07 from '../../../../assets/RegisterExamIcons/07_.png';
import image21 from '../../../../assets/RegisterExamIcons/21_.png';
import biop from '../../../../assets/RegisterExamIcons/BIOP.png';
import image01 from '../../../../assets/RegisterExamIcons/01_.png';
import model from '../../../../assets/RegisterExamIcons/b-model.png';
import title from '../../../../assets/RegisterExamIcons/B-SAT.png'

// styles
import bSatStyles from './RegisterForBsat.styles';

const RegisterExamCard = () => {
  const classes = bSatStyles();
  const myRef = useRef(null);
  return (
    <div className={classes.root} ref={myRef}>
      <div className={classes.mainContent}>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <div className={classes.cardHead}>
              <img
                variant="square"
                className={classes.logo}
                src={image01}
              />
            </div>
            <div className={classes.headerButtonsContainer}>
              <Link to='/sign-in'>
                <Button
                  color='primary'
                  size='small'
                  variant='contained'
                  className={classes.headerButton}
                >
                  Sign In
                </Button>
              </Link>
              <Button
                size="small"
                color="primary"
                variant="contained"
                className={classes.headerButton}
                onClick={() => {
                  window.scrollTo({
                    top: myRef.current.offsetTop + myRef.current.offsetHeight - 100,
                    left: 0,
                    behavior: 'smooth'
                  })
                }}
              >
                Register
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.content_1Container}>
          <Typography variant="h5" className={classes.content_1}>
            India's genuine talent search begins here
          </Typography>
        </div>
        <div className={classes.subContent}>
          <Paper className={classes.content_2}>
            <div className={classes.titleContainer}>
              <img className={classes.title} src={title} />
            </div>
            <div className={classes.registerButton}>
              <Button
                size="medium"
                color="primary"
                variant="contained"
                onClick={() => {
                  window.scrollTo({
                    top: myRef.current.offsetTop + myRef.current.offsetHeight - 100,
                    left: 0,
                    behavior: 'smooth'
                  })
                }}
              >
                Register
              </Button>
            </div>
          </Paper>
          <div className={classes.content_3Container}>
            <div className={classes.modelContainer}>
              <div className={classes.content_3}>
                <Typography variant="h5" className={classes.content_3Heading}>
                  Scholarship worth in Crores
                  {/* for students in grade 4 to grade 10 */}
                </Typography>
                <div className={classes.iconsContainer}>
                  <div className={classes.item}>
                    <div className={classes.icon}>
                      <GradCap className={classes.iconContainer} />
                    </div>
                    <Typography
                      variant={'span'} className={classes.iconDescription}
                    >
                      Upto 100% scholarship
                    </Typography>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.iconContainer}>
                      <Rupee />
                    </div>
                    <Typography
                      variant={'span'} className={classes.iconDescription}
                    >
                      Cash prizes upto 1 lakh
                    </Typography>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.iconContainer}>
                      <Medal />
                    </div>
                    <Typography
                      variant={'span'} className={classes.iconDescription}
                    >
                      Gold/ Silver/ Bronze medals
                    </Typography>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.iconContainer}>
                      <GadgetGift className={classes.iconContainer} />
                    </div>
                    <Typography
                      variant={'span'} className={classes.iconDescription}
                    >
                      Laptop, Tablet, Mobile phone
                    </Typography>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.iconContainer}>
                      <Bicycle />
                    </div>
                    <Typography
                      variant={'span'} className={classes.iconDescription}
                    >
                      Sports bicycle
                    </Typography>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.iconContainer}>
                      <OnlineClasses />
                    </div>
                    <Typography
                      variant={'span'} className={classes.iconDescription}
                    >
                      Free online coaching
                    </Typography>
                  </div>
                </div>
              </div>
              <img src={model} className={classes.model} />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.secContent}>
        <Paper className={classes.programsContainer}>
          <Typography variant={'h5'}>
            Bhashyam's top range programs
          </Typography>
          <div className={classes.programs}>
            <div className={classes.imageContainer}>
              <img
                variant="square"
                className={classes.square}
                alt='iit'
                src={image06}
              />
            </div>
            <div className={classes.imageContainer}>
              <img
                variant="square"
                className={classes.square}
                alt='iit'
                src={biop}
              />
            </div>
            <div className={classes.imageContainer}>
              <img
                variant="square"
                className={classes.square}
                src={image21}
              />
            </div>
            <div className={classes.imageContainer}>
              <img
                variant="square"
                className={classes.square}
                src={image11}
              />
            </div>
            <div className={classes.imageContainer}>
              <img
                variant="square"
                className={classes.square}
                src={image07}
              />
            </div>
          </div>
        </Paper>
        <div className={classes.datesContainer}>
          <Paper className={classes.datePaper}>
            <Typography className={classes.dateText}>
              {/* Online Exam dates: Jan 9<sup>th</sup>, 10<sup>th</sup>, 11<sup>th</sup>, 12<sup>th</sup> & 13<sup>th</sup> */}
              <Typography style={{margin: '10px', textAlign: 'start'}}>Online Exam dates:</Typography>
              <Typography style={{display: 'inline', color: '#31456A'}}>JAN</Typography>
              <Typography className={classes.date}>9<sup>th</sup></Typography>
              <Typography className={classes.date}>10<sup>th</sup></Typography>
              <Typography className={classes.date}>11<sup>th</sup></Typography>
              <Typography className={classes.date}>12<sup>th</sup></Typography>
              <Typography className={classes.date}>13<sup>th</sup></Typography>
            </Typography>
            <Typography className={classes.classNum}>
              Present studying classes from 4<sup>th</sup> to 10<sup>th</sup>
            </Typography>
            <Typography className={classes.timeSlot}>
              Exam time slot 11 AM - 12:15 PM
            </Typography>
          </Paper>
        </div>
      </div>
    </div>
  )
};

export default RegisterExamCard;

{/*  */ }