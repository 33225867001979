import { makeStyles, withStyles } from '@material-ui/core/styles'

export const useStyles1 = makeStyles(theme => ({
  root: {
    flexGrow: 1,

    padding: '4% 7% 4% 7%',
    [theme.breakpoints.down('sm')]: {
      padding: '4% 2% 2% 2%'
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    background: '#FAFAFA',
    boxShadow: '0px 5px 10px rgba(142, 154, 175, 0.1)',
    borderRadius: '10px'
  },
  borderNone: {
    border: 'none'
  }
}))
export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),

    textAlign: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    background: '#EBEFF3',
    boxShadow: `4px 4px 8px rgba(189, 194, 199, 0.75),
                8px 8px 12px rgba(189, 194, 199, 0.25),
                -4px -4px 8px rgba(255, 255, 255, 0.75),
                -8px -8px 12px rgba(255, 255, 255, 0.25)`,
    borderRadius: '16px'
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '20px',
    gap: '16px',
    // marginTop: isMobile ? "20px" : "56px",
    marginBottom: '20px',
    marginRight: '20px'
  },
  title: {
    color: '#2A2A2A',
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: 700,
    fontFamily: 'Quicksand',
    padding: '16px 0',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px' // Desktop
    }
  },
  icon: {
    fontSize: '25px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '27px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '27px' // Desktop
    },
    fontWeight: 700,
    color: '#2A2A2A'
  },
  dateGrid: {
    marginTop: '8px',
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '4px'
    // gap: "1px",
    // padding: "1px",
    // width: "fit-content",
  },
  date: {
    padding: theme.spacing(1),
    cursor: 'pointer',
    // margin: "2px",
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '16px'
    // backgroundColor: "#fff",
  },
  selectedDate: {
    backgroundColor: '#3358B8',
    color: 'white'
  },
  selectIcon: {
    color: '#3358B8', // Change this to your desired color
    height: '23px',
    width: '23px'
  },
  monthSelect: {
    marginBottom: theme.spacing(1),
    width: '100%',
    fontFamily: 'Quicksand',
    fontWeight: 700,
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px' // Desktop
    },
    lineHeight: '24px',
    color: '#3358B8',
    padding: '0px',
    height: '20px',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center'
  },
  noUnderline: {
    '&:before': {
      borderBottom: 'none'
    },
    '&:after': {
      borderBottom: 'none'
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    }
  },
  dateKey: {
    // width: 'fit-content',
    textAlign: 'center',

    fontWeight: 600,
    fontSize: '14px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '16px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px' // Desktop
    },
    lineHeight: '20px',
    color: '#2A2A2A'
  },
  dateValue: {
    fontWeight: 400,
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px' // Desktop
    },
    lineHeight: '20px',
    textAlign: 'center',
    color: '#2A2A2A'
    // margin: "2px",
    // width: 'fit-content'
  },
  activeBtn: {
    flexShrink: 0,
    flexGrow: 0,
    borderRadius: 40,
    marginLeft: '4px',
    width: 'fit-content',
    fontSize: '13px',
    fontSize: '13px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '15px' // Desktop
    }
  },
  inActiveBtn: {
    boxShadow: '4px 4px 8px 0px #FFFFFFBF',
    boxShadow: '2px 2px 8px 0px #BDC2C7BF inset',
    flexShrink: 0,
    flexGrow: 0,
    border: '1px solid #D4D4D4',
    background: '#EBEFF3',
    borderRadius: 40,
    marginLeft: '4px',
    color: '#2A2A2A !important',
    width: 'fit-content',
    fontSize: '13px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '15px' // Desktop
    }
  },
  cardMargins: {
    marginLeft: '26px',
    marginRight: '26px'

    // paddingTop: '20px'
  },
  weekday: {
    fontWeight: 600,
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px' // Desktop
    },
    lineHeight: '20px',
    color: '#2A2A2A',
    alignItems: 'center',
    justifyContent: 'center'
  },
  testTypeButtons: {
    flexDirection: 'row',
    padding: '20px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '8px',
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    background: '#EBEFF3'
  }
}))
