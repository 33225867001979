import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import studentPortalImg from "./logos/studentPortal1.png";
import magicPen from "./logos/magic wand.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(4),
    margin: theme.spacing(4),
    width: "1446px",
    height: "auto",
    boxShadow: "0px 0px 48px 0px rgba(49, 69, 106, 0.08)",
    backgroundColor: "#ffffff",
    overflow: "hidden",
    // position: "absolute",
    borderRadius: "24px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "50%", // Adjust the width here to take up 50% of the card's width
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: "50%", // Adjust the width here to take up 50% of the card's width
  },
  gradientBox: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    backgroundImage: "linear-gradient(to bottom, #4A4579, #39A6D5)", // Change these colors as needed
  },
  imgBox: {
    padding: 20,
  },
  sendMessage: {
    textTransform: "none",
    height: "56px",
    width: "200px",
    background: "#08C5A7",
    boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
    borderRadius: "16px",
    // fontFamily: 'Montserrat',
    marginTop: 12,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "100%",
    /* or 18px */
    textAlign: "center",
    color: "#FFFFFF",
    "&:hover": {
      background: "#0eab8f",
      transform: "scale(1.5)",
    },
  },
  heading: {
    marginBottom: "10px",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "45px",
    lineHeight: "75px",
    color: "#31456A",
  },
  backgroundText: {
    marginBottom: "10px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "48px",
    lineHeight: "74px",
    // color: "#FFFF",
    width: "auto",
    opacity: 0.3,

    color: "#ffffff",

    lineHeight: "56px",
    position: "relative",
  },
  subHeading: {
    marginBottom: "40px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "25px",
    lineHeight: "54px",
    color: "#31456a",
  },
  gradientText: {
    backgroundImage: "linear-gradient(to bottom, #4A4579, #39A6D5)",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    color: "transparent",
    fontWeight: 600,
    fontSize: "20px",
  },
}));

export default function MediaControlCard() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography className={classes.gradientText}>
            Student portal
          </Typography>
          <Typography className={classes.heading}>
            Learning Beyond the Classroom &nbsp;
            <img src={magicPen} alt="magic pen" />
          </Typography>
          <Typography className={classes.subHeading}>
            Easy access classes, tests, resources, and updates for collaborative
            education. Elevate your learning journey now!
          </Typography>
          &nbsp; &nbsp; &nbsp;
          <Button className={classes.sendMessage}> Explore Portal</Button>
        </CardContent>
      </div>
      <CardMedia className={classes.cover} title="Live from space album cover">
        {/* <Box className={classes.gradientBox}> */}
        <Box className={classes.imgBox}>
          {/* <Typography variant="button" className={classes.backgroundText}>
              Discover the Future
            </Typography> */}
          {/* <Box style={{ border: "3px solid #31456A", borderRadius: "10px" }}> */}
          <img
            src={studentPortalImg}
            alt="studentPortalimg"
            style={{ width: "100%", height: "100%", borderRadius: "10px" }}
          />
          {/* </Box> */}
        </Box>
        {/* </Box> */}
      </CardMedia>
    </Card>
  );
}
