import React from 'react';

// Material helpers
import { Button, makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

// components 
import DateAndTimeslot from './DateAndTimeslot';
import SyllabusModal from './SyllabusModal';


const examCardStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderTop: '1.2px solid white',
    boxShadow: '-8px -8px 20px #ffffff, 8px 8px 20px #d1d9e6',
    borderLeft: '1.2px solid white',
    borderRadius: '24px',
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: '20px',
    margin: '0px'
  },
  title: {
    color: 'white'
  },
  actions: {
    flexDirection: 'column'
  },
  dateTimeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'spaceBetween'
  },
  bookButton: {
    margin: '20px 0px',
  }
}))

const BookExamCard = ({ grade, examType, data, date, time, handleDateAndTime, handleBookExam }) => {
  const classes = examCardStyles()
  const title = `Grade ${grade}: ${examType === 'Foundation' ? '' : examType} Foundation`
  return (
    <Card className={classes.root}>
      <CardHeader
        title={title}
        className={classes.header}
        classes={{ title: classes.title }}
      ></CardHeader>
      <CardContent>
        <div>

        </div>
        <div className={classes.dateTimeContainer}>
          <DateAndTimeslot
            studentClass={grade}
            examType={examType}
            handleExamDateAndTime={handleDateAndTime}
            pulledDateTimeSlots={data.testSlotDetails}
            selectedDate={date}
            selectedTimeSlot={time}
          />
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        <SyllabusModal
          grade={grade}
          examType={examType}
          examDetails={data.examDetails}
        />
        <Button
          color='primary'
          size='medium'
          variant='contained'
          className={classes.bookButton}
          style={{ marginLeft: '0px' }}
          disabled={
            date && time ? false : true
          }
          onClick={handleBookExam}
        >
          Register at &#8377; 100
        </Button>
      </CardActions>
    </Card>
  )
}

export default BookExamCard;