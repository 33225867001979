import axios from "axios";

import API_CONST from "../constants";

var baseAxios = axios.create({
  baseURL: API_CONST.BASE_URL,
  //port : API_CONST.PORT,
  //timeout: 1000,
  //headers: {'X-Custom-Header': 'foobar'},
  withCredentials: true
});

export const adminAxios = axios.create({
  baseURL: API_CONST.ADMIN_URL,
  withCredentials: true
});

export default baseAxios;
