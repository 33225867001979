import React, { createContext, useContext, useState, useCallback } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import theme from "../../assets/v3/theme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import AlertComponent from "../../mycomponents/AlertComponent";

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "info",
    customActions: [],
  });

  const openSnackbar = useCallback(
    (message, severity = "info", snack = true, custom = {}) => {
      if (snack) {
        setSnackbar({ open: true, message, severity });
      } else {
        const { customActions, ...rest } = custom;
        setAlert({
          open: true,
          message,
          alertType: severity,
          customActions,
          ...rest,
        });
      }
    },
    []
  );

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar }}>
      {children}
      <ThemeProvider theme={theme}>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={closeSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <MuiAlert
            onClose={closeSnackbar}
            severity={snackbar.severity}
            variant="filled"
          >
            {snackbar.message}
          </MuiAlert>
        </Snackbar>

        <AlertComponent
          open={alert.open}
          closeAlert={() => setAlert({ ...alert, open: false })}
          ifThemeRequired={false}
          newTheme
          {...alert}
        >
          {alert.message}
        </AlertComponent>
      </ThemeProvider>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);
