import React from "react";
import { Tab, Tabs, withStyles } from "@material-ui/core";

const OnTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontSize: "16px",
    lineHeight: "20px",
    background: "#EBEFF3",
    border: "1px solid #D4D4D4",
    boxShadow: "inset 0 0 10px rgba(0,0,0,0.1)",
    borderRadius: "40px",
    boxSizing: "border-box",
    marginRight: "2%",
    "&:focus": {
      opacity: 1,
      color: "#FAFAFA",
    },
  },
  selected: {
    color: "#FAFAFA",
    background: theme.palette.primary.main,
    boxShadow:
      "4px 4px 8px rgba(189, 194, 199, 0.75), 8px 8px 12px rgba(189, 194, 199, 0.25)", // Drop shadows
    borderRadius: "40px",
  },
}))((props) => <Tab {...props} />);
const OnFeeTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "13px",
    background: "#EBEFF3",
    border: "1px solid #D4D4D4",
    boxShadow: "inset 0 0 10px rgba(0,0,0,0.1)",

    borderRadius: "40px",
    boxSizing: "border-box",
    marginRight: "2%",

    "&:focus": {
      opacity: 1,
      color: "#FAFAFA",
    },
  },
  selected: {
    color: "#FAFAFA",
    background: "#003366",
    boxShadow:
      "4px 4px 8px rgba(189, 194, 199, 0.75), 8px 8px 12px rgba(189, 194, 199, 0.25)", // Drop shadows
    borderRadius: "40px",
    height: "20px",
  },
}))((props) => <Tab {...props} />);
const FeeTab = withStyles((theme) => ({
  root: {
    lineHeight: "14px",

    fontSize: "14px",
  },
}))((props) => <OnFeeTab {...props} />);
const ExamTab = withStyles((theme) => ({
  root: {
    lineHeight: "18px",

    fontSize: "14px",
  },
}))((props) => <OnTab {...props} />);
const ExamTabs = withStyles({
  indicator: {
    backgroundColor: "transparent",
    float: "left",
  },
  scroller: {
    padding: "20px 0", // Adjust the padding value as needed
  },
  root: {
    gap: "8px",
  },
})((props) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons="off"
    TabIndicatorProps={{ children: <div /> }}
    indicatorColor="none"
    classes={{ scroller: props.classes.scroller, root: props.classes.root }}
  />
));
export { ExamTab, ExamTabs, FeeTab };
