import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { createStyles, withStyles } from "@material-ui/styles";
import { getCourseSubjects, getMeetings } from "apis/videoService";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import store from "store";
import theme from "../../assets/theme";
import Button from "../../components/CustomButtons/Button";
import RedirectUnpaidPublicUsers from "../../utils/RedirectUnpaidPublicUsers";
import BottomNav from "../BottomNav";
import NetworkProgress from "../NetworkProgress.js";
import { OnTabs, SubjectTab } from "./TabNavigation";
import "./VideoClasses.css";
import VideoListComponent from "./VideoListComponent";
import ResultHeader from "../resultPage/ResultHeader.jsx";

function compare(a, b) {
  if (a.createdDate > b.createdDate) {
    return -1;
  }
  if (a.createdDate < b.createdDate) {
    return 1;
  }
  return 0;
}

const styles = () =>
  createStyles({
    videoBg: {
      minHeight: "90vh",
      height: "max-content",
      overflow: "scroll",
      minWidth: "100vw",
      background: "#F0F3F5",
    },
    videoCount: {
      color: "#31456A",
      fontSize: "30px",
      lineHeight: "37px",
      fontWeight: "bold",
      marginLeft: "5px",
    },
    "@media(max-width: 600px)": {
      videoCount: {
        marginLeft: "2%",
      },
    },
    selectMenu1: {
      background: "#EFF2F4",
    },
    videoPlayer: {
      marginTop: "20px",
      height: "100%",

      // width: '100%',
      minHeight: "400px",
      overflow: "scroll",
    },
    pagination: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10%",
        padding: "3%",
      },
    },
    watchNow: {
      marginTop: "20px",
      textTransform: "none",
      height: "56px",
      width: "200px",
      background: "#08C5A7",
      boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
      borderRadius: "16px",
      // fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "18px",

      /* or 18px */
      textAlign: "center",
      color: "#FFFFFF",
      "&:hover": {
        background: "#0eab8f",
      },
    },
    customTabRoot: {
      color: "#08c5a7",
      textTransform: "capitalize",
      height: "34px",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
    },
    customTabIndicator: {
      background: "#08c5a7",
    },
  });

const VideoClasses = (props) => {
  // const [selectedValue, setSelectedValue] = useState(0);
  const exam = store.get("startedExam");

  const [selectedSubject, setSelectedSubject] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [netReq, setNetReq] = useState(false);
  const [videos, setVideos] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subjectsMap, setSubjectsMAp] = useState({});
  const changeSubject = (event, subject) => {
    setSelectedSubject(subject);
    setPageNumber(1);
  };

  const getVideos = async (subject, page) => {
    try {
      let subMap = {};

      const course = store.get("user").course;
      const classVal = store.get("user").classVal;
      const courseClass = classVal + "_" + course;

      setNetReq(true);

      let instituteId = store.get("user").institute;

      if (store.get("user").level) {
        instituteId = [
          store.get("user").institute,
          store.get("user").level,
        ].join("_");
      }

      if (store.get("user") && course && classVal) {
        let subjects = await getCourseSubjects(courseClass);
        if (subjects) {
          subjects = subjects[courseClass].subjects;

          if (courseClass) setSubjects(subjects);

          subjects.map((sub, index) => {
            subMap[index] = sub.value;
          });
        }
      } else {
        subMap = {
          0: "M",
          1: "P",
          2: "C",
          3: "G",
        };
        setSubjects([
          {
            name: "MATHEMATICS",
            value: "M",
          },
          {
            name: "PHYSICS",
            value: "P",
          },
          {
            name: "CHEMISTRY",
            value: "C",
          },
          {
            name: "GENERAL",
            value: "G",
          },
        ]);
      }

      setSubjectsMAp({
        ...subjectsMap,
        subMap,
      });
      const val = await getMeetings({
        instituteId: instituteId || "mockInstituteId",
        subject: subMap[subject],
        pageNumber: page,
      });
      if (val) {
        setVideos(val.data.payload);
      }
      setNetReq(false);
    } catch (err) {
      setNetReq(false);
    } finally {
      setNetReq(false);
    }
  };
  // pagination buttons controller
  const handlePagination = (direction) => {
    direction === "previous"
      ? setPageNumber(pageNumber - 1)
      : setPageNumber(pageNumber + 1);

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getVideos(selectedSubject, pageNumber);
  }, [selectedSubject, pageNumber]);
  const { classes } = props;
  // handle unpaid users
  if (RedirectUnpaidPublicUsers()) {
    return <Redirect to="/plans" />;
  }

  if (exam && exam.test_status === "STARTED") {
    return (
      <>
        <br />
        <ResultHeader testDisplayName="View Library" path="/home-page" />
        <br />
        <Typography>{`Please, Submit ongoing Test : ${
          exam.testDisplayName
        } Before accessing this feature!`}</Typography>
      </>
    );
  }

  return (
    <Fragment>
      <div className={classes.videoBg}>
        <ResultHeader testDisplayName="Previous Classes" path="/home-page" />
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid
            item
            xs={11}
            sm={10}
            md={8}
            style={{
              height: "fit-content",
            }}
          >
            {" "}
            <br />
            <Hidden xsUp>
              {subjects ? (
                <OnTabs value={selectedSubject} onChange={changeSubject}>
                  {/* <Fragment> */}
                  {subjects.map((subject) => {
                    return <SubjectTab label={subject.name}> </SubjectTab>;
                  })
                  // </Fragment>
                  }
                </OnTabs>
              ) : (
                ""
              )}
            </Hidden>
            {/* <Hidden smUp> */}
            {subjects ? (
              <div className="tabContainer">
                <OnTabs value={selectedSubject} onChange={changeSubject}>
                  {/* <Fragment> */}
                  {subjects.map((subject) => {
                    return <SubjectTab label={subject.name}> </SubjectTab>;
                  })
                  // </Fragment>
                  }
                </OnTabs>
              </div>
            ) : (
              ""
            )}
            {/* </Hidden> */}
          </Grid>
          <Grid className={classes.root} item xs={12} sm={10} md={8}>
            <br />
            <br />

            <Typography className={classes.videoCount}>
              <span
                style={{
                  color: "#FF0000",
                }}
              >
                {videos && videos.length === 0
                  ? "No Previous Classes Available"
                  : videos.length}
              </span>
            </Typography>
            {videos &&
              videos.sort(compare).map((v, index) => {
                let time = moment(v.createdDate).format("MMM Do YY");
                return (
                  <VideoListComponent
                    key={index}
                    topic={v.topic}
                    password={time}
                    data={v}
                    to={{
                      pathname: "/play-video",
                      state: {
                        live: v.live_url.split("/")[0],
                        chat: v.live_url,
                        provider: v.provider,
                      },
                    }}
                  />
                );
              })}
            <br />
            {/* pagination  */}
            {videos && videos.length > 0 && (
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.pagination}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handlePagination("previous")}
                  startIcon={<ArrowLeftIcon />}
                  disabled={pageNumber === 1}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handlePagination("next")}
                  disabled={videos.length < 20}
                  endIcon={<ArrowRightIcon />}
                >
                  Next
                </Button>
              </Grid>
            )}
            <br />
          </Grid>

          <Grid item xs={6} md={8} lg={6}>
            {netReq ? <NetworkProgress /> : null}
          </Grid>
        </Grid>
      </div>
      <BottomNav />
      {/* <Footer></Footer> */}
    </Fragment>
  );
};

export default withStyles(styles)(VideoClasses);
