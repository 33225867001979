import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import theme from "../../assets/theme";
import AnswerGrid from "./AnswersGrid";
import TotalMarks from "./TotalMarks";
const useStyles = makeStyles(() => ({
  answerDiv: {
    background: theme.palette.common.white,
    padding: "16px",
    borderRadius: "0px 0px 10px 10px",
    filter: "drop-shadow(0px 5px 10px rgba(142, 154, 175, 0.1))",
  },
  ansGrid: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
  qNoTiles: {
    gap: "12px",
  },
}));
function MarkedAnsGrid({ markedAns, subject, timeTag }) {
  const classes = useStyles();
  return (
    <div className={classes.answerDiv}>
      <Grid container className={classes.ansGrid} spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container className={classes.qNoTiles}>
            <AnswerGrid marked={markedAns} timeTag={timeTag} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TotalMarks subjects={subject} />
        </Grid>
      </Grid>
    </div>
  );
}

export default MarkedAnsGrid;
