import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { Button, Grid, makeStyles, Paper } from "@material-ui/core";
import FinanceDash from "./logos/FinanceDash.png";
import finCahrt from "./logos/finChart.png";
import attendance from "./logos/attendancedash.png";
import userMangement from "./logos/userManagementDash.png";
import Example from "./example";
import Xarrow from "react-xarrows";

const imagesData = {
  "Branch Analysis": [FinanceDash],
  Fiance: [finCahrt],
  "User Management": [userMangement],
  // Admission: ["image7.jpg", "image8.jpg"],
  Attendance: [attendance],
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: "40px",
    marginTop: "40px",
  },
  buttonContainer: {
    marginBottom: theme.spacing(2),
    marginTop: "2px",
    display: "flex",
    justifyContent: "center", // Center items horizontally
  },
  button: {
    marginRight: theme.spacing(2),
    margin: "2px",
  },
  imageContainer: {
    width: "100%", // Set width to 100% to occupy the full width of the container
    // height: "150vh", // Set height to 100vh to occupy the full viewport height
    display: "flex", // Use flexbox layout
    justifyContent: "center", // Center items horizontally
    alignItems: "center", // Center items vertically
  },
  image: {
    width: "70%",
    height: "70%",
  },
  messageBox: {
    position: "relative",
    top: "50px",
    left: "50px",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
  },
  imageWrapper: {
    position: "relative",
  },
}));

const ImageGallery = () => {
  const classes = useStyles();
  const [activeButton, setActiveButton] = useState("Branch Analysis");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            style={{
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: "800",
              fontSize: "45px",
              lineHeight: "44px",
              color: "#31456A",
            }}
          >
            Our Solutions that will blow your mind
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight="bold"
            style={{
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: "800",
              fontSize: "22px",
              lineHeight: "44px",
              color: "#31456A",
            }}
          >
            Vital KPI’s, Real-time updates, Customizable dashes
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column">
            <Grid item className={classes.buttonContainer}>
              {Object.keys(imagesData).map((buttonKey) => (
                <Button
                  key={buttonKey}
                  id={buttonKey}
                  className={classes.button}
                  style={{
                    background:
                      activeButton === buttonKey ? "#3BBDED" : "#BDC3CC",
                    color: activeButton === buttonKey ? "#FFFFFF" : "#55637D",
                    // fontFamily: "Montserrat",
                    fontSize: "17px",
                    fontweight: 900,
                    lineHeight: "34px",
                    // letterSpacing: "2px",
                    textAlign: "center",
                    borderRadius: "12px",

                    border: "none",
                  }}
                  variant="contained"
                  // variant={activeButton === buttonKey ? "contained" : "outlined"}
                  color="primary"
                  onClick={() => handleButtonClick(buttonKey)}
                >
                  &nbsp;&nbsp;{buttonKey} &nbsp;&nbsp;
                </Button>
              ))}
            </Grid>
            <Grid item className={classes.imageContainer}>
              {/* <Example/> */}
              {activeButton &&
                imagesData[activeButton].map((image, index) => (
                  // <Paper
                  //   key={index}
                  //   elevation={3}
                  //   style={{
                  //     justContent: "center",
                  //     alignCenter: "center",
                  //     alignContent: "center",
                  //   }}
                  // >
                  // </Paper>

                  <img
                    className={classes.image}
                    src={image}
                    alt={`Image ${index + 1}`}
                  />
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ImageGallery;
