import React from "react";
// import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
// import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.jsx";
import "./AdvanceNumbersPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Assignment from "@material-ui/icons/Assignment";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
const options = ["A", "B", "C", "D"];
const styles = (theme) => ({
  fab: {
    borderRadius: "0px",
    //  backgroundColor: 'unset',
    width: "40px",
    height: "40px",
    fontSize: "14px",
    fontWeight: "bolder",
    boxShadow: "none",
    //  width: 'max-content',
    backgroundSize: "90% 90% !important",
    // margin: theme.spacing(0.5),
    // width: theme.spacing(6),
    // height: theme.spacing(6),
    // backgroundColor: 'white',
    // color: '#474747',
    // border: 'solid 2px grey'
  },
  qdialogpanel: {
    margin: "24px",
    minHeight: "max-content",
    maxHeight: "max-content",
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class QuestionPaperDialog extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.props = props;
    this.state = {
      open: false,
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    //  [open, setOpen] = React.useState(false);
  }
  componentDidUpdate() {
    const math = document.getElementById("student-mat");

    // window.MathJax.typeset();
    if (math)
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, math, () => {}]);
  }
  // componentDidUpdate() {

  //     }
  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const { classes } = this.props;
    // console.log(this.props);
    const questions = this.props.questions.map((question) => {
      let html_current_question = {};
      html_current_question.question_number = question.question_number;
      html_current_question.q = (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <div dangerouslySetInnerHTML={{ __html: question.q }} />
        </Grid>
      );
      options.forEach((option) => {
        html_current_question[option] = (
          <span dangerouslySetInnerHTML={{ __html: question[option] }} />
        );
      });
      if (question.paragraph !== undefined && question.paragraph !== "NA")
        html_current_question.paragraph = (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <div dangerouslySetInnerHTML={{ __html: question.paragraph }} />
          </Grid>
        );
      let val = "";
      switch (question.q_type) {
        /// for single option
        case "list":
        case "single":
          val = (
            <React.Fragment>
              <Typography
                component="div"
                className={classes.qdialogpanel}
                style={{
                  padding: "0px 24px",
                  minWidth: "100%",
                  maxWidth: "100%",
                }}
              >
                {/* {sectionInstruction} */}
                {html_current_question.paragraph !== undefined && (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        Paragraph
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component="div">
                        {html_current_question.paragraph}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}
                <Divider variant="middle" />
                <div>
                  {" "}
                  <h5 className="question">
                    {" "}
                    Question({html_current_question.question_number})
                  </h5>
                </div>
                {html_current_question.q}
                {options.map((option) => {
                  return (
                    <React.Fragment>
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                      >
                        {" "}
                        {option}) &nbsp; &nbsp;&nbsp; &nbsp;{" "}
                        {html_current_question[option]}{" "}
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Typography>
            </React.Fragment>
          );
          break;
        // for multiple options / partial
        case "mutliple":
        case "partial":
        case "single_partial":
          val = (
            <React.Fragment>
              <Typography
                component="div"
                className={classes.qdialogpanel}
                style={{
                  padding: "0px 24px",
                  minWidth: "100%",
                  maxWidth: "100%",
                }}
              >
                {/* {sectionInstruction} */}
                <div>
                  {" "}
                  <h5 className="question">
                    {" "}
                    Question({html_current_question.question_number})
                  </h5>
                </div>
                {html_current_question.q}
                {options.map((option) => {
                  return (
                    <React.Fragment>
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                      >
                        {" "}
                        {option}) &nbsp; &nbsp;&nbsp; &nbsp;{" "}
                        {html_current_question[option]}{" "}
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Typography>
            </React.Fragment>
          );
          break;
        case "integer":
        case "float":
          val = (
            <React.Fragment>
              <Typography
                component="div"
                className={classes.qdialogpanel}
                style={{
                  padding: "0px 24px",
                  minWidth: "100%",
                  maxWidth: "100%",
                }}
              >
                {/* {sectionInstruction} */}
                <div>
                  {" "}
                  <h5 className="question">
                    {" "}
                    Question({question.question_number})
                  </h5>
                </div>
                {html_current_question.q}
              </Typography>
            </React.Fragment>
          );
          break;
      }
      return (
        <React.Fragment>
          {val}
          <Divider variant="middle" />
        </React.Fragment>
      );
    });

    return (
      <React.Fragment>
        <div>
          <span
            variant="outlined"
            color="primary"
            onClick={this.handleClickOpen}
            style={{ fontWeight: "400", verticalAlign: "top" }}
          >
            <Hidden smUp>
              <Assignment style={{ color: "white", fontSize: "20px" }} />
              <span style={{ marginTop: "10px" }} />
            </Hidden>
            <Hidden xsDown>
              <Assignment style={{ color: "white", fontSize: "24px" }} />
              <span style={{ marginTop: "10px" }}>Q Paper</span>
            </Hidden>
          </span>
          <Dialog
            maxWidth="lg"
            open={this.state.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              {"Questions"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <span id="student-mat"> {questions}</span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                OK
              </Button>
              {/* <Button onClick={handleClose} color="primary">
            Agree
          </Button> */}
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(QuestionPaperDialog);
