import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// colors
import colors from '../../assets/v3/base/colors';

const useStyles = makeStyles((theme) => ({
  customButton: {
    color: colors.gradients.tirumala.main,
    textDecoration: "underline",
  },
}));

const Guidelines = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ position: 'absolute', top: 4, right: 4 }}>
      {/* ...existing code... */}
      <Button variant="text" onClick={handleClickOpen} className={classes.customButton}>
        Guidelines
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Guidelines</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <ul style={{ fontSize: '1.2em' }}>
              <li>The admission of the student can be cancelled at any time if the institute decides that his/her continuance is not in the interest of academics, affecting the progress of co-students, affecting the image of the institute.</li>
              <li>The institute management reserves the right to change at any time, any of the rules and regulations that pertain to the admission and withdrawal of students. Punctuality should be the prime concern of all students. They should not abscond or be absent from classes without prior permission.</li>
              <li>Fee once paid will not be refundable under any circumstances.</li>
              <li>Students should attend special classes, exams/Tests, assessments without fail. No re-exam will be conducted.</li>
              <li>Mutilation / spoilage of fixtures / furniture is viewed very seriously. Cleanliness of the campus is the responsibility of every Student.</li>
              <li>Students are prohibited from bringing valuables in the form of ornaments/heavy purses etc., to the institute. The institute will not be responsible for any loss.</li>
              <li>Parents / guardians are restricted from entering the class rooms / labs/ library during the class hours. They shall in no way hinder the curriculum whatso ever.</li>
              <li>Students are not permitted to use any electronic goods in the campus. If caught, they will be broken.</li>
              <li>Parents are not allowed to visit the child except on visiting days.</li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color=" primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* ...existing code... */}
    </div>
  );
};

export default Guidelines;
