export default function structureStudentProgressData(data) {
  const topicsPercentage = [];
  const drilldown = [];
  if (data && data.percentTopicWise) {
    Object.keys(data.percentTopicWise).forEach((topic) => {
      if (data.percentTopicWise[topic]) {
        topicsPercentage.push({
          name: topic,
          y: data.percentTopicWise[topic],
          drilldown: topic,
        });
        const subTopics = [];
        Object.keys(data.subTopicWisePercent[topic]).forEach((subTopic) => {
          if (data.subTopicWisePercent[topic][subTopic]) {
            subTopics.push([
              subTopic,
              data.subTopicWisePercent[topic][subTopic],
            ]);
          }
        });
        drilldown.push({
          name: topic,
          id: topic,
          data: subTopics,
        });
      }
    });
  }
  return { topicsPercentage, drilldown };
}
