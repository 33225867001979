import React from "react";
import { IconButton, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { CloseTwoTone } from "@material-ui/icons";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import ResultTable from "./ResultTable";
import progress from "../assets/book-open.png";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    background: "#FAFAFA",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    flexGrow: 1,
    fontSize: "16px",
    color: "#31456A",
    display: "flex",
    fontWeight: 600,
  },
  bgCustomDialog: {
    maxWidth: "1700px",
    overflow: "auto",

    height: "3452px",

    background: "#ECF0F3",
  },
  root: {
    flexGrow: 1,
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

export default function BackToTop(props) {
  const { subjects, avgData, results, testTypes } = props;
  // console.log(subjects, avgData, results, testTypes);
  const classes = useStyles();
  return (
    <div className={classes.bgCustomDialog}>
      <CssBaseline />
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <img
              src={progress}
              alt=""
              style={{ width: "25px", height: "25px" }}
            />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Progress Report
          </Typography>
          <Tooltip title="Close">
            <IconButton
              edge="end"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseTwoTone style={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <Container>
        <Box m={2}>
          <ResultTable
            subjects={subjects}
            avgData={avgData}
            results={results}
            testTypes={testTypes}
          />
        </Box>
      </Container>
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  );
}
