import store from "store";
// react

import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Box, Grid, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Dialog from "@material-ui/core/Dialog";
// assets
import Percentage from "../assets/percentage.png";
import avg from "../assets/average.png";
// import rank from "../assets/rank.png";
import progress from "../assets/progress.png";
import Dropdown from "../../../components/CustomDropdown/Dropdown";
// styles
import { useStyles } from "./styles";
import ProgressReport from "../ProgressReport";

const a = {
  MPC: "JEEMAIN_2022",
  BiPC: "NEET_2021",
};
const examTypes = ["JEEMAIN_2022", "NEET_2021"];
function calculateDates(selectedRange) {
  const currentDate = new Date();
  const startDate = new Date();

  if (selectedRange === "3 months") {
    startDate.setMonth(currentDate.getMonth() - 3);
  } else if (selectedRange === "6 months") {
    startDate.setMonth(currentDate.getMonth() - 6);
  } else if (selectedRange === "1 year") {
    startDate.setFullYear(currentDate.getFullYear() - 1);
  }

  const endDate = currentDate;

  // Format dates as year-month-day
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  };
}
const OverAllAnalysisCards = ({
  data1,
  fetchedData,
  testTypes,
  group,
  avgData,
  results,
  subjects,
}) => {
  const classes = useStyles();
  const course = store.get("user").course;

  const matches = useMediaQuery("(max-width:600px)");
  const [values, setValues] = useState([]);
  let matchingTypes;
  if (testTypes) {
    matchingTypes = testTypes.filter((testType) =>
      examTypes.includes(testType)
    );
  }
  useEffect(() => {
    console.log(group, a[group]);
  }, [group]);

  const [exam, setExam] = useState(a[group]);
  const [open, setOpen] = React.useState(false);
  const [percentage1, setPercentage1] = React.useState(0);
  const [avgRank, setAvgRank] = React.useState(0);
  const [marks, setMarks] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (exam === "") {
      const values1 = data1.filter((test) => test.examType === a[group]);
      setValues(values1);
    } else {
      const values1 = data1.filter((test) => test.examType === exam);
      setValues(values1);
    }
  }, [exam, data1]);
  useEffect(() => {
    const scores = values
      .map((item) => item.additionalInfo)
      .map((obj) => Object.values(obj)[0].Marks);

    function calculateAverageWithoutZeros(arr) {
      // Filter out the zero values
      const nonZeroValues = arr.filter((value) => value !== 0);

      // Calculate the sum of the non-zero values
      const sum = nonZeroValues.reduce(
        (accumulator, currentValue) =>
          Number(accumulator) + Number(currentValue),
        0
      );

      // Calculate the average
      const average = sum / nonZeroValues.length;

      return average;
    }

    const values1 = values
      .map((item) => item.additionalInfo)
      .map((i) => Object.values(i)[0].Total);

    const averageWithoutZeros = calculateAverageWithoutZeros(values1);

    const percentages = values
      .map((item) => item.additionalInfo)
      .map((obj) => Object.values(obj)[0].Percentage);

    const avgPercent = calculateAverageWithoutZeros(percentages) || 0;

    const avgScore = calculateAverageWithoutZeros(scores) || 0;

    const avgTotal = averageWithoutZeros || 0;
    setPercentage1(avgPercent);
    setAvgRank(avgRank);
    setTotal(avgTotal);
    setMarks(avgScore);
  }, [values, exam]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const parent = store.get("user").accountType === "parent";
  return (
    <>
      <Grid
        item
        container
        spacing={3}
        direction="row"
        display="flex"
        style={{ marginTop: matches ? (parent ? "500px" : "220px") : null }}
      >
        {parent ? null : (
          <Grid item xs={12} md={2}>
            <Typography className={classes.hiStudentFont}>
              Hi Student
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <Typography>Great to see you!</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Dropdown
            width={200}
            dataToDisplay={testTypes || []}
            handleDropdownValueChange={(e, item) => {
              setExam(item);
            }}
            value={exam || ""}
            textFieldProps={{
              size: "small",
              InputLabelProps: { shrink: false },
              placeholder: matchingTypes[0] || "",
              label: "exam",
            }}
          />
        </Grid>
      </Grid>

      <Grid item container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper
            item
            className={classes.progressCard}
            onClick={handleClickOpen}
          >
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <img src={progress} alt="" style={{ height: "15px" }} />
              </Grid>
              <Grid item>View Progress card</Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper item className={classes.paper}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <img src={avg} alt="" style={{ height: "15px" }} />
              </Grid>

              <Grid item className={classes.numberFontStyle}>
                Avg Score : {marks.toFixed(2)}/{total.toFixed(2)}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper item className={classes.paper}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <img src={Percentage} alt="" style={{ height: "15px" }} />
              </Grid>

              <Grid item className={classes.numberFontStyle}>
                Avg Percentage : {percentage1.toFixed(2) || 0}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Dialog
          open={open}
          fullScreen
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <ProgressReport
            data={fetchedData}
            handleClose={handleClose}
            subjects={subjects}
            avgData={avgData}
            results={results}
            testTypes={testTypes}
          />
        </Dialog>
      </Grid>
    </>
  );
};
export default OverAllAnalysisCards;
