const getImageURLsFromString = (str = "") => {
  const imageURLs = [];
  const regex = /(https?:\/\/[^\s]+\.(?:jpg|jpeg|png|gif))/g;
  let match;
  while ((match = regex.exec(str)) !== null) {
    imageURLs.push(match[0]);
  }
  return imageURLs;
};

const preloadImages = async (questions = []) => {
  const urls = [];
  questions.forEach((q) => {
    urls.push(
      ...getImageURLsFromString(q.q),
      ...getImageURLsFromString(q.A),
      ...getImageURLsFromString(q.B),
      ...getImageURLsFromString(q.C),
      ...getImageURLsFromString(q.D)
    );
  });
  try {
    return urls;
  } catch (error) {
    console.error(error);
  }
};

export { preloadImages };
