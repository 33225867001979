import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: "30px",
    lineHeight: "37px",
    fontWeight: "bold",
    marginLeft: "5px",
  },
  assignmentContainer: {
    borderRadius: "10px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "none",
    marginBottom: "1%",
    padding: "1%",
    border: "none",
    "&:before": {
      background: "none",
    },
  },
  childCss: {
    marginLeft: "auto",
    marginRight: 0,
  },
  textScroll: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "55%",
  },
  countNumber: {
    backgroundColor: theme.palette.background.paper,
    width: "52px",
    height: "64px",
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: "64px",
    borderRadius: `${theme.shape.borderRadius}`,
  },
  subtitle: {
    color: theme.palette.text.primary,
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "bold",
    marginLeft: "5px",
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
  },
  download: {
    float: "right",
  },
}));
const CustomListItem = ({ title = "", children, count }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.assignmentContainer}
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <Grid item>
        <Typography
          className={classes.countNumber}
          color="textSecondary"
          variant="subtitle2"
        >
          {count}
        </Typography>
      </Grid>
      <Grid item className={classes.textScroll}>
        <Typography variant="subtitle1">{decodeURI(title)}</Typography>
      </Grid>
      <Grid item className={classes.childCss}>
        {children}
      </Grid>
    </Grid>
  );
};

export default CustomListItem;
