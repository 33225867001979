import {
  IconButton,
  Typography,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";

import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import store from "store";
import theme from "../../assets/theme";
import bell from "../../assets/img/navigationBarAssets/bell.svg";
import { fetchNotificationsList } from "../../redux/actions/notificationsAction/actionCreator";
import NetworkProgress from "../NetworkProgress";
import NotificationContent from "./NotificationContent";
import feeDetailsStyles from "../../views/FeeDetails/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationPage from "./NotificationPage";

const useStyles = makeStyles((theme) => ({
  bell: {
    cursor: "pointer",
  },
  typography: {
    padding: theme.spacing(2),
  },
  paper: {
    maxWidth: "1200px",
    minWidth: "320px",
    maxHeight: "100%",
    overflow: "auto",

    [theme.breakpoints.down("xs")]: {
      width: "320px", // Width for mobile devices
    },
    background: "#EBEFF3",
  },
  noticeHeader: {
    padding: theme.spacing(1),
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    position: "fixed",
    display: "flex",
    zIndex: 1,
    width: "100%",
    gap: "10px",
    overflowX: "auto", // Enable horizontal scrolling
    overflowY: "hidden", // Hide vertical overflow
    whiteSpace: "nowrap", // Prevent line breaks
    display: "flex", // Flex display to align items horizontally
  },
  iconButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "46px",
    height: "46px",
    margin: "0px 16px",

    background: "#EBEFF3",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "16px",
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
  deskTopIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "25px",
    height: "25px",
    color: "white",
    // background: "#EBEFF3",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "16px",
  },
  title: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    alignContent: "center",
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 16,
    marginTop: "4px",
  },
  customBadge: {
    top: "2%", // Adjust the percentage as needed
    right: "2%", // Adjust the percentage as needed
  },
}));
const Index = ({ ifMobile }) => {
  const classes = useStyles();
  const classes1 = feeDetailsStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const mobile = useMediaQuery("(max-width:600px)");
  const { pathname } = window.location;
  const [anchorEl, setAnchorEl] = useState(null);
  const [typeList, setTypeList] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [mesgType, setMesgType] = useState("All");
  const notifications = useSelector((state) => state.notifications);
  const user = store.get("user");
  const { level = "", institute = "", loginIndex, userId } = user ? user : {}; // to be used in notification fetch body obj
  const { data, error, isLoading } = notifications;
  const [notificationCount, setNotificationCount] = useState(0);
  const [timeStampsStoredInStore, setTimeStampsStoredInStore] = useState();
  const [type, setType] = useState("All");

  const handleClick = (event) => {
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    // save unread notifications in local store
    const timeStamps = data.map((notification) => notification.created_at);
    store.set("notificationTimestamps", timeStamps);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;

  // fetch notifications based on user's level or institute
  useEffect(() => {
    const body = { instituteIdLevelId: `${institute}_${level}`, type: type };
    if (user && user.accountType && user.accountType === "parent") {
      body.userId = `${institute}_${loginIndex || userId}`;
    }

    setTimeStampsStoredInStore(store.get("notificationTimestamps"));
    dispatch(fetchNotificationsList(body));
  }, [level, institute, type]);

  // set notification counter value
  useEffect(() => {
    // if timestamps are available in store then subtract from count
    const count = timeStampsStoredInStore
      ? data.filter(
          (notification) =>
            !timeStampsStoredInStore.includes(notification.created_at)
        ).length
      : data.filter((notification) =>
          // check if notification arrived today
          moment().isSame(moment(notification.created_at), "day")
        ).length;
    const getUniqueTypes = (data) => {
      const types = data.map((notification) => notification.type);
      return ["All", ...new Set(types)];
    };

    setNotificationCount(count);

    setTypeList(getUniqueTypes(data));
  }, [data, timeStampsStoredInStore]);
  useEffect(() => {
    const filteredData = data.filter(
      (notification) => notification.type === type
    );
    if (type === "All") {
      if (user && user.accountType && user.accountType === "parent") {
        setTypeData(data.filter((notification) => notification.ttl === true));
      }
      setTypeData(data);
    } else {
      if (user && user.accountType && user.accountType === "parent") {
        setTypeData(
          filteredData.filter((notification) => notification.ttl === true)
        );
      }
      setTypeData(filteredData);
    }
  }, [type, data]);

  // const PopoverContent = () => {
  //   return (
  //     <>
  //       <div className={classes.noticeHeader}>
  //         <Grid container spacing={1}>
  //           <Grid item xs={12}>
  //             <div className={classes.title}>Notifications</div>
  //           </Grid>
  //           <Grid item container xs={12} spacing={2}>
  //             {["All", "Unread", "Read"].map((typeValue, index) => (
  //               <Grid item>
  //                 <Button
  //                   key={typeValue}
  //                   variant="contained"
  //                   size="small"
  //                   onClick={() => setMesgType(typeValue)}
  //                   color={typeValue === mesgType ? "primary" : ""}
  //                   className={
  //                     typeValue === mesgType
  //                       ? classes1.activeBtn
  //                       : classes1.inActiveBtn
  //                   }
  //                 >
  //                   {typeValue}
  //                 </Button>
  //               </Grid>
  //             ))}
  //           </Grid>
  //           <Grid item xs={12}>
  //             <Select
  //               value={type}
  //               onChange={(event) => setType(event.target.value)}
  //               displayEmpty
  //               variant="outlined"
  //               fullWidth
  //               size="small"
  //               style={{ marginLeft: "10px", width: "250px", height: "35px" }}
  //             >
  //               {typeList.map((typeValue) => (
  //                 <MenuItem key={typeValue} value={typeValue}>
  //                   {typeValue}
  //                 </MenuItem>
  //               ))}
  //             </Select>
  //           </Grid>
  //         </Grid>
  //       </div>
  //       <br />
  //       <br />
  //       <div style={{ marginTop: "90px" }}>
  //         {error && (
  //           <Typography
  //             className={classes.typography}
  //             variant="body2"
  //             color="error"
  //             align="center"
  //           >
  //             Something went wrong!
  //           </Typography>
  //         )}
  //         {isLoading ? (
  //           <NetworkProgress />
  //         ) : typeData.length > 0 ? (
  //           typeData.map((notification) => (
  //             <div style={{ padding: "8px" }} key={notification.uuid}>
  //               <NotificationContent content={notification} />
  //             </div>
  //           ))
  //         ) : (
  //           // if no notification available (getting empty array from api)
  //           <Typography
  //             className={classes.typography}
  //             variant="body1"
  //             color="textSecondary"
  //             align="center"
  //           >
  //             No new notifications.
  //           </Typography>
  //         )}
  //       </div>
  //     </>
  //   );
  // };
  return (
    <React.Fragment>
      <>
        {mobile && (
          <IconButton
            className={classes.iconButton}
            onClick={(event) => {
              ifMobile ? history.push("/notification") : handleClick(event);
            }}
          >
            <Badge
              color="primary"
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              classes={{ badge: classes.customBadge }}
              badgeContent={
                pathname !== "/notification" ? notificationCount : 0
              }
              showZero={
                notificationCount > 0 && pathname !== "/notification"
                  ? true
                  : false
              }
            >
              <img
                src={bell}
                alt="Notifications"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </Badge>
          </IconButton>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{
            paper: classes.paper,
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          PaperProps={{
            style: {
              overflow: "auto", // or 'hidden', 'scroll', etc. depending on your needs
              maxWidth: "500px",
              minWidth: "320px",
              margin: "0 auto",
            },
          }}
        >
          <NotificationPage handleClose={handleClose}/>
        </Popover>
      </>

      {!mobile && (
        <IconButton
          onClick={(event) => {
            ifMobile ? history.push("/notification") : handleClick(event);
          }}
        >
          <Badge
            color="error"
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            classes={{ badge: classes.customBadge }}
            badgeContent={pathname !== "/notification" ? notificationCount : 0}
            showZero={
              notificationCount > 0 && pathname !== "/notification"
                ? true
                : false
            }
          >
            <NotificationsIcon className={classes.deskTopIcon} />
          </Badge>
        </IconButton>
      )}
    </React.Fragment>
  );
};

export default Index;
