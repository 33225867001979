import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Container,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import ImageIcon from "@material-ui/icons/Image";
// hooks
import useFetch from "../../hooks/useFetch";
import { useSnackbar } from "../../context/snackbar";
// components
import StyledBox from "../StyledBox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 500,
    margin: "0 auto",
  },
  video: {
    width: "100%",
    marginBottom: theme.spacing(1),
    filter: "blur(4px)",
  },
  videoActive: {
    filter: "blur(0px)",
    transition: "filter 0.3s ease-in-out",
  },
  canvas: {
    display: "none",
    position: "absolute",
    top: 0,
    left: 0,
  },
  captureButton: {
    marginTop: theme.spacing(1),
  },
  capturedImage: {
    maxHeight: "40vh",
    marginTop: theme.spacing(1),
  },
  dialogBackground: {
    backdropFilter: "blur(5px)",
  },
}));

const PhotoCapture = ({
  handleUpload,
  onPhotoUploadSuccess,
  width,
  height,
}) => {
  const classes = useStyles();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [capturedPhoto, setCapturedPhoto] = useState(null);
  const [cameraPermission, setCameraPermission] = useState(false);
  const [openPermissionDialog, setOpenPermissionDialog] = useState(false);
  const [l2, e2, d2, f2, r2] = useFetch(handleUpload);
  const { openSnackbar } = useSnackbar();

  const handleCapture = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (video && canvas) {
      // Set canvas dimensions to match video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Define passport photo dimensions (e.g., 35mm x 45mm)
      const passportWidth = width * (canvas.width / video.videoWidth); // Adjust as needed
      const passportHeight = height * (canvas.height / video.videoHeight); // Adjust as needed

      // Calculate the cropping area (centered)
      const startX = (canvas.width - passportWidth) / 2;
      const startY = (canvas.height - passportHeight) / 2;

      // Create a new canvas for the cropped image
      const croppedCanvas = document.createElement("canvas");
      croppedCanvas.width = passportWidth;
      croppedCanvas.height = passportHeight;
      const croppedContext = croppedCanvas.getContext("2d");

      // Draw the cropped image onto the new canvas
      croppedContext.drawImage(
        canvas,
        startX,
        startY,
        passportWidth,
        passportHeight,
        0,
        0,
        passportWidth,
        passportHeight
      );

      // Get the data URL of the cropped image
      const photoDataUrl = croppedCanvas.toDataURL("image/jpeg");
      setCapturedPhoto(photoDataUrl);
    }

    // Stop the video stream
    if (video.srcObject) {
      const stream = video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      video.srcObject = null;
    }
  };

  const handleClearPhoto = (play) => {
    setCapturedPhoto(null);
    // Restart the video stream
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && play) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          const video = videoRef.current;
          if (video) {
            video.srcObject = stream;
            video.play();
          }
        })
        .catch((err) => {
          console.error("Error accessing camera: ", err);
        });
    }
  };

  const handleUploadPhoto = () => {
    if (capturedPhoto) {
      const blob = dataURItoBlob(capturedPhoto);
      const file = new File([blob], "captured_photo.jpg", {
        type: "image/jpeg",
      });
      f2({ avatar: file });
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setCameraPermission(true);
        setOpenPermissionDialog(false);
      }
    } catch (error) {
      console.error("Error accessing the camera: ", error);
      setCameraPermission(false);
      setOpenPermissionDialog(true);
    }
  };

  const handleRequestPermission = () => {
    startCamera();
  };

  useEffect(() => {
    startCamera();
  }, []);

  useEffect(() => {
    if (d2) {
      console.log("Avatar updated:", d2);
      if (d2.data.status === "SUCCESS") {
        openSnackbar("Photo uploaded successfully", "success");
        handleClearPhoto();
        if (onPhotoUploadSuccess) {
          onPhotoUploadSuccess(d2);
        }
      } else {
        openSnackbar("Failed to upload photo", "error");
      }
    }
    if (e2) {
      console.error("Failed to upload photo:", e2);
      openSnackbar("Failed to upload photo", "error");
    }

    return () => {
      r2();
    };
  }, [d2, e2]);

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <StyledBox>
        {!capturedPhoto && (
          <>
            <Typography variant="h5" gutterBottom color="error">
              Photo Capture
            </Typography>

            <div style={{ position: "relative", width: "100%" }}>
              <video
                ref={videoRef}
                autoPlay
                playsInline
                muted
                className={`${classes.video} ${
                  cameraPermission ? classes.videoActive : ""
                }`}
              />
              <canvas ref={canvasRef} className={classes.canvas} />
            </div>

            <Button
              variant="outlined"
              color="primary"
              startIcon={<CameraAltIcon />}
              onClick={handleCapture}
              className={classes.captureButton}
              fullWidth
              disabled={!cameraPermission}
            >
              Capture Photo
            </Button>
          </>
        )}

        {capturedPhoto && (
          <Box margin="auto">
            <Typography
              variant="caption"
              className={classes.captureButton}
              gutterBottom
              color="primary"
              component="p"
            >
              <ImageIcon style={{ verticalAlign: "middle", marginRight: 8 }} />
              photo captured*
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              <img
                src={capturedPhoto}
                alt="Captured"
                className={classes.capturedImage}
              />
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleClearPhoto(true)}
                className={classes.captureButton}
                fullWidth
              >
                Clear Photo
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="contained"
                color="secondary"
                onClick={handleUploadPhoto}
                className={classes.captureButton}
                fullWidth
                disabled={l2}
              >
                {l2 ? "Uploading..." : "Upload Photo"}
              </Button>
            </Box>
          </Box>
        )}
      </StyledBox>

      {/* <Dialog
        open={openPermissionDialog}
        aria-labelledby="camera-permission-dialog"
        BackdropProps={{
          className: classes.dialogBackground,
        }}
        disableEscapeKeyDown
      >
        <DialogTitle id="camera-permission-dialog">
          Camera Permission Required
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This app needs access to your camera to capture photos. Please grant
            camera permissions to proceed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRequestPermission}
            color="primary"
            variant="contained"
            autoFocus
          >
            Grant Permission
          </Button>
        </DialogActions>
      </Dialog> */}
    </Container>
  );
};

export default PhotoCapture;
