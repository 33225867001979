import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CircularRingProgress from "./CircularRingProgress";
import subjectList from "./subjects.json";
function PercentageRing({ subject }) {
  const attemptData = useSelector((state) => state.rankAnalysis);
  const [correctValue, setCorrectValue] = useState(0);
  const [wrongValue, setWrongValue] = useState(0);
  const [displayValue, setDisplayValue] = useState(0);
  const { YourDetails } = attemptData.data;
  useEffect(() => {
    if (subjectList[subject]) {
      setCorrectValue(
        YourDetails[subjectList[subject].correct] *
          (100 /
            (YourDetails[subjectList[subject].correct] +
              YourDetails[subjectList[subject].wrong] +
              YourDetails[subjectList[subject].unAttempted]))
      );
      setWrongValue(
        (YourDetails[subjectList[subject].correct] +
          YourDetails[subjectList[subject].wrong]) *
          (100 /
            (YourDetails[subjectList[subject].correct] +
              YourDetails[subjectList[subject].wrong] +
              YourDetails[subjectList[subject].unAttempted]))
      );
      setDisplayValue(
        Math.floor(
          YourDetails[subjectList[subject].correct] *
            (100 /
              (YourDetails[subjectList[subject].correct] +
                YourDetails[subjectList[subject].wrong] +
                YourDetails[subjectList[subject].unAttempted]))
        )
      );
    }
  });
  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        justify="center"
        spacing={3}
        alignItems="center"
      >
        <Grid item xs={12}>
          <CircularRingProgress
            correctValue={YourDetails ? correctValue : 0}
            wrongValue={YourDetails ? wrongValue : 0}
            displayText={
              YourDetails ? `${!isNaN(displayValue) ? displayValue : 0}%` : `0%`
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PercentageRing;
