import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import "./NumbersPanel.css";
import Button from "components/CustomButtons/Button.jsx";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

var classNames = require("classnames");
const styles = (theme) => ({
  fab: {
    borderRadius: "0px",
    backgroundColor: "unset",
    width: "40px",
    fontSize: "14px",
    fontWeight: "bolder",
    boxShadow: "none",
    backgroundSize: "90% 90% !important",
    // margin: theme.spacing(0.5),
    // width: theme.spacing(6),
    // height: theme.spacing(6),
    // backgroundColor: 'white',
    // color: '#474747',
    // border: 'solid 2px grey'
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginRight: "3%",
    // marginBottom: theme.spacing(.5),
    // width: 'max-content',
    minWidth: theme.spacing.unit * 13,
    // minHeight: theme.spacing(9),
    padding: "-1% 15%",
    textAlign: "center",

    color: theme.palette.text.secondary,
  },
  mobilepaper: {
    // width: 'max-content',
    minWidth: theme.spacing.unit * 15,
    // padding: '8px 24px',
    textAlign: "center",
    margin: theme.spacing.unit * 1,
    color: theme.palette.text.secondary,
  },
  bottomleft: {
    // marginTop: theme.spacing(1),
    minHeight: theme.spacing.unit * 10,
    marginRight: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 1,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  bottomright: {
    minHeight: theme.spacing.unit * 10,
    // padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  newgridsep: {
    minHeight: theme.spacing.unit * 1,
  },
});

class NumbersPanel extends React.Component {
  a = [];
  statusmap = {
    NOT_VISITED: "not-visited",
    NOT_ANSWERED: "not-answered",
    ANSWERED: "answered",
    MARKED_FOR_REVIEW: "marked-for-review",
    ANSWERED_MARKED_FOR_REVIEW: "answered-marked-for-review",
  };
  statusdisplay = {
    NOT_VISITED: 0,
    NOT_ANSWERED: 0,
    ANSWERED: 0,
    MARKED_FOR_REVIEW: 0,
    ANSWERED_MARKED_FOR_REVIEW: 0,
  };
  componentDidUpdate(props) {
    if (this.props !== props) {
      this.statusCheck();
    }
  }
  statusCheck() {
    Object.keys(this.statusdisplay).forEach((key) => {
      this.statusdisplay[key] = 0;
    });
    this.props.questions.forEach((question) => {
      this.statusdisplay[question.status] += 1;
    });
    this.setState({ statusdisplay: this.statusdisplay });
  }
  constructor(props) {
    super(props);
    this.a = Array(this.props.questions.length)
      .fill(0)
      .map((v, i) => i + 1);
    this.state = { statusdisplay: this.statusdisplay };
  }
  sendQuestionIndex(index, status) {
    this.statusCheck();
    this.props.displayQuestion(index);
  }
  render() {
    const { classes } = this.props;
    let minval = 1000;
    return (
      <div>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Questions Marking
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12} sm={5}>
                <Button
                  size="sm"
                  key="a_m_r"
                  className={classNames(
                    classes.fab,
                    this.statusmap["NOT_VISITED"]
                  )}
                >
                  {this.state.statusdisplay.NOT_VISITED}
                </Button>
                <span> Not Visited </span>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Button
                  size="sm"
                  key="n_a"
                  className={classNames(
                    classes.fab,
                    this.statusmap["NOT_ANSWERED"]
                  )}
                >
                  {this.state.statusdisplay.NOT_ANSWERED}
                </Button>
                <span> Not Answered</span>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Button
                  size="sm"
                  key="a"
                  className={classNames(
                    classes.fab,
                    this.statusmap["ANSWERED"]
                  )}
                >
                  {this.state.statusdisplay.ANSWERED}
                </Button>
                <span>Answered</span>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Button
                  size="sm"
                  key="m_r"
                  className={classNames(
                    classes.fab,
                    this.statusmap["MARKED_FOR_REVIEW"]
                  )}
                >
                  {this.state.statusdisplay.MARKED_FOR_REVIEW}
                </Button>
                <span> Marked for Review</span>
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="sm"
                  key="a_m_r"
                  className={classNames(
                    classes.fab,
                    this.statusmap["ANSWERED_MARKED_FOR_REVIEW"]
                  )}
                >
                  {this.state.statusdisplay.ANSWERED_MARKED_FOR_REVIEW}
                </Button>
                <span>Answered & Marked for Review </span>
                <span style={{ fontSize: "14px" }}>
                  (will consider for evaluation)
                </span>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Grid container className="indexPanel">
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                {this.props.questions
                  .filter((question) => {
                    if (
                      question.subject === this.props.current_question.subject
                    ) {
                      if (minval > question.question_number)
                        minval = question.question_number;
                    }
                    return (
                      question.subject === this.props.current_question.subject
                    );
                  })
                  .map((question, index) => {
                    return (
                      <Button
                        size="sm"
                        key={question.question_number}
                        onClick={() =>
                          this.sendQuestionIndex(
                            minval + index,
                            question.status
                          )
                        }
                        aria-label="Add"
                        className={classNames(
                          classes.fab,
                          this.statusmap[question.status]
                        )}
                      >
                        {minval + index}
                      </Button>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

NumbersPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(
  withWidth(),
  withStyles(styles)
)(NumbersPanel);
// export default withStyles(styles)(NumbersPanel)
