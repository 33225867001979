import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import TimerIcon from "@material-ui/icons/Timer";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import theme from "../../../assets/theme";
import InputIntegerPanel from "../../InputIntegerPanel";
import CheckboxField from "../CheckboxField";
import { PaperComponent } from "../PaperComponent";
import ShowSolution from "../ShowSolution";
import AnalyticsSingleQuestion from "./AnalyticsSingleQuestion";
import PracticeResult from "./PracticeResult";
import QuestionTypeComponent from "./QuestionTypeComponent";
import { QuestionWrapper } from "./WrapperCard";
const useStyle = makeStyles(() => ({
  questionHead: {
    background: theme.palette.background.paper,
    borderRadius: "10px",
  },
  optionColor: {
    color: theme.palette.text.primary,
  },
  form: {
    width: "100%",
  },
}));
function SingleQuestionComponent({ questionData, handleBookmark, ...props }) {
  const classes = useStyle();
  const answerSubmittedQues = useSelector((state) => state.submittedAnswer);
  const bookmarkQuesStatus = useSelector((state) => state.bookmarkQuestion);
  const savedBody = useSelector((state) => state.savedBodyObject);
  const { isSubmitted, value, isError, integerVal } = savedBody;
  const [solution, setSolution] = useState({});
  useLayoutEffect(() => {
    const math = document.getElementById("question_details");
    if (math) {
      window.MathJax &&
        window.MathJax.Hub.Queue([
          "Typeset",
          window.MathJax.Hub,
          math,
          () => {},
        ]);
    }
  });
  useEffect(() => {
    if (questionData.answer) {
      const resultToShow = {
        markedAns: `${questionData.markedAns}`,
        correctAns: `${questionData.answer}`,
        status:
          questionData.status !== undefined &&
          (questionData.status === true ? "correct" : "wrong"),
      };
      setSolution(resultToShow);
    }
  }, [questionData]);
  return (
    <React.Fragment>
      <Grid>
        {/* question content area  */}
        <QuestionWrapper>
          <Grid container id="question_details" spacing={2}>
            <Grid item xs={12}>
              {/* question header  */}
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={4} container>
                  <Grid item>
                    <Typography
                      color="textPrimary"
                      variant="h4"
                      component="span"
                      dangerouslySetInnerHTML={{
                        __html: `${questionData.questionNo}.`,
                      }}
                    />
                    &nbsp;&nbsp;
                  </Grid>
                  <Grid item>
                    <QuestionTypeComponent qType={questionData.q_type} />
                  </Grid>
                </Grid>
                <Grid item xs={4} container justify="center">
                  <AnalyticsSingleQuestion body={props.body} />
                </Grid>
                <Grid
                  item
                  xs={4}
                  alignItems="center"
                  container
                  justify="flex-end"
                >
                  {!questionData.answer && (
                    <Typography align="right" color="error">
                      <TimerIcon fontSize="inherit" />
                      :&nbsp;{moment.utc(props.timer * 1000).format("HH:mm:ss")}
                    </Typography>
                  )}
                  &nbsp;
                  {questionData.bookmarked ||
                  bookmarkQuesStatus.data.status === "SUCCESS" ? (
                    <IconButton disableRipple>
                      <StarRoundedIcon color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton onClick={handleBookmark}>
                      <StarBorderRoundedIcon color="primary" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              {/* question body  */}
              {questionData.paragraph && (
                <Typography
                  color="textPrimary"
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: `${questionData.paragraph}:`,
                  }}
                />
              )}
              <br />
              <Typography
                color="textPrimary"
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: `Q. ${questionData.q}`,
                }}
              />
            </Grid>
            <br />
            {/* integer, single or multiple question - answer area  */}
            <Grid item xs={12}>
              {!isSubmitted && !questionData.answer ? (
                <React.Fragment>
                  {/* 1. integer input  */}
                  {questionData.q_type === "integer" ? (
                    <React.Fragment>
                      <Typography variant="subtitle1" color="textPrimary">
                        Enter your answer:
                      </Typography>
                      <InputIntegerPanel
                        setIntegerValue={props.setIntegerValue}
                        markedanswer={integerVal}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Typography variant="subtitle1" color="textPrimary">
                        Select your answer:
                      </Typography>
                      <br />
                      {/* 2. single correct option  */}
                      {(questionData.q_type === "single" ||
                        questionData.q_type === "paragraph") && (
                        <FormControl className={classes.form}>
                          <RadioGroup
                            name="markedAns"
                            value={value}
                            onChange={props.handleOptionChange}
                          >
                            {questionData.options &&
                              Object.keys(questionData.options).map(
                                (keyName) => {
                                  return (
                                    <PaperComponent key={keyName}>
                                      <FormControlLabel
                                        disabled={
                                          questionData.status ? true : false
                                        }
                                        value={keyName}
                                        control={<Radio color="primary" />}
                                        label={
                                          <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{
                                              __html: `${
                                                questionData.options[keyName]
                                              }`,
                                            }}
                                          />
                                        }
                                        className={classes.optionColor}
                                      />
                                    </PaperComponent>
                                  );
                                }
                              )}
                          </RadioGroup>
                        </FormControl>
                      )}
                      {/* 3. multiple correct options  */}
                      {questionData.q_type === "multiple" && (
                        <React.Fragment>
                          {questionData.options &&
                            Object.keys(questionData.options).map((keyName) => (
                              <CheckboxField
                                key={keyName}
                                value={keyName}
                                disabled={questionData.status ? true : false}
                                label={questionData.options[keyName]}
                                onChange={(event, isChecked) => {
                                  props.handleCheckbox(
                                    event,
                                    isChecked,
                                    keyName
                                  );
                                }}
                                className={classes.optionColor}
                              />
                            ))}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                !isError && (
                  <PracticeResult
                    result={
                      questionData.answer ? solution : answerSubmittedQues.data
                    }
                    data={questionData}
                  />
                )
              )}
            </Grid>
          </Grid>
          {/* SOLUTION SECTION  */}
          {/* see solution if marked and submitted  */}
          {questionData.solution || answerSubmittedQues.data.solution ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ShowSolution
                  solution={
                    questionData.solution
                      ? questionData.solution
                      : answerSubmittedQues.data.solution
                  }
                />
              </Grid>
            </Grid>
          ) : null}
        </QuestionWrapper>
      </Grid>
    </React.Fragment>
  );
}

export default SingleQuestionComponent;
