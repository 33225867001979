import React from "react";
import { withStyles } from "@material-ui/core/styles";
import "./SectionInstructions.css";
const styles = (theme) => ({});
let schemaInstructions = {};

schemaInstructions["2k24_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR (04)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).{" "}
          <strong>ONLY ONE</strong> of these four option(s) is (are) correct
          option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i>:<strong>+3</strong>
        If only the bubble corresponding to the correct option is darkened
        <br />
        <i> Zero Marks </i>:<strong>0</strong>
        If none of the bubbles is darkened
        <br />
        <i> Negative Marks </i>:<strong>-1</strong>
        In all other cases
        <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong> SECTION 2 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>Three (03)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i>Full Marks</i>:<strong>+4</strong>
        If only (all) the correct option(s) is (are) chosen.
        <br />
        <i> Partial Marks</i>:<strong>+3</strong>
        If all the four options are correct but ONLY three options are chosen.
        <br />
        <i>Partial Marks</i>:<strong>+2</strong>
        If three or more options are correct but ONLY two options are chosen,
        both of Which are correct options.
        <br />
        <i> Partial Marks</i>:<strong>+1</strong>
        If two or more options are correct but ONLY one option is chosen and it
        is a correct option.
        <br />
        <i>Zero Marks</i>:<strong>0</strong>
        If none of the options is chosen (i.e. the question is unanswered).
        <br />
        <i>Negative Marks</i>:<strong>-2</strong>
        In all other cases.
        <br />
        <li>
          <strong>For Example</strong>, in a question, if (A),(B) and (D) are
          the only three options corresponding to correct answers, Choose ONLY
          (A),(B) and (D) will get +4 marks;
        </li>
        Choose ONLY (A) and (B) will get +2 marks;
        <br />
        Choose ONLY (A) and (D) will get +2 marks;
        <br />
        Choose ONLY (B) and (D) will get +2 marks;
        <br />
        Choose ONLY (A) will get +1 mark;
        <br />
        Choose ONLY (B) will get +1 mark;
        <br />
        Choose ONLY (D) will get +1 mark;
        <br />
        Choosing no option (i.e. the question is unanswered) will get 0 marks;
        and choosing any other combination of options will get -2 mark.
        <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>SECTION 3 (Maximum Marks: 24)</h4>
      <ul>
        <li>
          This section contains <strong>SIX (06)</strong> questions.
        </li>
        <li>
          The answer to each question is a <strong>NON-NEGATIVE INTEGER</strong>
          .
        </li>
        <li>
          For each question, enter the correct integer corresponding to the
          answer using the mouse and the on-screen virtual numeric keypad in the
          place designated to enter the answer
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>:<strong>+4</strong>
        If ONLY the correct integer value is entered as answer.
        <br />
        <i> Zero Marks </i>:<strong>0</strong>
        In all other cases.
        <br />
      </ul>
    </div>
  ),
  4: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 4 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>Four (04)</strong> Matching List Sets.
        </li>
        <li>
          Each set has <strong>ONE</strong> Multiple Choice Question.
        </li>
        <li>
          Each set has <strong>TWO</strong> lists: <strong>List-I</strong> and
          <strong>List-II</strong>.
        </li>
        <li>
          <strong>List-I</strong> has <strong>Four</strong> entries (P), (Q),
          (R) and (S) and <strong>List-II</strong> has <strong>Five</strong>{" "}
          entries (1), (2), (3), (4) and (5).
        </li>
        <li>
          <strong>FOUR</strong> options are given in each Multiple Choice
          Question based on <strong>List-I</strong> and <strong>List-II</strong>{" "}
          and
          <strong>ONLY ONE</strong> of these four options satisfies the
          condition asked in the Multiple Choice Question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>:<strong>+3</strong>
        If only the bubble corresponding to the correct option is darkened
        <br />
        <i> Zero Marks </i>:<strong>0</strong>
        non options are chosen
        <br />
        <i> Negative Marks </i>:<strong>-1</strong>
        In all other cases
        <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k24_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong> SECTION 1 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i>:<strong>+3</strong>
        If only the bubble corresponding to the correct option is darkened
        <br />
        <i> Zero Marks </i>:<strong>0</strong>
        If none of the bubbles is darkened
        <br />
        <i> Negative Marks </i>:<strong>-1</strong>
        In all other cases
        <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>Three (03)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i>Full Marks</i>:<strong>+4</strong>
        If only (all) the correct option(s) is (are) chosen.
        <br />
        <i> Partial Marks</i>:<strong>+3</strong>
        If all the four options are correct but ONLY three options are chosen.
        <br />
        <i>Partial Marks</i>:<strong>+2</strong>
        If three or more options are correct but ONLY two options are chosen,
        both of Which are correct options.
        <br />
        <i> Partial Marks</i>:<strong>+1</strong>
        If two or more options are correct but ONLY one option is chosen and it
        is a correct option.
        <br />
        <i>Zero Marks</i>:<strong>0</strong>
        If none of the options is chosen (i.e. the question is unanswered).
        <br />
        <i>Negative Marks</i>:<strong>-2</strong>
        In all other cases.
        <br />
        <li>
          <strong>For Example</strong>, in a question, if (A),(B) and (D) are
          the only three options corresponding to correct answers, Choose ONLY
          (A),(B) and (D) will get +4 marks;
        </li>
        Choose ONLY (A) and (B) will get +2 marks;
        <br />
        Choose ONLY (A) and (D) will get +2 marks;
        <br />
        Choose ONLY (B) and (D) will get +2 marks;
        <br />
        Choose ONLY (A) will get +1 mark;
        <br />
        Choose ONLY (B) will get +1 mark;
        <br />
        Choose ONLY (D) will get +1 mark;
        <br />
        Choosing no option (i.e. the question is unanswered) will get 0 marks;
        and choosing any other combination of options will get -2 mark.
        <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>SECTION 3 (Maximum Marks: 24)</h4>
      <ul>
        <li>
          This section contains <strong>SIX (06)</strong> questions.
        </li>
        <li>
          The answer to each question is a <strong>NON-NEGATIVE INTEGER</strong>
          .
        </li>
        <li>
          For each question, enter the correct integer corresponding to the
          answer using the mouse and the on-screen virtual numeric keypad in the
          place designated to enter the answer
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>:<strong>+4</strong>
        If
        <strong>ONLY</strong>
        the correct integer value is entered as answer.
        <br />
        <i> Zero Marks </i>:<strong>0</strong>
        In all other cases.
        <br />
      </ul>
    </div>
  ),
  4: (
    <div class="sec-ins">
      <h4>
        <strong> SECTION 4 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TWO (02)</strong> paragraphs.
        </li>
        <li>
          Based on each paragraph, there are <strong>TWO (02)</strong>{" "}
          questions.
        </li>
        <li>
          The answer to each question is a <strong>NUMERICAL VALUE</strong>.
        </li>
        <li>
          For each question, enter the correct numerical value of the answer
          using the mouse and the onscreen virtual numeric keypad in the place
          designated to enter the answer.
        </li>
        <li>
          If the numerical value has more than two decimal places,
          <strong>truncate/round-off</strong> the value to <strong>TWO</strong>
          decimal places
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i>:<strong>+3</strong>
        If only the bubble corresponding to the correct option is darkened
        <br />
        <i> Negative Marks </i>:<strong>0</strong>
        In all other cases
        <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k23_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>THREE (03)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i>Full Marks</i>:<strong>+4</strong>
        If only (all) the correct option(s) is (are) chosen.
        <br />
        <i> Partial Marks</i>:<strong>+3</strong>
        If all the four options are correct but ONLY three options are chosen.
        <br />
        <i>Partial Marks</i>:<strong>+2</strong>
        If three or more options are correct but ONLY two options are chosen,
        both of Which are correct options.
        <br />
        <i> Partial Marks</i>:<strong>+1</strong>
        If two or more options are correct but ONLY one option is chosen and it
        is a correct option.
        <br />
        <i>Zero Marks</i>:<strong>0</strong>
        If none of the options is chosen (i.e. the question is unanswered).
        <br />
        <i>Negative Marks</i>:<strong>-2</strong>
        In all other cases.
        <br />
        <li>
          <strong>For Example</strong>, in a question, if (A),(B) and (D) are
          the only three options corresponding to correct answers, Choose ONLY
          (A),(B) and (D) will get +4 marks;
        </li>
        Choose ONLY (A) and (B) will get +2 marks;
        <br />
        Choose ONLY (A) and (D) will get +2 marks;
        <br />
        Choose ONLY (B) and (D) will get +2 marks;
        <br />
        Choose ONLY (A) will get +1 mark;
        <br />
        Choose ONLY (B) will get +1 mark;
        <br />
        Choose ONLY (D) will get +1 mark;
        <br />
        Choosing no option (i.e. the question is unanswered) will get 0 marks;
        and choosing any other combination of options will get -2 mark.
        <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong> SECTION 2 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i>:<strong>+3</strong>
        If only the bubble corresponding to the correct option is darkened
        <br />
        <i> Zero Marks </i>:<strong>0</strong>
        If none of the bubbles is darkened
        <br />
        <i> Negative Marks </i>:<strong>-1</strong>
        In all other cases
        <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>SECTION 3 (Maximum Marks: 24)</h4>
      <ul>
        <li>
          This section contains <strong>SIX (06)</strong> questions.
        </li>
        <li>
          The answer to each question is a <strong>NON-NEGATIVE INTEGER</strong>
          .
        </li>
        <li>
          For each question, enter the correct integer corresponding to the
          answer using the mouse and the on-screen virtual numeric keypad in the
          place designated to enter the answer
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>:<strong>+4</strong>
        If ONLY the correct integer value is entered as answer.
        <br />
        <i> Zero Marks </i>:<strong>0</strong>
        In all other cases.
        <br />
      </ul>
    </div>
  ),
  4: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 4 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>Four (04)</strong> Matching List Sets.
        </li>
        <li>
          Each set has <strong>ONE</strong> Multiple Choice Question.
        </li>
        <li>
          Each set has <strong>TWO</strong> lists: <strong>List-I</strong> and
          <strong>List-II</strong>.
        </li>
        <li>
          <strong>List-I</strong> has <strong>Four</strong> entries (P), (Q),
          (R) and (S) and <strong>List-II</strong> has <strong>Five</strong>{" "}
          entries (1), (2), (3), (4) and (5).
        </li>
        <li>
          <strong>FOUR</strong> options are given in each Multiple Choice
          Question based on <strong>List-I</strong> and <strong>List-II</strong>{" "}
          and
          <strong>ONLY ONE</strong> of these four options satisfies the
          condition asked in the Multiple Choice Question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>:<strong>+3</strong>
        If only the bubble corresponding to the correct option is darkened
        <br />
        <i> Zero Marks </i>:<strong>0</strong>
        non options are chosen
        <br />
        <i> Negative Marks </i>:<strong>-1</strong>
        In all other cases
        <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k23_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong> SECTION 1 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i>:<strong>+3</strong>
        If only the bubble corresponding to the correct option is darkened
        <br />
        <i> Zero Marks </i>:<strong>0</strong>
        If none of the bubbles is darkened
        <br />
        <i> Negative Marks </i>:<strong>-1</strong>
        In all other cases
        <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>Three (03)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i>Full Marks</i>:<strong>+4</strong>
        If only (all) the correct option(s) is (are) chosen.
        <br />
        <i> Partial Marks</i>:<strong>+3</strong>
        If all the four options are correct but ONLY three options are chosen.
        <br />
        <i>Partial Marks</i>:<strong>+2</strong>
        If three or more options are correct but ONLY two options are chosen,
        both of Which are correct options.
        <br />
        <i> Partial Marks</i>:<strong>+1</strong>
        If two or more options are correct but ONLY one option is chosen and it
        is a correct option.
        <br />
        <i>Zero Marks</i>:<strong>0</strong>
        If none of the options is chosen (i.e. the question is unanswered).
        <br />
        <i>Negative Marks</i>:<strong>-2</strong>
        In all other cases.
        <br />
        <li>
          <strong>For Example</strong>, in a question, if (A),(B) and (D) are
          the only three options corresponding to correct answers, Choose ONLY
          (A),(B) and (D) will get +4 marks;
        </li>
        Choose ONLY (A) and (B) will get +2 marks;
        <br />
        Choose ONLY (A) and (D) will get +2 marks;
        <br />
        Choose ONLY (B) and (D) will get +2 marks;
        <br />
        Choose ONLY (A) will get +1 mark;
        <br />
        Choose ONLY (B) will get +1 mark;
        <br />
        Choose ONLY (D) will get +1 mark;
        <br />
        Choosing no option (i.e. the question is unanswered) will get 0 marks;
        and choosing any other combination of options will get -2 mark.
        <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>SECTION 3 (Maximum Marks: 24)</h4>
      <ul>
        <li>
          This section contains <strong>SIX (06)</strong> questions.
        </li>
        <li>
          The answer to each question is a <strong>NON-NEGATIVE INTEGER</strong>
          .
        </li>
        <li>
          For each question, enter the correct integer corresponding to the
          answer using the mouse and the on-screen virtual numeric keypad in the
          place designated to enter the answer
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>:<strong>+4</strong>
        If
        <strong>ONLY</strong>
        the correct integer value is entered as answer.
        <br />
        <i> Zero Marks </i>:<strong>0</strong>
        In all other cases.
        <br />
      </ul>
    </div>
  ),
  4: (
    <div class="sec-ins">
      <h4>
        <strong> SECTION 4 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TWO (02)</strong> paragraphs.
        </li>
        <li>
          Based on each paragraph, there are <strong>TWO (02)</strong>{" "}
          questions.
        </li>
        <li>
          The answer to each question is a <strong>NUMERICAL VALUE</strong>.
        </li>
        <li>
          For each question, enter the correct numerical value of the answer
          using the mouse and the onscreen virtual numeric keypad in the place
          designated to enter the answer.
        </li>
        <li>
          If the numerical value has more than two decimal places,
          <strong>truncate/round-off</strong> the value to <strong>TWO</strong>
          decimal places
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i>:<strong>+3</strong>
        If only the bubble corresponding to the correct option is darkened
        <br />
        <i> Negative Marks </i>:<strong>0</strong>
        In all other cases
        <br />
      </ul>
    </div>
  ),
};

schemaInstructions["2k22_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT (08)</strong> questions. The
          answer to each question is a <strong>NUMERICAL VALUE</strong>.
        </li>
        For each question, enter the correct numerical value (in decimal
        notation, truncated/rounded-off to the{" "}
        <strong>second decimal place;</strong> <i>e.g</i>. 6.25, 7.00, -0.33,
        -.30, 30.27, -127.30) using the mouse and the on-screen virtual numeric
        keypad in the place designated to enter the answer.
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If ONLY the correct numerical
        value is entered as answer. <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases.
        <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong> SECTION 2 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          {" "}
          This section contains <strong>SIX (06)</strong> questions.
        </li>
        <li>
          Each question has FOUR options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> :<strong>+4</strong> If only (all) the correct
        option(s) is (are) chosen. <br />
        <i> Partial Marks </i> :<strong>+3</strong> If all the four options are
        correct but ONLY three options are chosen.
        <br />
        <i> Partial Marks </i> : <strong>+2</strong> If three or more options
        are correct but ONLY two options are chosen, both of Which are correct
        options.
        <br />
        <i> Partial Marks </i> : <strong>+1</strong> If two or more options are
        correct but ONLY one option is chosen and it is a correct option.
        <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the options is
        chosen (i.e. the question is unanswered).
        <br />
        <i> Negative Marks </i> : <strong>-2</strong> In all other cases.
        <br />
        <li>
          <strong>For Example:</strong> If first, third and fourth are the ONLY
          three correct options for a question with second option being an
          incorrect option; selecting only all the three correct options will
          result in +4 marks. Selecting only two of the three correct options (
          <i>e.g</i>. the first and fourth options), without selecting any
          incorrect option (second option in this case), will result in +2
          marks. Selecting only one of the three correct options (either first
          or third or fourth option), without selecting any incorrect option
          (second option in this case), will result in +1 marks. Selecting any
          incorrect option(s) (second option in this case), with or without
          selection of any correct option(s) will result in -2 marks.
        </li>{" "}
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR (04)</strong> questions.
        </li>
        <li>
          Each question has <strong>TWO (02)</strong> matching lists:{" "}
          <strong>LIST‐I</strong> and <strong>LIST‐II</strong>.
        </li>
        <li>
          <strong>FOUR</strong> options are given representing matching of
          elements from <strong>LIST‐I</strong> and{" "}
          <strong>LIST‐II. ONLY ONE</strong> of these four options corresponds
          to a correct matching.
        </li>
        <li>
          For each question, choose the option corresponding to the correct
          matching.
        </li>
        <li>
          For each question, marks will be awarded according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If ONLY the option
        corresponding to the correct matching is chosen. <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the options is
        chosen (i.e. the question is unanswered). <br />
        <i> Negative Marks </i> : <strong>-1</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k22_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT</strong> questions.
        </li>
        <li>
          The answer to each questions is a{" "}
          <strong>SINGLE DIGIT INTEGER</strong> ranging from 0 to 9, both
          inclusive.
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened. <br />
        <i> -1 Marks </i> : <strong>-1</strong> For incorrect attempts. <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong> SECTION 2 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          {" "}
          This section contains <strong>SIX (06)</strong> questions.
        </li>
        <li>
          Each question has FOUR options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> :<strong>+4</strong> If only (all) the correct
        option(s) is (are) chosen. <br />
        <i> Partial Marks </i> :<strong>+3</strong> If all the four options are
        correct but ONLY three options are chosen.
        <br />
        <i> Partial Marks </i> : <strong>+2</strong> If three or more options
        are correct but ONLY two options are chosen, both of Which are correct
        options.
        <br />
        <i> Partial Marks </i> : <strong>+1</strong> If two or more options are
        correct but ONLY one option is chosen and it is a correct option.
        <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the options is
        chosen (i.e. the question is unanswered).
        <br />
        <i> Negative Marks </i> : <strong>-2</strong> In all other cases.
        <br />
        <li>
          <strong>For Example:</strong> If first, third and fourth are the ONLY
          three correct options for a question with second option being an
          incorrect option; selecting only all the three correct options will
          result in +4 marks. Selecting only two of the three correct options (
          <i>e.g</i>. the first and fourth options), without selecting any
          incorrect option (second option in this case), will result in +2
          marks. Selecting only one of the three correct options (either first
          or third or fourth option), without selecting any incorrect option
          (second option in this case), will result in +1 marks. Selecting any
          incorrect option(s) (second option in this case), with or without
          selection of any correct option(s) will result in -2 marks.
        </li>{" "}
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong> SECTION 3 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i>: <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i>: <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i>: <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
};

schemaInstructions["BCONM_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX (06)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i>Full Marks</i> : <strong>+4</strong> If only (all) the correct
        option(s) is (are) chosen. <br />
        <i> Partial Marks</i> : <strong>+3</strong> If all the four options are
        correct but ONLY three options are chosen.
        <br />
        <i>Partial Marks</i> : <strong>+2</strong> If three or more options are
        correct but ONLY two options are chosen, both of Which are correct
        options. <br />
        <i> Partial Marks</i> : <strong>+1</strong> If two or more options are
        correct but ONLY one option is chosen and it is a correct option. <br />
        <i>Zero Marks</i> : <strong>0</strong> If none of the options is chosen
        (i.e. the question is unanswered). <br />
        <i>Negative Marks</i> : <strong>-2</strong> In all other cases. <br />
        <li>
          <strong>For Example</strong>, in a question, if (A),(B) and (D) are
          the only three options corresponding to correct answers, Choose ONLY
          (A),(B) and (D) will get +4 marks;
        </li>
        Choose ONLY (A) and (B) will get +2 marks; <br />
        Choose ONLY (A) and (D) will get +2 marks;
        <br />
        Choose ONLY (B) and (D) will get +2 marks;
        <br />
        Choose ONLY (A) will get +1 mark;
        <br />
        Choose ONLY (B) will get +1 mark;
        <br />
        Choose ONLY (D) will get +1 mark;
        <br />
        Choosing no option (i.e. the question is unanswered) will get 0 marks;
        and choosing any other combination of options will get -2 mark. <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR (04)</strong> questions. The answer
          to each question is a <strong>NUMERICAL VALUE</strong>.
        </li>
        <li>
          For each question, enter the correct numerical value of the answer
          using the mouse and the on-screen virtual numeric keypad in the place
          designated to enter the answer. If the numerical value has more than
          two decimal places, <strong>truncated/rounded-off</strong> the value
          to <strong>TWO</strong> decimal places.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>: <strong>+3</strong> If ONLY the correct numerical
        value is entered as answer. <br />
        <i> Zero Marks </i>: <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>SECTION 3 (Maximum Marks: 15)</h4>
      <ul>
        <li>
          This section contains <strong>FIVE (05)</strong> questions.
        </li>
        <li>
          The answer to each question is a <strong>NON-NEGATIVE INTEGER</strong>
          .
        </li>
        <li>
          For each question, enter the correct integer corresponding to the
          answer using the mouse and the on-screen virtual numeric keypad in the
          place designated to enter the answer
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>: <strong>+3</strong> If ONLY the correct integer
        value is entered as answer. <br />
        <i> Zero Marks </i>: <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
  4: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 4 (Maximum Marks: 15)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FIVE (05)</strong> questions stems.
        </li>
        <li>
          There are <strong>TWO (02)</strong> questions corresponding to each
          question stem.The answer to each question is a{" "}
          <strong>NUMERICAL VALUE</strong>.
        </li>
        <li>
          For each question, enter the correct numerical value of the answer
          using the mouse and the on-screen virtual numeric keypad in the place
          designated to enter the answer. If the numerical value has more than
          two decimal places, <strong>truncated/rounded-off</strong> the value
          to <strong>TWO</strong> decimal places.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>: <strong>+3</strong> If ONLY the correct numerical
        value is entered as answer. <br />
        <i> Zero Marks </i>: <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k21_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX (06)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i>Full Marks</i> : <strong>+4</strong> If only (all) the correct
        option(s) is (are) chosen. <br />
        <i> Partial Marks</i> : <strong>+3</strong> If all the four options are
        correct but ONLY three options are chosen.
        <br />
        <i>Partial Marks</i> : <strong>+2</strong> If three or more options are
        correct but ONLY two options are chosen, both of Which are correct
        options. <br />
        <i> Partial Marks</i> : <strong>+1</strong> If two or more options are
        correct but ONLY one option is chosen and it is a correct option. <br />
        <i>Zero Marks</i> : <strong>0</strong> If none of the options is chosen
        (i.e. the question is unanswered). <br />
        <i>Negative Marks</i> : <strong>-2</strong> In all other cases. <br />
        <li>
          <strong>For Example</strong>, in a question, if (A),(B) and (D) are
          the only three options corresponding to correct answers, Choose ONLY
          (A),(B) and (D) will get +4 marks;
        </li>
        Choose ONLY (A) and (B) will get +2 marks; <br />
        Choose ONLY (A) and (D) will get +2 marks;
        <br />
        Choose ONLY (B) and (D) will get +2 marks;
        <br />
        Choose ONLY (A) will get +1 mark;
        <br />
        Choose ONLY (B) will get +1 mark;
        <br />
        Choose ONLY (D) will get +1 mark;
        <br />
        Choosing no option (i.e. the question is unanswered) will get 0 marks;
        and choosing any other combination of options will get -2 mark. <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>Three (03)</strong> questions stems.
        </li>
        <li>
          There are <strong>TWO (02)</strong> questions corresponding to each
          question stem.The answer to each question is a{" "}
          <strong>NUMERICAL VALUE</strong>.
        </li>
        <li>
          For each question, enter the correct numerical value of the answer
          using the mouse and the on-screen virtual numeric keypad in the place
          designated to enter the answer. If the numerical value has more than
          two decimal places, <strong>truncated/rounded-off</strong> the value
          to <strong>TWO</strong> decimal places.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>: <strong>+2</strong> If ONLY the correct numerical
        value is entered as answer. <br />
        <i> Zero Marks </i>: <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TWO</strong> paragraphs
        </li>
        <li>
          Based on each paragraph, there are <strong>TWO</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C], and
          [D]. <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> non options are chosen <br />
        <i> Negative Marks </i> : <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  4: (
    <div class="sec-ins">
      <h4>SECTION 4 (Maximum Marks: 12)</h4>
      <ul>
        <li>
          This section contains <strong>THREE (03)</strong> questions.
        </li>
        <li>
          The answer to each question is a <strong>NON-NEGATIVE INTEGER</strong>
          .
        </li>
        <li>
          For each question, enter the correct integer corresponding to the
          answer using the mouse and the on-screen virtual numeric keypad in the
          place designated to enter the answer
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>: <strong>+4</strong> If ONLY the correct integer
        value is entered as answer. <br />
        <i> Zero Marks </i>: <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k21_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong> SECTION 1 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i>: <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i>: <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i>: <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>Three (03)</strong> questions stems.
        </li>
        <li>
          There are <strong>TWO (02)</strong> questions corresponding to each
          question stem.The answer to each question is a{" "}
          <strong>NUMERICAL VALUE</strong>.
        </li>
        <li>
          For each question, enter the correct numerical value of the answer
          using the mouse and the on-screen virtual numeric keypad in the place
          designated to enter the answer. If the numerical value has more than
          two decimal places, <strong>truncated/rounded-off</strong> the value
          to <strong>TWO</strong> decimal places.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>: <strong>+2</strong> If ONLY the correct numerical
        value is entered as answer. <br />
        <i> Zero Marks </i>: <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX (06)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i>Full Marks</i> : <strong>+4</strong> If only (all) the correct
        option(s) is (are) chosen. <br />
        <i> Partial Marks</i> : <strong>+3</strong> If all the four options are
        correct but ONLY three options are chosen.
        <br />
        <i>Partial Marks</i> : <strong>+2</strong> If three or more options are
        correct but ONLY two options are chosen, both of Which are correct
        options. <br />
        <i> Partial Marks</i> : <strong>+1</strong> If two or more options are
        correct but ONLY one option is chosen and it is a correct option. <br />
        <i>Zero Marks</i> : <strong>0</strong> If none of the options is chosen
        (i.e. the question is unanswered). <br />
        <i>Negative Marks</i> : <strong>-2</strong> In all other cases. <br />
        <li>
          <strong>For Example</strong>, in a question, if (A),(B) and (D) are
          the only three options corresponding to correct answers, Choose ONLY
          (A),(B) and (D) will get +4 marks;
        </li>
        Choose ONLY (A) and (B) will get +2 marks; <br />
        Choose ONLY (A) and (D) will get +2 marks;
        <br />
        Choose ONLY (B) and (D) will get +2 marks;
        <br />
        Choose ONLY (A) will get +1 mark;
        <br />
        Choose ONLY (B) will get +1 mark;
        <br />
        Choose ONLY (D) will get +1 mark;
        <br />
        Choosing no option (i.e. the question is unanswered) will get 0 marks;
        and choosing any other combination of options will get -2 mark. <br />
      </ul>
    </div>
  ),
  4: (
    <div class="sec-ins">
      <h4>SECTION 4 (Maximum Marks: 12)</h4>
      <ul>
        <li>
          This section contains <strong>THREE (03)</strong> questions.
        </li>
        <li>
          The answer to each question is a <strong>NON-NEGATIVE INTEGER</strong>
          .
        </li>
        <li>
          For each question, enter the correct integer corresponding to the
          answer using the mouse and the on-screen virtual numeric keypad in the
          place designated to enter the answer
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>: <strong>+4</strong> If ONLY the correct integer
        value is entered as answer. <br />
        <i> Zero Marks </i>: <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k20_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 18)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FIVE</strong> questions.
        </li>
        <li>
          The answer to each questions is a{" "}
          <strong>SINGLE DIGIT INTEGER</strong> ranging from 0 to 9, both
          inclusive.
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened. <br />
        <i> -1 Marks </i> : <strong>-1</strong> For incorrect attempts. <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX (06)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i>Full Marks</i> : <strong>+4</strong> If only (all) the correct
        option(s) is (are) chosen. <br />
        <i> Partial Marks</i> : <strong>+3</strong> If all the four options are
        correct but ONLY three options are chosen.
        <br />
        <i>Partial Marks</i> : <strong>+2</strong> If three or more options are
        correct but ONLY two options are chosen, both of Which are correct
        options. <br />
        <i> Partial Marks</i> : <strong>+1</strong> If two or more options are
        correct but ONLY one option is chosen and it is a correct option. <br />
        <i>Zero Marks</i> : <strong>0</strong> If none of the options is chosen
        (i.e. the question is unanswered). <br />
        <i>Negative Marks</i> : <strong>-2</strong> In all other cases. <br />
        <li>
          <strong>For Example</strong>, in a question, if (A),(B) and (D) are
          the only three options corresponding to correct answers, Choose ONLY
          (A),(B) and (D) will get +4 marks;
        </li>
        Choose ONLY (A) and (B) will get +2 marks; <br />
        Choose ONLY (A) and (D) will get +2 marks;
        <br />
        Choose ONLY (B) and (D) will get +2 marks;
        <br />
        Choose ONLY (A) will get +1 mark;
        <br />
        Choose ONLY (B) will get +1 mark;
        <br />
        Choose ONLY (D) will get +1 mark;
        <br />
        Choosing no option (i.e. the question is unanswered) will get 0 marks;
        and choosing any other combination of options will get -1 mark. <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX (06)</strong> questions. The answer
          to each question is a <strong>NUMERICAL VALUE</strong>.
        </li>
        <li>
          For each question, enter the correct numerical value of the answer
          using the mouse and the on-screen virtual numeric keypad in the place
          designated to enter the answer. If the numerical value has more than
          two decimal places, <strong>truncated/rounded-off</strong> the value
          to <strong>TWO</strong> decimal places.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>: <strong>+4</strong> If ONLY the correct numerical
        value is entered as answer. <br />
        <i> Zero Marks </i>: <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k20_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong> SECTION 1 (Maximum Marks: 18)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i>: <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i>: <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i>: <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX (06)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i>Full Marks</i> : <strong>+4</strong> If only (all) the correct
        option(s) is (are) chosen. <br />
        <i> Partial Marks</i> : <strong>+3</strong> If all the four options are
        correct but ONLY three options are chosen.
        <br />
        <i>Partial Marks</i> : <strong>+2</strong> If three or more options are
        correct but ONLY two options are chosen, both of Which are correct
        options. <br />
        <i> Partial Marks</i> : <strong>+1</strong> If two or more options are
        correct but ONLY one option is chosen and it is a correct option. <br />
        <i>Zero Marks</i> : <strong>0</strong> If none of the options is chosen
        (i.e. the question is unanswered). <br />
        <i>Negative Marks</i> : <strong>-2</strong> In all other cases. <br />
        <li>
          <strong>For Example</strong>, in a question, if (A),(B) and (D) are
          the only three options corresponding to correct answers, Choose ONLY
          (A),(B) and (D) will get +4 marks;
        </li>
        Choose ONLY (A) and (B) will get +2 marks; <br />
        Choose ONLY (A) and (D) will get +2 marks;
        <br />
        Choose ONLY (B) and (D) will get +2 marks;
        <br />
        Choose ONLY (A) will get +1 mark;
        <br />
        Choose ONLY (B) will get +1 mark;
        <br />
        Choose ONLY (D) will get +1 mark;
        <br />
        Choosing no option (i.e. the question is unanswered) will get 0 marks;
        and choosing any other combination of options will get -1 mark. <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX (06)</strong> questions. The answer
          to each question is a <strong>NUMERICAL VALUE</strong>.
        </li>
        <li>
          For each question, enter the correct numerical value of the answer
          using the mouse and the on-screen virtual numeric keypad in the place
          designated to enter the answer. If the numerical value has more than
          two decimal places, <strong>truncated/rounded-off</strong> the value
          to <strong>TWO</strong> decimal places.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>: <strong>+4</strong> If ONLY the correct numerical
        value is entered as answer. <br />
        <i> Zero Marks </i>: <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k19_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong> SECTION 1 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i>: <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i>: <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i>: <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 32)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT (08)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i>Full Marks</i> : <strong>+4</strong> If only (all) the correct
        option(s) is (are) chosen. <br />
        <i> Partial Marks</i> : <strong>+3</strong> If all the four options are
        correct but ONLY three options are chosen.
        <br />
        <i>Partial Marks</i> : <strong>+2</strong> If three or more options are
        correct but ONLY two options are chosen, both of Which are correct
        options. <br />
        <i> Partial Marks</i> : <strong>+1</strong> If two or more options are
        correct but ONLY one option is chosen and it is a correct option. <br />
        <i>Zero Marks</i> : <strong>0</strong> If none of the options is chosen
        (i.e. the question is unanswered). <br />
        <i>Negative Marks</i> : <strong>-1</strong> In all other cases. <br />
        <li>
          <strong>For Example</strong>, in a question, if (A),(B) and (D) are
          the only three options corresponding to correct answers, Choose ONLY
          (A),(B) and (D) will get +4 marks;
        </li>
        Choose ONLY (A) and (B) will get +2 marks; <br />
        Choose ONLY (A) and (D) will get +2 marks;
        <br />
        Choose ONLY (B) and (D) will get +2 marks;
        <br />
        Choose ONLY (A) will get +1 mark;
        <br />
        Choose ONLY (B) will get +1 mark;
        <br />
        Choose ONLY (D) will get +1 mark;
        <br />
        Choosing no option (i.e. the question is unanswered) will get 0 marks;
        and choosing any other combination of options will get -1 mark. <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 18)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX (06)</strong> questions. The answer
          to each question is a <strong>NUMERICAL VALUE</strong>.
        </li>
        <li>
          For each question, enter the correct numerical value of the answer
          using the mouse and the on-screen virtual numeric keypad in the place
          designated to enter the answer. If the numerical value has more than
          two decimal places, <strong>truncated/rounded-off</strong> the value
          to <strong>TWO</strong> decimal places.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i>: <strong>+3</strong> If ONLY the correct numerical
        value is entered as answer. <br />
        <i> Zero Marks </i>: <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k19_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 32)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT (08)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i>Full Marks</i> : <strong>+4</strong> If only (all) the correct
        option(s) is (are) chosen. <br />
        <i>Partial Marks</i> : <strong>+3</strong> If all the four options are
        correct but ONLY three options are chosen.
        <br />
        <i> Partial Marks</i> : <strong>+2</strong> If three or more options are
        correct but ONLY two options are chosen, both of Which are correct
        options. <br />
        <i> Partial Marks</i> : <strong>+1</strong> If two or more options are
        correct but ONLY one option is chosen and it is a correct option. <br />
        <i>Zero Marks</i> : <strong>0</strong> If none of the options is chosen
        (i.e. the question is unanswered). <br />
        <i> Negative Marks</i> : <strong>-1</strong> In all other cases. <br />
        <li>
          <strong>For Example</strong>, in a question, if (A),(B) and (D) are
          the only three options corresponding to correct answers, Choose ONLY
          (A),(B) and (D) will get +4 marks;
        </li>
        Choose ONLY (A) and (B) will get +2 marks; <br />
        Choose ONLY (A) and (D) will get +2 marks;
        <br />
        Choose ONLY (B) and (D) will get +2 marks;
        <br />
        Choose ONLY (A) will get +1 mark;
        <br />
        Choose ONLY (B) will get +1 mark;
        <br />
        Choose ONLY (D) will get +1 mark;
        <br />
        Choosing no option (i.e. the question is unanswered) will get 0 marks;
        and choosing any other combination of options will get -1 mark. <br />{" "}
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 18)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX (06)</strong> questions. The answer
          to each question is a <strong>NUMERICAL VALUE</strong>.
        </li>
        <li>
          For each question, enter the correct numerical value of the answer
          using the mouse and the on-screen virtual numeric keypad in the place
          designated to enter the answer. If the numerical value has more than
          two decimal places, <strong>truncated/rounded-off</strong> the value
          to <strong>TWO</strong> decimal places.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i>Full Marks </i>: <strong>+3</strong> If ONLY the correct numerical
        value is entered as answer. <br />
        <i>Zero Marks </i>: <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TWO (02)</strong> List-Match sets.
        </li>
        <li>
          Each List-Match sets has <strong>TWO (02)</strong> Multiple Choice
          Questions.{" "}
        </li>
        <li>
          Each List-Match set has two lists: <strong>LIST‐I</strong> and{" "}
          <strong>LIST-II</strong>.
        </li>
        <li>
          <strong>LIST‐I</strong> has <strong>FOUR</strong> entries (I), (II),
          (III) and (IV) and <strong>LIST‐II</strong> has <strong>SIX</strong>{" "}
          entries (P), (Q), (R), (S), (T) and (U).
        </li>
        <li>
          <strong>FOUR</strong> options are given in each Multiple Choice
          Question based on <strong>LIST‐I</strong> and <strong>LIST‐II</strong>{" "}
          and <strong>ONLY ONE</strong> of these four options satisfies the
          condition asked in the Multiple Choice Question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:{" "}
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If ONLY the option
        corresponding to the correct matching is chosen. <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the options is
        chosen (i.e. the question is unanswered). <br />
        <i> Negative Marks </i>: <strong>-1</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k18_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong> SECTION 1 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          {" "}
          This section contains <strong>SIX (06)</strong> questions.
        </li>
        <li>
          Each question has FOUR options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> :<strong>+4</strong> If only (all) the correct
        option(s) is (are) chosen. <br />
        <i> Partial Marks </i> :<strong>+3</strong> If all the four options are
        correct but ONLY three options are chosen.
        <br />
        <i> Partial Marks </i> : <strong>+2</strong> If three or more options
        are correct but ONLY two options are chosen, both of Which are correct
        options.
        <br />
        <i> Partial Marks </i> : <strong>+1</strong> If two or more options are
        correct but ONLY one option is chosen and it is a correct option.
        <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the options is
        chosen (i.e. the question is unanswered).
        <br />
        <i> Negative Marks </i> : <strong>-2</strong> In all other cases.
        <br />
        <li>
          <strong>For Example:</strong> If first, third and fourth are the ONLY
          three correct options for a question with second option being an
          incorrect option; selecting only all the three correct options will
          result in +4 marks. Selecting only two of the three correct options (
          <i>e.g</i>. the first and fourth options), without selecting any
          incorrect option (second option in this case), will result in +2
          marks. Selecting only one of the three correct options (either first
          or third or fourth option), without selecting any incorrect option
          (second option in this case), will result in +1 marks. Selecting any
          incorrect option(s) (second option in this case), with or without
          selection of any correct option(s) will result in -2 marks.
        </li>
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT (08)</strong> questions. The
          answer to each question is a <strong>NUMERICAL VALUE</strong>.
        </li>
        For each question, enter the correct numerical value (in decimal
        notation, truncated/rounded-off to the{" "}
        <strong>second decimal place;</strong> <i>e.g</i>. 6.25, 7.00, -0.33,
        -.30, 30.27, -127.30) using the mouse and the on-screen virtual numeric
        keypad in the place designated to enter the answer.
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If ONLY the correct numerical
        value is entered as answer. <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TWO (02)</strong> paragraphs. Based on
          each paragraph, there are <strong>TWO (02)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options.{" "}
          <strong>ONLY ONE</strong> of these four options corresponds to the
          correct answer.
        </li>
        <li>
          For each question, choose the option corresponding to the correct
          answer.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If ONLY the correct option is
        chosen. <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the options is
        chosen (i.e. the question is unanswered). <br />
        <i> Negative Marks </i> : <strong>−1</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k18_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong> SECTION 1 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          {" "}
          This section contains <strong>SIX (06)</strong> questions.
        </li>
        <li>
          Each question has FOUR options for correct answer(s).{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four option(s) is (are)
          correct option(s).
        </li>
        <li>
          For each question, choose the correct option(s) to answer the
          question.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> :<strong>+4</strong> If only (all) the correct
        option(s) is (are) chosen. <br />
        <i> Partial Marks </i> :<strong>+3</strong> If all the four options are
        correct but ONLY three options are chosen.
        <br />
        <i> Partial Marks </i> : <strong>+2</strong> If three or more options
        are correct but ONLY two options are chosen, both of Which are correct
        options.
        <br />
        <i> Partial Marks </i> : <strong>+1</strong> If two or more options are
        correct but ONLY one option is chosen and it is a correct option.
        <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the options is
        chosen (i.e. the question is unanswered).
        <br />
        <i> Negative Marks </i> : <strong>-2</strong> In all other cases.
        <br />
        <li>
          <strong>For Example:</strong> If first, third and fourth are the ONLY
          three correct options for a question with second option being an
          incorrect option; selecting only all the three correct options will
          result in +4 marks. Selecting only two of the three correct options (
          <i>e.g</i>. the first and fourth options), without selecting any
          incorrect option (second option in this case), will result in +2
          marks. Selecting only one of the three correct options (either first
          or third or fourth option), without selecting any incorrect option
          (second option in this case), will result in +1 marks. Selecting any
          incorrect option(s) (second option in this case), with or without
          selection of any correct option(s) will result in -2 marks.
        </li>{" "}
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT (08)</strong> questions. The
          answer to each question is a <strong>NUMERICAL VALUE</strong>.
        </li>
        For each question, enter the correct numerical value (in decimal
        notation, truncated/rounded-off to the{" "}
        <strong>second decimal place;</strong> <i>e.g</i>. 6.25, 7.00, -0.33,
        -.30, 30.27, -127.30) using the mouse and the on-screen virtual numeric
        keypad in the place designated to enter the answer.
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If ONLY the correct numerical
        value is entered as answer. <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases.
        <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR (04)</strong> questions.
        </li>
        <li>
          Each question has <strong>TWO (02)</strong> matching lists:{" "}
          <strong>LIST‐I</strong> and <strong>LIST‐II</strong>.
        </li>
        <li>
          <strong>FOUR</strong> options are given representing matching of
          elements from <strong>LIST‐I</strong> and{" "}
          <strong>LIST‐II. ONLY ONE</strong> of these four options corresponds
          to a correct matching.
        </li>
        <li>
          For each question, choose the option corresponding to the correct
          matching.
        </li>
        <li>
          For each question, marks will be awarded according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If ONLY the option
        corresponding to the correct matching is chosen. <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the options is
        chosen (i.e. the question is unanswered). <br />
        <i> Negative Marks </i> : <strong>-1</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k17_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 28)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SEVEN</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four options is(are)
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble(s)
        corresponding to all the correct option(s)is(are) darkened <br />
        <i> Partial Marks </i> : <strong>+1</strong> For darkening a bubble
        corresponding to <strong>each correct option</strong>,provided NO
        incorrect option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i> : <strong>-2</strong> In all other cases <br />
        <li>
          For example, if [A], [C] and [D] are all the correct options for a
          question, darkening all these three will get +4 marks; darkening only
          [A] and [D] will get +2 marks; and darkening [A] and [B] will get -2
          marks, as a wrong option is also darkened
        </li>
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 15)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FIVE</strong> questions
        </li>
        <li>
          The answer to each question is a <strong>SINGLE DIGIT INTEGER</strong>{" "}
          ranging from 0 to 9, both inclusive
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct answer is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases
        <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 18)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX</strong> questions of matching type
        </li>
        <li>
          This section contains <strong>TWO</strong> tables (each having 3
          columns and 4 rows)
        </li>
        <li>
          Based on each table, there are <strong>THREE</strong> questions
        </li>
        <li>
          Each question has FOUR options [A], [B], [C], and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened
        <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened
        <br />
        <i> Negative Marks </i> : <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k17_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 21)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SEVEN</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened
        <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened
        <br />
        <i> Negative Marks </i>: <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 28)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SEVEN</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four options is(are)
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble(s)
        corresponding to all the correct option(s)is(are) darkened <br />
        <i> Partial Marks </i> : <strong>+1</strong> For darkening a bubble
        corresponding to <strong>each correct option</strong>,provided NO
        incorrect option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i> : <strong>-2</strong> In all other cases <br />
        <li>
          For example, if [A], [C] and [D] are all the correct options for a
          question, darkening all these three will get +4 marks; darkening only
          [A] and [D] will get +2 marks; and darkening [A] and [B] will get -2
          marks, as a wrong option is also darkened
        </li>
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TWO</strong> paragraphs
        </li>
        <li>
          Based on each paragraph, there are <strong>TWO</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C], and
          [D]. <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k16_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 15)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FIVE</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i>: <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 32)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four options is(are)
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble(s)
        corresponding to all the correct option(s)is(are) darkened <br />
        <i> Partial Marks </i> : <strong>+1</strong> For darkening a bubble
        corresponding to <strong>each correct option</strong>,provided NO
        incorrect option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i> : <strong>-2</strong> In all other cases <br />
        <li>
          For example, if [A], [C] and [D] are all the correct options for a
          question, darkening all these three will get +4 marks; darkening only
          [A] and [D] will get +2 marks; and darkening [A] and [B] will get -2
          marks, as a wrong option is also darkened
        </li>
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 15)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FIVE</strong> questions.
        </li>
        <li>
          The answer to each questions is a{" "}
          <strong>SINGLE DIGIT INTEGER</strong> ranging from 0 to 9, both
          inclusive.
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened. <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k16_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 18)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i>: <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 32)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four options is(are)
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble(s)
        corresponding to all the correct option(s)is(are) darkened <br />
        <i> Partial Marks </i> : <strong>+1</strong> For darkening a bubble
        corresponding to <strong>each correct option</strong>,provided NO
        incorrect option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i> : <strong>-2</strong> In all other cases <br />
        <li>
          For example, if [A], [C] and [D] are all the correct options for a
          question, darkening all these three will get +4 marks; darkening only
          [A] and [D] will get +2 marks; and darkening [A] and [B] will get -2
          marks, as a wrong option is also darkened
        </li>
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 15)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TWO</strong> paragraphs
        </li>
        <li>
          Based on each paragraph, there are <strong>TWO</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C], and
          [D]. <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k15_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 32)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT</strong> questions.
        </li>
        <li>
          The answer to each questions is a{" "}
          <strong>SINGLE DIGIT INTEGER</strong> ranging from 0 to 9, both
          inclusive.
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble
        corresponding to the correct option is darkened. <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 40)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TEN</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four options is(are)
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble(s)
        corresponding to all the correct option(s) is(are) darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened
        <br />
        <i> Negative Marks </i> : <strong>-2</strong> In all other cases <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 15)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TWO</strong> questions.
        </li>
        <li>
          Each questions contains two columns, <strong>Column-I</strong> and{" "}
          <strong>Column-II</strong>
        </li>
        <li>
          <strong>Column-I</strong> has <strong>four</strong> entries (A), (B),
          (C) and (D)
        </li>
        <li>
          <strong>Column-II</strong> has <strong>five</strong> entries (P), (Q),
          (R), (S) and (T)
        </li>
        <li>
          Match the entries in <strong>Column-I</strong> with the entries in{" "}
          <strong>Column-II</strong>
        </li>
        <li>
          One or more entries in <strong>Column-I</strong> may match with one or
          more entries in <strong>Column-II</strong>
        </li>
        <li>Marking scheme:</li>
        For each entry in Column-I.
        <br />
        <strong>+2</strong> If only the bubble corresponding to all the correct
        match (es) is (are) darkened <br />
        <strong>0</strong> If none of the bubble darkened
        <br />
        <strong>-1</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k15_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 32)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT</strong> questions.
        </li>
        <li>
          The answer to each questions is a{" "}
          <strong>SINGLE DIGIT INTEGER</strong> ranging from 0 to 9, both
          inclusive.
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble
        corresponding to the correct option is darkened. <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 32)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four options is(are)
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble(s)
        corresponding to all the correct option(s) is(are) darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened
        <br />
        <i> Negative Marks </i> : <strong>-2</strong> In all other cases <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 16)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TWO</strong> paragraphs
        </li>
        <li>
          Based on each paragraph, there are <strong>TWO</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C], and
          [D]. <strong>ONE OR MORETHAN ONE</strong> of these four options is
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble(s)
        corresponding to all the correct option(s) is(are) darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i> : <strong>-2</strong> In all other cases <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k14_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 30)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TEN</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four options is(are)
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble(s)
        corresponding to all the correct option(s) is(are) darkened
        <br />
        <i> Negative Marks </i> : <strong>0</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 30)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TEN</strong> questions.
        </li>
        <li>
          The answer to each questions is a{" "}
          <strong>SINGLE DIGIT INTEGER</strong> ranging from 0 to 9, both
          inclusive.
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened. <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k14_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 30)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TEN</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i>: <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 18)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>THREE</strong> paragraphs. Based on each
          paragraph, there are <strong>TWO (02)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options.{" "}
          <strong>ONLY ONE</strong> of these four options corresponds to the
          correct answer.
        </li>
        <li>
          For each question, choose the option corresponding to the correct
          answer.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If ONLY the correct option is
        chosen. <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the options is
        chosen (i.e. the question is unanswered). <br />
        <i> Negative Marks </i> : <strong>−1</strong> In all other cases. <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR</strong> questions
        </li>
        <li>
          Each having <strong>TWO</strong> matching list.
        </li>
        <li>
          Choices for the correct combination of elements from{" "}
          <strong>List-I</strong> and <strong>List-II</strong> are given as
          options (A), (B), (C) and (D), out of which one is correct.
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble(s)
        corresponding to all the correct option(s) is(are) darkened
        <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened
        <br />
        <i> Negative Marks </i> : <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k13_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 20)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TEN</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+2</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Negative Marks </i>: <strong>0</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 20</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FIVE</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four options is(are)
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble(s)
        corresponding to all the correct option(s) is(are) darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened
        <br />
        <i> Negative Marks </i> : <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 20)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FIVE</strong> questions
        </li>
        <li>
          The answer to each question is a <strong>SINGLE DIGIT INTEGER</strong>{" "}
          ranging from 0 to 9, both inclusive
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble
        corresponding to the correct answer is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened
        <br />
        <i> Negative Marks </i> : <strong>-1</strong> In all other cases
        <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k13_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 24</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four options is(are)
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble(s)
        corresponding to all the correct option(s) is(are) darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened
        <br />
        <i> Negative Marks </i> : <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR</strong> paragraphs. Based on each
          paragraph, there are <strong>TWO (02)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options.{" "}
          <strong>ONLY ONE</strong> of these four options corresponds to the
          correct answer.
        </li>
        <li>
          For each question, choose the option corresponding to the correct
          answer.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If ONLY the correct option is
        chosen. <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the options is
        chosen (i.e. the question is unanswered). <br />
        <i> Negative Marks </i> : <strong>−1</strong> In all other cases. <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 12)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR</strong> multiple choice questions
        </li>
        <li>
          Each question has <strong>Matching List</strong>.
        </li>
        <li>
          The codes for the list have choices (A), (B), (C) and (D), out of
          which one is correct.
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble(s)
        corresponding to all the correct option(s) is(are) darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i> : <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k12_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 30)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TEN</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i> : <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 20)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FIVE</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four options is(are)
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble(s)
        corresponding to all the correct option(s) is(are) darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases
        <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 20)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FIVE</strong> questions
        </li>
        <li>
          The answer to each question is a <strong>SINGLE DIGIT INTEGER</strong>{" "}
          ranging from 0 to 9, both inclusive
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble
        corresponding to the correct answer is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases
        <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k12_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i> : <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 18)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>THREE</strong> paragraphs. Based on each
          paragraph, there are <strong>TWO (02)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options.{" "}
          <strong>ONLY ONE</strong> of these four options corresponds to the
          correct answer.
        </li>
        <li>
          For each question, choose the option corresponding to the correct
          answer.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If ONLY the correct option is
        chosen. <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the options is
        chosen (i.e. the question is unanswered). <br />
        <i> Negative Marks </i> : <strong>−1</strong> In all other cases. <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four options is(are)
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble(s)
        corresponding to all the correct option(s) is(are) darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases
        <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k11_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 21)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SEVEN</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i> : <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 16)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four options is(are)
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble(s)
        corresponding to all the correct option(s) is(are) darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases
        <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 15)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TWO</strong> paragraphs. Based on each
          paragraph, there are <strong>TWO (02)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options.{" "}
          <strong>ONLY ONE</strong> of these four options corresponds to the
          correct answer.
        </li>
        <li>
          For each question, choose the option corresponding to the correct
          answer.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If ONLY the correct option is
        chosen. <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the options is
        chosen (i.e. the question is unanswered). <br />
        <i> Negative Marks </i> : <strong>−1</strong> In all other cases. <br />
      </ul>
    </div>
  ),
  4: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 4 (Maximum Marks: 28)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SEVEN</strong> questions
        </li>
        <li>
          The answer to each question is a <strong>SINGLE DIGIT INTEGER</strong>{" "}
          ranging from 0 to 9, both inclusive
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble
        corresponding to the correct answer is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases
        <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k11_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i> : <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 16)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FOUR</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four options is(are)
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble(s)
        corresponding to all the correct option(s) is(are) darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases
        <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX</strong> questions
        </li>
        <li>
          The answer to each question is a <strong>SINGLE DIGIT INTEGER</strong>{" "}
          ranging from 0 to 9, both inclusive
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+4</strong> If only the bubble
        corresponding to the correct answer is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases
        <br />
      </ul>
    </div>
  ),
  4: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 4 (Maximum Marks: 16)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TWO</strong> questions.
        </li>
        <li>
          Each questions contains two columns, <strong>Column-I</strong> and{" "}
          <strong>Column-II</strong>
        </li>
        <li>
          <strong>Column-I</strong> has four entries (A), (B), (C) and (D)
        </li>
        <li>
          <strong>Column-II</strong> has five entries (P), (Q), (R), (S) and (T)
        </li>
        <li>
          Match the entries in <strong>Column-I</strong> with the entries in{" "}
          <strong>Column-II</strong>
        </li>
        <li>
          One or more entries in <strong>Column-I</strong> may match with one or
          more entries in <strong>Column-II</strong>
        </li>
        <li>Marking scheme:</li>
        For each entry in <strong>Column-I</strong>. <br />
        <strong>+8</strong> If only the bubble corresponding to all the correct
        match (es) is (are) darkened <br />
        <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k10_paper_1"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 24)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>EIGHT</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i> : <strong>-1</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 15)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FIVE</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONE OR MORE THAN ONE</strong> of these four options is(are)
          correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble(s)
        corresponding to all the correct option(s) is(are) darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases
        <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 15)</strong>
      </h4>
      <ul>
        <li>
          Based on first paragraph, there are <strong>THREE</strong> questions
          and Based up on second paragraph, there are <strong>TWO</strong>{" "}
          questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options.{" "}
          <strong>ONLY ONE</strong> of these four options corresponds to the
          correct answer.
        </li>
        <li>
          For each question, choose the option corresponding to the correct
          answer.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If ONLY the correct option is
        chosen. <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the options is
        chosen (i.e. the question is unanswered). <br />
        <i> Negative Marks </i> : <strong>−1</strong> In all other cases. <br />
      </ul>
    </div>
  ),
  4: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 4 (Maximum Marks: 30)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TEN</strong> questions
        </li>
        <li>
          The answer to each question is a <strong>SINGLE DIGIT INTEGER</strong>{" "}
          ranging from 0 to 9, both inclusive
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct answer is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases
        <br />
      </ul>
    </div>
  ),
};
schemaInstructions["2k10_paper_2"] = {
  1: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 1 (Maximum Marks: 30)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>SIX</strong> questions
        </li>
        <li>
          Each question has <strong>FOUR</strong> options [A], [B], [C] and [D].{" "}
          <strong>ONLY ONE</strong> of these four options is correct
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+5</strong> If only the bubble
        corresponding to the correct option is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the bubbles is
        darkened <br />
        <i> Negative Marks </i> : <strong>-2</strong> In all other cases <br />
      </ul>
    </div>
  ),
  2: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 2 (Maximum Marks: 15)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>FIVE</strong> questions
        </li>
        <li>
          The answer to each question is a <strong>SINGLE DIGIT INTEGER</strong>{" "}
          ranging from 0 to 9, both inclusive
        </li>
        <li>
          For each question, marks will be awarded in one of the following
          categories:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If only the bubble
        corresponding to the correct answer is darkened <br />
        <i> Zero Marks </i> : <strong>0</strong> In all other cases
        <br />
      </ul>
    </div>
  ),
  3: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 3 (Maximum Marks: 18)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TWO</strong> paragraphs. Based on each
          paragraph, there are <strong>THREE (03)</strong> questions.
        </li>
        <li>
          Each question has <strong>FOUR</strong> options.{" "}
          <strong>ONLY ONE</strong> of these four options corresponds to the
          correct answer.
        </li>
        <li>
          For each question, choose the option corresponding to the correct
          answer.
        </li>
        <li>
          Answer to each question will be evaluated according to the following
          marking scheme:
        </li>
        <i> Full Marks </i> : <strong>+3</strong> If ONLY the correct option is
        chosen. <br />
        <i> Zero Marks </i> : <strong>0</strong> If none of the options is
        chosen (i.e. the question is unanswered). <br />
        <i> Negative Marks </i> : <strong>−1</strong> In all other cases. <br />
      </ul>
    </div>
  ),
  4: (
    <div class="sec-ins">
      <h4>
        <strong>SECTION 4 (Maximum Marks: 16)</strong>
      </h4>
      <ul>
        <li>
          This section contains <strong>TWO</strong> questions.
        </li>
        <li>
          Each questions contains two columns, <strong>Column-I</strong> and{" "}
          <strong>Column-II</strong>
        </li>
        <li>
          <strong>Column-I</strong> has four entries (A), (B), (C) and (D)
        </li>
        <li>
          <strong>Column-II</strong> has five entries (P), (Q), (R), (S) and (T)
        </li>
        <li>
          Match the entries in <strong>Column-I</strong> with the entries in{" "}
          <strong>Column-II</strong>
        </li>
        <li>
          One or more entries in <strong>Column-I</strong> may match with one or
          more entries in <strong>Column-II</strong>
        </li>
        <li>Marking scheme:</li>
        For each entry in <strong>Column-I</strong>. <br />
        <strong>+8</strong> If only the bubble corresponding to all the correct
        match (es) is (are) darkened <br />
        <strong>0</strong> In all other cases. <br />
      </ul>
    </div>
  ),
};
// return schemaInstructions(props.paper, props.section);
// }

function SectionInstructions(props) {
  return schemaInstructions[props.paper][
    props.schema.questions[props.question_number]
  ];
}

export default withStyles(styles)(SectionInstructions);
