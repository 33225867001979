import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    borderRadius: 20,
    overflow: "hidden",
    "& .MuiButtonGroup-grouped": {
      minWidth: 100,
      borderColor: "transparent",
      "&:not(:last-child)": {
        borderRightColor: "rgba(255, 255, 255, 0.12)",
      },
    },
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.12)",
    height: "auto",
    alignSelf: "stretch",
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 0,
    flexGrow: 1,
  },
}));

const GenericButtonGroup = ({
  buttons,
  color = "primary",
  variant = "contained",
}) => {
  const classes = useStyles();

  return (
    <ButtonGroup
      variant="contained"
      color="primary"
      aria-label="contained primary button group"
      className={classes.buttonGroup}
    >
      {buttons.map((button, index) => (
        <React.Fragment key={index}>
          <Button
            color={color}
            variant={variant}
            startIcon={button.icon}
            className={classes.button}
            onClick={button.onClick}
          >
            {button.label}
          </Button>
          {index < buttons.length - 1 && (
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
          )}
        </React.Fragment>
      ))}
    </ButtonGroup>
  );
};

GenericButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
    })
  ).isRequired,
};

export default GenericButtonGroup;
