import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import { Link } from "react-router-dom";
// core components
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.jsx";
import NavPills from "components/NavPills/NavPills.jsx";

import Typography from "@material-ui/core/Typography";
import check from "assets/v2/check.png";
const styles = (theme) => ({
  pricingBg: {
    background: "#FAFAFA",
  },
  heading: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "36px",
    lineHeight: "44px",
    color: "#31456A",
  },
  content: {
    fontSize: "18px",
    lineHeight: "200%",
    /* or 36px */
    color: "rgba(49, 69, 106, 0.7)",
  },
  tab: {
    background:
      "linear-gradient(39.24deg, #FFFFFF -30.86%, #ECF1F7 -10.72%, #CFDCEB 112.31%)",
    boxShadow:
      "inset 4px 4px 6px rgba(18, 46, 101, 0.1), inset -4px -4px 4px rgba(255, 255, 255, 0.7)",
    borderRadius: "31px",
    transform: "rotate(-90deg)",
  },
  horizLine: {
    width: "55px",
    height: "3px",
    background: "#08C5A7",
    borderRadius: "5px",
  },
  text: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "37px",
    color: "rgba(49, 69, 106, 0.7)",
  },
  icon: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "37px",
    padding: "4px",
    background: "#08C5A7",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    borderRadius: "197px",
    color: "rgba(49, 69, 106, 0.7)",
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    display: "inline",
  },
  priceCard: {
    marginBottom: "5px",
    borderTop: "1.2px solid white",
    borderLeft: "1.2px solid white",
    background: "#EFF2F4",
    // boxShadow: '16px 4px 55px #D1D9E6, -16px -16px 35px rgba(255, 255, 255, 0.8), inset -8px -6px 70px rgba(255, 255, 255, 0.18)',
    borderRadius: "24px",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    // transform: rotate(-90deg);
  },
  tryFree: {
    background: "#08C5A7",
    boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
    borderRadius: "16px",
    color: "white",
  },
  sendMessage: {
    textTransform: "none",
    height: "56px",
    width: "200px",
    background: "#08C5A7",
    boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
    borderRadius: "16px",
    // fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "100%",
    /* or 18px */
    textAlign: "center",
    color: "#FFFFFF",
    "&:hover": {
      background: "#0eab8f",
    },
  },
  check: {
    background: "#08C5A7",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    borderRadius: "197px",
  },
});
function SectionPricing(props) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      {/* <div className={classes.pricingBg} style={{background: "#FAFAFA"}}>
        <div > */}
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.pricingBg}
      >
        <Grid
          item
          xs={12}
          style={{ textAlign: "center", margin: "80px 0px 30px 0px" }}
          // className={`${classes.mlAuto} ${classes.mrAuto}`}
        >
          <h2 className={classes.heading}>Plan for Your Need</h2>
          <h3 className={classes.content}>
            Pick the plan that helps you grow, thank you!
          </h3>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <NavPills
            style={{
              background:
                "linear-gradient(89.16deg, #73E7D4 -4.95%, #169A85 578.66%)",
            }}
            alignCenter
            //  color="onesaz"
            tabs={[
              {
                tabButton: "Student",
                tabContent: (
                  <Grid container justify="space-evenly" alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={5}
                      style={{ marginBottom: "20px" }}
                    >
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.priceCard}
                      >
                        <Grid item xs style={{ marginTop: "30px" }}>
                          <Typography
                            variant="h1"
                            component="h1"
                            className={classes.heading}
                            style={{ fontSize: "30px", lineHeight: "37px" }}
                          >
                            Free
                          </Typography>
                        </Grid>
                        <Grid item xs style={{ marginTop: "10px" }}>
                          <div className={classes.horizLine} />
                          <br />
                        </Grid>
                        <Grid
                          item
                          xs
                          style={{ width: "200px", marginBottom: "5px" }}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <Grid item xs={1}>
                              <div
                                className={classes.icon}
                                style={{ marginLeft: "-15px" }}
                              >
                                <img alt="..." src={check} />
                              </div>
                            </Grid>
                            <Grid item xs={10}>
                              <div
                                className={classes.text}
                                style={{ width: "max-content" }}
                              >
                                10 Previous Papers
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs
                          style={{ width: "200px", marginBottom: "5px" }}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <Grid item xs={1}>
                              <div
                                className={classes.icon}
                                style={{ marginLeft: "-15px" }}
                              >
                                <img alt="..." src={check} />
                              </div>
                            </Grid>
                            <Grid item xs={10}>
                              <div
                                className={classes.text}
                                style={{ width: "max-content" }}
                              >
                                Best Analysis
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs
                          style={{ width: "200px", marginBottom: "5px" }}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <Grid item xs={1}>
                              <div
                                className={classes.icon}
                                style={{ marginLeft: "-15px" }}
                              >
                                <img alt="..." src={check} />
                              </div>
                            </Grid>
                            <Grid item xs={10}>
                              <div
                                className={classes.text}
                                style={{ width: "max-content" }}
                              >
                                2 Onesaz Exam Papers
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs>
                          <br />
                          <Link to="/register">
                            <Button
                              className={classes.sendMessage}
                              style={{ marginBottom: "40px" }}
                            >
                              Sign up
                            </Button>
                          </Link>
                          <br />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={5}
                      style={{ marginBottom: "20px" }}
                    >
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.priceCard}
                      >
                        <Grid item xs style={{ marginTop: "30px" }}>
                          <Typography
                            variant="h1"
                            component="h1"
                            className={classes.heading}
                            style={{ fontSize: "30px", lineHeight: "37px" }}
                          >
                            Premium
                          </Typography>
                        </Grid>
                        <Grid item xs style={{ marginTop: "10px" }}>
                          <div className={classes.horizLine} />
                          <br />
                        </Grid>
                        <Grid
                          item
                          xs
                          style={{ width: "200px", marginBottom: "5px" }}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <Grid item xs={1}>
                              <div
                                className={classes.icon}
                                style={{ marginLeft: "-15px" }}
                              >
                                <img alt="..." src={check} />
                              </div>
                            </Grid>
                            <Grid item xs={10}>
                              <div
                                className={classes.text}
                                style={{ width: "max-content" }}
                              >
                                10 Previous Papers
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs
                          style={{ width: "200px", marginBottom: "5px" }}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <Grid item xs={1}>
                              <div
                                className={classes.icon}
                                style={{ marginLeft: "-15px" }}
                              >
                                <img alt="..." src={check} />
                              </div>
                            </Grid>
                            <Grid item xs={10}>
                              <div
                                className={classes.text}
                                style={{ width: "max-content" }}
                              >
                                Best Analysis
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs
                          style={{ width: "200px", marginBottom: "5px" }}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <Grid item xs={1}>
                              <div
                                className={classes.icon}
                                style={{ marginLeft: "-15px" }}
                              >
                                <img alt="..." src={check} />
                              </div>
                            </Grid>
                            <Grid item xs={10}>
                              <div
                                className={classes.text}
                                style={{ width: "max-content" }}
                              >
                                Complete Curriculum
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs>
                          <br />
                          <Button
                            disabled
                            className={classes.sendMessage}
                            style={{
                              marginBottom: "40px",
                              background: "#EFF2F4",
                              color: "#08C5A7",
                            }}
                          >
                            {/* <Link to to={"/student-premium"}> */}
                            More Details
                            {/* </Link> */}
                          </Button>
                          <br />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ),
              },
              {
                tabButton: "Institute",
                tabContent: (
                  <Grid
                    container
                    // justify="center"
                    direction="row"
                    justify="space-evenly"
                  >
                    <Grid item xs={12} sm={7}>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.priceCard}
                      >
                        <Grid item xs style={{ marginTop: "30px" }}>
                          <Typography
                            variant="h1"
                            component="h1"
                            className={classes.heading}
                            style={{ fontSize: "30px", lineHeight: "37px" }}
                          >
                            Premium
                          </Typography>
                        </Grid>
                        <Grid item xs style={{ marginTop: "10px" }}>
                          <div className={classes.horizLine} />
                          <br />
                        </Grid>
                        <Grid
                          item
                          xs
                          style={{ width: "200px", marginBottom: "5px" }}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <Grid item xs={1}>
                              <div
                                className={classes.icon}
                                style={{ marginLeft: "-15px" }}
                              >
                                <img alt="..." src={check} />
                              </div>
                            </Grid>
                            <Grid item xs={10}>
                              <div
                                className={classes.text}
                                style={{ width: "max-content" }}
                              >
                                Question Bank
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs
                          style={{ width: "200px", marginBottom: "5px" }}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <Grid item xs={1}>
                              <div
                                className={classes.icon}
                                style={{ marginLeft: "-15px" }}
                              >
                                <img alt="..." src={check} />
                              </div>
                            </Grid>
                            <Grid item xs={10}>
                              <div
                                className={classes.text}
                                style={{ width: "max-content" }}
                              >
                                AI powered Test in 5 mins
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs
                          style={{ width: "200px", marginBottom: "5px" }}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <Grid item xs={1}>
                              <div
                                className={classes.icon}
                                style={{ marginLeft: "-15px" }}
                              >
                                <img alt="..." src={check} />
                              </div>
                            </Grid>
                            <Grid item xs={10}>
                              <div
                                className={classes.text}
                                style={{ width: "max-content" }}
                              >
                                Instant and In-depth Analysis
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs>
                          <br />
                          <Button
                            disabled
                            className={classes.sendMessage}
                            style={{ marginBottom: "40px" }}
                          >
                            {/* <Link to to={"/student-premium"}> */}
                            Contact us
                            {/* </Link> */}
                          </Button>
                          <br />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ),
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <br /> <br /> <br />
        </Grid>
      </Grid>
      {/* </div>
      </div> */}
      {/* Pricing 5 END */}
    </div>
  );
}

export default withStyles(styles)(SectionPricing);
