import Axios from "axios";
const SMS_API_KEY = "461CAD87D81795";
export async function sendConfirmationSMS(mobileNo, message) {
  try {
    let data = await Axios.post(
      `http://bulksms.smsroot.com/app/smsapi/index.php?key=${SMS_API_KEY}&campaign=0&routeid=17&type=text&contacts=${mobileNo}&senderid=SJSMTH&msg=${message}`
    );
    return data.data;
  } catch (error) {
    console.log("payment", error);
    return error;
  }
}
