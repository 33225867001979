const neet2021SectionBQuesNos = {
  PHYSICS: [
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,
    86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,
    136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,
186,187,188,189,190,191,192,193,194,195,196,197,198,199,200,
  ],
  CHEMISTRY: [
    "86",
    "87",
    "88",
    "89",
    "90",
    "91",
    "92",
    "93",
    "94",
    "95",
    "96",
    "97",
    "98",
    "99",
    "100",
    36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,
    86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,
    136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,
186,187,188,189,190,191,192,193,194,195,196,197,198,199,200,
  ],
  BOTANY: [
    "136",
    "137",
    "138",
    "139",
    "140",
    "141",
    "142",
    "143",
    "144",
    "145",
    "146",
    "147",
    "148",
    "149",
    "150",
    36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,
    86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,
    136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,
186,187,188,189,190,191,192,193,194,195,196,197,198,199,200,
  ],
  ZOOLOGY: [
    "186",
    "187",
    "188",
    "189",
    "190",
    "191",
    "192",
    "193",
    "194",
    "195",
    "196",
    "197",
    "198",
    "199",
    "200",
    36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,
    86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,
    136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,
186,187,188,189,190,191,192,193,194,195,196,197,198,199,200,
  ],
};
export default neet2021SectionBQuesNos;