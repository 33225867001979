import { Box, Grid, withStyles } from "@material-ui/core";
import React from "react";
const WrapperCard = withStyles((theme) => ({
  root: {
    padding: "1% 0",
    background: "white",
    borderBottom: `2px solid ${theme.palette.background.default}`,
  },
}))((props) => <Grid {...props} />);

const QuestionWrapper = withStyles((theme) => ({
  root: {
    padding: "4%",
    margin: "3%",
    overflow: "auto",
    whiteSpace: "break-spaces",
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    [theme.breakpoints.down("xs")]: {
      marginBottom: "130px",
    },
  },
}))((props) => <Box {...props} />);
export { WrapperCard, QuestionWrapper };
