const paper_2_2k23 = {
  questions: {
    "1": "1",
    "2": "1",
    "3": "1",
    "4": "1",
    "5": "2",
    "6": "2",
    "7": "2",
    "8": "3",
    "9": "3",
    "10": "3",
    "11": "3",
    "12": "3",
    "13": "3",
    "14": "4",
    "15": "4",
    "16": "4",
    "17": "4",
    "18": "1",
    "19": "1",
    "20": "1",
    "21": "1",
    "22": "2",
    "23": "2",
    "24": "2",
    "25": "3",
    "26": "3",
    "27": "3",
    "28": "3",
    "29": "3",
    "30": "3",
    "31": "4",
    "32": "4",
    "33": "4",
    "34": "4",
    "35": "1",
    "36": "1",
    "37": "1",
    "38": "1",
    "39": "2",
    "40": "2",
    "41": "2",
    "42": "3",
    "43": "3",
    "44": "3",
    "45": "3",
    "46": "3",
    "47": "3",
    "48": "4",
    "49": "4",
    "50": "4",
    "51": "4",
  },
  sections: {
    1: [1, 18, 35],
    2: [5, 22, 39],
    3: [8, 25, 42],
    4: [14, 31, 48],
  },
};

export default paper_2_2k23;
