import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import compose from "recompose/compose";
import GridItem from "components/Grid/GridItem.jsx";
import { withRouter } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
const styles = (theme) => ({
  heading: {
    textAlign: "center",
    fontWeight: 900,
    lineHeight: 1.1,
  },
  subHeading: {
    fontWeight: 700,
    lineHeight: 1.1,
  },
  proceed: {
    color: "#fff",
    backgroundColor: "#337ab7",
    borderColor: "#2e6da4",
    padding: ".5% 7%",
    width: "max-content",
  },
  examHeading: {
    color: "white",
    backgroundColor: "#f7931e",
    padding: "5px 0",
    fontWeight: 900,
    // minHeight: theme.spacing(9)
  },
  instruction: {
    "& li": {
      color: "black",
      fontWeight: 400,
    },
  },
});
class InstructionNeet extends React.Component {
  checkedFlag = false;
  constructor(props) {
    super(props);
    this.props = props;
  }
  isChecked(val) {
    this.checkedFlag = val.target.checked;
  }
  proceed() {
    const { history } = this.props;
    if (!this.checkedFlag) {
      alert("Please accept terms and conditions before proceeding.");
    } else {
      history.push("/exam-portal", { ...this.props.location.state });
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <GridContainer
          className={classes.instruction}
          style={{ background: "white" }}
        >
          <GridItem xs={12} className={classes.examHeading}>
            <Typography
              component="h4"
              variant="h5"
              style={{ padding: "1% 10%", fontWeight: "bold" }}
            >
              General Instructions
            </Typography>
          </GridItem>
          <GridItem>
            <GridItem xs={12}>
              <h3 className={classes.heading}>
                Please read the instructions carefully
              </h3>
            </GridItem>
            <GridItem xs={12}>
              <h3 className={classes.subHeading}>
                <u>General Instructions:</u>
              </h3>
            </GridItem>
            <GridItem xs>
              <ol>
                <li>
                  <ul style={{ listStyleType: "circle" }}>
                    <li>Total duration of NEET Examination is 180 min.</li>
                    <li>
                      NEET exam consists of following four subjects PHYSICS,
                      CHEMISTRY, BOTONAY, ZOOLOGY
                    </li>
                  </ul>
                </li>
                <li>
                  The clock will be set at the server. The countdown timer in
                  the top right corner of screen will display the remaining time
                  available for you to complete the examination. When the timer
                  reaches zero, the examination will end by itself. You will not
                  be required to end or submit your examination.
                </li>
                <li>
                  The Questions Palette displayed on the right side of screen
                  will show the status of each question using one of the
                  following symbols:
                  <ol>
                    <li>
                      <img
                        src="./images/SVG/not-visited.svg"
                        width="25px"
                        height="25px"
                        alt=""
                      />{" "}
                      You have not visited the question yet.
                    </li>
                    <li>
                      <img
                        src="./images/SVG/not-answered.svg"
                        width="25px"
                        height="25px"
                        alt=""
                      />{" "}
                      You have not answered the question.
                    </li>
                    <li>
                      <img
                        src="./images/SVG/answered.svg"
                        width="25px"
                        height="25px"
                        alt=""
                      />{" "}
                      You have answered the question.
                    </li>
                    <li>
                      <img
                        src="./images/SVG/mark-for-review.svg"
                        width="25px"
                        height="25px"
                        alt=""
                      />{" "}
                      You have NOT answered the question, but have marked the
                      question for review.
                    </li>
                    <li>
                      <img
                        src="./images/SVG/answered_marked_for_review.svg"
                        width="25px"
                        height="25px"
                        alt=""
                      />{" "}
                      The question(s) "Answered and Marked for Review" will be
                      considered for evalution.
                    </li>
                  </ol>
                </li>
                <li>
                  You can click on the "&lt;" arrow which apperes to the left of
                  question palette to collapse the question palette thereby
                  maximizing the question window. To view the question palette
                  again, you can click on "&gt;" which appears on the right side
                  of question window.
                </li>
                <li>
                  You can click on your "Profile" image on top right corner of
                  your screen to change the language during the exam for entire
                  question paper. On clicking of Profile image you will get a
                  drop-down to change the question content to the desired
                  language.
                </li>
                {/* <li>
                                       You can click on  to navigate to the bottom and  to navigate to top of the question are, without scrolling.
                                   </li> */}
              </ol>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant="h6" className={classes.subHeading}>
                <u>Navigating to a Question:</u>
              </Typography>
            </GridItem>
            <GridItem>
              <ol start="6">
                <li>
                  To answer a question, do the following:
                  <ol type="a">
                    <li>
                      Click on the question number in the Question Palette at
                      the right of your screen to go to that numbered question
                      directly. Note that using this option does NOT save your
                      answer to the current question.{" "}
                    </li>
                    <li>
                      Click on <strong> Save & Next </strong> to save your
                      answer for the current question and then go to the next
                      question.
                    </li>
                    <li>
                      Click on Mark for <strong>Review & Next</strong> to save
                      your answer for the current question, mark it for review,
                      and then go to the next question.
                    </li>
                  </ol>
                </li>
              </ol>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant="h6" className={classes.subHeading}>
                <u>Answering a Question:</u>
              </Typography>
            </GridItem>
            <GridItem>
              <ol start="7">
                <li>
                  Procedure for answering a multiple choice type question:
                  <ol type="a">
                    <li>
                      To select you answer, click on the button of one of the
                      options.
                    </li>
                    <li>
                      To deselect your chosen answer, click on the button of the
                      chosen option again or click on the Clear Response button
                    </li>
                    <li>
                      To change your chosen answer, click on the button of
                      another option
                    </li>
                    <li>
                      To save your answer, you MUST click on the Save & Next
                      button.
                    </li>
                    <li>
                      To mark the question for review, click on the Mark for
                      Review & Next button.
                    </li>
                  </ol>
                </li>
                <li>
                  To change your answer to a question that has already been
                  answered, first select that question for answering and then
                  follow the procedure for answering that type of question.
                </li>
              </ol>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant="h6" className={classes.subHeading}>
                <u>Navigating through sections:</u>
              </Typography>
            </GridItem>
            <GridItem>
              <ol start="9">
                <li>
                  Sections in this question paper are displayed on the top bar
                  of the screen. Questions in a section can be viewed by click
                  on the section name. The section you are currently viewing is
                  highlighted.
                </li>
                <li>
                  After click the Save & Next button on the last question for a
                  section, you will automatically be taken to the first question
                  of the next section.
                </li>
                <li>
                  You can shuffle between sections and questions anything during
                  the examination as per your convenience only during the time
                  stipulated.
                </li>
                <li>
                  Candidate can view the corresponding section summery as part
                  of the legend that appears in every section above the question
                  palette.
                </li>
              </ol>
            </GridItem>
            <GridItem xs={12}>
              <hr />
              <h3
                style={{
                  padding: "1%",
                  fontSize: 20,
                  fontWeight: 400,
                  color: "#a94442",
                }}
              >
                {" "}
                Please note all questions will appear in your default language.
                This language can be changed for a particular question later on.{" "}
              </h3>
              <hr />
              <h3 style={{ padding: "1%", fontSize: 14, fontWeight: 400 }}>
                <input type="checkbox" onChange={this.isChecked.bind(this)} />I
                have read and understood the instructions. All computer hardware
                allotted to me are in proper working condition. I declare that I
                am not in possession of / not wearing / not carrying any
                prohibited gadget like mobile phone, bluetooth devices etc. /any
                prohibited material with me into the Examination Hall.I agree
                that in case of not adhering to the instructions, I shall be
                liable to be debarred from this Test and/or to disciplinary
                action, which may include ban from future Tests / Examinations
              </h3>
              <hr />
            </GridItem>
            <GridItem item xs={12} container justify="space-around">
              <Typography
                component="h6"
                className={classes.proceed}
                onClick={this.proceed.bind(this)}
              >
                Proceed
              </Typography>
            </GridItem>
          </GridItem>
          <GridItem>
            {" "}
            <br />{" "}
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(InstructionNeet);
