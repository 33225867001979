import React from "react";
import { Typography, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import LockIcon from "@material-ui/icons/Lock";
import play from "assets/img/Music/play-circle.png";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import momentTime from "../../utils/momentTime";
const useStyles = makeStyles((theme) => ({
  videoTopic: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "#31456a",
    fontWeight: "600",
    fontSize: "16px",
  },
  lock: {
    color: "#08C5A7",
  },
  playButton: {
    marginTop: "-6px",
    filter: "grayscale(100%) !important",
    "&:hover": {
      filter: "grayscale(0%) !important",
    },
  },
  "@media(max-width: 600px)": {
    video: {
      margin: "2%",
      width: "95vw",
    },
    videoTitle: {
      width: "70%",
    },
    videoTopic: {
      width: "70%",
    },
  },
  password: {
    marginTop: "4px",
    color: "#8e9aaf",
    fontWeight: "normal",
    fontSize: "12px",
  },
  video: {
    margin: "10px 5px",
    padding: "13px 20px",
    height: "50px",
    "&:hover": {
      background: "#FAFAFA",
      boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
      borderRadius: "13px",
    },
  },
}));
function VideoListComponent(props) {
  const classes = useStyles();
  const { data } = props;

  const toolTipText = `Video is only available between ${momentTime(
    data.startDateTime
  )} and  ${momentTime(data.endDateTime)}`;

  const allowVideo = data.startDateTime
    ? moment().isBetween(data.startDateTime, data.endDateTime)
    : true;

  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      justifyContent="space-between"
      className={classes.video}
      css={{ maxWidth: "100vw" }}
    >
      <Box className={classes.videoTitle} display="flex">
        <Typography className={classes.videoTopic}>
          {props.topic}&nbsp;&nbsp;
        </Typography>

        <Typography className={classes.password}>{props.password}</Typography>
      </Box>
      <Box>
        {allowVideo ? (
          <Link {...props}>
            <img src={play} alt="play" className={classes.playButton} />
          </Link>
        ) : (
          <Tooltip title={toolTipText}>
            <LockIcon className={classes.lock} />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}

export default VideoListComponent;
