import { Box } from "@material-ui/core";
import React from "react";

const RowBox = ({ children }) => {
  return (
    <Box
      px={2}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {children}
    </Box>
  );
};

export default RowBox;
