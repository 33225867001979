import React from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../assets/theme";

const useStyle = makeStyles((theme) => ({
  optionWrapperDiv: {
    borderRadius: "10px",
    position: "relative",
    border: (props) => props.border || `1px solid ${theme.palette.info.main}`,
    width: "inherit",
  },

  overlayOptions: {
    position: "absolute",
    height: "-webkit-fill-available",
    opacity: "0.3",
    zIndex: 10,
    borderRadius: "10px",
    margin: (props) => props.margin || "-16px 0px 2px -10px",
  },
  legendColor: {
    color: theme.palette.text.secondary,
    fontSize: "small",
  },
}));
const Fieldset = ({ value = "", ...props }) => {
  const classes = useStyle(props);
  return (
    <fieldset className={classes.optionWrapperDiv}>
      <div
        className={classes.overlayOptions}
        style={{
          width: `${value}%`,
          backgroundColor: theme.palette.info.main,
        }}
      />
      <legend align="right" className={classes.legendColor}>
        {`${Math.ceil(value)}%`}
      </legend>
      {props.children}
    </fieldset>
  );
};

export default Fieldset;
