import axios from "./examServerAxios";


export async function intiateOnlineTransaction(body) {
    

    try {
      let data = await axios.post("/pay/initiateTxn", body);
     
      return data;
    } catch (error) {

      return error;
    }
  }

