import { Grid } from "@material-ui/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import React from "react";
highchartsMore(Highcharts);

const TimeSpentChart = ({ series, colors, data }) => {
  const options = {
    colors: colors,
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      inverted: true,
      polar: true,
      height: "50%",
    },
    title: {
      text: data["Perfect Attempts"]
        ? `${data["Perfect Attempts"].count +
            data["Overtime Correct"].count +
            data["Too Fast Correct"].count}<br/>Correct`
        : "",
      align: "center",
      verticalAlign: "middle",
      style: {
        fontSize: "10px",
      },
    },
    tooltip: {
      outside: true,
    },
    pane: {
      size: "85%",
      innerSize: "20%",
      endAngle: 360,
    },
    xAxis: {
      tickInterval: 1,
      labels: {
        align: "right",
        useHTML: true,
        allowOverlap: true,
        enabled: false,
        step: 1,
        y: 3,
      },
      lineWidth: 0,
    },
    yAxis: {
      crosshair: {
        enabled: true,
        color: "#333",
      },
      labels: { enabled: false },
      lineWidth: 0,
      reversedStacks: false,
      endOnTick: true,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.15,
      },
    },
    series: series,
  };
  return <HighchartsReact options={options} highcharts={Highcharts} />;
};

export default TimeSpentChart;
