import React from "react";
import { Tab, Tabs, withStyles } from "@material-ui/core";

const OnTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#8e9aaf",
    background: "#EFF2F4",
    boxSizing: "border-box",
    "&:focus": {
      opacity: 1,
      color: "#08C5A7",
    },
  },

  selected: {
    color: "#08C5A7",
  },
}))((props) => <Tab disableRipple {...props} />);

const SubjectTab = withStyles((theme) => ({
  root: {
    lineHeight: "18px",
    fontSize: "14px",
    textAlign: "left",
  },
}))((props) => <OnTab disableRipple {...props} />);
const OnTabs = withStyles({
  indicator: {
    backgroundColor: "transparent",
    float: "left",
  },
})((props) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons="off"
    TabIndicatorProps={{ children: <div /> }}
  />
));

export { OnTabs, SubjectTab };
