import { Typography, ThemeProvider } from "@material-ui/core";
// Material components
import Grid from "@material-ui/core/Grid";
// Material helpers
import { withStyles } from "@material-ui/core/styles";
// Externals
import PropTypes from "prop-types";
import React, { lazy, Suspense } from "react";
import theme from "../../assets/theme";
import NetworkProgress from "../../mycomponents/NetworkProgress";
const LazyLoadImages = lazy(() =>
  import("../../mycomponents/LazyLoadImages.jsx")
);
// Component styles
const styles = () => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    marginTop: "100px",
    textAlign: "center",
  },
  image: {
    display: "inline-block",
    marginTop: "50px",
    maxWidth: "100%",
    width: "554px",
  },
});

const NotFound = (props) => {
  const { classes } = props;
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Grid container justify="center" spacing={32}>
          <Grid item lg={6} xs={12}>
            <div className={classes.content}>
              <Typography variant="h1" color="textPrimary">
                404: The page you are looking for isn’t here
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                You either tried some shady route or you came here by mistake.
                Whichever it is, try using the navigation
              </Typography>

              <Suspense fallback={<NetworkProgress />}>
                <LazyLoadImages
                  alt="Under development"
                  className={classes.image}
                  src="/images/not_found.png"
                />
              </Suspense>
            </div>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFound);
