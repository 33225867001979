import { Box, Grid, Hidden, Typography } from "@material-ui/core";
import React from "react";
import { BorderLinearProgress } from "./CustomProgressBar";
import MarksList from "./MarksList";
import { makeStyles } from "@material-ui/styles";
import theme from "../../assets/theme";
import WrapperCard from "../HomePage/Components/WrapperCard";
import ContentWrap from "../HomePage/Components/ContentWrap";
const useStyle = makeStyles(() => ({
  listItem: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "2%",
    },
  },
  top: {
    borderRadius: 5,
    backgroundColor: "#46AB87",
  },
  avg: {
    borderRadius: 5,
    backgroundColor: "#A83B96",
  },
  low: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));
function AccuracyCard({ accuracy, avg, topper }) {
  const classes = useStyle();
  const compareAccuracy = [accuracy, avg, topper].sort((a, b) => a - b);
  const barColor = {
    0: "low",
    1: "avg",
    2: "top",
  };

  return (
    <ContentWrap>
      <Grid item xs={12}>
        <Grid
          container
          direction="column"
          style={{ padding: "20px 0", gap: "10px" }}
        >
          <MarksList
            subjectName="Student's Accuracy"
            subjectMarks={accuracy ? `${accuracy}%` : "0%"}
          />
          <BorderLinearProgress
            classes={{
              bar: classes[barColor[compareAccuracy.indexOf(accuracy)]],
            }}
            value={accuracy}
          />
          <MarksList
            subjectName="Class Average Accuracy"
            subjectMarks={avg ? `${avg}%` : "0%"}
          />
          <BorderLinearProgress
            classes={{
              bar: classes[barColor[compareAccuracy.indexOf(avg)]],
            }}
            value={avg}
          />
          <MarksList
            subjectName="Topper's Accuracy"
            subjectMarks={topper ? `${topper}%` : "0%"}
          />
          <BorderLinearProgress
            classes={{
              bar: classes[barColor[compareAccuracy.indexOf(topper)]],
            }}
            value={topper}
          />
        </Grid>
      </Grid>
    </ContentWrap>
  );
}

export default AccuracyCard;
