import * as actionType from "../../actions/practicePageActions/actions";

// submit answer reducer
const INITIAL_STATE = {
  isLoading: false,
  data: {},
  error: null,
};
export const SubmitAnswerReducer = (answer = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SAVE_ANSWER_LOADING:
      return {
        ...answer,
        isLoading: true,
      };
    case actionType.SAVE_ANSWER_SUCCESS:
      return {
        ...answer,
        isLoading: false,
        data: action.payload,
      };
    case actionType.CATCH_ERROR_ANSWER:
      return {
        ...answer,
        isLoading: false,
        error: action.payload,
      };
    case actionType.CLEAR_ERROR_SUBMIT:
      return {
        ...answer,
        isLoading: false,
        error: null,
        data: {},
      };
    default:
      return answer;
  }
};
