import axios from "./examServerAxios";
import store from "store";

function compare(a, b) {
  // Extract createdDate values or default to a date in the past
  const createdDateA = a.createdDate || 0;
  const createdDateB = b.createdDate || 0;

  if (createdDateA > createdDateB) {
    return -1;
  }
  if (createdDateA < createdDateB) {
    return 1;
  }
  return 0;
}

export async function getMeetings(body) {
  try {
    const data = await axios.post("/video/getallmeetings", body);
    console.log(data, "he");
    return data;
  } catch (error) {
    console.log("data", "he");
    return false;
  }
}

export async function getAssignments(body) {
  try {
    const data = await axios.post("/video/assignments", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getMeetingDetails(body) {
  try {
    const data = await axios.post("/video/getsignature", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getZoomMeetingDetails(body) {
  try {
    const data = await axios.post("/video/getinteractivemeeting", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getZoomInteractiveMeetingDetails(body) {
  try {
    const data = await axios.post("/video/getzoominteractivemeeting", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function recordAttendance(body) {
  try {
    const data = await axios.post("/video/recordattendance", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getCourseSubjects(course) {
  try {
    let data = await axios.get(
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/domain_config/course.json"
    );
    return data.data;
  } catch (error) {
    return error;
  }
}

export async function getZmMeetings(body) {
  try {
    const data = await axios.post("/video/recordedMeetings", body);
    return data;
  } catch (error) {
    return false;
  }
}

export async function getLiveAndUpcomingClasses(body) {
  try {
    const data = await axios.post("/video/liveAndUpcomingClasses", body);
    return data.data;
  } catch (error) {
    return false;
  }
}

export async function getAllAssignments(body) {
  try {
    const data = await axios.post("/video/all-assignments", body);
    return data.data;
  } catch (error) {
    return error;
  }
}

export async function getAllAssignmentsWithoutTopic(body) {
  try {
    // const { instituteId, type, branch } = body;
    // const storageKey = `assignment_${instituteId}_${type}_${branch}`;

    // let cachedAssignments = store.get(storageKey);

    // if (
    //   cachedAssignments &&
    //   cachedAssignments.payload &&
    //   Array.isArray(cachedAssignments.payload) &&
    //   cachedAssignments.payload.length > 0
    // ) {
    //   const sortedList = cachedAssignments.payload.slice().sort(compare);
    //   const timeStamp =
    //     sortedList[0] && sortedList[0].createdDate
    //       ? sortedList[0].createdDate
    //       : Date.now();

    //   const data = await axios.post("/video/all-assignments-withoutTopic", {
    //     ...body,
    //     timeStamp,
    //   });

    //   const result = data.data;

    //   if (result && result.payload && result.payload.length > 0) {
    //     cachedAssignments.payload = cachedAssignments.payload.concat(
    //       result.payload
    //     );
    //     store.set(storageKey, cachedAssignments);
    //   }

    //   return cachedAssignments;
    // }

    const data = await axios.post("/video/all-assignments-withoutTopic", body);
    // store.set(storageKey, data.data);

    return data.data;
  } catch (error) {
    return error;
  }
}

export async function zoomSignature(body) {
  try {
    const data = await axios.post("/video/zoom-signature", body);
    return data.data;
  } catch (error) {
    return error;
  }
}

var VideoService = {
  getMeetingDetails: getMeetingDetails,
  getMeetings: getMeetings,
  getCourseSubjects: getCourseSubjects,
  getAssignments,
  getZmMeetings,
  getLiveAndUpcomingClasses,
  getAllAssignments,
  getAllAssignmentsWithoutTopic,
  zoomSignature,
};
export default VideoService;
