import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import React, { Fragment, useEffect } from "react";
import Header from "../Header";
import "./IframePlayer.css";
const styles = (theme) => ({
  videoBg: {
    minHeight: "90vh",
    height: "max-content",
    overflow: "scroll",
    minWidth: "100vh",
    background: "#F0F3F5",
  },
});
function IframePlayer(props) {
  const { classes } = props;
  // document.querySelector("iframe").addEventListener( "load", function(e) {
  //     var iframe = document.getElementById('play');
  //     var innerDoc = (iframe.contentDocument)
  //            ? iframe.contentDocument
  //            : iframe.contentWindow.document;
  // console.log(innerDoc)
  // } );
  // console.log(props);
  useEffect(() => {
    console.log(document.getElementsByClassName("r-header"));
  }, []);
  const handleDoc = () => {
    var iframe = document.getElementById("play");
    var innerDoc = iframe.contentDocument;
    //    ? iframe.contentDocument
    //    : iframe.contentWindow.document;
    console.log("he", innerDoc);
    console.log("he", document.getElementsByClassName("r-header"));
  };
  // window.$('#play_here').load(props.location.state.play_url+' .video-player');
  return (
    <Fragment>
      {/* <Header /> */}
      <Grid
        container
        direction="row"
        justify="center"
        className={classes.videoBg}
      >
        <Grid item xs={12} sm={10}>
          <br /> <br /> <br />
          <div
            id="play_here"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <iframe
              onLoad={handleDoc}
              title="play"
              id="play"
              style={{
                height: "100%",
                border: "0px none",
                marginTop: "-50px",
                width: "926px",
              }}
              src={props.location.state.play_url}
            />
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default withStyles(styles)(IframePlayer);
