import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import AlertComponent from "./AlertComponent";
import store from "store";
function ExamExpired(props) {
  const { history } = props;
  const exam = store.get("examObject");
  const [confirmOpen, setConfirmOpen] = useState(true);
  const handleCloseAlertBox = () => {
    setConfirmOpen(false);
    history.push("/exam-portal", exam && exam.test_name ? exam : {});
  };
  return (
    <AlertComponent
      open={confirmOpen}
      alertType={"error"}
      closeAlert={handleCloseAlertBox}
    >
      {props.location.state.message}
      <br />
    </AlertComponent>
  );
}

export default compose(withRouter)(ExamExpired);
