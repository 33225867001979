import React from "react";
import Gallerys,{ImageComponent} from "react-photo-gallery";
import image1 from "./logos/dashboard.png";
import image2 from "./logos/attendance.png";
import image3 from "./logos/exam.png";
import image4 from "./logos/admissions.png";
import image5 from "./logos/FailedStatus.png";

const photo = [
  {
    src: image1,
    width: "50px",
    height: "50px",
    title:"Finance Dashboard",
   
  },
  {
    src: image2,
    width: 4927,
    height: 1000,
    title:"Attendance Dashboard",
 
  },
  {
    src: image3,
    width: 4927,
    height: 1000,
    title:"Rank Analysis",
  },
  {
    src: image4,
    width: 4927,
    height: 1000,
    title:"Admissions Dashboard",
  },
  {
    src: image5,
    width: 4927,
    height: 1000,
    title:"Subject wise Analysis",
  },
  

];



class Picture extends React.Component {
  render() {
    const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};
    return (
      <div className="flex space-x-3 overflow-y-scroll scrollbar-hide p-3 -ml-3" style={{paddingTop:"20px"}}>
        
        &nbsp;   &nbsp;   &nbsp;
        <Gallerys
          direction={"row"}
          margin={4}
          photos={photo}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            // if (swipe < -swipeConfidenceThreshold) {
            //   paginate(1);
            // } else if (swipe > swipeConfidenceThreshold) {
            //   paginate(-1);
            // }
          }}
          renderImage={({ photo }) => (
            
            <div style={{ position: "relative",padding:1,marginTop:2 }}>
              
              <p style={{ position: "absolute", top: "-24px", left: "50%", transform: "translateX(-50%)",  padding: "5px 10px", borderRadius: "5px",fontsize:"17px",fontWeight:"bold" }}>{photo.title}</p>
              &nbsp;
              <img
                src={photo.src}
                alt={photo.title}
                style={{ width: photo.width, height: "290px", borderRadius: "10px", padding: 2 }} />
           
            </div>
          )}
        />
      </div>
    );
  }
}

export default Picture;
// import React from "react";
// import Gallerys from "react-photo-gallery";
// import image1 from "./dashboard.png";
// import image2 from "./attendance.png";
// import image3 from "./exam.png";
// import image4 from "./admissions.png";
// import image5 from "./FailedStatus.png";

// const images = [image1, image2, image3, image4, image5];
// const getTitle = (index) => {
//   switch (index) {
//     case 0:
//       return "Finance Dashboard";
//     case 1:
//       return "Attendance Dashboard";
//     case 2:
//       return "Rank Analysis";
//     case 3:
//       return "Admissions Dashboard";
//     case 4:
//       return "Subject wise Analysis";
//     default:
//       return "";
//   }
// };

// const photo = images.map((src, index) => ({
//   src: src,
//   width: 4927,
//   height: 1000,
//   title: getTitle(index), // Define your title function accordingly
// }));

// class Picture extends React.Component {
//   componentDidMount() {
//     window.addEventListener("load", this.handleImagesLoaded);
//   }

//   componentWillUnmount() {
//     window.removeEventListener("load", this.handleImagesLoaded);
//   }

//   handleImagesLoaded = () => {
//     // Remove scroll lock after all images are loaded
//     // You can adjust this based on your requirement
//     document.body.style.overflow = "auto";
//   };

//   render() {
//     return (
//       <div className="flex space-x-3 overflow-y-scroll scrollbar-hide p-3 -ml-3" style={{ paddingTop: "20px" }}>
//         <Gallerys
//           direction={"row"}
//           margin={4}
//           photos={photo}
//           drag="x"
//           dragConstraints={{ left: 0, right: 0 }}
//           dragElastic={1}
//           renderImage={({ photo }) => (
//             <div style={{ position: "relative", padding: 1, marginTop: 2 }}>
//               <p style={{ position: "absolute", top: "-24px", left: "50%", transform: "translateX(-50%)", padding: "5px 10px", borderRadius: "5px", fontsize: "17px", fontWeight: "bold" }}>{photo.title}</p>
//               &nbsp;
//               <img
//                 src={photo.src}
//                 alt={photo.title}
//                 style={{ width: photo.width, height: "290px", borderRadius: "10px", padding: 2 }} />
//             </div>
//           )}
//         />
//       </div>
//     );
//   }
// }

// export default Picture;
