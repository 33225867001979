
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Box, Button } from "@material-ui/core";
import "react-phone-input-2/lib/style.css";

const PhoneInputGfg = () => {
  const [phone, setPhone] = useState("");

  // handle api call here to send link to phone number
  return (
    <>
      <Box display="flex" alignItems="center" height="50px" width="400px">
        {" "}
        {/* Adjusted height and width */}
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <PhoneInput
            className="number"
            country={"in"}
            value={phone}
            onChange={(phone) => setPhone(phone)}
            placeholder="Enter Phone number"
            inputStyle={{ height: "100%", width: "calc(100% - 100px)" }}
            containerStyle={{ height: "100%" }}
          />
          <Button
            variant="contained"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              height: "100%", // Adjusted height
              width: "150px", // Adjusted width
              background: "#3BBDED",
              color: "#FFFF",
              borderRadius: "0 4px 4px 0", // match PhoneInput's border-radius
            }}
          >
            Get Link
          </Button>
        </div>
      </Box>
    </>
  );
};

export default PhoneInputGfg;
