// import React, { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";
// import { wrap } from "popmotion";
// import { makeStyles } from "@material-ui/core/styles";
// import { Button } from "@material-ui/core";
// import CustomerReviewCard from "./CustomerReviewCard";
// import { customerReviews, customerReviews1 } from "./CradsDetails";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
// import Typography from "@material-ui/core/Typography";
// import Paper from "@material-ui/core/Paper";
// import Grid from "@material-ui/core/Grid";
// import Box from "@material-ui/core/Box";
// import Fab from "@material-ui/core/Fab";
// import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import clsx from "clsx";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//     flexGrow: 1,
//     padding: theme.spacing(6),
//     margin: theme.spacing(6),
//     width: "1446px",
//     height: "auto",
//     overflow: "hidden",
//     borderRadius: "24px",
//   },

//   heading: {
//     marginBottom: "10px",
//     fontStyle: "normal",
//     fontWeight: "800",
//     fontSize: "32px",
//     lineHeight: "44px",
//     color: "#31456A",
//     paddingTop: "30px",
//   },

//   subHeading: {
//     marginBottom: "40px",
//     fontStyle: "normal",
//     fontWeight: "500",
//     fontSize: "18px",
//     lineHeight: "24px",
//     color: "#31456a",
//   },

//   paper: {
//     padding: theme.spacing(2),
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//   },
//   hovered: {
//     background: "#B8B8B8 !important",
//     color: "#FFFF !important",
//   },
// }));

// const variants = {
//   enter: (direction) => {
//     return {
//       x: direction > 0 ? 1000 : -1000,
//       opacity: 0,
//     };
//   },
//   center: {
//     zIndex: 1,
//     x: 0,
//     opacity: 1,
//   },
//   exit: (direction) => {
//     return {
//       zIndex: 0,
//       x: direction < 0 ? 1000 : -1000,
//       opacity: 0,
//     };
//   },
// };

// const swipeConfidenceThreshold = 10000;
// const swipePower = (offset, velocity) => {
//   return Math.abs(offset) * velocity;
// };

// const Example = () => {
//   const [page, setPage] = useState(0);
//   const [direction, setDirection] = useState(0);
//   const classes = useStyles();
//   const imageIndex = wrap(0, customerReviews.length, page);
//   const [isHovered, setIsHovered] = useState(false);
//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       paginate(1);
//     }, 4300); // Change the interval duration as needed (in milliseconds)

//     return () => clearInterval(intervalId);
//   }, [page]);

//   const paginate = (newDirection) => {
//     setPage((prevPage) =>
//       wrap(0, customerReviews.length, prevPage + newDirection)
//     );
//     setDirection(newDirection);
//   };

//   return (
//     <div>
//       <Grid
//         // xs={12}
//         // sm={1}
//         container
//         spacing={4}
//         style={{
//           // margin: "7px",
//           justifyContent: "center",
//           alignContent: "center",
//         }}
//       >
//         <Grid
//           item
//           xs={12}
//           container
//           style={{
//             justifyContent: "center",
//             alignContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Typography className={classes.heading}>
//             What our customers were saying
//           </Typography>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           container
//           style={{
//             justifyContent: "center",
//             alignContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Typography className={classes.subHeading}>
//             Vital KPI’s, Real-time updates, Customisable dashlets
//           </Typography>
//         </Grid>

//         <Grid item xs={1.5} />
//         {customerReviews.map((item, index) => (
//           <Grid item xs={12} sm={3}>
//             <CustomerReviewCard
//               key={index}
//               icon={item.icon}
//               title={item.title}
//               description={item.description}
//             />
//           </Grid>
//         ))}
//         <Grid item xs={1.5} />
//         <Grid item xs={1.5} />
//         {customerReviews1.map((item, index) => (
//           <Grid item xs={12} sm={3}>
//             <CustomerReviewCard
//               key={index}
//               icon={item.icon}
//               title={item.title}
//               description={item.description}
//             />
//           </Grid>
//         ))}
//         <Grid item xs={1.5} />
//       </Grid>
//     </div>
//   );
// };

// export default Example;
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import CustomerReviewCard from "./CustomerReviewCard";
import { customerReviews, customerReviews1 } from "./CradsDetails";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
      margin: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      margin: theme.spacing(6),
    },
    width: "100%",
    height: "auto",
    overflow: "hidden",
    borderRadius: "24px",
  },
  heading: {
    marginBottom: theme.spacing(1),
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#31456A",
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      fontSize: "32px",
      lineHeight: "44px",
      marginBottom: theme.spacing(2),
    },
  },
  subHeading: {
    marginBottom: theme.spacing(2),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#31456a",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      lineHeight: "24px",
      marginBottom: theme.spacing(4),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  hovered: {
    background: "#B8B8B8 !important",
    color: "#FFFF !important",
  },
}));

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Example = () => {
  const [page, setPage] = useState(0);
  const [direction, setDirection] = useState(0);
  const classes = useStyles();
  const imageIndex = wrap(0, customerReviews.length, page);
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    const intervalId = setInterval(() => {
      paginate(1);
    }, 4300); // Change the interval duration as needed (in milliseconds)

    return () => clearInterval(intervalId);
  }, [page]);

  const paginate = (newDirection) => {
    setPage((prevPage) =>
      wrap(0, customerReviews.length, prevPage + newDirection)
    );
    setDirection(newDirection);
  };

  return (
    <div>
      <Grid
        container
        spacing={4}
        style={{ justifyContent: "center", alignContent: "center" }}
      >
        <Grid item xs={12} container justify="center" alignItems="center">
          <Typography className={classes.heading}>
            What our customers were saying
          </Typography>
        </Grid>
        <Grid item xs={12} container justify="center" alignItems="center">
          <Typography className={classes.subHeading}>
            Vital KPI’s, Real-time updates, Customisable dashlets
          </Typography>
        </Grid>

        <Grid item xs={false} sm={1.5} />
        {customerReviews.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <CustomerReviewCard
              icon={item.icon}
              title={item.title}
              description={item.description}
            />
          </Grid>
        ))}
        <Grid item xs={false} sm={1.5} />
        <Grid item xs={false} sm={1.5} />
        {customerReviews1.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <CustomerReviewCard
              icon={item.icon}
              title={item.title}
              description={item.description}
            />
          </Grid>
        ))}
        {/* <Grid item xs={false} sm={1.5} /> */}
      </Grid>
    </div>
  );
};

export default Example;
