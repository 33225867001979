const BCONM_1 = {
  questions: {
    "1": "1",
    "2": "1",
    "3": "1",
    "4": "1",
    "5": "1",
    "6": "1",
    "7": "2",
    "8": "2",
    "9": "2",
    "10": "2",
    "11": "3",
    "12": "3",
    "13": "3",
    "14": "3",
    "15": "3",
    "16": "4",
    "17": "4",
    "18": "4",
    "19": "4",
    "20": "4",
    "21": "1",
    "22": "1",
    "23": "1",
    "24": "1",
    "25": "1",
    "26": "1",
    "27": "2",
    "28": "2",
    "29": "2",
    "30": "2",
    "31": "3",
    "32": "3",
    "33": "3",
    "34": "3",
    "35": "3",
    "36": "4",
    "37": "4",
    "38": "4",
    "39": "4",
    "40": "4",
    "41": "1",
    "42": "1",
    "43": "1",
    "44": "1",
    "45": "1",
    "46": "1",
    "47": "2",
    "48": "2",
    "49": "2",
    "50": "2",
    "51": "3",
    "52": "3",
    "53": "3",
    "54": "3",
    "55": "3",
    "56": "4",
    "57": "4",
    "58": "4",
    "59": "4",
    "60": "4",
  },
  sections: {
    1: [1, 21, 41],
    2: [7, 27, 47],
    3: [11, 31, 51],
    4: [16, 36, 56],
  },
};

export default BCONM_1;
