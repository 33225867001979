import store from "store";
const profileEditCondition = () => {
  const { hostname } = window.location;
  const user = store.get("user");
  const allowEditing =
    !("fatherName" in user) &&
    !(user.personalDetails && "fatherName" in user.personalDetails);

  return (
    (hostname.includes("bhashyam") &&
      user.level == "2119611" &&
      user.statusType == "unpaid") ||
    (hostname.includes("viitjee") && user.level == "17410" && allowEditing) ||
    (hostname.includes("agastya") &&
      user.level == "X0210761" &&
      allowEditing) ||
    (hostname.includes("excellencia") &&
      user.level == "X0210779" &&
      allowEditing) ||
    (hostname.includes("miity") && user.level == "X0210780" && allowEditing) ||
    (hostname.includes("motion") &&
      ["X0210764", "X0210766", "X0210765", "X0210767"].includes(
        `${user.level}`
      ) &&
      allowEditing)
  );
};
export default profileEditCondition;
