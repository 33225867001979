import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrorAnalytics,
  getAnalyticsSingleQuestion,
} from "../../../redux/actions/practicePageActions/actionCreators";
import AnalyticsPopUp from "./AnalyticsPopUp.jsx";
import EqualizerOutlinedIcon from "@material-ui/icons/EqualizerOutlined";
const AnalyticsSingleQuestion = ({ body }) => {
  const { student_id, subject, topic, subTopic, qId, instituteId } = body;
  const analyticsData = useSelector((state) => state.analyticsCurrentQuestion);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    dispatch(
      getAnalyticsSingleQuestion({
        student_id,
        subject,
        topic,
        subTopic,
        qId,
        instituteId,
      })
    );
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(clearErrorAnalytics());
  };
  return (
    <React.Fragment>
      <Button onClick={handleClickOpen}>
        <EqualizerOutlinedIcon />
      </Button>
      <AnalyticsPopUp
        open={open}
        handleClose={handleClose}
        analyticsData={analyticsData}
      />
    </React.Fragment>
  );
};

export default AnalyticsSingleQuestion;
