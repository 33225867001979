import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import store from "store";
import Header from "./Header";
const useStyles = makeStyles((theme) => ({
  rocketFrame: {
    width: "100% !important",
    height: "80vh !important",
  },
}));
const Discuss = () => {
  const classes = useStyles();
  const chat = store.get("chatAuth");
  return (
    <Grid container direction="column">
      <Grid item>
        <Header />
      </Grid>
      <br />
      <Grid item>
        {chat && chat.token ? (
          <iframe
            className={classes.rocketFrame}
            src={`https://onesaz.rocket.chat/home?resumeToken=${chat.token}`}
            title="Discussion"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        ) : null}
      </Grid>
      <br />
    </Grid>
  );
};

export default Discuss;
