import { Grid, ThemeProvider } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import theme from "../../assets/theme";
import { ExamTab, ExamTabs } from "../ExamTypeSelectNav";
import ComingSoonPlans from "./ComingSoonPlans.jsx";
import Footer from "./Footer";
import Header from "./Header.jsx";
import PlansContent from "./PlansContent";

function Plans() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Header />
        <br />
        <Grid
          container
          spacing={3}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid xs={12} sm={10} md={10}>
            <ExamTabs value={value} onChange={handleChange}>
              {/* <ExamTab label="IIT-JEE  Target 2019-2020" /> */}
              <ExamTab label="Current Packages" />
              {/* <ExamTab label="IIT JEE for 2nd year" /> */}
            </ExamTabs>
          </Grid>
          <Grid item xs={12} sm={10} md={8}>
            {value === 0 && <PlansContent courseName="JEEMAIN  2022" />}
            {/* {value === 1 && (
              
              <CrashCoursePlans courseName="IIT-JEE  Target 2019-2020" />
              // <ComingSoonPlans />
              // <PlansContent courseName="Premium Plus"/>
            )} */}
            {value === 2 && (
              <ComingSoonPlans />
              // <PlansContent/>
            )}
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br /> <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Footer />
      </ThemeProvider>
    </Fragment>
  );
}

export default Plans;
