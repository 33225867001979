import React, { useState, useEffect, useRef } from "react";
import store from "store";
import { useStyles } from "../Components/styles";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import TableHead from "@material-ui/core/TableHead";
import {
  CustomRowType2,
  CustomTableCellType2,
  CustomTableRow,
} from "../../components/CustomTableCells";
import DownloadTable from "../../components/DownloadTable";

const ColumnShortNames = {
  MATHEMATICS: "MAT",
  MATHEMATICSPercent: "%",
  CHEMISTRY: "CHE",
  CHEMISTRYPercent: "%",
  PHYSICS: "PHY",
  PHYSICSPercent: "%",
  Marks: "TOT",
  percentage: "%",
  MATHEMATICSStatus: "Status",
  PHYSICSStatus: "Status",
  CHEMISTRYStatus: "Status",
  "LANGUAGE-I": "LAN-I",

  "LANGUAGE-IPercent": "%",

  "LANGUAGE-IStatus": "Status",
  "LANGUAGE-II": "LAN-II",

  "LANGUAGE-IIPercent": "%",

  "LANGUAGE-IIStatus": "Status",
  "MATHS-A": "MAT-A",

  "MATHS-APercent": "%",

  "MATHS-AStatus": "Status",
  "MATHS-B": "MAT-B",

  "MATHS-BPercent": "%",

  "MATHS-BStatus": "Status",

  BOTANY: "BOT",

  BOTANYPercent: "%",

  BOTANYStatus: "Status",
  ZOOLOGY: "ZOO",

  ZOOLOGYPercent: "%",

  ZOOLOGYStatus: "Status",
  BIOLOGY: "BIO",

  BIOLOGYPercent: "%",

  BIOLOGYStatus: "Status",
  ENGLISH: "ENG",

  ENGLISHPercent: "%",

  ENGLISHStatus: "Status",
  SANSKRIT: "SAN",

  SANSKRITPercent: "%",

  SANSKRITStatus: "Status",
  TELUGU: "TEL",

  TELUGUPercent: "%",

  TELUGUStatus: "Status",
  HINDI: "HIN",

  HINDIPercent: "%",

  HINDIStatus: "Status",
  SCIENCE: "SCI",

  SCIENCEPercent: "%",

  SCIENCEStatus: "Status",
  CIVICS: "CIV",
  CIVICSPercent: "%",

  CIVICSStatus: "Status",
  COMMERCE: "COM",

  COMMERCEPercent: "%",

  COMMERCEStatus: "Status",
  ECONOMICS: "ECO",
  ECONOMICSPercent: "%",
  ECONOMICSStatus: "Status",
  PHYSICAL_SCIENCE: "PHY_SCI",

  PHYSICAL_SCIENCEPercent: "%",

  PHYSICAL_SCIENCEStatus: "Status",
  "LANGUAGE-IRank": "Grade",
  "LANGUAGE-IIRank": "Grade",

  "MATHS-ARank": "Grade",

  "MATHS-BRank": "Grade",
  "MATHS-BStatus": "Grade",

  MATHEMATICSRank: "Grade",

  PHYSICSRank: "Grade",

  CHEMISTRYRank: "Grade",

  BOTANYRank: "Grade",

  ZOOLOGYRank: "Grade",

  BIOLOGYRank: "Grade",

  ENGLISHRank: "Grade",

  SANSKRITRank: "Grade",

  TELUGURank: "Grade",

  HINDIRank: "Grade",

  SCIENCERank: "Grade",

  CIVICSRank: "Grade",

  COMMERCERank: "Grade",

  ECONOMICSRank: "Grade",

  PHYSICAL_SCIENCERank: "Grade",
  PHYSICAL_SCIENCEStatus: "Grade",

  rank: "Grade",
};

function TestResultsTable({ data }) {
  const classes = useStyles();
  const tableRef = useRef(null);
  // Extract the unique keys from the result object to use as column headings
  const resultKeys = data.reduce((keys, item) => {

    for (const key in item.result) {
      if (!keys.includes(key)) {
        keys.push(key);
      }
    }

    return keys;
  }, []);

  const finalKeys = resultKeys.filter(
    (item) =>
      !item.includes("Total") &&
      item !== "0" &&
      item !== "1" &&
      // !item.includes("Rank") &&
      !item.includes("total") &&
      // !item.includes("rank") &&
      !item.includes("ATT") &&
      !item.includes("Marks") &&
      !item.includes("percentage") &&
      item !== "Status" &&
      item !== "rank"
  );
  const colors = {
    Fail: "red",
    Pass: "green",
    A: "green",
    B: "blue",
    C: "purple",
    D: "orange",
    E: "red",
    F: "red",
  };

  return (
    <DownloadTable
      tableId="resultsTable"
      tableName={store.get("user").userId}
      ref={tableRef}
      alignDownloadButtons={true}
    >
      <TableHead>
        <CustomTableRow>
          <CustomTableCellType2
            align="left"
            style={{ fontSize: "17px", color: "#31456A" }}
            colSpan={finalKeys.length + 7}
          >
            Name : &nbsp;&nbsp; {store.get("user").name}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; User Id : &nbsp;&nbsp;
            {store.get("user").userId}
            &nbsp;&nbsp; &nbsp;&nbsp;
          </CustomTableCellType2>
        </CustomTableRow>
      </TableHead>
      <TableHead>
        <CustomTableRow>
          <CustomTableCellType2 className={classes.tableHeaderStyle}>
            S.No:
          </CustomTableCellType2>
          <CustomTableCellType2
            align="left"
            className={classes.tableHeaderStyle}
          >
            Test Name
          </CustomTableCellType2>
          <CustomTableCellType2
            style={{ whiteSpace: "nowrap" }}
            className={classes.tableHeaderStyle}
          >
            Date
          </CustomTableCellType2>
          <CustomTableCellType2 className={classes.tableHeaderStyle}>
            Course
          </CustomTableCellType2>
          {[...new Set(finalKeys)].map((key) => (
            <CustomTableCellType2
              className={classes.tableHeaderStyle}
              key={key}
            >
              {ColumnShortNames[key]}
            </CustomTableCellType2>
          ))}
          <CustomTableCellType2 className={classes.tableHeaderStyle}>
            TOT
          </CustomTableCellType2>
          <CustomTableCellType2 className={classes.tableHeaderStyle}>
            %
          </CustomTableCellType2>
          <CustomTableCellType2 className={classes.tableHeaderStyle}>
            Status
          </CustomTableCellType2>
          <CustomTableCellType2 className={classes.tableHeaderStyle}>
            Grade
          </CustomTableCellType2>
        </CustomTableRow>
      </TableHead>
      <TableBody>
        {data.map((item, index) => (
          <CustomRowType2
            hover
            role="checkbox"
            tabIndex={-1}
            key={index}
            className={classes.tr}
          >
            <CustomTableCellType2>{index + 1}</CustomTableCellType2>
            <CustomTableCellType2 align="left">
              {item.testDisplayName}
            </CustomTableCellType2>
            <CustomTableCellType2 style={{ whiteSpace: "nowrap" }}>
              &nbsp;&nbsp; {moment(item.startDateTime).format("DD-MM-YY")}{" "}
              &nbsp; &nbsp;
            </CustomTableCellType2>
            <CustomTableCellType2>{item.test_type}</CustomTableCellType2>
            {[...new Set(finalKeys)].map((key) => (
              <CustomTableCellType2
                key={key}
                style={{ color: colors[item.result[key]] }}
              >
                {item.result[key] || "-"}
              </CustomTableCellType2>
            ))}

            <CustomTableCellType2>{item.result.Marks}</CustomTableCellType2>
            <CustomTableCellType2>
              {item.result.percentage || "-"}
            </CustomTableCellType2>
            <CustomTableCellType2 style={{ color: colors[item.result.Status] }}>
              {item.result.Status || "-"}
            </CustomTableCellType2>
            <CustomTableCellType2 style={{ color: colors[item.result.rank] }}>
              {item.result.rank || "-"}
            </CustomTableCellType2>
            <td />
          </CustomRowType2>
        ))}
      </TableBody>
    </DownloadTable>
  );
}

export default TestResultsTable;
