import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  accordion: {
    boxShadow: "none", // Remove shadow
    backgroundColor: "transparent", // Remove background color
    border: "none", // Remove border
    width: "40%", // Adjust this as needed
  },
  accordionDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  accordionSummary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "2px solid #415B8C", // Add this line
  },
  
});

const Tree = ({ heading, subHeading, classes }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion
      className={classes.accordion}
      expanded={expanded}
      onChange={handleExpand}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={
          expanded ? (
            <RemoveIcon
              style={{
                color: "#ffffff",
              }}
            />
          ) : (
            <AddIcon
              style={{
                color: "#ffffff",
              }}
            />
          )
        }
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
      >
        <Typography
          variant="h4"
          style={{
            color: "#ffffff",
            fontFamily: "Montserrat",
            fontSize: "24px",
            fontWeight: 500,
            lineHeight: "30px",
            textAlign: "left",
          }}
        >
          {heading}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Typography
          color="textSecondary"
          style={{
            fontFamily: "Montserrat",
            color: "#415B8C",
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "30px",
            textAlign: "left",
          }}
        >
          {subHeading}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default function ActionsInAccordionSummary() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tree
        classes={classes}
        heading="Why do educational institutes need an LMS?"
        subHeading="An LMS helps educational institutes streamline course management, deliver online learning materials, track student progress, and facilitate communication between students and instructors"
      />
      <Tree
        classes={classes}
        heading="What are the key features of an LMS?"
        subHeading="Key features of an LMS include course management, content authoring, assessment and grading tools, communication tools (such as discussion forums and messaging), analytics and reporting, and integration capabilities with other systems."
      />
      <Tree
        classes={classes}
        heading="What is an ERP system in the context of educational institutes?"
        subHeading="An ERP system in the context of educational institutes is a software solution that integrates various administrative and operational functions such as finance, human resources, student information, and academic management into a single system."
      />
       <Tree
        classes={classes}
        heading="What are the common modules or components of an ERP system for educational institutes?"
        subHeading="Common modules or components of an ERP system for educational institutes include finance and accounting, human resources, student information management, admissions, enrollment management, academic planning and scheduling, and facilities management."
      />
      <Tree
        classes={classes}
        heading="How can educational institutes ensure a successful implementation of an LMS or ERP system?"
        subHeading="Successful implementation requires thorough planning, stakeholder involvement, effective change management, comprehensive training for users, ongoing support, and regular evaluation and optimization of the system to align with the institute's goals and objectives."
      />
    </div>
  );
}
