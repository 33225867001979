import axios from "./examServerAxios";
import store from "store";

const { institute, instituteId } = store.get("user") || {};
const user = store.get("user") || {};
const inst = user.institute != null ? user.institute : user.instituteId;

export async function getResultDetails(body) {
  try {
    let data = await axios.post("/analysis/results", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getTimeSpentAnalysis(body) {
  try {
    let data = await axios.post("/analysis/timespent", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getStudentBehaviour(exam, student_id) {
  try {
    const data = await axios.get(
      `/analysis/getStudentBehavior?exam=${exam}&student_id=${student_id}`
    );
    return data.data;
  } catch (error) {
    return error;
  }
}

export async function getRankAnalysis(body) {
  try {
    let data = await axios.post("/analysis/rank", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getCompareResultQuesDetails(body) {
  try {
    let data = await axios.post("/analysis/questiondetails", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getStudentAnswersCount(testName) {
  try {
    return axios.get(`/analysis/countStudentsAnswers?testName=${testName}`);
  } catch (error) {
    return error;
  }
}

export async function getStudentExamAnalysis(payload) {
  try {
    const data = await axios.get(
      `/analysis/getStudentExamAnalysis/${payload.student_id}`,
      {
        params: {
          ...payload,
          instituteId: inst
        }
      }
    );
    return data.data;
  } catch (error) {
    return error;
  }
}
