
import axios from "./examServerAxios";

const COMMON = "/appSettings";
export async function getAppSettingsInfo(obj) {
  try {
      // Send instituteId as a query parameter
      const { instituteId } = obj;
      const response = await axios.get(`${COMMON}/get-details`, {
          params: { instituteId }
      });
      return response.data;
  } catch (error) {
      return Promise.reject(error);
  }
}


