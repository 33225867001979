import React from 'react'
import { Button } from '@material-ui/core'
import styled from 'styled-components'
import WrapperCard from './WrapperCard'

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-align: left;
`

const ArrowButton = ({ handleClick, bgColor, endIcon, content }) => (
  <WrapperCard style={{ backgroundColor: bgColor }}>
    <StyledButton endIcon={endIcon} onClick={handleClick} bgColor={bgColor}>
      {content}
    </StyledButton>
  </WrapperCard>
)

export default ArrowButton;
