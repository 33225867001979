import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

// Update useStyles to accept props
const useStyles = makeStyles(theme => ({
  paper: props => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // alignItems: 'center',
    padding: '12px',
    gap: '10px',
    width: '100%',
    height: '100%',
    background: '#EBEFF3',
    boxShadow: `4px 4px 8px rgba(189, 194, 199, 0.75),
                    8px 8px 12px rgba(189, 194, 199, 0.25),
                   -4px -4px 8px rgba(255, 255, 255, 0.75),
                   -8px -8px 12px rgba(255, 255, 255, 0.25)`,
    borderRadius: '16px',
    flex: 'none',
    order: 0,
    flexGrow: 1,
    '@media (min-width: 458px)': {
      height: props.mediaHeight || 'inherit'
    }
  })
}))

const WrapperCard = ({ children, className, style, mediaHeight }) => {
  const classes = useStyles({ mediaHeight })

  return (
    <div className={`${classes.paper} ${className}`} style={style}>
      {children}
    </div>
  )
}

WrapperCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  mediaHeight: PropTypes.string
}

WrapperCard.defaultProps = {
  className: '',
  style: {},
  mediaHeight: 'inherit' // Default height
}

export default WrapperCard
