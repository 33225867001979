import { makeStyles, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React from "react";
const useStyles = makeStyles(() => ({
  root: {
    minHeight: "50vh",
  },
}));
function ComingSoonPlans({ plans = "Coming Soon" }) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <Typography color="textPrimary" align="center" variant="h2">
        {plans}
      </Typography>
    </Box>
  );
}
export default ComingSoonPlans;
