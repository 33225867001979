import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
const useStyle = makeStyles((theme) => ({
  questionType: {
    backgroundColor: theme.palette.background.default,
    padding: "1px 4px",
    borderRadius: "4px",
  },
}));
const QuestionTypeComponent = ({ qType }) => {
  const classes = useStyle();
  return (
    <Grid
      container
      justify="space-around"
      alignItems="center"
      className={classes.questionType}
    >
      <Grid item />
      <Grid item>
        <Typography variant="caption" color="textSecondary">
          {qType}&nbsp;Correct
        </Typography>
      </Grid>
    </Grid>
  );
};

export default QuestionTypeComponent;
