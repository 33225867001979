import React from "react";
import theme from "../../assets/theme";
// Import react-circular-progressbar module and styles
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function CircularRingProgress({ correctValue, wrongValue, displayText }) {
  return (
    <CircularProgressbarWithChildren
      value={wrongValue}
      styles={buildStyles({
        pathColor: theme.palette.text.primary,
        trailColor: theme.palette.common.white,
        rotation: 0.5,
      })}
    >
      {/* Foreground path */}
      <CircularProgressbar
        value={correctValue}
        text={displayText}
        styles={buildStyles({
          trailColor: "transparent",
          pathColor: theme.palette.action.selected,
          rotation: 0.5,
          textColor: theme.palette.action.selected,
        })}
      />
    </CircularProgressbarWithChildren>
  );
}

export default CircularRingProgress;
