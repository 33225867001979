import * as actionType from "../../actions/appSettingsAction/actions";

const INITIAL_STATE = {
  isLoading: false,
  data: {},
  error: null,
};
export const AppSettingsReducer = (appSettings = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.APP_SETTINGS_LOADING:
      return {
        ...appSettings,
        isLoading: true,
      };
    case actionType.APP_SETTINGS_SUCCESS:
      return {
        ...appSettings,
        isLoading: false,
        data: action.payload,
      };
    case actionType.CATCH_ERROR_APP_SETTINGS:
      return {
        ...appSettings,
        isLoading: false,
        error: action.payload,
      };
    case actionType.CLEAR_ERROR_APP_SETTINGS:
      return INITIAL_STATE;
    default:
      return appSettings;
  }
};
