import {
  Box,
  CircularProgress,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";

const styles = (theme) => ({
  circleWrapper: {
    position: "relative",
    display: "inline-flex",
    float: "right",
  },
  circleProgress: {
    color: "#8E9AAF", // Progress circle color
  },
  circleInnerProgress: {
    position: "absolute",
    left: 0,
    color: "#e0e0e0", // Light gray background circle
  },
  circleContent: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  gradeText: {
    fontWeight: "bold",
  },
});

const GradeCircle = ({ score, totalMarks, classes, text, color }) => {
  const percentage = (score / totalMarks) * 100;

  return (
    <Box className={classes.circleWrapper}>
      <CircularProgress
        variant="determinate"
        value={percentage}
        size={130}
        thickness={5}
        className={classes.circleProgress}
      />
      <CircularProgress
        variant="determinate"
        value={100}
        size={130}
        thickness={5}
        className={classes.circleInnerProgress}
      />
      <Box className={classes.circleContent}>
        <Typography variant="subtitle1">{text}</Typography>
        <Typography
          variant="h4"
          className={classes.gradeText}
          style={{ color: color }}
        >
          {score}
        </Typography>
        <Typography variant="caption">out of {totalMarks}</Typography>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(GradeCircle);
