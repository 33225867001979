import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ResultHeader from "../resultPage/ResultHeader";
import { Box, Typography } from "@material-ui/core";
import Filters from "./Filters";
import LabeledSwitch from "../HomePage/Components/LabeledSwitch";
import useFetch from "../../hooks/useFetch";
import { getStudentExamAnalysis } from "../../apis/resultService";
import calculateDates from "../../utils/caluculateDateRanges";
import store from "store";
import NetworkProgress from "../NetworkProgress";
import RowBox from "../HomePage/Components/RowBox";
import CardInsideOut from "../HomePage/Components/CardInsideOut";
import TestMarks from "./TestMarks";
import examAxios from "../../apis/examServerAxios";
import { groupBy } from "lodash";
import ColumnChart from "./columnChart";
import NoResultMsg from "../resultPage/NoResultMsg";

function getLatestTestCategory(data) {
  let latestCategory = null;
  let latestTest = null;

  for (const [category, tests] of Object.entries(data)) {
      for (const test of tests) {
          const startDateTime = new Date(test.startDateTime).getTime();

          // Update if this test has the latest startDateTime
          if (!latestTest || startDateTime > latestTest) {
              latestTest = startDateTime;
              latestCategory = category;
          }
      }
  }

  return latestCategory;
}

const ExamAnalysis = () => {
  const location = useLocation();
  const user = store.get("user");
  const query = new URLSearchParams(location.search);
  const level = query.get("level");
  const account = query.get("accountType");
  const userLevel = level || (user && user.level);
  const accountType = account || (user && user.accountType);
  const student_id = user && user.accountType ? user.studentIds : user._id;

  const [load, setLoad] = useState(false);
  const [allExams, setAllExams] = useState({});
  const [courses, setCourses] = useState([]);
  const [selectedDateValue, setSelectedDateValue] = useState("1 month");
  const [selectedCourseValue, setSelectedCourseValue] = useState("");
  const [exams, setExams] = useState([]);
  const [testsAavailable, setTestsAavailable] = useState(false);
  const [selectedExams, setSelectedExams] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [finalData, setFinalData] = useState([]);

  const [isLoading, err, analysisData, getAnalysis, reset] = useFetch(
    getStudentExamAnalysis
  );

  const getAllExams = async () => {
    try {
      setLoad(true);
      const exams = await examAxios.post("/exams/tests", { student_id });
      const vals = exams.data.payload.Items;
      const data = vals.sort((a, b) => (a.test_status === "FINISHED" ? -1 : 1));
      setTestsAavailable(data.length > 0);
      const groupData = groupBy(data, "test_type");
      const sortedGroupData = Object.keys(groupData)
        .sort((a, b) => {
          const aFinished = groupData[a].some(
            test => test.test_status === "FINISHED"
          );
          const bFinished = groupData[b].some(
            test => test.test_status === "FINISHED"
          );
          return aFinished === bFinished ? 0 : aFinished ? -1 : 1;
        })
        .reduce((acc, key) => {
          acc[key] = groupData[key];
          return acc;
        }, {});
      
     const d= getLatestTestCategory(sortedGroupData);


      const courseList = Object.keys(sortedGroupData).map(c => ({
        label: c,
        value: c
      }));
      setCourses(courseList);
      setAllExams(sortedGroupData);

      // Set the initial selected course if available
      if (courseList.length > 0) {
        setSelectedCourseValue(d);
      }
      setLoad(false);
    } catch (err) {
      setLoad(false);
     
    }
  };

  const handleSwitchChange = event => {
    setIsChecked(event.target.checked);
  };

  const handleDateChange = value => {

    setSelectedDateValue(value);
  };

  const handleCourseChange = value => {

    setSelectedCourseValue(value);
    reset();
  };

  const handleExamsChange = value => {
    const currentIndex = selectedExams.indexOf(value);
    const newChecked = [...selectedExams];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedExams(newChecked);
  };

  useEffect(() => {
    getAllExams();
  }, []);

  useEffect(() => {
    if (selectedCourseValue && selectedDateValue) {
      const payload = {
        dates: calculateDates(selectedDateValue),
        course: selectedCourseValue,
        avg: isChecked,
        student_id
      };
      getAnalysis(payload);
    }
  }, [selectedCourseValue, selectedDateValue, isChecked]);

  useEffect(() => {
    if (analysisData && !isLoading && analysisData.output) {
      const results = analysisData.output.results || [];
      const fetchedExams = results.map(exam => exam.test_name) || [];
      setExams(fetchedExams);
      setSelectedExams(fetchedExams); // Initialize selected exams with fetched exams
    }
  }, [analysisData, isLoading]);

  useEffect(() => {
    if (analysisData && analysisData.output && analysisData.output.results) {
      const data = analysisData.output.results;
      const filteredData =
        selectedExams.length > 0
          ? data.filter(item => selectedExams.includes(item.test_name))
          : data;
      setFinalData(filteredData);
    }
  }, [analysisData, selectedExams]);


  if (
    [
      "211210",
      "2119710",
      "X0210767",
      "X0210766",
      "X0210765",
      "X0210764"
    ].includes(`${userLevel}`) &&
    accountType !== "parent"
  )
    return <NoResultMsg header />;

  if (load) return <NetworkProgress />;

  const averages =
    analysisData && analysisData.output && analysisData.output.averages
      ? analysisData.output.averages
      : [];
  const avg =
    averages.length > 0
      ? averages.map(test => ({
          result: { ...test },
          testDisplayName: `Average | Attempted Exams: ${
            test.ATT.includes("|") ? test.ATT.replace(/\|/g, "/") : test.ATT
          }`
        }))
      : [];

  return (
    <>
      <div>
        <ResultHeader testDisplayName="Analysis" path="/home-page" />
    
        {analysisData &&
        !isLoading &&
        analysisData.output &&
        analysisData.output.results &&
        analysisData.output.results[0] === null ? (
          <Typography align="center" color="error">
            Analysis not available, please select a different set of filters.
          </Typography>
        ) : null}

       
        {isLoading && <NetworkProgress />}
        
        {finalData.length > 0 ? (
        <>
          <RowBox>
            <Typography>
              <b>Exam Summary</b>
            </Typography>
            <LabeledSwitch
              checked={isChecked}
              onChange={handleSwitchChange}
              label="Average"
            />
          </RowBox>
          <br />
          <Box px={1} display="flex" flexDirection="row">
            <Typography>
              <b>{selectedDateValue}</b>
            </Typography>
            , &nbsp;
            <Typography>
              <b>{selectedCourseValue}</b>
            </Typography>
          </Box>
          {testsAavailable ? (
            <Box px={2} display="flex" flexDirection="column" paddingBottom={2}>
              <ColumnChart
                selectedExams={selectedExams}
                subjects={
                  analysisData &&
                  analysisData.output &&
                  analysisData.output.subjects
                    ? analysisData.output.subjects
                    : []
                }
                data={isChecked ? [...finalData, ...avg] : finalData}
              />
            </Box>
          ) : null}

          <Box px={2} display="flex" flexDirection="column" gridGap={16}>
            {isChecked && (
              <CardInsideOut>
                <TestMarks
                  title="Average"
                  result={averages[0] ? averages[0] : {}}
                  subjects={
                    analysisData &&
                    analysisData.output &&
                    analysisData.output.subjects
                      ? analysisData.output.subjects
                      : []
                  }
                />
              </CardInsideOut>
            )}
            {finalData.map(result => (
              <CardInsideOut key={result.test_name}>
                <TestMarks
                  title={result.testDisplayName}
                  program={result.program_type}
                  result={result.result}
                  subjects={
                    analysisData &&
                    analysisData.output &&
                    analysisData.output.subjects
                      ? analysisData.output.subjects
                      : []
                  }
                />
              </CardInsideOut>
            ))}
          </Box>
        </>
        ) : (
          !isLoading && (
            <Typography align="center" color="error">
              Exams not available
            </Typography>
          )
        )} 
      </div>
      <br />
      <Box
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "16px"
        }}
      >
        <Filters
          selectedDateValue={selectedDateValue || ""}
          selectedCourseValue={selectedCourseValue || ""}
          selectedExams={selectedExams || []}
          onDateChange={handleDateChange}
          onCourseChange={handleCourseChange}
          onExamsChange={handleExamsChange}
          courses={courses || []}
          exams={
            (analysisData &&
              analysisData.output &&
              analysisData.output.results) ||
            []
          }
        />
      </Box>
    </>
  );
};

export default ExamAnalysis;
