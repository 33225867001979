import { Chip, Grid, makeStyles, Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import React, { useLayoutEffect } from "react";
import theme from "../../../assets/theme";
import QuestionTypeComponent from "./QuestionTypeComponent";
import classNames from "classnames";
const useStyle = makeStyles(() => ({
  container: {
    padding: "16px",
  },
  questionNo: {
    margin: "7px 0 7px 0",
  },
  questionGrid: {
    overflow: "hidden",
    width: "100%",
    height: "100%",
    position: "relative",

    "&:after": {
      content: "",
      position: "absolute",
      bottom: 0,
      left: 0,
      pointerEvents: "none",
      backgroundImage:
        "linear-gradient(to bottom, rgba(255,255,255,0), #fff 90%)",

      width: "100%",
      height: "4em",
    },
  },
  questionBody: { overflow: "hidden", height: "16em", fontSize: "13px" },
  wrapperBorder: {
    border: (props) => props.border,
    background: "#fff",
    boxShadow: theme.customBoxShadow.normal,
    overflow: "hidden",
    height: "336px",
    margin: "12px",
    padding: "16px",
    borderRadius: "16px",
    cursor: "pointer",
    "&:hover": {
      boxShadow: theme.customBoxShadow.hover,
    },
  },
  questionBodyWidth: {
    width: "inherit",
  },

  bottom: { marginLeft: "auto" },
}));
const QuestionComponent = ({
  qType,
  questionNo,
  questionBody,
  status,
  statusColor,
  ...props
}) => {
  const classes = useStyle(props);
  const { bookmarked } = props.quesData;
  useLayoutEffect(() => {
    const math = document.getElementsByClassName("questionBody");
    if (math) {
      window.MathJax &&
        window.MathJax.Hub.Queue([
          "Typeset",
          window.MathJax.Hub,
          math,
          () => {},
        ]);
    }
  });
  return (
    <Grid
      item
      classes={{ root: classes.wrapperBorder }}
      onClick={props.onClick}
    >
      <Grid container direction="column">
        {/* header  */}
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          className={classes.questionNo}
        >
          <Grid item>
            <Typography variant="h4">{questionNo}</Typography>
          </Grid>
          <Grid item>
            {status && (
              <Chip
                variant="outlined"
                size="small"
                icon={<FiberManualRecordIcon style={statusColor} />}
                label={status}
                style={statusColor}
              />
            )}
          </Grid>
        </Grid>
        {/* question body  */}
        <Grid
          item
          container
          direction="column"
          wrap="wrap"
          className={classes.questionGrid}
        >
          <div
            className={classNames("questionBody", classes.questionBodyWidth)}
          >
            <Typography
              noWrap={false}
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: questionBody,
              }}
              className={classes.questionBody}
            />
          </div>
        </Grid>
        <br />
        {/* footer  */}
        <Grid item container direction="row" justify="space-between">
          <Grid item>{bookmarked && <StarRoundedIcon color="primary" />}</Grid>
          <Grid item>
            <QuestionTypeComponent qType={qType} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuestionComponent;
