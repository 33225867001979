import React, { useState, useRef, useEffect } from 'react'
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Icon,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation, useHistory } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import store from 'store'
import { intiateOnlineTransaction } from '../../apis/payService';
import Lottie from 'react-lottie';
import failed from './failed.json'; // Update the path to your Lottie animation JSON file
import successAnimation from './success.json'; // Update the path to your Lottie animation JSON file
import OnlinePaymentReq from './OnlinePaymentReq';
import loading from './loading.json'; // Update the path to your Lottie animation JSON file

// styles
import feeDetailsStyles from './styles'

const capitalizeFirstLetter = string => {
  return string
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const paymentStuff = {
  '5ee85fc60f25a93146ea0bf9': {
    merchantId: 'M00005479',
    encryptionKey: 'WW4lk7ne1vE2ma1iW9yb5nY7lh8BJ4uu'
  },
  '646634c8a400ecffc1306d0d': {
    merchantId: 'M00006266',
    encryptionKey: 'oe3cm1Ut5eq4ly8aU2XX2jJ4sy0vE0RA'
  },
  '5ea3572fe301133d0a73e9e0': {
    merchantId: 'M00005675',
    encryptionKey: 'MT9yx5fv8RH8uW9NY5yc3Jg5Al6qv9Rt'
  }
}
const useStyles = makeStyles(theme => ({
  // container: {
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "flex-start",
  //   padding: 0,
  //   gap: "20px",

  //   borderRadius: "8px",

  //   /* Inside auto layout */
  //   flex: "none",
  //   order: 0,
  //   alignSelf: "stretch",
  //   flexGrow: 0
  // },
  table: {
    // padding: "0px !important",
    marginLeft: '-12px !important',
    minWidth: '100%',
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      width: '100%' /* Width for mobile */,
      minWidth: '320px'
    }
  },
  tableContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      overflowX: 'auto',
      whiteSpace: 'nowrap'
    }
  },
  td: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  textField: {
    // backgroundColor: 'white',
    /* Frame 1313 */
    // boxSizing: 'border-box', // Corrected to camelCase

    /* Auto layout */
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    // padding: '1px 30px',
    // gap: '10px',
    fontSize: '14px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100px'
    },
    background: 'rgba(244, 247, 249, 1)',

    border: '1px solid rgba(244, 247, 249, 1)',
    borderRadius: '8px',
    '&.MuiInputBase-input': {
      color: 'blue', // Example: Change text color to blue
      height: '1px',
      fontSize: '14px' // Example: Change font size
      // Add more styles as needed
    }
    /* Inside auto layout */
    // flex: 'none',
    // order: 1,
    // alignSelf: 'stretch',
    // flexGrow: 0
  }
}));

const PayingAmountCard = ({ data }) => {
  const location = useLocation()
  const classes = feeDetailsStyles()
  const classes1 = useStyles()
  const history = useHistory()
  const isMobile = useMediaQuery('(max-width:620px)')
  const queryParams = new URLSearchParams(location.search)
  const txnId = queryParams.get('txnId')
  const status = queryParams.get('status')
  const statusMessages = {
    OK: { message: 'Payment Transaction Completed', color: 'green' ,icon: successAnimation},
    ok: { message: 'Payment Transaction Completed', color: 'green' ,icon: successAnimation},
    Ok: { message: 'Payment Transaction Completed', color: 'green', icon: successAnimation },
    F: { message: 'Transaction failed, please try again', color: 'red', icon: failed },
    Pending: {
      message: 'Transaction pending...please wait for some time',
      color: 'orange',
      icon: loading
    },
    To: { message: 'Time Out Please try again', color: 'red', icon: failed },
  };
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: statusMessages[status] ? statusMessages[status].icon : successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [color, setColor] = useState('')
  useEffect(() => {
    if (txnId && status) {
      const statusInfo = statusMessages[status] || {
        message: 'Unknown status',
        color: 'black'
      }
      setMessage(statusInfo.message)
      setColor(statusInfo.color)
      setOpen(true)
    }
  }, [txnId, status])

  const handleClose = () => {
    setOpen(false)
    history.replace('/fee-details');
  if(message === 'Payment Transaction Completed'){
    window.location.reload();
  }
  }

  const initialSelectedFees = {}
  let initialSelectAll = false

  let disableSelectAll = false

  if (data && data.currentYear && data.currentYear.rows) {
    const feeTypes = data.currentYear.rows.map(fee => fee.feeType)
    if (
      feeTypes.includes('registration_fee') &&
      feeTypes.includes('application_fee') &&
      feeTypes.length === 2
    ) {
      disableSelectAll = true
    }
    if (
      feeTypes.includes('registration_fee') &&
      feeTypes.includes('application_fee')
    ) {
      data.currentYear.rows.forEach(fee => {
        initialSelectedFees[fee.feeType] = fee.balance
      })
      initialSelectAll = true
    }
  }

  const [selectedFees, setSelectedFees] = useState(initialSelectedFees)
  const [selectAll, setSelectAll] = useState(initialSelectAll)
  const [isLoading, error, reqInfo, postData, resetFetch] = useFetch(
    intiateOnlineTransaction
  )

  const user = store.get('user')

  // Calculate total paying amount
  const totalPayingAmount = Object.values(selectedFees).reduce(
    (total, balance) => {
      return total + (balance || 0)
    },
    0
  )

  // Handle individual checkbox change
  const handleCheckboxChange = (feeType, balance) => {
    setSelectedFees(prevSelectedFees => {
      const newSelectedFees = {
        ...prevSelectedFees,
        [feeType]: prevSelectedFees[feeType] ? undefined : balance
      }

      // Check if all individual checkboxes are selected
      const allSelected = data.currentYear.rows.every(
        fee => newSelectedFees[fee.feeType]
      )
      setSelectAll(allSelected)

      return newSelectedFees
    })
  }

  const handleAmountChange = (feeType, event) => {
    const value = event.target.value
    setSelectedFees(prevSelectedFees => ({
      ...prevSelectedFees,
      [feeType]: value ? parseFloat(value) : undefined
    }))
  }

  const handleOnlinePayment = () => {
    const payload = {
      customerId: data && data.admissionNo,
      applicationNo:
        data && data.currentYear && data.currentYear.application_no,
      merchantId: paymentStuff[user.institute].merchantId,
      fees: selectedFees,
      customerMobileNo: user.phoneNumber,
      instituteId: user.institute,
      branchId: user.branchId,
      academicYear: data && data.ins_current_ay,
      batch: user.level,
      amount: `${totalPayingAmount}.00`,
      summaryAmt: totalPayingAmount,
      key: paymentStuff[user.institute].encryptionKey,
      gstFees: {},
      gstAmt: 0
    }

    postData(payload)
  }

  const onlineRef = useRef()

  // Handle root checkbox (select all)
  const handleSelectAll = () => {
    setSelectAll(!selectAll)
    if (!selectAll) {
      const allSelected = {}
      data &&
        data.currentYear &&
        data.currentYear.rows &&
        data.currentYear.rows.forEach(fee => {
          allSelected[fee.feeType] = fee.balance
        })
      setSelectedFees(allSelected)
    } else {
      setSelectedFees({})
    }
  }

  useEffect(() => {
    if (onlineRef.current) {
      onlineRef.current.submit()
    }
  }, [reqInfo, onlineRef])

  if (reqInfo && reqInfo.data.payload && reqInfo.data.payload.postUrl) {
    const { postUrl, merchantId, reqData } = reqInfo.data.payload

    return (
      <div>
        Redirecting to payment page, DO NOT click! or refresh the Page.
        <OnlinePaymentReq
          ref={onlineRef}
          postUrl={postUrl}
          merchantId={merchantId}
          reqData={reqData}
        />
      </div>
    )
  }

  return (
    <Box
      // display='flex'
      justifyContent='flex-start'
      alignItems='flex-start'
      className={classes1.tableContainer}
    >
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Status</DialogTitle>
        <DialogContent>
          {/* <Box display='flex' justifyContent='center' alignItems='center'>
            <CheckCircleIcon style={{ color }} />
          </Box> */}
           <Box display='flex' justifyContent='center' alignItems='center'>
            <Lottie options={defaultOptions} height={100} width={100} />
          </Box>
          <Typography  className={classes.subHeading} style={{ color,textAlign:"center" }}>
            {message}  
          </Typography>
          &nbsp;
          <Typography className={classes.subHeading} style={{ textAlign:"center"  }}>
            Transaction Id :{txnId}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
     
      <div>
        <Typography variant='h5' className={classes.title} gutterBottom>
          Paying Amount
        </Typography>
        <table className={classes1.table}>
          <thead>
            <tr>
              <th>
                <Checkbox
                  checked={selectAll}
                  size='small'
                  onChange={handleSelectAll}
                  disabled={disableSelectAll}
                />
              </th>
              <th className={classes.textHeading}>
                <b>Name</b>
              </th>
              <th className={classes.textHeading}>
                <b>Balance</b>
              </th>
              <th
                className={classes.textHeading}
                style={{ textAlign: isMobile ? 'center' : 'left' }}
              >
                <b>Amount</b>
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.currentYear &&
              data.currentYear.rows &&
              data.currentYear.rows.map((fee, index) => (
                <tr key={index}>
                  <td>
                    <Checkbox
                      checked={!!selectedFees[fee.feeType]}
                      size='small'
                      onChange={() =>
                        handleCheckboxChange(fee.feeType, fee.balance)
                      }
                      disabled={
                        fee.feeType === 'registration_fee' ||
                        fee.feeType === 'application_fee'
                      }
                    />
                  </td>
                  <td className={classes.feeTypeName}>
                    {capitalizeFirstLetter(fee.feeType.replace('_', ' '))}
                  </td>
                  <td className={classes.textHeading}>
                    <b style={{ color: 'rgba(42, 42, 42, 1)' }}>
                      {' '}
                      ₹{fee.balance}
                    </b>
                  </td>
                  <td>
                    {fee.partial ? (
                      <TextField
                        type='number'
                        variant='outlined'
                        size='small'
                        value={selectedFees[fee.feeType] || ''}
                        onChange={event =>
                          handleAmountChange(fee.feeType, event)
                        }
                        // className={classes1.textField}
                        InputProps={{
                          shrink: false,
                          className: classes1.textField,
                          min: 0,
                          max: fee.balance
                        }}
                      />
                    ) : (
                      selectedFees[fee.feeType] && (
                        <Typography
                          className={classes.textHeading}
                          color='primary'
                        >
                          ₹{fee.balance}
                        </Typography>
                      )
                    )}
                  </td>
                </tr>
              ))}
            <tr>
              <td colSpan={5} align='right'>
                <Grid
                  container
                  spacing={2}
                  justifyContent='space-between'
                  style={{ padding: '10px' }}
                >
                  <Grid item>
                    <Grid item xs={12}>
                      <Typography className={classes.TotalAmountText}>
                        Total Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        style={{ textAlign: 'left' }}
                        color='primary'
                        className={classes.title}
                      >
                        ₹{totalPayingAmount}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    style={{
                      padddingTop: '5px',
                      marginTop: '5px',
                    }}
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleOnlinePayment}
                    >
                      Pay Now
                    </Button>
                  </Grid>
                </Grid>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Box>
  )
}

export default PayingAmountCard
