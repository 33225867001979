import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/styles";
import React from "react";

// custom row
const CustomTableRow = withStyles((theme) => ({
  root: {
    height: "auto",
  },
}))((props) => <TableRow {...props} />);

const CustomRowType2 = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#F7F7F8",
    },
    height: "fit-content",
  },
}))((props) => <TableRow {...props} />);
// custom cell
const CustomTableCell = withStyles((theme) => ({
  root: {
    // border: "0.5px solid #F7F7F8",
    display: "tableRowGroup",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "4px 6px 4px 6px",
  },
}))((props) => <TableCell align={props.align || "center"} {...props} />);
// custom cell
const CustomTableCellType2 = withStyles((theme) => ({
  root: {
    // border: "0.5px solid #F7F7F8",
    display: "tableRowGroup",
    // fontSize: "12px",
    lineHeight: "1rem",
    padding: "5px",
    fontWeight:500,
  },
}))((props) => <TableCell align={props.align || "center"} {...props} />);
 const CustomTableCellType3 = withStyles((theme) => ({
  root: {
    // border: "0.5px solid #F7F7F8",
    display: "tableRowGroup",
    fontSize: "12px",
    lineHeight: "1.8rem",
    padding: "7px",
    fontWeight:500,
  },
}))((props) => <TableCell align={props.align || "center"} {...props} />);
export {
  CustomTableRow,
  CustomTableCell,
  CustomRowType2,
  CustomTableCellType2,
  CustomTableCellType3
};
