import React from "react";
import {
  makeStyles,
  Grid,
  IconButton,
 } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  title: {
    margin: "0 auto",
    width: "100%",
    height: "100%",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#2A2A2A",
    flex: "none",
    order: 0,
    flexGrow: 0,
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px', // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px', // Desktop
    },
  },
  iconOverLay: {
    boxSizing: "border-box",
    position: "absolute",
    width: "48px",
    height: "auto",
    justifyContent: "center",
    alignItems: "center",
    // left: '0px',
    // top: '0px',
    background: "#EBEFF3",
    border: "1px solid #EBEFF3",
    boxShadow:
      "4px 4px 8px rgba(255, 255, 255, 0.75), inset 2px 2px 8px rgba(189, 194, 199, 0.75)",
    borderRadius: "16px"
  },
  icon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    margin: "0 auto",
    width: "48px",
    height: "48px",
    flex: "none",
    order: 1,
    flexGrow: 0
    /* Rectangle 35 */
  },
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#EBEFF3",
    width: "100%",
    height: "70px",
    minHeight: "30px",
    boxShadow: `4px 4px 8px rgba(189, 194, 199, 0.75),
                  8px 8px 12px rgba(189, 194, 199, 0.25),
                 -4px -4px 8px rgba(255, 255, 255, 0.75),
                 -8px -8px 12px rgba(255, 255, 255, 0.25)`,
    borderRadius: "16px",
    maxWidth: "100%",
    overflow: "auto",
    padding: "12px",
    background: "#EBEFF3",
    "@media (min-width: 430px)": {
      height: "100px"
    
    }
  }
}));

const CatCard = ({ title, icon, handleClick }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <div className={classes.title}>{title}</div>
          </Grid>
          <Grid
            item
            xs={5}
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <div className={classes.iconOverLay}>
              <IconButton className={classes.icon}>
                <img src={icon} alt="analysis" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CatCard;
