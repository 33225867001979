import * as actionType from "./actions";

export const appSettingsLoading = (body) => ({
  type: actionType.APP_SETTINGS_LOADING,
  body,
});

export const appSettingsSuccess = (result) => ({
  type: actionType.APP_SETTINGS_SUCCESS,
  payload: result,
});

/* ------------------api error handling action creators------------------- */

export const catchErrorappSettings = (error) => ({
  type: actionType.CATCH_ERROR_APP_SETTINGS,
  payload: error,
});

/* ------------clear error from store if any on component unmount------------ */

export const clearErrorappSettings = () => ({
  type: actionType.CLEAR_ERROR_APP_SETTINGS,
});




