// fetching result data and time spent analysis for all subjects
export const FETCH_RESULT = "FETCH_RESULT";
export const FETCH_RESULT_SUCCESS = "FETCH_RESULT_SUCCESS";
export const FETCH_RESULT_ERROR = "FETCH_RESULT_ERROR";

export const FETCH_TIME_SPENT_SUCCESS = "FETCH_TIME_SPENT_SUCCESS";
export const FETCH_TIME_SPENT_ERROR = "FETCH_TIME_SPENT_ERROR";

//fetching compare result question details
export const FETCH_COMPARISON_QUESTIONS = "FETCH_COMPARISON_QUESTIONS";
export const FETCH_COMPARISON_QUESTIONS_SUCCESS =
  "FETCH_COMPARISON_QUESTIONS_SUCCESS";
export const FETCH_COMPARISON_QUESTIONS_ERROR =
  "FETCH_COMPARISON_QUESTIONS_ERROR";

// fetching rank details
export const FETCH_RANK = "FETCH_RANK";
export const FETCH_RANK_SUCCESS = "FETCH_RANK_SUCCESS";
export const FETCH_RANK_ERROR = "FETCH_RANK_ERROR";
//fetch all subjects
export const ALL_SUBJECTS = "ALL_SUBJECTS";
// clear component
export const CLEAR_RESULT = "CLEAR_RESULT";
export const CLEAR_RANK = "CLEAR_RANK";
export const CLEAR_COMPARE_QUESTIONS = "CLEAR_COMPARE_QUESTIONS";
// student behaviour
export const FETCH_STUDENT_BEHAVIOUR = "FETCH_STUDENT_BEHAVIOUR"
export const FETCH_STUDENT_BEHAVIOUR_SUCCESS = "FETCH_STUDENT_BEHAVIOUR_SUCCESS";
export const FETCH_STUDENT_BEHAVIOUR_ERR = "FETCH_STUDENT_BEHAVIOUR_ERR";

