import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { BarProgress } from "./CustomProgressBar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      marginRight: "3%",
    },
  },
  containerGrid: {
    height: "30px",
  },
}));

export default function TimeSpentList({ Qno, timeSpent, accuracy, maxTime }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container alignItems="center" className={classes.containerGrid}>
        <Grid item xs={2}>
          <Typography variant="subtitle2" color="textSecondary" align="left">
            {Qno ? Qno : Qno}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" color="textPrimary" align="left">
            {timeSpent ? timeSpent : "0s"}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <BarProgress
            variant="determinate"
            value={accuracy ? Math.floor((accuracy / (maxTime + 10)) * 100) : 0}
          />
        </Grid>
      </Grid>
    </div>
  );
}
