import React from "react";
import store from "store";
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  Container,
  Typography,
  makeStyles,
  Divider
} from "@material-ui/core";
import ResultHeader from "../resultPage/ResultHeader";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 0,
    gap: "20px",

    borderRadius: "8px",

    /* Inside auto layout */
    flex: "none",
    order: 0,
    alignSelf: "stretch",
    flexGrow: 0
  },
  textField: {
    // backgroundColor: 'white',
    /* Frame 1313 */
    // boxSizing: 'border-box', // Corrected to camelCase

    /* Auto layout */
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    // padding: '12px 16px',
    // gap: '10px',

    background: "#FFFFFF",
    border: "0.5px solid #A3A3A3",
    borderRadius: "8px"

    /* Inside auto layout */
    // flex: 'none',
    // order: 1,
    // alignSelf: 'stretch',
    // flexGrow: 0
  },
  title: {
    color: "#2A2A2A",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
    padding: "8px 0"
  },
  cancelButton: {
    background: "#FFFFFF",
    border: "1px solid #3358B8",
    borderRadius: "8px",
    color: "#3358B8",
    padding: "8px",
    width: "150px",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center"
  },
  submitButton: {
    background: "#3358B8",
    border: "1px solid #3358B8",
    borderRadius: "8px",
    color: "#FFFFFF",
    padding: "8px",
    width: "150px",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center"
  },
  placeholder: {
    color: "red",
    fontWeight: 300
  }
}));

const LeaveRequestForm = () => {
  const classes = useStyles();

  const [leaveType, setLeaveType] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");
  const user = store.get("user");

  const leaveTypes = [
    { value: "sick", label: "Sick Leave" },
    { value: "casual", label: "Casual Leave" },
    { value: "earned", label: "Earned Leave" }
  ];

  const handleLeaveTypeChange = event => {
    setLeaveType(event.target.value);
  };

  const handleReasonChange = event => {
    setReason(event.target.value);
  };

  const handleFromDateChange = event => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = event => {
    setToDate(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const leaveRequest = {
      userId: user.userId,
      userName: user.name,
      leaveType,
      fromDate,
      toDate,
      reason,
      classTeacher: "Lakshmi Priya"
    };
    console.log("Leave Request:", leaveRequest);
    // Here, you can add code to save the leaveRequest object to a server or database.
    // For example, using fetch or axios to make a POST request.
    // Reset fields after submission
    setLeaveType("");
    setReason("");
    setFromDate("");
    setToDate("");
  };

  const handleCancel = () => {
    setLeaveType("");
    setReason("");
    setFromDate("");
    setToDate("");
  };

  return (
    <>
      <ResultHeader testDisplayName="Leave Request Form" path="/attendance" />
      <Container maxWidth="sm">
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={classes.title}>Roll No</div>
              <TextField
                id="roll-no"
                size="small"
                placeholder="Roll No"
                variant="outlined"
                fullWidth
                disabled
                value={user.userId}
                InputProps={{
                  readOnly: true,
                  shrink: false,
                  className: classes.textField
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.title}>Student Name</div>
              <TextField
                id="student-name"
                placeholder="Student Name"
                variant="outlined"
                size="small"
                disabled
                fullWidth
                value={user.name}
                InputProps={{
                  readOnly: true,
                  shrink: false,
                  className: classes.textField
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.title}>Leave Type</div>
              <TextField
                id="leave-type"
                select
                size="small"
                // label='Select Leave Type'
                placeholder="Select Leave Type"
                value={leaveType}
                onChange={handleLeaveTypeChange}
                variant="outlined"
                fullWidth
                // SelectProps={{
                //   displayEmpty: true
                // }}
                InputLabelProps={{
                  shrink: false // shrink only if leaveType is not empty
                }}
                className={classes.textField}
              >
                {leaveTypes.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.title}>From</div>
              <TextField
                id="from-date"
                type="date"
                size="small"
                variant="outlined"
                fullWidth
                value={fromDate}
                onChange={handleFromDateChange}
                InputLabelProps={{
                  shrink: false,
                  className: classes.textField
                }}
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <div className={classes.title}>To</div>
              <TextField
                id="to-date"
                type="date"
                variant="outlined"
                size="small"
                fullWidth
                value={toDate}
                onChange={handleToDateChange}
                InputLabelProps={{
                  shrink: false,
                  className: classes.textField
                }}
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.title}>Reason</div>
              <TextField
                id="reason"
                placeholder="Reason"
                // select
                size="small"
                // label='Reason'
                value={reason}
                rows={3}
                multiline
                onChange={handleReasonChange}
                variant="outlined"
                fullWidth
                InputProps={{
                  shrink: false,
                  className: classes.textField
                }}
              >
                {leaveTypes.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.title}>Class Teacher</div>
              <TextField
                id="class-teacher"
                placeholder="Class Teacher"
                variant="outlined"
                size="small"
                fullWidth
                value="Lakshmi Priya"
                InputProps={{
                  readOnly: true,
                  className: classes.textField
                }}
              />
            </Grid>
          </Grid>
          &nbsp;
          <Divider />
          &nbsp;
          <Grid
            container
            spacing={2}
            alignContent="flex-end"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                className={classes.cancelButton}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.submitButton}
                type="submit"
              >
                Request Leave
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default LeaveRequestForm;
