import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Card, Divider } from "@material-ui/core";
// import theme from "../assets/v3/theme";
import colors from "../assets/v3/base/colors";

const useStyles = makeStyles((theme) => {
  const { error, badgeColors } = colors;
  return {
    container: {
      textAlign: "center",
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
    },
    countdownBox: {
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    timeCard: {
      // width: "3rem",
      // height: "5rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: "#FEE7E7",
      color: badgeColors.error.text,
      transition: "transform 0.3s",
      // gap: theme.spacing(0.5),
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    heading: {
      textAlign: "left",
    },
    timeValue: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      lineHeight: 1.35
    },
    divider: {
      width: "60%",
      backgroundColor: "#A3A3A3",
      margin: theme.spacing(0.5),
    },
    timeLabel: {
      fontSize: "1rem",
      fontWeight: "500",
      color: theme.palette.text.secondary,
      lineHeight: 1.5,
    },
    "@keyframes blinkColor": {
      "0%, 100%": { color: theme.palette.secondary.main },
      "50%": { color: theme.palette.primary.main },
    },
    "@keyframes highlightCard": {
      "0%, 100%": { transform: "scale(1)" },
      "50%": { transform: "scale(1.1)" },
    },
  };
});

const BoxCountdown = ({ countDown }) => {
  const classes = useStyles();
  const [timeLeft, setTimeLeft] = useState(countDown);
  const [changedUnit, setChangedUnit] = useState(null); // Track which unit is changing

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        let { days, hours, minutes, seconds } = prev;
        let unitChanged = null;

        if (seconds > 0) {
          seconds--;
          unitChanged = "seconds";
        } else {
          seconds = 59;
          if (minutes > 0) {
            minutes--;
            unitChanged = "minutes";
          } else {
            minutes = 59;
            if (hours > 0) {
              hours--;
              unitChanged = "hours";
            } else {
              hours = 23;
              if (days > 0) {
                days--;
                unitChanged = "days";
              }
            }
          }
        }

        setChangedUnit(unitChanged); // Set the changed unit

        // Revert the `changedUnit` after 600ms to stop highlighting
        setTimeout(() => setChangedUnit(null), 600);

        return { days, hours, minutes, seconds };
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const TimeCard = ({ value, label, isHighlighted }) => {
    const classes = useStyles();
    return (
    <Box
      className={`${classes.timeCard}`}
      elevation={0}
    >
      <Typography
        className={`${classes.timeValue} ${
          isHighlighted ? classes.highlightedValue : ""
        }`}
        component="p"
      >
        {String(value).padStart(2, "0")}
      </Typography>
      <Divider className={classes.divider} />
      <Typography className={classes.timeLabel} component="p">{label}</Typography>
    </Box>
  )};

  return (
    <Box className={classes.container}>
      <Typography variant="body1" className={classes.heading}>
        Registration ends in
      </Typography>
      <Box className={classes.countdownBox}>
        <TimeCard
          value={timeLeft.days}
          label="Day"
          isHighlighted={changedUnit === "days"}
        />
        <TimeCard
          value={timeLeft.hours}
          label="Hrs"
          isHighlighted={changedUnit === "hours"}
        />
        <TimeCard
          value={timeLeft.minutes}
          label="Min"
          isHighlighted={changedUnit === "minutes"}
        />
        <TimeCard
          value={timeLeft.seconds}
          label="Sec"
          isHighlighted={changedUnit === "seconds"}
        />
      </Box>
    </Box>
  );
};

export default BoxCountdown;
