import { Checkbox, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  contentMargin: {
    marginLeft: theme.spacing(3),
  },
  matrix: { lineHeight: 1 },
  radio: {
    padding: "4px",
    color: "black",
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    "&$checked": {
      background: "linear-gradient(45deg, #5db85c 30%, #5db85c 90%)",
      color: "#fff",
    },
  },
  checked: {
    backgroundColor: "linear-gradient(60deg,#5db85c, #5db85c)",
    color: "#5db85c", //'#535d8c'
  },
}));
const matchCodes = {
  A: ["P", "Q", "R", "S", "T"],
  B: ["P", "Q", "R", "S", "T"],
  C: ["P", "Q", "R", "S", "T"],
  D: ["P", "Q", "R", "S", "T"],
};
const sortAlphabets = (str) => {
  return [...str].sort((a, b) => a.localeCompare(b)).join("");
};
const MatrixInput = ({ markedAnswer, setMatrixValues }) => {
  const classes = useStyles();
  const initialState =
    markedAnswer.A || markedAnswer.B || markedAnswer.C || markedAnswer.D
      ? markedAnswer
      : {
          A: "",
          B: "",
          C: "",
          D: "",
        };
  const [matrixVal, setMatrixVal] = React.useState(initialState);

  const handleClickMatchCodes = (e, checked) => {
    const { name, value } = e.target;
    let matrixMatchValues = matrixVal[name];
    if (checked) {
      matrixMatchValues += value;
    } else {
      matrixMatchValues = matrixMatchValues.replace(value, "");
    }
    setMatrixVal({ ...matrixVal, [name]: sortAlphabets(matrixMatchValues) });
    setMatrixValues({
      ...matrixVal,
      [name]: sortAlphabets(matrixMatchValues),
    });
  };

  useEffect(() => {
    setMatrixVal(initialState);
  }, [markedAnswer]);

  return (
    <Grid container direction="column" className={classes.contentMargin}>
      <br />
      {Object.keys(matchCodes).map((option) => (
        <Grid
          item
          key={option}
          container
          direction="row"
          alignItems="baseline"
          spacing={2}
        >
          <Grid item>
            <Typography>{option}.</Typography>&nbsp;
          </Grid>
          {matchCodes[option].map((matchCode, i) => {
            return (
              <Grid item key={matchCode}>
                <Checkbox
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                  checked={matrixVal[option].includes(matchCode)}
                  onChange={handleClickMatchCodes}
                  value={matchCode}
                  name={option}
                  icon={
                    <Typography className={classes.matrix} size="small">
                      {matchCode}
                    </Typography>
                  }
                  checkedIcon={
                    <Typography className={classes.matrix} size="small">
                      {matchCode}
                    </Typography>
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      ))}
      <br />
    </Grid>
  );
};

export default MatrixInput;
