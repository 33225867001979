import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import cardLeaf from "./logos/cardLeaf.svg";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import LeftArrow from "./logos/leftRrrow.png";
import RightArrow from "./logos/rightArrow.png";
import StarIcon from "@material-ui/icons/Star";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
//import Button from "components/CustomButtons/Button.jsx";
const styles = (theme) => ({
  cardStyle: {
    background: "#EFF2F4",
    backgroundImage: `url("${cardLeaf}")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    borderRadius: "24px",
    padding: "20px",
    paddingTop: "40px",
    //  margin: '0px 20px',
    animation: "$slideIn 2s ease-in-out",
    minWidth: "320px",
    minHeight: "327px",
    "&:hover": {
      transform: "scale(1.2)",
    },

    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
      minHeight: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      minHeight: "250px",
    },
  },

  "@keyframes slideIn": {
    "0%": {
      opacity: 0,
      transform: "translateX(100%)", // Start from right
    },

    "100%": {
      transform: "translateY(0%)", // Move to left
    },
  },

  titleStyle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#31456A",
    marginTop: "10px",
  },
  descStyle: {
    marginTop: "5px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "29px",

    color: "rgba(49, 69, 106, 0.7)",
  },
  iconShadow: {
    marginTop: "-20%",
    textAlign: "center",
    height: "60px",
    width: "60px",
    background: "#EFF2F4",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    borderRadius: "197px",
  },
});

function FeatureCard(props) {
  const { classes } = props;
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
      className={classes.cardStyle}
    >
      <Grid item container xs={12}>
        <Grid item xs={2}>
          {/* <div  className={classes.cardStyle}> */}
          <div>
            <img
              alt="icon"
              src={props.icon}
              style={{ height: "50px", width: "50px" }}
            />
          </div>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="h6"
            textAlign="center"
            className={classes.titleStyle}
          >
            {props.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.descStyle}>{props.description}</div>
      </Grid>
      <Grid item xs={12}>
        <StarIcon style={{ color: "#E4A71D" }} />
        <StarIcon style={{ color: "#E4A71D" }} />
        <StarIcon style={{ color: "#E4A71D" }} />
        <StarIcon style={{ color: "#E4A71D" }} />
        <StarIcon style={{ color: "#E4A71D" }} />
      </Grid>

      <Grid item xs={12} container justify="center" alignItems="flex-start">
        <MoreHorizIcon
          style={{ height: "30px", width: "30px", color: "#D8DBDD" }}
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(FeatureCard);
