const { createMuiTheme } = require("@material-ui/core");

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
      sizeLarge: {
        minWidth: "320px",
        fontSize: "18px",
      },
      sizeSmall: {
        width: "110px",
        height: "34px",
      },
      contained: {
        color: "#FFFFFF !important",
        width: "200px",
        height: "56px",
        fontSize: "18px",
        background: "#3358B8",
        boxShadow: "inset 1px 1px 1px ##5A75DA, inset -1px -1px 1px #7E94FD",
        fontStyle: "normal",
        textAlign: "center",
        fontWeight: "600",
        lineHeight: "100%",
        borderRadius: "16px",
        textTransform: "capitalize",
        "&$disabled": {
          background: "#fafafa",
          boxShadow: "none",
        },
        "&:hover": {
          background: "#5A75DA !important",
        },
      },
      text: {
        color: "#3358B8",
        margin: "0",
        padding: "1px 10px 1px 10px",
      },
      outlined: {
        background: "#ffffff",
        width: "200px",
        height: "56px",
        fontSize: "18px",
        boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
        border: "none",
        fontStyle: "normal",
        textAlign: "center",
        fontWeight: "600",
        lineHeight: "100%",
        borderRadius: "16px",
        textTransform: "capitalize",
        "&$disabled": {
          background: "#fafafa",
          boxShadow: "none",
        },
        "&:hover": {
          background: "#fafafa !important",
        },
      },
    },
    MuiCard: {
      root: {
        width: "100%",
        background: "#fafafa",
        boxShadow: "0px 5px 10px rgba(142, 154, 175, 0.1)",
        borderRadius: "10px",
      },
    },
    MuiCardActionArea: {
      root: {
        "&:hover": {
          backgroundColor: "#ffffff",
        },
      },
    },
  },
  typography: {
    subtitle1: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "20px",
      textTransform: "none",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    subtitle2: {
      fontWeight: "normal",
      textTransform: "none",
      fontSize: "16px",
      lineHeight: "20px",
    },
    h3: {
      fontWeight: "bold",
      fontSize: "30px",
      lineHeight: "37px",
    },
    h2: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "54px",
      lineHeight: "100%",
    },
    body1: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
      textTransform: "none",
      letterSpacing: "0.15px",
    },
    body2: {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "20px",
      textTransform: "none",
      letterSpacing: "0.15px",
    },
    h1: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "90px",
      lineHeight: "110px",
    },
    h4: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "29px",
    },
  },
  palette: {
    text: {
      primary: "#31456A",
      secondary: "#2A2A2A",
    },
    primary: {
      main: "#3358B8",
    },
    secondary: {
      main: "#6a1b9a",
    },
    success: {
      main: "#5A75DA",
    },
    info: {
      main: "#08C5A7", //light blue
    },
    background: {
      paper: "#eff2f4",
      default: "#E5E5E5",
    },
    action: {
      selected: "#3BBDED", //light blue
    },
  },
  shape: {
    borderRadius: "10px",
  },
  customBoxShadow: {
    normal:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);",
    hover:
      " 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);",
  },
  link: {
    main: "#5A75DA",
    hover: "#3358B8",
  },
  btnColor: {
    main: "#08C5A7", //primary green
  },
});
export default theme;
