import React, { useEffect, useState, useMemo } from "react";
import store from "store";
import { Box, Typography, Grid, Container } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// theme
import theme from "../../assets/v3/theme";
import colors from "../../assets/v3/base/colors";
import {
  getRegistrationFormConfig,
  getHallTicket,
  generateUserTalentTestHallTicket,
} from "../../apis/UserService";
import useFetch from "../../hooks/useFetch";
import { useSnackbar } from "../../context/snackbar";
import {
  StudentDetailsFields,
  ParentDetailsFields,
  PreviousSchoolDetails,
  AddressFieldDetails,
} from "./utils";
import moment from "moment";
// components
import StyledBox from "../../components/StyledBox";
import { useStyles } from "./ReviewPage";
import TextScroll from "./TextScroll";
import { hostnamesMap } from "../../components/LogoURLMap";

const RegDashBoard = () => {
  const classes = useStyles();
  const { openSnackbar } = useSnackbar();
  const instituteId = hostnamesMap[window.location.hostname];
  const sid = store.get("user")._id;
  const [l1, e1, d1, f1, r1] = useFetch(getRegistrationFormConfig);
  const [l2, e2, d2, f2, r2] = useFetch(getHallTicket);
  const [l3, e3, d3, f3, r3] = useFetch(generateUserTalentTestHallTicket);
  const [details, setDetails] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");
  const payload = d1 ? d1.payload : null;
  const talentTestFee = payload ? payload.talent_test_fee : 0;
  const paidStatus = details ? details.talentTestFeePaid : false;
  const generated = d1 ? d1.hallTicketGenerated : false;
  const test_name = payload ? payload.test_name : "";
  const fee_title = payload ? payload.fee_title : "";
  const display_text = payload ? payload.display_text : "";
  const scrollText =
    payload && payload.scroll_text ? payload.scroll_text : null;

  const fieldMap = useMemo(() => {
    return [
      ...StudentDetailsFields,
      ...ParentDetailsFields,
      ...PreviousSchoolDetails,
      ...AddressFieldDetails,
    ].reduce((map, field) => {
      map[field.field] = field;
      return map;
    }, {});
  }, []);

  const initialValues = useMemo(() => {
    return Object.keys(fieldMap).reduce((values, key) => {
      const myKey = fieldMap[key].key;
      if (myKey && details) {
        values[key] = myKey.includes(".")
          ? details[myKey.split(".")[0]]
            ? details[myKey.split(".")[0]][myKey.split(".")[1]]
            : ""
          : details[myKey];

        if (myKey === "dateOfBirth" && values[key]) {
          values[key] = moment(values[key]).format("YYYY-MM-DD");
        }
      } else {
        values[key] = "";
      }
      return values;
    }, {});
  }, [fieldMap, details]);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("instituteId", instituteId);
    console.log("sid", sid);
    if (instituteId && sid) {
      f1({ instituteId, page: "reg-dashboard", sid });
    }

    return () => {
      r1();
    };
  }, []);

  useEffect(() => {
    if (d1) {
      setDetails(d1.details);
      if (generated) {
        f2({ sid, mode: "view" });
      }
    }
    if (e1) {
      console.error("Failed to fetch registration details:", e1);
      openSnackbar("Failed to fetch registration details", "error");
    }
  }, [d1, e1]);

  useEffect(() => {
    if (d2) {
      if (d2.html) {
        setHtmlContent(d2.html);
      } else {
        openSnackbar("downloaded successfully", "success");
      }
    }
    if (e2) {
      openSnackbar("Failed to download", "error");
    }

    return () => {
      r2();
    };
  }, [d2, e2]);

  useEffect(() => {
    if (d3) {
      if (d3.data.status === "SUCCESS") {
        f1({ instituteId, page: "reg-dashboard", sid });
      } else if (d3.data.status === "FAILURE") {
        openSnackbar(d3.data.message, "error", false);
      }
    }
    if (e3) {
      openSnackbar("Failed to generate hall ticket", "error");
    }

    return () => {
      r3();
    };
  }, [d3, e3]);

  const handleDownloadHallTicket = () => {
    f2({ sid });
  };

  const handleAvatarClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSkip = () => {
    if (sid && test_name) {
      f3({ sid, test_name });
    }
  };

  const renderDetails = (title, fields) => (
    <StyledBox
      // shadow={"lg"}
      borderRadius="lg"
      marginTop={0.5}
      padding={2}
      bgColor="white"
    >
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {fields.map((field) => {
          const value = initialValues[field.field] || "";
          if (!value) return null;
          return (
            <Grid item xs={12} key={field.label}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.key}
                >
                  {field.label}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.value}
                  color="primary"
                >
                  :&nbsp;{value}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </StyledBox>
  );

  if (l1 || l2 || l3) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      {scrollText && (
        <TextScroll
          text={scrollText}
          textStyles={{
            fontWeight: "bolder",
            fontSize: "1.5rem",
            background: `-webkit-linear-gradient(#ED1B24, #074EA0)`,
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
          }}
        />
      )}
      <Box className={classes.mainContainer} minHeight="100vh">
        <Container maxWidth="md">
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            padding={2}
          >
            <Avatar
              alt={details && details.fullName ? details.fullName : "Avatar"}
              src={`${details && details.avatar ? details.avatar : ""}`}
              className={classes.large}
              onClick={handleAvatarClick}
              style={{ cursor: "pointer" }}
            />
          </Box>
          {generated && (
            <Button
              onClick={handleDownloadHallTicket}
              disabled={l2}
              variant="text"
              color="info"
            >
              {l2 ? "Downloading..." : "Download"}
            </Button>
          )}
          {htmlContent && generated && (
            <Box marginTop={2}>
              <iframe
                srcDoc={htmlContent}
                title="Hall Ticket"
                width="100%"
                style={{ border: "none", minHeight: "50vh" }}
              />
            </Box>
          )}
          {!paidStatus && (
            <Box marginTop={2} bgColor="background.default">
              <Typography
                variant="body1"
                style={{
                  color: colors.dark.main,
                }}
              >
                {display_text}
              </Typography>
              <Typography
                variant="caption"
                color="error"
                style={{ fontWeight: 300 }}
              >
                Note: Fee once paid will not be refunded under any
                circumstances.
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="50vh"
              >
                <Box
                  className={classes.white}
                  p={2}
                  maxWidth={500}
                  minWidth={300}
                  minHeight={300}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  gridGap={10}
                  flexGrow={1}
                >
                  <Typography
                    variant="h3"
                    textAlign="center"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: colors.text.main,
                    }}
                  >
                    {fee_title}
                  </Typography>
                  <Typography
                    variant="h2"
                    color="primary"
                    textAlign="center"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: colors.dark.main,
                    }}
                  >
                    ₹{talentTestFee}
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gridGap={10}
                  >
                    {!generated && (
                      <Button
                        variant="outlined"
                        className={classes.blueGradientBtn2}
                        style={{ marginTop: 8 }}
                        onClick={handleSkip}
                        disabled={l3}
                      >
                        {l3 ? "Generating..." : "Skip"}
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      className={classes.blueGradientBtn}
                      style={{ marginTop: 8 }}
                    >
                      Pay Now
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Container>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Details
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              {renderDetails("Student Details", StudentDetailsFields)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDetails("Parent Details", ParentDetailsFields)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDetails("Previous School Details", PreviousSchoolDetails)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDetails("Address Details", AddressFieldDetails)}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default RegDashBoard;
