import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import cardLeaf from "./logos/cardLeaf.svg";
import Typography from "@material-ui/core/Typography";
//import Button from "components/CustomButtons/Button.jsx";
const styles = (theme) => ({
  cardStyle: {
    background: "#EFF2F4",
    backgroundImage: `url("${cardLeaf}")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    borderRadius: "24px",
    padding: "20px",
    paddingTop: "40px",
    //  margin: '0px 20px',
    animation: "$slideIn 2s ease-in-out",
    minWidth: "320px",
    minHeight: "327px",
    "&:hover": {
      transform: "scale(1.2)",
    },

    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
      minHeight: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      minHeight: "250px",
    },
  },
  "@keyframes slideIn": {
    "0%": {
      opacity: 0,
      transform: "translateY(100%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0.9)",
    },
  },
  titleStyle: {
    fontStyle: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    lineHeight: "24px",
    color: "#31456A",
  },
  descStyle: {
    marginTop: "5px",
    fontStyle: "Montserrat",
    fontWeight: 400,
    fontSize: "19px",
    lineHeight: "29px",
    color: "#31456A",
  },
  iconShadow: {
    marginTop: "-20%",
    textAlign: "center",
    height: "60px",
    width: "60px",
    background: "#EFF2F4",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    borderRadius: "197px",
  },
});

function FeatureCard(props) {
  const { classes } = props;
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
      className={classes.cardStyle}
    >
      <Grid item xs={12}>
        {/* <div  className={classes.cardStyle}> */}
        <div className={classes.iconShadow}>
          <img alt="icon" src={props.icon} style={{ marginTop: "15px" }} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <br />
        <Typography variant="h1" component="h1" className={classes.titleStyle}>
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.descStyle}>{props.description}</div>
      </Grid>
      {/* </div> */}{" "}
    </Grid>
  );
}

export default withStyles(styles)(FeatureCard);
