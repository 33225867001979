import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory, Link } from "react-router-dom";
import store from "store";
// material ui components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import IconButton from "@material-ui/core/IconButton";
// components
import OtpInput from "./OtpInput";
// assets
import IndFlag from "../../assets/svgs/ind.svg";
// hooks
import useFetch from "../../hooks/useFetch";
// apis
import { createOtpLogin, newRegLogin } from "../../apis/UserService";
import { useSnackbar } from "../../context/snackbar";

// form validation schema
const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Phone number must be a number")
    .min(10, "Phone number must be 10 characters")
    .max(10, "Phone number must be 10 characters")
    .required("Phone number is required"),
});

export const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 500,
    textAlign: "center",
  },
  label: {
    lineHeight: "1.5",
    marginBottom: theme.spacing(1),
  },
  inputWithIcon: {
    border: "none",
  },
  back: {
    position: "absolute",
    top: 8,
    left: 8,
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  helperText: {
    marginLeft: theme.spacing(1),
  },
}));

const RegistrationLogInPage = () => {
  const [l1, e1, d1, f1, r1] = useFetch(createOtpLogin);
  const [l2, e2, d2, f2, r2] = useFetch(newRegLogin);
  const [otpSent, setOtpSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const { openSnackbar } = useSnackbar();
  const history = useHistory();
  const payload = d1 ? d1.payload : null;

  const clear = () => {
    setOtpSent(false);
    r1();
    r2();
  };

  const submitOtp = (values) => {
    const data = {
      phoneNumber,
      otp: values.otp,
    };
    f2(data);
  };

  useEffect(() => {
    if (d1) {
      if (d1.data.status === "FAILURE") {
        openSnackbar(d1.data.message, "error");
      } else {
        openSnackbar("OTP sent successfully", "success");
        setOtpSent(true);
      }
    }
    if (e1) {
      openSnackbar("Failed to send OTP", "error");
    }

    return () => {
      r1();
    };
  }, [d1, e1, openSnackbar]);

  useEffect(() => {
    if (d2) {
      if (d2.data.status === "FAILURE") {
        openSnackbar(d2.data.message, "error");
      } else {
        openSnackbar("Login successful", "success");

        localStorage.setItem("isAuthenticated", true);
        store.set("user", d2.data);

        history.push("/reg-dashboard");
      }
    }
    if (e2) {
      openSnackbar(e2, "error");
    }

    return () => {
      r2();
    };
  }, [d2, e2, openSnackbar]);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const classes = useStyles();

  return (
    <Box>
      {otpSent ? (
        <Box>
          <IconButton className={classes.back} onClick={() => clear()}>
            <ArrowBackIosRoundedIcon />
          </IconButton>
          <Formik
            initialValues={{ otp: "" }}
            validationSchema={Yup.object().shape({
              otp: Yup.string()
                .matches(/^[0-9]+$/, "OTP must be a number")
                .min(6, "OTP is required")
                .max(6, "OTP is required")
                .required("OTP is required"),
            })}
            onSubmit={(values) => {
              submitOtp(values);
            }}
          >
            {({ errors, touched, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Box maxWidth={320} margin="auto">
                  <Typography
                    variant="h4"
                    gutterBottom
                    className={classes.title}
                  >
                    Enter OTP
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <OtpInput
                        handleOtpChange={(otp) => {
                          setFieldValue("otp", otp);
                        }}
                      />
                      {touched.otp && errors.otp && (
                        <Typography color="error" variant="caption">
                          {errors.otp}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={1}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={l2}
                        >
                          {l2 ? "Verifying OTP..." : "Submit OTP"}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      ) : (
        <Formik
          initialValues={{ phoneNumber: phoneNumber }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setPhoneNumber(values.phoneNumber);
            f1(values);
          }}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box maxWidth={320} margin="auto">
                <Typography variant="h4" gutterBottom className={classes.title}>
                  Log In
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.label}
                    >
                      Phone Number
                    </Typography>
                    <TextField
                      fullWidth
                      id="phoneNumber"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                      variant="outlined"
                      color="secondary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              gridGap={10}
                            >
                              <Box>
                                <img
                                  src={IndFlag}
                                  width="40"
                                  alt="india flag"
                                />
                              </Box>
                              <Typography variant="button">+91</Typography>
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box textAlign="right" className={classes.buttonContainer}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={l1}
                      >
                        {l1 ? "Sending OTP..." : "Verify Number"}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box textAlign="center" mt={2}>
                      <Typography variant="body2">
                        Don't have an account?{" "}
                        <Link to="/new-registration" style={{ textDecoration: "none" }}>
                          Register
                        </Link>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default RegistrationLogInPage;