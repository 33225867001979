import React from "react";
import { motion, Variants } from "framer-motion";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "100px auto",
    maxWidth: 500,
    paddingBottom: 100,
  },
  card: {
    fontSize: 164,
    width: 300,
    height: 430,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    borderRadius: 20,
    boxShadow:
      "0 0 1px hsl(0deg 0% 0% / 0.075), 0 0 2px hsl(0deg 0% 0% / 0.075), 0 0 4px hsl(0deg 0% 0% / 0.075), 0 0 8px hsl(0deg 0% 0% / 0.075), 0 0 16px hsl(0deg 0% 0% / 0.075)",
    transformOrigin: "10% 60%",
  },
  cardContainer: {
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    paddingTop: 20,
    marginBottom: -120,
  },
  splash: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    clipPath:
      "path(M 0 303.5 C 0 292.454 8.995 285.101 20 283.5 L 460 219.5 C 470.085 218.033 480 228.454 480 239.5 L 500 430 C 500 441.046 491.046 450 480 450 L 20 450 C 8.954 450 0 441.046 0 430 Z)",
  },
}));

const cardVariants = {
  offscreen: {
    y: 300,
  },
  onscreen: {
    y: 50,
    rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

function Card({ emoji, hueA, hueB }) {
  const classes = useStyles();

  const background = `linear-gradient(306deg, hsl(${hueA}, 100%, 50%), hsl(${hueB}, 100%, 50%))`;

  return (
    <motion.div
      className={classes.cardContainer}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
    >
      <div className={classes.splash} style={{ background }} />
      <motion.div className={classes.card} variants={cardVariants}>
        {emoji}
      </motion.div>
    </motion.div>
  );
}

const food = [
  ["🍅", 340, 10],
  ["🍊", 20, 40],
  ["🍋", 60, 90],
  ["🍐", 80, 120],
  ["🍏", 100, 140],
  ["🫐", 205, 245],
  ["🍆", 260, 290],
  ["🍇", 290, 320],
];

export default function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {food.map(([emoji, hueA, hueB]) => (
        <Card key={emoji} emoji={emoji} hueA={hueA} hueB={hueB} />
      ))}
    </div>
  );
}
