import { history } from "../index";

const checkAfterLogin = (domainConfig, props) => {
  switch (domainConfig["afterLogin"]) {
    // case "live":
    //   return props.history.push("/video-classes");
    // case "exams":
    //   return props.history.push("/home");
    default:
      return props.history.push("/home-page");
  }
};
export default checkAfterLogin;
