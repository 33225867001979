import React from 'react';
// Material ui
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

// components
import SyllabusCard from './SyllabusCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: "20px",
    // width: "337px"
  },
  syllabusButton: {
    textTransform: "none",
    background: theme.palette.background.default,
    background: "#08C5A7",
    boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
    borderRadius: "16px",
    fontWeight: "600",
    lineHeight: "18px",
    "&:hover, &:focus": {
      opacity: 1,
      background: "#08C5A7 !important",
    }
  },
  // text: {
  //   color: '#08C5A7',
  // }
}));

const SyllabusModal = ({ grade, examType, examDetails }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <Button
        className={classes.syllabusButton}
        classes={{
          text: classes.text
        }}
        color='primary'
        disabled={
          examDetails && Object.keys(examDetails).length > 0 ? false : true
        }
        onClick={handleOpen}
        size="medium"
        variant="contained"
        id="register-button"
      >
        Exam details & syllabus
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <SyllabusCard
          handleClose={handleClose}
          grade={grade}
          examType={examType}
          examDetails={examDetails}
        />
      </Modal>
    </div>
  );
}

export default SyllabusModal;