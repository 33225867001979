import store from "store";
import React, { useState, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import { ExamTab, ExamTabs, FeeTab } from "../ExamTypeSelectNav";
import Grid from "@material-ui/core/Grid";
import TotalFeeDetails from "./TotalFeeDetails";
import MakePaymentTable from "./MakePaymentTable";
import TransactionTable from "./TransactionTable";
import { useStyles, useStyles1 } from "./styles";
import Header from "../Header";
import SendPaymentLink from "./SendPaymentLink";
import useFeeData from "./UseFeeData";

const ErrorTypes = ["Total Fee Details", "Make payment", "Transactions"]; // tab names
export default function FullWidthGrid() {
  const classes1 = useStyles1();
  const classes = useStyles();
  const [value, setValue] = useState(1);
  const[concessionFeeType,setConcessionFeeType]=useState({})
  const [modifiedObject, otherFees, rows, transactions, config] = useFeeData();
  console.log(rows);
  useEffect(()=>{
    const concessionByFeeType = {};
    if(rows){
      // Loop through the JSON data and extract concession values
      rows.forEach(item => {
        if (item.feeType !== "Total") {
            concessionByFeeType[item.feeType] = item.concession;
        }
    });
  }
  setConcessionFeeType(concessionByFeeType);

  },[rows])
  const [oFees, setOFess] = useState(null);
  const [mdObj, setMdObj] = useState(null);
  useEffect(() => {
    if ((otherFees, modifiedObject)) {
      setOFess(otherFees);
      setMdObj(modifiedObject);
    }
  }, [otherFees, modifiedObject]);
  const balance = oFees ? oFees.Total.balance.toLocaleString("en-IN") : 0;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes1.root}>
      {/* <Header /> */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.root}>
            <ExamTabs value={value} onChange={handleChange}>
              {ErrorTypes.map((name, index) => (
                <FeeTab label={name} key={index} />
              ))}
            </ExamTabs>
          </div>
          {value === 0 && <TotalFeeDetails modifiedObject={mdObj} concessionFeeType={concessionFeeType}/>}
          {value === 1 && (
            <Typography>
              <MakePaymentTable
                modifiedObject={mdObj}
                otherFees={oFees}
                config={config}
                concessionFeeType={concessionFeeType}
              />
            </Typography>
          )}
          {value === 2 && (
            <Typography>
              <TransactionTable balance={balance} transactions={transactions} />
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
