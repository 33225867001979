import { all } from "redux-saga/effects";
import editProfileSagaWatcher from "./editProfileAction/SagaEditProfile";
import notificationSagaWatcher from "./notificationsAction/SagaNotification";
import practiceSagaWatcher from "./practicePageActions/SagaPractice";
import resultSagaWatcher from "./resultPageActions/SagaResult";
import appSettingsSagaWatcher from "./appSettingsAction/SagaAppSettings";
function* rootSaga() {
  yield all([
    practiceSagaWatcher(),
    resultSagaWatcher(),
    notificationSagaWatcher(),
    editProfileSagaWatcher(),
    appSettingsSagaWatcher(),

  ]);
}
export default rootSaga;
