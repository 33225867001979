import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableFooter,
  DialogActions,
  Button,
} from "@material-ui/core";
import {
  CustomTableRow,
  CustomTableCellType3,
} from "../components/CustomTableCells";
import { useStyles } from "../Dashboard/Components/styles";
// loadash
import startCase from "lodash/startCase";


const MyTable = ({ data, handleCloseDialog }) => {
  const classes = useStyles();
  const calculateTotalAmounts = () => {
    const totalAmounts = {};

    for (const key in data) {
      const value = data[key];

      if (typeof value === "object") {
        for (const feeType in value) {
          const amount = value[feeType];

          if (totalAmounts[feeType]) {
            totalAmounts[feeType] += amount;
          } else {
            totalAmounts[feeType] = amount;
          }
        }
      } else {
        if (totalAmounts[key]) {
          totalAmounts[key] += value;
        } else {
          totalAmounts[key] = value;
        }
      }
    }

    return totalAmounts;
  };

  const totalAmounts = calculateTotalAmounts();
  const totalSum = Object.values(totalAmounts).reduce(
    (sum, amount) => sum + amount,
    0
  );
  const handleSave = () => {
    // api call

  };
  return (
    <>
      <TableContainer style={{ padding: 2 }}>
        <Table>
          <TableHead>
            <CustomTableRow className={classes.tr}>
              <CustomTableCellType3 className={classes.tableHeaderStyle}>
                Fee Type
              </CustomTableCellType3>
              <CustomTableCellType3 className={classes.tableHeaderStyle}>
                Calculated Amount
              </CustomTableCellType3>
            </CustomTableRow>
          </TableHead>
          <TableBody>
            {Object.entries(totalAmounts).map(([feeType, amount]) => (
              <CustomTableRow key={feeType}>
                <CustomTableCellType3>{startCase(feeType)}</CustomTableCellType3>
                <CustomTableCellType3>{amount}</CustomTableCellType3>
              </CustomTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <CustomTableRow>
              <CustomTableCellType3>Total:</CustomTableCellType3>
              <CustomTableCellType3>{totalSum}</CustomTableCellType3>
            </CustomTableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Close
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Pay Now
        </Button>
      </DialogActions>
    </>
  );
};

export default MyTable;
