import React from "react";
import store from "store";

import ParentsHome from "./ParentsHome";
import StudentsHome from "./StudentsHome";
import ResultHeader from "./resultPage/ResultHeader";
const Home = () => {
  const accountType = store.get("user").accountType;
  return (
    <>
      <ResultHeader testDisplayName="Exams" path="/home-page" />
      {accountType && accountType === "parent" ? (
        <ParentsHome />
      ) : (
        <StudentsHome />
      )}
    </>
  );
};

export default Home;
