import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { withStyles } from "@material-ui/core/styles";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import compose from "recompose/compose";
import { ReactComponent as CheckSvg } from "../assets/img/Interface/check-square.svg";
import { ReactComponent as FaceSvg } from "../assets/img/Shapes/circle.svg";
import { ReactComponent as LessonSvg } from "../assets/img/Writing/book-bookmark.svg";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import store from "store";
const styles = {
  root: {
    minWidth: "0px",
    filter: "grayscale(100%)",
    color: "#8e9aaf",
    "&$selected": {
      color: "#08c5a7",
      filter: "grayscale(0%)",
    },
  },
  selected: {
    color: "#08c5a7",
  },
};
const styleDiv = (theme) => ({
  bottomNavigation: {
    position: "fixed",
    width: "100%",
    bottom: "0",
    boxShadow: "0px 0px 20px #D1D9E6 !important",
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
});
function FaceIcon() {
  return <FaceSvg fontSize="inherit" />;
}
function LessonIcon() {
  return <LessonSvg fontSize="inherit" />;
}
function CheckIcon() {
  return <CheckSvg fontSize="inherit" />;
}
function BottomNav(props) {
  const accountType =
    (store.get("user") && store.get("user").accountType) || null;
  const pathname = window.location.pathname;
  const getPath = (value) => {
    const relativePath = pathname.includes("/m/") ? `/m${value}` : value;
    return relativePath;
  };
  const routingLessons = getPath("/previous-classes");
  const routingHome = getPath("/home");
  const routingProfile = getPath("/profile");
  const routingLive = getPath("/video-classes");
  const routingPractice = getPath("/practice");
  const [value, setValue] = useState(pathname);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const actionClasses = props.classes;
  const { classes } = props;
  const { hostname } = window.location;
  return (
    <div className={classes.bottomNavigation}>
      {/* <BottomNavigation value={value} onChange={handleChange}>
        {accountType && accountType === "parent" ? (
          <React.Fragment>
            <BottomNavigationAction
              classes={actionClasses}
              component={Link}
              to={routingHome}
              label="Results"
              icon={<CheckIcon />}
              disableRipple
              value={routingHome}
            />
            <BottomNavigationAction
              classes={actionClasses}
              component={Link}
              to={routingProfile}
              label="Profile"
              icon={<FaceIcon />}
              disableRipple
              value={routingProfile}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <BottomNavigationAction
              classes={actionClasses}
              component={Link}
              to={routingLive}
              label="Live"
              icon={<LiveTvIcon />}
              disableRipple
              value={routingLive}
            />
            <BottomNavigationAction
              classes={actionClasses}
              component={Link}
              to={routingLessons}
              label="Lessons"
              disableRipple
              icon={<LessonIcon />}
              value={routingLessons}
            />
            <BottomNavigationAction
              classes={actionClasses}
              component={Link}
              to={routingHome}
              label="Exams"
              icon={<CheckIcon />}
              disableRipple
              value={routingHome}
            />
            {hostname === "onesaz.com" ||
              (hostname === "localhost" && (
                <BottomNavigationAction
                  classes={actionClasses}
                  component={Link}
                  to={routingPractice}
                  label="Practice"
                  icon={<AssignmentRoundedIcon />}
                  disableRipple
                  value={routingPractice}
                />
              ))}
            <BottomNavigationAction
              classes={actionClasses}
              component={Link}
              to={routingProfile}
              label="Profile"
              icon={<FaceIcon />}
              disableRipple
              value={routingProfile}
            />
          </React.Fragment>
        )}
      </BottomNavigation> */}
    </div>
  );
}
export default compose(
  withStyles(styles),
  withStyles(styleDiv)
)(BottomNav);
