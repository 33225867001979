
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer
} from 'recharts';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardInsideOut from '../HomePage/Components/CardInsideOut';

const useStyles = makeStyles(theme => ({
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: "0px !important",
    margin:  "0px !important",
    width: '100%',
    padding:  "0px !important",
  }
}));

const colorPalette = [
  ['#46AB87', 'rgba(106, 225, 183, 0.92)', '#46AB87'],
  ['#A83B96', 'rgba(235, 119, 216, 0.92)', '#A83B96'],
  ['#3358B8', 'rgba(85, 133, 255, 0.92)', '#3358B8'],
  ['#FFBB28', 'rgba(255, 221, 128, 0.92)', '#FFBB28'],
  ['#FF5733', 'rgba(255, 87, 51, 0.92)', '#FF5733'],
  ['#C70039', 'rgba(199, 0, 57, 0.92)', '#C70039'],
  ['#900C3F', 'rgba(144, 12, 63, 0.92)', '#900C3F'],
  ['#581845', 'rgba(88, 24, 69, 0.92)', '#581845'],
  ['#1F77B4', 'rgba(31, 119, 180, 0.92)', '#1F77B4'],
  ['#FF7F0E', 'rgba(255, 127, 14, 0.92)', '#FF7F0E'],
  ['#2CA02C', 'rgba(44, 160, 44, 0.92)', '#2CA02C'],
  ['#D62728', 'rgba(214, 39, 40, 0.92)', '#D62728'],
  ['#9467BD', 'rgba(148, 103, 189, 0.92)', '#9467BD'],
  ['#8C564B', 'rgba(140, 86, 75, 0.92)', '#8C564B'],
  ['#E377C2', 'rgba(227, 119, 194, 0.92)', '#E377C2'],
  ['#7F7F7F', 'rgba(127, 127, 127, 0.92)', '#7F7F7F'],
  ['#BCBD22', 'rgba(188, 189, 34, 0.92)', '#BCBD22'],
  ['#17BECF', 'rgba(23, 190, 207, 0.92)', '#17BECF'],
  // Add more colors as needed
];

const getColorForSubject = (index) => {
  return colorPalette[index % colorPalette.length];
};

const CustomLegend = ({ payload }) => {
  // Add a check to ensure payload is defined
  if (!payload) return null;

  return (
    <div style={{ textAlign: 'flex-start', marginBottom: '10px' }}>
      {payload.map((entry, index) => {
        const colors = getColorForSubject(index);
        return (
          <span
            key={entry.value}
            style={{
              margin: '0 10px',
              display: 'inline-flex',
              alignItems: 'center'
            }}
          >
            <span
              style={{
                display: 'inline-block',
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: colors[0],
                marginRight: '5px'
              }}
            />
            <span>{entry.value}</span>
          </span>
        );
      })}
    </div>
  );
};

const StackedBarChart = ({ subjects, data }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  console.log("subjects", subjects);

  const gradientDefinitions = Object.keys(subjects).reduce((acc, subject, index) => {
    const gradientId = `color${subject}`;
    const colors = getColorForSubject(index);

    acc.push(
      <linearGradient id={gradientId} key={gradientId} x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor={colors[0]} />
        <stop offset="52%" stopColor={colors[1]} />
        <stop offset="100%" stopColor={colors[2]} />
      </linearGradient>
    );

    return acc;
  }, []);

  const transformedData =
    data &&
    data[0] !== null &&
    data
      .filter(item => item.result && item.result !== 'NA')
      .map((item, index) => {
        const result = {
          name:
            item.testDisplayName.includes('Average') ||
            item.testDisplayName.includes('Attempted Exams:')
              ? 'Avg'
              : `T${index + 1}`,
          fullName: item.testDisplayName,
          ...item.result
        };

        return result;
      });

  // Limit the number of bars based on screen size
  const limitedData = transformedData.slice(
    0,
    isMobile ? 6 : isTablet ? 8 : transformedData.length
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const dataIndex = transformedData.findIndex(item => item.name === label);
      const dataItem = transformedData[dataIndex];

      return (
        <div
          className='custom-tooltip'
          style={{
            padding: '10px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px'
          }}
        >
          <p
            className='label'
            style={{ fontWeight: 'bold', marginBottom: '10px' }}
          >
            {dataItem.fullName}
          </p>
          {Object.keys(subjects).map((subject, index) => {
            const colors = getColorForSubject(index);
            return (
              <p
                key={subject}
                style={{
                  margin: '5px 0',
                  color: colors[0],
                  fontWeight: '500'
                }}
              >
                {`${subject}: ${dataItem[subject] || 0}`}
              </p>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return subjects&&(Object.keys(subjects).length!==0)&&(
    <div>
      <CustomLegend payload={Object.keys(subjects).map((subject, index) => ({ value: subject, color: getColorForSubject(index)[0] }))} />
      <CardInsideOut className={classes.root}>
        <div className={classes.chartContainer}>
          <ResponsiveContainer width='100%' height={340}>
            <BarChart data={limitedData} style={{ marginLeft: '-30px', marginTop: "15px" }}>
              <defs>
                {gradientDefinitions}
              </defs>
              <CartesianGrid strokeDasharray='3 3' vertical={false} />
              <XAxis dataKey='name' axisLine={false} tickLine={false} />
              <YAxis axisLine={false} tickLine={false} />
              <Tooltip content={<CustomTooltip />} />
              {Object.keys(subjects).map((subject, index) => (
                <Bar key={subject} dataKey={subject} stackId='a' barSize={30}>
                  {limitedData.map((entry, index) => (
                    <Cell
                      key={`cell-${subject}-${index}`}
                      fill={`url(#color${subject})`}
                    />
                  ))}
                </Bar>
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </CardInsideOut>
    </div>
  );
};

export default StackedBarChart;