import colors from "../../base/colors";
import borders from "../../base/borders";
import pxToRem from "../../functions/pxToRem";

const { inputColors } = colors;
const { borderWidth, borderRadius } = borders;

const outlinedInput = {
  root: {
    display: "flex !important",
    padding: `${pxToRem(11)} ${pxToRem(28)} ${pxToRem(11)} ${pxToRem(
      12
    )} !important`,
    border: `${borderWidth[1]} solid ${inputColors.borderColor.light}`,
    borderRadius: `${borderRadius.md} !important`,

    "& fieldset": {
      border: "none",
    },
  },

  input: {
    height: pxToRem(20),
    // width: "max-content !important",
  },
};

export default outlinedInput;
