import React from "react";
import { Box, Typography } from "@material-ui/core";
import MarksList from "./MarksList";
import theme from "../../assets/theme";
import { useSelector } from "react-redux";

function TotalGrades() {
  const ranks = useSelector((state) => state.rankAnalysis);

  return (
    <React.Fragment>
      <Typography variant="subtitle1" color="textPrimary">
        Total
      </Typography>
      <br />
      <Box display="flex" flexDirection="column" gridGap="10px">
        <MarksList
          subjectName="My results"
          subjectMarks={
            ranks.data && ranks.data.YourDetails ? ranks.data.YourDetails.T : 0
          }
          MarksColor={{ color: theme.palette.info.main }}
        />
        <MarksList
          subjectName="Average"
          subjectMarks={
            ranks.data && ranks.data.Average ? ranks.data.Average.T : 0
          }
          MarksColor={{ color: theme.palette.info.main }}
        />
        <MarksList
          subjectName="Topper"
          subjectMarks={
            ranks.data && ranks.data.TopperDetails
              ? ranks.data.TopperDetails.T
              : 0
          }
          MarksColor={{ color: theme.palette.info.main }}
        />
      </Box>
    </React.Fragment>
  );
}

export default TotalGrades;
