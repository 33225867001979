import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import NetworkProgress from "../NetworkProgress";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
const useStyle = makeStyles(() => ({
  contentWidth: {
    minWidth: "50vw",
    overflow: "auto",
  },
}));
const ProgressAnalytics = ({
  open,
  progressData,
  isLoading,
  handleCloseProgress,
  error,
}) => {
  const theme = useTheme();
  const classes = useStyle();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [option, setOptions] = useState({});

  useEffect(() => {
    setOptions({
      ...option, //getting default options
      chart: {
        type: "column",
      },
      title: {
        text: "Topic wise Practice shares.",
      },
      subtitle: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: "Total percent (%)",
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y:.1f}%",
          },
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
      },

      series: [
        {
          name: "Topics",
          colorByPoint: true,
          data: progressData ? progressData.topicsPercentage : [], //topic percentages
        },
      ],
      drilldown: {
        breadcrumbs: {
          position: {
            align: "right",
          },
        },
        series: progressData ? progressData.drilldown : [], //subtopic percentages
      },
    });
  }, [progressData]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      maxWidth="lg"
      onClose={handleCloseProgress}
      aria-labelledby="analytics-dialog-title"
    >
      <DialogTitle id="analytics-dialog-title">
        <Grid container spacing={2} justify="space-between">
          <Grid item>My Statistics</Grid>
          <Grid item>
            <Typography align="right">
              <CloseIcon fontSize="inherit" onClick={handleCloseProgress} />
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <NetworkProgress />
        ) : error ? (
          <Typography color="error" variant="h5" align="center">
            Something went wrong!
          </Typography>
        ) : (
          <React.Fragment>
            <DialogContentText className={classes.contentWidth}>
              {progressData && progressData.topicsPercentage.length > 0 ? (
                <HighchartsReact options={option} highcharts={Highcharts} />
              ) : (
                <Typography variant="h5" align="center">
                  Data not Available!
                </Typography>
              )}
            </DialogContentText>
            <br />
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseProgress} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProgressAnalytics;
