import React, { useRef, useEffect, useState } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
// core components
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Grow from "@material-ui/core/Grow";
import { motion } from "framer-motion";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
// import management from "assets/img alt="..."/management.svg";
import database from "assets/v2/database.png";
import edit from "assets/v2/edit.png";
import chart from "assets/v2/chart.png";
import layers from "assets/v2/layers.png";
import management from "assets/v2/management.png";
import student from "assets/v2/student.png";
import feather from "assets/v2/feather.png";
import bars from "assets/v2/bars.png";
import FeatureCard from "./FeatureCard";
import Button from "@material-ui/core/Button";
import { Fade } from "react-reveal";
import compose from "recompose/compose";
import { Link } from "react-router-dom";

import { Suspense } from "react";
import empowermgmt from "./logos/empowermgmt.svg";
import enhance from "./logos/enhance.svg";
import fully from "./logos/fully.png";
import features from "./logos/Component 11.png";
import NetworkProgress from "../../../mycomponents/NetworkProgress";
import Leaves from "./logos/Leaves_1.png";
import styles from "./SectionFeaturesStyles";
import ScrollingCards from "./ScrollingCards";
import {
  modulesStreamlinedCards,
  LmsFeturescards,
  OptimizeOperationsCards,
  features1,
  features2,
} from "./CradsDetails";
import SmoothScroll from "./SmoothScrolling";
import AnimatedText from "./AnimatedText";
import StudentPortal from "./StudentPortal";
import CustomersReview from "./CustomersReview";
import NewAnimation from "./NewAnimation";
import ring from "./logos/ring.png";
import db from "./logos/db.svg";
import ImageDisplay from "./ImageDisplay";

//lazy load images
const LazyLoadImages = React.lazy(() =>
  import("../../../mycomponents/LazyLoadImages")
);

function SectionFeatures({ ...props }) {
  const { classes, ...rest } = props;
  const cardsRef = useRef([]);
  const containerRef = useRef(null);
  const [animatedCards, setAnimatedCards] = useState(new Set());
  const text = "Framer Motion is a really cool tool".split(" ");
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2, // Trigger animation when 20% of the card is in the viewport
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !animatedCards.has(entry.target)) {
          entry.target.classList.add("animate"); // Add a CSS class to trigger the animation
          setAnimatedCards((prevSet) => new Set(prevSet.add(entry.target)));
        }
      });
    }, options);

    cardsRef.current.forEach((card) => {
      observer.observe(card);
    });

    return () => {
      // Clean up the observer when the component unmounts
      observer.disconnect();
    };
  }, [animatedCards]);
  const [showBox1, setShowBox1] = React.useState(false);
  const [showBox2, setShowBox2] = React.useState(false);
  const [showBox3, setShowBox3] = React.useState(false);

  const handleScroll1 = () => {
    if (window.scrollY > 500) {
      setShowBox1(true);
    } else {
      setShowBox1(false);
    }
  };
  const handleScroll2 = () => {
    if (window.scrollY > 700) {
      setShowBox2(true);
    } else {
      setShowBox2(false);
    }
  };
  const handleScroll3 = () => {
    if (window.scrollY > 1000) {
      setShowBox3(true);
    } else {
      setShowBox3(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll1);
    window.addEventListener("scroll", handleScroll2);
    window.addEventListener("scroll", handleScroll3);
    window.addEventListener("scroll", handleScroll);

    return () => {
      // window.removeEventListener("scroll", handleScroll1);
      // window.removeEventListener("scroll", handleScroll2);
    };
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  const [growChecked, setGrowChecked] = React.useState(false);

  const handleScroll = () => {
    if (window.scrollY > 1500) {
      setGrowChecked(true);
    } else {
      setGrowChecked(false);
    }
  };
  var scrollPosition = window.scrollY;

  // Log the scroll position to the console
  console.log("Vertical scroll position: " + scrollPosition);
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
        className={classes.studentBg}
        style={{ paddingTop: "50px" }}
      >
        <Grid item xs={10} sm={8}>
          <Typography
            variant="h1"
            component="h1"
            className={classes.heading}
            style={{ textAlign: "center" }}
          >
            Why our product is the <b style={{ color: "#3BBDED" }}>best</b>
          </Typography>
          <br />
        </Grid>
        <Grid item xs={10} sm={10}>
          {showBox1 && (
            <Grid
              container
              spacing={8}
              direction="row"
              // justify={
              //   isWidthUp("xs", props.width) ? "space-between" : "center"
              // }
              alignItems="center"
              style={{
                transform: "scale(0.9)",
                opacity: 1,
              }}
            >
              {features1.map((feature, index) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  style={{ marginBottom: "10px" }}
                  key={index}
                >
                  <div ref={(el) => (cardsRef.current[index] = el)}>
                    <FeatureCard
                      icon={feature.icon}
                      title={feature.title}
                      description={feature.description}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        <Grid item xs={10} sm={10}>
          {showBox2 && (
            <Grid
              container
              spacing={8}
              direction="row"
              // justify={
              //   isWidthUp("xs", props.width) ? "space-between" : "center"
              // }
              alignItems="center"
              style={{
                transform: "scale(0.9)",
                opacity: 1,
              }}
            >
              {features2.map((feature, index) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  style={{ marginBottom: "10px" }}
                  key={index}
                >
                  <div ref={(el) => (cardsRef.current[index] = el)}>
                    <FeatureCard
                      icon={feature.icon}
                      title={feature.title}
                      description={feature.description}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <br />
          <br />
          <Link to="/register">
            <Button
              className={classes.sendMessage}
              style={{ marginBottom: "40px" }}
            >
              Sign up
            </Button>
          </Link>
          <br />
        </Grid>
      </Grid>
      <>
        {/* -------------------first---------------------- */}
        <div className={classes.appScreenShot}>
          <Grid
            container
            style={{ marginTop: "25px", margin: "20px", marginBottom: "36px" }}
            spacing={1}
          >
            <Grid item xs={12} sm={12}>
              <Grid item xs={12}>
                <Typography
                  style={{ marginLeft: 15 }}
                  className={classes.myText}
                >
                  Increased results with{" "}
                  <b style={{ color: "#26DDB1", fontWeight: 900 }}>LMS</b>{" "}
                  features
                </Typography>
              </Grid>
              {/* <Grid item xs={12}>
                <Typography
                  style={{ marginLeft: 15 }}
                  className={classes.myText}
                >
                  <b style={{ color: "#26DDB1", fontWeight: 900 }}>LMS</b>{" "}
                  features
                </Typography>
              </Grid> */}

              <Grid item xs={12}>
                <Typography
                  style={{ marginLeft: 15 }}
                  className={classes.subMyText}
                >
                  Run your school on most intelligent operating system
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={1} />
            {/* <Grid item xs={12} sm={5}>
              <img src={ring} alt="ring" />
            </Grid> */}
            <Grid item xs={12}>
              &nbsp;
            </Grid>
            <Grid item xs={12} sm={3} style={{ marginLeft: 15 }}>
              <Grow
                in={growChecked}
                style={{ transformOrigin: "0 0 0" }}
                {...(growChecked ? { timeout: 1000 } : {})}
              >
                <div
                  className={`${classes.empowerMangBox} ${
                    growChecked ? "slide-up" : ""
                  }`}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={2}>
                      <Fab disabled aria-label="like" size="small">
                        <img src={empowermgmt} alt="onesaz" />
                      </Fab>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Typography className={classes.empowerText}>
                        Empower Management Efficiency
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grow>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Grow
                in={growChecked}
                style={{ transformOrigin: "0 0 0" }}
                {...(growChecked ? { timeout: 1000 } : {})}
              >
                <div
                  className={`${classes.empowerMangBox} ${
                    growChecked ? "slide-up" : ""
                  }`}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <Fab disabled aria-label="like" size="small">
                        <img src={enhance} alt="onesaz" />
                      </Fab>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Typography className={classes.empowerText}>
                        Enhance the Students Learning
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grow>
            </Grid>
          </Grid>

          <ScrollingCards
            cards={LmsFeturescards}
            containerRef={containerRef}
            // scrollYFrom={1960}
            // scrollYTo={2200}
            // scrollFactor={0.2}
          />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
        {/* ---------------2nd------------- */}
        <div className={classes.appScreenShot}>
          <Grid
            container
            style={{ marginTop: "25px", margin: "20px", marginBottom: "36px" }}
            spacing={1}
          >
            <Grid item xs={12} sm={12}>
              <Grid item xs={12}>
                <Typography
                  style={{ marginLeft: 15 }}
                  className={classes.myText}
                >
                  Optimize Operations with Onesaz
                  <b style={{ color: "#12AEEE", fontWeight: 900 }}>
                    {" "}
                    &nbsp;ERP
                  </b>
                </Typography>
              </Grid>
              {/* <Grid item xs={12}>
                <Typography
                  style={{ marginLeft: 15 }}
                  className={classes.myText}
                >
                  with Onesaz
                  <b style={{ color: "#12AEEE", fontWeight: 900 }}>
                    {" "}
                    &nbsp;ERP
                  </b>
                </Typography>
              </Grid> */}

              <Grid item xs={12}>
                <Typography
                  style={{ marginLeft: 15 }}
                  className={classes.subMyText}
                >
                  With Onesaz ERP you're embracing a new era of education
                  management
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <img src={db} alt="db" />
            </Grid> */}
            <Grid item xs={12}>
              &nbsp;
            </Grid>
            <Grid item xs={12} sm={2} style={{ marginLeft: 15 }}>
              <Grow
                in={growChecked}
                style={{ transformOrigin: "0 0 0" }}
                {...(growChecked ? { timeout: 1000 } : {})}
              >
                <div
                  className={`${classes.empowerMangBox} ${
                    growChecked ? "slide-up" : ""
                  }`}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={2}>
                      <Fab disabled aria-label="like" size="small">
                        <img src={features} alt="onesaz" />
                      </Fab>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Typography
                        style={{ marginTop: "4px" }}
                        className={classes.empowerText}
                      >
                        &nbsp; &nbsp;12 + ERP features
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grow>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Grow
                in={growChecked}
                style={{ transformOrigin: "0 0 0" }}
                {...(growChecked ? { timeout: 1000 } : {})}
              >
                <div
                  className={`${classes.empowerMangBox} ${
                    growChecked ? "slide-up" : ""
                  }`}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <Fab disabled aria-label="like" size="small">
                        <img src={fully} alt="onesaz" />
                      </Fab>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Typography
                        style={{ marginTop: "4px" }}
                        className={classes.empowerText}
                      >
                        &nbsp; &nbsp;Fully customizable
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grow>
            </Grid>
          </Grid>
          <ScrollingCards
            scrollYFrom={3724}
            scrollYTo={4000}
            cards={OptimizeOperationsCards}
            scrollFactor={0.00021}
            containerRef={containerRef}
          />
        </div>
        &nbsp; &nbsp; &nbsp;&nbsp;
        {/* ----------------3rd------------ */}
        <div className={classes.appScreenShot}>
          <Typography className={classes.myText}>
            Modules for Streamlined Operations
          </Typography>
          {/* <Typography className={classes.myText}>
            Streamlined Operations
          </Typography> */}
          <Typography className={classes.subMyText}>
            Streamline operations, enhance collaboration, and optimize resource
            allocation.
          </Typography>
          &nbsp;&nbsp;
          <ScrollingCards
            scrollYFrom={2724}
            scrollYTo={2900}
            containerRef={containerRef}
            cards={modulesStreamlinedCards}
            scrollFactor={0.00001}
          />
        </div>
      </>
      {/* --------------------------------------------------------------- */}

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.studentBg}
      >
        <ImageDisplay />
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.studentBg}
      >
        <AnimatedText />
      </Grid>
      {/* <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.studentBg}
      >
        <Grid item xs={12} style={{ margin: "60px 0px 20px 0" }}>
          <SmoothScroll />
        </Grid>
      </Grid> */}
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.studentBg}
        padding={6}
      >
        <StudentPortal />
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.studentBg}
        padding={4}
      >
        <CustomersReview />
      </Grid>
      {/* <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.studentBg}
      >
        <Grid
          item
          xs={12}
          sm={10}
          lg={8}
          style={{ margin: "120px 0px 140px 0" }}
        >
          <Grid
            container
            direction="row"
            justify={isWidthUp("md", props.width) ? "space-between" : "center"}
            alignItems="center"
          >
            <Grid item xs={12} sm={8} md={6}>
              <Suspense fallback={<NetworkProgress />}>
                <LazyLoadImages src={student} className={classes.studentImg} />
              </Suspense>
            </Grid>
            <Grid item xs={12} sm={10} md={6}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item xs={11}>
                  <Typography
                    variant="h1"
                    component="h1"
                    className={classes.heading}
                  >
                    Students life will be much easier
                  </Typography>
                  <br />
                  <br />
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item xs={12} className={classes.infoAreaMargin}>
                  <InfoArea
                    className={classes.infoArea}
                    icon={layers}
                    title="Plenty of Question Papers to Practice"
                    description="The moment you use Onesaz, you know you’ve never felt anything like this online examination series and lets you perform more than ever before."
                    iconColor="primary"
                  />
                </Grid>
                <Grid item xs={12} className={classes.infoAreaMargin}>
                  <InfoArea
                    className={classes.infoArea}
                    icon={feather}
                    title="Easy to Use"
                    description="Our portal is user friendly and students can write exams on multiple devices (Desktops, tablets, smartphones) "
                    iconColor="primary"
                  />
                </Grid>
                <Grid item xs={12} className={classes.infoAreaMargin}>
                  <InfoArea
                    className={classes.infoArea}
                    icon={bars}
                    image={
                      <img alt="..." src={bars} style={{ marginTop: "10%" }} />
                    }
                    title="Quick Results and Analysis"
                    description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
                    iconColor="primary"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      {/* <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.manageBg}
      >
        <Grid
          item
          xs={12}
          sm={10}
          lg={8}
          style={{ margin: "120px 0px 140px 0" }}
        >
          <Grid
            container
            direction="row"
            justify={isWidthUp("md", props.width) ? "space-between" : "center"}
            alignItems="center"
          >
            <Grid item xs={12} sm={10} md={6}>
              <Grid container direction="row" justify="center">
                <Grid item xs={11}>
                  <Typography
                    variant="h1"
                    component="h1"
                    className={classes.heading}
                  >
                    Management will be much more easier
                  </Typography>
                  <br />
                  <br />
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item xs={12} className={classes.infoAreaMargin}>
                  <InfoArea
                    className={classes.infoArea}
                    icon={database}
                    title="Question Bank"
                    description="We provide you our High Quality Question Bank and you can also create your own Question Bank"
                    iconColor="primary"
                  />
                </Grid>
                <Grid item xs={12} className={classes.infoAreaMargin}>
                  <InfoArea
                    className={classes.infoArea}
                    icon={edit}
                    title={
                      <React.Fragment>
                        {" "}
                        <div
                          style={{ marginTop: "-10px", marginBottom: "15px" }}
                        >
                          {" "}
                          Test Creation and Set Generation in minutes{" "}
                        </div>
                      </React.Fragment>
                    }
                    description="Upload your own question paper or pick from Question Bank  and conduct test in minutes, Generate sets for Online/Offline Students in the few minutes "
                    iconColor="primary"
                  />
                </Grid>
                <Grid item xs={12} className={classes.infoAreaMargin}>
                  <InfoArea
                    className={classes.infoArea}
                    // icon={chart}
                    image={
                      <img alt="..." src={chart} style={{ marginTop: "10%" }} />
                    }
                    title="Trusted Analysis"
                    description="Get the best insights on student performance and in-depth analysis on indiviual student, question difficulty, time spent "
                    iconColor="primary"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
              <Suspense fallback={<NetworkProgress />}>
                <LazyLoadImages
                  src={management}
                  className={classes.manageImg}
                />
              </Suspense>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
    </React.Fragment>
  );
}

export default compose(
  withWidth(),
  withStyles(styles)
)(SectionFeatures);
