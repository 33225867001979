import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",

    padding: "5px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "14px",
    "&:focus": {
      border: "none",
      boxShadow: `0 0 5px ${theme.palette.info.main}`,
    },
    "&:focus-visible": {
      outline: "none",
    },
  },
}));
const StyledDataInputField = ({ value, max, handleChange, id }) => {
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState(false);
  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };
  return (
    <input
      type="date"
      name={id}
      id={id}
      className={`${classes.input} ${isFocused ? classes.focused : ""}`}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
      value={value}
      onChange={handleChange}
      max={max}
    />
  );
};
export default StyledDataInputField;
