import React from "react"
import result from "./logos/results.svg";
import practice from "./logos/practice.svg";
import easyToUse from "./logos/easyToUse.svg";
import vedio from "./logos/vedioSymbol.svg";
import Inventory from "./logos/InventoryMgmt.svg";
import Institute from "./logos/Institutemgmt.svg";
import finance from "./logos/FinanceMgmt.svg";
import Admissions from "./logos/AdmissionsMgmt.svg";
import data from "./logos/DataMgmt.svg";
import Transport from "./logos/TransportMgmt.svg";
import sms from "./logos/smsIntegration.svg";
import hostel from "./logos/hostelmgmt.svg";
import hrandpayroll from "./logos/HrandPayroll.svg";
import courses from "./logos/coursesAndBatches.svg";
import idGenerator from "./logos/IdGenerator.svg";
import secure from "./logos/secureLogin.svg";
import support from "assets/v2/support.png";
import badge from "assets/v2/badge.png";
import search from "assets/v2/search.png";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import profile from "./logos/profile.png"
import c from "./logos/c.png";
import layer from "./logos/4layer.png";
import  adaptive from "./logos/adaptive.png";
import bsetContent from "./logos/bestContent.png";
import omr from "./logos/omr.png";
import book from "./logos/book.png";
import testCreation from "./logos/TestCreation.svg";


export const LmsFeturescards = [
  {
    icon: vedio,
    title: "Secure Virtual Classes",
    backgroundColor: "rgba(253, 151, 56, 0.1)",
    content:
      "Our solutions empower you to conduct online classes with ironclad security. You have full control over class access and participants. ",
  },
  {
    icon: result,
    title: "Quick Analysis",
    backgroundColor: "rgba(153, 211, 28, 0.2)",
    content:
      "We provides 14 types of analysis that teachers and students can dive into for insights that spark improvement.",
  },
  {
    icon: easyToUse,
    title: "User Friendly",
    backgroundColor: "rgba(251, 36, 191, 0.1)",
    content:
      "Our portal is user friendly and students can write exams on multiple devices (Desktops, tablets, smartphones).",
  },
  {
    icon: practice,
    backgroundColor: "rgba(36, 159, 251, 0.1)",
    title: "Multiple Question Papers",
    content:
      "Leverage our library to effortlessly create & customize multiple question papers tailored to your exact needs.",
  },
  {
    icon: testCreation,
    backgroundColor: " #26DDB11A",
    title: "Test Creation",
    content:"Our test creation tool lets students craft personalized papers aligned with their performance, fostering progress and improvement."
  }
];
export const OptimizeOperationsCards = [
  {
    icon: Institute,
    title: "Institute management",
    backgroundColor: "rgba(36, 159, 251, 0.1)",
    content:
      "Effective institute management in the educational system involves  efficient administration, and a student-centered approach to cultivate academic excellence.",
  },
  {
    icon: data,
    title: "Data management",
    backgroundColor: "rgba(251, 36, 191, 0.1)",
    content:
      "Easily record, backup, export data in CSV or XML format. Customize fields, manage student details, and save records for in-depth analysis.",
  },
  {
    icon: finance,
    title: "Finance management",
    backgroundColor: "rgba(153, 211, 28, 0.2)",
    content:
      "Simplifies fees collection, Automate Transactions and Provide In-depth Financial Reports.",
  },
  {
    icon: Admissions,
    backgroundColor: "rgba(253, 151, 56, 0.1)",
   
    title: "Admission management",
    content:
      "Digital admission process, Easy form submission, Seamless admission tracking.",
  },
  {
    icon: Inventory,
    backgroundColor: "rgba(165, 79, 252, 0.1)",
    title: "Inventory management",
   
    content:
      "Manage Inventory, Maintain Supplier details and Generate a paperless invoice.",
  },
  {
    icon: Transport,
    backgroundColor: "rgba(81, 164, 139, 0.1)",
    title: "Transport management",
    content:
      "Enhance student safety, Tracking vehicle status and Collect transportation fees.",
  },
];
export const modulesStreamlinedCards = [
  {
    icon: sms,
    title: "Sms integration",
    backgroundColor: "rgba(38, 221, 177, 0.1)",
    content:
      "Send Instant Alerts, Enhance Communication and Reduce Workload. SMS integration for important updates like exam schedules, meetings, updates.",
  },
  {
    icon: courses,
    title: "Courses and Batches",
    backgroundColor: " rgba(86, 113, 255, 0.1)",
    content:
      "Our robust database had capacity to handle 100+ branches and 100,000+ batches effortlessly.",
  },
  // {
  //   icon: hostel,
  //   backgroundColor:"rgba(88, 215, 67, 0.15)",
  //   title: "Hostel management",
  //   content:
  //     "Monitor activities, room allocation, warden portals, attendance, and reduce paper waste with our comprehensive tool",
  // },
  {
    icon: hrandpayroll,
    backgroundColor: "rgba(199, 57, 82, 0.1)",
    title: "Hr and payroll",
    content:
      "Save time on employee payments, taxes, reimbursements, and focus on your business plans.",
  },
  {
    icon: idGenerator,
    backgroundColor: "rgba(232, 196, 8, 0.1)",
    title: "Id generator",
    content:
      "Digital admission process, Easy form submission, Seamless admission tracking.",
  },
  {
    icon: secure,
    backgroundColor: "rgba(173, 111, 222, 0.1)",
    title: "Secure login",
    content:
      "The moment you use Onesaz, you know you’ve never felt anything like this online examination series.",
  },

];

export const features1 = [
  {
    icon: c, // Replace with your actual icon source
    title: "Customized Solutions",
    description:
      "Our tech-savvy team listens to your needs, delivering fast, tailored solutions",
  },
  {
    icon: layer, // Replace with your actual icon source
    title: "4 Layer security",
    description:
      "Your data is guarded by 4 layers of protection, ensuring a hack-free environment. Notified whenever data is edited/damaged",
  },
  {
    icon: omr, // Replace with your actual icon source
    title: "OMR scan analysis",
    description:
      "Easily scan any OMR sheet with your mobile camera. We provide instant, comprehensive analysis with 14 detailed insights",
  },
];
export const features2 = [
  {
    icon: adaptive, // Replace with your actual icon source
    title: "Adaptive Learning",
    description:
      "Our system leverages advanced algorithms to analyze individual student performance and preferences",
  },
  {
    icon: bsetContent, // Replace with your actual icon source
    title: "School operating system",
    description:
      "Our ERP solution seamlessly integrates all aspects of education management, from enrollment to assessments, into a unified platform.",
  },
  {
    icon: book, // Replace with your actual icon source
    title: "Best content",
    description:
      "Explore Onesaz’s question bank with 300,000+ questions, subject wise, topics, and subtopics – all expertly organized to save your time & energy.",
  },
];
export const customerReviews = [
  {
    icon: profile, // Replace with your actual icon source
    title: "Rajiv Bhupathi",
    description:
      "Its user-friendly interface and robust features empower institutions to efficiently manage student data, enhancing productivity and organization. ",
  },
  {
    icon: profile, // Replace with your actual icon source
    title: " Ch.Sri Charan",
    description:
      "I appreciate the app's robust security measures, ensuring fair and reliable exam assessment. Features like randomized questions and proctoring options uphold academic integrity.",
  },
  {
    icon: profile, // Replace with your actual icon source
    title: "Kommalapati Harsha",
    description:
      "With this app, students can take exams from anywhere. Its flexibility allows for studying on the go, making it ideal for busy schedules and remote learning environments.",
  },
  
 
];
export const customerReviews1 = [
  
  {
    icon: profile, // Replace with your actual icon source
    title: "Suwarna Minmulwar",
    description:
      "An Excellent app for Exams. Helps us to compare our performance with other people. It also helps us to compare our performance with the previous one. shows the time spent analysis on each question .",
  },
  {
    icon: profile, // Replace with your actual icon source
    title: "Rakesh Naidu Gandi",
    description:
      "Many more features that makes student life better and also helps teachers to get in depth analysis about student performance in exams and assignments which can help student to perform better.",
  },
  {
    icon: profile, // Replace with your actual icon source
    title: "Swathi",
    description:
    "Its user-friendly interface and robust features empower institutions to efficiently manage student data, enhancing productivity and organization.An Excellent app for Exams ",
  },
 
];