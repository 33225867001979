import * as actionType from "./actions";

export const editProfileLoading = (userId, body) => ({
  type: actionType.EDIT_PROFILE_LOADING,
  userId,
  body,
});

export const editProfileSuccess = (result) => ({
  type: actionType.EDIT_PROFILE_SUCCESS,
  payload: result,
});

/* ------------------api error handling action creators------------------- */

export const catchErrorEditProfile = (error) => ({
  type: actionType.CATCH_ERROR_EDIT_PROFILE,
  payload: error,
});

/* ------------clear error from store if any on component unmount------------ */

export const clearErrorEditProfile = () => ({
  type: actionType.CLEAR_ERROR_EDIT_PROFILE,
});
