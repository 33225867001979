import React, { useState, useEffect } from 'react';

// material 
import { InputBase } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

// components
import {
  CustomLabel,
  CustomSelect,
} from "../../components/CustomInput";

import styles from '../../styles';


const DateAndTimeslot = ({
  classes,
  handleExamDateAndTime,
  pulledDateTimeSlots,
  studentClass,
  examType,
  selectedDate,
  selectedTimeSlot
}) => {
  console.log(pulledDateTimeSlots)
  const [dateAndTimeSlots, setDateAndTimeSlots] = useState({})
  const [date, setDate] = useState(selectedDate);
  const [timeSlot, setTimeSlot] = useState(selectedTimeSlot);

  const handleOptionSelect = (e) => {
    // const option = e.target.name
    setDate(e.target.value)
    setTimeSlot(dateAndTimeSlots[e.target.value]['slot1'])
    // option === 'date' ? setDate(e.target.value) : setTimeSlot(e.target.value)
  }

  useEffect(() => {
    if (pulledDateTimeSlots && Object.keys(pulledDateTimeSlots).length > 0) {
      setDateAndTimeSlots(pulledDateTimeSlots[studentClass][examType]);
    }
    handleExamDateAndTime(date, timeSlot)
  }, [date, timeSlot, pulledDateTimeSlots, studentClass, examType, selectedDate, selectedTimeSlot])

  useEffect(() => {
    setDate('')
    setTimeSlot('')
  }, [studentClass, examType])

  return (
    <>
      <FormControl
        style={{ marginTop: "20px", width: '200px' }}
        variant="outlined"
        fullWidth
        className={classes.selectFormControl}
      >
        <CustomLabel htmlFor="simple-select1">
          Exam Date
        </CustomLabel>
        <CustomSelect
          placeholder='Exam Date'
          style={{ textAlign: "left" }}
          variant="outlined"
          classes={{
            select: classes.select,
          }}
          value={selectedDate}
          name="date"
          onChange={handleOptionSelect}
          input={
            <DateAndTimeInput
              displayEmpty
              placeholder="Exam date"
              label="Exam date"
              labelWidth={40}
              name="date"
              id="simple-select8"
            />
          }
          inputProps={{
            placeholder: "Your Class",
            name: "date",
            id: "simple-select8",
          }}
        >
          <MenuItem
            disabled
            value=""
            classes={{
              root: classes.selectMenuItem,
            }}
          >
            Select Exam date
          </MenuItem>

          {
            dateAndTimeSlots ? (Object.keys(dateAndTimeSlots).map((date, index) => {
              return (
                <MenuItem
                  key={index}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={date}
                >
                  {date} 2022
                </MenuItem>
              );
            })) : null
          }
        </CustomSelect>
      </FormControl>
      <FormControl
        style={{ marginTop: "20px", width: '200px' }}
        variant="outlined"
        fullWidth
        className={classes.selectFormControl}
      >
        <CustomLabel htmlFor="simple-select1">
          Exam Time slot
        </CustomLabel>
        <CustomSelect
          placeholder="Exam time slot"
          style={{ textAlign: "left" }}
          variant="outlined"
          classes={{
            select: classes.select,
          }}
          value={selectedTimeSlot}
          name="timeslot"
          // onChange={handleOptionSelect}
          input={
            <DateAndTimeInput
              displayEmpty
              placeholder="exam time slot"
              label="exam time slot"
              labelWidth={40}
              name="timeslot"
              id="simple-select7"
            />
          }
          inputProps={{
            placeholder: "Your Class",
            name: "class",
            id: "simple-select7",
          }}
        >
          <MenuItem
            disabled
            value=""
            classes={{
              root: classes.selectMenuItem,
            }}
          >
            Select Your Time slot
          </MenuItem>

          {
            date ? (
              Object.keys(dateAndTimeSlots[date]).map((slot, index) => {
                return (
                  <MenuItem
                    key={index}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={dateAndTimeSlots[date][slot]}
                  >
                    {dateAndTimeSlots[date][slot]}
                  </MenuItem>
                );
              })
            ) : null
          }
        </CustomSelect>
      </FormControl>
    </>
  )
}

const DateAndTimeInput = withStyles({
  root: {
    // marginLeft: '-7px',
    height: "57px",
    padding: "0px 15px",
    borderColor: "#60c6a8",
    minWidth: "200px",
    boxShadow: "rgb(209, 217, 230) 2px 2px 5px inset",
    paddingLeft: "8px",
    borderRadius: "12px",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(130deg, rgb(255, 255, 255), rgb(209, 217, 230))",
    backgroundOrigin: "border-box",
    "&:hover, &:focus": {
      borderColor: "#60c6a8",
    },
    borderWidth: "0px !important",
  },
  outlined: {
    borderWidth: "10px !important",
  },
})(InputBase);

export default withStyles(styles)(DateAndTimeslot);